<template>
  <div class="popup-layer">
    <div class="popup-layer-bg" @click="rosterClose"></div>
    <div class="personPopup">
      <div class="popup-layer-close" @click="rosterClose">
        <svg-icon icon-class="popup-close"></svg-icon>
      </div>
      <div class="addStaffPopup-header">个税专项扣除</div>
      <div class="cumulative-mian" v-if="show">
        <!-- <div class="cumulative-box" style="border-bottom: none">
          <div class="cumulative-box-l">入职日期</div>
          <div class="cumulative-box-r">
            <input v-model="jobTimeText" type="text" readonly />
          </div>
        </div> -->
        <!-- <p>提示：以下累计项目是指该员工在本公司上班期间内，发生的累计金额。</p> -->
        <div class="cumulative-box">
          <div class="cumulative-box-l">子女教育</div>
          <div class="cumulative-box-r">
            <slot
              v-if="specialInfo.znjyzc === '' || specialInfo.znjyzc === '0.00'"
              ><span style="color: #bbb">无</span></slot
            >
            <slot v-else>{{ specialInfo.znjyzc }}</slot>
          </div>
        </div>
        <div class="cumulative-box">
          <div class="cumulative-box-l">继续教育</div>
          <div class="cumulative-box-r">
            <slot
              v-if="specialInfo.jxjyzc === '' || specialInfo.jxjyzc === '0.00'"
              ><span style="color: #bbb">无</span></slot
            >
            <slot v-else>{{ specialInfo.jxjyzc }}</slot>
          </div>
        </div>
        <div class="cumulative-box">
          <div class="cumulative-box-l">住房贷款利息</div>
          <div class="cumulative-box-r">
            <slot
              v-if="
                specialInfo.zfdklxzc === '' || specialInfo.zfdklxzc === '0.00'
              "
              ><span style="color: #bbb">无</span></slot
            >
            <slot v-else>{{ specialInfo.zfdklxzc }}</slot>
          </div>
        </div>
        <div class="cumulative-box">
          <div class="cumulative-box-l">住房租金</div>
          <div class="cumulative-box-r">
            <slot
              v-if="specialInfo.zfzjzc === '' || specialInfo.zfzjzc === '0.00'"
              ><span style="color: #bbb">无</span></slot
            >
            <slot v-else>{{ specialInfo.zfzjzc }}</slot>
          </div>
        </div>
        <div class="cumulative-box">
          <div class="cumulative-box-l">赡养老人</div>
          <div class="cumulative-box-r">
            <slot
              v-if="specialInfo.sylrzc === '' || specialInfo.sylrzc === '0.00'"
              ><span style="color: #bbb">无</span></slot
            >
            <slot v-else>{{ specialInfo.sylrzc }}</slot>
          </div>
        </div>
        <div class="cumulative-box">
          <div class="cumulative-box-l">婴幼儿照护费用</div>
          <div class="cumulative-box-r">
            <slot
              v-if="
                specialInfo.yyezhzc === '' ||
                specialInfo.yyezhzc === '0.00' ||
                specialInfo.yyezhzc === undefined
              "
              ><span style="color: #bbb">无</span></slot
            >
            <slot v-else>{{ specialInfo.yyezhzc }}</slot>
          </div>
        </div>
      </div>
      <div class="earmarked-footer">
        <div
          class="earmarked-btn"
          :class="{ statusStyle: status === 1 }"
          @click="updateSpecialInfo"
        >
          更新
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  updateEmployeeStaffInfo,
  updateSpecialInfo,
  getSpecialInfo,
} from "@/http/api";
export default {
  data() {
    return {
      // userId: this.$getCookie("userId"),
      // enterpriseId: this.$getCookie("enterpriseId"),

      enterpriseId: sessionStorage.getItem("enterpriseId"),
      userId: sessionStorage.getItem("userId"),
      list: [
        {
          title: "累计专项个税扣除-子女教育",
          field: "ljznjyzc",
        },
        {
          title: "累计专项个税扣除-继续教育",
          field: "ljjxjyzc",
        },
        {
          title: "累计专项个税扣除-住房租金",
          field: "ljzfzjzc",
        },
        {
          title: "累计专项个税扣除-住房贷款",
          field: "ljzfdklxzc",
          show: false,
        },
        {
          title: "累计专项个税扣除-赡养老人",
          field: "ljsylrzc",
        },
      ],
      data: null,
      json1: {
        acheBouns: 0,
        area: "",
        areaId: "",
        bankCardNum: "",
        bankCode: "",
        bankName: "",
        bankProvince: "",
        bankProvinceId: "",
        bouns: 0,
        city: "",
        cityId: "",
        compensation: 0,
        contractType: 0,
        createTime: "",
        createTimes: 0,
        cumulativeChild: 0,
        cumulativeHousingLoan: 0,
        cumulativeNextTeach: 0,
        cumulativeProvident: 0,
        cumulativeRent: 0,
        cumulativeSalary: 0,
        cumulativeSupport: 0,
        cumulativeTax: 0,
        cumulativeTaxAmt: 0,
        cumulativeThreshold: 0,
        deSalary: 0,
        deductionChild: 0,
        deductionTeach: 0,
        endBouns: 0,
        enterpriseId: "",
        head: "",
        health: 0,
        healthAmt: 0,
        houseRent: 0,
        housingLoan: 0,
        idNum: "",
        identity: 0,
        isCompen: 0,
        isDel: 0,
        isFinish: 0,
        isSend: 0,
        isTrue: 0,
        jSalary: 0,
        jobTime: "",
        jobTimes: "",
        kSalary: 0,
        nickName: "",
        oldAve: 0,
        otherSubsidyBouns: 0,
        position: "",
        postName: "",
        provideAged: 0,
        province: "",
        provinceId: "",
        registered: 0,
        salaryStaffId: "",
        salaryStatus: 0,
        salaryTrue: 0,
        stakeIn: 0,
        subsidyId: 0,
        trueSalary: 0,
        userId: "",
      },
      show: false,
      date: this.$config.getDate(),
      jobTimeText: "",
      specialInfo: {},
      status: null,
    };
  },
  props: {
    SalaryStaff: {
      type: Object,
      default() {
        return {};
      },
    },
    employeeId: {
      type: String,
      default() {
        return "";
      },
    },
    jobTime: {
      type: String,
      default() {
        return "";
      },
    },
  },
  mounted() {
    this.data = this.SalaryStaff;
    console.log(this.data);
    this.data.cumulativeTax = (this.data.cumulativeTax * 1).toFixed(2);
    this.data.cumulativeTaxAmt = (this.data.cumulativeTaxAmt * 1).toFixed(2);
    this.data.cumulativeSalary = (this.data.cumulativeSalary * 1).toFixed(2);
    this.data.cumulativeThreshold = (this.data.cumulativeThreshold * 1).toFixed(
      2
    );
    this.data.cumulativeProvident = (this.data.cumulativeProvident * 1).toFixed(
      2
    );

    this.selectIndex = this.data.deductionChild;
    this.jobTimeText = this.jobTime.split(" ")[0];
    // 查询专项
    this.getSpecialInfo();
    return;
    if (this.data.isFinish === 0) {
      this.data.cumulativeSalary =
        this.data.cumulativeSalary === 0 ? "" : this.data.cumulativeSalary;

      this.data.cumulativeThreshold =
        this.data.cumulativeThreshold === 0
          ? ""
          : this.data.cumulativeThreshold;

      this.data.cumulativeProvident =
        this.data.cumulativeProvident === 0
          ? ""
          : this.data.cumulativeProvident;

      this.data.cumulativeChild =
        this.data.cumulativeChild === 0 ? "" : this.data.cumulativeChild;

      this.data.cumulativeNextTeach =
        this.data.cumulativeNextTeach === 0
          ? ""
          : this.data.cumulativeNextTeach;

      this.data.cumulativeRent =
        this.data.cumulativeRent === 0 ? "" : this.data.cumulativeRent;

      this.data.cumulativeHousingLoan =
        this.data.cumulativeHousingLoan === 0
          ? ""
          : this.data.cumulativeHousingLoan;

      this.data.cumulativeSupport =
        this.data.cumulativeSupport === 0 ? "" : this.data.cumulativeSupport;

      this.data.cumulativeTaxAmt =
        this.data.cumulativeTaxAmt === 0 ? "" : this.data.cumulativeTaxAmt;

      this.data.cumulativeTax =
        this.data.cumulativeTax === 0 ? "" : this.data.cumulativeTax;
    }
  },
  methods: {
    getSpecialInfo() {
      this.$store.commit("showLoading", "");
      let param = {
        employeeId: this.employeeId,
        enterpriseId: this.enterpriseId,
        month: this.date.year + "-" + this.date.lastMounth,
      };
      getSpecialInfo(param).then((res) => {
        this.$store.commit("hideLoading", "");
        console.log(res);
        if (res.code === 0) {
          this.specialInfo = res.data;
          this.status = res.data.status;
          this.show = true;
        }
      });
    },
    updateSpecialInfo() {
      if (this.status === 1) return;
      this.$store.commit("showLoading", "");
      let param = {
        employeeId: this.employeeId,
        enterpriseId: this.enterpriseId,
        month: this.date.year + "-" + this.date.lastMounth,
      };
      updateSpecialInfo(param).then((res) => {
        this.$store.commit("hideLoading", "");
        console.log(res);
        if (res.code === 0) {
        }
      });
    },
    rosterClose() {
      // this.$emit("rosterClose");
      this.$emit("update:earmarkedShow", false);
    },
  },
};
</script>

<style lang="less" scoped>
.personPopup {
  width: 480px;
  // height: 628px;
  background: #ffffff;
  opacity: 1;
  border-radius: 10px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -240px;
  margin-top: -220px;
  padding-bottom: 30px;
}
.addStaffPopup-header {
  border-radius: 10px;
  background: #fff;
}
.cumulative-mian {
  width: calc(100% - 120px);
  margin: 15px 60px 20px;
  p {
    font-size: 12px;
    font-weight: 400;
    color: #e74b47;
    line-height: 20px;
    letter-spacing: 1px;
  }
  .cumulative-box {
    height: 44px;
    line-height: 44px;
    border-bottom: 1px solid rgba(219, 219, 219, 0.5);
    font-size: 14px;
    &.show {
      border-bottom: 1px solid rgba(231, 75, 71, 0.5);

      .cumulative-box-l {
        color: #e74b47;
      }
    }
    .cumulative-box-l {
      width: 200px;
      display: inline-block;
      letter-spacing: 1px;
      span {
        font-size: 12px;
        color: #999;
      }
    }
    .cumulative-box-r {
      width: calc(100% - 200px);
      display: inline-block;
      text-align: right;
      input {
        text-align: right;
        width: 100%;
        &::placeholder {
          color: #bbb;
        }
      }
    }
  }
}
.earmarked-footer {
  width: 100%;
  text-align: center;
  .earmarked-btn {
    width: 170px;
    height: 34px;
    line-height: 34px;
    background: #4c8afc;
    border: 1px solid #1a73e8;
    opacity: 1;
    border-radius: 3px;
    display: inline-block;
    font-size: 12px;
    font-weight: bold;
    color: #ffffff;
    cursor: pointer;
    &.statusStyle {
      opacity: 0.5;
    }
  }
}
input[type="number"] {
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  -moz-appearance: textfield;
}
</style>