<template>
  <div class="views-width">
    <div class="views-main">
      <div class="views-mian-header">
        <div class="views-mian-header-left">
          <span style="color: #bbb">
            注：导入花名册，请先下载花名册模板
            <div class="btn-link1">
              <a
                class="download-link1"
                href="http://www.jzcfo.com/download/花名册导入模版.zip"
              >
                <svg-icon icon-class="down2-icon"></svg-icon>下载员工模板</a
              >
            </div>
            <div class="btn-link1" @click="avatarFun()">
              <a class="download-link2" href="javascript:">
                <svg-icon icon-class="down1-icon"></svg-icon>导入员工模板</a
              >
            </div>
          </span>
        </div>
        <div class="views-mian-header-title">导入花名册</div>
        <div class="views-mian-header-right">
          <span style="color: #bbb">
            <div class="btn-link1">
              <a class="download-link1" href="javascript:"> 清空当前数据 </a>
            </div>
            <el-button type="primary" class="btn-primary" @click="saveClick">
              上传至花名册
            </el-button>
          </span>
        </div>
      </div>
      <div
        class="views-mian-scroll"
        v-if="
          showTable &&
          (list1.length > 0 || list2.length > 0 || departmentList.length > 0)
        "
      >
        <div class="export-nav" v-if="departmentList.length > 0">
          <p class="export-nav-tip">注：请先给公司所有部门设置部门职能</p>
          <div class="export-nav-top">
            <div
              class="export-nav-top-box"
              v-for="(e, i) in navList"
              :key="i"
              :class="{ cr: navBox === e.id }"
              @click="navClick(e.id)"
            >
              <p>{{ e.title }}</p>
            </div>
          </div>
          <div class="export-nav-content">
            <slot v-for="(e, i) in departmentList">
              <div
                class="export-nav-content-box"
                v-if="e.select + '' === navBox + '' || e.select + '' === ''"
                :key="i"
              >
                <el-checkbox
                  v-model="e.checkbox"
                  @change="selectClick(e, navBox + '', $event)"
                  >{{ e.departmentName }}</el-checkbox
                >
              </div>
            </slot>
          </div>
          <div class="export-btns">
            <el-button
              type="primary"
              class="export-btn"
              @click="setDeptFunction"
              >保存</el-button
            >
            <!-- <el-button type="info" class="export-btn">取消</el-button> -->
          </div>
        </div>
        <slot v-if="list1.length > 0">
          <p class="export-title">已完善人员</p>
          <div class="views-mian-content" v-if="list1.length > 0">
            <el-table
              :data="list1"
              :header-cell-style="{
                background: '#F4F4F4',
              }"
              :max-height="tableHeight"
              style="width: 100%"
              @selection-change="handleSelectionChange"
              lazy
            >
              <!-- //这里设置表头居中 > -->
              <!-- <el-table-column
              type="selection"
              width="30"
              :selectable="checkSelectable"
            >
            </el-table-column> -->
              <slot v-for="(e, i) in headerList1">
                <ex-table-column
                  :prop="e.field"
                  :label="e.title"
                  align="center"
                  :autoFit="true"
                  :fixed="i === 0 || i === 1 ? 'left' : false"
                  :key="i"
                >
                  <template slot-scope="scope">
                    <!-- :style="{ minWidth: e.width + 'px', }" -->
                    <div
                      class="scope-div"
                      :style="{ width: e.width * 1 + 50 + 'px' }"
                      :title="scope.row[e.field]"
                    >
                      <input
                        type="text"
                        class="scope-input"
                        v-model="scope.row[e.field]"
                      />
                    </div>
                  </template>
                </ex-table-column>
              </slot>
              <ex-table-column label="操作" fixed="right">
                <template slot-scope="scope">
                  <div
                    class="scope-div"
                    style="
                      min-width: 60px;
                      text-align: center;
                      cursor: pointer;
                      color: #4c8afc;
                    "
                    @click="updateImportEmpInfo(scope.row)"
                  >
                    修改
                  </div>
                </template>
              </ex-table-column>
            </el-table>
          </div>
          <div class="pageDiv">
            <el-pagination
              style="margin-top: 20px; text-align: right"
              background
              @size-change="handleSizeChange1"
              @current-change="handleCurrentChange1"
              :current-page="page1.pageNum"
              :page-sizes="[10, 20, 50, 100]"
              :page-size="page1.pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="page1.total"
              >>
            </el-pagination>
          </div>
        </slot>
        <slot v-if="list2.length > 0">
          <p class="export-title">未完善人员</p>
          <div class="views-mian-content">
            <el-table
              :data="list2"
              :header-cell-style="{
                background: '#F4F4F4',
              }"
              :max-height="tableHeight"
              :row-class-name="tableRowClassName"
              style="width: 100%"
              @selection-change="handleSelectionChange1"
              lazy
            >
              <!-- //这里设置表头居中 > -->
              <el-table-column type="selection" width="30">
                <!-- :selectable="checkSelectable" -->
              </el-table-column>
              <slot v-for="(e, i) in headerList1">
                <ex-table-column
                  :prop="e.field"
                  :label="e.title"
                  align="center"
                  :autoFit="true"
                  :fixed="i === 0 || i === 1 ? 'left' : false"
                  :key="i"
                >
                  <template slot-scope="scope">
                    <div
                      class="scope-div"
                      :style="{
                        width: e.width * 1 + 50 + 'px',
                      }"
                      :title="scope.row[e.field]"
                    >
                      <input
                        type="text"
                        class="scope-input"
                        v-model="scope.row[e.field]"
                      />
                    </div>
                  </template>
                </ex-table-column>
              </slot>
              <ex-table-column label="操作" fixed="right">
                <template slot-scope="scope">
                  <div
                    class="scope-div"
                    style="
                      min-width: 60px;
                      text-align: center;
                      cursor: pointer;
                      color: #4c8afc;
                    "
                    @click="updateImportEmpInfo(scope.row)"
                  >
                    修改
                  </div>
                </template>
              </ex-table-column>
            </el-table>
          </div>
          <div class="pageDiv">
            <el-pagination
              style="margin-top: 20px; text-align: right"
              background
              @size-change="handleSizeChange2"
              @current-change="handleCurrentChange2"
              :current-page="page2.pageNum"
              :page-sizes="[10, 20, 50, 100]"
              :page-size="page2.pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="page2.total"
              >>
            </el-pagination>
          </div>
        </slot>
      </div>
      <NoRecord v-else></NoRecord>
    </div>
    <input
      type="file"
      id="avatar"
      @change="uploadEmployee($event)"
      class="uploadInput"
      ref="filElem"
    />
    <exportSelect
      :popupLeft="popupLeft"
      :popupTop="popupTop"
      :item="item"
      :row="row"
      v-if="exportShow"
      @exportParent="exportParent"
    ></exportSelect>
  </div>
</template>

<script>
import {
  importExcel,
  getImportExcelStatus,
  getAllCacheEmpData,
  setDeptFunction,
  getPerfectEmpData,
  getNotPerfectEmpData,
  addToRoster,
  getaddRosterStatus,
  getEmpList,
  updateImportEmpInfo,
} from "@/http/api";
import exportSelect from "./components/exportSelect.vue";
import NoRecord from "@/components/NoRecord.vue";
export default {
  data() {
    return {
      userId: sessionStorage.getItem("userId"),
      enterpriseId: sessionStorage.getItem("enterpriseId"),
      token: sessionStorage.getItem("pc-tkn"),
      mouth: "",
      date: this.$config.getDate(),
      navBox: 11,
      departmentList: [],
      navList: [
        {
          title: "管理后勤服务类",
          id: 11,
        },
        {
          title: "市场销售运营类",
          id: 10,
        },
        {
          title: "产品设计研发类",
          id: 12,
        },
      ],
      tableData: [],
      headerList1: [
        { title: "手机号", field: "mobile", width: 120 },
        { title: "姓名", field: "name", width: 60 },
        { title: "证件号码", field: "idNumber", width: 60 },
        { title: "所属部门", field: "departmentName", width: 60 },
        { title: "岗位名称", field: "postName", width: 60 },
        { title: "岗位工资", field: "postSalary", width: 60 },
        { title: "试用期工资", field: "onTrialTermSalaryScale", width: 80 },
        { title: "绩效奖金", field: "acheBouns", width: 60 },
        { title: "补贴补助", field: "subsidy;", width: 60 },
        { title: "其他补贴", field: "otherSubsidyBouns", width: 60 },
        { title: "年终奖", field: "endBonus", width: 60 },
        { title: "解除合同补偿金", field: "compensation", width: 100 },
        { title: "考勤扣款", field: "kSalary", width: 60 },
        { title: "其他扣款", field: "fine", width: 60 },
        { title: "其他扣款2", field: "fine2", width: 70 },
        { title: "现在居住地-省", field: "contactProvince", width: 120 },
        { title: "现在居住地-市", field: "contactCity", width: 120 },
        { title: "现在居住地-区(县)", field: "contactArea", width: 120 },
        { title: "现居住地详细地址", field: "contactDetail", width: 110 },
        { title: "国籍", field: "nationality", width: 40 },
        { title: "出生国家", field: "bornNation", width: 60 },
        { title: "出生日期", field: "birth", width: 60 },
        { title: "性别", field: "gender", width: 40 },
        { title: "户口性质", field: "registered", width: 60 },
        { title: "户籍所在地-省", field: "province", width: 120 },
        { title: "户籍所在地-市", field: "city", width: 120 },
        { title: "户籍所在地-区(县)", field: "area", width: 120 },
        { title: "户籍所在地详细地址", field: "hujiDetail", width: 130 },
        { title: "学历", field: "education", width: 40 },
        { title: "学校", field: "school", width: 40 },
        { title: "电子邮箱", field: "email", width: 60 },
        { title: "紧急联系人", field: "jinjiLianxiren", width: 70 },
        { title: "紧急联系人电话", field: "jinjiLianxidianhua", width: 110 },
        { title: "合同类型", field: "contractType", width: 60 },
        { title: "合同期限", field: "contractTerm", width: 60 },
        { title: "员工身份", field: "identity", width: 60 },
        { title: "入职日期", field: "jobTime", width: 60 },
        { title: "离职日期", field: "dimissionTime", width: 60 },
        { title: "试用期开始", field: "onTrialTermBegin", width: 70 },
        { title: "试用期结束", field: "onTrialTermEnd", width: 70 },
        { title: "涉税事由", field: "sssy", width: 60 },
        { title: "首次入境时间", field: "rujingTime", width: 90 },
        { title: "预计离境时间", field: "lijingTime", width: 90 },
        { title: "中文名", field: "chinaName", width: 50 },
        // { title: "其他证件类型", field: "", width: 90 },
        // { title: "其他证件号码", field: "", width: 90 },
        { title: "缴纳城市", field: "socialnounAddress", width: 60 },
        { title: "社保基数", field: "socialBasics", width: 120 },
        { title: "社保缴纳时间", field: "socialPayTime", width: 120 },
        { title: "养老-单位", field: "oldCompany", width: 120 },
        { title: "养老 - 个人", field: "oldPerson", width: 120 },
        { title: "医疗(生育) - 单位", field: "medicalPerson", width: 120 },
        { title: "医疗(生育) - 个人", field: "medicalCompany", width: 120 },
        {
          title: "大病统筹（元）- 个人",
          field: "medicalFujiaPerson",
          width: 140,
        },
        { title: "失业 - 单位", field: "jobCompany", width: 70 },
        { title: "失业 - 个人", field: "jobPerson", width: 70 },
        { title: "工伤 - 单位", field: "industrialCompany", width: 70 },
        { title: "工伤 - 个人", field: "industrialPerson", width: 70 },
        { title: "其他 - 单位", field: "otherCompany", width: 70 },
        { title: "其他 - 个人", field: "otherPerson", width: 70 },
        { title: "公积金缴纳时间", field: "providentPayTime", width: 120 },
        { title: "公积金基数", field: "providentBasics", width: 60 },
        { title: "公积金 - 单位", field: "providentCompany", width: 60 },
        { title: "公积金 - 个人", field: "providentPerson", width: 60 },
        { title: "银行卡号", field: "bankCardNum", width: 100 },
        { title: "所属银行", field: "bankName", width: 60 },
        { title: "开户行所在省份", field: "bankProvince", width: 100 },
      ],
      popupTop: "",
      popupBottom: "",
      popupLeft: "",
      exportShow: false,
      showTable: false,
      item: {},
      row: {},
      scope: {},
      tableHeight: 0,
      page1: {
        pageNum: 1,
        pageSize: 15,
        total: 0,
      },
      page2: {
        pageNum: 1,
        pageSize: 15,
        total: 0,
      },
      list1: [],
      list2: [],
      multipleSelection1: [],
      multipleSelection2: [],
    };
  },
  components: {
    NoRecord,
    exportSelect,
  },
  mounted() {
    this.tableHeight = document.body.clientHeight - 270;
    // this.mouth = this.date.year + "-" + this.date.thisMounth;
    this.departmentList = [];
    //   this.departmentList.push({
    //     id: index + 1,
    //     departmentName: "部门" + (index + 1),
    //     select: "",
    //     checkbox: false,
    //   });
    // for (let index = 0; index < 45; index++) {
    //   this.departmentList.push({
    //     id: index + 1,
    //     departmentName: "部门" + (index + 1),
    //     select: "",
    //     checkbox: false,
    //   });
    // }
    // this.getEmpList();
    this.getaddRosterStatus();
  },

  methods: {
    editClick(row) {},
    handleSizeChange1(val) {
      console.log(`每页 ${val} 条`);
      this.page1.pageSize = val;
      this.getPerfectEmpData();
    },
    handleCurrentChange1(val) {
      console.log(`当前页: ${val}`);
      this.page1.pageNum = val;
      this.getPerfectEmpData();
    },
    handleSizeChange2(val) {
      console.log(`每页 ${val} 条`);
      this.page2.pageSize = val;
      this.getNotPerfectEmpData();
    },
    handleCurrentChange2(val) {
      console.log(`当前页: ${val}`);
      this.page2.pageNum = val;
      this.getNotPerfectEmpData();
    },
    saveClick() {
      if (this.departmentList.length > 0) {
        this.$store.commit("MyMessage", "请先保存部门信息");
        return false;
      }
      this.addToRoster();
    },
    checkSelectable(row) {
      return row.infoFlag === 1;
    },
    handleSelectionChange(val) {
      this.multipleSelection1 = val;
    },
    handleSelectionChange1(val) {
      this.multipleSelection2 = val;
    },
    tableRowClassName({ row, rowIndex }) {
      if (row.infoFlag === 0) {
        return "salaryStatus1";
      }
      return "";
    },
    setDeptFunction() {
      let flag = false;
      // [
      //   {
      //     departmentFuncitonType: 0,
      //     departmentName: "string",
      //     enterpriseId: "string",
      //     rdDirection: 0,
      //     userId: "string",
      //   },
      // ];
      let list = [];
      this.departmentList.forEach((e) => {
        if (e.select === "") {
          flag = true;
          return false;
        } else {
          list.push({
            userId: this.userId,
            enterpriseId: this.enterpriseId,
            departmentName: e.departmentName,
            departmentFuncitonType: e.select,
            rdDirection: 10,
          });
        }
      });
      if (flag) {
        this.$store.commit("MyMessage", "还有部门未设置部门职能");
        return false;
      }
      console.log(this.departmentList);
      let param = {
        userId: this.userId,
        enterpriseId: this.enterpriseId,
        list: list,
      };
      setDeptFunction(param).then((res) => {
        console.log(res);
      });
    },
    getImportExcelStatus() {
      // importExcel
      let param = {
        enterpriseId: this.enterpriseId,
        userId: this.userId,
      };
      // console.log(111);
      getImportExcelStatus(param).then((res) => {
        console.log(res);
        if (res.code === 0) {
          if (res.data === "1") {
            setTimeout(() => {
              this.getImportExcelStatus();
            }, 5000);
          } else if (res.data === "2") {
            this.getAllCacheEmpData();
          }
        }
      });
    },
    addToRoster() {
      let param = {
        enterpriseId: this.enterpriseId,
        userId: this.userId,
      };
      addToRoster(param).then((res) => {
        console.log(res);
        if (res.code === 0) {
          this.getaddRosterStatus();
        }
      });
    },
    updateImportEmpInfo(item) {
      let list = [item];
      let param = {
        enterpriseId: this.enterpriseId,
        userId: this.userId,
        employees: list,
      };
      updateImportEmpInfo(param).then((res) => {
        console.log(res);
        if (res.code === 0) {
          this.getAllCacheEmpData();
        }
      });
    },
    getaddRosterStatus() {
      let param = {
        enterpriseId: this.enterpriseId,
        userId: this.userId,
      };
      getaddRosterStatus(param).then((res) => {
        console.log(res);
        if (res.code === 0) {
          if (res.data !== "2") {
            if (res.data !== -2) {
              setTimeout(() => {
                this.getaddRosterStatus();
              }, 5000);
            } else {
              this.getAllCacheEmpData();
            }
          } else {
            this.getAllCacheEmpData();
            // this.$store.commit("MyMessage", "导入成功!");
          }
        }
      });
    },
    getPerfectEmpData() {
      // importExcel
      let param = {
        enterpriseId: this.enterpriseId,
        userId: this.userId,
        pageNum: this.page1.pageNum,
        pageSize: this.page1.pageSize,
      };
      // console.log(111);
      getPerfectEmpData(param).then((res) => {
        console.log(res);
        if (res.code === 0) {
          this.list1 = res.data.perfectEmpList;
          this.page1.total = res.data.perfectEmpTotal;
        }
      });
    },
    getNotPerfectEmpData() {
      // importExcel
      let param = {
        enterpriseId: this.enterpriseId,
        userId: this.userId,
        pageNum: this.page2.pageNum,
        pageSize: this.page2.pageSize,
      };
      // console.log(111);
      getNotPerfectEmpData(param).then((res) => {
        console.log(res);
        if (res.code === 0) {
          this.list2 = res.data.notPerfectEmpList;
          this.page2.total = res.data.notPerfectEmpTotal;
        }
      });
    },
    getAllCacheEmpData() {
      // importExcel
      let param = {
        enterpriseId: this.enterpriseId,
        userId: this.userId,
        pageNum: this.page1.pageNum,
        pageSize: this.page1.pageSize,
      };
      getAllCacheEmpData(param).then((res) => {
        // console.log(res);
        if (res.code === 0) {
          // territoryEmpData   境内
          // abroadEmpData   境外
          // departmentData   部门

          this.departmentList = [];
          res.data.departmentData.forEach((e, i) => {
            this.departmentList.push({
              id: i + 1,
              departmentName: e,
              select: "",
              checkbox: false,
            });
          });
          // this.tableData = [];
          this.list1 = res.data.perfectEmpList;
          this.page1.total = res.data.perfectEmpTotal;
          this.list2 = res.data.notPerfectEmpList;
          this.page2.total = res.data.notPerfectEmpTotal;
          // res.data.employeeData.forEach((e, i) => {
          //   if (i < 10) {
          //     this.tableData.push(e);
          //   }
          // });
          // this.tableData = res.data.employeeData;
          this.showTable = true;
        }
      });
    },
    avatarFun() {
      this.$refs.filElem.dispatchEvent(new MouseEvent("click"));
    },
    uploadEmployee(e) {
      let that = this;
      const avatar = document.getElementById("avatar");
      avatar.onchange = (e) => {
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (e) => {};
      };
      if (!avatar.files[0]) {
        this.$store.commit("MyMessage", "请选择文件!");
        return false;
      }
      const formData = new FormData();
      formData.append("file", avatar.files[0]);
      formData.append("userId", that.userId);
      formData.append("enterpriseId", that.enterpriseId);
      const xhr = new XMLHttpRequest();
      xhr.onreadystatechange = (data) => {
        if (xhr.readyState === 4 && xhr.status === 200) {
          let res = JSON.parse(xhr.responseText);
          console.log(res);
          if (res.code === 0) {
            // Toast("上传成功");
            that.getImportExcelStatus();
          } else {
            console.log(res.msg);
          }
        }
      };
      xhr.open("POST", "http://192.168.3.28:28300/batchimport/importExcel");
      xhr.setRequestHeader("token", that.token);
      xhr.send(formData);
      e.target.value = "";
    },
    exportParent(item) {
      this.exportShow = false;
      this.tableData[this.scope.$index][this.item.field] = item.value;
      this.scope = {};
      this.item = {};
      this.$forceUpdate();
    },
    scopeClick(e, row, item, scope) {
      this.exportShow = false;
      this.scope = scope;
      this.item = item;
      this.row = row;
      if (item.type === "select") {
        this.$nextTick(() => {
          this.exportShow = true;
          this.popupLeft = e.pageX - 100;
          this.popupTop = e.pageY + 20;
        });
      }
    },
    navClick(index) {
      this.navBox = index;
    },

    selectClick(item, index, value) {
      console.log(item, index, value);
      if (value) {
        item.select = this.navBox;
      } else {
        item.select = "";
      }
    },
  },
};
</script>
<style lang="less" scoped>
.views-mian-scroll {
  height: calc(100% - 106px);
}
.views-mian-header-left {
  font-weight: 400;
  span {
    margin-left: 0;
  }
}
.views-mian-header,
.views-mian-content {
  width: calc(100% - 60px) !important;
  min-width: 1224px;
}
.pageDiv {
  width: calc(100% - 60px) !important;
  min-width: 1224px;
  margin: 0 auto;
}
.views-mian-header {
  padding: 20px 0;
  margin: 0 auto;
  border-bottom: 1px solid #dbdbdb;
  .views-mian-header-title {
    line-height: 76px;
  }
}
.export-nav {
  width: calc(100% - 60px);
  min-width: 1224px;
  margin: 10px 30px 10px;
  .export-nav-tip {
    color: #bbb;
    margin-bottom: 10px;
  }
  .export-nav-top {
    width: 100%;
    height: 32px;
    border-bottom: 1px solid #dbdbdb;
    .export-nav-top-box {
      display: inline-block;
      width: 125px;
      text-align: center;
      line-height: 32px;
      font-size: 12px;
      font-weight: 400;
      color: #262626;
      height: 100%;
      cursor: pointer;
      &.cr {
        p {
          border-left: 1px solid #dbdbdb;
          border-right: 1px solid #dbdbdb;
          border-top: 1px solid #dbdbdb;
          height: 32px;
          background: #fff;
        }
      }
    }
  }
  .export-nav-content {
    border-left: 1px solid #dbdbdb;
    border-right: 1px solid #dbdbdb;
    border-bottom: 1px solid #dbdbdb;
    width: calc(100% - 40px);
    min-height: 40px;
    padding: 15px 20px;
    margin-bottom: 10px;
    .export-nav-content-box {
      display: inline-block;
      margin-right: 34px;
      margin-bottom: 10px;
    }
    .export-btns {
      padding-top: 15px;
      .export-btn {
        width: 57px;
        height: 24px;
        opacity: 1;
        border-radius: 3px;
        line-height: 24px;
        padding: 0;
        font-size: 12px;
      }
    }
  }
}
.views-mian-content {
  border: 1px solid #dbdbdb;
  margin-top: 10px;
}
.export-title {
  width: calc(100% - 60px);
  min-width: 1224px;
  margin: 20px 30px 0;
  font-size: 12px;
}
.btn-primary {
  margin-left: 10px;
}
.btn-link1 {
  padding: 0 20px;
  display: inline-block;
  height: 34px;
  line-height: 34px;
  cursor: pointer;
  background: #ffffff;
  border: 1px solid #dbdbdb;
  font-size: 14px;
  font-weight: 400;
  color: #4c8afc;
  margin-left: 15px;
  border-radius: 3px;

  .download-link1 {
    color: #262626;
  }
  .download-link2 {
    color: #4c8afc;
  }
}
.scope-div {
  width: 100%;
  height: 100%;
  cursor: pointer;
  min-height: 20px;
  .scope-input {
    text-align: center;
    width: 100%;
    background: none;
    cursor: pointer;
  }
}
.export-popup {
  position: fixed;
  width: 100px;
  height: 200px;
  background: #fff;
  box-shadow: 0 0 3px rgba(38, 38, 38, 0.5);
}
.uploadInput {
  position: absolute;
  width: 0;
  height: 0;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: -999;
}
</style>
<style lang="less">
.views-mian-content .el-table__body-wrapper td {
  border-right: 1px solid #ebeef5;
}
.views-mian-content .el-table .el-table__header tr th {
  border-right: 1px solid #ebeef5;
  text-align: center;
}
.views-mian-content {
  .el-table .cell {
    white-space: nowrap;
    width: fit-content;
    margin: 0 auto;
    padding: 0 5px !important;
    span {
      display: inline-block;
    }
  }
}
.salaryStatus1 {
  // background: #f5eaea !important;
  // .cell {
  //   font-size: 14px;
  //   color: rgba(237, 73, 86, 0.6) !important;
  //   // span {
  //   //   color: #f58e21 !important;
  //   // }
  // }
}
.hover_row {
  &.salaryStatus1 {
    // background: #f5eaea !important;
  }
}
</style>