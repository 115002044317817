<template>
  <div class="popup-layer">
    <div class="popup-layer-bg" @click="approvalClose"></div>
    <div class="invitation-main">
      <div class="banklist-main">
        <p class="payment-p4">选择个税付款方式</p>

        <el-select
          v-model="value"
          class="selectStyle"
          @change="selectChange()"
          placeholder="请选择"
        >
          <el-option
            v-for="item in selectList"
            :key="item.bankcardId"
            :label="item.bankName"
            :value="item.bankcardId"
          >
          </el-option>
        </el-select>
        <p class="payment-p1">{{ mouth.split("-")[1] * 1 }}月个税总额</p>
        <p class="payment-p2"><span>¥</span>{{ taxTotal }}</p>
        <p class="payment-p3">请保证账户余额充足</p>
      </div>
      <div class="payment-main">
        <!-- <p class="payment-p5">注：一旦申报个税，工资表将无法修改。</p> -->
        <div class="payment-btns" style="margin-top: 35px">
          <!-- <el-button plain @click="invitationShow = false">取消</el-button> -->
          <el-button type="primary" style="width: 279px" @click="submitBtn">
            <svg-icon
              icon-class="geshuishenbao"
              style="font-size: 20px; position: relative; left: -5px; top: 2px"
            ></svg-icon
            >申报个税</el-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getEmployeeFukuan,
  getEnterpriseEmployeeCheckers,
  submmitSalaryCheck,
  queryEnterpriseInfoForUser,
  queryBaoshuiamount,
  salaryserviceBaoshui,
} from "@/http/api";
export default {
  data() {
    return {
      userId: sessionStorage.getItem("userId"),
      enterpriseId: sessionStorage.getItem("enterpriseId"),
      payment: this.$dictionaries.payment,
      adminId: "",
      selectList: [],
      value: "",
      // taxTotal: "",
    };
  },
  mounted() {
    this.queryEnterpriseInfoForUser();
    // this.queryBaoshuiamount();
  },
  props: {
    mouth: {
      type: String,
      default() {
        return null;
      },
    },
    taxTotal: {
      type: String,
      default() {
        return null;
      },
    },
  },
  mounted() {
  },
  methods: {
    approvalClose() {
      this.$emit("approvalClose");
    },
    submitBtn() {
      if (!this.value) {
        this.$store.commit("MyMessage", "请先选择付款方式");
        return false;
      }
      let param = {
        enterpriseId: this.enterpriseId,
        month: this.mouth,
        payType: this.value,
      };
      if (this.value === "11" || this.value === "12") {
        param.bankCardId = this.value;
      } else {
        param.bankCardId = this.value;
        param.payType = "10";
      }
      salaryserviceBaoshui(param).then((res) => {
        // console.log(res);
        if (res.code === 0) {
          this.$store.commit("MyMessage", "申报成功");
          this.invitationShow = false;
          location.reload();
        } else {
          this.$store.commit("MyMessage", res.msg);
        }
      });
    },
    selectChange() {},
    queryEnterpriseInfoForUser() {
      let param = {
        enterpriseId: this.enterpriseId,
        userId: this.userId,
      };
      queryEnterpriseInfoForUser(param).then((res) => {
        // console.log("queryEnterpriseInfo", res);
        this.adminId = res.data.adminId;
        this.selectList = res.data.bankList;
        if (this.selectList.length > 0) {
          this.value = this.selectList[0].bankcardId;
        }
        this.payment.forEach((element) => {
          this.selectList.push(element);
        });
      });
    },
    queryBaoshuiamount() {
      // 查询报税金额
      let param = {
        enterpriseId: this.enterpriseId,
        month: this.mouth,
      };
      queryBaoshuiamount(param).then((res) => {
        // this.taxTotal = res.data.taxTotal;
      });
    },
  },
};
</script>

<style lang="less" scoped>
.popup-layer {
  text-align: center;
}
.invitation-main {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 400px;
  margin-left: -200px;
  background: #fff;
  border-radius: 5px;
  height: 350px;
  margin-top: -280px;
}
.banklist-main {
  width: 90%;
  margin: 0 auto;
}
.payment-p1 {
  font-size: 15px;
  font-weight: bold;
  color: #262626;
  margin-top: 20px;
}
.payment-p2 {
  height: 50px;
  line-height: 50px;
  font-size: 36px;
  font-family: PingFang SC;
  font-weight: bold;
  line-height: 67px;
  color: #262626;
  span {
    color: #262626;
    font-size: 17px;
  }
}
.payment-p3 {
  font-size: 12px;
  font-weight: 400;
  line-height: 28px;
  color: #bbbbbb;
}
.payment-p4 {
  font-size: 12px;
  line-height: 28px;
  margin-top: 20px;
  text-align: left;
  font-weight: 400;
  color: #999999;
}
</style>
<style lang="less">
.selectStyle {
  width: 100%;
  .el-input__inner {
    border: none;
    border-radius: 0;
    border-bottom: 1px dashed;
    padding: 0;
  }
  .el-select__caret {
    color: #4c8afc !important;
    width: 12px;
    font-weight: bold;
  }
}
</style>