<template>
  <div class="personDetails-content-main" v-if="show">
    <div class="main-list" v-if="addStaffShow">
      <slot v-for="(e, i) in employee.list1">
        <staffBox
          :key="'list1-' + i"
          :item="e"
          index1="0"
          :index2="i"
          :ajaxData="enterpriseEmployee"
          :staffType="staffType"
          @staffParent="staffParent"
        ></staffBox>
      </slot>
      <slot v-if="enterpriseEmployee.idType + '' !== '1'">
        <slot v-for="(e, i) in employee.list6">
          <staffBox
            :key="'list6-' + i"
            :item="e"
            index1="0"
            :index2="i"
            :ajaxData="enterpriseEmployee"
            :staffType="staffType"
            @staffParent="staffParent"
          ></staffBox>
        </slot>
      </slot>
      <slot v-else>
        <slot v-for="(e, i) in employee.list7">
          <staffBox
            :key="'list7-' + i"
            :item="e"
            index1="0"
            :index2="i"
            :ajaxData="enterpriseEmployee"
            :staffType="staffType"
            @staffParent="staffParent"
          ></staffBox>
        </slot>
      </slot>
      <slot v-for="(e, i) in employee.list5">
        <staffBox
          :key="'list5-' + i"
          :item="e"
          index1="0"
          :index2="i"
          :ajaxData="enterpriseEmployee"
          :staffType="staffType"
          @staffParent="staffParent"
        ></staffBox>
      </slot>
    </div>
    <div class="main-list">
      <slot v-for="(e, i) in employee.list2">
        <staffBox
          :key="'list2-' + i"
          :item="e"
          index1="0"
          :index2="i"
          :ajaxData="enterpriseEmployee"
          :staffType="staffType"
          @staffParent="staffParent"
        ></staffBox>
      </slot>

      <slot v-if="enterpriseEmployee.idType + '' !== '1'">
        <slot v-for="(e, i) in employee.shewai">
          <p style="padding: 70px 0 18px" v-if="i === 0" :key="'shewai--' + i">
            涉税事项(必填)
          </p>
          <staffBox
            :key="'shewai-' + i"
            :item="e"
            index1="0"
            :index2="i"
            :ajaxData="enterpriseEmployee"
            :staffType="staffType"
            @staffParent="staffParent"
          ></staffBox>
        </slot>
      </slot>
    </div>
    <div class="main-list-line"></div>
    <div class="main-list main-list-optional">
      <slot v-for="(e, i) in employee.list3">
        <staffBox
          :key="'list3-' + i"
          :item="e"
          index1="0"
          :index2="i"
          :ajaxData="enterpriseEmployee"
          :staffType="staffType"
          @staffParent="staffParent"
        ></staffBox>
      </slot>
      <slot v-if="enterpriseEmployee.idType + '' !== '1'">
        <slot v-for="(e, i) in employee.list8">
          <slot v-if="e.field === 'chinaName'">
            <slot v-if="enterpriseEmployee.idType + '' === '5'">
              <staffBox
                :key="'list8-' + i"
                :item="e"
                index1="0"
                :index2="i"
                :ajaxData="enterpriseEmployee"
                :staffType="staffType"
                @staffParent="staffParent"
              ></staffBox
            ></slot>
          </slot>
          <slot v-else>
            <staffBox
              :key="'list8-' + i"
              :item="e"
              index1="0"
              :index2="i"
              :ajaxData="enterpriseEmployee"
              :staffType="staffType"
              @staffParent="staffParent"
            ></staffBox>
          </slot>
        </slot>
      </slot>
    </div>
    <div class="main-list main-list-optional">
      <slot v-for="(e, i1) in employee.list4">
        <!-- <staffBox
          :key="'list4-' + i"
          :item="e"
          index1="0"
          :index2="i"
          :ajaxData="enterpriseEmployee"
          :staffType="staffType"
          @staffParent="staffParent"
        ></staffBox> -->
        <slot v-if="e.field === 'disabilityNum'">
          <staffBox
            v-if="employee.list4[i1 - 1].value === '1'"
            :key="'info2-' + i1"
            :item="e"
            index1="0"
            :index2="i1"
            :ajaxData="enterpriseEmployee"
            :staffType="staffType"
            @staffParent="staffParent"
          ></staffBox>
        </slot>
        <slot v-else-if="e.field === 'martyrFamilyNum'">
          <staffBox
            v-if="employee.list4[i1 - 1].value === '1'"
            :key="'info2-' + i1"
            :item="e"
            index1="0"
            :index2="i1"
            :ajaxData="enterpriseEmployee"
            :staffType="staffType"
            @staffParent="staffParent"
          ></staffBox>
        </slot>
        <slot v-else>
          <staffBox
            :key="'info2-' + i1"
            :item="e"
            index1="0"
            :index2="i1"
            :ajaxData="enterpriseEmployee"
            :staffType="staffType"
            @staffParent="staffParent"
          ></staffBox>
        </slot>
      </slot>
    </div>
    <div class="popup-layer" v-if="checkFlag">
      <div class="popup-layer-bg" @click="reportClose"></div>
      <div class="huifuPopup zhizuoPopup">
        <div class="popup-layer-close" @click="reportClose">
          <svg-icon icon-class="popup-close"></svg-icon>
        </div>
        <div class="huifuPopup-content">
          <p class="huifuPopup-p2">基本信息报送中，无法进行修改</p>
          <!-- <p class="huifuPopup-p2">如已制作，请到工资记录里查看</p> -->
          <div class="huifuPopup-btns">
            <!-- <div class="huifuPopup-btn-l" @click="reportClose">取 消</div> -->
            <div class="huifuPopup-btn-r" @click="reportClose">我知道了</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import bus from "@/util/bus";
import {
  getEmployeeInfo,
  queryEnterpriseDepartment,
  dimission,
  prefectEnterpriseEmployee,
  modifyEnterpriseEmployee,
  getEmployeeInfoUserId,
} from "@/http/api";
import staffBox from "./jibenBox/staffBox.vue";
export default {
  data() {
    return {
      value: "",
      employee: this.$config.cloneObj(this.$dictionaries.gzList),
      staffType: "details",
      userId: "",
      enterpriseEmployee: {},
      enterpriseId: sessionStorage.getItem("enterpriseId"),
      show: false,
      addStaffShow: false,
      checkFlag: false,
    };
  },
  components: {
    staffBox,
  },
  props: {
    navIndex: {
      type: Number,
      default() {
        return 0;
      },
    },
    SalaryStaff: {
      type: Object,
      default() {
        return {};
      },
    },
    ajaxData: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  mounted() {
    // this.queryEnterprieDepartment();
  },
  watch: {
    navIndex(newValue, oldValue) {
      this.init();
    },
  },
  methods: {
    reportClose() {
      this.gotoFun();
    },
    init() {
      if (this.navIndex === 3) {
        let _route = this.$route.query;
        this.userId = _route.user;
        if (_route.user) {
          this.getEmployeeInfo(_route.user);
        }
        let that = this;
        bus.$off("jbxx");
        bus.$on("jbxx", function () {
          that.save();
        });
      }
    },
    save() {
      if (this.enterpriseEmployee.ptsControInfo.baosongStatus !== 2) {
        this.checkFlag = true;
        return false;
      }
      if (
        this.enterpriseEmployee.dimissionTime !== "" &&
        this.enterpriseEmployee.dimissionTime
      ) {
        this.dimission(this.enterpriseEmployee);
        return;
      }
      this.enterpriseEmployee.mobile = this.enterpriseEmployee.mobile.replace(
        /\s+/g,
        ""
      );
      var jmz = {};
      jmz.GetLength = function (str) {
        return str.replace(/[\u0391-\uFFE5]/g, "aa").length; //先把中文替换成两个字节的英文，在计算长度
      };
      let length1 = jmz.GetLength(this.enterpriseEmployee.name);
      // if (!this.enterpriseEmployee.name) {
      //   bus.$emit("name", true);
      //   this.$store.commit("MyMessage", "请输入姓名");
      //   return false;
      // } else if (length1 < 4) {
      //   bus.$emit("name", true);
      //   this.$store.commit("MyMessage", "姓名长度不符合规则");
      //   return false;
      // }
      // if (!this.enterpriseEmployee.mobile) {
      //   bus.$emit("mobile", true);
      //   this.$store.commit("MyMessage", "请输入常用手机号");
      //   return false;
      // }
      // const regMobile = /^1(3|4|5|6|7|8|9)\d{9}$/;
      // if (!regMobile.test(this.enterpriseEmployee.mobile)) {
      //   bus.$emit("mobile", true);
      //   this.$store.commit("MyMessage", "手机号不符合规则");
      //   return false;
      // }
      if (!this.enterpriseEmployee.postName) {
        bus.$emit("postName", true);
        this.$store.commit("MyMessage", "请输入岗位名称");
        return false;
      }
      if (!this.enterpriseEmployee.departmentId) {
        bus.$emit("departmentId", true);
        this.$store.commit("MyMessage", "请选择所属部门");
        return false;
      }
      if (!this.enterpriseEmployee.contactProvinceId) {
        bus.$emit("contactProvince", true);
        this.$store.commit("MyMessage", "请选择现居住地");
        return false;
      }
      if (!this.enterpriseEmployee.contactDetail) {
        bus.$emit("contactDetail", true);
        this.$store.commit("MyMessage", "请输入现居住详细地址");
        return false;
      }
      if (this.enterpriseEmployee.idType === 0) {
        this.enterpriseEmployee.idType = 1;
      }
      if (
        this.enterpriseEmployee.idType + "" === "1" ||
        this.enterpriseEmployee.idType + "" === "0"
      ) {
        if (!this.enterpriseEmployee.idNumber) {
          this.$store.commit("MyMessage", "证件号码未完善");
          bus.$emit("idNumber", true);
          return false;
        }
        let testId = this.testid(this.enterpriseEmployee.idNumber);
        if (testId.status === 0) {
          bus.$emit("idNumber", true);
          this.$store.commit("MyMessage", "身份证格式有误，请重新填写");
          return false;
        }

        if (this.enterpriseEmployee.idType + "" != "1") {
          if (!this.enterpriseEmployee.nationality) {
            this.$store.commit("MyMessage", "国籍未完善");
            return;
          }
        }
      }
      if (this.enterpriseEmployee.idType + "" === "3") {
        if (
          this.enterpriseEmployee.idNumber.length === 9 ||
          this.enterpriseEmployee.idNumber.length === 11
        ) {
        } else {
          this.$store.commit("MyMessage", "证件号码未完善");
          bus.$emit("idNumber", true);
          return false;
        }
      }
      if (this.enterpriseEmployee.idType + "" === "4") {
        if (this.enterpriseEmployee.idNumber.length === 8) {
        } else {
          this.$store.commit("MyMessage", "证件号码未完善");
          bus.$emit("idNumber", true);
          return false;
        }
      }
      if (
        this.enterpriseEmployee.idType !== 1 &&
        this.enterpriseEmployee.idType !== 0
      ) {
        let sw = this.employee.shewai;
        for (let i = 0; i < sw.length; i++) {
          if (sw[i].require) {
            if (!sw[i].value) {
              this.$store.commit("MyMessage", sw[i].tips + sw[i].text);
              bus.$emit(sw[i].field, true);
              return false;
            }
          }
        }

        let list7 = this.employee.list7;
        for (let i = 0; i < list7.length; i++) {
          if (list7[i].require) {
            if (
              !this.enterpriseEmployee[list7[i].field] ||
              this.enterpriseEmployee[list7[i].field] === -1
            ) {
              this.$store.commit("MyMessage", list7[i].tips + list7[i].text);
              bus.$emit(list7[i].field, true);
              return false;
            }
          }
        }
      } else {
        let list6 = this.employee.list6;
        for (let i = 0; i < list6.length; i++) {
          if (list6[i].require) {
            if (
              !this.enterpriseEmployee[list6[i].field] ||
              this.enterpriseEmployee[list6[i].field] === -1
            ) {
              this.$store.commit("MyMessage", list6[i].tips + list6[i].text);
              bus.$emit(list6[i].field, true);
              return false;
            }
          }
        }
      }
      let list1 = this.employee.list1;
      for (let i = 0; i < list1.length; i++) {
        if (list1[i].require) {
          if (
            !this.enterpriseEmployee[list1[i].field] ||
            this.enterpriseEmployee[list1[i].field] === -1
          ) {
            this.$store.commit("MyMessage", list1[i].tips + list1[i].text);
            bus.$emit(list1[i].field, true);
            return false;
          }
        }
      }
      let list2 = this.employee.list2;
      for (let i = 0; i < list2.length; i++) {
        if (list2[i].require) {
          if (list2[i].field === "onTrialTermBegin") {
            if (!this.enterpriseEmployee.onTrialTermBegin) {
              console.log(this.enterpriseEmployee.onTrialTermBegin);
              this.$store.commit("MyMessage", list2[i].tips + list2[i].text);
              bus.$emit(list2[i].field, true);
              return false;
            }
          } else if (!list2[i].value) {
            this.$store.commit("MyMessage", list2[i].tips + list2[i].text);
            bus.$emit(list2[i].field, true);
            return false;
          }
        }
      }
      let list5 = this.employee.list5;
      for (let i = 0; i < list5.length; i++) {
        if (list5[i].require) {
          if (
            !this.enterpriseEmployee[list5[i].field] ||
            this.enterpriseEmployee[list5[i].field] === -1
          ) {
            this.$store.commit("MyMessage", list5[i].tips + list5[i].text);
            bus.$emit(list5[i].field, true);
            return false;
          }
        }
      }
      if (this.enterpriseEmployee.isOnterm === 0) {
        this.enterpriseEmployee.onTrialTermSalaryScale =
          this.enterpriseEmployee.postSalary;
      }

      if (this.enterpriseEmployee.jobTime) {
        this.enterpriseEmployee.jobTime =
          this.enterpriseEmployee.jobTime.split(" ")[0];
      }
      this.enterpriseEmployee.ptsControInfo = {};
      this.$store.commit("showLoading", "");
      if (this.enterpriseEmployee.userId === this.userId) {
        // * 完善员工信息[自己给自己]
        this.prefectEnterpriseEmployee(this.enterpriseEmployee);
      } else {
        this.modifyEnterpriseEmployee(this.enterpriseEmployee);
      }
    },
    prefectEnterpriseEmployee(enterpriseEmployee) {
      prefectEnterpriseEmployee(enterpriseEmployee).then((res) => {
        this.$store.commit("hideLoading", "");
        // console.log(res);
        if (res.code === 0) {
          this.getEmployeeInfoUserId();
          setTimeout(() => {
            this.gotoFun();
            // window.close();
            this.$store.commit("MyMessage", "修改成功");
          }, 500);
        } else {
          this.$store.commit("MyMessage", res.msg);
        }
      });
    },
    modifyEnterpriseEmployee(enterpriseEmployee) {
      let date = {
        enterpriseEmployeeVo3: enterpriseEmployee,
        userId: this.userId,
        enterpriseId: this.enterpriseId,
        employeeId: enterpriseEmployee.employeeId,
      };
      modifyEnterpriseEmployee(date).then((res) => {
        this.$store.commit("hideLoading", "");
        if (res.code === 0) {
          setTimeout(() => {
            this.gotoFun();
            // window.close();
            this.$store.commit("MyMessage", "修改成功");
          }, 500);
        } else {
          this.$store.commit("MyMessage", res.msg);
        }
      });
    },
    getEmployeeInfoUserId() {
      let param = {
        enterpriseId: this.enterpriseId,
        userId: this.userId,
      };
      getEmployeeInfoUserId(param).then((res) => {
        if (res.code === 0) {
          sessionStorage.setItem("infoUser", JSON.stringify(res));
        }
      });
    },
    gotoFun(path) {
      this.$router.go(-1); //返回上一层
    },
    dimission(enterpriseEmployee) {
      let date = {
        userId: this.userId,
        enterpriseId: this.enterpriseId,
        employeeId: enterpriseEmployee.employeeId,
        dimissionTime: enterpriseEmployee.dimissionTime,
      };
      dimission(date).then((res) => {
        // console.log(res);
        if (res.code === 0) {
          // this.rosterClose();
          // window.close();
          this.$store.commit("MyMessage", "操作成功");
          setTimeout(() => {
            // this.tishiFlag = true;
          }, 500);
        } else {
          this.$store.commit("MyMessage", res.msg);
        }
      });
    },
    // 部门列表
    queryEnterprieDepartment() {
      let that = this;
      let param = {
        userId: sessionStorage.getItem("userId"),
        enterpriseId: that.enterpriseId,
      };
      queryEnterpriseDepartment(param).then((res) => {
        if (res.code == 0) {
          let list = res.data;
          sessionStorage.setItem("departmentList", JSON.stringify(list));

          // 获取数据后进行赋值
          this.assignmentFun();
          this.show = true;
          this.$forceUpdate();
        }
      });
    },
    // 获取员工信息
    getEmployeeInfo(uid) {
      let param = {
        enterpriseId: this.enterpriseId,
        userId: uid,
        employeeId: this.SalaryStaff.employeeId,
      };
      getEmployeeInfo(param).then((res) => {
        // console.table(res.data);
        if (res.code === 0) {
          if (res.data.jobTime) {
            res.data.jobTime = res.data.jobTime.split(" ")[0];
          }
          this.oldData = this.$config.cloneObj(res.data);
          this.oldIdtype = res.data.idType;
          this.enterpriseEmployee = res.data;
          this.enterpriseEmployee.mobile =
            this.enterpriseEmployee.mobile.replace(/\s+/g, "");
          this.enterpriseEmployee.beizhu = "";
          this.status = res.data.status;

          this.isOntermFun();
          const regMobile = /^1(3|4|5|6|7|8|9)\d{9}$/;
          if (res.data.ptsControInfo) {
            this.sbyyList = [];
            if (res.data.ptsControInfo.length > 0) {
              this.baosongStatus = res.data.ptsControInfo[0].baosongStatus;
              res.data.ptsControInfo.forEach((e) => {
                if (e.baosongStatus !== 2) {
                  if (e.ptsSbyy) {
                    this.sbyyList.push({
                      title: e.ptsSbyy,
                    });
                  }
                }
              });
            }
          }
          if (regMobile.test(this.enterpriseEmployee.name)) {
            this.enterpriseEmployee.name =
              "*" +
              this.enterpriseEmployee.name.substr(
                this.enterpriseEmployee.name.length - 2,
                2
              );
          }
          if (this.enterpriseEmployee.nationality + "" === "") {
            this.enterpriseEmployee.nationality = "1";
          }
          if (res.data.idType === 2) {
            this.enterpriseEmployee.nationality = "1";
          }
          if (res.data.idType === 4) {
            this.enterpriseEmployee.nationality = "3";
          }
          if (this.enterpriseEmployee.sssy + "" === "0") {
            this.enterpriseEmployee.sssy = "";
          }
          if (this.enterpriseEmployee.education + "" === "0") {
            this.enterpriseEmployee.education = "";
          }
          if (!this.enterpriseEmployee.jobTime) {
            this.staffType = "add";
            this.enterpriseEmployee.jobTime = "";
            // this.enterpriseEmployee.onTrialTermSalaryScale = "";
          } else {
            if (
              !this.enterpriseEmployee.onTrialTermBegin &&
              !this.enterpriseEmployee.onTrialTermBegin
            ) {
              this.enterpriseEmployee.onTrialTermBegin =
                this.enterpriseEmployee.jobTime;
              this.enterpriseEmployee.onTrialTermEnd =
                this.enterpriseEmployee.jobTime;
            }
          }
          this.staffTitle = this.enterpriseEmployee.name + "  个人信息";
          if (this.status === 10) {
            this.staffTitle = "申请加入";
          }

          if (!this.enterpriseEmployee.identity) {
            this.enterpriseEmployee.identity = 1;
          }
          if (!this.enterpriseEmployee.contractType) {
            this.enterpriseEmployee.contractType = 3;
          }

          this.queryEnterprieDepartment();
          this.$nextTick(() => {
            this.addStaffShow = true;
            this.statusShow = true;
          });
        }
      });
    },
    staffParent(item, index1, index2) {
      console.log(item);
      this.enterpriseEmployee[item.field] = item.value;
      if (item.field === "idType") {
        this.idTypeFun(item, index1, index2);
      } else if (item.field === "identity") {
        if (item.value + "" === "2") {
          this.enterpriseEmployee.investMoney = item.value1;
          this.enterpriseEmployee.investproportion = item.value2;
        }
      } else if (item.type === "city") {
        this.enterpriseEmployee[item.field + "Id"] = item.value1;
        this.enterpriseEmployee[item.city + "Id"] = item.value2;
        this.enterpriseEmployee[item.area + "Id"] = item.value3;
        this.enterpriseEmployee[item.field] = item.desc1;
        this.enterpriseEmployee[item.city] = item.desc2;
        this.enterpriseEmployee[item.area] = item.desc3;
      } else if (item.field === "jobTime") {
        this.enterpriseEmployee.onTrialTermBegin = item.value;
        this.enterpriseEmployee.onTrialTermEnd = item.value;
        this.isOntermFun();
      } else if (item.field === "contractType") {
        if (item.value + "" === "2") {
          this.enterpriseEmployee.identity = "6";
        } else if (item.value + "" === "1") {
          this.enterpriseEmployee.identity = "3";
        }
      } else if (item.field === "onTrialTermBegin") {
        this.enterpriseEmployee.onTrialTermBegin = item.onTrialTermBegin;
        this.enterpriseEmployee.onTrialTermEnd = item.onTrialTermEnd;
        this.isOntermFun();
      } else if (item.field === "idcardImgFront") {
        this.enterpriseEmployee.idcardImgFront = item.back;
        this.enterpriseEmployee.idcardImgReverse = item.positive;
      } else if (item.field === "degreeImg") {
        this.enterpriseEmployee.degreeImg = item.back;
        this.enterpriseEmployee.diplomaImg = item.positive;
      } else if (item.field === "status") {
        this.enterpriseEmployee.dimissionTime = item.dimissionTime;
      } else if (item.field === "bankProvinceId") {
        this.enterpriseEmployee.bankProvince = item.bankProvince;
      }
      console.log(this.enterpriseEmployee);
      this.$forceUpdate();
    },
    // 证件类型--切换判断
    idTypeFun(item, index1, index2) {
      this.addStaffShow = false;
      // this.oldData.idType = item.value;
      this.oldIdtype = item.value;
      if (item.value + "" === "1") {
        this.enterpriseEmployee.nationality = "1";
        this.employee.list6[0].value = "1";
      } else if (item.value + "" === "2") {
        this.enterpriseEmployee.nationality = "1";
        this.employee.list6[0].value = "1";
        this.employee.list6[0].arrayList = this.$dictionaries.countryData;
      } else if (item.value + "" === "3") {
        this.enterpriseEmployee.nationality = "4";
        this.employee.list6[0].value = "4";
        this.employee.list6[0].arrayList = this.$dictionaries.gangao;
      } else if (item.value + "" === "4") {
        console.log(111);
        this.enterpriseEmployee.nationality = "3";
        this.employee.list6[0].value = "3";
        this.employee.list6[0].arrayList = this.$dictionaries.taiwan;
      } else {
        this.enterpriseEmployee.nationality = "";
        this.employee.list6[0].value = "";
        this.employee.list6[0].arrayList = this.$dictionaries.countryData;
      }
      // this.employee.list6[0].value = item.value;
      console.log(this.employee.list6[0]);
      // 重新进行 数据赋值
      this.$forceUpdate();
      this.assignmentFun();
      this.$nextTick(() => {
        this.addStaffShow = true;
      });
    },
    // 数据赋值
    assignmentFun() {
      if (this.enterpriseEmployee.idType + "" === "1") {
        this.enterpriseEmployee.nationality = "1";
        this.employee.list6[0].value = "1";
      } else if (this.enterpriseEmployee.idType + "" === "2") {
        this.enterpriseEmployee.nationality = "1";
        this.employee.list6[0].value = "1";
        this.employee.list6[0].arrayList = this.$dictionaries.countryData;
      } else if (this.enterpriseEmployee.idType + "" === "3") {
        this.enterpriseEmployee.nationality = "4";
        this.employee.list6[0].value = "4";
        this.employee.list6[0].arrayList = this.$dictionaries.gangao;
      } else if (this.enterpriseEmployee.idType + "" === "4") {
        this.enterpriseEmployee.nationality = "3";
        this.employee.list6[0].value = "3";
        this.employee.list6[0].arrayList = this.$dictionaries.taiwan;
      } else {
        this.enterpriseEmployee.nationality = "";
        this.employee.list6[0].value = "";
        this.employee.list6[0].arrayList = this.$dictionaries.countryData;
      }

      this.employee.list1.forEach((e, v) => {
        if (e.field === "contactProvince") {
          e.desc1 = this.enterpriseEmployee.contactProvince;
          e.desc2 = this.enterpriseEmployee.contactCity;
          e.desc3 = this.enterpriseEmployee.contactArea;
          e.value1 = this.enterpriseEmployee.contactProvinceId;
          e.value2 = this.enterpriseEmployee.contactCityId;
          e.value3 = this.enterpriseEmployee.contactAreaId;
        } else {
          e.value = this.enterpriseEmployee[e.field] + "";
        }
      });
      this.employee.list2.forEach((e, v) => {
        if (e.field === "onTrialTermBegin") {
          e.onTrialTermBegin = this.enterpriseEmployee.onTrialTermBegin;
          e.onTrialTermEnd = this.enterpriseEmployee.onTrialTermEnd;
        } else if (e.field === "degreeImg") {
          e.positive = this.enterpriseEmployee.degreeImg;
          e.back = this.enterpriseEmployee.diplomaImg;
        } else {
          e.value = this.enterpriseEmployee[e.field] + "";
        }
      });
      this.employee.list3.forEach((e, v) => {
        if (e.field === "idcardImgFront") {
          e.positive = this.enterpriseEmployee.idcardImgFront;
          e.back = this.enterpriseEmployee.idcardImgReverse;
        } else {
          e.value = this.enterpriseEmployee[e.field] + "";
        }
      });
      this.employee.list4.forEach((e, v) => {
        e.value = this.enterpriseEmployee[e.field] + "";
      });
      this.employee.list5.forEach((e, v) => {
        e.value = this.enterpriseEmployee[e.field] + "";
      });
      this.employee.list6.forEach((e, v) => {
        e.value = this.enterpriseEmployee[e.field] + "";
      });
      this.employee.list7.forEach((e, v) => {
        if (e.field === "province") {
          e.desc1 = this.enterpriseEmployee.province;
          e.desc2 = this.enterpriseEmployee.city;
          e.desc3 = this.enterpriseEmployee.area;
          e.value1 = this.enterpriseEmployee.provinceId;
          e.value2 = this.enterpriseEmployee.cityId;
          e.value3 = this.enterpriseEmployee.areaId;
        } else {
          e.value = this.enterpriseEmployee[e.field] + "";
        }
      });
      this.employee.list8.forEach((e, v) => {
        e.value = this.enterpriseEmployee[e.field] + "";
      });
      this.employee.shewai.forEach((e, v) => {
        e.value = this.enterpriseEmployee[e.field] + "";
      });
      this.$forceUpdate();
    },
    // 判断试用期
    isOntermFun() {
      if (
        this.enterpriseEmployee.onTrialTermBegin &&
        this.enterpriseEmployee.onTrialTermEnd
      ) {
        if (
          this.enterpriseEmployee.onTrialTermBegin ===
          this.enterpriseEmployee.onTrialTermEnd
        ) {
          this.enterpriseEmployee.isOnterm = 0;
        } else {
          this.enterpriseEmployee.isOnterm = 1;
        }
      } else {
        this.enterpriseEmployee.isOnterm = 0;
      }
      this.$forceUpdate();
      // console.log(this.enterpriseEmployee.isOnterm);
    },
    testid(id) {
      // 1 "验证通过!", 0 //校验不通过 // id为身份证号码
      var format =
        /^(([1][1-5])|([2][1-3])|([3][1-7])|([4][1-6])|([5][0-4])|([6][1-5])|([7][1])|([8][1-2]))\d{4}(([1][9]\d{2})|([2]\d{3}))(([0][1-9])|([1][0-2]))(([0][1-9])|([1-2][0-9])|([3][0-1]))\d{3}[0-9xX]$/;
      //号码规则校验
      if (!format.test(id)) {
        return {
          status: 0,
          msg: "身份证号码不合规",
        };
      }
      //区位码校验
      //出生年月日校验  前正则限制起始年份为1900;
      var year = id.substr(6, 4),
        //身份证年
        month = id.substr(10, 2),
        //身份证月
        date = id.substr(12, 2),
        //身份证日
        time = Date.parse(month + "-" + date + "-" + year),
        //身份证日期时间戳date
        now_time = Date.parse(new Date()),
        //当前时间戳
        dates = new Date(year, month, 0).getDate(); //身份证当月天数
      if (time > now_time || date > dates) {
        return {
          status: 0,
          msg: "出生日期不合规",
        };
      }
      //校验码判断
      var c = new Array(7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2); //系数
      var b = new Array("1", "0", "X", "9", "8", "7", "6", "5", "4", "3", "2"); //校验码对照表
      var id_array = id.split("");
      var sum = 0;
      for (var k = 0; k < 17; k++) {
        sum += parseInt(id_array[k]) * parseInt(c[k]);
      }
      if (id_array[17].toUpperCase() != b[sum % 11].toUpperCase()) {
        return {
          status: 0,
          msg: "身份证校验码不合规",
        };
      }
      return {
        status: 1,
        msg: "校验通过",
      };
    },
  },
};
</script>

<style lang="less" scoped>
@import "./payroll.less";
.huifuPopup {
  position: fixed;
  margin-left: -140px;
  margin-top: -100px;
  width: 280px;
  height: 200px;
}
</style>