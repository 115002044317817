<template>
  <div class="views-width">
    <div class="views-main">
      <div class="views-mian-header">
        <div class="views-mian-header-left">
          <el-button
            type="primary"
            @click="salaryRecord(true)"
            class="btn-primary btn-link"
            style="position: relative"
            >工资记录<!-- <span
              style="
                position: absolute;
                right: 5px;
                top: 5px;
                width: 7px;
                height: 7px;
                border-radius: 50%;
                background: #ff931e;
              "
            ></span> -->
          </el-button>
          <span>合计 {{ totalRow }} 人</span>
        </div>
        <div class="views-mian-header-title">人员列表</div>
        <div class="views-mian-header-right">
          <span class="qrStyle" v-if="1" @click="deductPersonQuery1"
            >减除费用扣除确认</span
          >
          <span
            class="qrStyle"
            v-if="visit === 'visit'"
            @click="queryPTSEmployeeList"
            >人员列表（税局）</span
          >
          <el-button
            type="primary"
            @click="payrollClick($event, 1)"
            class="btn-primary btn-link"
            >添加员工</el-button
          >
          <el-button
            type="primary"
            @click="open"
            v-if="dataList.length !== 0"
            class="btn-primary"
            >制作工资表</el-button
          >
        </div>
      </div>
      <div
        class="views-mian-scroll"
        id="views-mian-scroll"
        :class="{ overflowStyle: overflowStyle }"
      >
        <div class="views-mian-content">
          <!-- <div class="dataList-nav">
            <div
              class="dataList-nav-box"
              v-for="(e, i) in departmentList"
              :class="{ cr: e.departmentId === departmentId }"
              :key="i"
              @click="departmentFun(e.departmentId)"
            >
              <i class="el-icon-circle-check"></i> {{ e.departmentName }}
            </div>
          </div> -->
          <slot v-if="dataList.length > 0">
            <payrollTable :dataList="dataList"></payrollTable>
            <div class="pages" v-if="pages.totalPage > 1">
              <el-pagination
                background
                layout="total, prev, pager, next, jumper"
                :page-size="pages.pageSize"
                :current-page.sync="pages.curren"
                :total="pages.total"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :page-sizes="[20, 50, 100]"
              >
              </el-pagination>
            </div>
          </slot>
          <NoRecord v-else></NoRecord>
        </div>
        <TheFooter></TheFooter>
      </div>
    </div>
    <div
      class="replace-popup"
      :style="{ left: popupLeft, top: popupTop }"
      @mouseleave="popupShow1 = false"
      v-if="popupShow1 && staffList.length > 0"
    >
      <Triangle direction="top" left="246" color="#cccccc"></Triangle>
      <div class="replace-main">
        <div class="replace-list">
          <slot v-for="(e, i) in staffList">
            <div class="replace-list-box" :key="i" @click="personFun(e)">
              <div class="replace-list-l">
                <div class="userHead" v-if="e.head">
                  <img class="userHead-logo" :src="e.head" alt="" />
                </div>
                <div class="userHead" v-else-if="e.nickName">
                  <div class="userHead-logo" v-if="e.nickName.length > 2">
                    <slot v-if="e.nickName.substr(0, 1) === '*'">*</slot
                    >{{ e.nickName.substr(e.nickName.length - 2, 2) }}
                  </div>
                  <div class="userHead-logo" v-else>
                    {{ e.nickName }}
                  </div>
                </div>
              </div>
              <div class="replace-list-r">
                {{ e.nickNameText }} <span>{{ e.position }}</span>
              </div>
            </div>
            <div class="clear" :key="i + '--0'"></div>
          </slot>
        </div>
      </div>
    </div>
    <div
      class="replace-popup"
      :style="{ left: popupLeft, top: popupTop }"
      @mouseleave="popupShow2 = false"
      v-if="popupShow2"
    >
      <Triangle direction="top" left="246" color="#cccccc"></Triangle>
      <div class="replace-main">
        <div class="mouth-mian-title">选择工资表月份</div>
        <div class="replace-btns">
          <el-button
            plain
            class="mouth-click"
            @click.stop="dataClick(1)"
            v-if="mouth1"
            >{{ date.lastMounth }}月 工资表</el-button
          ><br />
          <el-button
            plain
            class="mouth-click"
            @click.stop="dataClick(2)"
            v-if="mouth2"
            >{{ date.thisMounth }}月 工资表</el-button
          >
          <!-- <el-button
            plain
            class="mouth-click"
            @click.stop="dataClick(3)"
            v-if="mouth3"
            >{{ mouth3 * 1 }}月 工资表</el-button
          > -->
        </div>
      </div>
    </div>
    <perfectPopup
      :wanshanShow1.sync="wanshanShow1"
      :userItem="userItem"
      v-if="wanshanShow1"
      @wanshanFun="wanshanFun"
    ></perfectPopup>
    <!-- 完善工资信息 -->
    <div class="popup-layer" v-if="wanshanShow">
      <div class="popup-layer-bg" @click="wanshanShow = false"></div>
      <div class="payroll-popup-main">
        <div class="payroll-popup-main-title" style="margin-top: 15px">
          制作工资表遇到问题
          <p
            style="
              color: #bbb;
              font-weight: 400;
              font-size: 12px;
              padding: 3px 0 0;
            "
          >
            请先完善以下员工工资信息再制作工资表
          </p>
        </div>
        <div
          class="payroll-popup-main-text"
          style="height: 190px; overflow-y: auto; padding-bottom: 10px"
        >
          <div style="width: 100%; height: 190px; overflow-y: auto">
            <!-- 请先完善 <span class="personName">{{ personName }}</span>
          的工资信息 -->
            <!-- {{ wanshanText }} -->
            <table class="wanshanTab">
              <tr v-for="(e, i) in personData" :key="i">
                <td class="td1">{{ e.name }}</td>
                <td class="td2" style="color: #ff931e">
                  <slot v-if="e.notice !== ''">{{ e.notice }}</slot
                  ><slot v-else>工资信息未完善</slot>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <div class="payroll-popup-main-fotter">
          <el-button
            type="primary"
            style="
              background: none;
              color: #409eff;
              border-top: 1px solid rgba(187, 187, 187, 0.5);
            "
            class="fotter-click"
            @click="showDetails"
            >我知道了</el-button
          >
          <!-- <el-button
            plain
            class="fotter-click closeColor"
            @click="wanshanShow = false"
            >取消</el-button
          > -->
        </div>
      </div>
    </div>
    <!-- 人员列表（税局） -->
    <div class="popup-layer" v-if="ryShow">
      <div class="popup-layer-bg" @click="ryShow = false"></div>
      <div class="payroll-popup-main">
        <div class="payroll-popup-main-title" style="margin-top: 15px">
          人员列表（税局）
        </div>
        <div
          class="payroll-popup-main-text"
          style="height: 220px; overflow-y: auto; padding-bottom: 10px"
        >
          <div style="width: 100%; height: 220px; overflow-y: auto">
            <!-- 请先完善 <span class="personName">{{ personName }}</span>
          的工资信息 -->
            <!-- {{ wanshanText }} -->
            <table class="wanshanTab">
              <tr v-for="(e, i) in ryList" :key="i">
                <td class="td1">{{ e.xm }}</td>
                <td class="td2" style="color: #ff931e">{{ e.nsrztmc }}</td>
              </tr>
            </table>
          </div>
        </div>
        <div class="payroll-popup-main-fotter">
          <el-button
            type="primary"
            style="
              background: none;
              color: #409eff;
              border-top: 1px solid rgba(187, 187, 187, 0.5);
            "
            class="fotter-click"
            @click="ryShow = false"
            >我知道了</el-button
          >
          <!-- <el-button
            plain
            class="fotter-click closeColor"
            @click="wanshanShow = false"
            >取消</el-button
          > -->
        </div>
      </div>
    </div>
    <!-- 报送中信息 -->
    <div class="popup-layer" v-if="bszShow">
      <div class="popup-layer-bg"></div>
      <div class="huifuPopup zhizuoPopup" style="height: 210px">
        <div class="popup-layer-close" @click="bszShow = false">
          <svg-icon icon-class="popup-close"></svg-icon>
        </div>
        <div class="huifuPopup-content">
          <p class="huifuPopup-p2">人员信息核实中，请稍等...</p>
          <!-- <p class="huifuPopup-p2">如已制作，请到工资记录里查看</p> -->
          <div class="huifuPopup-btns">
            <!-- <div class="huifuPopup-btn-l" @click="reportClose">取 消</div> -->
            <div class="huifuPopup-btn-r" @click="bszShow = false">
              我知道了
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="popup-layer" v-if="wanshanOldShow">
      <div class="popup-layer-bg" @click="wanshanOldShow = false"></div>
      <div class="payroll-popup-main">
        <div class="payroll-popup-main-title">未完善员工信息</div>
        <div class="payroll-popup-main-text">
          请先完善 <span class="personName">{{ personName }}</span>
          {{ wanshanText }}
        </div>
        <div class="payroll-popup-main-fotter">
          <el-button
            type="primary"
            class="fott+er-click"
            @click="showOldDetails"
            >去完善</el-button
          >
          <el-button
            plain
            class="fotter-click closeColor"
            @click="wanshanOldShow = false"
            >取消</el-button
          >
        </div>
      </div>
    </div>
    <!-- 本月入职 -->
    <div class="popup-layer" v-if="tishiShow">
      <div class="popup-layer-bg" @click="tishiShow = false"></div>
      <div class="payroll-popup-main" style="height: 385px">
        <div class="payroll-popup-main-title" style="margin-top: 15px">
          以下员工不能计算到{{ mouth1 * 1 }}月工资表<br />
          是否确定制作工作表？
        </div>
        <div
          class="payroll-popup-main-text"
          style="color: #ff931e; height: 170px; padding: 0 25px"
        >
          <!-- {{ personText }}
          <span style="color: #262626; margin-bottom: 5px; display: block">
            {{ mouth2 * 1 }}月份入职，不能计算到{{
              mouth1 * 1
            }}月工资表<br />是否继续提交？</span
          > -->
          <div style="width: 100%; height: 190px; overflow-y: auto">
            <!-- 请先完善 <span class="personName">{{ personName }}</span>
          的工资信息 -->
            <!-- {{ wanshanText }} -->
            <table class="wanshanTab">
              <tr v-for="(e, i) in personData2" :key="i">
                <td class="td1">{{ e.name }}</td>
                <td class="td2">{{ e.time }}</td>
              </tr>
            </table>
          </div>
          <!-- {{ personText }} -->
        </div>
        <span style="color: #999; margin-bottom: 5px; display: block">
          *{{ mouth2 * 1 }}月份入职，不能计算到{{ mouth1 * 1 }}月工资表</span
        >
        <div class="payroll-popup-main-fotter">
          <el-button
            type="primary"
            class="fotter-click"
            @click="payrollZz(true)"
            >提 交</el-button
          >
          <el-button
            plain
            class="fotter-click closeColor"
            @click="tishiShow = false"
            style="background: none"
            >暂不提交</el-button
          >
        </div>
      </div>
    </div>
    <detailsStaff v-if="0"></detailsStaff>
    <personPopup
      v-if="rosterShow"
      :uId="uId"
      @rosterClose="rosterClose"
      @rosterSave="rosterSave"
    ></personPopup>
    <detailsStaffPopup
      v-if="detailsStaffShow"
      :details="details"
      @hidePopup="hideLogin"
      @savePopup="savePopup"
    ></detailsStaffPopup>
    <!-- 您已离职，暂无权限访问。 -->
    <div class="popup-layer" v-if="huifuFlag">
      <div class="popup-layer-bg" @click="rosterClose"></div>
      <div class="huifuPopup">
        <div class="popup-layer-close" @click="rosterClose">
          <svg-icon icon-class="popup-close"></svg-icon>
        </div>
        <div class="huifuPopup-content">
          <p class="huifuPopup-p2">您已离职，暂无权限访问。</p>
          <!-- <p class="huifuPopup-p2">如有疑问，请联系公司管理员</p> -->
          <!-- <p class="huifuPopup-p2">有信息变动请手动修改</p> -->
          <div class="huifuPopup-btns">
            <!-- <div class="huifuPopup-btn-l" @click="rosterClose">取 消</div> -->
            <div class="huifuPopup-btn-r" @click="rosterClose">我知道了</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 上月工资尚未完成 -->
    <div class="popup-layer" v-if="zhizuoFlag">
      <div class="popup-layer-bg" @click="rosterClose"></div>
      <div class="huifuPopup zhizuoPopup">
        <div class="popup-layer-close" @click="rosterClose">
          <svg-icon icon-class="popup-close"></svg-icon>
        </div>
        <div class="huifuPopup-content">
          <p class="huifuPopup-p2">{{ zhizuoText }}</p>
          <!-- <p class="huifuPopup-p2">如有疑问，请联系公司管理员</p> -->
          <!-- <p class="huifuPopup-p2">有信息变动请手动修改</p> -->
          <div class="huifuPopup-btns">
            <!-- <div class="huifuPopup-btn-l" @click="rosterClose">取 消</div> -->
            <div class="huifuPopup-btn-r" @click="zhizuoFun">我知道了</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 未到申报时间 -->
    <div class="popup-layer" v-if="payrollTips">
      <div class="popup-layer-bg" @click="rosterClose"></div>
      <div class="huifuPopup zhizuoPopup">
        <div class="popup-layer-close" @click="rosterClose">
          <svg-icon icon-class="popup-close"></svg-icon>
        </div>
        <div class="huifuPopup-content">
          <p class="huifuPopup-p6">未到申报时间，每月1日起</p>
          <p class="huifuPopup-p6">可以制作上个月工资表</p>
          <p class="huifuPopup-p6">请到工资记录里进行查看以往制作记录</p>
          <div class="huifuPopup-btns" style="padding-top: 24px">
            <!-- <div class="huifuPopup-btn-l" @click="rosterClose">取 消</div> -->
            <div class="huifuPopup-btn-r" @click="rosterClose">我知道了</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 个税申报密码 -->
    <div class="popup-layer" v-if="pwShow">
      <div class="popup-layer-bg" @click="pwCloseFun"></div>
      <div
        class="huifuPopup"
        style="
          width: 360px;
          margin-top: -240px;
          margin-left: -180px;
          height: 360px;
        "
      >
        <div class="popup-layer-close" @click="pwCloseFun">
          <svg-icon icon-class="popup-close"></svg-icon>
        </div>
        <div class="huifuPopup-content" style="padding-top: 30px">
          <!-- <p class="huifuPopup-p1" style="font-size: 14px">个税申报密码</p> -->
          <p class="huifuPopup-title">企业名称</p>
          <p class="huifuPopup-p2 margin-p2">
            <input
              class="huifuPopup-ipt1"
              type="text"
              v-model="infoData.company"
              placeholder="请输入"
            />
          </p>
          <p class="huifuPopup-title">统一社会信用代码</p>
          <p class="huifuPopup-p2 margin-p2">
            <input
              class="huifuPopup-ipt1"
              type="text"
              v-model="infoData.creditCode"
              placeholder="请输入"
            />
          </p>
          <p class="huifuPopup-title">个税申报密码</p>
          <p class="huifuPopup-p2">
            <input
              class="huifuPopup-ipt1"
              type="text"
              v-model="shenbaoPassword"
              :placeholder="placeholder"
              @blur="iptBlur('请输入申报密码')"
              @focus="iptFocus"
            />
          </p>
          <div class="huifuPopup-btns">
            <div
              class="huifuPopup-btn-r1"
              v-if="pwBtn"
              @click="perfectEnterpriseInfo"
            >
              <span v-if="!checkLoading">验证密码</span>
              <span v-if="checkLoading"
                >验证中<i class="el-icon-loading"></i
              ></span>
            </div>
            <div
              class="huifuPopup-btn-r1"
              style="background: #f58e21"
              v-if="!pwBtn"
              @click="perfectEnterpriseInfo"
            >
              重新验证
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 建账后，再使用工资 -->
    <div class="popup-layer" v-if="checkFlag">
      <div class="popup-layer-bg"></div>
      <div class="huifuPopup zhizuoPopup" style="height: 210px">
        <div class="popup-layer-close" @click="reportClose">
          <svg-icon icon-class="popup-close"></svg-icon>
        </div>
        <div class="huifuPopup-content">
          <p class="huifuPopup-p2">建账后，再使用工资!</p>
          <!-- <p class="huifuPopup-p2">如已制作，请到工资记录里查看</p> -->
          <div class="huifuPopup-btns">
            <!-- <div class="huifuPopup-btn-l" @click="reportClose">取 消</div> -->
            <div class="huifuPopup-btn-r" @click="reportClose">我知道了</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 建账审批中 -->
    <div class="popup-layer" v-if="checkFlag1">
      <div class="popup-layer-bg"></div>
      <div class="huifuPopup zhizuoPopup" style="height: 210px">
        <div class="popup-layer-close" @click="reportClose">
          <svg-icon icon-class="popup-close"></svg-icon>
        </div>
        <div class="huifuPopup-content">
          <p class="huifuPopup-p2">建账审批中</p>
          <!-- <p class="huifuPopup-p2">如已制作，请到工资记录里查看</p> -->
          <div class="huifuPopup-btns">
            <!-- <div class="huifuPopup-btn-l" @click="reportClose">取 消</div> -->
            <div class="huifuPopup-btn-r" @click="reportClose">我知道了</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 因工资表升级 -->
    <div class="popup-layer" v-if="pswTextFlag">
      <div class="popup-layer-bg"></div>
      <div class="huifuPopup zhizuoPopup" style="height: 210px">
        <div class="popup-layer-close" @click="reportClose">
          <svg-icon icon-class="popup-close"></svg-icon>
        </div>
        <div class="huifuPopup-content" style="padding-top: 45px">
          <p class="huifuPopup-p2">因工资表升级</p>
          <p class="huifuPopup-p2">需完善个税申报信息,才能使用</p>
          <p class="huifuPopup-p2">
            请联系客服 <span style="color: #4c8afc">4008-408-100</span>
          </p>
          <!-- <p class="huifuPopup-p2">如已制作，请到工资记录里查看</p> -->
          <div class="huifuPopup-btns">
            <!-- <div class="huifuPopup-btn-l" @click="reportClose">取 消</div> -->
            <div class="huifuPopup-btn-r" @click="reportClose">我知道了</div>
          </div>
        </div>
      </div>
    </div>
    <transitionPage1
      v-if="tPage1"
      :month="tPageDate"
      :batch="batch"
      @hideLayer="hideLayer"
    ></transitionPage1>
    <!-- 6万元弹窗 -->
    <div class="popup-layer" v-if="quernShow">
      <div class="popup-layer-bg" @click="quernShow = false"></div>
      <div class="reduction-popup">
        <div class="popup-layer-close" @click="quernShow = false">
          <svg-icon icon-class="popup-close"></svg-icon>
        </div>
        <div class="reduction-main">
          <div class="reduction-tips">
            <p>减除费用扣除确认</p>
            该名单中“是否直接按照6万元扣除”选“是”的纳税人，累计减除费用自1月份起将直接按照全年6万元计算扣除。
            <!-- 请根据
            纳税人的实际收入情况，再次判断纳税人是否同时满足以下三个条件，如不符合需选“否”。<br />
            一、取得工资薪金所得的居民个人需要满足以下条件 <br />
            (1)上一纳税年度1-12月均在同一单位任职且预扣预缴申报了工资薪金所得个人所得税；<br />
            (2)上一纳税年度1-12月的累计工资薪金收入（包括全年一次性奖金等各类工资薪金所得，且不扣减任何费用及免税收入）不超过6万元；<br />
            (3)本纳税年度自1月起，仍在该单位任职受雇并取得工资薪金所得<br /> -->
          </div>
          <div class="reduction-body">
            <table>
              <thead>
                <tr>
                  <th>选项</th>
                  <th>序号</th>
                  <th>姓名</th>
                  <th>证件类型</th>
                  <th>证件号码</th>
                  <!-- <th>国籍（地区）</th> -->
                  <th>状态</th>
                  <th>是否直接按照6万元扣除</th>
                  <!-- <th>操作</th> -->
                </tr>
              </thead>
              <tbody>
                <tr v-for="(e, i) in deductList" :key="i">
                  <td>
                    <svg-icon
                      v-if="e.checked"
                      icon-class="select"
                      class="duigou sHide"
                      @click="checkedFun(e)"
                    ></svg-icon>
                    <!-- <svg-icon
                      v-if="!e.checked"
                      icon-class="selectUn"
                      class="duigou sHide"
                      @click="checkedFun(e)"
                    ></svg-icon> -->
                    <svg-icon
                      v-if="!e.checked"
                      icon-class="selectUn"
                      class="duigou sHide selectUn"
                      @click="checkedFun(e)"
                    ></svg-icon>
                  </td>
                  <td class="td-w1">{{ i + 1 }}</td>
                  <td class="td-w2">{{ e.xm }}</td>
                  <td class="td-w3">{{ e.zzlx }}</td>
                  <td class="td-w4">{{ e.zzhm }}</td>
                  <td class="td-w5">
                    <span v-if="e.status === -1">未确认</span>
                    <span v-if="e.status === 1">处理中</span>
                    <span v-if="e.status === 2">确认失败</span>
                    <span v-if="e.status === 3">已确认</span>
                  </td>
                  <td>
                    <slot v-if="e.kcbsStatus === 1">是</slot>
                    <slot v-else>否</slot>
                    <!-- <el-select v-model="e.kcbsStatus" placeholder="请选择">
                      <el-option
                        v-for="item in options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select> -->
                  </td>
                  <!-- <td class="td-w6">
                    <span @click="editDeduct(e)">确认</span>
                  </td> -->
                </tr>
              </tbody>
            </table>
          </div>
          <div
            class="pages"
            style="
              padding-right: 20px;
              width: calc(100% - 15px);
              padding-top: 20px;
            "
          >
            <span
              style="margin-right: 20px; color: #999; cursor: pointer"
              @click="deductPersonQuery"
              >刷新</span
            >
            <div class="yijian" @click="editDeductAll">批量确认</div>
            <!-- v-if="pages1.totalPage > 1" -->
            <!-- <el-pagination
              background
              layout="total, prev, pager, next, jumper"
              :page-size="pages1.pageSize"
              :current-page.sync="pages1.curren"
              :total="pages1.total"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :page-sizes="[20, 50, 100]"
            >
            </el-pagination> -->
          </div>
        </div>
      </div>
    </div>
    <!-- 6万元弹窗 -->
    <div class="popup-layer" v-if="quernShow1">
      <div class="popup-layer-bg" @click="quernShow1 = false"></div>
      <div class="reduction-popup">
        <div class="popup-layer-close" @click="quernShow1 = false">
          <svg-icon icon-class="popup-close"></svg-icon>
        </div>
        <div class="reduction-main">
          <div class="reduction-tips">
            <p>减除费用扣除确认</p>
            该名单中“是否直接按照6万元扣除”选“是”的纳税人，累计减除费用自1月份起将直接按照全年6万元计算扣除。
            <!-- 请根据
            纳税人的实际收入情况，再次判断纳税人是否同时满足以下三个条件，如不符合需选“否”。<br />
            一、取得工资薪金所得的居民个人需要满足以下条件 <br />
            (1)上一纳税年度1-12月均在同一单位任职且预扣预缴申报了工资薪金所得个人所得税；<br />
            (2)上一纳税年度1-12月的累计工资薪金收入（包括全年一次性奖金等各类工资薪金所得，且不扣减任何费用及免税收入）不超过6万元；<br />
            (3)本纳税年度自1月起，仍在该单位任职受雇并取得工资薪金所得<br /> -->
          </div>
          <div class="reduction-body">
            <table>
              <thead>
                <tr>
                  <th>选项</th>
                  <th>序号</th>
                  <th>姓名</th>
                  <th>证件类型</th>
                  <th>证件号码</th>
                  <!-- <th>国籍（地区）</th> -->
                  <th>状态</th>
                  <th>是否直接按照6万元扣除</th>
                  <!-- <th>操作</th> -->
                </tr>
              </thead>
              <tbody>
                <tr v-for="(e, i) in deductList" :key="i">
                  <td>
                    <svg-icon
                      v-if="e.checked"
                      icon-class="select"
                      class="duigou sHide"
                      @click="checkedFun(e)"
                    ></svg-icon>
                    <!-- <svg-icon
                      v-if="!e.checked"
                      icon-class="selectUn"
                      class="duigou sHide"
                      @click="checkedFun(e)"
                    ></svg-icon> -->
                    <svg-icon
                      v-if="!e.checked"
                      icon-class="selectUn"
                      class="duigou sHide selectUn"
                      @click="checkedFun(e)"
                    ></svg-icon>
                  </td>
                  <td class="td-w1">{{ i + 1 }}</td>
                  <td class="td-w2">{{ e.xm }}</td>
                  <td class="td-w3">{{ e.zzlx }}</td>
                  <td class="td-w4">{{ e.zzhm }}</td>
                  <td class="td-w5">
                    <span v-if="e.status === -1">未确认</span>
                    <span v-if="e.status === 1">处理中</span>
                    <span v-if="e.status === 2">确认失败</span>
                    <span v-if="e.status === 3">已确认</span>
                  </td>
                  <td>
                    <slot v-if="e.kcbsStatus === 1">是</slot>
                    <slot v-else>否</slot>
                    <!-- <el-select v-model="e.kcbsStatus" placeholder="请选择">
                      <el-option
                        v-for="item in options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select> -->
                  </td>
                  <!-- <td class="td-w6">
                    <span @click="editDeduct(e)">确认</span>
                  </td> -->
                </tr>
              </tbody>
            </table>
          </div>
          <div
            class="pages"
            style="
              padding-right: 20px;
              width: calc(100% - 15px);
              padding-top: 20px;
            "
          >
            <span
              style="margin-right: 20px; color: #999; cursor: pointer"
              @click="deductPersonQuery1"
              >刷新</span
            >
            <div class="yijian" @click="editDeductAll('1')">批量确认</div>
            <!-- v-if="pages1.totalPage > 1" -->
            <!-- <el-pagination
              background
              layout="total, prev, pager, next, jumper"
              :page-size="pages1.pageSize"
              :current-page.sync="pages1.curren"
              :total="pages1.total"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :page-sizes="[20, 50, 100]"
            >
            </el-pagination> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  flashTokenPc,
  findEmployeeSalary,
  findEmployeeSalaryV2,
  queryStaff,
  getEmployeeInfoUserId,
  addSalaryStaff,
  makeSalary,
  makeSalaryV2,
  findEmployeeSalaryNosubmmit,
  salaryRecord,
  queryBaoShuiStatus,
  queryMonthSalaryListV2,
  reportInitQuery,
  queryRegsterDetail,
  checkShenBaoPassword,
  queryEnterpriseInfoForUser,
  perfectEnterpriseInfo,
  deductPersonQuery,
  confirm,
  queryPTSEmployeeList,
  flashTokenPcPlatform,
} from "@/http/api";
import personPopup from "./components/personPopup";
import payrollTable from "./components/payrollTable.vue";
import detailsStaff from "./components/detailsStaff.vue";
import transitionPage1 from "./components/transitionPage1";
import TheFooter from "@/components/TheFooter.vue";
import NoRecord from "@/components/NoRecord.vue";
import perfectPopup from "@/components/perfectPopup.vue";
import detailsStaffPopup from "@/views/roster/components/detailsStaffPopup.vue";
export default {
  data() {
    return {
      options: [
        {
          value: 1,
          label: "是",
        },
        {
          value: -1,
          label: "否",
        },
      ],
      selValue: "",
      ryShow: false,
      quernShow: false,
      quernShow1: false,
      pwShow: false,
      pswTextFlag: false,
      userId: sessionStorage.getItem("userId"),
      enterpriseId: sessionStorage.getItem("enterpriseId"),
      visit: sessionStorage.getItem("visit"),
      jurisdiction: sessionStorage.getItem("jurisdiction"),
      departmentList: JSON.parse(sessionStorage.getItem("departmentList")), //所属部门
      mouth: "",
      date: this.$config.getDate(),
      pickerOptions: {
        disabledDate(time) {
          let _now = Date.now(),
            seven = 7 * 24 * 60 * 60 * 1000,
            sevenDays = _now - seven;
          return time.getTime() > _now; //大于当前的禁止，小于7天前的禁止
        },
      },
      oldMouth: "",
      dataList: [],
      staffList: [],
      details: null,
      detailsStaffShow: false,
      huifuFlag: false,
      payrollTips: false,
      rosterShow: false,
      popupShow1: false,
      popupShow2: false,
      payrollShow: false,
      popupTop: "",
      popupLeft: "",
      noPerfect: false,
      wanshanShow: false,
      wanshanShow1: false,
      wanshanOldShow: false,
      tishiShow: false,
      userItem: {
        employeeId: "",
        userId: "",
        isAdmin: "",
      },
      mouth1: "",
      mouth2: "",
      mouth3: "",
      date1: "",
      date2: "",
      queryDate: "",
      wanshanText: "",
      personName: "",
      personText: "",
      mouthText: "",
      mouthNum1: 0,
      mouthNum2: 0,
      totalRow: 0,
      personDetails: null,
      list: [],
      overflowStyle: false,
      zhizuoFlag: false,
      uId: "",
      addUser: {},
      zhizuoText: "",
      payrollText: "",
      pages: {
        total: 0,
        current: 1,
        pageSize: 60,
      },
      pages1: {
        total: 0,
        current: 1,
        pageSize: 60,
      },
      departmentId: "",
      personData: [],
      personData1: [],
      personData2: [],
      tPage1: false,
      checkFlag: false,
      checkFlag1: false,
      pwBtn: true,
      batch: "",
      tPageDate: "",
      checkStatus: "",
      shenbaoPassword: "",
      djxhid: "",
      placeholder: "请输入申报密码",
      infoData: {},
      gzFun: null,
      checkLoading: false,
      bszShow: false,
      deductList: [],
      ryList: [],
      selectNum: 0,
    };
  },
  components: {
    NoRecord,
    perfectPopup,
    payrollTable,
    TheFooter,
    personPopup,
    detailsStaffPopup,
    detailsStaff,
    transitionPage1,
  },
  mounted() {
    // this.reportInitQuery();
    this.getEmployeeInfoUserId();
    // batch=&month=2021-11
    // this.batch = "20211224179471054";
    // this.tPageDate = "2021-11";
    // this.tPage1 = true;
    let year =
      this.date.lastMounth === "12" ? this.date.year - 1 : this.date.year;
    let date1 = year + "-" + this.date.lastMounth;
    this.date1 = year + "-" + this.date.lastMounth;
    this.date2 = this.date.year + "-" + this.date.thisMounth;
    this.payrollText = "制作" + this.date.lastMounth + "月工资";
    // this.findEmployeeSalaryNosubmmit();  //old-data
    this.queryMonthSalaryListV2();
    // this.queryPTSEmployeeList();
    // this.deductPersonQuery();
    // this.findEmployeeSalary();
    console.log(this.departmentList);
    if (this.departmentList) {
      this.departmentId = this.departmentList[0]?.departmentId;
    }
    this.findEmployeeSalary();
    // this.queryBaoShuiStatus();
    // this.salaryRecord();
  },
  methods: {
    queryPTSEmployeeList() {
      let param = {
        month: this.date1,
        enterpriseId: this.enterpriseId,
        userId: this.userId,
      };
      queryPTSEmployeeList(param).then((res) => {
        if (res.code === 0) {
          this.ryList = res.data;
          if (this.ryList.length > 0) {
            this.ryShow = true;
          } else {
            this.ryShow = false;
            this.$store.commit("MyMessage", "暂无数据");
          }
        }
      });
    },
    checkedFun(e) {
      // if()
      if (this.selectNum === 10 && !e.checked) {
        this.$store.commit("MyMessage", "批量确认一次性最多可选10人");
        return false;
      }
      e.checked = !e.checked;
      this.selectNum = 0;
      this.deductList.forEach((list) => {
        if (list.checked) {
          this.selectNum++;
        }
      });
      this.$forceUpdate();
    },
    querenClick() {
      this.deductPersonQuery();
    },
    queryEnterpriseInfoForUser(flag) {
      var that = this;
      let param = {
        userId: this.userId,
        enterpriseId: this.enterpriseId,
      };
      queryEnterpriseInfoForUser(param).then((res) => {
        if (res.code === 0) {
          this.infoData = res.data;
        }
      });
    },
    deductPersonQuery() {
      var that = this;
      let param = {
        enterpriseId: this.enterpriseId,
        year: this.date.year,
        userId: this.userId,
      };
      deductPersonQuery(param).then((res) => {
        if (res.code === 0) {
          this.deductList = res.data;
          if (this.deductList.length > 0) {
            let employeeIds = [];
            this.deductList.forEach((e) => {
              e.checked = false;
              if (e.status !== 3) {
                employeeIds.push({
                  employeeId: e.employeeId,
                  confirm: 1,
                });
              }
            });
            if (employeeIds.length > 0) {
              this.quernShow = true;
              return false;
            }
            let year =
              this.date.lastMounth === "12"
                ? this.date.year - 1
                : this.date.year;

            this.makeSalary(year + "-" + this.date.lastMounth);
            // this.payrollZz();
          } else {
            let year =
              this.date.lastMounth === "12"
                ? this.date.year - 1
                : this.date.year;

            this.makeSalary(year + "-" + this.date.lastMounth);
            // this.payrollZz();
          }
        } else {
          this.$store.commit("MyMessage", res.msg);
        }
      });
    },
    deductPersonQuery1() {
      var that = this;
      let param = {
        enterpriseId: this.enterpriseId,
        year: this.date.year,
        userId: this.userId,
      };
      deductPersonQuery(param).then((res) => {
        if (res.code === 0) {
          this.deductList = res.data;
          if (this.deductList.length > 0) {
            let employeeIds = [];
            this.deductList.forEach((e) => {
              e.checked = false;
              if (e.status !== 3) {
                employeeIds.push({
                  employeeId: e.employeeId,
                  confirm: 1,
                });
              }
            });
            this.quernShow1 = true;
            // if (employeeIds.length > 0) {
            //   return false;
            // } else {
            //   this.quernShow1 = false;
            // }
          }
        }
      });
    },
    editDeduct(e) {
      var that = this;
      let param = {
        enterpriseId: this.enterpriseId,
        year: this.date.year,
        userId: this.userId,
        employeeIds: [
          {
            employeeId: e.employeeId,
            confirm: 1,
          },
        ],
      };
      this.confirmFun(param);
    },
    editDeductAll(type) {
      let employeeIds = [];
      this.deductList.forEach((e) => {
        if (e.checked) {
          employeeIds.push({
            employeeId: e.employeeId,
            confirm: -1,
          });
        }
      });
      let param = {
        enterpriseId: this.enterpriseId,
        year: this.date.year,
        employeeIds: employeeIds,
        userId: this.userId,
      };

      this.confirmFun(param, type);
    },
    confirmFun(param, type) {
      confirm(param).then((res) => {
        if (res.code === 0) {
          console.log(res.data);
          if (type === "1") {
            this.quernShow1 = false;
          } else {
            this.deductPersonQuery();
          }
          this.$store.commit("MyMessage", "修改成功");
        }
      });
    },

    perfectEnterpriseInfo() {
      if (!this.infoData.company) {
        this.$store.commit("MyMessage", "请输入公司名称");
        return;
      }
      if (!this.infoData.creditCode) {
        this.$store.commit("MyMessage", "请输入统一社会信用代码");
        return;
      }
      if (!this.shenbaoPassword) {
        this.$store.commit("MyMessage", "请输入个税申报密码");
        return;
      }

      let param = {
        enterpriseInfo: this.infoData,
        userId: this.userId,
      };
      perfectEnterpriseInfo(param).then((res) => {
        if (res.code === 0) {
          this.checkLoading = true;
          this.queryRegsterDetail(true);
        } else {
          this.$store.commit("MyMessage", res.msg);
        }
      });
    },
    iptFocus() {
      this.placeholder = "";
    },
    iptBlur(txt) {
      this.placeholder = txt;
    },
    // pwdFun() {
    //   if (this.shenbaoPassword) {
    //     this.checkShenBaoPassword(true);
    //   } else {
    //     this.queryEnterpriseInfoForUser();
    //   }
    // },
    pwCloseFun() {
      // this.$router.push("/Navigation");
      this.pwShow = false;
    },
    checkShenBaoPassword() {
      if (!this.shenbaoPassword) {
        this.$store.commit("MyMessage", "请输入个税密码");
        return;
      }

      let param = {
        enterpriseId: this.enterpriseId,
        djxhid: this.djxhid,
        shenbaoPassword: this.shenbaoPassword,
      };
      //校验申报密码
      checkShenBaoPassword(param).then((res) => {
        console.log(res);
        if (res.code === 0) {
          this.pwShow = false;
          this.$store.commit("MyMessage", "校验成功");
        } else {
          this.pwBtn = false;
          this.$store.commit("MyMessage", res.msg);
        }
      });
    },
    queryRegsterDetail(flag) {
      let param = {
        enterpriseId: this.enterpriseId,
      };
      //查询企业登记信息
      queryRegsterDetail(param).then((res) => {
        if (res.data.register.detailList.length > 0) {
          res.data.register.detailList.forEach((e) => {
            if (e.enterpriseId === this.enterpriseId) {
              this.djxhid = e.djxhid;
              if (flag) {
                this.checkShenBaoPassword();
              }
            }
          });
          if (!flag) {
            this.shenbaoPassword = res.data.register.shenbaoPassword;
          }
        } else {
          this.checkLoading = false;
        }
      });
    },
    reportClose() {
      // this.$router.push("/Navigation");
      this.pswTextFlag = false;
      this.checkFlag = false;
      this.checkFlag1 = false;
    },
    reportInitQuery() {
      let data = {
        enterpriseId: this.enterpriseId,
      };
      reportInitQuery(data).then((res) => {
        // console.log("审核企业建账状态", res);
        this.checkStatus = res.data.checkStatus;
        this.flag = res.data.flag;
        if (this.flag !== "y") {
          this.checkFlag = true;
          return false;
        }
        if (this.checkStatus !== "y") {
          this.checkFlag1 = true;
          return false;
        }
        return true;

        // this.queryRegsterDetail(false);
      });
    },
    departmentFun(e) {
      this.pages = {
        total: 0,
        current: 1,
        pageSize: 20,
      };
      this.departmentId = e;
      this.findEmployeeSalary();
      this.$forceUpdate();
    },
    handleSizeChange(val) {
      this.pages.pageSize = val;
      this.findEmployeeSalary();
    },
    handleCurrentChange(val) {
      this.pages.current = val;
      this.findEmployeeSalary();
    },
    hideLayer() {
      this.tPage1 = false;
      this.queryMonthSalaryListV2();
      this.$forceUpdate();
    },
    open() {
      this.$confirm("确定要制作工资", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "",
        center: true,
      })
        .then(() => {
          if (!this.mouth1) {
            this.payrollTips = true;
            return false;
          }
          this.payrollZz();
        })
        .catch(() => {});
    },
    payrollZz(flag) {
      // 1、人员完善信息情况
      let isFinish = false;
      this.personText = "";
      this.personData = [];
      this.personData2 = [];
      this.dataList.forEach((e) => {
        e.salaryStaffList.forEach((e2) => {
          // if (e2.isFinish === 0 || e2.notice !== "") {
          if (e2.isFinish === 0) {
            if (!isFinish) {
              if (
                !e2.bankName ||
                !e2.bankCardNum ||
                !e2.bankProvince ||
                !e2.bankProvinceId
              ) {
                this.wanshanText = "工资卡银行信息";
              } else {
                this.wanshanText = "累计薪酬扣税项目";
              }
              this.personName = e2.name;
              // isFinish = true;
              this.personDetails = e2;
              this.personData.push(e2);
              // this.wanshanShow = true;
              // this.wanshanOldShow = true;
            }
          }
          let time = e2.jobTime.split(" ")[0];
          let time2 = time.split("-")[0] + "-" + time.split("-")[1];
          if (time2 === this.date2) {
            this.personData2.push({
              name: e2.name,
              time: time,
            });
            this.mouthNum1++;
            if (this.personText === "") {
              this.personText += e2.name;
            } else {
              this.personText += "、" + e2.name;
            }
          }
          this.mouthNum2++;
        });
      });
      // 完善
      if (this.personData.length > 0) {
        this.wanshanShow = true;
        return false;
      }
      if (!flag) {
        // 入职日期判断
        if (this.personData2.length > 0) {
          console.log(this.personData2);
          this.tishiShow = true;
          return false;
        }
      }
      // if (!this.mouth1) {
      //   this.payrollTips = true;
      //   return false;
      // }

      this.deductPersonQuery();
    },
    queryMonthSalaryListV2() {
      let param = {
        enterpriseId: this.enterpriseId,
        userId: this.userId,
      };
      queryMonthSalaryListV2(param).then((res) => {
        if (res.code === 0) {
          this.list = res.data;
          let date = this.mouth.split("-")[0] + this.mouth.split("-")[1];
          let year =
            this.date.lastMounth === "12" ? this.date.year - 1 : this.date.year;

          this.date1 = year + "-" + this.date.lastMounth;
          this.date2 = this.date.year + "-" + this.date.thisMounth;

          this.mouth1 = this.date.lastMounth;
          this.mouth2 = this.date.thisMounth;
          res.data.forEach((e) => {
            if (e.month + "" === year + "" + this.date.lastMounth) {
              this.mouth1 = null;
            }
            if (e.month + "" === this.date.year + "" + this.date.thisMounth) {
              this.mouth2 = null;
            }
          });
        }
      });
    },
    queryBaoShuiStatus() {
      let param = {
        enterpriseId: this.enterpriseId,
        userId: this.userId,
      };
      queryBaoShuiStatus(param).then((res) => {
        console.log(res);
        if (res.code === 0) {
          if (res.data) {
            this.mouth1 = this.date.lastMounth;
            this.mouth2 = this.date.thisMounth;
            let year =
              this.date.lastMounth === "12"
                ? this.date.year - 1
                : this.date.year;
            let date1 = year + "-" + this.date.lastMounth;
            let date2 = this.date.year + "-" + this.date.thisMounth;
            res.data.forEach((e) => {
              console.log(e.month);
              if (e.month + "" === year + "" + this.date.lastMounth) {
                this.mouth1 = "";
              }
              if (e.month + "" === this.date.year + "" + this.date.thisMounth) {
                this.mouth2 = "";
              }
            });
          }
        }
      });
    },
    wanshanFun() {
      this.wanshanShow1 = false;
      this.detailsStaffShow = true;
    },
    hideLogin() {
      this.detailsStaffShow = false;
    },
    savePopup() {
      // this.hideLogin();
      // this.init();
      this.getEmployeeInfoUserId();
      this.hideLogin();
    },
    rosterClose() {
      this.rosterShow = false;
      this.huifuFlag = false;
      this.zhizuoFlag = false;
      this.payrollTips = false;
      this.checkFlag = false;
      this.pwShow = false;
    },
    zhizuoFun() {
      this.rosterClose();
      return;
      let list = {};
      this.list.forEach((e, i) => {
        if (e.month + "" === this.date.year + "" + this.date.lastMounth) {
          list = e;
        }
      });
      let date = this.date.year + "-" + this.date.lastMounth;
      let taxStatus = this.list[0].taxStatus;
      this.tPageDate = date;
      if (taxStatus === -2 || taxStatus === 1) {
        this.tPageDate = date;
        this.batch = this.list[0].batch;
        this.$router.push({
          path: "/transitionPage1",
          query: {
            month: date,
            batch: this.list[0].batch,
          },
        });
      } else {
        this.$router.push({
          path: "/generatePayroll",
          query: {
            month: date,
            batch: this.list[0].batch,
          },
        });
      }
    },
    rosterSave() {
      // this.querySalaryStaff();
      // this.queryBuzhuButie();
      this.staffClick(this.addUser);
      this.rosterClose();
    },
    salaryRecord(type) {
      console.log(this.list);
      if (type) {
        // if (this.list.length === 0) {
        //   this.$store.commit("MyMessage", "暂无记录");

        //   return;
        // }
        // if (!this.list.length) {
        //   this.$store.commit("MyMessage", "暂无记录");
        //   return;
        // }

        this.$router.push("/payrollRecord");
      }
      return;
      console.log(this.enterpriseId);
      let param = {
        enterpriseId: this.enterpriseId,
        pageNum: "1",
        pageSize: "999",
      };
      salaryRecord(param).then((res) => {
        // console.log(res);
        this.list = res.data;
        if (type) {
          if (this.list.length === 0) {
            this.$store.commit("MyMessage", "暂无记录");

            return;
          }
          if (!this.list.length) {
            this.$store.commit("MyMessage", "暂无记录");
            return;
          }

          this.$router.push("/payrollRecord");
        }
      });
    },
    findEmployeeSalaryNosubmmit() {
      let param = {
        enterpriseId: this.enterpriseId,
        batch: "",
        pageNum: "1",
        pageSize: "20",
      };
      findEmployeeSalaryNosubmmit(param).then((res) => {
        console.log("findEmployeeSalaryNosubmmit", res.data);
        if (JSON.stringify(res.data) !== "{}") {
          if (res.data.salaryEndList.list.length > 0) {
            if (
              res.data.month === this.date1 ||
              res.data.month === this.date2
            ) {
              // this.$router.push("/generatePayroll");
            } else {
              this.findEmployeeSalary(this.date2);
            }
          } else {
            this.findEmployeeSalary(this.date2);
          }
          this.status = res.data.comprehensive.status + "";
          this.payStatus = res.data.comprehensive.payStatus + "";
        } else {
          this.findEmployeeSalary(this.date2);
        }
      });
    },
    dataClick(type) {
      this.mouthShow = false;
      this.queryDate = this.date2;
      if (type === 1) {
        // 3、在职人员入职日期
        this.queryDate = this.date1;
        if (this.mouthNum1 === this.mouthNum2) {
          this.$store.commit(
            "MyMessage",
            this.mouth1 * 1 + "月份无在职人员，无法制作"
          );
          return;
        }
        console.log(this.personText);
        if (this.personText !== "") {
          this.tishiShow = true;
          return;
        }
      } else if (type === 3) {
        this.$store.commit("MyMessage", "目前还不能制作下月工资表");
        return;
      }
      this.makeSalary();
    },
    tishiFun() {
      let year =
        this.date.lastMounth === "12" ? this.date.year - 1 : this.date.year;
      this.makeSalary(year + "-" + this.date.lastMounth);
      this.tishiShow = false;
    },
    // 制作工资表;
    makeSalary(data) {
      // this.$store.commit("showLoading", "");
      this.tishiShow = false;
      let param = {
        enterpriseId: this.enterpriseId,
        userId: this.userId,
        month: data,
      };
      makeSalaryV2(param).then((res) => {
        // this.$store.commit("hideLoading", "");
        // console.log("initialListpc", res);
        if (res.code === 0) {
          this.status = res.data.status;
          this.batch = res.data.batch;
          this.$setCookie("approvalShow", true);
          // this.$router.push("/generatePayroll");
          this.tPageDate = data;
          this.tPage1 = true;
          this.$forceUpdate();
          return;
          this.$router.push({
            path: "/transitionPage1",
            query: {
              month: data,
              batch: res.data.batch,
            },
          });
        } else {
          if (res.msg === "上月工资尚未完成") {
            let year =
              this.date.lastMounth === "12"
                ? this.date.year - 1
                : this.date.year;
            // this.zhizuoText = "请先完成" + data + "工资表";
            this.zhizuoText = "请先完成上月工资表";
            this.zhizuoFlag = true;
          } else if (res.msg === "含有尚未完善信息的员工") {
            if (res.data.employeeList.length > 0) {
              // this.personData = res.data.employeeList;
              this.personData = [];
              res.data.employeeList.forEach((e) => {
                this.personData.push({ name: e });
              });
              this.wanshanShow = true;
            }
          } else if (res.msg === "含有尚未报送税局的员工") {
            if (res.data.employeeList.length > 0) {
              this.personData1 = res.data.employeeList;

              this.bszShow = true;
            }
          } else if (res.msg === "尚未完善企业报税信息(企业税号，报税密码)") {
            // this.queryEnterpriseInfoForUser();
            this.$store.commit("MyMessage", "尚未完善企业报税信息");
            this.queryEnterpriseInfoForUser(true);
            this.queryRegsterDetail(false);
            this.pwShow = true;
          } else {
            this.$store.commit("MyMessage", res.msg);
          }
        }
      });
    },
    initialFun() {
      console.log(this.mouth2);
      if (this.mouth2 === "" && this.mouth1 === "") {
        this.$store.commit("MyMessage", "目前还不能制作下月工资表");
        return;
      }
      // 1、人员完善信息情况
      let isFinish = false;
      this.personText = "";
      this.mouthNum1 = 0;
      this.mouthNum2 = 0;
      this.dataList.forEach((e) => {
        e.salaryStaffList.forEach((e2) => {
          if (e2.isFinish === 0) {
            if (!isFinish) {
              if (
                !e2.bankName ||
                !e2.bankCardNum ||
                !e2.bankProvince ||
                !e2.bankProvinceId
              ) {
                this.wanshanText = "工资卡银行信息";
              } else {
                this.wanshanText = "累计薪酬扣税项目";
              }
              this.personName = e2.name;
              isFinish = true;
              this.personDetails = e2;
              this.wanshanShow = true;
            }
          }
          let time = e2.jobTime.split(" ")[0];
          let time2 = time.split("-")[0] + "-" + time.split("-")[1];
          if (time2 === this.date2) {
            this.mouthNum1++;
            if (this.personText === "") {
              this.personText += e2.name;
            } else {
              this.personText += "、" + e2.name;
            }
          }
          this.mouthNum2++;
        });
      });
      if (isFinish) {
        return;
      }
      if (!this.mouth2) {
        if (!this.mouth1) {
          this.mouth3 = this.mouth2 * 1 === 12 ? 1 : this.mouth2 * 1 + 1;
        } else {
          if (this.mouth1 * 1 === 11) {
            this.mouth3 = 1;
          } else if (this.mouth1 * 1 === 12) {
            this.mouth3 = 2;
          } else {
            this.mouth3 = this.mouth1 * 1 + 2;
          }
        }
      }
      // 2、判断月份
      if (this.mouth1 || this.mouth2) {
        // this.popupShow1 = false;
        // this.popupShow2 = true;
        this.dataClick(1);
        return;
      } else {
        this.$store.commit("MyMessage", "目前还不能制作下月工资表");
      }
      if (this.mouth1 && this.mouth2) {
        this.$store.commit("MyMessage", "无法生成下个月工资表");
        return;
      }
    },
    showDetails() {
      // /personInformation
      this.wanshanShow = false;
      this.bszShow = false;
      // this.$router.push({
      //   path: "/personInformation",
      //   query: {
      //     id: this.personDetails.salaryStaffId,
      //     user: this.personDetails.userId,
      //   },
      // });
    },
    showOldDetails() {
      // /personInformation
      this.wanshanShow = false;
      this.$router.push({
        path: "/personInformation",
        query: {
          id: this.personDetails.salaryStaffId,
          user: this.personDetails.userId,
        },
      });
    },
    init() {
      this.findEmployeeSalary();
    },
    // 员工列表
    findEmployeeSalaryV2() {
      this.flag = false;
      let that = this;
      let param = {
        enterpriseId: this.enterpriseId,
        pageNum: this.pages.current,
        pageSize: this.pages.pageSize,
      };
      findEmployeeSalaryV2(param).then((res) => {
        // console.log("findEmployeeSalary", res);
        if (res.code == 0) {
          if (res.data.dataList.length) {
            that.totalRow = res.data.totalRow;
            that.pages.totalPage = res.data.totalPage;
            that.pages.total = res.data.totalRow;
            that.dataList = res.data.dataList;
            that.dataList.forEach((e) => {
              e.salaryStaffList.forEach((e2) => {
                if (e2.jobTime) {
                  let data2 = e2.jobTime.split(" ")[0].split("-")[1] * 1;
                  if (
                    this.oldData * 1 < data2 * 1 ||
                    (this.oldData === 12 && data2 === 1)
                  ) {
                    this.newData = data2;
                    this.newName = e2.name;
                  }
                }
                const regMobile = /^1(3|4|5|6|7|8|9)\d{9}$/;
                if (regMobile.test(e2.name)) {
                  e2.nameText = "*" + e2.name.substr(e2.name.length - 2, 2);
                } else {
                  e2.nameText = e2.name;
                }
              });
            });
            this.$nextTick(() => {
              this.overflowStyle = this.$config.hasScrolled(
                "views-mian-scroll",
                "horizontal"
              );
            });
          }
        }
      });
    },
    // 员工列表
    findEmployeeSalary() {
      this.flag = false;
      let that = this;
      let param = {
        enterpriseId: this.enterpriseId,
        deparmentId: this.departmentId,
        pageNum: this.pages.current,
        pageSize: this.pages.pageSize,
        userId: this.userId,
      };
      findEmployeeSalary(param).then((res) => {
        console.log("findEmployeeSalary", res);
        if (res.code == 0) {
          if (res.data.dataList.length) {
            that.totalRow = res.data.totalRow;
            that.pages.totalPage = res.data.totalPage;
            that.pages.total = res.data.totalRow;
            that.dataList = res.data.dataList;
            that.dataList.forEach((e) => {
              e.salaryStaffList.forEach((e2) => {
                if (e2.jobTime) {
                  let data2 = e2.jobTime.split(" ")[0].split("-")[1] * 1;
                  if (
                    this.oldData * 1 < data2 * 1 ||
                    (this.oldData === 12 && data2 === 1)
                  ) {
                    this.newData = data2;
                    this.newName = e2.name;
                  }
                }
                const regMobile = /^1(3|4|5|6|7|8|9)\d{9}$/;
                if (regMobile.test(e2.name)) {
                  e2.nameText = "*" + e2.name.substr(e2.name.length - 2, 2);
                } else {
                  e2.nameText = e2.name;
                }
              });
            });
            this.$nextTick(() => {
              this.overflowStyle = this.$config.hasScrolled(
                "views-mian-scroll",
                "horizontal"
              );
            });
          } else {
            that.dataList = [];
            that.totalRow = 0;
          }
        }
      });
    },
    queryStaff() {
      // 添加员工  弹出
      let param = {
        enterpriseId: this.enterpriseId,
        userId: this.userId,
      };
      queryStaff(param).then((res) => {
        this.staffList = res.data;
        if (this.staffList.length > 0) {
          this.staffList.forEach((element) => {
            const regMobile = /^1(3|4|5|6|7|8|9)\d{9}$/;
            if (regMobile.test(element.nickName)) {
              element.nickNameText =
                "*" + element.nickName.substr(element.nickName.length - 2, 2);
            } else {
              element.nickNameText = element.nickName;
            }
          });
          this.$forceUpdate();
          this.payrollShow = true;
        } else {
          this.$store.commit("MyMessage", "暂无新员工");
          this.payrollShow = false;
        }
      });
    },
    personFun(item) {
      this.uId = item.userId;
      this.addUser = item;
      this.rosterShow = true;
      this.$forceUpdate();
    },
    getEmployeeInfoUserId(item) {
      let that = this;
      let userId = this.userId;
      let param = {
        enterpriseId: this.enterpriseId,
        userId: userId,
      };
      if (item) {
        param.userId = item.userId;
        param.employeeId = item.employeeId;
      }
      getEmployeeInfoUserId(param).then((res) => {
        // console.log(res);
        // sss
        if (res.code === 0) {
          this.noPerfect = false;
          sessionStorage.setItem("infoUser", JSON.stringify(res));
          this.details = res.data;
          this.userItem.employeeId = res.data.employeeId;
          this.userItem.userId = res.data.userId;
          this.userItem.isAdmin = res.data.isAdmin;
          if (res.data.contactDetail === "") {
            this.noPerfect = true;
            if (item) {
              this.wanshanShow1 = true;
              // this.$store.commit("MyMessage", "请先前往花名册完善个人信息");
            }
            return;
          }
          if (item) {
            this.staffClick(item);
          }
        }
      });
    },
    staffClick(item) {
      let param = {
        enterpriseId: this.enterpriseId,
        userId: item.userId,
        employeeId: item.employeeId,
      };
      addSalaryStaff(param).then((res) => {
        console.log(res);
        if (res.code === 0) {
          this.$store.commit("MyMessage", "添加成功");
          this.$router.push({
            path: "/personInformation",
            query: {
              id: res.data.salaryStaffId,
              user: item.userId,
            },
          });
        } else {
          if (res.msg === "尚未完善企业报税信息(企业税号，报税密码)") {
            // this.queryEnterpriseInfoForUser();
            this.$store.commit("MyMessage", "尚未完善企业报税信息");
            this.queryEnterpriseInfoForUser(true);
            this.queryRegsterDetail(false);
            this.pwShow = true;
          } else {
            this.$store.commit("MyMessage", res.msg);
          }
        }
      });
    },
    payrollClick(e, type, item) {
      // if (this.noPerfect) {
      //   this.wanshanShow1 = true;
      //   return;
      // }
      if (this.pages.total >= 5) {
        // let flag = this.reportInitQuery();
        // console.log(flag);

        let data = {
          enterpriseId: this.enterpriseId,
        };
        reportInitQuery(data).then((res) => {
          // console.log("审核企业建账状态", res);
          this.checkStatus = res.data.checkStatus;
          this.flag = res.data.flag;
          if (this.flag !== "y") {
            this.$store.commit("MyMessage", "未建账用户，只能体验5个人。");
            this.checkFlag = true;
            return false;
          }
          if (this.checkStatus !== "y") {
            this.$store.commit("MyMessage", "未建账用户，只能体验5个人。");
            this.checkFlag1 = true;
            return false;
          }
        });
      }
      let top = e.currentTarget.getBoundingClientRect().top;
      let left = e.currentTarget.getBoundingClientRect().left;
      if (type === 1) {
        // if (this.mouth1 && this.mouth2) {
        //   return;
        // }
        this.popupShow1 = true;
        this.popupShow2 = false;
        this.queryStaff();
      } else {
        this.initialFun();
      }
      this.popupTop = top + 50 + "px";
      this.popupLeft = left - 192 + "px";
      this.$forceUpdate();
    },
    dateChange() {
      return;
      this.init();
    },

    flashTokenPc() {
      if (this.visit === "visit") {
        this.flashTokenPcPlatform();
        return false;
      }
      let that = this;
      let param = {
        userId: this.userId,
        enterpriseId: this.enterpriseId,
      };
      flashTokenPc(param).then((res) => {
        if (res.code == 0) {
          sessionStorage.setItem("pc-tkn", res.data.token);
          this.$forceUpdate();
        }
      });
    },
    flashTokenPcPlatform() {
      // if (this.visit === "visit") return;
      let that = this;
      let param = {
        userId: this.userId,
        enterpriseId: this.enterpriseId,
      };
      flashTokenPcPlatform(param).then((res) => {
        if (res.code == 0) {
          sessionStorage.setItem("pc-tkn", res.data.token);
          this.$forceUpdate();
        }
      });
    },
    //页面跳转
    gotoFun(link) {
      if (link === "/payrollRecord") {
        if (this.list.length === 0) {
          this.$store.commit("MyMessage", "暂无记录");

          return;
        }
        if (!this.list.length) {
          this.$store.commit("MyMessage", "暂无记录");
          return;
        }
      }
      this.$router.push(link);
    },
  },
};
</script>

<style lang="less" scoped>
.selectUn {
  width: 24px;
  height: 24px;
  position: relative;
  top: 1px;
}
.reduction-popup {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 742px;
  height: 640px;
  margin-left: -371px;
  margin-top: -320px;
  background: #ffffff;
  border: 1px solid #cccccc;
}
.reduction-tips {
  padding: 20px 30px 10px;
  color: #999;
  font-size: 14px;
  p {
    font-size: 18px;
    text-align: center;
    padding: 10px 0;
  }
}
.qrStyle {
  margin-right: 15px;
  color: #4c8afc;
  font-size: 12px;
  cursor: pointer;
}
.yijian {
  width: 100px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  color: #ffffff;
  background: #4c8afc;
  border-radius: 4px;
  margin-right: 10px;
  display: inline-block;
  cursor: pointer;
}
.reduction-body {
  padding: 0px 30px 10px;
  height: 458px;
  overflow-y: auto;
  table {
    width: 100%;
    border: 1px solid #dbdbdb;
    th,
    td {
      border: 1px solid #dbdbdb;
      height: 30px;
      text-align: center;
    }
    th {
      background: #ccc;
      color: #333;
      font-weight: 400;
    }
    .td-w1 {
      width: 40px;
    }
    .td-w2 {
      width: 100px;
    }
    .td-w3 {
      width: 110px;
    }
    .td-w4 {
      width: 150px;
    }
    .td-w5 {
      width: 80px;
      span {
        color: #999;
      }
    }
    .td-w6 {
      width: 60px;
      span {
        color: #4c8afc;
        cursor: pointer;
      }
    }
  }
}
.zhizuoPopup {
  width: 320px;
  height: 200px;
  margin-left: -160px;
  margin-top: -150px;
}
.views-mian-content {
  min-height: calc(100% - 114px);
}
.replace-popup {
  position: fixed;
  width: 302px;
  background: #ffffff;
  border: 1px solid #cccccc;
  z-index: 1;
  padding: 10px 0;
  text-align: center;
  .replace-list-box {
    border-bottom: 1px solid #ddd;
    cursor: pointer;
    &:last-child {
      // border-bottom: none;
    }
    .replace-list-r {
      border-bottom: none;
      span {
        font-size: 12px;
        color: #999;
        padding-left: 10px;
      }
    }
    .userHead-logo {
      text-align: center;
    }
  }
}
.replace-btns {
  width: 100%;
  padding: 30px 0;
}
.mouth-mian-title {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: #000000;
  margin: 20px auto 0;
  width: 100%;
}
.mouth-click {
  width: 232px;
  height: 60px;
  display: block;
  margin: 0 auto 20px;
  font-size: 20px;
  font-weight: 700;
  color: #262626;
  border-radius: 6px;
  &:hover {
    background: #4c8afc;
    color: #fff;
  }
}
.payroll-popup-main {
  width: 340px;
  height: 340px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -170px;
  margin-top: -170px;
  background: #ffffff;
  // border: 1px solid #dbdbdb;
  opacity: 1;
  border-radius: 12px;
  text-align: center;
  .mouth-mian-text {
    height: auto !important;
  }
  &.mouth-mian {
    width: 464px;
    max-height: 309px;
    margin-left: -232px;
    height: auto !important;
    padding-bottom: 15px;
  }
  .payroll-popup-main-title {
    font-size: 17px;
    font-weight: bold;
    line-height: 24px;
    color: #000000;
    margin: 80px auto 20px;
    width: 100%;
  }
  .mouth-mian-title {
    font-size: 17px;
    font-weight: 400;
    line-height: 24px;
    color: #000000;
    margin: 20px auto 20px;
    width: 100%;
  }
  .payroll-popup-main-text {
    font-size: 15px;
    font-weight: 400;
    line-height: 24px;
    color: #999999;
    height: 100px;
    &.mouth-mian-text {
      height: 160px;
    }
    .mouth-click {
      width: 294px;
      height: 60px;
      display: block;
      margin: 0 auto 20px;
      font-size: 20px;
      font-weight: bold;
      color: #262626;
      border-radius: 6px;
      &:hover {
        background: #4c8afc;
        color: #fff !important;
      }
    }
  }
  .payroll-popup-main-fotter {
    .fotter-click {
      width: 100%;
      border-radius: 0;
      height: 54px;
      border: none;
      margin: 0;
      font-size: 17px;
      &.closeColor {
        color: #999;
      }
    }
  }
  .personName {
    color: #ff931e;
  }
}
.dataList-nav {
  padding-bottom: 10px;
}
.dataList-nav-box {
  display: inline-block;
  padding: 8px 15px;
  border: 1px solid #dbdbdb;
  border-radius: 5px;
  margin-right: 15px;
  color: #bbb;
  cursor: pointer;
  &.cr {
    border: 1px solid #4c8afc;
    color: #4c8afc;
  }
}
.wanshanTab {
  width: 252px;
  margin: 0 auto;
  border: 1px solid rgba(187, 187, 187, 0.5);
  tr {
    td {
      padding: 5px 0;
      border-bottom: 1px solid rgba(187, 187, 187, 0.5);
      &.td1 {
        width: 60%;
        text-align: left;
        padding-left: 12px;
        color: #262626;
      }
      &.td2 {
        width: 40%;
        padding-right: 12px;
        text-align: right;
        font-size: 12px;
      }
    }
  }
}
</style>
<style lang="less">
.reduction-body {
  .el-input__inner {
    border: none;
  }
}
</style>

