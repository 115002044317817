<template>
  <div class="popup-layer">
    <div class="popup-layer-bg"></div>
    <div class="transition-page">
      <div class="popup-layer-close" @click="rosterClose">
        <svg-icon icon-class="popup-close"></svg-icon>
      </div>
      <div class="transition-title">缴纳个税</div>
      <div class="transition-content">
        <div class="transition-content-top">
          <slot v-if="!errorFlag">
            <svg-icon icon-class="xlsx" class="xlsx"></svg-icon>
            <!-- <p class="transition-p1">{{ monthText }}月工资表个税</p> -->
            <p class="transition-p1">队列处理中...</p>

            <p class="transition-p2">正在查询缴费信息 请等待...</p>

            <!-- <div class="guocheng" style="margin: 20px auto">
              <p>{{ monthText }}月个税总额</p>
              <p>¥{{ taxTotal }}</p>
            </div> -->

            <loadingCss style="margin-top: 20px"></loadingCss>
          </slot>
          <slot v-else>
            <svg-icon icon-class="shenbaoshibai" class="xlsx"></svg-icon>
            <p class="transition-p1" style="color: #e74b47">
              {{ month }} 工资表个税缴款失败
            </p>
            <p style="padding-top: 50px; color: #999; font-size: 14px">
              失败原因：{{ jksbyy }}
            </p>
            <button class="cxjf" @click="getAgreementBankInfo()">
              重新缴款
            </button>
          </slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  queryMonthSalaryListV2,
  queryShenbaoStatusV2,
  getTaxPaymentResult,
  getAgreementBankInfo,
  arrearsQuery,
} from "@/http/api";
export default {
  data() {
    return {
      // userId: this.$getCookie("userId"),
      // enterpriseId: this.$getCookie("enterpriseId"),

      enterpriseId: sessionStorage.getItem("enterpriseId"),
      userId: sessionStorage.getItem("userId"),
      flag: 1,
      flags: {
        flag1: false,
        flag2: false,
        flag3: false,
        flag4: false,
        flag5: false,
      },
      month: "",
      batch: "",
      deSalary: "",
      ybtse: "",
      errorFlag: false,
      incomeTaxFlag: false,
      leaveFlag: false,
      jksbyy: "",
      sfxyh: "",
      setTime: null,
      taxTotal: "",
      monthText: "",
      totalCount: "0",
    };
  },
  mounted() {
    this.month = this.$route.query.month;
    this.batch = this.$route.query.batch;
    this.monthText = this.month.split("-")[1];
    this.leaveFlag = true;
    this.queryMonthSalaryListV2();
    // this.queryShenbaoStatusV2();
    this.arrearsQuery();
  },
  destroyed() {
    clearInterval(this.setTime);
    this.leaveFlag = false;
    this.$forceUpdate();
  },
  methods: {
    arrearsQuery() {
      let param = {
        enterpriseId: this.enterpriseId,
        taxMonth: this.month,
        userId: this.userId,
      };
      arrearsQuery(param).then((res) => {
        // this.totalCount = res.data.comprehensive.totalCount;
        if (res.code === 0) {
          if (res.data.length === 0) {
            setTimeout(() => {
              if (this.shenbaoStatus == 60) {
                if (this.payStatus === 300 || this.payStatus === 301) {
                  this.arrearsQuery();
                }
              }
            }, 5000);
            return;
          }
          if (res.data[0].status === "0" || res.data[0].status === "-1") {
            setTimeout(() => {
              this.arrearsQuery();
            }, 5000);
          } else {
            this.taxTotal = 0;
            res.data.forEach((e1) => {
              e1.salaryTaxArrears.forEach((e2) => {
                e2.detailList.forEach((e3) => {
                  if (e3.ybtse !== "")
                    this.taxTotal += e3.ybtse * 1 + e3.znje * 1;
                });
              });
            });
            this.taxTotal = this.taxTotal.toFixed(2);
          }
        }
      });
    },
    rosterClose() {
      this.$router.push("/payrollRecord");
    },
    getAgreementBankInfo() {
      // 查询协议银行
      let param = {
        enterpriseId: this.enterpriseId,
        taxMonth: this.month,
      };
      getAgreementBankInfo(param).then((res) => {
        console.log(res);
        if (res.code === 0) {
          if (res.data.status === "1") {
            // 更新的时候调取
            // this.queryAgreementBankInfo();
            console.log(res.data.banksList[0]);
            this.sfxyh = res.data.banksList[0].sfxyh;
          }
        }
      });
    },
    taxPayment() {
      if (!this.sfxyh) return;
      let param = {
        enterpriseId: this.enterpriseId,
        taxMonth: this.month,
        sfxyh: this.sfxyh,
      };
      taxPayment(param).then((res) => {
        console.log(res);
        if (res.code === 0) {
          this.queryMonthSalaryListV2();
        }
      });
    },
    queryShenbaoStatusV2() {
      let param = {
        enterpriseId: this.enterpriseId,
        month: this.month,
        userId: this.userId,
      };
      queryShenbaoStatusV2(param).then((res) => {
        console.log(res);
        // this.taxTotal = res.data.zhsd[0].srze;
        if (res.code === 0) {
          // this.deSalary = res.data.zhsd[0].srze;
        }
        // this.ybtse = res.data.zhsd[0].ybtse;
        // this.totalCount = res.data.zhsd[0].nsrc;

        // this.incomeTaxFlag = true;
      });
    },
    queryMonthSalaryListV2() {
      let param = {
        enterpriseId: this.enterpriseId,
        userId: this.userId,
      };
      queryMonthSalaryListV2(param).then((res) => {
        if (res.code === 0) {
          let date = this.month.split("-")[0] + this.month.split("-")[1];
          res.data.forEach((e) => {
            if (e.month + "" === date) {
              e.baoshuiList.forEach((e1) => {
                if (e1.month + "" === date) {
                  this.status = e1.status;
                  let payStatus = e1.payStatus;
                  if (payStatus === 302) {
                    this.$emit("tFun");
                  } else if (payStatus === 301) {
                    // 缴款中
                    this.setTime = setTimeout(() => {
                      if (this.leaveFlag) this.queryMonthSalaryListV2();
                    }, 5000);
                  } else {
                    // 缴款失败
                    this.getTaxPaymentResult();
                  }
                }
              });
            }
          });
        }
      });
    },
    getTaxPaymentResult() {
      let param = {
        enterpriseId: this.enterpriseId,
        taxMonth: this.month,
        userId: this.userId,
      };
      getTaxPaymentResult(param).then((res) => {
        if (res.code === 0) {
          console.log(res);
          if (res.data.length > 0) {
            this.jksbyy = res.data[0].jksbyy;
          }
          this.errorFlag = true;
        }
      });
    },
    goFun(str) {
      this.$router.push(str);
    },
  },
};
</script>

<style lang="less" scoped>
.cxjf {
  width: 251px;
  height: 54px;
  background: #ffffff;
  border: 1px solid #4c8afc;
  opacity: 1;
  border-radius: 6px;
  font-size: 20px;
  font-weight: bold;
  color: #4c8afc;
  margin-top: 50px;
  cursor: pointer;
}
.loading-icon {
  width: 24px;
  height: 24px;
  margin-top: 30px;
}
.xlsx {
  font-size: 36px;
  padding: 130px 0 10px;
}
.transition-page {
  margin: 0 auto;
  text-align: center;
  width: 460px;
  height: 450px;
  background: #ffffff;
  opacity: 1;
  border-radius: 6px;
  z-index: 999;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -230px;
  margin-top: -305px;
  .transition-title {
    font-size: 20px;
    padding: 28px 0 0px;
    text-align: center;
    color: #262626;
    font-weight: bold;
  }
  .transition-content {
    .transition-p1 {
      color: #262626;
      font-size: 20px;
      padding-bottom: 5px;
      font-weight: bold;
      padding-top: 10px;
    }
    .transition-p2 {
      color: #bbb;
      font-size: 12px;
      letter-spacing: 1px;
      padding-top: 2px;
    }
    .guocheng {
      width: 360px;
      margin: 60px auto;
      text-align: left;
      p {
        position: relative;
        font-size: 16px;
        margin-bottom: 10px;
        color: #999;
        text-align: center;
        i {
          position: absolute;
          left: -20px;
          top: 1px;
          font-size: 16px;
        }
        .span1 {
          position: absolute;
          right: 0;
          top: 0px;
          font-size: 16px;
          color: #33c481;
        }
        .span2 {
          position: absolute;
          right: 0;
          top: 0px;
          font-size: 16px;
          // color: #e75850;
          color: #bbb;
        }
      }
    }
  }
}
</style>