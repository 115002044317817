<template>
  <div class="department">
    <div class="department-mian">
      <div class="department-header department-width">
        <div class="department-link" @click="gotoFun('/roster')">
          <i class="el-icon-arrow-left"></i>返回
        </div>
        部门设置
      </div>
      <div class="department-list department-width">
        <div
          class="department-box"
          v-for="(item, i) in departmentList"
          :key="i"
        >
          <div class="department-box-l">
            <table class="accountTable">
              <tbody>
                <tr>
                  <td class="enter-td">部门名称</td>
                  <td>
                    <!-- <slot v-if="!item.edit">{{ item.departmentName }}</slot> -->

                    <input
                      type="text"
                      :readonly="!item.edit"
                      v-model="item.departmentName"
                      placeholder="请输入新增部门名称"
                      class="department-input add-department-input"
                    />
                  </td>
                </tr>
                <tr>
                  <td class="enter-td"></td>
                  <td>
                    <el-select
                      v-model="item.departmentFuncitonType"
                      placeholder="请选择部门职能"
                      :disabled="!item.edit"
                    >
                      <el-option
                        v-for="item in typeList"
                        :key="item.id"
                        :label="item.text1"
                        :value="item.id"
                        :disabled="item.disabled"
                      >
                      </el-option>
                    </el-select>
                    <!-- {{ item.text1 }} -->
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="department-box-r">
            <div class="department-btn">
              <span
                v-if="!item.edit"
                class="el-icon-edit-outline inner-edit-icon"
                @click="item.edit = true"
              ></span>
              <span
                v-if="item.edit"
                class="el-icon-check inner-edit-icon"
                @click="editFun(item)"
              ></span>
              <span
                style="float: left; color: #bbb"
                class="el-icon-remove-outline inner-del-icon"
                @click="deleteEnterpriseDepartment(item, i)"
              ></span>
              <span
                class="el-icon-circle-plus-outline inner-add-icon"
                v-if="i === 0 && !addDepart"
                @click="addDepart = true"
              ></span>
            </div>
          </div>
        </div>
        <div class="department-box" v-if="addDepart">
          <div class="department-box-l">
            <table class="accountTable">
              <tbody>
                <tr>
                  <td class="enter-td">部门名称</td>
                  <td>
                    <input
                      type="text"
                      v-model="addValue"
                      placeholder="请输入新增部门名称"
                      class="department-input add-department-input"
                    />
                  </td>
                </tr>
                <tr>
                  <td class="enter-td"></td>
                  <td class="enter-td2">
                    <el-select v-model="addType" placeholder="请选择部门职能">
                      <el-option
                        v-for="item in typeList"
                        :key="item.id"
                        :label="item.text1"
                        :value="item.id"
                        :disabled="item.disabled"
                      >
                      </el-option>
                    </el-select>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="department-box-r">
            <div class="department-btn">
              <span
                style="color: #bbb"
                class="el-icon-remove-outline inner-del-icon"
                @click="addDepart = !addDepart"
              ></span>
            </div>
          </div>
        </div>
        <div class="department-box">
          <div class="department-box-l">
            <el-button class="plainStyle saveBtn" @click="addClick" plain
              >保 存</el-button
            >
          </div>
          <div class="department-box-r"></div>
        </div>
      </div>
      <div class="clear"></div>
      <TheFooter></TheFooter>
    </div>
  </div>
</template>

<script>
import {
  queryEnterpriseDepartment,
  addEnterpriseDepartment,
  deleteEnterpriseDepartment,
  modifyEnterpriseDepartment,
} from "@/http/api";
import TheFooter from "@/components/TheFooter.vue";
export default {
  data() {
    return {
      userId: sessionStorage.getItem("userId"),
      enterpriseId: sessionStorage.getItem("enterpriseId"),
      addValue: "",
      addType: "",
      addDepart: false,
      typeList: [
        {
          id: 11,
          text1: "管理后勤服务类",
          text2: "服务类",
          disabled: false,
        },
        {
          id: 10,
          text1: "市场销售运营类",
          text2: "运营类",
          disabled: false,
        },
        {
          id: 12,
          text1: "产品设计研发类",
          text2: "研发类",
          disabled: false,
        },
        {
          id: 4,
          text1: "生产制造管理类",
          text2: "管理类",
          disabled: true,
        },
        {
          id: 5,
          text1: "生产制造工人类",
          text2: "工人类",
          disabled: true,
        },
      ],
      departmentList: [],
    };
  },
  mounted() {
    this.queryEnterpriseDepartment();
  },
  methods: {
    open(msg) {
      this.$alert(msg, "", {
        center: true,
        confirmButtonText: "我知道了",
        dangerouslyUseHTMLString: true,
        callback: (action) => {},
      });
    },
    addClick() {
      if (!this.addDepart) return;
      if (!this.addValue) {
        this.$store.commit("MyMessage", "部门名称不能为空");
        return false;
      } else if (!this.addType) {
        this.$store.commit("MyMessage", "未选择类别");
        return false;
      }
      let param = {
        userId: this.userId,
        enterpriseId: this.enterpriseId,
        departmentName: this.addValue,
        departmentFuncitonType: this.addType,
        rdDirection: 10,
      };
      this.addEnterpriseDepartment(param);
    },

    //添加部门
    addEnterpriseDepartment(param) {
      addEnterpriseDepartment(param).then((res) => {
        if (res.code === 0) {
          console.log("addEnterpriseDepartment", res);
          this.addValue = "";
          this.addType = "";
          this.$store.commit("MyMessage", "添加成功");
          this.queryEnterpriseDepartment();
        }
      });
    },
    //删除部门
    deleteEnterpriseDepartment(item, index) {
      let that = this;
      let param = {
        userId: this.userId,
        enterpriseId: this.enterpriseId,
        departmentId: item.departmentId,
      };
      deleteEnterpriseDepartment(param).then((res) => {
        console.log("queryEnterpriseDepartment", res);
        if (res.code === 0) {
          this.departmentList.splice(index, 1);
          this.$store.commit("MyMessage", "操作成功");
          this.queryEnterpriseDepartment();
        } else {
          if (res.msg === "该部门还有员工") {
            this.open(`请将该部门下员工移出后<br>再删除`);
          } else {
            this.open(res.msg);
          }
        }
      });
    },
    //修改部门
    editFun(item) {
      let that = this;
      let param = {
        userId: this.userId,
        enterpriseId: this.enterpriseId,
        departmentId: item.departmentId,
        departmentName: item.departmentName,
        departmentFuncitonType: item.departmentFuncitonType,
        rdDirection: 10,
      };
      modifyEnterpriseDepartment(param).then((res) => {
        if (res.code === 0) {
          this.$store.commit("MyMessage", "修改成功");
          item.edit = false;
        } else {
          this.$store.commit("MyMessage", res.msg);
        }
      });
    },
    queryEnterpriseDepartment() {
      let that = this;
      let param = {
        userId: this.userId,
        enterpriseId: this.enterpriseId,
      };
      queryEnterpriseDepartment(param).then((res) => {
        if (res.code === 0) {
          sessionStorage.setItem("departmentList", JSON.stringify(res.data));
          res.data.forEach((element) => {
            this.typeList.forEach((element1) => {
              if (element.departmentFuncitonType === element1.id) {
                element.text1 = element1.text1;
                element.text2 = element1.text2;
                element.edit = false;
              }
            });
          });
          this.departmentList = res.data;
        } else {
          this.$store.commit("MyMessage", res.msg);
        }
      });
    },
    gotoFun(path) {
      this.$router.push(path);
    },
  },
  components: {
    TheFooter,
  },
};
</script>

<style lang="less" scoped>
.department-width {
  width: 640px;
  margin: 0 auto;
}
.department-box {
  width: 100%;
  .department-box-l {
    width: calc(100% - 80px);
    float: left;
  }
  .department-box-r {
    width: 70px;
    margin-left: 10px;
    float: left;
    position: relative;
  }
}
.accountTable {
  margin-bottom: 15px;
  tbody {
    tr {
      &:hover {
        background: none;
      }
    }
  }
  td {
    text-align: left;
    padding: 0 10px;
    &:first-child {
      text-align: center;
      width: 90px;
      background: #f8f9fa;
    }
    &.td-padding {
      padding: 0;
      padding-left: 20px;
      line-height: 30px;
    }
    input {
      width: 100%;
      height: 100%;
      &::placeholder {
        color: #bbb;
      }
    }
  }
}
.department-btn {
  padding: 47px 0;
  width: 100%;
}
.saveBtn {
  width: 100%;
  border: 1px solid #4c8afc;
  color: #4c8afc;
  margin-top: 10px;
}
.inner-add-icon {
  color: #4c8afc;
  cursor: pointer;
  font-weight: bold;
  font-size: 16px;
  margin-left: 15px;
}

.inner-del-icon {
  cursor: pointer;
  font-weight: bold;
  font-size: 16px;
  margin-left: 10px;
}
.inner-edit-icon {
  float: left;
  color: #4c8afc;
  cursor: pointer;
  font-weight: bold;
  font-size: 16px;
  margin-left: 10px;
  position: absolute;
  left: 0;
  top: 14px;
}
</style>
<style lang="less">
.department {
  .el-select {
    width: 100%;

    height: 55px;
    line-height: 55px;
    .el-input__inner {
      height: 30px;
      line-height: 30px;
      outline: 0;
      padding: 0;
      border: none;
    }
    .is-disabled {
      .el-input__inner {
        background: none;
        color: #262626;
        cursor: default;
      }
      .el-input__suffix {
        display: none;
      }
    }
  }
}
.el-message-box__btns {
  text-align: center;
}
.el-message-box {
  width: 275px;
  text-align: center;
  border-radius: 10px;
}
.enter-td2 {
  &:hover {
    // background: #fff;
  }
  .el-select {
    .el-input {
      .el-select__caret {
        color: #262626;
        font-weight: bold;
        font-size: 16px;
      }
      .el-input__inner {
        height: 55px;
        background: none;
        &::placeholder {
          color: #bbb;
        }
      }
    }
  }
}
</style>