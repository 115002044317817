<template>
  <div class="department">
    <div
      class="department-mian"
      id="views-mian-scroll"
      :class="{ overflowStyle: overflowStyle }"
    >
      <div class="department-header department-width">
        <div class="department-link" @click="gotoFun('/roster')">
          <i class="el-icon-arrow-left"></i>返回
        </div>
        {{ staffTitle }}
        <statusBoxLayer
          v-if="
            statusShow &&
            enterpriseEmployee.isAdmin !== 1 &&
            enterpriseEmployee.userId !== userId
          "
          :item="statusItem"
          :ajaxData="enterpriseEmployee"
        ></statusBoxLayer>
      </div>
      <div class="department-list department-width" v-if="addStaffShow">
        <div class="addStaff-main">
          <!-- <div class="tips-box" v-if="sbyyList.length > 0">
            <span v-for="(e, i) in sbyyList" :key="i">
              <slot v-if="sbyyList.length > 1">{{ i + 1 }}、</slot>{{ e.title }}
            </span>
          </div> -->
          <div class="">
            <div class="addStaff-list">
              <p class="list-title-style">主要信息</p>
              <slot v-for="(e, i1) in employee.zhuyao">
                <StaffBox
                  :key="'zhuyao-' + i1"
                  :item="e"
                  index1="0"
                  :index2="i1"
                  :ajaxData="enterpriseEmployee"
                  @staffParent="staffParent"
                  :staffType="staffType"
                ></StaffBox>
              </slot>
              <slot v-if="enterpriseEmployee.idType > 1">
                <p class="list-title-style marTop">涉外信息</p>
                <slot v-for="(e, i1) in employee.shewai">
                  <StaffBox
                    :key="'shewai-' + i1"
                    :item="e"
                    index1="0"
                    :index2="i1"
                    :ajaxData="enterpriseEmployee"
                    @staffParent="staffParent"
                    :staffType="staffType"
                  ></StaffBox>
                </slot>
              </slot>
            </div>
            <div class="addStaff-list">
              <p class="list-title-style">基本信息</p>
              <slot v-for="(e, i1) in jiben">
                <StaffBox
                  :key="'jiben-' + i1"
                  :item="e"
                  index1="0"
                  :index2="i1"
                  :ajaxData="enterpriseEmployee"
                  @staffParent="staffParent"
                  :staffType="staffType"
                ></StaffBox>
              </slot>
            </div>
            <div class="addStaff-list">
              <p class="list-title-style">岗位信息</p>
              <slot v-for="(e, i1) in employee.gangwei">
                <StaffBox
                  :key="'gangwei-' + i1"
                  :item="e"
                  index1="0"
                  :index2="i1"
                  :ajaxData="enterpriseEmployee"
                  @staffParent="staffParent"
                  :staffType="staffType"
                ></StaffBox>
              </slot>
              <p class="list-title-style marTop">工资卡信息</p>
              <slot v-for="(e, i1) in employee.gongzika">
                <StaffBox
                  :key="'gongzika-' + i1"
                  :item="e"
                  index1="0"
                  :index2="i1"
                  :ajaxData="enterpriseEmployee"
                  @staffParent="staffParent"
                  :staffType="staffType"
                ></StaffBox>
              </slot>
            </div>
          </div>
          <div class="clear"></div>
          <div class="addStaff-line-box">
            <div class="addStaff-line-btn" @click="isShow = !isShow">
              更多信息
              <i class="el-icon-arrow-down" :class="{ isRotate: !isShow }"></i>
            </div>
          </div>
          <div style="overflow: hidden; margin-bottom: 30px" v-if="isShow">
            <div class="addStaff-list addStaff-list-bottom">
              <slot v-for="(e, i1) in qita">
                <StaffBox
                  v-if="i1 < 3"
                  :key="'info1-' + i1"
                  :item="e"
                  index1="0"
                  :index2="i1"
                  :ajaxData="enterpriseEmployee"
                  @staffParent="staffParent"
                  :staffType="staffType"
                ></StaffBox>
              </slot>
            </div>
            <div class="addStaff-list addStaff-list-bottom">
              <!-- 残疾  烈属 -->
              <slot v-for="(e, i1) in qita">
                <slot v-if="i1 >= 3 && i1 < qita.length - 3">
                  <slot v-if="e.field === 'disabilityNum'">
                    <StaffBox
                      v-if="qita[i1 - 1].value === '1'"
                      :key="'info2-' + i1"
                      :item="e"
                      index1="0"
                      :index2="i1"
                      :ajaxData="enterpriseEmployee"
                      @staffParent="staffParent"
                      :staffType="staffType"
                    ></StaffBox>
                  </slot>
                  <slot v-else-if="e.field === 'martyrFamilyNum'">
                    <StaffBox
                      v-if="qita[i1 - 1].value === '1'"
                      :key="'info2-' + i1"
                      :item="e"
                      index1="0"
                      :index2="i1"
                      :ajaxData="enterpriseEmployee"
                      @staffParent="staffParent"
                      :staffType="staffType"
                    ></StaffBox>
                  </slot>
                  <slot v-else>
                    <StaffBox
                      :key="'info2-' + i1"
                      :item="e"
                      index1="0"
                      :index2="i1"
                      :ajaxData="enterpriseEmployee"
                      @staffParent="staffParent"
                      :staffType="staffType"
                    ></StaffBox>
                  </slot>
                </slot>
              </slot>
            </div>
            <div class="addStaff-list addStaff-list-bottom">
              <slot v-for="(e, i1) in qita">
                <StaffBox
                  v-if="i1 >= qita.length - 3"
                  :key="'info3-' + i1"
                  :item="e"
                  index1="0"
                  :index2="i1"
                  :ajaxData="enterpriseEmployee"
                  @staffParent="staffParent"
                  :staffType="staffType"
                ></StaffBox>
              </slot>
            </div>
          </div>
          <div class="clear"></div>
        </div>
        <div class="clear"></div>
        <div class="department-btn">
          <slot v-if="status !== 10">
            <div
              class="addStaff-btn"
              v-if="baosongStatus + '' !== '1'"
              @click="addStaffSave"
            >
              保 存
            </div>
            <div
              v-else
              class="addStaff-btn"
              style="opacity: 0.5; border-color: rgb(76 138 252 / 0.5)"
            >
              报送中
            </div>
          </slot>
          <slot v-if="status === 10">
            <div
              class="addStaff-btn addStaff-del"
              @click="checkApplyJoinEnterprise(2)"
            >
              删除信息
            </div>
            <div
              class="addStaff-btn addStaff-submit"
              @click="checkApplyJoinEnterprise(1)"
            >
              同意加入
            </div>
          </slot>
          <!-- <div class="addStaff-btn" @click="addStaffSave">添 加</div> -->
        </div>
      </div>
      <div class="clear"></div>
      <TheFooter></TheFooter>
    </div>
    <div class="popup-layer" v-if="tishiFlag">
      <div class="popup-layer-bg" @click="rosterClose"></div>
      <div class="huifuPopup zhizuoPopup">
        <div class="popup-layer-close" @click="rosterClose">
          <svg-icon icon-class="popup-close"></svg-icon>
        </div>
        <div class="huifuPopup-content">
          <p class="huifuPopup-p1">小提示</p>
          <p class="huifuPopup-p5">
            员工离职，如果工资表有该名员工，
            请及时到工资，设置“离职停薪”将员工移出工资表。
          </p>
          <div class="huifuPopup-btns">
            <!-- <div class="huifuPopup-btn-l" @click="rosterClose">取 消</div> -->
            <div class="huifuPopup-btn-r" @click="rosterClose">我知道了</div>
          </div>
        </div>
      </div>
    </div>
    <div class="popup-layer" v-if="workFlowFlag">
      <div class="popup-layer-bg" @click="workFlowFlag = false"></div>
      <div class="workFlowPopup">
        <div class="popup-layer-close" @click="workFlowFlag = false">
          <svg-icon icon-class="popup-close"></svg-icon>
        </div>
        <div class="workFlowPopup-content list-h">
          <div class="workFlowPopup-title">待审批列表</div>
          <div class="workFlowPopup-table">
            <table class="accountTable">
              <thead>
                <tr>
                  <th>类型</th>
                  <th>申请人</th>
                  <th>当前状态</th>
                  <th>操作</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(e, i) in flowList" :key="i">
                  <td>
                    <slot v-if="e.bussType === 10">票据报销</slot>
                    <slot v-else-if="e.bussType === 20">记账</slot>
                    <slot v-else-if="e.bussType === 21">销项票记账</slot>
                    <slot v-else-if="e.bussType === 30">付款申请</slot>
                    <slot v-else-if="e.bussType === 40">工资</slot>
                    <slot v-else-if="e.bussType === 41">缓发工资</slot>
                    <slot v-else-if="e.bussType === 50">请假</slot>
                    <slot v-else-if="e.bussType === 60">采购</slot>
                    <slot v-else-if="e.bussType === 70">出差</slot>
                    <slot v-else-if="e.bussType === 80">文档</slot>
                    <slot v-else-if="e.bussType === 90">加班</slot>
                    <slot v-else-if="e.bussType === 91">外勤</slot>
                    <slot v-else-if="e.bussType === 92">打卡异常</slot>
                    <slot v-else-if="e.bussType === 93">迟到</slot>
                    <slot v-else-if="e.bussType === 94">早退</slot>
                    <slot v-else-if="e.bussType === 95">考勤表</slot>
                    <slot v-else-if="e.bussType === 96">旷工</slot>
                    <slot v-else-if="e.bussType === 97">调休假</slot>
                  </td>
                  <td>{{ e.userName }}</td>
                  <!-- <td>{{ e.states }}</td> -->
                  <td>
                    <slot v-if="e.type === 1">提交审批</slot>
                    <slot v-else-if="e.type === 2">待审批</slot>
                    <slot v-else-if="e.type === 3">转审</slot>
                    <slot v-else-if="e.type === 4">审批同意</slot>
                    <slot v-else-if="e.type === 5">等待付款</slot>
                    <slot v-else-if="e.type === 6">已付款</slot>
                    <slot v-else-if="e.type === 7">驳回</slot>
                    <slot v-else-if="e.type === 8">确认收款方式</slot>
                    <slot v-else-if="e.type === 9">已撤销</slot>
                    <slot v-else-if="e.type === 10">已收款</slot>
                    <slot v-else-if="e.type === 11">已作废</slot>
                  </td>
                  <td>
                    <slot v-if="e.userId === uid">
                      <span
                        class="t-span"
                        v-if="e.type === 2 || e.type === 3"
                        @click="zhuanshenFun(e, 3)"
                      >
                        转审
                      </span>
                      <span
                        class="t-span"
                        v-else-if="e.type === 5"
                        @click="zhuanshenFun(e, 6)"
                      >
                        变更付款人
                      </span>
                      <span
                        class="t-span"
                        v-else-if="e.type === 8"
                        @click="zhuanshenFun(e, 8)"
                      >
                        变更收款人
                      </span>
                      <span v-else> --- </span>
                    </slot>
                    <slot v-else>--</slot>
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="loadMore" v-if="pagesShow" @click="flowMore">
              加载更多
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="popup-layer" v-if="shenpiFlag">
      <div class="popup-layer-bg" @click="shenpiFlag = false"></div>
      <div class="workFlowPopup">
        <div class="popup-layer-close" @click="shenpiFlag = false">
          <svg-icon icon-class="popup-close"></svg-icon>
        </div>
        <div class="workFlowPopup-content">
          <div class="workFlowPopup-title">选择人员</div>
          <div class="workFlowPopup-table">
            <div class="workFlowPopup-body">
              <div class="workFlowPopup-l">人员列表:</div>
              <div class="workFlowPopup-r">
                <el-select
                  v-model="approvalId"
                  @change="selectChange"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in approvalList"
                    :key="item.userId"
                    :label="item.name"
                    :value="item.userId"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>
            <div class="workFlowPopup-footer">
              <div class="workFlowPopup-btn" @click="submitFun">确 认</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import bus from "@/util/bus";
import {
  getEmployeeInfo,
  prefectEnterpriseEmployee,
  modifyEnterpriseEmployee,
  checkApplyJoinEnterprise,
  getEmployeeInfoUserId,
  dimission,
  workFlowList,
  getEnterpriseEmployeeCheckers,
  changePayee,
  workflowToOther,
  payReject,
  exchangePayer,
  getEmployeeFukuan, //付款人
  poapprove, //考勤处转审
} from "@/http/api";
import TheFooter from "@/components/TheFooter.vue";
import StaffBox from "./components/StaffBox";
import statusBoxLayer from "./components/statusBoxLayer";
export default {
  data() {
    return {
      options: [
        {
          value: "选项1",
          label: "黄金糕",
        },
        {
          value: "选项2",
          label: "双皮奶",
        },
        {
          value: "选项3",
          label: "蚵仔煎",
        },
        {
          value: "选项4",
          label: "龙须面",
        },
        {
          value: "选项5",
          label: "北京烤鸭",
        },
      ],
      value: "",
      approvalId: "",
      shenpiFlag: false,
      isShow: false,
      statusShow: false,
      tishiFlag: false,
      addStaffShow: false,
      overflowStyle: false,
      userId: sessionStorage.getItem("userId"),
      enterpriseId: sessionStorage.getItem("enterpriseId"),
      employeeDate: this.$config.cloneObj(
        this.$dictionaries.employeeDateOld.child1
      ),
      oldData: {},

      employee: this.$config.cloneObj(this.$dictionaries.employeeDateOld),
      jiben: this.$config.cloneObj(this.$dictionaries.employeeDateOld.jiben),
      qita: this.$config.cloneObj(this.$dictionaries.employeeDateOld.qita1),
      enterpriseEmployee: {},
      staffType: "details",
      staffTitle: "",
      status: "",
      employeeId: "",
      uid: "",
      baosongStatus: "2",
      statusItem: {
        text: "员工状态",
        field: "status",
        type: "status",
        value: "",
        dimissionTime: "",
        desc: "",
        tips: "修改员工状态",
      },
      oldIdtype: null,
      sbyyList: [],
      flowList: [],
      FukuanList: [],
      approvalList: [],
      workFlowFlag: false,
      pagesShow: true,
      pages: {
        pageNum: 1,
        pageSize: 20,
        total: 0,
      },
      flowItem: {},
      selectItem: {},
      approvalResult: "",
      infoUser: JSON.parse(sessionStorage.getItem("infoUser")),
    };
  },
  components: {
    TheFooter,
    StaffBox,
    statusBoxLayer,
  },
  mounted() {
    let _route = this.$route.query;
    this.employeeId = _route.eid;
    this.uid = _route.uid;
    this.workFlowList(_route.uid, _route.eid);
    if (_route.uid) {
      this.getEmployeeInfo(_route.uid, _route.eid);
    }
    setInterval(() => {
      this.overflowStyle = this.$config.hasScrolled(
        "views-mian-scroll",
        "horizontal"
      );
    }, 100);
  },
  methods: {
    submitFun() {
      console.log(this.approvalId);
      if (this.approvalId === "") {
        this.$store.commit("MyMessage", "请先选择审批人");
        return false;
      }

      if (this.approvalResult === 3) {
        if (
          this.flowItem.bussType === 50 ||
          this.flowItem.bussType === 60 ||
          this.flowItem.bussType === 70 ||
          this.flowItem.bussType === 80 ||
          this.flowItem.bussType === 90 ||
          this.flowItem.bussType === 91 ||
          this.flowItem.bussType === 92 ||
          this.flowItem.bussType === 93 ||
          this.flowItem.bussType === 94 ||
          this.flowItem.bussType === 95 ||
          this.flowItem.bussType === 96 ||
          this.flowItem.bussType === 97
        ) {
          this.poapprove();
          return false;
        }
        this.workflowToOther();
      } else if (this.approvalResult === 6) {
        this.exchangePayer();
      } else if (this.approvalResult === 8) {
        this.changePayee();
      }
    },
    zhuanshenFun(e, type) {
      console.log(e);
      this.approvalResult = type;
      this.flowItem = e;
      if (type === 3) {
        this.getEnterpriseEmployeeCheckers();
      } else {
        this.getEmployeeFukuan();
      }
      this.shenpiFlag = true;
      this.workFlowFlag = false;
    },
    getEnterpriseEmployeeCheckers() {
      let that = this;
      let param = {
        enterpriseId: this.enterpriseId,
        userId: this.userId,
        pageNum: "1",
        pageSize: "9999",
      };
      getEnterpriseEmployeeCheckers(param).then((res) => {
        // console.log(res);
        if (res.code === 0) {
          // this.approvalList = res.data.list;
          this.approvalList = [];
          res.data.list.forEach((element, index) => {
            if (this.employeeId !== element.employeeId) {
              this.approvalList.push(element);
            }
          });
        }
      });
    },
    getEmployeeFukuan() {
      let param = {
        enterpriseId: this.enterpriseId,
        userId: this.userId,
      };
      getEmployeeFukuan(param).then((res) => {
        console.log("getEmployeeFukuan", res.data);
        this.approvalList = [];
        res.data.forEach((element) => {
          if (this.employeeId !== element.employeeId) {
            this.approvalList.push(element);
          }
        });
      });
    },
    selectChange() {
      this.approvalList.forEach((element) => {
        if (element.userId === this.approvalId) {
          this.selectItem = element;
        }
      });
    },
    rosterClose() {
      this.tishiFlag = false;
      this.gotoFun("/roster");
    },
    flowMore() {
      this.pages.pageNum++;
      this.workFlowList();
    },
    workFlowList(uid, eid) {
      let param = {
        enterpriseId: this.enterpriseId,
        userId: this.uid,
        employeeId: this.employeeId,
        pageNum: this.pages.pageNum,
        pageSize: this.pages.pageSize,
      };
      workFlowList(param).then((res) => {
        console.log(res);
        if (res.code === 0) {
          // console.log(res);
          if (res.data.length < this.pages.pageSize) this.pagesShow = false;
          this.flowList = [];
          res.data.forEach((e) => {
            e.nodeList.sort(function (a, b) {
              return b.sortNo - a.sortNo;
            });
            e.nodeList[0].bussType = e.bussType;
            e.nodeList[0].workflowId = e.workflowId;
            this.flowList.push(e.nodeList[0]);
          });
        }
      });
    },
    workflowToOther() {
      // 票据审批-转审PC
      let param = {
        enterpriseId: this.enterpriseId,
        opUserId: this.userId, //操作人的id
        userId: this.enterpriseEmployee.userId, //选择的审批人的id
        workFlowId: this.flowItem.workflowId, //流程id
        approvalResult: 3, //审批结果[4同意，6驳回,3转审]
        toCheckerApproval: this.approvalId, //审批人[approvalResult为3时必输]
        remark:
          this.infoUser.name +
          "操作员工" +
          this.oldData.name +
          "离职，进行转审操作",
      };
      // if (this.approvalResult === 6) {
      //   param.rejectReason = "离职转审";
      // }
      // if (this.approvalResult === 3) {
      //   param.toCheckerApproval = this.approvalId;
      // }
      console.log(param);
      workflowToOther(param).then((res) => {
        if (res.code === 0) {
          console.log(res);
          this.shenpiFlag = false;
          this.workFlowFlag = true;
          this.$store.commit("MyMessage", "操作成功");
          this.workFlowList(this.uid, this.employeeId);
        } else {
          this.$store.commit("MyMessage", res.msg);
        }
      });
    },
    payReject() {
      // 工资驳回
      let param = {
        enterpriseId: this.enterpriseId,
        opUserId: this.userId, //操作人的id
        userId: this.enterpriseEmployee.userId, //选择的审批人的id
        workFlowId: this.flowItem.workflowId, //流程id
        approvalResult: 3, //审批结果[4同意，6驳回,3转审]
        toCheckerApproval: this.approvalId, //审批人[approvalResult为3时必输]
        remark:
          this.infoUser.name +
          "操作员工" +
          this.oldData.name +
          "离职，进行驳回操作",
      };
      console.log(param);
      payReject(param).then((res) => {
        if (res.code === 0) {
          console.log(res);
          this.shenpiFlag = false;
          this.workFlowFlag = true;
          this.$store.commit("MyMessage", "操作成功");
          this.workFlowList(this.uid, this.employeeId);
        } else {
          this.$store.commit("MyMessage", res.msg);
        }
      });
    },
    changePayee() {
      // 票据审批-变更收款人
      let param = {
        opUserId: this.userId, //操作人的id
        userId: this.enterpriseEmployee.userId, //原付款人
        toUserId: this.approvalId, //新付款人
        workFlowId: this.flowItem.workflowId, //流程id
        enterpriseId: this.enterpriseId,
        rejectReason: "用户已离职，变更收款人", //变更原因
        remark:
          this.infoUser.name +
          "操作员工" +
          this.oldData.name +
          "离职，进行变更收款人操作",
      };
      changePayee(param).then((res) => {
        if (res.code === 0) {
          console.log(res);
          this.shenpiFlag = false;
          this.workFlowFlag = true;
          this.workFlowList(this.uid, this.employeeId);
        } else {
          this.$store.commit("MyMessage", res.msg);
        }
      });
    },
    exchangePayer() {
      // 票据审批-变更付款人
      let param = {
        opUserId: this.userId, //操作人的id
        userId: this.enterpriseEmployee.userId, //原付款人
        toUserId: this.approvalId, //新付款人
        workFlowId: this.flowItem.workflowId, //流程id
        enterpriseId: this.enterpriseId,
        rejectReason: "用户已离职，变更付款人", //变更原因
        remark:
          this.infoUser.name +
          "操作员工" +
          this.oldData.name +
          "离职，进行变更付款人操作",
      };
      exchangePayer(param).then((res) => {
        if (res.code === 0) {
          console.log(res);
          this.shenpiFlag = false;
          this.workFlowFlag = true;
          this.workFlowList(this.uid, this.employeeId);
        } else {
          this.$store.commit("MyMessage", res.msg);
        }
      });
    },
    poapprove() {
      // 票据审批-考勤转审
      // let param = {
      //   opUserId: this.userId, //操作人的id
      //   userId: this.enterpriseEmployee.userId, //原付款人
      //   toUserId: this.approvalId, //新付款人
      //   workFlowId: this.flowItem.workflowId, //流程id
      //   enterpriseId: this.enterpriseId,
      //   rejectReason: "用户已离职，变更付款人", //变更原因
      //   remark:
      //     this.infoUser.name +
      //     "操作员工" +
      //     this.oldData.name +
      //     "离职，进行变更付款人操作",
      // };
      let param = {
        opUserId: this.userId, //操作人
        opEmployeeNo: this.infoUser.employeeId,
        toCheckerApprovalUserId: this.approvalId, //新审批人
        toCheckerApproveEmployeeNo: this.selectItem.employeeId, //
        approveUserId: this.enterpriseEmployee.userId, //原审批人
        approveEmployeeNo: this.enterpriseEmployee.employeeId,
        workFlowId: this.flowItem.workflowId, //流程id
        enterpriseId: this.enterpriseId,
        remark:
          this.infoUser.name +
          "操作员工" +
          this.oldData.name +
          "离职，进行离职处理的转审操作",
      };
      poapprove(param).then((res) => {
        if (res.code === 0) {
          console.log(res);
          this.shenpiFlag = false;
          this.workFlowFlag = true;
          this.workFlowList(this.uid, this.employeeId);
        } else {
          this.$store.commit("MyMessage", res.msg);
        }
      });
    },
    getEmployeeInfoUserId() {
      let param = {
        enterpriseId: this.enterpriseId,
        userId: this.userId,
      };
      getEmployeeInfoUserId(param).then((res) => {
        if (res.code === 0) {
          sessionStorage.setItem("infoUser", JSON.stringify(res));
        }
      });
    },
    checkApplyJoinEnterprise(type) {
      this.$store.commit("showLoading", "");
      // console.log(item);
      let param = {
        adminId: this.userId,
        enterpriseId: this.enterpriseId,
        employeeId: this.enterpriseEmployee.employeeId,
        confirmResult: type, //通过1/不通过2
      };
      checkApplyJoinEnterprise(param).then((res) => {
        this.$store.commit("hideLoading", "");
        // console.log(res);
        if (res.code === 0) {
          this.enterpriseEmployee.status = 1;
          if (type === 1) {
            this.addStaffSave();
          } else {
            this.gotoFun("/roster");
          }
        } else {
          this.$store.commit("MyMessage", res.msg);
        }
      });
    },
    // 获取员工信息
    getEmployeeInfo(uid, eid) {
      let param = {
        enterpriseId: this.enterpriseId,
        userId: uid,
        employeeId: eid,
      };
      getEmployeeInfo(param).then((res) => {
        // console.table(res.data);
        if (res.code === 0) {
          this.oldData = this.$config.cloneObj(res.data);
          this.oldIdtype = res.data.idType;
          this.enterpriseEmployee = res.data;
          this.enterpriseEmployee.mobile =
            this.enterpriseEmployee.mobile.replace(/\s+/g, "");
          this.enterpriseEmployee.beizhu = "";
          this.status = res.data.status;
          this.isOntermFun();
          const regMobile = /^1(3|4|5|6|7|8|9)\d{9}$/;
          if (res.data.ptsControInfo) {
            this.sbyyList = [];
            if (res.data.ptsControInfo.length > 0) {
              this.baosongStatus = res.data.ptsControInfo[0].baosongStatus;
              res.data.ptsControInfo.forEach((e) => {
                if (e.baosongStatus !== 2) {
                  if (e.ptsSbyy) {
                    this.sbyyList.push({
                      title: e.ptsSbyy,
                    });
                  }
                }
              });
            }
          }
          if (regMobile.test(this.enterpriseEmployee.name)) {
            this.enterpriseEmployee.name =
              "*" +
              this.enterpriseEmployee.name.substr(
                this.enterpriseEmployee.name.length - 2,
                2
              );
          }
          if (this.enterpriseEmployee.nationality + "" === "") {
            this.enterpriseEmployee.nationality = "1";
          }
          if (res.data.idType === 2) {
            res.data.idType = 1;
            this.enterpriseEmployee.nationality = "1";
          }
          if (res.data.idType === 4) {
            this.enterpriseEmployee.nationality = "3";
          }
          if (this.enterpriseEmployee.sssy + "" === "0") {
            this.enterpriseEmployee.sssy = "";
          }
          if (this.enterpriseEmployee.education + "" === "0") {
            this.enterpriseEmployee.education = "";
          }
          if (!this.enterpriseEmployee.jobTime) {
            // this.staffType = "add";
            this.enterpriseEmployee.jobTime = "";
            // this.enterpriseEmployee.onTrialTermSalaryScale = "";
          } else {
            if (
              !this.enterpriseEmployee.onTrialTermBegin &&
              !this.enterpriseEmployee.onTrialTermBegin
            ) {
              this.enterpriseEmployee.onTrialTermBegin =
                this.enterpriseEmployee.jobTime;
              this.enterpriseEmployee.onTrialTermEnd =
                this.enterpriseEmployee.jobTime;
            }
          }
          this.staffTitle = this.enterpriseEmployee.name + "  个人信息";
          if (this.status === 10) {
            this.staffTitle = "申请加入";
          }

          if (!this.enterpriseEmployee.identity) {
            this.enterpriseEmployee.identity = 1;
          }
          if (!this.enterpriseEmployee.contractType) {
            this.enterpriseEmployee.contractType = 3;
          }

          // 获取数据后进行赋值
          this.assignmentFun();
          this.$nextTick(() => {
            this.addStaffShow = true;
            this.statusShow = true;
          });
        }
      });
    },
    // 数据赋值
    assignmentFun() {
      let that = this;

      if (
        this.enterpriseEmployee.idType + "" === "1" ||
        this.enterpriseEmployee.idType + "" === "0"
      ) {
        this.enterpriseEmployee.idType = 1;
        that.jiben = that.$config.cloneObj(
          that.$dictionaries.employeeDateOld.jiben
        );
      } else {
        that.jiben = that.$config.cloneObj(
          that.$dictionaries.employeeDateOld.jiben2
        );
        that.qita = that.$config.cloneObj(
          that.$dictionaries.employeeDateOld.qita2
        );
        that.jiben[0].value = this.enterpriseEmployee.idType;
        if (this.enterpriseEmployee.idType + "" === "2") {
          that.jiben[2].value = "1";
          that.jiben[2].arrayList = this.$dictionaries.countryData;
        } else if (this.enterpriseEmployee.idType + "" === "3") {
          that.jiben[2].arrayList = this.$dictionaries.gangao;
          that.jiben[2].value = "4";
        } else if (this.enterpriseEmployee.idType + "" === "4") {
          that.jiben[2].arrayList = this.$dictionaries.taiwan;
          that.jiben[2].value = "3";
        } else {
          that.jiben[2].arrayList = this.$dictionaries.countryData;
          that.jiben[2].value = "";
        }
      }

      this.employee.zhuyao.forEach((e, v) => {
        if (e.field === "contactProvince") {
          e.desc1 = this.enterpriseEmployee.contactProvince;
          e.desc2 = this.enterpriseEmployee.contactCity;
          e.desc3 = this.enterpriseEmployee.contactArea;
          e.value1 = this.enterpriseEmployee.contactProvinceId;
          e.value2 = this.enterpriseEmployee.contactCityId;
          e.value3 = this.enterpriseEmployee.contactAreaId;
        } else {
          e.value = this.enterpriseEmployee[e.field] + "";
        }
      });
      this.employee.gangwei.forEach((e, v) => {
        if (e.field === "onTrialTermBegin") {
          e.onTrialTermBegin = this.enterpriseEmployee.onTrialTermBegin;
          e.onTrialTermEnd = this.enterpriseEmployee.onTrialTermEnd;
        } else {
          e.value = this.enterpriseEmployee[e.field] + "";
        }
      });
      this.jiben.forEach((e, v) => {
        if (e.field === "province") {
          e.desc1 = this.enterpriseEmployee.province;
          e.desc2 = this.enterpriseEmployee.city;
          e.desc3 = this.enterpriseEmployee.area;
          e.value1 = this.enterpriseEmployee.provinceId;
          e.value2 = this.enterpriseEmployee.cityId;
          e.value3 = this.enterpriseEmployee.areaId;
        } else {
          e.value = this.enterpriseEmployee[e.field] + "";
        }
      });
      this.employee.gongzika.forEach((e, v) => {
        e.value = this.enterpriseEmployee[e.field] + "";
      });
      this.employee.shewai.forEach((e, v) => {
        e.value = this.enterpriseEmployee[e.field] + "";
      });
      this.qita.forEach((e, v) => {
        if (e.field === "idcardImgFront") {
          e.positive = this.enterpriseEmployee.idcardImgFront;
          e.back = this.enterpriseEmployee.idcardImgReverse;
        } else if (e.field === "degreeImg") {
          e.positive = this.enterpriseEmployee.degreeImg;
          e.back = this.enterpriseEmployee.diplomaImg;
        } else {
          e.value = this.enterpriseEmployee[e.field] + "";
        }
      });

      this.$forceUpdate();
    },
    // 提交按钮
    addStaffSave() {
      bus.$emit("hideLines");
      console.log(this.enterpriseEmployee.dimissionTime);
      if (
        this.enterpriseEmployee.dimissionTime !== "" &&
        this.enterpriseEmployee.dimissionTime
      ) {
        // this.tishiFlag = true;
        this.dimission(this.enterpriseEmployee);
        return;
      }
      this.enterpriseEmployee.mobile = this.enterpriseEmployee.mobile.replace(
        /\s+/g,
        ""
      );
      var jmz = {};
      jmz.GetLength = function (str) {
        return str.replace(/[\u0391-\uFFE5]/g, "aa").length; //先把中文替换成两个字节的英文，在计算长度
      };
      let length1 = jmz.GetLength(this.enterpriseEmployee.name);
      if (!this.enterpriseEmployee.name) {
        bus.$emit("name", true);
        this.$store.commit("MyMessage", "请输入姓名");
        return false;
      } else if (length1 < 4) {
        bus.$emit("name", true);
        this.$store.commit("MyMessage", "姓名长度不符合规则");
        return false;
      }
      if (!this.enterpriseEmployee.mobile) {
        bus.$emit("mobile", true);
        this.$store.commit("MyMessage", "请输入常用手机号");
        return false;
      }
      const regMobile = /^1(3|4|5|6|7|8|9)\d{9}$/;
      if (!regMobile.test(this.enterpriseEmployee.mobile)) {
        bus.$emit("mobile", true);
        this.$store.commit("MyMessage", "手机号不符合规则");
        return false;
      }
      if (!this.enterpriseEmployee.postName) {
        bus.$emit("postName", true);
        this.$store.commit("MyMessage", "请输入岗位名称");
        return false;
      }
      if (!this.enterpriseEmployee.departmentId) {
        bus.$emit("departmentId", true);
        this.$store.commit("MyMessage", "请选择所属部门");
        return false;
      }
      if (!this.enterpriseEmployee.contactProvinceId) {
        bus.$emit("contactProvince", true);
        this.$store.commit("MyMessage", "请选择现居住地");
        return false;
      }
      if (!this.enterpriseEmployee.contactDetail) {
        bus.$emit("contactDetail", true);
        this.$store.commit("MyMessage", "请输入现居住详细地址");
        return false;
      }
      // if (this.enterpriseEmployee.idNumber) {
      //   this.oldData.idType = 1;
      //   this.enterpriseEmployee.idType = 1;
      // }

      if (this.enterpriseEmployee.idType === 0) {
        this.enterpriseEmployee.idType = 1;
      }
      if (
        this.enterpriseEmployee.idType + "" === "1" ||
        this.enterpriseEmployee.idType + "" === "0"
      ) {
        if (this.enterpriseEmployee.idNumber !== "") {
          if (!this.enterpriseEmployee.idNumber) {
            this.$store.commit("MyMessage", "证件号码未完善");
            bus.$emit("idNumber", true);
            return false;
          }
          let testId = this.testid(this.enterpriseEmployee.idNumber);
          if (testId.status === 0) {
            bus.$emit("idNumber", true);
            this.$store.commit("MyMessage", "身份证格式有误，请重新填写");
            return false;
          }
        }
      } else if (this.enterpriseEmployee.idType + "" != "1") {
        if (!this.enterpriseEmployee.idNumber) {
          this.$store.commit("MyMessage", "证件号码未完善");
          bus.$emit("idNumber", true);
          return false;
        }
        if (!this.enterpriseEmployee.nationality) {
          this.$store.commit("MyMessage", "国籍未完善");
          return;
        }
        if (this.enterpriseEmployee.idType + "" === "3") {
          if (
            this.enterpriseEmployee.idNumber.length === 9 ||
            this.enterpriseEmployee.idNumber.length === 11
          ) {
          } else {
            this.$store.commit("MyMessage", "证件号码未完善");
            bus.$emit("idNumber", true);
            return false;
          }
        }
        if (this.enterpriseEmployee.idType + "" === "4") {
          if (this.enterpriseEmployee.idNumber.length === 8) {
          } else {
            this.$store.commit("MyMessage", "证件号码未完善");
            bus.$emit("idNumber", true);
            return false;
          }
        }
      }

      if (0) {
        // for
        if (
          this.enterpriseEmployee.idType !== 1 &&
          this.enterpriseEmployee.idType !== 0
        ) {
          let sw = this.employee.shewai;
          for (let i = 0; i < sw.length; i++) {
            if (sw[i].require) {
              if (!sw[i].value) {
                this.$store.commit("MyMessage", sw[i].tips + sw[i].text);
                bus.$emit(sw[i].field, true);
                return false;
              }
            }
          }
        }
        let jb = this.jiben;
        for (let i = 0; i < jb.length; i++) {
          if (jb[i].require) {
            if (
              !this.enterpriseEmployee[jb[i].field] ||
              this.enterpriseEmployee[jb[i].field] === -1
            ) {
              this.$store.commit("MyMessage", jb[i].tips + jb[i].text);
              bus.$emit(jb[i].field, true);
              return false;
            }
          }
        }
        let gw = this.employee.gangwei;
        for (let i = 0; i < gw.length; i++) {
          if (gw[i].require) {
            if (gw[i].field === "onTrialTermBegin") {
              if (!this.enterpriseEmployee.onTrialTermBegin) {
                console.log(this.enterpriseEmployee.onTrialTermBegin);
                this.$store.commit("MyMessage", gw[i].tips + gw[i].text);
                bus.$emit(gw[i].field, true);
                return false;
              }
            } else if (!gw[i].value) {
              this.$store.commit("MyMessage", gw[i].tips + gw[i].text);
              bus.$emit(gw[i].field, true);
              return false;
            }
          }
        }
      }
      if (this.enterpriseEmployee.isOnterm === 0) {
        this.enterpriseEmployee.onTrialTermSalaryScale =
          this.enterpriseEmployee.postSalary;
      }

      if (this.enterpriseEmployee.jobTime) {
        this.enterpriseEmployee.jobTime =
          this.enterpriseEmployee.jobTime.split(" ")[0];
      }
      this.enterpriseEmployee.ptsControInfo = {};
      if (this.enterpriseEmployee.idNumber === "") {
        this.enterpriseEmployee.idType = 0;
      }
      this.$store.commit("showLoading", "");
      this.enterpriseEmployee.idNumber =
        this.enterpriseEmployee.idNumber.toUpperCase();
      if (this.enterpriseEmployee.userId === this.userId) {
        // * 完善员工信息[自己给自己]
        this.prefectEnterpriseEmployee(this.enterpriseEmployee);
      } else {
        this.modifyEnterpriseEmployee(this.enterpriseEmployee);
      }
    },
    prefectEnterpriseEmployee(enterpriseEmployee) {
      prefectEnterpriseEmployee(enterpriseEmployee).then((res) => {
        this.$store.commit("hideLoading", "");
        // console.log(res);
        if (res.code === 0) {
          this.getEmployeeInfoUserId();
          setTimeout(() => {
            this.gotoFun("/roster");
            // window.close();
            this.$store.commit("MyMessage", "修改成功");
          }, 500);
        } else {
          this.$store.commit("MyMessage", res.msg);
        }
      });
    },
    modifyEnterpriseEmployee(enterpriseEmployee) {
      let date = {
        enterpriseEmployeeVo3: enterpriseEmployee,
        userId: this.userId,
        enterpriseId: this.enterpriseId,
        employeeId: enterpriseEmployee.employeeId,
      };
      modifyEnterpriseEmployee(date).then((res) => {
        this.$store.commit("hideLoading", "");
        // console.log(res);
        if (res.code === 0) {
          setTimeout(() => {
            this.gotoFun("/roster");
            // window.close();
            this.$store.commit("MyMessage", "修改成功");
          }, 500);
        } else {
          this.$store.commit("MyMessage", res.msg);
        }
      });
    },
    dimission(enterpriseEmployee) {
      this.$confirm(`离职当月是否有工资?`, "提示", {
        confirmButtonText: "是",
        cancelButtonText: "否",
        type: "warning",
        center: true,
        showClose: false,
        distinguishCancelAndClose: false,
        closeOnClickModal: false,
        closeOnPressEscape: false,
      })
        .then(() => {
          this.dimissionFun(enterpriseEmployee, 1);
        })
        .catch(() => {
          this.dimissionFun(enterpriseEmployee, -1);
        });
    },
    dimissionFun(enterpriseEmployee, type) {
      this.$confirm(`确定 ${enterpriseEmployee.name} 离职吗?`, "", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: true,
      }).then(() => {
        let date = {
          userId: enterpriseEmployee.userId,
          opUserId: this.userId,
          enterpriseId: this.enterpriseId,
          employeeId: enterpriseEmployee.employeeId,
          dimissionMonthIsHavaSalary: type,
          dimissionTime: enterpriseEmployee.dimissionTime,
        };
        // return;
        dimission(date).then((res) => {
          // console.log(res);
          if (res.code === 0) {
            this.rosterClose();
            this.$store.commit("MyMessage", "操作成功");
            setTimeout(() => {
              // this.tishiFlag = true;
            }, 500);
          } else {
            if (res.msg === "请求失败!:请先完成待审批事项再离职") {
              this.pages.pageNum = 1;
              this.workFlowList(this.employeeId, this.uid);
              this.workFlowFlag = true;
            } else {
              this.$store.commit("MyMessage", res.msg);
            }
          }
        });
      });
    },

    gotoFun(path) {
      this.$router.push(path);
    },
    back() {
      this.$router.go(-1); //返回上一层
    },
    // 证件类型--切换判断
    idTypeFun(item, index1, index2) {
      this.addStaffShow = false;
      // this.oldData.idType = item.value;
      this.oldIdtype = item.value;
      if (item.value === "1") {
        this.jiben = this.$dictionaries.employeeDateOld.jiben;
        this.qita = this.$dictionaries.employeeDateOld.qita1;
      } else if (item.value === "5") {
        this.jiben = this.$config.cloneObj(
          this.$dictionaries.employeeDateOld.jiben2
        );
        this.qita = this.$config.cloneObj(
          this.$dictionaries.employeeDateOld.qita3
        );
      } else {
        this.jiben = this.$config.cloneObj(
          this.$dictionaries.employeeDateOld.jiben2
        );
        this.qita = this.$config.cloneObj(
          this.$dictionaries.employeeDateOld.qita2
        );
      }

      if (item.value === "1") {
        this.enterpriseEmployee.nationality = "1";
        this.jiben[0].value = "1";
      } else if (item.value === "2") {
        this.enterpriseEmployee.nationality = "1";
        this.jiben[2].value = "1";
        this.jiben[2].arrayList = this.$dictionaries.countryData;
      } else if (item.value === "3") {
        this.enterpriseEmployee.nationality = "4";
        this.jiben[2].value = "4";
        this.jiben[2].arrayList = this.$dictionaries.gangao;
      } else if (item.value === "4") {
        this.enterpriseEmployee.nationality = "3";
        this.jiben[2].value = "3";
        this.jiben[2].arrayList = this.$dictionaries.taiwan;
      } else {
        this.enterpriseEmployee.nationality = "";
        this.jiben[2].value = "";
        this.jiben[2].arrayList = this.$dictionaries.countryData;
      }
      this.jiben[0].value = item.value;
      // 重新进行 数据赋值
      this.assignmentFun();
      this.$nextTick(() => {
        this.addStaffShow = true;
      });
    },
    // 判断试用期
    isOntermFun() {
      if (
        this.enterpriseEmployee.onTrialTermBegin &&
        this.enterpriseEmployee.onTrialTermEnd
      ) {
        if (
          this.enterpriseEmployee.onTrialTermBegin ===
          this.enterpriseEmployee.onTrialTermEnd
        ) {
          this.enterpriseEmployee.isOnterm = 0;
        } else {
          this.enterpriseEmployee.isOnterm = 1;
        }
      } else {
        this.enterpriseEmployee.isOnterm = 0;
      }
      this.$forceUpdate();
      // console.log(this.enterpriseEmployee.isOnterm);
    },
    staffParent(item, index1, index2) {
      // console.log(item);
      this.enterpriseEmployee[item.field] = item.value;
      if (item.field === "idType") {
        this.idTypeFun(item, index1, index2);
      } else if (item.field === "identity") {
        if (item.value + "" === "2") {
          this.enterpriseEmployee.investMoney = item.value1;
          this.enterpriseEmployee.investproportion = item.value2;
        }
      } else if (item.type === "city") {
        this.enterpriseEmployee[item.field + "Id"] = item.value1;
        this.enterpriseEmployee[item.city + "Id"] = item.value2;
        this.enterpriseEmployee[item.area + "Id"] = item.value3;
        this.enterpriseEmployee[item.field] = item.desc1;
        this.enterpriseEmployee[item.city] = item.desc2;
        this.enterpriseEmployee[item.area] = item.desc3;
      } else if (item.field === "jobTime") {
        this.enterpriseEmployee.onTrialTermBegin = item.value;
        this.enterpriseEmployee.onTrialTermEnd = item.value;
        this.isOntermFun();
      } else if (item.field === "contractType") {
        if (item.value + "" === "2") {
          this.enterpriseEmployee.identity = "6";
        } else if (item.value + "" === "1") {
          this.enterpriseEmployee.identity = "3";
        }
      } else if (item.field === "onTrialTermBegin") {
        this.enterpriseEmployee.onTrialTermBegin = item.onTrialTermBegin;
        this.enterpriseEmployee.onTrialTermEnd = item.onTrialTermEnd;
        this.isOntermFun();
      } else if (item.field === "idcardImgFront") {
        this.enterpriseEmployee.idcardImgFront = item.back;
        this.enterpriseEmployee.idcardImgReverse = item.positive;
      } else if (item.field === "degreeImg") {
        this.enterpriseEmployee.degreeImg = item.back;
        this.enterpriseEmployee.diplomaImg = item.positive;
      } else if (item.field === "status") {
        this.enterpriseEmployee.dimissionTime = item.dimissionTime;
      } else if (item.field === "bankProvinceId") {
        this.enterpriseEmployee.bankProvince = item.bankProvince;
      }
      // console.log(this.enterpriseEmployee);
      this.$forceUpdate();
    },
    testid(id) {
      // 1 "验证通过!", 0 //校验不通过 // id为身份证号码
      var format =
        /^(([1][1-5])|([2][1-3])|([3][1-7])|([4][1-6])|([5][0-4])|([6][1-5])|([7][1])|([8][1-2]))\d{4}(([1][9]\d{2})|([2]\d{3}))(([0][1-9])|([1][0-2]))(([0][1-9])|([1-2][0-9])|([3][0-1]))\d{3}[0-9xX]$/;
      //号码规则校验
      if (!format.test(id)) {
        return {
          status: 0,
          msg: "身份证号码不合规",
        };
      }
      //区位码校验
      //出生年月日校验  前正则限制起始年份为1900;
      var year = id.substr(6, 4),
        //身份证年
        month = id.substr(10, 2),
        //身份证月
        date = id.substr(12, 2),
        //身份证日
        time = Date.parse(month + "-" + date + "-" + year),
        //身份证日期时间戳date
        now_time = Date.parse(new Date()),
        //当前时间戳
        dates = new Date(year, month, 0).getDate(); //身份证当月天数
      if (time > now_time || date > dates) {
        return {
          status: 0,
          msg: "出生日期不合规",
        };
      }
      //校验码判断
      var c = new Array(7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2); //系数
      var b = new Array("1", "0", "X", "9", "8", "7", "6", "5", "4", "3", "2"); //校验码对照表
      var id_array = id.split("");
      var sum = 0;
      for (var k = 0; k < 17; k++) {
        sum += parseInt(id_array[k]) * parseInt(c[k]);
      }
      if (id_array[17].toUpperCase() != b[sum % 11].toUpperCase()) {
        return {
          status: 0,
          msg: "身份证校验码不合规",
        };
      }
      return {
        status: 1,
        msg: "校验通过",
      };
    },
  },
};
</script>

<style lang="less" scoped>
.tips-box {
  position: absolute;
  top: 0;
  left: 0px;
  width: calc(100% - 0px);
  height: 20px;
  background: rgba(234, 2, 0, 0.7);
  color: #fff;
  text-align: center;
  font-size: 12px;
}
.department .department-list {
  padding-top: 18px;
  min-height: calc(100% - 158px);
}

.department-width {
  width: 1328px;
  margin: 0 auto;
}
.addStaffBtn {
  width: 200px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  display: inline-block;
  margin: 0 20px;
  background: #4c8afc;
  color: #fff;
  cursor: pointer;
}
.addStaff-submit {
  width: 120px;
}
.addStaff-del {
  background: #fff;
  color: #999;
  width: 120px;
  border: none;
  font-weight: 400;
  &:hover {
    background: #fff;
    color: #999;
  }
}
.addStaff-list {
  width: calc((100% - 160px) / 3);
  float: left;
  margin-left: 80px;
  &:first-child {
    margin-left: 0;
  }
}
.addStaff-main {
  margin-bottom: 20px;
  padding-bottom: 20px;
}

.addStaff-line-box {
  margin: 30px 0;
}
.department-btn {
  position: relative;
  top: 0;
  right: 0;
  text-align: right;
  .addStaff-btn {
    margin: 0;
    padding: 0;
  }
}
.list-title-style {
  color: #999;
  background: #f5f5f5;
  &.marTop {
    margin-top: 36px;
  }
}
.list-h {
  min-height: 300px;
  max-height: 500px;
  overflow-y: auto;
  text-align: center;
}
.workFlowPopup-table {
  padding: 20px;
}
.workFlowPopup-title {
  text-align: center;
  padding: 10px 0 0;
  color: #262626;
  font-size: 16px;
}
.workFlowPopup-body {
  width: 80%;
  margin: 0 auto;
  padding-top: 20px;
}
.workFlowPopup-l {
  display: inline-block;
  width: 80px;
}
.workFlowPopup-r {
  display: inline-block;
  width: calc(100% - 102px);
  border: 1px solid #dbdbdb;
  border-radius: 5px;
  padding: 0 10px;
  cursor: pointer;
}
.workFlowPopup-footer {
  text-align: center;
  padding: 50px 0 0;
  .workFlowPopup-btn {
    display: inline-block;
    width: 180px;
    height: 40px;
    text-align: center;
    line-height: 40px;
    background: #4c8afc;
    color: #fff;
    border: none;
    font-size: 14px;
    border-radius: 5px;
    cursor: pointer;
  }
}
.accountTable {
  tbody {
    td {
      color: #666;
    }
  }
  .t-span {
    color: #4c8afc;
    cursor: pointer;
  }
}
.loadMore {
  display: inline-block;
  width: 120px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border: 1px solid #dbdbdb;
  color: #262626;
  font-size: 14px;
  cursor: pointer;
  margin: 20px auto 0;
  &:hover {
    border: 1px solid #4c8afc;
    color: #4c8afc;
  }
}
</style>
