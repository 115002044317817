<template>
  <div class="personDetails-content-main">
    <div class="main-list" v-if="show">
      <div class="main-div" v-for="(e, i) in list1" :key="i">
        <div class="main-div-l">
          {{ e.text }}<span v-if="e.requireAuth">*</span>
        </div>
        <div class="main-div-c">
          <input
            type="text"
            v-model="SalaryStaff[e.field]"
            @click="selectClick($event, 2, e)"
            @input="iptInput(e)"
            :placeholder="e.tips"
            :readonly="e.field === 'sum'"
          />
        </div>
        <div class="main-div-r" v-if="e.desc === '元'">元</div>
        <div class="main-div-r" v-else>
          <svg-icon icon-class="jiantou_qian" class="jiantou-icon"></svg-icon>
        </div>
      </div>
    </div>
    <div class="main-list">
      <div class="main-div">
        <div class="main-div-l">考勤扣款</div>
        <div class="main-div-c">
          <input
            type="text"
            v-model="SalaryStaff.kSalary"
            placeholder="请输入"
          />
        </div>
        <div class="main-div-r">元</div>
      </div>
      <div class="main-div">
        <div class="main-div-l">其他扣款</div>
        <div class="main-div-c">
          <input type="text" v-model="SalaryStaff.fine" placeholder="请输入" />
        </div>
        <div class="main-div-r">元</div>
      </div>
      <div class="main-div cursorSty" @click="earmarkedShow = true">
        <div class="main-div-l">个税专项扣除</div>
        <div class="main-div-c">
          <input type="text" readonly placeholder="自动计算" />
        </div>
        <div class="main-div-r">
          <svg-icon icon-class="jiantou_qian" class="jiantou-icon"></svg-icon>
        </div>
      </div>
      <div></div>
      <div class="main-div cursorSty" @click="cumulativeShow = true">
        <div class="main-div-l">
          <div style="width: 106px">累计薪酬扣税项目</div>
        </div>
        <div class="main-div-c">
          <div class="main-div-style">
            <input type="text" readonly placeholder="自动计算" />
          </div>
        </div>
        <div class="main-div-r">
          <svg-icon icon-class="jiantou_qian" class="jiantou-icon"></svg-icon>
        </div>
      </div>
    </div>
    <div class="main-list">
      <div class="main-div">
        <div class="main-div-l">银行卡号<span>*</span></div>
        <div class="main-div-c">
          <span class="descClass" :style="{ right: descRight }">{{
            bankDesc
          }}</span>

          <input
            type="text"
            class="bankIpt"
            id="myInput"
            v-model="bankCardNum"
            @input="changeWidth"
            placeholder="请输入"
          />
        </div>
        <!-- <div class="main-div-r">元</div> -->
      </div>
      <div class="main-div">
        <div class="main-div-l">所属银行<span>*</span></div>
        <div class="main-div-c">
          <input
            type="text"
            v-model="SalaryStaff.bankName"
            placeholder="请输入"
          />
        </div>
      </div>
      <div class="main-div cursorSty" @click="popupShowClick($event)">
        <div class="main-div-l">
          <div style="width: 120px">开户银行所在省份<span>*</span></div>
        </div>
        <div class="main-div-c">
          <div class="main-div-style">
            <input
              type="text"
              readonly
              v-model="SalaryStaff.bankProvince"
              placeholder="请输入"
            />
          </div>
        </div>
        <div class="main-div-r">
          <svg-icon
            icon-class="jiantou_shen"
            v-if="SalaryStaff.bankProvince"
            class="jiantou-icon"
          ></svg-icon>
          <svg-icon
            icon-class="jiantou_qian"
            v-else
            class="jiantou-icon"
          ></svg-icon>
        </div>
      </div>
    </div>
    <popupSelect
      v-if="selectShow"
      :popupTop="popupTop"
      :popupBottom="popupBottom"
      :popupLeft="popupLeft"
      :item="bankList"
      :direction="direction"
      :obj="SalaryStaff"
      @parentEmit="parentEmit"
      @closeFun="closeFun"
    ></popupSelect>
    <popupSubsidy
      v-if="selectShow2"
      :popupLeft="popupLeft"
      :popupTop="popupTop"
      :popupBottom="popupBottom"
      :direction="direction"
      :SalaryStaff="SalaryStaff"
      @cascadeClose="closeFun"
      @subsidyFun="subsidyFun"
    ></popupSubsidy>
    <cumulativePopup
      :SalaryStaff="SalaryStaff"
      v-if="cumulativeShow"
      :employeeId="employeeId"
      :jobTime="jobTime"
      @rosterClose="rosterClose"
      @earmarkedSave="earmarkedSave"
      :cumulativeShow.sync="cumulativeShow"
    ></cumulativePopup>
    <earmarkedPopup
      :SalaryStaff="SalaryStaff"
      v-if="earmarkedShow"
      :employeeId="employeeId"
      :jobTime="jobTime"
      @rosterClose="rosterClose"
      @earmarkedSave="earmarkedSave"
      :earmarkedShow.sync="earmarkedShow"
    ></earmarkedPopup>
    <div class="popup-layer" v-if="huifuFlag">
      <!-- <div class="popup-layer-bg" @click="rosterClose"></div> -->
      <div class="popup-layer-bg"></div>
      <div
        class="huifuPopup"
        style="
          width: 360px;
          height: 240px;
          margin-left: -180px;
          margin-top: -120px;
        "
      >
        <div class="huifuPopup-content">
          <!-- <p class="huifuPopup-p1">小提示</p> -->
          <p
            class="huifuPopup-p2"
            style="color: #ff931e; font-size: 16px; margin: 20px 0"
          >
            劳动合同 需完善社保信息
          </p>
          <!-- <p class="huifuPopup-p2">如有疑问，请联系公司管理员</p> -->
          <!-- <p class="huifuPopup-p2">有信息变动请手动修改</p> -->
          <div class="huifuPopup-btns">
            <!-- <div class="huifuPopup-btn-l" @click="rosterClose">取 消</div> -->
            <div class="huifuPopup-btn-r" @click="huifuFlag = false">
              我知道了
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import bus from "@/util/bus";
import popupSelect from "@/components/popupBox/popupSelect";
import popupSubsidy from "@/components/popupBox/popupSubsidy";
import cumulativePopup from "./cumulativePopup";
import earmarkedPopup from "./earmarkedPopup";
import { queryBuzhuButie, updateEmployeeStaffInfo } from "@/http/api";
export default {
  data() {
    return {
      bankCardNum: "",
      bankDesc: "",
      descRight: "",
      bankList: {
        text: "开户行所在省份",
        field: "bankProvinceId",
        type: "input",
        value: "",
        desc: "",
        tips: "请输入",
        requireAuth: false,
        arrayList: this.$dictionaries.employeeModel.cityMap,
      },
      list1: [
        {
          text: "岗位工资",
          field: "deSalary",
          type: "input",
          value: "",
          desc: "元",
          tips: "请输入",
          requireAuth: false,
        },
        {
          text: "试用期工资",
          field: "onTrialTermSalaryScale",
          type: "input",
          value: "",
          desc: "元",
          tips: "请输入",
        },
        {
          text: "绩效/奖金",
          field: "acheBouns",
          type: "input",
          value: "",
          desc: "元",
          tips: "请输入",
        },
        {
          text: "补贴补助",
          field: "sum",
          type: "input",
          value: "",
          desc: "icon",
          tips: "请选择",
        },
        // {
        //   text: "加班补助",
        //   field: "",
        //   type: "input",
        //   value: "",
        //   desc: "icon",
        //   tips: "请输入",
        // },
        {
          text: "年终奖",
          field: "endBouns",
          type: "input",
          value: "",
          desc: "元",
          tips: "请输入",
        },
        {
          text: "解除合同补偿金",
          field: "compensation",
          type: "input",
          value: "",
          desc: "元",
          tips: "请输入",
        },
      ],
      list2: [
        {
          text: "银行卡号",
          field: "deSalary",
          type: "input",
          value: "",
          desc: "元",
          tips: "请输入",
          requireAuth: false,
        },
        {
          text: "所属银行",
          field: "onTrialTermSalaryScale",
          type: "input",
          value: "",
          desc: "元",
          tips: "请输入",
        },
        {
          text: "开户银行所在省份",
          field: "acheBouns",
          type: "input",
          value: "",
          desc: "元",
          tips: "请输入",
        },
      ],
      json1: {
        acheBouns: 0,
        area: "",
        areaId: "",
        bankCardNum: "",
        bankCode: "",
        bankName: "",
        bankProvince: "",
        bankProvinceId: "",
        bouns: 0,
        city: "",
        cityId: "",
        compensation: 0,
        contractType: 0,
        createTime: "",
        createTimes: 0,
        cumulativeChild: 0,
        cumulativeHousingLoan: 0,
        cumulativeNextTeach: 0,
        cumulativeProvident: 0,
        cumulativeRent: 0,
        cumulativeSalary: 0,
        cumulativeSupport: 0,
        cumulativeTax: 0,
        cumulativeTaxAmt: 0,
        cumulativeThreshold: 0,
        deSalary: 0,
        deductionChild: 0,
        deductionTeach: 0,
        endBouns: 0,
        enterpriseId: "",
        head: "",
        health: 0,
        healthAmt: 0,
        houseRent: 0,
        housingLoan: 0,
        idNum: "",
        identity: 0,
        isCompen: 0,
        isDel: 0,
        isFinish: 0,
        isSend: 0,
        isTrue: 0,
        jSalary: 0,
        jobTime: "",
        jobTimes: "",
        kSalary: 0,
        nickName: "",
        oldAve: 0,
        otherSubsidyBouns: 0,
        position: "",
        postName: "",
        provideAged: 0,
        province: "",
        provinceId: "",
        registered: 0,
        salaryStaffId: "",
        salaryStatus: 0,
        salaryTrue: 0,
        stakeIn: 0,
        subsidyId: 0,
        trueSalary: 0,
        userId: "",
      },
      SalaryStaff: {},
      huifuFlag: false,
      show: false,
      selectShow: false,
      selectShow2: false,
      cumulativeShow: false,
      earmarkedShow: false,
      popupTop: "",
      popupBottom: "",
      popupLeft: "",
      showText: "",
      direction: "",
      jobTime: "",
      employeeId: "",
      deSalary: "",
    };
  },
  components: {
    popupSelect,
    popupSubsidy,
    cumulativePopup,
    earmarkedPopup,
  },
  props: {
    navIndex: {
      type: Number,
      default() {
        return 0;
      },
    },
    details: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  watch: {
    navIndex(newValue, oldValue) {
      this.init();
    },
  },
  mounted() {},

  methods: {
    init() {
      if (this.navIndex === 1) {
        this.SalaryStaff.sum = 0;
        // this.queryBuzhuButie();
        this.SalaryStaff = this.details;
        this.bankCardNum = this.SalaryStaff.bankCardNum;
        this.bankList.value = this.SalaryStaff.bankProvinceId;
        this.SalaryStaff.name = this.SalaryStaff.nickName;
        this.jobTime = this.SalaryStaff.jobTime;
        this.employeeId = this.SalaryStaff.employeeId;
        if (this.SalaryStaff.isFinish === 0) {
          if (this.SalaryStaff.deSalary === 0) {
            this.deSalary = "";
          }
        }
        setTimeout(() => {
          this.bankBlur("sc");
          this.show = true;
        }, 100);

        this.$forceUpdate();
        let that = this;
        bus.$off("gzxx");
        bus.$on("gzxx", function () {
          that.save();
        });
      }
    },
    back() {
      this.$router.go(-1); //返回上一层
    },
    save() {
      if (this.SalaryStaff.isFinish === 0) {
        if (this.deSalary === "") {
          this.$store.commit("MyMessage", "请输入岗位工资");
          return false;
        }
      }
      if (this.bankCardNum === "") {
        this.$store.commit("MyMessage", "请输入银行卡号");
        return false;
      }
      this.SalaryStaff.bankCardNum = this.bankCardNum;
      if (this.SalaryStaff.bankName === "") {
        this.$store.commit("MyMessage", "请输入开户行全称");
        return false;
      }
      // if (
      //   this.SalaryStaff.isPaymentSS !== 2 &&
      //   this.SalaryStaff.contractType === 3
      // ) {
      //   this.huifuFlag = true;
      //   return;
      // }
      for (let key in this.json1) {
        if (this.SalaryStaff[key]) {
          this.json1[key] = this.SalaryStaff[key];
        }
      }
      if (this.json1.registered === 0) {
        this.json1.registered = 2;
      }
      this.json1.userId = sessionStorage.getItem("userId");
      updateEmployeeStaffInfo(this.json1).then((res) => {
        if (res.code === 0) {
          // console.log(res);
          let text = 0;
          let text1 = 0;
          for (let key in bus._events) {
            if (key === "sbgjj") text = 1;
            if (key === "jbxx") text1 = 1;
          }
          if (text) {
            bus.$emit("sbgjj");
          } else if (text1) {
            bus.$emit("jbxx");
          } else {
            this.$store.commit("MyMessage", "保存成功");
            this.back();
          }
        } else {
          // bus.$emit("saveSubmit", 1);
          this.$store.commit("MyMessage", res.msg);
        }
      });
    },
    iptInput(e) {
      if (e.field === "deSalary") {
        this.deSalary = this.SalaryStaff[e.field];
      }
    },
    queryBuzhuButie() {
      let that = this;
      let param = {
        userId: this.SalaryStaff.userId,
        enterpriseId: this.SalaryStaff.enterpriseId,
      };
      queryBuzhuButie(param, true).then((res) => {
        // console.log("res", res);
        if (res.code === 0) {
          sessionStorage.setItem(
            "buzhuButie",
            JSON.stringify(res.data.subsidyCompany)
          );
          this.SalaryStaff.sum = 0;
          res.data.subsidy.forEach((element) => {
            this.SalaryStaff.sum += element.subsidyMoney;
          });
          this.$forceUpdate();
        }
      });
    },
    earmarkedSave(flag) {
      // this.init(flag);
      // this.rosterClose(flag);
    },
    rosterClose() {
      // this.rosterShow = false;
      // this.earmarkedShow = false;
      // this.cumulativeShow = false;
      this.huifuFlag = false;
    },
    selectClick(e, type, item) {
      // console.log(item);
      if (item.field === "sum") {
        this.selectShow2 = true;
      } else {
        return false;
      }
      let H1 =
        document.body.clientHeight -
        e.currentTarget.getBoundingClientRect().bottom;

      if (H1 < 450) {
        this.popupBottom = H1 + 60;
        this.direction = "bottom";
        this.popupTop = null;
      } else {
        this.popupTop = e.currentTarget.getBoundingClientRect().top + 50;
        this.direction = "top";
        this.popupBottom = null;
      }

      this.popupLeft = e.currentTarget.getBoundingClientRect().left - 100;
    },
    subsidyFun(sum) {
      this.SalaryStaff.sum = sum;
      this.closeFun();
    },
    parentEmit(e) {
      console.log(e);
      this.SalaryStaff.bankProvince = e.bankProvince;
      this.SalaryStaff.bankProvinceId = e.value;
      this.selectShow = false;
      this.$forceUpdate();
    },
    closeFun() {
      this.selectShow = false;
      this.selectShow2 = false;
      this.$forceUpdate();
    },
    cascadeClose() {
      this.popupShow = false;
      this.deductShow = false;
      this.selectShow2 = false;
      this.$forceUpdate();
    },
    popupShowClick(e) {
      // bus.$emit("hidePopup");
      // bus.$emit("subsidy");
      this.popupShow = true;
      let H1 =
        document.body.clientHeight -
        e.currentTarget.getBoundingClientRect().bottom;
      // console.log(H1);
      if (H1 < 440) {
        this.popupBottom = H1 + 56 + "px";
        this.direction = "bottom";
        this.popupTop = "auto";
      } else {
        this.popupTop = e.currentTarget.getBoundingClientRect().top + 56 + "px";
        this.direction = "top";
        this.popupBottom = "auto";
      }
      this.popupLeft = e.currentTarget.getBoundingClientRect().left + 20 + "px";
      this.selectShow = true;
    },
    bankBlur(type) {
      let bankItem = this.$bank.bankCardAttribution(
        this.bankCardNum.replace(/\s+/g, "")
      );
      if (bankItem !== "error") {
        if (this.bankNameFlag) {
          this.SalaryStaff.bankName = bankItem.bankName;
        }
        this.bankDesc = bankItem.cardTypeName;
      } else {
        // if (this.bankNameFlag) {
        //   this.SalaryStaff.bankName = "";
        // }
        this.bankDesc = "";
      }
      this.bankNameFlag = true;
      this.bankCardNum = this.bankCardNum
        .replace(/\D+/g, "")
        .replace(/(\d{4})/g, "$1 ")
        .replace(/ $/, "");
      this.$forceUpdate();
      if (type === "sc") {
        setTimeout(() => {
          this.changeWidth();
        }, 1);
      }
    },
    changeWidth(e) {
      let input = document.getElementById("myInput");
      const l = input.value.length;
      this.bankBlur();
      if ((l + 1) * 8 > 190) {
        input.style.width = (l + 1) * 8 + "px";
        this.descRight = (l + 1) * 8 - 10 + "px";
        return false;
      }
      if ((l + 1) * 8 > 50) {
        input.style.width = (l + 1) * 8 + "px";
        this.descRight = (l + 1) * 8 - 10 + "px";
      }
    },
  },
};
</script>

<style lang="less" scoped>
@import "./payroll.less";
.main-div-c {
  position: relative;
}
.descClass {
  position: absolute;
  top: 17px;
  width: 54px;
}
.cursorSty {
  cursor: pointer;
}
</style>
