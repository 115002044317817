<template>
  <div>
    <div v-for="(item, index) in dataList" :key="index">
      <p class="departmentName-style">
        {{ item.departmentName }} {{ item.salaryStaffList.length }} 人
      </p>
      <table class="accountTable">
        <thead>
          <tr>
            <th><div class="table-width1">发放状态</div></th>
            <th><div class="table-width2">姓名</div></th>
            <!-- <th><div class="table-width2">合同类型</div></th> -->
            <th><div class="table-width3">岗位</div></th>
            <th><div class="table-width4">岗位工资</div></th>
            <th><div class="table-width5">绩效/奖金</div></th>
            <th><div class="table-width6">补贴补助</div></th>
            <th><div class="table-width6">考勤扣款</div></th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(child, index) in item.salaryStaffList"
            :key="index"
            @click="detailsFun(child)"
          >
            <td>
              <div class="table-width1">
                <slot v-if="child.salaryStatus === 1">
                  <span style="color: #70c050">正常发放</span>
                </slot>
                <slot v-else-if="child.salaryStatus === 2">
                  <span style="color: #f58e21">缓发工资</span>
                </slot>
                <slot v-else-if="child.salaryStatus === 3">
                  <span style="color: #e74b47">停薪</span>
                </slot>
                <slot v-else-if="child.salaryStatus === 4">
                  <span style="color: #262626">无薪</span>
                </slot>
              </div>
            </td>
            <td>
              <div
                class="table-width2"
                :class="{
                  isFinishStyle: child.isFinish === 0 || child.isFinish === -1,
                }"
              >
                {{ child.nameText }}
                <span
                  class="isFinishStyle-span"
                  v-if="child.isFinish === 0 || child.isFinish === -1"
                  >({{ child.notice === "" ? "未完善" : child.notice }})</span
                >
              </div>
            </td>
            <!-- <td>
              <div class="table-width3">合同类型</div>
            </td> -->
            <td>
              <div class="table-width3">{{ child.postName }}</div>
            </td>
            <td>
              <div class="table-width4">{{ child.deSalary }}</div>
            </td>
            <td>
              <div class="table-width5">{{ child.acheBouns }}</div>
            </td>
            <td>
              <div class="table-width6">{{ child.butieSalary }}</div>
            </td>
            <td>
              <div class="table-width6">{{ child.kSalary }}</div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    dataList: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  methods: {
    detailsFun(child) {
      window.open(
        // "http://localhost:8185/navigation?pcTkn=" +
        window.location.origin +
          "/personInformation?id=" +
          child.salaryStaffId +
          "&user=" +
          child.userId,
        "_blank"
      );
      return;
      this.$router.push({
        // path: "/personDetails",
        path: "/personInformation",
        query: {
          id: child.salaryStaffId,
          user: child.userId,
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.accountTable {
  margin-bottom: 24px;
}
.accountTable tbody td {
  height: 55px;
}
.accountTable tbody tr {
  cursor: pointer;
}

.table-width1 {
  width: 125px;
}
.table-width2 {
  width: 188px;
}
.table-width3 {
  width: 278px;
}
.table-width4 {
  width: 298px;
}
.table-width5 {
  width: 147px;
}
.table-width6 {
  // width: 130px;
  width: 142px;
}
.isFinishStyle-span {
  display: none;
}

.departmentName-style {
  width: 100%;
  text-align: left;
  font-size: 14px;
  font-weight: 400;
  color: #262626;
  margin-bottom: 5px;
}
.isFinishStyle {
  color: #ea0200;
  span {
    position: relative;
    color: #ea0200;
    font-size: 12px;
    transform: scale(0.9, 0.83);
    display: inline-block;
    top: 1px;
    left: -5px;
    display: inline-block;
  }
}
</style>