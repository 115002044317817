<template>
  <!-- <div class="views-mian-header-btn"> -->
  <div class="" style="display: inline-block">
    <div
      class="btn-link1"
      :class="{
        shenbaoStatus60: tableData.length === 0,
      }"
      @click="downloadGeshui"
    >
      <!-- v-if="shenbaoStatus === 60" -->
      下载个税申报表
    </div>
    <div
      class="btn-link1"
      :class="{
        shenbaoStatus60: tableData.length === 0,
      }"
      @click="downloadSalary"
    >
      下载工资表
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // token: this.$getCookie("pc-tkn"),
      // userId: this.$getCookie("userId"),
      // enterpriseId: this.$getCookie("enterpriseId"),
      company: sessionStorage.getItem("company"),
      enterpriseId: sessionStorage.getItem("enterpriseId"),
      userId: sessionStorage.getItem("userId"),
      token: sessionStorage.getItem("pc-tkn"),
    };
  },
  props: {
    tableData: {
      type: Array,
      default() {
        return null;
      },
    },
    mouth: {
      type: String,
      default() {
        return null;
      },
    },
    shenbaoStatus: {
      type: Number,
      default() {
        return null;
      },
    },
    status: {
      type: String,
      default() {
        return null;
      },
    },
  },
  methods: {
    downloadGeshui() {
      if (this.tableData.length === 0) {
        return;
      }
      // if (this.status + "" === "1") {
      //   this.$store.commit("MyMessage", "请先将工资表提交审批");
      //   return false;
      // } else if (this.status + "" === "2") {
      //   this.$store.commit("MyMessage", "审批同意才能下载个税申报表");
      //   return false;
      // } else if (this.status + "" === "4") {
      //   this.$store.commit(
      //     "MyMessage",
      //     "审批同意才能进行下载,请登录APP,进行审批"
      //   );
      //   return false;
      // }
      let that = this;
      this.$store.commit("showLoading", "");
      const formData = new FormData();

      formData.append("enterpriseId", this.enterpriseId);
      formData.append("userId", this.userId);
      formData.append("month", this.mouth);
      const xhr = new XMLHttpRequest();
      xhr.onreadystatechange = (data) => {
        if (xhr.readyState === 4 && xhr.status === 200) {
          // type: "application/zip"

          const blob = new Blob([xhr.response], {
            type: "application/zip",
          }); // 构造一个blob对象来处理数据，并设置文件类型

          if (window.navigator.msSaveOrOpenBlob) {
            //兼容IE10
            navigator.msSaveBlob(blob, this.filename);
          } else {
            const href = URL.createObjectURL(blob); //创建新的URL表示指定的blob对象
            const a = document.createElement("a"); //创建a标签
            a.style.display = "none";
            a.href = href; // 指定下载链接
            a.download = this.company + " " + this.mouth + "个税报表"; //指定下载文件名
            a.click(); //触发下载
            URL.revokeObjectURL(a.href); //释放URL对象
          }
          this.$store.commit("hideLoading", "");

          return;
          let link = document.createElement("a");
          link.style.display = "none";
          // 请求文件数据，返回类型为blob数据流，类型application/octet-stream
          link.href = window.URL.createObjectURL(xhr.response);
          link.download = this.company + " " + this.mouth + "个税报表.zip"; //文件名
          document.head.appendChild(link);
          link.click();
          document.head.removeChild(link);
          this.$store.commit("hideLoading", "");
        }
      };
      let url = "http://api.ceshihr.com";
      if (that.$store.state.target === "https://api.jzcfo.com") {
        url = that.$store.state.target;
      }
      xhr.open("POST", url + "/manager/salaryDownloadService/downloadGeshui");
      xhr.setRequestHeader("token", this.token);
      xhr.responseType = "blob";
      xhr.send(formData);
    },
    downloadSalary() {
      if (this.tableData.length === 0) {
        return;
      }
      // if (this.status + "" === "1") {
      //   this.$store.commit("MyMessage", "请先将工资表提交审批");
      //   return false;
      // } else if (this.status + "" === "2") {
      //   this.$store.commit("MyMessage", "审批同意才能下载工资表");
      //   return false;
      // } else if (this.status + "" === "4") {
      //   this.$store.commit(
      //     "MyMessage",
      //     "审批同意才能进行下载,请登录APP,进行审批"
      //   );
      //   return false;
      // }
      let that = this;
      this.$store.commit("showLoading", "");
      const formData = new FormData();
      formData.append("enterpriseId", this.enterpriseId);
      formData.append("userId", this.userId);
      formData.append("month", this.mouth);
      const xhr = new XMLHttpRequest();
      xhr.onreadystatechange = (data) => {
        if (xhr.readyState === 4 && xhr.status === 200) {
          console.log(data);
          const blob = new Blob([xhr.response], {
            type: "application/vnd.ms-excel",
          }); // 构造一个blob对象来处理数据，并设置文件类型

          if (window.navigator.msSaveOrOpenBlob) {
            //兼容IE10
            navigator.msSaveBlob(blob, this.filename);
          } else {
            const href = URL.createObjectURL(blob); //创建新的URL表示指定的blob对象
            const a = document.createElement("a"); //创建a标签
            a.style.display = "none";
            a.href = href; // 指定下载链接
            a.download = this.company + " " + this.mouth + "工资表"; //指定下载文件名
            a.click(); //触发下载
            URL.revokeObjectURL(a.href); //释放URL对象
          }
          this.$store.commit("hideLoading", "");
          return;
          let link = document.createElement("a");
          link.style.display = "none";
          link.href = window.URL.createObjectURL(xhr.response);
          link.download = this.company + " " + this.mouth + "工资表.xls"; //文件名
          document.head.appendChild(link);
          link.click();
          document.head.removeChild(link);
          this.$store.commit("hideLoading", "");
        }
      };
      let url = "http://api.ceshihr.com";
      if (that.$store.state.target === "https://api.jzcfo.com") {
        url = that.$store.state.target;
      }
      xhr.open("POST", url + "/manager/salaryDownloadService2/downloadSalary");
      xhr.setRequestHeader("token", this.token);
      xhr.responseType = "blob";
      xhr.send(formData);
    },
  },
};
</script>

<style lang="less" scoped>
.btn-link1 {
  padding: 0 20px;
  display: inline-block;
  height: 32px;
  line-height: 32px;
  cursor: pointer;
  background: #ffffff;
  border: 1px solid #dbdbdb;
  font-size: 14px;
  font-weight: 400;
  color: #4c8afc;
  margin-left: 15px;
  border-radius: 3px;
}
.shenbaoStatus60 {
  opacity: 0.5;
}
</style>