<template>
  <div
    class="cascade-main"
    :style="{
      top: offsetTop + 'px',
      left: offsetWidth + 'px',
      bottom: popupBottom + 'px',
    }"
  >
    <div class="cascade-popup">
      <div class="cascade-title">
        请给<span style="color: #ff931e; padding: 0 7px; font-size: 12px">
          <slot v-if="ajaxData.name === ''">员工</slot>
          <slot v-if="ajaxData.name !== ''">{{ ajaxData.name }}</slot> </span
        >选择{{ item.text }}
      </div>
      <div class="cascade-list">
        <div class="cascade-list-header">一级</div>
        <div class="cascade-list-content">
          <div class="cascade-list-padding" id="cascadeId1">
            <div
              class="cascade-list-box"
              v-for="(item, index) in cityData1"
              :key="index"
              :class="{ cr: item.value === cityId1 }"
              @click.stop="cityDataFun1(item, index)"
              :id="item.value"
            >
              <svg-icon
                icon-class="selectUn"
                v-if="item.value !== cityId1"
                class="selectUn"
              ></svg-icon>
              <svg-icon
                icon-class="select"
                v-if="item.value === cityId1"
                class="selectUn"
              ></svg-icon>
              {{ item.label }}
            </div>
          </div>
        </div>
      </div>
      <div class="cascade-list">
        <div class="cascade-list-header">二级</div>
        <div class="cascade-list-content">
          <div class="cascade-list-padding" id="cascadeId2">
            <div
              class="cascade-list-box"
              v-for="(item, index) in cityData2"
              :key="index"
              :class="{ cr: item.value === cityId2 }"
              @click.stop="cityDataFun2(item, index)"
              :id="item.value"
            >
              <svg-icon
                icon-class="selectUn"
                v-if="item.value !== cityId2"
                class="selectUn"
              ></svg-icon>
              <svg-icon
                icon-class="select"
                v-if="item.value === cityId2"
                class="selectUn"
              ></svg-icon>
              {{ item.label }}
            </div>
          </div>
        </div>
      </div>
      <div
        class="cascade-list"
        v-show="cityData3.length > 0 && cityData3[0].label"
      >
        <div class="cascade-list-header">三级</div>
        <div class="cascade-list-content">
          <div class="cascade-list-padding" id="cascadeId3">
            <slot v-for="(item, index) in cityData3">
              <div
                class="cascade-list-box"
                :key="index"
                v-if="item.label"
                :class="{ cr: item.value === cityId3 }"
                :style="{ color: !show3 ? '#999' : '' }"
                @click.stop="cityDataFun3(item, index, false)"
                :id="item.value"
              >
                <slot v-if="!show3">
                  <svg-icon icon-class="selectNo" class="selectUn"></svg-icon>
                </slot>
                <slot v-else>
                  <svg-icon
                    icon-class="selectUn"
                    v-if="item.value !== cityId3"
                    class="selectUn"
                  ></svg-icon>
                  <svg-icon
                    icon-class="select"
                    v-if="item.value === cityId3"
                    class="selectUn"
                  ></svg-icon>
                </slot>
                <span> {{ item.label }}</span>
              </div>
            </slot>
          </div>
        </div>
      </div>
      <div class="clear"></div>
      <div class="cascade-btn">
        <el-button
          type="primary"
          class="primary-btn table-popup"
          @click.stop="cascadeClick()"
        >
          确定
        </el-button>
        <el-button
          type="text"
          class="text-btn table-popup"
          @click.stop="cascadeClose"
          >取消</el-button
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      value: "",
      options: this.$cityData,
      cityData1: [],
      cityData2: [],
      cityData3: [],
      cityId1: "",
      cityId2: "",
      cityId3: "",
      desc1: "",
      desc2: "",
      desc3: "",
      left: false,
      top: false,
      show3: true,
    };
  },
  props: {
    ajaxData: {
      type: Object,
      default() {
        return null;
      },
    },
    item: {
      type: Object,
      default() {
        return null;
      },
    },
    offsetTop: {
      type: Number,
      default() {
        return null;
      },
    },
    offsetWidth: {
      type: Number,
      default() {
        return null;
      },
    },
    popupBottom: {
      type: Number,
      default() {
        return null;
      },
    },
  },
  mounted() {
    this.cityData1 = this.options;
    this.cityData2 = this.options[0].children;
    this.cityData3 = this.options[0].children[0].children;
    this.cityId1 = this.item.value1;
    this.cityId2 = this.item.value2;
    this.cityId3 = this.item.value3;
    this.desc1 = this.item.desc1;
    this.desc2 = this.item.desc2;
    this.desc3 = this.item.desc3;
    console.log(this.item);
    if (this.cityId1) {
      this.options.forEach((e, i) => {
        if (e.value === this.cityId1) {
          this.cityData2 = e.children;
          e.children.forEach((e1, i1) => {
            if (e1.value === this.cityId2) {
              this.cityData3 = e1.children;
            }
          });
        }
      });
      this.$nextTick(() => {
        let id1 = document.getElementById(this.cityId1);
        let id2 = document.getElementById(this.cityId2);

        let total1 = id1.offsetTop;
        let total2 = id2.offsetTop;
        document.getElementById("cascadeId1").scrollTop = total1 - 90;
        document.getElementById("cascadeId2").scrollTop = total2 - 90;
        if (
          this.cityId1 === "110000" ||
          this.cityId1 === "120000" ||
          this.cityId1 === "310000" ||
          this.cityId1 === "500000"
        ) {
          let id3 = document.getElementById(this.cityId3);
          let total3 = id3.offsetTop;
          document.getElementById("cascadeId3").scrollTop = total3 - 90;
        }
      });
    } else {
      this.cityData2 = this.options[0].children;
      this.cityData3 = this.options[0].children[0].children;
      this.cityId1 = this.cityData1[0].value;
      this.cityId2 = this.cityData2[0].value;
      this.cityId3 = this.cityData3[0].value;
      this.desc1 = this.cityData1[0].label;
      this.desc2 = this.cityData2[0].label;
      this.desc3 = this.cityData3[0].label;
    }

    // console.log("ajaxData", this.ajaxData);
  },
  methods: {
    showFun() {
      if (
        this.cityId1 === "110000" ||
        this.cityId1 === "120000" ||
        this.cityId1 === "310000" ||
        this.cityId1 === "500000"
      ) {
        this.show3 = true;
      } else {
        this.show3 = false;
      }
    },
    cascadeClose() {
      this.$emit("cascadeClose");
    },
    cascadeClick() {
      let param = {
        value1: this.cityId1,
        value2: this.cityId2,
        value3: this.cityId3,
        desc1: this.desc1,
        desc2: this.desc2,
        desc3: this.desc3,
      };
      this.$emit("swiperSave", param);
    },
    cityDataFun1(item, index) {
      this.cityId1 = item.value;
      this.desc1 = item.label;
      // console.log(item);
      this.cityData2 = this.cityData1[index].children;
      this.cityDataFun2(this.cityData1[index].children[0], 0, true);
      this.showFun();
    },
    cityDataFun2(item, index, flag) {
      this.cityId2 = item.value;
      this.desc2 = item.label;
      this.cityData3 = this.cityData2[index].children;
      this.cityDataFun3(this.cityData2[index].children[0], 0, true);
      if (flag) {
        let dom = document.getElementById("cascadeId2");
        dom.scrollTop = 0;
      }
    },
    cityDataFun3(item, index, flag) {
      this.cityId3 = item.value;
      this.desc3 = item.label;

      if (flag) {
        let dom = document.getElementById("cascadeId3");
        dom.scrollTop = 0;
      }
    },
  },
};
</script>
<style lang="less" scoped>
.cascade-main {
  width: 700px;
  height: 420px;
  // border: 1px solid #dbdbdb;
  box-shadow: 0px 0px 10px rgba(157, 165, 178, 0.6);
  margin: 30px;
  position: fixed;
  z-index: 99;
  background: #fff;
  .cascade-popup {
    width: calc(100% - 20px);
    margin: 10px;
  }
  .cascade-title {
    padding: 5px 0;
    font-size: 16px;
    color: #262626;
    font-size: 12px;
  }
  .cascade-list {
    width: 220px;
    height: 300px;
    float: left;
    // height: 100%;
    border: 1px solid #dbdbdb;
    margin-bottom: 10px;

    .cascade-list-header {
      height: 30px;
      line-height: 30px;
      border-bottom: 1px solid #dbdbdb;
      padding-left: 15px;
      font-size: 14px;
      background: #f9f9f9;
    }
    .cascade-list-content {
      height: calc(100% - 51px);
      padding: 10px 0;
      // max-height: 240px;
      // overflow-y: auto;
    }
    .cascade-list-padding {
      height: 100%;
      overflow-y: auto;
    }
    .cascade-list-box {
      width: calc(100% - 15px);
      // height: 30px;
      line-height: 30px;
      text-align: left;
      padding-left: 15px;
      cursor: pointer;
      font-weight: 400;
      -moz-user-select: none; /*火狐*/
      -webkit-user-select: none; /*webkit浏览器*/
      -ms-user-select: none; /*IE10*/
      -khtml-user-select: none; /*早期浏览器*/
      user-select: none;
      .selectUn {
        width: 16px;
        height: 16px;
        position: relative;
        top: 1px;
      }
      &.cr,
      &:hover {
        color: #4c8afc;
        font-weight: bold;
      }
    }
  }
}
.primary-btn {
  width: 200px;
}
.text-btn {
  width: 100px;
}
</style>