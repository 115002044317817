<template>
  <div class="popup-layer">
    <div class="popup-layer-bg" @click="hidePopup"></div>
    <div class="popup-layer-content">
      <div class="popup-layer-close" @click="hidePopup">
        <svg-icon icon-class="popup-close"></svg-icon>
      </div>
      <div class="addStaffPopup-header">添加员工</div>
      <div class="addStaffPopup-body">
        <div class="addStaffPopup-box">
          <div class="addStaffPopup-box-l">姓名</div>
          <div class="addStaffPopup-box-r">
            <input
              type="text"
              v-model="enterpriseEmployee.name"
              @input="
                enterpriseEmployee.name = enterpriseEmployee.name.replace(
                  /[^\u4E00-\u9FA5A-Za-z.]/g,
                  ''
                )
              "
              placeholder="真实姓名"
            />
          </div>
        </div>
        <div class="addStaffPopup-box">
          <div class="addStaffPopup-box-l">手机号</div>
          <div class="addStaffPopup-box-r">
            <input
              type="tel"
              maxlength="13"
              v-model="mobile"
              placeholder="常用手机号"
              @blur="blur"
            />
          </div>
        </div>
        <div class="addStaffPopup-box">
          <div class="addStaffPopup-box-l">岗位名称</div>
          <div class="addStaffPopup-box-r">
            <input
              type="text"
              v-model="enterpriseEmployee.postName"
              placeholder="请输入"
            />
          </div>
        </div>
        <div class="addStaffPopup-box">
          <div class="addStaffPopup-box-l">所属部门</div>
          <div
            class="addStaffPopup-box-r cityText"
            :class="{ cityDesc: selectText === '请选择' }"
            @click="popupShowClick($event, 2)"
          >
            {{ selectText
            }}<svg-icon icon-class="xiala-icon" class="xiala"></svg-icon>
          </div>
        </div>

        <div class="addStaffPopup-box">
          <div class="addStaffPopup-box-l">现居住地</div>
          <div
            class="addStaffPopup-box-r cityText"
            :class="{ cityDesc: showText === '所在地区' }"
            @click="popupShowClick($event, 1)"
          >
            {{ showText
            }}<svg-icon icon-class="xiala-icon" class="xiala"></svg-icon>
          </div>
        </div>
        <div class="addStaffPopup-box">
          <div class="addStaffPopup-box-l">现居住详细地址</div>
          <div class="addStaffPopup-box-r">
            <el-input
              type="textarea"
              :autosize="{ minRows: 1, maxRows: 6 }"
              v-model="enterpriseEmployee.contactDetail"
              placeholder="请输入"
              class="textStyle"
            >
            </el-input>
          </div>
        </div>
      </div>
      <div class="addStaffPopup-footer">
        <div class="addStaff-btn" @click="addStaffSave">添 加</div>
      </div>
    </div>
    <cascadePopup
      v-if="popupShow"
      :offsetTop="popupTop"
      :offsetWidth="popupLeft"
      :popupBottom="popupBottom"
      :ajaxData="enterpriseEmployee"
      :item="item"
      @swiperSave="swiperSave"
      @cascadeClose="cascadeClose"
    ></cascadePopup>

    <div
      class="staff-popup fadeIn"
      :style="{
        left: popupLeft + 'px',
        top: popupTop + 'px',
        bottom: popupBottom + 'px',
        height: item.field === 'bankProvinceId' ? '417px' : '',
      }"
      v-if="selectShow"
      @mouseleave="selectShow = false"
    >
      <Triangle direction="top" left="290" color="#cccccc"></Triangle>
      <div class="staff-popup-header" v-if="!enterpriseEmployee.name">
        请给<span>{{ name }}</span
        >选择所属部门
      </div>
      <div class="staff-popup-header" v-else>
        请给<span>{{ enterpriseEmployee.name }}</span
        >选择所属部门
      </div>
      <div class="staff-popup-content">
        <div
          class="staff-popup-box"
          v-for="(item, i) in arrayList"
          :key="i"
          @click.stop="listClick(item)"
        >
          <div class="staff-popup-box-l">
            <svg-icon
              v-if="item.id === enterpriseEmployee.departmentId"
              icon-class="duigou-yes"
              class="duigou"
            ></svg-icon>
            <svg-icon
              v-if="item.id !== enterpriseEmployee.departmentId"
              icon-class="duigou-no"
              class="duigou"
            ></svg-icon>
          </div>
          <div
            class="staff-popup-box-r"
            :class="{ gudongr: item.text === '股东' }"
          >
            {{ item.text }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import cascadePopup from "@/components/cascadePopup";
import { addEmployee } from "@/http/api";
export default {
  data() {
    return {
      userId: sessionStorage.getItem("userId"),
      enterpriseId: sessionStorage.getItem("enterpriseId"),
      item: {
        value1: "",
        value2: "",
        value3: "",
        desc1: "",
        desc2: "",
        desc3: "",
      },
      popupShow: false,
      selectShow: false,
      popupTop: null,
      popupLeft: 0,
      popupBottom: null,
      showText: "所在地区",
      enterpriseEmployee: {
        area: "",
        areaId: "",
        bankCardNum: "",
        bankCode: "",
        bankName: "",
        bankProvince: "",
        bankProvinceId: "",
        birth: "",
        bornNation: "",
        chinaName: "",
        city: "",
        cityId: "",
        contactArea: "",
        contactAreaId: "",
        contactCity: "",
        contactCityId: "",
        contactDetail: "",
        contactProvince: "",
        contactProvinceId: "",
        contractTerm: 0,
        contractType: 3,
        degreeImg: "",
        departmentId: "",
        departmentName: "",
        dimissionTime: "",
        diplomaImg: "",
        disabilityNum: "",
        dutiesType: 0,
        education: 0,
        employeeId: "",
        enterpriseDepartment: {},
        enterpriseId: "",
        estimateLeaveTime: "",
        firstEntryTime: "",
        gender: "",
        headOne: "",
        hujiDetail: "",
        idNumber: "",
        idType: 0,
        idcardImgFront: "",
        idcardImgReverse: "",
        identity: 1,
        investMoney: "",
        investproportion: "",
        isAbroad: 0,
        isAdmin: 0,
        isDeductionCost: 0,
        isDisability: 0,
        isLonelyOld: 0,
        isMartyrFamily: 0,
        isOnterm: 0,
        isleave: 0,
        jobTime: "",
        martyrFamilyNum: "",
        mobile: "",
        name: "",
        nationality: "1",
        oftenArea: "",
        oftenAreaId: "",
        oftenCity: "",
        oftenCityId: "",
        oftenDetail: "",
        oftenProvince: "",
        oftenProvinceId: "",
        onTrialTermBegin: "",
        onTrialTermEnd: "",
        onTrialTermSalaryScale: "",
        otherCardNum: "",
        otherCardType: "",
        postName: "",
        postSalary: 0,
        province: "",
        provinceId: "",
        registered: 0,
        role: "",
        ruzhiqingxing: 0,
        school: "",
        sssy: 0,
        status: 0,
        subsidyList: [],
        userAuthorizationList: [],
        userHead: "",
        userId: "",
      },
      arrayList: {},
      requireList: [
        "name",
        "mobile",
        "postName",
        "contactProvinceId",
        "contactDetail",
      ],
      selectText: "请选择",
      name: "员工",
      mobile: "",
    };
  },
  components: {
    cascadePopup,
  },
  watch: {
    //监听手机号位数input输入
    mobile(newValue, oldValue) {
      if (this.mobile) {
        this.mobile = this.mobile.substring(0, 13);
        this.mobile =
          newValue.length > oldValue.length
            ? newValue
                .replace(/\s/g, "")
                .replace(/(\d{3})(\d{0,4})(\d{0,4})/, "$1 $2 $3")
            : this.mobile.trim();
        // 用户名与密码联动效果
        // var reg = /^[1-9]\\d{" + 0 + "," + 13 + "}$/;
        // var re = new RegExp(reg);
        // console.log(re.text(thismobile));
        this.mobile = this.mobile.substring(0, 13);
      }
    },
  },
  mounted() {
    this.arrayList = JSON.parse(sessionStorage.getItem("departmentList"));
    this.arrayList.forEach((element) => {
      element.text = element.departmentName;
      element.id = element.departmentId;
    });
  },
  methods: {
    hidePopup() {
      this.$emit("hidePopup");
    },
    blur() {
      const regMobile = /^1(3|4|5|6|7|8|9)\d{9}$/;
      if (!regMobile.test(this.mobile.replace(/\s+/g, ""))) {
        this.$store.commit("MyMessage", "手机号不符合规则");
        return;
      }
    },
    addStaffSave() {
      var jmz = {};
      this.enterpriseEmployee.mobile = this.mobile;
      jmz.GetLength = function (str) {
        return str.replace(/[\u0391-\uFFE5]/g, "aa").length; //先把中文替换成两个字节的英文，在计算长度
      };
      let length1 = jmz.GetLength(this.enterpriseEmployee.name);
      if (!this.enterpriseEmployee.name) {
        this.$store.commit("MyMessage", "请输入姓名");
        return;
      } else if (length1 < 4) {
        this.$store.commit("MyMessage", "姓名长度不符合规则");
        return;
      }
      if (!this.enterpriseEmployee.mobile) {
        this.$store.commit("MyMessage", "请输入常用手机号");
        return;
      }
      const regMobile = /^1(3|4|5|6|7|8|9)\d{9}$/;
      if (!regMobile.test(this.enterpriseEmployee.mobile.replace(/\s+/g, ""))) {
        this.$store.commit("MyMessage", "手机号不符合规则");
        return;
      }
      if (!this.enterpriseEmployee.postName) {
        this.$store.commit("MyMessage", "请输入岗位名称");
        return;
      }
      if (!this.enterpriseEmployee.departmentId) {
        this.$store.commit("MyMessage", "请选择所属部门");
        return;
      }
      if (!this.enterpriseEmployee.contactProvinceId) {
        this.$store.commit("MyMessage", "请选择现居住地");
        return;
      }
      if (!this.enterpriseEmployee.contactDetail) {
        this.$store.commit("MyMessage", "请输入现居住详细地址");
        return;
      }
      let enterpriseEmployee = this.$config.cloneObj(this.enterpriseEmployee);
      enterpriseEmployee.mobile = enterpriseEmployee.mobile.replace(/\s+/g, "");
      let param = {
        enterpriseId: this.enterpriseId,
        userId: this.userId,
        enterpriseEmployee: enterpriseEmployee,
      };
      addEmployee(param).then((res) => {
        console.log("res", res);
        if (res.code === 0) {
          this.$store.commit("MyMessage", "添加成功");
          this.$emit("savePopup");
        } else {
          this.$store.commit("MyMessage", res.msg);
        }
      });
    },
    listClick(item) {
      this.enterpriseEmployee.departmentId = item.id;
      this.selectText = item.text;
      this.cascadeClose();
    },
    cascadeClose() {
      this.popupShow = false;
      this.selectShow = false;
      this.$forceUpdate();
    },
    swiperSave(item) {
      this.showText = item.desc1 + " - " + item.desc2 + " - " + item.desc3;
      this.popupShow = false;

      this.enterpriseEmployee.contactProvince = item.desc1;
      this.enterpriseEmployee.contactCity = item.desc2;
      this.enterpriseEmployee.contactArea = item.desc3;
      this.enterpriseEmployee.contactProvinceId = item.value1;
      this.enterpriseEmployee.contactCityId = item.value2;
      this.enterpriseEmployee.contactAreaId = item.value3;
    },
    popupShowClick(e, type) {
      if (type === 1) {
        this.popupShow = true;
      } else {
        if (!this.enterpriseEmployee.postName) {
          this.$store.commit("MyMessage", "请先输入岗位名称");
          return;
        }
        this.selectShow = true;
      }
      let H1 =
        document.body.clientHeight -
        e.currentTarget.getBoundingClientRect().bottom;
      if (H1 < 440) {
        this.popupBottom = H1 + 10;
        this.direction = "bottom";
      } else {
        this.popupTop = e.currentTarget.getBoundingClientRect().top + 10;
        this.direction = "top";
      }
      this.popupLeft = e.currentTarget.getBoundingClientRect().left - 139;
      if (type === 2) {
        this.popupLeft += 50;
        this.popupTop += 28;
      }
    },
  },
};
</script>
<style lang="less" scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type="number"] {
  -moz-appearance: textfield;
}
</style>