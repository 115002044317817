var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"popup-layer"},[_c('div',{staticClass:"popup-layer-bg",on:{"click":_vm.rosterClose}}),(_vm.personShow)?_c('div',{staticClass:"personPopup"},[_c('div',{staticClass:"popup-layer-close",on:{"click":_vm.rosterClose}},[_c('svg-icon',{attrs:{"icon-class":"popup-close"}})],1),_c('div',{staticClass:"addStaffPopup-header"},[(_vm.enterpriseEmployee.name)?_c('span',[_vm._v(" "+_vm._s(_vm.enterpriseEmployee.name)+" - ")]):_vm._e(),_vm._v(" 员工信息 ")]),_c('div',{staticClass:"addStaffPopup-content"},[_c('div',{},[(_vm.addStaffShow)?_c('div',{staticClass:"addStaff-main"},[(_vm.enterpriseEmployee.status + '' === '2')?_c('div',{staticClass:"hideStyle"}):_vm._e(),_c('div',{},_vm._l((_vm.employeeDate.employeeList),function(item,i){return _c('div',{key:i,staticClass:"addStaff-list"},[(i === 0)?_c('p',{staticStyle:{"color":"#bbbbbb"}},[_vm._v("基本信息")]):_vm._e(),(i === 1)?_c('p',{staticStyle:{"color":"#bbbbbb"}},[_vm._v("岗位信息")]):_vm._e(),_vm._l((item.list),function(child,i1){return _vm._t("default",function(){return [(
                    (child.field === 'status' &&
                      _vm.enterpriseEmployee.isAdmin === 1) ||
                    (_vm.enterpriseEmployee.status === 10 &&
                      child.field === 'status')
                  )?_vm._t("default"):(child.field === 'onTrialTermSalaryScale')?_vm._t("default",function(){return [(_vm.enterpriseEmployee.isOnterm + '' === '0')?_vm._t("default"):_vm._t("default",function(){return [_c('StaffBox',{key:'info-' + i1,attrs:{"item":child,"index1":i + '',"index2":i1,"ajaxData":_vm.enterpriseEmployee,"staffType":"add"},on:{"staffParent":_vm.staffParent}})]})]}):_c('StaffBox',{key:'info-' + i1,attrs:{"item":child,"index1":i + '',"index2":i1,"ajaxData":_vm.enterpriseEmployee,"staffType":_vm.staffType},on:{"staffParent":_vm.staffParent}})]})})],2)}),0),_c('div',{staticClass:"clear"}),(!_vm.isShow)?_c('div',{staticClass:"addStaff-lines",on:{"click":function($event){_vm.isShow = !_vm.isShow}}},[_c('svg-icon',{staticClass:"big-jiantou",attrs:{"icon-class":"big-jiantou"}})],1):_vm._e(),(_vm.isShow)?_c('div',{staticStyle:{"overflow":"hidden","margin-top":"20px"}},_vm._l((_vm.employeeDate.employeeMoreList),function(item,i){return _c('div',{key:'info-' + i,staticClass:"addStaff-list addStaff-list-bottom"},[(i === 0)?_c('p',{staticStyle:{"color":"#bbbbbb"}},[_vm._v("选填")]):_vm._e(),(i === 1)?_c('p',{staticStyle:{"height":"19px"}}):_vm._e(),_vm._l((item.list),function(child,i1){return _vm._t("default",function(){return [(child.field === 'disabilityNum')?_vm._t("default",function(){return [(
                      item.list[i1 - 1].value+'' !== '0' &&
                      item.list[i1 - 1].value+'' !== '-1' &&
                      item.list[i1 - 1].value+'' !== ''
                    )?_c('StaffBox',{key:'info-' + i1,attrs:{"item":child,"index1":i + '',"index2":i1,"ajaxData":_vm.enterpriseEmployee,"staffType":_vm.staffType},on:{"staffParent":_vm.staffParent}}):_vm._e()]}):(child.field === 'martyrFamilyNum')?_vm._t("default",function(){return [(
                      item.list[i1 - 1].value+'' !== '0' &&
                      item.list[i1 - 1].value+'' !== '-1' &&
                      item.list[i1 - 1].value+'' !== ''
                    )?_c('StaffBox',{key:'info-' + i1,attrs:{"item":child,"index1":i + '',"index2":i1,"ajaxData":_vm.enterpriseEmployee,"staffType":_vm.staffType},on:{"staffParent":_vm.staffParent}}):_vm._e()]}):_vm._t("default",function(){return [_c('StaffBox',{key:'info-' + i1,attrs:{"item":child,"index1":i + '',"index2":i1,"ajaxData":_vm.enterpriseEmployee,"staffType":_vm.staffType},on:{"staffParent":_vm.staffParent}})]})]})})],2)}),0):_vm._e(),_c('div',{staticClass:"clear"}),(_vm.isShow)?_c('div',{staticClass:"addStaff-lines",on:{"click":function($event){_vm.isShow = !_vm.isShow}}},[_c('svg-icon',{staticClass:"big-jiantou isRotate",attrs:{"icon-class":"big-jiantou"}})],1):_vm._e()]):_vm._e()])]),(_vm.baosongStatus + '' !== '1')?_c('div',{staticClass:"addStaff-footer-btns"},[(_vm.enterpriseEmployee.status + '' !== '2')?_c('div',{staticClass:"addStaff-footer-btn",on:{"click":_vm.addStaffSave}},[_vm._v(" 保 存 ")]):_vm._e()]):_c('div',{staticClass:"addStaff-footer-btns"},[_c('div',{staticClass:"addStaff-footer-btn",staticStyle:{"opacity":"0.5","border-color":"rgb(76 138 252 / 0.5)"}},[_vm._v(" 报送中 ")])])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }