<template>
  <div class="popup-layer">
    <div class="popup-layer-bg" @click="delayClose"></div>
    <div class="invitation-main payment-height">
      <div class="popup-layer-close" @click="delayClose">
        <svg-icon icon-class="popup-close"></svg-icon>
      </div>
      <div
        style="
          width: calc(100% - 60px);
          max-height: 400px;
          padding: 15px 30px 0;
        "
      >
        <div class="delay-popup">
          <div class="delay-popup-title">请选择缓发工资的员工</div>
          <div class="delay-popup-content webkit-scrollbar">
            <div class="selsect-height webkit-scrollbar">
              <slot v-for="(item, index) in showList">
                <div
                  class="invitation-popup-select"
                  :key="index"
                  @click="delayClick(item)"
                >
                  <div class="invitation-popup-select-text">
                    <div class="delay-icon">
                      <svg-icon
                        v-if="item.flag"
                        icon-class="duigou-yes"
                        class="duigou sHide"
                      ></svg-icon>
                      <svg-icon
                        v-if="!item.flag"
                        icon-class="duigou-no"
                        class="duigou sHide"
                      ></svg-icon>
                    </div>
                    <div class="invitation-popup-box-text-l delay-l1">
                      <img v-if="item.userHead" :src="item.userHead" alt="" />
                      <span class="invitation-popup-header" v-else>
                        {{ item.nickNameText }}
                      </span>
                    </div>
                    <div class="invitation-popup-box-text-r delay-r1">
                      <div class="delay-class">
                        {{ item.nickName
                        }}<span
                          style="
                            font-size: 12px;
                            color: #bbb;
                            padding-left: 5px;
                          "
                        >
                          {{ item.postName }}</span
                        ><span class="invitation-desc delay-desc1"
                          >¥{{ item.netSalary.toFixed(2) }}</span
                        >
                      </div>
                      <!-- <div class="delay-class delay-class1">
                        <span class="invitation-desc delay-desc">实发工资</span>
                      </div> -->
                    </div>
                  </div>
                </div>
                <div class="clear" :key="'li1-' + index"></div>
              </slot>
            </div>
          </div>
          <div class="delay-popup-footer">
            <div class="delay-popup-footer-l">
              <span @click="selectAllFun" style="padding-left: 0">
                <svg-icon
                  v-if="selectAll"
                  icon-class="duigou-yes"
                  class="duigou sHide"
                ></svg-icon>
                <svg-icon
                  v-if="!selectAll"
                  icon-class="duigou-no"
                  class="duigou sHide"
                ></svg-icon>
                <span class="span1">合计 </span>
              </span>
              <span class="span2">¥{{ allMoney }}</span>
            </div>
            <div class="delay-popup-footer-r">
              <slot v-if="showList.length === personNum">全选</slot
              ><slot v-else>已选</slot>{{ personNum }}人
            </div>
          </div>
        </div>
        <div class="invitation-popup-title">选择审批人</div>
        <div class="invitation-popup-box" :class="{ borderNone: show1 }">
          <div class="invitation-popup-box-text" @click="shenpiClick">
            <div
              class="invitation-popup-box-text-center"
              v-if="item1.name === ''"
            >
              请选择
            </div>
            <div class="invitation-popup-box-text-l" v-if="item1.name !== ''">
              <img v-if="item1.userHead" :src="item1.userHead" alt="" />

              <span class="invitation-popup-header" v-else>
                {{ item1.nameText }}
              </span>
              <!-- <img src="" alt="" /> -->
            </div>
            <div class="invitation-popup-box-text-r" v-if="item1.name !== ''">
              {{ item1.nameText1
              }}<span class="invitation-desc">{{ item1.postName }}</span>
            </div>
          </div>
          <!-- @mouseleave="show1 = false" -->
          <div class="invitation-popup-select-main" v-if="show1">
            <div class="invitation-popup-select">
              <div class="invitation-popup-select-text">
                <div
                  class="invitation-popup-box-text-center"
                  v-if="item1.name === ''"
                >
                  请选择
                </div>
                <div
                  class="invitation-popup-box-text-l"
                  v-if="item1.name !== ''"
                >
                  <img v-if="item1.userHead" :src="item1.userHead" alt="" />
                  <span class="invitation-popup-header" v-else>
                    {{ item1.nameText }}
                  </span>
                </div>
                <div
                  class="invitation-popup-box-text-r"
                  v-if="item1.name !== ''"
                >
                  {{ item1.nameText1
                  }}<span class="invitation-desc">{{ item1.postName }}</span>
                </div>
              </div>
            </div>
            <div class="selsect-height webkit-scrollbar">
              <slot v-for="(item, index) in approvalList">
                <div
                  class="invitation-popup-select"
                  :key="index"
                  @click="itemClick1(item)"
                >
                  <div class="invitation-popup-select-text">
                    <div class="invitation-popup-box-text-l">
                      <img v-if="item.userHead" :src="item.userHead" alt="" />
                      <span class="invitation-popup-header" v-else>
                        {{ item.nameText }}
                      </span>
                    </div>
                    <div class="invitation-popup-box-text-r">
                      {{ item.nameText1
                      }}<span class="invitation-desc">{{ item.postName }}</span>
                    </div>
                  </div>
                </div>
                <div class="clear" :key="'li1-' + index"></div>
              </slot>
            </div>
          </div>
        </div>
        <div class="invitation-popup-title lastChild">选择付款人</div>
        <div class="invitation-popup-box" :class="{ borderNone: show2 }">
          <div
            class="invitation-popup-box-text"
            @click="
              show2 = true;
              show1 = false;
            "
          >
            <div
              class="invitation-popup-box-text-center"
              v-if="item2.name === ''"
            >
              请选择
            </div>
            <div class="invitation-popup-box-text-l" v-if="item2.name !== ''">
              <img v-if="item2.userHead" :src="item2.userHead" alt="" />
              <span class="invitation-popup-header" v-else>
                {{ item2.nameText1 }}
              </span>
            </div>
            <div class="invitation-popup-box-text-r" v-if="item2.name !== ''">
              {{ item2.nameText1
              }}<span class="invitation-desc">{{ item2.postName }}</span>
            </div>
          </div>
          <div
            class="invitation-popup-select-main"
            v-if="show2"
            @mouseleave="show2 = false"
          >
            <div class="invitation-popup-select">
              <div class="invitation-popup-select-text">
                <div
                  class="invitation-popup-box-text-center"
                  v-if="item2.name === ''"
                >
                  请选择
                </div>
                <div
                  class="invitation-popup-box-text-l"
                  v-if="item2.name !== ''"
                >
                  <img v-if="item2.userHead" :src="item2.userHead" alt="" />
                  <span class="invitation-popup-header" v-else>
                    {{ item2.nameText }}
                  </span>
                </div>
                <div
                  class="invitation-popup-box-text-r"
                  v-if="item2.name !== ''"
                >
                  {{ item2.nameText1
                  }}<span class="invitation-desc">{{ item2.postName }}</span>
                </div>
              </div>
            </div>
            <div class="selsect-height webkit-scrollbar">
              <slot v-for="(item, index) in FukuanList">
                <div
                  class="invitation-popup-select"
                  :key="index"
                  @click="itemClick2(item)"
                >
                  <div class="invitation-popup-select-text">
                    <div class="invitation-popup-box-text-l">
                      <img v-if="item.userHead" :src="item.userHead" alt="" />
                      <span class="invitation-popup-header" v-else>
                        {{ item.name }}
                      </span>
                    </div>
                    <div class="invitation-popup-box-text-r">
                      {{ item.nameText1
                      }}<span class="invitation-desc">{{ item.postName }}</span>
                    </div>
                  </div>
                </div>
                <div class="clear" :key="'li2-' + index"></div>
              </slot>
            </div>
          </div>
        </div>

        <div class="invitation-popup-btn" @click="submmitHuanfaSalaryCheck()">
          提交审批
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getEmployeeFukuan,
  getEnterpriseEmployeeCheckers,
  submmitSalaryCheck,
  submmitSalaryCheckV2,
  submmitHuanfaSalaryCheck,
} from "@/http/api";
export default {
  data() {
    return {
      userId: sessionStorage.getItem("userId"),
      enterpriseId: sessionStorage.getItem("enterpriseId"),
      FukuanList: [],
      approvalList: [],
      selectShow1: false,
      item1: { name: "" },
      item2: { name: "" },
      show1: false,
      show2: false,
      approvaName: "",
      remark: "",
      fukuanName: "",
      allSelect: false,
      selectAll: false,
      list: [],
      showList: [],
      allMoney: 0,
      personNum: 0,
      salaryStaffIdList: [],
    };
  },
  mounted() {
    this.getEmployeeFukuan();
    this.getEnterpriseEmployeeCheckers();
    console.log(this.tableData);
    this.list = this.tableData;
    this.showList = [];
    this.salaryStaffIdList = [];
    this.allMoney = 0;
    this.list.forEach((element) => {
      element.nickNameText = element.nickName.substr(
        element.nickName.length - 2,
        2
      );
      this.allMoney += element.netSalary;
      element.flag = true;
      if (
        element.salaryStatus === 2 &&
        (element.status === 1 || element.status === 4)
      ) {
        this.showList.push(element);
        this.salaryStaffIdList.push(element.salaryStaffId);
      }
    });
    this.selectAll = true;
    this.allMoney = this.allMoney.toFixed(2);
    this.personNum = this.showList.length;
    console.log(this.showList);
  },
  props: {
    mouth: {
      type: String,
      default() {
        return null;
      },
    },
    batch: {
      type: String,
      default() {
        return null;
      },
    },
    tableData: {
      type: Array,
      default() {
        return null;
      },
    },
  },
  methods: {
    selectAllFun() {
      this.selectAll = !this.selectAll;
      this.showList.forEach((element) => {
        element.flag = this.selectAll;
      });
      this.jisuan();
      this.$forceUpdate();
    },
    delayClick(item) {
      item.flag = !item.flag;
      this.jisuan();
      this.$forceUpdate();
    },
    jisuan() {
      this.allMoney = 0;
      this.personNum = 0;
      this.salaryStaffIdList = [];
      this.showList.forEach((element) => {
        if (element.flag) {
          this.allMoney += element.netSalary;
          this.personNum++;
          this.salaryStaffIdList.push(element.salaryStaffId);
        }
      });
      this.allMoney = this.allMoney.toFixed(2);
      if (this.salaryStaffIdList.length === this.showList.length) {
        this.selectAll = true;
      } else {
        this.selectAll = false;
      }
    },
    shenpiClick() {
      this.show1 = true;
      this.show2 = false;
    },
    itemClick1(item) {
      this.item1 = item;
      this.show1 = false;
    },
    itemClick2(item) {
      this.show2 = false;
      this.item2 = item;
    },
    submmitHuanfaSalaryCheck() {
      if (!this.salaryStaffIdList.length) {
        this.$store.commit("MyMessage", "请选择缓发人员");
        return;
      }
      if (!this.item1.userId) {
        this.$store.commit("MyMessage", "请选择审批人");
        return;
      }
      if (!this.item2.userId) {
        this.$store.commit("MyMessage", "请选择付款人");
        return;
      }
      this.$store.commit("showLoading", "");
      let param = {
        enterpriseId: this.enterpriseId,
        userId: this.userId,
        month: this.mouth,
        checkId: this.item1.userId,
        batch: this.batch,
        fukuanrenId: this.item2.userId,
        salaryStaffIdList: this.salaryStaffIdList,
      };
      submmitHuanfaSalaryCheck(param).then((res) => {
        this.$store.commit("hideLoading", "");
        if (res.code === 0) {
          this.$store.commit("MyMessage", "提交成功");
          this.$emit("delayClose");
        } else {
          this.$store.commit("MyMessage", res.msg);
        }
      });
    },
    selectApproval() {
      this.$store.commit("showLoading", "");
      this.submmitSalaryCheckV2();
    },
    submmitSalaryCheckV2() {
      if (!this.item1.userId) {
        this.$store.commit("MyMessage", "请选择审批人");
        return;
      }
      if (!this.item2.userId) {
        this.$store.commit("MyMessage", "请选择付款人");
        return;
      }
      let param = {
        enterpriseId: this.enterpriseId,
        userId: this.userId,
        month: this.mouth,
        checkId: this.item1.userId,
        batch: this.batch,
        fukuanrenId: this.item2.userId,
        remark: this.remark,
      };
      submmitSalaryCheckV2(param).then((res) => {
        this.$store.commit("hideLoading", "");
        if (res.code === 0) {
          this.status = 2;
          this.$store.commit("MyMessage", "提交成功");
          this.$delCookie("approvalShow"); //删除权限信息
          this.$emit("delayClose");
          location.reload();
        } else {
          this.$store.commit("MyMessage", res.msg);
        }
      });
    },
    delayClose() {
      this.$emit("delayClose");
    },
    getEmployeeFukuan() {
      let param = {
        enterpriseId: this.enterpriseId,
        userId: this.userId,
      };
      getEmployeeFukuan(param).then((res) => {
        console.log("getEmployeeFukuan", res.data);
        this.FukuanList = res.data;
        this.FukuanList.forEach((element) => {
          if (element.userHead.indexOf("http://jz.jzdcs.com") !== -1) {
            element.userHead =
              "https://www.jzcfo.com" +
              element.userHead.split("http://jz.jzdcs.com")[1];
          }
          const regMobile = /^1(3|4|5|6|7|8|9)\d{9}$/;

          if (regMobile.test(element.name)) {
            element.nameText =
              "*" + element.name.substr(element.name.length - 2, 2);
            element.nameText1 =
              "*" + element.name.substr(element.name.length - 2, 2);
          } else {
            element.nameText = element.name.substr(element.name.length - 2, 2);
            element.nameText1 = element.name;
          }
        });
      });
    },
    getEnterpriseEmployeeCheckers() {
      let that = this;
      let param = {
        enterpriseId: this.enterpriseId,
        userId: this.userId,
        pageNum: "1",
        pageSize: "9999",
      };
      getEnterpriseEmployeeCheckers(param).then((res) => {
        // console.log(res);
        if (res.code === 0) {
          this.approvalList = res.data.list;

          let fristList = null;
          let fristIndex = null;

          this.approvalList.forEach((element, index) => {
            if (that.userId === element.userId) {
              fristList = element;
              fristIndex = index;
            }
            if (element.userHead.indexOf("http://jz.jzdcs.com") !== -1) {
              element.userHead =
                "https://www.jzcfo.com" +
                element.userHead.split("http://jz.jzdcs.com")[1];
            }
            const regMobile = /^1(3|4|5|6|7|8|9)\d{9}$/;
            if (regMobile.test(element.name)) {
              element.nameText =
                "*" + element.name.substr(element.name.length - 2, 2);
              element.nameText1 =
                "*" + element.name.substr(element.name.length - 2, 2);
            } else {
              element.nameText = element.name.substr(
                element.name.length - 2,
                2
              );
              element.nameText1 = element.name;
            }
            element.idType = element.idType ? element.idType : "1";
          });
          if (this.approvalList.length > 1) {
            // console.log(fristList);
            if (fristIndex) {
              this.approvalList.splice(fristIndex, 1);
              this.approvalList.unshift(fristList);
            }
          }
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.delay-popup {
  width: 100%;
  margin-bottom: 25px;
  .delay-popup-title {
    width: calc(100% - 20px);
    height: 40px;
    line-height: 40px;
    text-align: left;
    font-size: 12px;
    font-weight: 400;
    color: #999999;
    padding: 0 10px;
  }
  .delay-popup-content {
    width: 100%;
    height: 180px;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 16px;
    }
  }
  .delay-popup-footer {
    padding: 16px 0;
    border-top: 1px solid rgba(219, 219, 219, 0.5);
    border-bottom: 1px solid rgba(219, 219, 219, 0.5);
    margin-bottom: 10px;
    margin-top: 10px;
    width: 100%;
    font-size: 15px;
    .delay-popup-footer-l {
      width: calc(50% - 10px);
      display: inline-block;
      text-align: left;
      padding-left: 10px;
      svg {
        position: relative;
        top: 1px;
      }
      span {
        padding-left: 8px;
        display: inline-block;
      }
      .span1 {
        color: #4c8afc;
      }
    }
    .delay-popup-footer-r {
      width: 50%;
      display: inline-block;
      text-align: right;
    }
  }
  .delay-class {
    height: 40px;
    line-height: 40px;
    font-size: 15px;
    &.delay-class1 {
      font-size: 12px;
    }
    span {
      font-size: 15px;
      color: #262626;
    }
    .delay-desc {
      color: #bbb;
      font-size: 12px;
      text-align: right;
      float: right;
    }
    .delay-desc1 {
      text-align: right;
      float: right;
    }
  }
}
.popup-layer {
  text-align: center;
}
.invitation-main {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 400px;
  margin-left: -200px;
  background: #fff;
  border-radius: 5px;
  height: 610px;
  margin-top: -300px;
}
.invitation-popup-title {
  width: 100%;
  text-align: left;
  font-size: 12px;
  font-weight: 400;
  color: #999999;
  padding-left: 10px;
  &.lastChild {
    margin-top: 10px;
  }
}

.invitation-popup-box {
  // padding: 0 10px;
  // overflow: hidden;
  padding-bottom: 15px;
  position: relative;
  // margin-top: 5px;
  border: 1px solid #fff;
  &.borderNone {
    &:hover {
      border: 1px solid rgba(219, 219, 219, 0.5);
      .invitation-popup-select-main {
        // display: block;
      }
    }
  }
}
.invitation-popup-box-text,
.invitation-popup-select {
  // margin-top: 25px;
  width: calc(100% - 20px);
  height: 32px;
  line-height: 32px;
  padding: 0 10px 10px;
  cursor: pointer;
}
.delay-icon {
  float: left;
  width: 30px;
  text-align: left;
  svg {
    position: relative;
    top: 12px;
  }
}
.invitation-popup-box-text-l {
  width: 40px;
  height: 40px;
  float: left;
  border-bottom: 1px solid rgba(219, 219, 219, 0.5);
  padding: 6px 0 6px;

  img {
    width: 40px;
    height: 40px;
    display: inline-block;
    border-radius: 4px;
  }
  .invitation-popup-header {
    width: 40px;
    height: 40px;
    line-height: 40px;
    background: #4c8afc;
    display: inline-block;
    color: #fff;
    border-radius: 4px;
    font-size: 12px;
  }
}
.invitation-popup-box-text-r {
  &.delay-r1 {
    width: calc(100% - 80px);
  }
  width: calc(100% - 50px);
  height: 100%;
  float: left;
  text-align: left;
  padding: 6px 0 6px 10px;
  font-size: 15px;
  font-weight: 400;
  color: #262626;
  line-height: 40px;
  height: 40px;
  border-bottom: 1px solid rgba(219, 219, 219, 0.5);
  .invitation-desc {
    display: inline-block;
    padding-left: 10px;

    font-size: 12px;
    font-weight: 400;
    color: #999999;
  }
}
.invitation-popup-box-text-center {
  width: calc(100%);
  padding: 10px 0 10px 0px;

  font-size: 16px;
  font-weight: 400;
  color: #4c8afc;
  border-bottom: 1px solid rgba(219, 219, 219, 0.5);
  text-align: left;
}
.invitation-popup-btn {
  font-size: 17px;
  font-weight: bold;
  color: #ffffff;
  text-align: center;
  background: #4c8afc;
  width: 310px;
  height: 54px;
  line-height: 54px;
  border-radius: 6px;
  margin: 40px auto 30px;
  cursor: pointer;
}

.invitation-popup-select-main {
  position: absolute;
  left: -1px;
  top: -1px;
  width: 340px;
  // max-height: 400px;
  background: #fff;
  border: 1px solid rgba(219, 219, 219, 0.5);
  z-index: 9;
  padding-bottom: 15px;
  .selsect-height {
    // max-height: 200px;
    max-height: 260px;
    overflow-y: auto;
    width: 100%;
    margin: 15px 0px 0px;
  }
}
</style>
<style lang="less">
.invitation-popup-beizhu {
  width: calc(100% - 20px);
  margin: 0 auto;
  border-bottom: 1px solid rgba(219, 219, 219, 0.5);
  text-align: left;
  .el-textarea {
    .el-textarea__inner {
      padding-left: 0;
      padding-right: 0;
      border: none;
      resize: none;
    }
  }
}
</style>