<template>
  <div class="addStaff-box-right" @click="popupShowClick($event)">
    <div class="addStaff-tips" v-if="!showText">{{ child.tips }}</div>
    <div class="addStaff-text" v-else>
      <span style="line-height: 20px; display: inline-block">{{
        showText
      }}</span>
    </div>
    <svg-icon icon-class="xiala-icon" class="xiala"></svg-icon>
    <cascadePopup
      v-if="popupShow"
      :offsetTop="popupTop"
      :offsetWidth="popupLeft"
      :popupBottom="popupBottom"
      :ajaxData="ajaxData"
      :item="item"
      @swiperSave="swiperSave"
      @cascadeClose="cascadeClose"
    ></cascadePopup>
  </div>
</template>

<script>
import bus from "@/util/bus";
import cascadePopup from "@/components/cascadePopup";
export default {
  data() {
    return {
      child: {},
      popupShow: false,
      popupTop: null,
      popupLeft: 0,
      popupBottom: null,
      showText: "",
    };
  },
  components: {
    cascadePopup,
  },
  inject: ["ajaxData"],
  mounted() {
    let that = this;
    that.child = that.item;
    if (this.child.value1) {
      that.showText =
        that.child.desc1 + " - " + that.child.desc2 + " - " + that.child.desc3;
    }
    // console.log(this.item);
    bus.$on("hidePopup", function (data) {
      that.popupShow = false;
    });
  },
  methods: {
    cascadeClose() {
      this.popupShow = false;
      this.$forceUpdate();
    },
    swiperSave(item) {
      this.showText = item.desc1 + " - " + item.desc2;
      let area = "";
      let areaName = "";
      if (!item.value3) {
        area = 1;
      } else {
        area = item.value3;
        areaName = item.desc3;
        this.showText += " - " + item.desc3;
      }
      this.popupShow = false;
      this.child.value1 = item.value1;
      this.child.value2 = item.value2;
      this.child.value3 = area;
      this.child.desc1 = item.desc1;
      this.child.desc2 = item.desc2;
      this.child.desc3 = areaName;
      let param = item;
      param.field = this.child.field;
      param.type = this.child.type;
      param.city = this.child.city;
      param.area = this.child.area;
      this.$emit("staffParent", param);
      bus.$emit("hidePopup");
    },
    popupShowClick(e) {
      bus.$emit("hidePopup");

      this.popupShow = true;
      let H1 =
        document.body.clientHeight -
        e.currentTarget.getBoundingClientRect().bottom;
      console.log(H1);
      if (H1 < 360) {
        this.popupBottom = H1 + 10;
        this.direction = "bottom";
      } else {
        this.popupTop = e.currentTarget.getBoundingClientRect().top + 10;
        this.direction = "top";
      }
      this.popupLeft = e.currentTarget.getBoundingClientRect().left - 139;
    },
  },
  props: {
    item: {
      type: Object,
      default() {
        return null;
      },
    },
  },
};
</script>
