<template>
  <div class="popup-layer">
    <div class="popup-layer-bg"></div>
    <div class="transition-page">
      <div class="popup-layer-close" @click="rosterClose">
        <svg-icon icon-class="popup-close"></svg-icon>
      </div>
      <!-- <div class="transition-page" v-if="show"> -->
      <div class="transition-title">{{ monthText }}月 工资表制作</div>
      <div class="transition-title"></div>
      <div class="transition-content">
        <div class="transition-content-top">
          <slot v-if="taxStatus === -2">
            <svg-icon icon-class="shenbaoshibai" class="xlsx"></svg-icon>
            <slot v-if="taxStatus === -2">
              <p class="transition-p1" style="color: #e74b47">
                {{ monthText }}月 工资表算税失败
              </p>
              <p class="transition-p2">请检查工资表信息 重新算税</p>
            </slot>

            <div
              class="guocheng"
              v-if="sbyyList.length > 0"
              style="max-height: 126px"
            >
              <table class="accountTable">
                <thead>
                  <tr>
                    <th><div class="sbyy-td1">姓名</div></th>
                    <th class="sbyy-td">
                      <div class="sbyy-td2">失败原因</div>
                    </th>
                  </tr>
                </thead>
              </table>
              <div
                class="sbyy-list"
                style="max-height: 126px; overflow-y: auto"
              >
                <table class="accountTable">
                  <tbody>
                    <tr v-for="(e, i) in sbyyList" :key="i">
                      <td>
                        <div class="sbyy-td1">{{ e.xm }}</div>
                      </td>
                      <td class="sbyy-td">
                        <div class="sbyy-td2">{{ e.cwxx }}</div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <br />
          </slot>
          <slot v-else-if="salaryStatus === -2">
            <svg-icon icon-class="shenbaoshibai" class="xlsx"></svg-icon>
            <slot>
              <p class="transition-p1" style="color: #e74b47">
                {{ monthText }}月 工资表算税失败
              </p>
              <p class="transition-p2">请检查工资表信息 重新算税</p>
            </slot>

            <div class="guocheng" style="margin-bottom: 20px"></div>

            <br />
          </slot>
          <slot v-else>
            <svg-icon icon-class="xlsx" class="xlsx"></svg-icon>
            <p class="transition-p1">队列处理中...</p>
            <p class="transition-p2">电子税务局算税中 请等待...</p>
            <loadingCss style="margin-top: 10px"></loadingCss>
            <div class="guocheng">
              <p>
                人员信息报送中<slot>...</slot>
                <span class="span1" v-if="flag > 1">已完成</span>
              </p>
              <p>
                人员专项扣除信息登记报送中<slot>...</slot>
                <span class="span1" v-if="flag > 2">已完成</span>
              </p>
              <p>
                正常工资薪金所得报送中<slot>...</slot>
                <span class="span1" v-if="flag > 3">已完成</span>
              </p>
              <p>
                劳务报酬报送中<slot v-if="flag">...</slot>
                <!-- <span class="span2">报送失败</span> -->
                <span class="span1" v-if="flag > 4">已完成</span>
                <span class="span2" v-if="flags.flag4">报送失败</span>
              </p>
              <p>
                队列处理中<slot>...</slot>
                <span class="span1" v-if="flag > 5">已完成</span>
                <span class="span3" v-if="taxStatus === -2 && flag > 4"
                  >算税失败</span
                >
              </p>
              <p v-if="sbyyFlag && flag >= 5">
                失败原因
                <span class="span1" v-if="flag > 5">已完成</span>
                <span class="span3"> {{ sbyyText }}</span>
              </p>
            </div>
          </slot>
          <div
            :style="{ marginTop: salaryStatus === -2 ? '80px' : '' }"
            class="btn-link btn-footer"
            @click="revokeSalaryV2"
            v-if="taxStatus === -2 || salaryStatus === -2"
          >
            返回修改
          </div>
          <br />
          <div
            class="btn-link btn-footer btn-repeat"
            v-if="taxStatus === -2"
            @click="reCalculatTax"
          >
            重新算税
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  queryMonthSalaryListV2,
  findEmployeeSalaryStatusV2,
  revokeSalaryV2,
  reCalculatTax,
  querySalaryTaxCalculatRecord,
} from "@/http/api";
export default {
  components: {},
  data() {
    return {
      // userId: this.$getCookie("userId"),
      // enterpriseId: this.$getCookie("enterpriseId"),
      visit: sessionStorage.getItem("visit"),

      enterpriseId: sessionStorage.getItem("enterpriseId"),
      userId: sessionStorage.getItem("userId"),
      flag: 1,
      show: false,
      flags: {
        flag1: false,
        flag2: false,
        flag3: false,
        flag4: false,
        flag5: false,
      },
      // month: "",
      // batch: "",
      salaryStatus: "",
      taxStatus: "",
      interval: null,
      sbyyList: [],
      sbyyFlag: false,
      leaveFlag: true,
      batch: "",
      monthText: "",
    };
  },
  props: {
    month: {
      type: String,
      default() {
        return "";
      },
    },
  },
  mounted() {
    // this.month = this.$route.query.month;
    // this.batch = this.$route.query.batch;
    // console.log(this.month);
    this.monthText = this.month.split("-")[1];
    this.leaveFlag = true;
    this.queryMonthSalaryListV2();
    this.interval = setInterval(() => {
      this.flag++;
      if (this.salaryStatus !== 2 && this.flag > 5) {
        this.flag = 5;
      }
      if (this.taxStatus < 2 && this.flag > 5) {
        this.flag = 5;
      }
      // console.log(this.salaryStatus + "----" + this.flag);
      // console.log(this.salaryStatus);
      if (this.salaryStatus === 2 && this.flag > 6) {
        clearInterval(this.interval);
        this.$router.push({
          path: "/generatePayroll",
          query: {
            month: this.month,
            batch: this.batch,
          },
        });
      }
    }, 1000);
    setTimeout(() => {
      this.show = true;
    }, 1000);
  },
  destroyed() {
    this.leaveFlag = false;
    this.$forceUpdate();
  },
  methods: {
    rosterClose() {
      // this.$router.push("/payrollRecord");
      this.$emit("hideLayer");
    },
    //员工工资-撤销工资v2
    revokeSalaryV2() {
      let param = {
        enterpriseId: this.enterpriseId,
        month: this.month,
        userId: this.userId,
      };
      revokeSalaryV2(param).then((res) => {
        if (res.code === 0) {
          // this.gotoFun("/payrollModule");
          this.$emit("hideLayer");
        } else {
          if (res.msg.substring(6) === "请先撤销审批") {
            this.$store.commit("MyMessage", "请先到APP端撤销审批");
          } else {
            this.$store.commit("MyMessage", res.msg);
          }
        }
      });
    },
    //员工工资-重新算税
    reCalculatTax() {
      // return;
      let param = {
        enterpriseId: this.enterpriseId,
        taxMonth: this.month,
        userId: this.userId,
      };
      reCalculatTax(param).then((res) => {
        if (res.code === 0) {
          this.sbyyList = [];
          this.flag = 1;
          this.queryMonthSalaryListV2();
        }
      });
    },
    //员工工资-查询算税失败原因
    querySalaryTaxCalculatRecord() {
      let param = {
        enterpriseId: this.enterpriseId,
        taxMonth: this.month.split("-")[0] + this.month.split("-")[1],
        userId: this.userId,
      };
      querySalaryTaxCalculatRecord(param).then((res) => {
        if (res.code === 0) {
          this.sbyyList = [];
          let array1 = [];
          // xm;//姓名String(64)
          // zzlx;//证件类型名称String(64)
          // zzhm;//证件号码String(64)
          // sbzt;//代报状态String(64)1待报送2代报中3代报失败4代报成功
          // rzzt;//人员认证状态 String(64)
          // sbyy;//失败原因String(64)
          // failReason;//失败原因Liist    String(64)
          // clzt;//专项代报状态String(64)
          // cljgms;//专项代报结果原因String(64)
          console.log(res.data);
          res.data.forEach((e) => {
            // if (taxMonth)
            console.log(e.failReason);
            if (e.failReason) {
              // console.log(e.failReason);
              // if (e.failReason.msg) {
              //   // console.log(e.msg);
              //   array1.push({
              //     xm: "",
              //     cwxx: e.failReason.msg,
              //   });
              // }
              console.log();
              if (
                e.failReason.msg &&
                !e.failReason.reasonsOne.length &&
                !e.failReason.reasonsTwo.length &&
                !e.failReason.reasonsOne.reasonsThree
              ) {
                array1.push({
                  xm: "",
                  cwxx: e.failReason.msg,
                });
              }
              array1 = [
                ...array1,
                ...e.failReason.reasonsOne,
                ...e.failReason.reasonsTwo,
                ...e.failReason.reasonsThree,
              ];
            }
            // this.sbyyList = [...this.sbyyList, ...array1];
            // array1 = [];
          });
          console.log(array1);
          this.sbyyList = array1;
        }
      });
    },
    //页面跳转
    gotoFun(link) {
      this.$router.push(link);
    },
    // 暂时用不到
    findEmployeeSalaryStatusV2() {
      let param = {
        enterpriseId: this.enterpriseId,
        month: this.month,
        batch: this.batch,
        pageNum: 1,
        pageSize: 999,
      };
      findEmployeeSalaryStatusV2().then((res) => {
        console.log(res);
      });
    },
    queryMonthSalaryListV2() {
      let param = {
        enterpriseId: this.enterpriseId,
        userId: this.userId,
      };
      queryMonthSalaryListV2(param).then((res) => {
        if (res.code === 0) {
          let date = this.month.split("-")[0] + this.month.split("-")[1];
          if (res.data.length) {
            res.data.forEach((e) => {
              if (e.month + "" === date) {
                this.batch = e.batch;
                this.taxStatus = e.taxStatus;
                this.salaryStatus = e.salaryStatus;
                if (this.salaryStatus === -2) {
                  this.querySalaryTaxCalculatRecord();
                  return false;
                }
                if (e.taxStatus === 2) return;
                if (e.baoshuiList.status === 30 && e.taxStatus === -2) {
                  this.taxStatus = 1;
                } else {
                  this.taxStatus = e.taxStatus;
                }
                if (this.taxStatus < 2) {
                  if (this.taxStatus === -2) {
                    this.querySalaryTaxCalculatRecord();
                    return;
                  } else {
                    setTimeout(() => {
                      if (this.leaveFlag) this.queryMonthSalaryListV2();
                    }, 5000);
                  }
                } else if (e.salaryStatus !== 2) {
                  setTimeout(() => {
                    if (this.leaveFlag) this.queryMonthSalaryListV2();
                  }, 5000);
                }
              }
            });
          } else {
            setTimeout(() => {
              this.queryMonthSalaryListV2();
            }, 5000);
          }
        }
      });
    },
    goFun(str) {
      this.$router.push(str);
    },
  },
};
</script>

<style lang="less" scoped>
.account-content {
  overflow-y: auto;
}
.btn-link {
  padding: 0 20px;
  display: inline-block;
  height: 34px;
  line-height: 34px;
  cursor: pointer;
  background: #f4f4f4;
  border: 1px solid #f4f4f4;
  font-size: 16px;
  font-weight: 400;
  color: #262626;
  margin-right: 10px;
  border-radius: 3px;
}
.xlsx {
  font-size: 36px;
  padding: 30px 0 10px;
}
.transition-page {
  margin: 0 auto;
  text-align: center;
  width: 460px;
  max-height: 560px;
  min-height: 380px;
  // padding-bottom: 40px;
  background: #ffffff;
  opacity: 1;
  border-radius: 6px;
  z-index: 999;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -284px;
  margin-top: -305px;
  .transition-title {
    font-size: 20px;
    padding: 28px 0 0px;
    text-align: center;
    color: #262626;
    font-weight: bold;
  }
  &::-webkit-scrollbar {
    width: 0;
  }
  .transition-content {
    // padding-bottom: 60px;
    min-height: calc(100% - 180px);
    padding-bottom: 30px;
    .transition-p1 {
      color: #262626;
      font-size: 20px;
      padding-bottom: 5px;
    }
    .transition-p2 {
      color: #bbb;
      font-size: 12px;
    }
    .guocheng {
      width: 360px;
      margin: 30px auto;
      text-align: left;
      p {
        position: relative;
        font-size: 16px;
        margin-bottom: 10px;
        color: #999;
        i {
          position: absolute;
          left: -20px;
          top: 1px;
          font-size: 16px;
        }
        .span1 {
          position: absolute;
          right: 0;
          top: 0px;
          font-size: 16px;
          color: #33c481;
        }
        .span2 {
          position: absolute;
          right: 0;
          top: 0px;
          font-size: 16px;
          // color: #e75850;
          color: #bbb;
        }
        .span3 {
          position: absolute;
          right: 0;
          top: 0px;
          font-size: 16px;
          // color: #e75850;
          color: #e74b47;
        }
      }
    }
  }
}
.btn-footer {
  width: 360px;
  margin: 20px auto 0;
  height: 54px;
  line-height: 54px;
  border-radius: 6px;
  font-size: 20px;
  padding: 0;
  &.btn-repeat {
    background: #ffffff;
    border: 1px solid #4c8afc;
    color: #4c8afc;
    // font-weight: bold;
  }
}
.sbyy-list {
  width: 100%;
  max-height: 300px;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 0;
  }
  .accountTable {
    tbody {
      tr {
        &:first-child {
          td {
            border-top: none;
          }
        }
      }
    }
  }
}
.accountTable {
  tr .sbyy-td {
    text-align: left;
  }
  th {
    color: #999;
  }
  td {
    height: 40px;
  }
  .sbyy-td1 {
    width: 100px;
  }
  .sbyy-td2 {
    width: 247px;
    padding-left: 10px;
  }
}
</style>