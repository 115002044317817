<template>
  <div class="popup-layer">
    <div class="popup-layer-bg" @click="hidePopup"></div>
    <div class="popup-layer-content">
      <div class="popup-layer-close" @click="hidePopup">
        <svg-icon icon-class="popup-close"></svg-icon>
      </div>
      <div class="addStaffPopup-header">员工信息</div>
      <div class="addStaffPopup-body">
        <div class="addStaffPopup-box">
          <div class="addStaffPopup-box-l">姓名</div>
          <div class="addStaffPopup-box-r cityDesc">
            {{ enterpriseEmployee.name }}
          </div>
        </div>
        <div class="addStaffPopup-box">
          <div class="addStaffPopup-box-l">手机号</div>
          <div class="addStaffPopup-box-r cityDesc">
            {{ enterpriseEmployee.mobile }}
          </div>
        </div>
        <div class="addStaffPopup-box">
          <div class="addStaffPopup-box-l">岗位名称</div>
          <div class="addStaffPopup-box-r cityDesc">
            {{ enterpriseEmployee.postName }}
          </div>
        </div>
        <div class="addStaffPopup-box">
          <div class="addStaffPopup-box-l">所属部门</div>
          <div class="addStaffPopup-box-r cityDesc">
            {{ enterpriseEmployee.departmentName }}
          </div>
        </div>
        <div class="addStaffPopup-box">
          <div class="addStaffPopup-box-l">现居住地</div>
          <div class="addStaffPopup-box-r cityDesc">
            {{ showText }}
          </div>
        </div>
        <div class="addStaffPopup-box">
          <div class="addStaffPopup-box-l">现居住详细地址</div>
          <div class="addStaffPopup-box-r cityDesc">
            {{ enterpriseEmployee.contactDetail }}
          </div>
        </div>
        <div class="addStaffPopup-box">
          <div class="addStaffPopup-box-l">员工状态</div>
          <div class="addStaffPopup-box-r cityDesc" style="color: #e75850">
            {{ statusText }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getEmployeeInfo } from "@/http/api";
export default {
  data() {
    return {
      userId: sessionStorage.getItem("userId"),
      enterpriseId: sessionStorage.getItem("enterpriseId"),
      item: {
        value1: "",
        value2: "",
        value3: "",
        desc1: "",
        desc2: "",
        desc3: "",
      },
      showText: "所在地区",
      enterpriseEmployee: {},
      statusText: "",
    };
  },
  props: {
    details: {
      type: Object,
      default() {
        return {};
      },
    },
  },

  mounted() {
    this.getEmployeeInfo();
  },
  methods: {
    // 获取员工信息
    getEmployeeInfo() {
      let param = {
        enterpriseId: this.enterpriseId,
        userId: this.details.userId,
        employeeId: this.details.employeeId,
      };
      getEmployeeInfo(param).then((res) => {
        console.log(res);
        if (res.code === 0) {
          this.enterpriseEmployee = res.data;
          this.status = res.data.status;
          res.data.mobile = res.data.mobile
            .replace(/\D/g, "")
            .replace(/\s/g, "")
            .replace(/(\d{3})(\d{0,4})(\d{0,4})/, "$1 $2 $3");
          // this.isOntermFun();
          if (this.enterpriseEmployee.nationality + "" === "") {
            this.enterpriseEmployee.nationality = "1";
          }
          if (!this.enterpriseEmployee.jobTime) {
            this.staffType = "add";
            this.enterpriseEmployee.jobTime = "";
            // this.enterpriseEmployee.onTrialTermSalaryScale = "";
          }
          if (this.enterpriseEmployee.isAdmin === 1) {
            this.staffTitle = "完善管理员信息";
          }
          if (!this.enterpriseEmployee.identity) {
            this.enterpriseEmployee.identity = 1;
          }
          if (!this.enterpriseEmployee.contractType) {
            this.enterpriseEmployee.contractType = 3;
          }
          if (this.enterpriseEmployee.status === 1) {
            this.statusText = "正常";
          } else if (this.enterpriseEmployee.status === 2) {
            // this.statusText = "离职";
            this.statusText =
              "离职日期: " +
              this.enterpriseEmployee.dimissionTime.split(" ")[0];
          } else if (this.enterpriseEmployee.status === 10) {
            this.statusText = "待入职";
          } else if (this.enterpriseEmployee.status === 99) {
            this.statusText = "审核不通过";
          }
          this.item.desc1 = this.enterpriseEmployee.contactProvince;
          this.item.desc2 = this.enterpriseEmployee.contactCity;
          this.item.desc3 = this.enterpriseEmployee.contactArea;
          this.item.value1 = this.enterpriseEmployee.contactProvinceId;
          this.item.value2 = this.enterpriseEmployee.contactCityId;
          this.item.value3 = this.enterpriseEmployee.contactAreaId;
          if (this.item.value1) {
            this.showText =
              this.item.desc1 +
              " - " +
              this.item.desc2 +
              " - " +
              this.item.desc3;
          }
        }
      });
    },
    hidePopup() {
      this.$emit("hidePopup");
    },
  },
};
</script>