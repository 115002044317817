<template>
  <div class="popup-layer">
    <div class="popup-layer-bg"></div>
    <div class="transition-page">
      <div class="popup-layer-close" @click="rosterClose">
        <svg-icon icon-class="popup-close"></svg-icon>
      </div>
      <div class="transition-title">税款缴纳更正</div>
      <div class="transition-content">
        <div class="transition-content-top">
          <svg-icon icon-class="xlsx" class="xlsx"></svg-icon>
          <p class="transition-p1">{{ monthText }}月 工资表队列处理中...</p>
          <p class="transition-p2">队列处理中，请稍等<slot>...</slot></p>

          <loadingCss style="margin-top: 50px"></loadingCss>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { queryMonthSalaryListV2, queryShenbaoStatusV2 } from "@/http/api";
export default {
  data() {
    return {
      // userId: this.$getCookie("userId"),
      // enterpriseId: this.$getCookie("enterpriseId"),

      enterpriseId: sessionStorage.getItem("enterpriseId"),
      userId: sessionStorage.getItem("userId"),
      flag: 1,
      flags: {
        flag1: false,
        flag2: false,
        flag3: false,
        flag4: false,
        flag5: false,
      },
      month: "",
      batch: "",
      leaveFlag: true,
      monthText: "",
    };
  },
  destroyed() {
    this.leaveFlag = false;
    this.$forceUpdate();
  },
  mounted() {
    this.month = this.$route.query.month;
    this.monthText = this.month.split("-")[1];
    this.batch = this.$route.query.batch;
    this.leaveFlag = true;
    this.queryMonthSalaryListV2();
  },
  methods: {
    rosterClose() {
      this.$router.push("/payrollRecord");
    },
    queryMonthSalaryListV2() {
      let param = {
        enterpriseId: this.enterpriseId,
        userId: this.userId,
      };
      queryMonthSalaryListV2(param).then((res) => {
        if (res.code === 0) {
          // console.log(res.data);

          let date = this.month.split("-")[0] + this.month.split("-")[1];
          res.data.forEach((e) => {
            if (e.month + "" === date) {
              e.baoshuiList.forEach((e1) => {
                if (e1.month + "" === date) {
                  this.status = e1.status;
                  let correctStatus = e1.correctStatus;
                  if (correctStatus === 200) {
                    // 未更正
                    //   setTimeout(() => {
                    //     this.queryMonthSalaryListV2();
                    //   }, 5000);
                  } else if (correctStatus === 201) {
                    // 更正中
                    setTimeout(() => {
                      if (this.leaveFlag) this.queryMonthSalaryListV2();
                    }, 5000);
                  } else if (correctStatus === 202) {
                    this.$emit("tFun");
                  } else {
                    // 更正失败
                    // !显示失败原因
                  }
                }
              });
            }
          });
        }
      });
    },
    goFun(str) {
      this.$emit("tFun");
      return;
      if (str === "/payrollRecord") {
        this.$router.push(str);
      } else {
        this.$router.push({
          path: str,
          query: {
            month: this.month,
            batch: this.batch,
          },
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.loading-icon {
  width: 24px;
  height: 24px;
  margin-top: 30px;
}
.xlsx {
  font-size: 36px;
  padding: 100px 0 10px;
}
.transition-page {
  margin: 0 auto;
  text-align: center;
  width: 460px;
  height: 450px;
  background: #ffffff;
  opacity: 1;
  border-radius: 6px;
  z-index: 999;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -230px;
  margin-top: -305px;
  .transition-title {
    font-size: 20px;
    padding: 28px 0 0px;
    text-align: center;
    color: #262626;
    font-weight: bold;
  }
  .transition-content {
    .transition-p1 {
      color: #262626;
      font-size: 20px;
      padding-bottom: 5px;
      font-weight: bold;
      padding-top: 10px;
    }
    .transition-p2 {
      color: #bbb;
      font-size: 12px;
      letter-spacing: 1px;
      padding-top: 2px;
    }
    .guocheng {
      width: 360px;
      margin: 60px auto;
      text-align: left;
      p {
        position: relative;
        font-size: 16px;
        margin-bottom: 10px;
        color: #999;
        i {
          position: absolute;
          left: -20px;
          top: 1px;
          font-size: 16px;
        }
        .span1 {
          position: absolute;
          right: 0;
          top: 0px;
          font-size: 16px;
          color: #33c481;
        }
        .span2 {
          position: absolute;
          right: 0;
          top: 0px;
          font-size: 16px;
          // color: #e75850;
          color: #bbb;
        }
      }
    }
  }
}
</style>