<template>
  <div class="popup-layer">
    <div class="popup-layer-bg" @click="approvalClose"></div>
    <div class="invitation-main payment-height">
      <div class="popup-layer-close" @click="approvalClose">
        <svg-icon icon-class="popup-close"></svg-icon>
      </div>
      <div
        style="
          width: calc(100% - 60px);
          max-height: 400px;
          padding: 42px 30px 0;
        "
      >
        <div class="invitation-popup-title">选择审批人</div>
        <div class="invitation-popup-box" :class="{ borderNone: show1 }">
          <div class="invitation-popup-box-text" @click="shenpiClick">
            <div
              class="invitation-popup-box-text-center"
              v-if="item1.name === ''"
            >
              请选择
            </div>
            <div class="invitation-popup-box-text-l" v-if="item1.name !== ''">
              <img v-if="item1.userHead" :src="item1.userHead" alt="" />

              <span class="invitation-popup-header" v-else>
                {{ item1.nameText }}
              </span>
              <!-- <img src="" alt="" /> -->
            </div>
            <div class="invitation-popup-box-text-r" v-if="item1.name !== ''">
              {{ item1.nameText1
              }}<span class="invitation-desc">{{ item1.postName }}</span>
            </div>
          </div>
          <!-- @mouseleave="show1 = false" -->
          <div class="invitation-popup-select-main" v-if="show1">
            <div class="invitation-popup-select">
              <div class="invitation-popup-select-text">
                <div
                  class="invitation-popup-box-text-center"
                  v-if="item1.name === ''"
                >
                  请选择
                </div>
                <div
                  class="invitation-popup-box-text-l"
                  v-if="item1.name !== ''"
                >
                  <img v-if="item1.userHead" :src="item1.userHead" alt="" />
                  <span class="invitation-popup-header" v-else>
                    {{ item1.nameText }}
                  </span>
                </div>
                <div
                  class="invitation-popup-box-text-r"
                  v-if="item1.name !== ''"
                >
                  {{ item1.nameText1
                  }}<span class="invitation-desc">{{ item1.postName }}</span>
                </div>
              </div>
            </div>
            <div class="selsect-height webkit-scrollbar">
              <slot v-for="(item, index) in approvalList">
                <div
                  class="invitation-popup-select"
                  :key="index"
                  @click="itemClick1(item)"
                >
                  <div class="invitation-popup-select-text">
                    <div class="invitation-popup-box-text-l">
                      <img v-if="item.userHead" :src="item.userHead" alt="" />
                      <span class="invitation-popup-header" v-else>
                        {{ item.nameText }}
                      </span>
                    </div>
                    <div class="invitation-popup-box-text-r">
                      {{ item.nameText1
                      }}<span class="invitation-desc">{{ item.postName }}</span>
                    </div>
                  </div>
                </div>
                <div class="clear" :key="'li1-' + index"></div>
              </slot>
            </div>
          </div>
        </div>
        <div class="invitation-popup-title lastChild">选择付款人</div>
        <div class="invitation-popup-box" :class="{ borderNone: show2 }">
          <div
            class="invitation-popup-box-text"
            @click="
              show2 = true;
              show1 = false;
            "
          >
            <div
              class="invitation-popup-box-text-center"
              v-if="item2.name === ''"
            >
              请选择
            </div>
            <div class="invitation-popup-box-text-l" v-if="item2.name !== ''">
              <img v-if="item2.userHead" :src="item2.userHead" alt="" />
              <span class="invitation-popup-header" v-else>
                {{ item2.nameText }}
              </span>
            </div>
            <div class="invitation-popup-box-text-r" v-if="item2.name !== ''">
              {{ item2.nameText1
              }}<span class="invitation-desc">{{ item2.postName }}</span>
            </div>
          </div>
          <div
            class="invitation-popup-select-main"
            v-if="show2"
            @mouseleave="show2 = false"
          >
            <div class="invitation-popup-select">
              <div class="invitation-popup-select-text">
                <div
                  class="invitation-popup-box-text-center"
                  v-if="item2.name === ''"
                >
                  请选择
                </div>
                <div
                  class="invitation-popup-box-text-l"
                  v-if="item2.name !== ''"
                >
                  <img v-if="item2.userHead" :src="item2.userHead" alt="" />
                  <span class="invitation-popup-header" v-else>
                    {{ item2.nameText }}
                  </span>
                </div>
                <div
                  class="invitation-popup-box-text-r"
                  v-if="item2.name !== ''"
                >
                  {{ item2.nameText1
                  }}<span class="invitation-desc">{{ item2.postName }}</span>
                </div>
              </div>
            </div>
            <div class="selsect-height webkit-scrollbar">
              <slot v-for="(item, index) in FukuanList">
                <div
                  class="invitation-popup-select"
                  :key="index"
                  @click="itemClick2(item)"
                >
                  <div class="invitation-popup-select-text">
                    <div class="invitation-popup-box-text-l">
                      <img v-if="item.userHead" :src="item.userHead" alt="" />
                      <span class="invitation-popup-header" v-else>
                        {{ item.name }}
                      </span>
                    </div>
                    <div class="invitation-popup-box-text-r">
                      {{ item.nameText1
                      }}<span class="invitation-desc">{{ item.postName }}</span>
                    </div>
                  </div>
                </div>
                <div class="clear" :key="'li2-' + index"></div>
              </slot>
            </div>
          </div>
        </div>
        <div class="invitation-popup-title lastChild">备注</div>

        <div class="invitation-popup-beizhu">
          <el-input
            type="textarea"
            placeholder="请输入内容"
            v-model="remark"
            maxlength="150"
            rows="5"
            show-word-limit
          >
          </el-input>
        </div>

        <div class="invitation-popup-btn" @click="selectApproval()">
          提交审批
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getEmployeeFukuan,
  getEnterpriseEmployeeCheckers,
  submmitSalaryCheck,
  submmitSalaryCheckV2,
} from "@/http/api";
export default {
  data() {
    return {
      userId: sessionStorage.getItem("userId"),
      enterpriseId: sessionStorage.getItem("enterpriseId"),
      FukuanList: [],
      approvalList: [],
      selectShow1: false,
      item1: { name: "" },
      item2: { name: "" },
      show1: false,
      show2: false,
      approvaName: "",
      remark: "",
      fukuanName: "",
    };
  },
  mounted() {
    this.getEmployeeFukuan();
    this.getEnterpriseEmployeeCheckers();
  },
  props: {
    mouth: {
      type: String,
      default() {
        return null;
      },
    },
    batch: {
      type: String,
      default() {
        return null;
      },
    },
  },
  methods: {
    shenpiClick() {
      this.show1 = true;
      this.show2 = false;
    },
    itemClick1(item) {
      this.item1 = item;
      this.show1 = false;
    },
    itemClick2(item) {
      this.show2 = false;
      this.item2 = item;
    },
    selectApproval() {
      this.$store.commit("showLoading", "");
      this.submmitSalaryCheckV2();
      return;
      if (!this.item1.userId) {
        this.$store.commit("MyMessage", "请选择审批人");
        return;
      }
      if (!this.item2.userId) {
        this.$store.commit("MyMessage", "请选择付款人");
        return;
      }
      let param = {
        enterpriseId: this.enterpriseId,
        userId: this.userId,
        month: this.mouth,
        checkId: this.item1.userId,
        batch: this.batch,
        fukuanrenId: this.item2.userId,
      };
      submmitSalaryCheck(param).then((res) => {
        if (res.code === 0) {
          this.status = 2;
          this.$store.commit("MyMessage", "提交成功");
          this.$delCookie("approvalShow"); //删除权限信息
          this.$emit("approvalClose");
          location.reload();
        } else {
          this.$store.commit("MyMessage", res.msg);
        }
      });
    },
    submmitSalaryCheckV2() {
      if (!this.item1.userId) {
        this.$store.commit("MyMessage", "请选择审批人");
        return;
      }
      if (!this.item2.userId) {
        this.$store.commit("MyMessage", "请选择付款人");
        return;
      }
      let param = {
        enterpriseId: this.enterpriseId,
        userId: this.userId,
        month: this.mouth,
        checkId: this.item1.userId,
        batch: this.batch,
        fukuanrenId: this.item2.userId,
        remark: this.remark,
      };
      submmitSalaryCheckV2(param).then((res) => {
        this.$store.commit("hideLoading", "");
        if (res.code === 0) {
          this.status = 2;
          this.$store.commit("MyMessage", "提交成功");
          this.$delCookie("approvalShow"); //删除权限信息
          this.$emit("approvalClose");
          location.reload();
        } else {
          this.$store.commit("MyMessage", res.msg);
        }
      });
    },
    approvalClose() {
      this.$delCookie("approvalShow"); //删除弹框显示
      this.$emit("approvalClose");
    },
    getEmployeeFukuan() {
      let param = {
        enterpriseId: this.enterpriseId,
        userId: this.userId,
      };
      getEmployeeFukuan(param).then((res) => {
        console.log("getEmployeeFukuan", res.data);
        this.FukuanList = res.data;
        this.FukuanList.forEach((element) => {
          const regMobile = /^1(3|4|5|6|7|8|9)\d{9}$/;
          if (element.userHead.indexOf("http://jz.jzdcs.com") !== -1) {
            element.userHead =
              "https://www.jzcfo.com" +
              element.userHead.split("http://jz.jzdcs.com")[1];
          }
          if (regMobile.test(element.name)) {
            element.nameText =
              "*" + element.name.substr(element.name.length - 2, 2);
            element.nameText1 =
              "*" + element.name.substr(element.name.length - 2, 2);
          } else {
            element.nameText = element.name.substr(element.name.length - 2, 2);
            element.nameText1 = element.name;
          }
        });
      });
    },
    getEnterpriseEmployeeCheckers() {
      let that = this;
      let param = {
        enterpriseId: this.enterpriseId,
        userId: this.userId,
        pageNum: "1",
        pageSize: "9999",
      };
      getEnterpriseEmployeeCheckers(param).then((res) => {
        // console.log(res);
        if (res.code === 0) {
          this.approvalList = res.data.list;

          let fristList = null;
          let fristIndex = null;

          this.approvalList.forEach((element, index) => {
            if (element.userHead.indexOf("http://jz.jzdcs.com") !== -1) {
              element.userHead =
                "https://www.jzcfo.com" +
                element.userHead.split("http://jz.jzdcs.com")[1];
            }
            if (that.userId === element.userId) {
              fristList = element;
              fristIndex = index;
            }
            const regMobile = /^1(3|4|5|6|7|8|9)\d{9}$/;
            if (regMobile.test(element.name)) {
              element.nameText =
                "*" + element.name.substr(element.name.length - 2, 2);
              element.nameText1 =
                "*" + element.name.substr(element.name.length - 2, 2);
            } else {
              element.nameText = element.name.substr(
                element.name.length - 2,
                2
              );
              element.nameText1 = element.name;
            }
            element.idType = element.idType ? element.idType : "1";
          });
          if (this.approvalList.length > 1) {
            // console.log(fristList);
            if (fristIndex) {
              this.approvalList.splice(fristIndex, 1);
              this.approvalList.unshift(fristList);
            }
          }
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.popup-layer {
  text-align: center;
}
.invitation-main {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 400px;
  margin-left: -200px;
  background: #fff;
  border-radius: 5px;
  height: 520px;
  margin-top: -300px;
}
.invitation-popup-title {
  width: 100%;
  text-align: left;
  font-size: 12px;
  font-weight: 400;
  color: #999999;
  padding-left: 10px;
  &.lastChild {
    margin-top: 30px;
  }
}

.invitation-popup-box {
  // padding: 0 10px;
  // overflow: hidden;
  padding-bottom: 15px;
  position: relative;
  margin-top: 5px;
  border: 1px solid #fff;
  &.borderNone {
    &:hover {
      border: 1px solid rgba(219, 219, 219, 0.5);
      .invitation-popup-select-main {
        // display: block;
      }
    }
  }
}
.invitation-popup-box-text,
.invitation-popup-select {
  // margin-top: 25px;
  width: calc(100% - 20px);
  height: 32px;
  line-height: 32px;
  padding: 0 10px 10px;
  cursor: pointer;
}
.invitation-popup-box-text-l {
  width: 40px;
  height: 40px;
  float: left;
  border-bottom: 1px solid rgba(219, 219, 219, 0.5);
  padding: 6px 0 6px;
  img {
    width: 40px;
    height: 40px;
    display: inline-block;
    border-radius: 4px;
  }
  .invitation-popup-header {
    width: 40px;
    height: 40px;
    line-height: 40px;
    background: #4c8afc;
    display: inline-block;
    color: #fff;
    border-radius: 4px;
    font-size: 12px;
  }
}
.invitation-popup-box-text-r {
  width: calc(100% - 50px);
  height: 100%;
  float: left;
  text-align: left;
  padding: 6px 0 6px 10px;
  font-size: 15px;
  font-weight: 400;
  color: #262626;
  line-height: 40px;
  height: 40px;
  border-bottom: 1px solid rgba(219, 219, 219, 0.5);
  .invitation-desc {
    display: inline-block;
    padding-left: 10px;

    font-size: 12px;
    font-weight: 400;
    color: #999999;
  }
}
.invitation-popup-box-text-center {
  width: calc(100%);
  padding: 10px 0 10px 0px;

  font-size: 16px;
  font-weight: 400;
  color: #4c8afc;
  border-bottom: 1px solid rgba(219, 219, 219, 0.5);
  text-align: left;
}
.invitation-popup-btn {
  font-size: 17px;
  font-weight: bold;
  color: #ffffff;
  text-align: center;
  background: #4c8afc;
  width: 310px;
  height: 54px;
  line-height: 54px;
  border-radius: 6px;
  margin: 40px auto 30px;
  cursor: pointer;
}

.invitation-popup-select-main {
  position: absolute;
  left: -1px;
  top: -1px;
  width: 340px;
  // max-height: 400px;
  background: #fff;
  border: 1px solid rgba(219, 219, 219, 0.5);
  z-index: 9;
  padding-bottom: 15px;
  .selsect-height {
    // max-height: 200px;
    max-height: 260px;
    overflow-y: auto;
    width: 100%;
    margin: 15px 0px 0px;
  }
}
</style>
<style lang="less">
.invitation-popup-beizhu {
  width: calc(100% - 20px);
  margin: 0 auto;
  border-bottom: 1px solid rgba(219, 219, 219, 0.5);
  text-align: left;
  .el-textarea {
    .el-textarea__inner {
      padding-left: 0;
      padding-right: 0;
      border: none;
      resize: none;
    }
  }
}
</style>