var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"addStaff-box",class:{
    redLine: _vm.redLine,
    noClickMain: _vm.staffType === 'quitStaff',
    marginTop: _vm.item.field === 'status',
    jinjiLianxidianhua: _vm.item.field === 'jinjiLianxidianhua',
  }},[(_vm.staffType === 'quitStaff')?_c('div',{staticClass:"noClick"}):_vm._e(),_c('div',{staticClass:"addStaff-box-left",class:{
      addStaffWidth1:
        _vm.item.field === 'bankProvinceId' ||
        _vm.item.field === 'isDeductionCost' ||
        _vm.item.field === 'ruzhiqingxing',
      addStaffWidth2: _vm.item.field === 'identity',
      addStaffWidth3: _vm.item.field === 'onTrialTermBegin',
      descStyle: _vm.item.desc,
      descStyle1: _vm.item.field === 'contactDetail',
    }},[_c('p',{staticClass:"desc-p1"},[_vm._v(_vm._s(_vm.item.text))]),(_vm.item.desc)?_c('p',{staticClass:"desc-p2"},[_vm._v(_vm._s(_vm.item.desc))]):_vm._e()]),(_vm.item.type === 'input' || _vm.item.type === 'textarea')?_vm._t("default",function(){return [_c('inputBox',{attrs:{"item":_vm.item,"staffType":_vm.staffType},on:{"staffParent":_vm.staffParent}})]}):(_vm.item.type === 'select')?_vm._t("default",function(){return [_c('selectBox',{attrs:{"item":_vm.item,"staffType":_vm.staffType},on:{"staffParent":_vm.staffParent}})]}):(_vm.item.type === 'selects')?_vm._t("default",function(){return [_c('selectsBox',{attrs:{"item":_vm.item},on:{"staffParent":_vm.staffParent}})]}):(_vm.item.type === 'city')?_vm._t("default",function(){return [_c('cityBox',{attrs:{"item":_vm.item},on:{"staffParent":_vm.staffParent}})]}):(_vm.item.type === 'date')?_vm._t("default",function(){return [_c('dateBox',{attrs:{"item":_vm.item,"staffType":_vm.staffType},on:{"staffParent":_vm.staffParent}})]}):(_vm.item.type === 'dates')?_vm._t("default",function(){return [_c('datesBox',{attrs:{"item":_vm.item},on:{"staffParent":_vm.staffParent}})]}):(_vm.item.type === 'image')?_vm._t("default",function(){return [_c('imgBox',{attrs:{"item":_vm.item},on:{"staffParent":_vm.staffParent}})]}):(_vm.item.type === 'images')?_vm._t("default",function(){return [_c('imgsBox',{attrs:{"item":_vm.item},on:{"staffParent":_vm.staffParent}})]}):(_vm.item.type === 'status')?_vm._t("default",function(){return [_c('statusBox',{attrs:{"item":_vm.item},on:{"staffParent":_vm.staffParent}})]}):(_vm.item.type === 'subsidy')?_vm._t("default",function(){return [_c('subsidyBox',{attrs:{"item":_vm.item,"staffType":_vm.staffType},on:{"staffParent":_vm.staffParent}})]}):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }