<template>
  <div class="popup-layer">
    <div class="popup-layer-bg" @click="approvalClose"></div>
    <div class="invitation-main">
      <svg-icon
        icon-class="close-svg"
        @click="approvalClose"
        class="close-svg"
      ></svg-icon>
      <div class="banklist-main">
        <p class="generate-p4">选择个税付款方式</p>
        <!-- <p class="generate-p5">{{ company }}</p> -->
        <p class="generate-p5" style="position: relative">
          <span
            v-if="!showFlag"
            style="
              position: absolute;
              top: 0;
              left: 0;
              display: inline-block;
              width: 100%;
              height: 100%;
              background: rgb(255 255 255 / 0.4);
              z-index: 1;
            "
          ></span>
          <el-select
            v-model="sfxyh"
            class="selectStyle noSelect"
            @change="selectChange()"
            placeholder="获取中"
          >
            <el-option
              v-for="item in selectList"
              :key="item.sfxyh"
              :label="item.yhhbmc"
              :value="item.sfxyh"
            >
            </el-option>
          </el-select>
          <slot v-if="!showFlag">
            <i
              class="el-icon-loading"
              style="position: absolute; left: 76px; top: 13px; font-size: 14px"
            ></i>
          </slot>
          <span
            class="gengxin"
            style="z-index: 12"
            @click="queryAgreementBankInfo"
            >更新</span
          >
        </p>
        <!-- <p class="generate-p1">{{ company }}</p> -->
        <p class="generate-p2">{{ mouth.split("-")[1] * 1 }}月个税总额</p>
        <div class="clear"></div>
        <p class="generate-p3"><span>¥</span>{{ taxTotal1 }}</p>
        <p class="generate-p6">请保证账户余额充足</p>
        <div class="generate-table">
          <table>
            <tr>
              <td class="generate-table-td1">申报状态</td>
              <td class="generate-table-td2">申报成功 待缴款</td>
            </tr>
            <tr>
              <td class="generate-table-td1">申报日期</td>
              <td class="generate-table-td2">
                {{ mouth.split("-")[0] + "年" + mouth.split("-")[1] + "月" }}
              </td>
            </tr>
            <tr>
              <td class="generate-table-td1">申报总金额</td>
              <td class="generate-table-td2">{{ taxTotal1 }}</td>
            </tr>
          </table>
        </div>
      </div>
      <div class="payment-main">
        <!-- <p class="payment-p5">注：一旦申报个税，工资表将无法修改。</p> -->
        <div class="payment-btns" style="margin-top: 35px">
          <button class="generate-btn1" @click="approvalClose">取消</button>
          <button class="generate-btn2" @click="taxPayment">确认完成</button>
          <!-- <button class="generate-btn2" @click="geshuiCheck">确认完成</button> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getEmployeeFukuan,
  getEnterpriseEmployeeCheckers,
  submmitSalaryCheck,
  queryEnterpriseInfoForUser,
  queryBaoshuiamount,
  salaryserviceBaoshui,
  geshuiCheck,
  queryAgreementBankInfo,
  getAgreementBankInfo,
  queryShenbaoStatusV2,
  taxPayment,
  arrearsQuery,
} from "@/http/api";
export default {
  data() {
    return {
      // userId: this.$getCookie("userId"),
      // enterpriseId: this.$getCookie("enterpriseId"),

      enterpriseId: sessionStorage.getItem("enterpriseId"),
      userId: sessionStorage.getItem("userId"),
      payment: this.$dictionaries.payment,
      adminId: "",
      selectList: [],
      value: "",
      // taxTotal: "",
      ybtse: "",
      skssq: "",
      company: "",
      sfxyh: "",
      ajax: true,
      showFlag: false,
      taxTotal1: "",
    };
  },

  props: {
    mouth: {
      type: String,
      default() {
        return null;
      },
    },
    batch: {
      type: String,
      default() {
        return null;
      },
    },
    taxTotal: {
      type: Number,
      default() {
        return null;
      },
    },
    srze: {
      type: Number,
      default() {
        return null;
      },
    },
    tableData: {
      type: Array,
      default() {
        return null;
      },
    },
  },
  mounted() {
    // this.queryEnterpriseInfoForUser();
    // this.queryBaoshuiamount();
    // this.queryAgreementBankInfo();
    // this.queryShenbaoStatusV2();
    this.getAgreementBankInfo();
    this.arrearsQuery();
    // console.log(this.taxTotal);
  },
  methods: {
    approvalClose() {
      this.ajax = false;
      this.$emit("approvalClose");
    },
    queryShenbaoStatusV2() {
      let param = {
        enterpriseId: this.enterpriseId,
        month: this.mouth,
        userId: this.userId,
      };
      queryShenbaoStatusV2(param).then((res) => {
        console.log(res);
        if (res.code === 0) {
          // this.taxTotal = res.data.zhsd[0].srze;
          this.ybtse = res.data.zhsd[0].ybtse;
          this.skssq = res.data.zhsd[0].skssq;
        }
      });
    },
    arrearsQuery() {
      let param = {
        enterpriseId: this.enterpriseId,
        taxMonth: this.mouth,
        userId: this.userId,
      };
      arrearsQuery(param).then((res) => {
        console.log(res);
        if (res.code === 0) {
          this.taxTotal1 = 0;
          res.data.forEach((e1) => {
            e1.salaryTaxArrears.forEach((e2) => {
              e2.detailList.forEach((e3) => {
                if (e3.ybtse !== "")
                  this.taxTotal1 += e3.ybtse * 1 + e3.znje * 1;
              });
            });
          });

          this.taxTotal1 = this.taxTotal.toFixed(2);
        }
      });
    },
    taxPayment() {
      if (!this.showFlag) return false;
      if (this.sfxyh === "") return false;
      if (
        !this.selectList.length === 0 ||
        typeof this.selectList === "string"
      ) {
        return false;
      }
      this.$store.commit("showLoading", "");
      let param = {
        enterpriseId: this.enterpriseId,
        taxMonth: this.mouth,
        sfxyh: this.sfxyh,
        userId: this.userId,
      };
      taxPayment(param).then((res) => {
        console.log(res);
        if (res.code === 0) {
          this.$emit("showTPage4");
          this.$store.commit("hideLoading", "");
          // this.$router.push({
          //   path: "/transitionPage4",
          //   query: {
          //     month: this.mouth,
          //     batch: this.batch,
          //   },
          // });
        } else {
          this.$store.commit("MyMessage", res.msg);
        }
      });
    },
    geshuiCheck() {
      if (this.tableData.length === 0) {
        return;
      }
      let param = {
        enterpriseId: this.enterpriseId,
        userId: this.userId,
        month: this.mouth,
        states: 80,
      };
      geshuiCheck(param).then((res) => {
        console.log("geshuiCheck", res);
        if (res.code === 0) {
          location.reload();
          this.$store.commit("MyMessage", "审核成功");
        } else {
          this.$store.commit("MyMessage", res.code);
        }
      });
    },
    selectChange(e) {
      console.log(this.sfxyh);
    },
    queryEnterpriseInfoForUser() {
      let param = {
        enterpriseId: this.enterpriseId,
        userId: this.userId,
      };
      queryEnterpriseInfoForUser(param).then((res) => {
        console.log("queryEnterpriseInfo", res);
        this.adminId = res.data.adminId;
        this.selectList = res.data.bankList;
        this.company = res.data.company;
        if (this.selectList.length > 0) {
          this.value = this.selectList[0].bankcardId;
        }
        this.payment.forEach((element) => {
          this.selectList.push(element);
        });
      });
    },
    queryBaoshuiamount() {
      // 查询报税金额
      let param = {
        enterpriseId: this.enterpriseId,
        month: this.mouth,
        userId: this.userId,
      };
      queryBaoshuiamount(param).then((res) => {
        this.taxTotal = res.data.taxTotal;
      });
    },
    queryAgreementBankInfo() {
      this.$store.commit("showLoading", "");
      // 查询协议银行
      let param = {
        enterpriseId: this.enterpriseId,
        taxMonth: this.mouth.split("-")[0] + this.mouth.split("-")[1],
        userId: this.userId,
      };
      queryAgreementBankInfo(param).then((res) => {
        console.log(res);
        if (res.code === 0) {
          this.showFlag = false;
          this.getAgreementBankInfo();
        }
      });
    },
    getAgreementBankInfo() {
      this.$store.commit("showLoading", "");
      // 查询协议银行
      let param = {
        enterpriseId: this.enterpriseId,
        taxMonth: this.mouth,
        userId: this.userId,
      };
      getAgreementBankInfo(param).then((res) => {
        console.log(res);
        if (res.code === 0) {
          if (res.data.status === "1") {
            // 更新的时候调取
            // this.queryAgreementBankInfo();
            this.sfxyh = res.data.banksList[0].sfxyh;
            this.selectList = res.data.banksList;
            this.$store.commit("hideLoading", "");
            this.showFlag = true;
          } else {
            setTimeout(() => {
              if (this.ajax) this.getAgreementBankInfo();
            }, 5000);
          }
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.popup-layer {
  text-align: center;
}
.invitation-main {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 400px;
  margin-left: -200px;
  background: #fff;
  border-radius: 5px;
  height: 475px;
  margin-top: -280px;
}
.banklist-main {
  width: 90%;
  margin: 0 auto;
}
.generate-p1 {
  padding: 36px 28px 22px;
  font-size: 14px;
  font-weight: 400;
  color: #999999;
  text-align: left;
}
.generate-p2 {
  padding: 15px 28px 0px;
  font-size: 14px;
  font-weight: bold;
  color: #262626;
  text-align: center;
}
.generate-p3 {
  font-size: 36px;
  font-weight: bold;
  color: #262626;
  text-align: center;
  padding: 0 28px;
  line-height: 48px;
  // height: 54px;
  // line-height: 54px;
  span {
    font-size: 15px;
    font-weight: bold;
    line-height: 28px;
    color: #262626;
    // float: left;
    // position: relative;
    // top: 11px;
    // margin-right: 5px;
  }
}
.generate-p4 {
  font-size: 12px;
  font-weight: 400;
  color: #262626;
  text-align: left;
  padding: 15px 28px 5px;
}
.generate-p5 {
  text-align: left;
  padding: 0 28px 10px;
  font-size: 16px;
  font-weight: 400;
  color: #262626;
  position: relative;
  .gengxin {
    font-size: 14px;
    position: absolute;
    right: 60px;
    top: 7px;
    width: 40px;
    display: inline-block;
    color: #4c8afc;
    border: 1px solid #4c8afc;
    text-align: center;
    padding: 3px 5px;
    border-radius: 3px;
    cursor: pointer;
  }
  .el-select {
    padding-bottom: 10px;
    border-bottom: 1px dashed #bbb;
  }
}
.generate-p6 {
  font-size: 12px;
  font-weight: 400;
  line-height: 28px;
  color: #bbbbbb;
  text-align: center;
}
.generate-btn1 {
  width: 139px;
  height: 44px;
  background: #f4f4f4;
  opacity: 1;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 400;
  color: #262626;
  cursor: pointer;
}
.generate-table {
  padding: 20px 28px 10px;

  table {
    border: 1px solid #dbdbdb;
    width: 100%;
  }
  td {
    height: 36px;
  }
  .generate-table-td1 {
    width: 85px;
    text-align: left;
    border-bottom: 1px solid #dbdbdb;
    padding-left: 14px;
    color: #bbb;
  }
  .generate-table-td2 {
    text-align: left;
    border-bottom: 1px solid #dbdbdb;
    color: #262626;
  }
}
.generate-btn2 {
  width: 139px;
  height: 44px;
  background: #4c8afc;
  opacity: 1;
  border-radius: 4px;
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  margin-left: 28px;
  cursor: pointer;
}
</style>
<style lang="less">
.selectStyle {
  width: 100%;
  .el-input__inner {
    border: none;
    border-radius: 0;
    border-bottom: 1px dashed;
    padding: 0;
    // font-size: 12px;
    &::placeholder {
      font-size: 14px;
    }
  }
  .el-select__caret {
    color: #4c8afc !important;
    width: 12px;
    font-weight: bold;
  }
}
.noSelect {
  .el-input__inner {
    background: none !important;
    border: none !important;
    border-radius: 0;
    border-bottom: none;
    padding: 0;
    cursor: default !important;
    color: #262626 !important;
    font-size: 16px !important;
  }
}
</style>