<template>
  <div class="personDetails-content-main">
    <div class="main-list">
      <div class="main-div">
        <div class="main-div-l">社保公积金增员</div>
        <div class="main-div-c">
          <el-switch
            v-model="switchFlag"
            active-color="#4CD964"
            inactive-color="#bbbbbb"
            style="width: 50px; height: 24px"
          >
          </el-switch>
        </div>
      </div>
      <div class="main-div" v-if="switchFlag" @click="selectClick($event)">
        <div class="main-list-bg" v-if="!switchFlag"></div>
        <div class="main-div-l">所在城市<span>*</span></div>
        <div class="main-div-c">
          <input
            type="text"
            readonly
            v-model="cascadeText"
            placeholder="请选择"
          />
        </div>
        <div class="main-div-r">
          <svg-icon icon-class="jiantou_qian" class="jiantou-icon"></svg-icon>
        </div>
      </div>
    </div>
    <div class="main-list" v-if="switchFlag">
      <div class="main-list-bg" v-if="!switchFlag"></div>
      <div class="main-div">
        <div class="main-div-l">社保基数<span>*</span></div>
        <div class="main-div-c">
          <input
            type="text"
            v-model="employeeNoun.socialBasics"
            placeholder="请输入"
          />
        </div>
        <div class="main-div-r">元</div>
      </div>
      <div class="main-div">
        <div class="main-div-l">缴纳时间<span>*</span></div>
        <div class="main-div-c">
          <el-date-picker
            v-model="employeeNoun.socialPayTime"
            :editable="false"
            value-format="yyyy-MM"
            type="month"
            placeholder="选择月"
            :picker-options="pickerOptions"
          >
          </el-date-picker>
        </div>
        <div class="main-div-r">
          <svg-icon
            icon-class="jiantou_shen"
            v-if="employeeNoun.socialPayTime"
            class="jiantou-icon"
          ></svg-icon>
          <svg-icon
            icon-class="jiantou_qian"
            v-else
            class="jiantou-icon"
          ></svg-icon>
        </div>
      </div>
      <div class="main-tips">
        <span class="span1">提示：可根据实际比例情况修正</span>
      </div>
      <div class="main-div1 main-div2">
        <div class="main-div1-title">
          <div class="main-div1-l">险种名称</div>
          <div class="main-div1-c">公司%</div>
          <div class="main-div1-r">个人%</div>
        </div>
      </div>
      <div class="main-div1" v-for="(e, i) in list4" :key="i">
        <div class="main-div1-body">
          <div class="main-div1-l">
            {{ e.title }} <span v-if="e.desc">{{ e.desc }}</span>
          </div>
          <div class="main-div1-c">
            <input type="text" v-model="employeeNoun[e.field1]" />
          </div>
          <div class="main-div1-r">
            <input type="text" v-model="employeeNoun[e.field2]" />
          </div>
        </div>
      </div>
    </div>
    <div class="main-list" v-if="switchFlag">
      <div class="main-list-bg" v-if="!switchFlag"></div>
      <div class="main-div">
        <div class="main-div-l">公积金基数<span>*</span></div>
        <div class="main-div-c">
          <input
            type="text"
            class="bankIpt"
            v-model="employeeNoun.providentBasics"
            placeholder="请输入"
          />
        </div>
        <div class="main-div-r">元</div>
      </div>
      <div class="main-div">
        <div class="main-div-l">缴纳时间<span>*</span></div>
        <div class="main-div-c">
          <el-date-picker
            v-model="employeeNoun.providentPayTime"
            :editable="false"
            value-format="yyyy-MM"
            type="month"
            placeholder="选择月"
            :picker-options="pickerOptions"
          >
          </el-date-picker>
        </div>
        <div class="main-div-r">
          <svg-icon
            icon-class="jiantou_shen"
            v-if="employeeNoun.providentPayTime"
            class="jiantou-icon"
          ></svg-icon>
          <svg-icon
            icon-class="jiantou_qian"
            v-else
            class="jiantou-icon"
          ></svg-icon>
        </div>
      </div>
      <div class="main-tips">
        <span class="span2">提示：请根据企业实际情况输入公积金基数及比例</span>
      </div>
      <div class="main-div1 main-div2">
        <div class="main-div1-title">
          <div class="main-div1-l">险种名称</div>
          <div class="main-div1-c">公司%</div>
          <div class="main-div1-r">个人%</div>
        </div>
      </div>
      <div class="main-div1">
        <div class="main-div1-body">
          <div class="main-div1-l">公积金比例</div>
          <div class="main-div1-c">
            <input v-model="employeeNoun.providentPerson" type="text" />
          </div>
          <div class="main-div1-r">
            <input type="text" v-model="employeeNoun.providentCompany" />
          </div>
        </div>
      </div>
    </div>
    <cascadeCityPopup
      v-if="popupShow"
      :offsetTop="popupTop"
      :offsetWidth="popupLeft"
      :popupBottom="popupBottom"
      :item="item"
      :ajaxData="employeeNoun"
      @cascadeClose="cascadeClose"
      @swiperSave="swiperSave"
    ></cascadeCityPopup>
  </div>
</template>

<script>
import bus from "@/util/bus";
import { querySocialSecurity, updateEmployeeNoun } from "@/http/api";
import cascadeCityPopup from "./cascadeCityPopup";
export default {
  data() {
    return {
      userId: sessionStorage.getItem("userId"),
      enterpriseId: sessionStorage.getItem("enterpriseId"),
      value: true,
      switchFlag: false,
      popupShow: false,
      SalaryStaff: {},
      employeeNoun: {},
      popupTop: "",
      popupBottom: "",
      popupLeft: "",
      cascadeText: "",
      item: {
        value1: "",
        value2: "",
        value3: "",
        desc1: "",
        desc2: "",
        desc3: "",
      },
      list4: [
        {
          title: "养老",
          field1: "oldPerson",
          field2: "oldCompany",
        },
        {
          title: "医疗",
          desc: "(+生育)",
          field1: "medicalPerson",
          field2: "medicalCompany",
        },
        {
          title: "失业",
          field1: "jobPerson",
          field2: "jobCompany",
        },
        {
          title: "工伤",
          field1: "industrialPerson",
          field2: "industrialCompany",
        },
        {
          title: "其他",
          field1: "babyPerson",
          field2: "babyCompany",
        },
      ],
      pickerOptions: {
        disabledDate(time) {
          let _now = Date.now(),
            seven = 7 * 24 * 60 * 60 * 1000,
            sevenDays = _now - seven;
          return time.getTime() > _now; //大于当前的禁止，小于7天前的禁止
        },
      },
    };
  },
  props: {
    details: {
      type: Object,
      default() {
        return {};
      },
    },
    eData: {
      type: Object,
      default() {
        return {};
      },
    },
    navIndex: {
      type: Number,
      default() {
        return 0;
      },
    },
  },
  components: {
    cascadeCityPopup,
  },
  watch: {
    navIndex(newValue, oldValue) {
      this.init();
    },
  },
  mounted() {},
  methods: {
    init() {
      if (this.navIndex === 2) {
        this.SalaryStaff = this.$config.cloneObj(this.details);
        this.switchFlag = this.SalaryStaff.isPaymentSS === 2;
        this.employeeNoun = this.eData;
        if (this.employeeNoun.provinceCode !== "") {
          if (
            this.employeeNoun.provinceCode === "110000" ||
            this.employeeNoun.provinceCode === "310000" ||
            this.employeeNoun.provinceCode === "500000" ||
            this.employeeNoun.provinceCode === "120000"
          ) {
            this.cascadeText =
              this.employeeNoun.provinceName +
              " - " +
              this.employeeNoun.areaName;
          } else {
            this.cascadeText =
              this.employeeNoun.provinceName +
              " - " +
              this.employeeNoun.cityName;
          }
          console.log(this.cascadeText);
          if (this.cascadeText === " - ") {
            this.cascadeText = "";
          }
        }
        let that = this;
        bus.$off("sbgjj");
        bus.$on("sbgjj", function () {
          that.save();
        });
      }
    },
    save() {
      if (this.switchFlag) {
        if (
          this.employeeNoun.provinceCode === "" ||
          !this.employeeNoun.provinceCode
        ) {
          this.$store.commit("MyMessage", "请选择缴纳城市");
          return;
        }
        if (
          this.employeeNoun.socialBasics === "" ||
          !this.employeeNoun.socialBasics
        ) {
          this.$store.commit("MyMessage", "请输入社保基数金额");
          return;
        }

        if (
          this.employeeNoun.socialPayTime === "" ||
          !this.employeeNoun.socialPayTime
        ) {
          this.$store.commit("MyMessage", "请选择社保缴纳时间");
          return;
        }
        if (this.employeeNoun.providentBasics === "") {
          this.$store.commit("MyMessage", "请输入公积金基数金额");
          return;
        }

        if (
          this.employeeNoun.providentPayTime === "" ||
          !this.employeeNoun.providentPayTime
        ) {
          this.$store.commit("MyMessage", "请选择公积金缴纳时间");
          return;
        }

        this.employeeNoun.socialPayTimes = this.employeeNoun.socialPayTime;
        this.employeeNoun.providentPayTimes =
          this.employeeNoun.providentPayTime;
        if (
          this.employeeNoun.industrialPerson === "" ||
          this.employeeNoun.industrialCompany === ""
        ) {
          this.$store.commit("MyMessage", "请输入工伤缴纳比例");
          return;
        }
        if (
          this.employeeNoun.oldPerson === "" ||
          this.employeeNoun.oldCompany === ""
        ) {
          this.$store.commit("MyMessage", "请输入养老缴纳比例");
          return;
        }
        if (
          this.employeeNoun.medicalPerson === "" ||
          this.employeeNoun.medicalCompany === ""
        ) {
          this.$store.commit("MyMessage", "请输入医疗缴纳比例");
          return;
        }
        if (
          this.employeeNoun.babyPerson === "" ||
          this.employeeNoun.babyCompany === ""
        ) {
          this.$store.commit("MyMessage", "请输入其他缴纳比例");
          return;
        }
        if (
          this.employeeNoun.jobPerson === "" ||
          this.employeeNoun.jobCompany === ""
        ) {
          this.$store.commit("MyMessage", "请输入失业缴纳比例");
          return;
        }
        if (
          this.employeeNoun.providentPerson === "" ||
          this.employeeNoun.providentCompany === ""
        ) {
          this.$store.commit("MyMessage", "请输入公积金缴纳比例");
          return;
        }
        // console.log(this.employeeNoun);
        this.employeeNoun.salaryStaffId = this.SalaryStaff.salaryStaffId;
        this.employeeNoun.enterpriseId = this.enterpriseId;
        this.employeeNoun.registered =
          this.SalaryStaff.registered <= 0 ? 2 : this.SalaryStaff.registered;

        updateEmployeeNoun(this.employeeNoun).then((res) => {
          // console.log(res);
          if (res.code === 0) {
            let text = 0;
            for (let key in bus._events) {
              if (key === "jbxx") text = 1;
            }
            if (text) {
              bus.$emit("jbxx");
            } else {
              this.$store.commit("MyMessage", "保存成功");
              this.back();
            }
          } else {
            // bus.$emit("saveSubmit", 2);
            this.$store.commit("MyMessage", res.msg);
          }
        });
      }
    },
    back() {
      this.$router.go(-1); //返回上一层
    },
    cascadeClose() {
      this.popupShow = false;
      this.deductShow = false;
      this.selectShow2 = false;
      this.$forceUpdate();
    },
    swiperSave(param) {
      this.item = param;
      if (
        this.item.value1 === "110000" ||
        this.item.value1 === "310000" ||
        this.item.value1 === "500000" ||
        this.item.value1 === "120000"
      ) {
        this.cascadeText = this.item.desc1 + " - " + this.item.desc3;
      } else {
        this.cascadeText = this.item.desc1 + " - " + this.item.desc2;
      }

      this.SalaryStaff.provinceCode = this.item.value1;
      this.SalaryStaff.cityCode = this.item.value2;
      this.SalaryStaff.areaCode = this.item.value3;
      this.SalaryStaff.provinceName = this.item.desc1;
      this.SalaryStaff.cityName = this.item.desc2;
      this.SalaryStaff.areaName = this.item.desc3;
      let cityCode = this.item.value2;
      let areaCode = this.item.value3;
      if (
        this.item.value1 === "110000" ||
        this.item.value1 === "310000" ||
        this.item.value1 === "500000" ||
        this.item.value1 === "120000"
      ) {
      } else {
        areaCode = 1;
      }
      let params = {
        provinceCode: this.item.value1,
        cityCode: cityCode,
        areaCode: areaCode,
        registered:
          this.SalaryStaff.registered <= 0 ? 2 : this.SalaryStaff.registered,
      };
      this.querySocialSecurity(params);
      this.$forceUpdate();
    },
    querySocialSecurity(param) {
      querySocialSecurity(param).then((res) => {
        // console.log("querySocialSecurity", res);
        if (res.code == 0) {
          let database = res.data;
          if (database) {
            this.employeeNoun.socialBasics = database.socialBasicsYanglao;
            this.employeeNoun.providentBasics = database.socialBasicsProvident;
            //工伤
            this.employeeNoun.industrialPerson = database.industrialPerson;
            this.employeeNoun.industrialCompany = database.industrialCompany;
            //养老
            this.employeeNoun.oldPerson = database.oldPerson;
            this.employeeNoun.oldCompany = database.oldCompany;

            // 医疗
            this.employeeNoun.medicalPerson = database.medicalPerson;
            this.employeeNoun.medicalCompany = database.medicalCompany;
            // 生育
            this.employeeNoun.babyPerson = database.babyPerson;
            this.employeeNoun.babyCompany = database.babyCompany;
            // 失业
            this.employeeNoun.jobPerson = database.jobPerson;
            this.employeeNoun.jobCompany = database.jobCompany;
            // 公积金比例
            this.employeeNoun.providentPerson = database.providentPerson;
            this.employeeNoun.providentCompany = database.providentCompany;
          } else {
            this.employeeNoun.socialBasics = "";
            this.employeeNoun.providentBasics = "";
            //工伤
            this.employeeNoun.industrialPerson = 0;
            this.employeeNoun.industrialCompany = 0;
            //养老
            this.employeeNoun.oldPerson = 0;
            this.employeeNoun.oldCompany = 0;

            // 医疗
            this.employeeNoun.medicalPerson = 0;
            this.employeeNoun.medicalCompany = 0;
            // 生育
            this.employeeNoun.babyPerson = 0;
            this.employeeNoun.babyCompany = 0;
            // 失业
            this.employeeNoun.jobPerson = 0;
            this.employeeNoun.jobCompany = 0;
            // 公积金比例
            this.employeeNoun.providentPerson = 0;
            this.employeeNoun.providentCompany = 0;
          }
          this.popupShow = false;
        }
        this.$forceUpdate();
      });
    },
    selectClick(e) {
      console.log(111);
      if (!this.switchFlag) {
        return;
      }
      this.popupShow = true;
      let H1 =
        document.body.clientHeight -
        e.currentTarget.getBoundingClientRect().bottom;

      if (H1 < 450) {
        this.popupBottom = H1 + 20;
        this.direction = "bottom";
        this.popupTop = null;
      } else {
        this.popupTop = e.currentTarget.getBoundingClientRect().top + 20;
        this.direction = "top";
        this.popupBottom = null;
      }

      this.popupLeft = e.currentTarget.getBoundingClientRect().left - 20;
    },
  },
};
</script>

<style lang="less" scoped>
@import "./payroll.less";
.personDetails-content-main {
  min-height: 436px;
}
.main-list,
.main-div {
  position: relative;
}
.main-list-bg {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: #fff;
  opacity: 0.5;
  z-index: 1;
}
</style>
<style lang="less">
.main-div-c {
  .el-switch__core {
    width: 50px !important;
    height: 100%;
    border-radius: 15px;
    &::after {
      width: 20px;
      height: 20px;
      margin-left: 1px !important;
    }
  }
  .is-checked {
    .el-switch__core {
      &::after {
        margin-left: -22px !important;
      }
    }
  }
}
</style>
<style lang="less">
.main-div-c {
  .el-date-editor {
    width: 100%;
    .el-input__inner {
      height: 54px;
      padding: 0;
      margin: 0;
      text-align: right;
      border: none;
      background: none;
      font-size: 14px;
      font-weight: 600;
      color: #262626;
      &::placeholder {
        color: #bbbbbb;
        font-weight: 400;
      }
    }
    .el-input__prefix,
    .el-input__suffix {
      display: none;
    }
  }
}
</style>