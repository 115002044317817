import { render, staticRenderFns } from "./transitionPage1.vue?vue&type=template&id=abc3a766&scoped=true&"
import script from "./transitionPage1.vue?vue&type=script&lang=js&"
export * from "./transitionPage1.vue?vue&type=script&lang=js&"
import style0 from "./transitionPage1.vue?vue&type=style&index=0&id=abc3a766&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "abc3a766",
  null
  
)

export default component.exports