<template>
  <div class="declare-popup">
    <div class="declare-main">
      <div class="department-header department-width">
        <div class="department-link" @click="childClose">
          <i class="el-icon-arrow-left"></i>返回
        </div>
        赡养老人
        <div class="department-btn">
          <i class="el-icon-info" @click="showPopup3 = true"></i>
          <!-- <div class="addStaff-btn" @click="submitFun">保 存</div> -->
        </div>
      </div>
      <div class="declare-main-content">
        <div class="declare-list">
          <div class="declare-box">
            <div class="declare-box-l">手机号码</div>
            <div class="declare-box-r">
              <input type="text" v-model="item.mobile" placeholder="请输入" />
            </div>
          </div>
          <div class="declare-box">
            <div class="declare-box-l">电子邮箱</div>
            <div class="declare-box-r">
              <input type="text" v-model="item.email" placeholder="请输入" />
            </div>
          </div>
          <div class="declare-box">
            <div class="declare-box-l">联系地址</div>
            <div class="declare-box-r">
              <el-input
                type="textarea"
                :autosize="{ minRows: 1, maxRows: 5 }"
                v-model="item.address"
                class="textStyle"
                placeholder="请输入"
              >
              </el-input>
            </div>
          </div>
        </div>
        <div class="declare-list">
          <div class="declare-box">
            <div class="declare-box-l">选择扣除年度</div>
            <div class="declare-box-r">
              <el-select v-model="item.deductYear" placeholder="请选择">
                <el-option
                  v-for="item in options1"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="declare-box">
            <div class="declare-box-l">选择被赡养老人</div>
            <div class="declare-box-r">
              <div
                class="declare-text"
                @click="showPopup4 = true"
                v-if="list1.length > 0"
              >
                {{ laorenText }}<i class="el-icon-arrow-down"></i>
              </div>
              <div
                class="declare-desc"
                @click="showClick('添加被赡养人信息', 1)"
                v-else
              >
                请添加<i class="el-icon-arrow-down"></i>
              </div>
            </div>
          </div>
        </div>
        <div class="declare-list">
          <div class="declare-box">
            <div class="declare-box-l">是否独生子女</div>
            <div class="declare-box-r">
              <el-select v-model="item.dusheng" placeholder="请选择">
                <el-option
                  v-for="item in dusheng"
                  :key="item.id"
                  :label="item.text"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="declare-box" v-if="item.dusheng === '1'">
            <div class="declare-box-l">分配比例</div>
            <div class="declare-box-r">
              <div class="declare-desc">全部由我扣除</div>
            </div>
          </div>
          <slot v-if="item.dusheng === '2'">
            <div
              class="declare-box"
              v-for="(e, i) in list2"
              :key="i"
              @click="showDetails(e, i)"
            >
              <div class="declare-box-l">共同赡养人</div>
              <div class="declare-box-r">
                <div class="declare-text">{{ e.name }}</div>
              </div>
            </div>
            <div
              class="declare-add-text"
              @click="showClick('共同赡养人信息', 2)"
            >
              <i class="el-icon-circle-plus-outline"></i> 添加共同赡养人
            </div>
          </slot>
        </div>

        <div class="declare-list" v-if="item.dusheng === '2'">
          <div class="declare-box">
            <div class="declare-box-l">
              分摊方式 <i class="el-icon-info" @click="showPopup5 = true"></i>
            </div>
            <div class="declare-box-r">
              <el-select v-model="item.fentan" placeholder="请选择">
                <el-option
                  v-for="item in fentan"
                  :key="item.id"
                  :label="item.text"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="declare-box dabing">
            <div class="declare-box-l">本年度月扣除金额(元)</div>
            <div class="declare-box-r">
              <input
                type="number"
                v-model="item.amt"
                placeholder="请输入"
                @input="amtFun"
              />
            </div>
          </div>
        </div>

        <div class="declare-list check-list">
          <div class="declare-title">您的扣缴义务人</div>
          <div class="declare-box" @click="check = 1">
            <p class="p1">唐山市航达商贸有限公司1</p>
            <p class="p2">主管税务机关：国家税务总局唐山市路北区税务局</p>
            <p class="p2">地址：唐山丰润区曹雪芹西大街（三丰小区）102</p>
            <i class="el-icon-check" v-if="check === 1"></i>
          </div>
          <div class="declare-box" @click="check = 2">
            <p class="p1">唐山市航达商贸有限公司2</p>
            <p class="p2">主管税务机关：国家税务总局唐山市路北区税务局</p>
            <p class="p2">地址：唐山丰润区曹雪芹西大街（三丰小区）102</p>
            <i class="el-icon-check" v-if="check === 2"></i>
          </div>
        </div>
        <div class="declare-btns">
          <el-button type="primary" @click="saveFun">保 存</el-button>
        </div>
      </div>
    </div>
    <div class="popup-layer" v-if="showPopup5">
      <div class="popup-layer-bg" @click="showPopup5 = false"></div>
      <div class="popup-zinv tishi-popup" style="min-height: 240px">
        <div class="popup-layer-close" @click="showPopup5 = false">
          <svg-icon icon-class="popup-close"></svg-icon>
        </div>
        <div class="popup-zinv-tishi" style="height: 200px">
          <div class="tishi-title">分摊方式</div>
          <div class="tishi-p1">
            如果您为非独生子女，且您与兄弟姐妹分摊每月2000元的扣除额度，每人分摊的额度不能超过每月1000元。可以由赡养人均摊或约定分摊，也可以由被赡养人指定分摊。
          </div>
        </div>
        <div class="popup-tishi-footer">
          <div class="zinv-btn" @click="showPopup5 = false">我知道了</div>
        </div>
      </div>
    </div>
    <!-- 共同赡养人信息列表 -->
    <div class="popup-layer" v-if="showPopup2">
      <div class="popup-layer-bg" @click="showPopup2 = false"></div>
      <div class="popup-zinv">
        <div class="popup-layer-close" @click="showPopup2 = false">
          <svg-icon icon-class="popup-close"></svg-icon>
        </div>
        <div class="popup-zinv-main">
          <div class="popup-zinv-list">
            <div class="declare-box" v-for="(e, i) in list1" :key="i">
              <p class="p1">{{ e.name }}</p>
              <p class="p2">与我的关系：女儿</p>
              <p class="p2">居民身份证号：1*****************X</p>
            </div>
          </div>
        </div>
        <div class="popup-zinv-footer">
          <div class="zinv-btn" @click="showClick('共同赡养人信息', 2)">
            添加共同赡养人
          </div>
        </div>
      </div>
    </div>
    <!-- 被赡养人信息列表 -->
    <div class="popup-layer" v-if="showPopup4">
      <div class="popup-layer-bg"></div>
      <div class="popup-zinv">
        <div class="popup-layer-close">
          <svg-icon icon-class="popup-close"></svg-icon>
        </div>
        <div class="popup-zinv-main">
          <div class="popup-zinv-list">
            <div
              class="declare-box"
              v-for="(e, i) in list1"
              :key="i"
              @click="laorenSelect(e)"
            >
              <p class="p1">{{ e.name }}</p>
              <p class="p2">与我的关系：女儿</p>
              <p class="p2">居民身份证号：1*****************X</p>
            </div>
          </div>
        </div>
        <div class="popup-zinv-footer">
          <div class="zinv-btn" @click="showClick('添加被赡养人信息', 1)">
            添加被赡养人信息
          </div>
        </div>
      </div>
    </div>
    <!-- 头部提示 -->
    <div class="popup-layer" v-if="showPopup3">
      <div class="popup-layer-bg" @click="showPopup3 = false"></div>
      <div class="popup-zinv tishi-popup">
        <div class="popup-layer-close" @click="showPopup3 = false">
          <svg-icon icon-class="popup-close"></svg-icon>
        </div>
        <div class="popup-zinv-tishi">
          <div class="tishi-title">赡养老人填报提示</div>
          <div class="tishi-p1">
            纳税人赡养一位及以上被赡养人的赡养支出,统一按照以下标准定额扣除：
          </div>
          <div class="tishi-p2">温馨提示：</div>
          <div class="tishi-p2">
            （一）纳税人为独生子女的,按照每月2000元的标准定额扣除；
          </div>
          <div class="tishi-p2">
            （二）纳税人为非独生子女的,由其与兄弟姐妹分摊每月2000元的扣除额度,每人分摊的额度不能超过每月1000元。可以由赡养人均摊或者约定分摊,也可以由被赡养人指定分摊。
            温馨提示：
          </div>
          <div class="tishi-p1">被赡养人年满60周岁后,您才可享受该项扣除。</div>
        </div>
        <div class="popup-tishi-footer">
          <div class="zinv-btn" @click="showPopup3 = false">我知道了</div>
        </div>
      </div>
    </div>

    <addPatientPopup
      :popupData="popupData"
      type="shanyang"
      v-if="showPopup1"
      @closeFun="closeFun"
      @save="save"
    ></addPatientPopup>
    <delPatientPopup
      :popupData="details"
      type="shanyang"
      v-if="detailsShow"
      @closeFun="closeFun"
      @detailsDel="detailsDel"
      @detailsSave="detailsSave"
    ></delPatientPopup>
  </div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/zh-cn";
import addPatientPopup from "./addPatientPopup";
import delPatientPopup from "./delPatientPopup";
export default {
  data() {
    return {
      detailsShow: false,
      showPopup1: false,
      showPopup2: false,
      showPopup3: false,
      showPopup4: false,
      showPopup5: false,
      item: {
        mobile: "",
        email: "",
        address: "",
        deductYear: "",
        daikuan: "",
        dusheng: "",
        fentan: "",
        amt: null,
      },
      check: "",
      supportList: [
        {
          name: "",
          userId: "",
        },
      ],
      options1: [
        {
          value: "2020",
          label: "2020",
        },
        {
          value: "2021",
          label: "2021",
        },
      ],
      dusheng: [
        {
          id: "1",
          text: "独生子女",
        },
        {
          id: "2",
          text: "非独生子女",
        },
      ],
      fentan: [
        {
          id: "1",
          text: "赡养人平均分摊",
        },
        {
          id: "2",
          text: "赡养人约定分摊",
        },
        {
          id: "3",
          text: "被赡养人指定分摊",
        },
      ],
      popupData: {
        title: "",
        relation: "",
        relationText: "",
      },
      list1: [],
      list2: [],
      laorenText: "",
      type: "",
      details: {},
      detailsIndex: null,
    };
  },
  components: {
    // DatePicker,
    addPatientPopup,
    delPatientPopup,
  },
  props: {
    dateList: {
      type: Array,
      default() {
        return null;
      },
    },
  },
  mounted() {
    this.options1 = this.dateList;
  },
  methods: {
    showDetails(e, i) {
      this.detailsIndex = i;
      this.details = e;
      this.details.title = "共同赡养人信息";
      this.detailsShow = true;
    },
    detailsDel() {
      this.list2.splice(this.detailsIndex, 1);
      this.detailsShow = false;
    },
    detailsSave(e) {
      this.list2[this.detailsIndex] = e;
      this.detailsShow = false;
    },
    save(e) {
      if (this.type === 1) {
        this.list1.push(e);
        this.showPopup1 = false;
        this.showPopup4 = true;
      }
      if (this.type === 2) {
        this.list2.push(e);
        this.showPopup1 = false;
      }
    },
    laorenSelect(e) {
      this.laorenText = e.name;
      this.showPopup4 = false;
    },
    saveFun() {
      this.childClose();
    },
    childClose() {
      this.$emit("closeFun");
    },
    amtFun() {
      if (this.item.amt > 1000) {
        this.item.amt = 1000;
      }
    },
    closeFun() {
      this.showPopup1 = false;
      this.detailsShow = false;
    },
    showClick(text, type) {
      this.popupData.title = text;
      this.type = type;
      this.showPopup4 = false;
      this.showPopup1 = true;
      this.showPopup2 = false;
    },
  },
};
</script>

<style lang="less" scoped>
.department-width {
  width: 480px;
  margin: 0 auto;
}

.popup-layer {
  z-index: 999;
}
.tishi-popup {
  // min-height: 256px;
  .popup-zinv-tishi {
    // height: 256px;
  }
}
.dabing {
  .declare-box-l {
    width: 190px;
    position: relative;
    .el-icon-info {
      position: absolute;
      right: 0;
      top: 1px;
    }
  }
  .declare-box-r {
    width: calc(100% - 190px);
  }
}
input[type="number"] {
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  -moz-appearance: textfield;
}
</style>
