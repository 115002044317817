<template>
  <div class="declare-popup">
    <div class="declare-main">
      <div class="department-header department-width">
        <div class="department-link" @click="childClose">
          <i class="el-icon-arrow-left"></i>返回
        </div>
        住房租金
        <div class="department-btn">
          <!-- <div class="addStaff-btn" @click="submitFun">保 存</div> -->
        </div>
      </div>
      <div class="declare-main-content">
        <div class="declare-list">
          <div class="declare-box">
            <div class="declare-box-l">手机号码</div>
            <div class="declare-box-r">
              <input type="text" v-model="item.mobile" placeholder="请输入" />
            </div>
          </div>
          <div class="declare-box">
            <div class="declare-box-l">电子邮箱</div>
            <div class="declare-box-r">
              <input type="text" v-model="item.email" placeholder="请输入" />
            </div>
          </div>
          <div class="declare-box">
            <div class="declare-box-l">联系地址</div>
            <div class="declare-box-r">
              <el-input
                type="textarea"
                :autosize="{ minRows: 1, maxRows: 5 }"
                v-model="item.address"
                class="textStyle"
                placeholder="请输入"
              >
              </el-input>
            </div>
          </div>
        </div>
        <div class="declare-list">
          <div class="declare-box">
            <div class="declare-box-l">选择扣除年度</div>
            <div class="declare-box-r">
              <el-select v-model="item.deductYear" placeholder="请选择">
                <el-option
                  v-for="item in options1"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="declare-box">
            <div class="declare-box-l">主要工作城市</div>
            <div class="declare-box-r">
              <el-select v-model="item.jixu" placeholder="请选择">
                <el-option
                  v-for="item in options2"
                  :key="item.id"
                  :label="item.text"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </div>
          </div>
        </div>
        <div class="declare-list">
          <div class="declare-box" style="overflow: hidden">
            <div class="declare-box-l">
              租赁房屋坐落地址<br />(省市地区)<br />
            </div>
            <div class="declare-box-r" @click="popupShowClick($event)">
              <div
                class="declare-text"
                @click="showPopup1 = true"
                v-if="cascadeText"
              >
                {{ cascadeText }}<i class="el-icon-arrow-down"></i>
              </div>
              <div class="declare-desc" @click="showPopup1 = true" v-else>
                请选择<i class="el-icon-arrow-down"></i>
              </div>
            </div>
          </div>
          <div class="declare-box">
            <div class="declare-box-l">详细地址</div>
            <div class="declare-box-r">
              <el-input
                type="textarea"
                :autosize="{ minRows: 1, maxRows: 5 }"
                v-model="item.address"
                class="textStyle"
                placeholder="请输入"
              >
              </el-input>
            </div>
          </div>

          <div class="declare-box">
            <div class="declare-box-l">租赁时间起</div>
            <div class="declare-box-r">
              <date-picker
                v-model="item.start"
                value-type="format"
                format="YYYY-MM-DD"
                class="data-picker-style"
                :clearable="false"
                :editable="false"
                placeholder="请选择"
              ></date-picker>
              <i class="el-icon-arrow-down"></i>
            </div>
          </div>
          <div class="declare-box">
            <div class="declare-box-l">租赁时间止</div>
            <div class="declare-box-r">
              <date-picker
                v-model="item.end"
                value-type="format"
                format="YYYY-MM-DD"
                class="data-picker-style"
                :clearable="false"
                :editable="false"
                placeholder="请选择"
              ></date-picker>
              <i class="el-icon-arrow-down"></i>
            </div>
          </div>
          <div class="declare-box">
            <div class="declare-box-l">租赁合同编号</div>
            <div class="declare-box-r">
              <input type="text" v-model="item.mobile" placeholder="请输入" />
            </div>
          </div>
        </div>

        <div class="declare-list">
          <div class="declare-box">
            <div class="declare-box-l">出租方类型</div>
            <div class="declare-box-r">
              <el-select v-model="item.chuzu" placeholder="请选择">
                <el-option
                  v-for="item in options3"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
          </div>
          <slot v-if="item.chuzu === '1'">
            <div class="declare-box">
              <div class="declare-box-l">出租人姓名</div>
              <div class="declare-box-r">
                <input type="text" v-model="item.mobile" placeholder="选填" />
              </div>
            </div>
            <div class="declare-box">
              <div class="declare-box-l">出租人身份证件类型</div>
              <div class="declare-box-r">
                <el-select v-model="item.daikuan" placeholder="选填">
                  <el-option
                    v-for="item in idTypeData"
                    :key="item.id"
                    :label="item.text"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>
            <div class="declare-box">
              <div class="declare-box-l">出租人身份证件号码</div>
              <div class="declare-box-r">
                <input type="text" v-model="item.mobile" placeholder="选填" />
              </div>
            </div>
          </slot>
          <slot v-if="item.chuzu === '2'">
            <div class="declare-box" style="overflow: hidden">
              <div class="declare-box-l">出租单位统一<br />社会信用代码</div>
              <div class="declare-box-r">
                <input type="text" v-model="item.mobile" placeholder="选填" />
              </div>
            </div>
            <div class="declare-box">
              <div class="declare-box-l">出租单位名称</div>
              <div class="declare-box-r">
                <input type="text" v-model="item.mobile" placeholder="选填" />
              </div>
            </div>
          </slot>
        </div>

        <div class="declare-list check-list">
          <div class="declare-title">您的扣缴义务人</div>
          <div class="declare-box" @click="check = 1">
            <p class="p1">唐山市航达商贸有限公司1</p>
            <p class="p2">主管税务机关：国家税务总局唐山市路北区税务局</p>
            <p class="p2">地址：唐山丰润区曹雪芹西大街（三丰小区）102</p>
            <i class="el-icon-check" v-if="check === 1"></i>
          </div>
          <div class="declare-box" @click="check = 2">
            <p class="p1">唐山市航达商贸有限公司2</p>
            <p class="p2">主管税务机关：国家税务总局唐山市路北区税务局</p>
            <p class="p2">地址：唐山丰润区曹雪芹西大街（三丰小区）102</p>
            <i class="el-icon-check" v-if="check === 2"></i>
          </div>
        </div>
        <div class="declare-btns">
          <el-button type="primary" @click="saveFun">保 存</el-button>
        </div>
      </div>
    </div>
    <div class="popup-layer" v-if="showPopup3">
      <div class="popup-layer-bg" @click="showPopup3 = false"></div>
      <div class="popup-zinv tishi-popup">
        <div class="popup-layer-close" @click="showPopup3 = false">
          <svg-icon icon-class="popup-close"></svg-icon>
        </div>
        <div class="popup-zinv-tishi">
          <div class="tishi-title">说明</div>
          <div class="tishi-p1">
            是否婚前各自首套贷款且婚后分别扣除：夫妻双方婚前分别购买住房发生的首套住房贷款，其贷款利息支出，婚后可以选择其中一套购买的住房，由购买方按扣除标准的100%扣除，也可以由夫妻双方对各自购买的住房分别按扣除标准的50%扣除，具体扣除方式在一个纳税年度内不能变更。
          </div>
        </div>
        <div class="popup-tishi-footer">
          <div class="zinv-btn" @click="showPopup3 = false">我知道了</div>
        </div>
      </div>
    </div>
    <cascadePopup
      v-if="popupShow"
      :offsetTop="popupTop"
      :offsetWidth="popupLeft"
      :popupBottom="popupBottom"
      :ajaxData="item"
      :item="child"
      @swiperSave="swiperSave"
      @cascadeClose="cascadeClose"
    ></cascadePopup>
  </div>
</template>

<script>
import cascadePopup from "@/components/cascadePopup";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/zh-cn";
export default {
  data() {
    return {
      popupShow: false,
      showPopup1: false,
      showPopup2: false,
      showPopup3: false,
      popupTop: null,
      popupBottom: null,
      popupLeft: null,
      child: {
        value1: "",
        value2: "",
        value3: "",
        desc1: "",
        desc2: "",
        desc3: "",
      },
      item: {
        mobile: "",
        email: "",
        address: "",
        deductYear: "",
        laoren: "",
        //  1
        chuzu: "",
        start: "",
        end: "",
        //  2
        jixuType: "",
        certification: "",
        certificationNo: "",
        certificationName: "",
        certificationOffice: "",
        value1: "",
        value2: "",
      },
      type: "",
      value: "13812345678",
      check: "",
      idTypeData: [
        {
          id: "-1",
          text: "暂不选择",
        },
        {
          id: "1",
          text: "居民身份证",
        },
        {
          id: "2",
          text: "中国护照",
        },
        {
          id: "3",
          text: "港澳居民来往内地通行证",
        },
        {
          id: "4",
          text: "台湾居民来往内地通行证",
        },
        {
          id: "5",
          text: "外国护照",
        },
        {
          id: "6",
          text: "外国人永久居留身份证(外国人永久居留证) ",
        },
        {
          id: "7",
          text: "中华人民共和国港澳居民居住证",
        },
        {
          id: "8",
          text: "中华人民共和国台湾居民居住证",
        },
        {
          id: "9",
          text: "中华人民共和国外国人工作许可证(A类)",
        },
        {
          id: "10",
          text: "中华人民共和国外国人工作许可证(B类)",
        },
        {
          id: "11",
          text: "中华人民共和国外国人工作许可证(C类)",
        },
        {
          id: "12",
          text: "其他个人证件",
        },
      ],
      options: [],
      options1: [
        {
          value: "2020",
          label: "2020",
        },
        {
          value: "2021",
          label: "2021",
        },
      ],
      options2: this.$dictionaries.employeeModel.cityMap,
      options3: [
        {
          value: "1",
          label: "自然人",
        },
        {
          value: "2",
          label: "组织",
        },
      ],
      options4: [
        {
          value: "1",
          label: "博士研究生",
        },
        {
          value: "2",
          label: "硕士研究生",
        },
        {
          value: "3",
          label: "大学本科",
        },
        {
          value: "4",
          label: "大学专科",
        },
        {
          value: "5",
          label: "其他",
        },
      ],
      options5: [
        {
          value: "1",
          label: "技能人员职业资格",
        },
        {
          value: "2",
          label: "专业技术人员职业资格",
        },
      ],
      boolData: this.$dictionaries.employeeModel.boolData.list,
      cascadeText: "",
    };
  },
  components: {
    DatePicker,
    cascadePopup,
  },
  props: {
    dateList: {
      type: Array,
      default() {
        return null;
      },
    },
  },
  mounted() {
    this.options1 = this.dateList;
  },
  methods: {
    saveFun() {
      this.childClose();
    },
    childClose() {
      this.$emit("closeFun");
    },
    popupShowClick(e) {
      this.popupShow = true;
      let H1 =
        document.body.clientHeight -
        e.currentTarget.getBoundingClientRect().bottom;
      if (H1 < 440) {
        this.popupBottom = H1 + 10;
        this.direction = "bottom";
      } else {
        this.popupTop = e.currentTarget.getBoundingClientRect().top + 30;
        this.direction = "top";
      }
      this.popupLeft = e.currentTarget.getBoundingClientRect().left - 350;
    },
    //     @swiperSave="swiperSave"
    // @cascadeClose="cascadeClose"
    swiperSave(param) {
      console.log(param);
      this.cascadeText = param.desc1 + "-" + param.desc2 + "-" + param.desc3;
      this.cascadeClose();
      this.$forceUpdate();
    },
    cascadeClose() {
      this.popupShow = false;
    },
  },
};
</script>

<style lang="less" scoped>
.department-width {
  width: 480px;
  margin: 0 auto;
}

.popup-layer {
  z-index: 999;
}
.tishi-popup {
  min-height: 256px;
  .popup-zinv-tishi {
    height: 256px;
  }
}
.dabing {
  .declare-box-l {
    width: 190px;
    position: relative;
    .el-icon-info {
      position: absolute;
      right: 0;
      top: 1px;
    }
  }
  .declare-box-r {
    width: calc(100% - 190px);
  }
}
</style>
