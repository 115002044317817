<template>
  <div class="staffbox-div">
    <div class="main-div-c">
      <slot v-if="child.type === 'input'">
        <div class="addStaff-tips" v-if="readonlyFlag">{{ child.value }}</div>
        <slot v-else>
          <slot v-if="child.field === 'name'">
            <input
              type="text"
              v-model="child.value"
              :placeholder="child.tips"
              @blur="blur"
              @focus="focus"
              @input="
                item.value = item.value.replace(/[^\u4E00-\u9FA5A-Za-z.]/g, '')
              "
            />
          </slot>
          <slot v-else-if="child.field === 'postSalary'">
            <input
              type="text"
              v-model="child.value"
              :placeholder="child.tips"
              @blur="blur"
              @focus="focus"
              @input="item.value = item.value.replace(/[^0-9.]{4}/g, ' ')"
            />
          </slot>
          <slot v-else-if="child.field === 'bankCardNum'">
            <input
              type="text"
              v-model="bankCardNum"
              :placeholder="child.tips"
              @blur="bankBlur"
              @focus="focus"
              @input="bankBlur"
            />
          </slot>
          <slot v-else-if="child.field === 'onTrialTermSalaryScale'">
            <input
              type="text"
              v-model="child.value"
              :placeholder="child.tips"
              @blur="blur"
              @focus="focus"
            />
          </slot>
          <slot v-else-if="child.field === 'mobile'">
            <input
              type="text"
              v-model="child.value"
              :placeholder="child.tips"
              @input="formatPhoneOnkeyUp(child.value)"
              @blur="blur"
              @focus="focus"
            />
          </slot>
          <slot v-else>
            <input
              type="text"
              v-model="child.value"
              :maxlength="maxlength"
              :placeholder="child.tips"
              @blur="blur"
              :readonly="readonlyFlag"
              @focus="focus"
            />
          </slot>
        </slot>
      </slot>
      <slot v-else>
        <textarea
          class="textStyle"
          v-model="currentValue"
          cols="30"
          rows="auto"
          @blur="blur"
          @focus="focus"
          :placeholder="child.tips"
          :ref="child.field"
        ></textarea>
      </slot>
    </div>
  </div>
</template>

<script>
import bus from "@/util/bus";
export default {
  data() {
    return {
      child: {},
      maxlength: 999,
      readonlyFlag: false,
      bankCardNum: "",
      bankDesc: "",
      currentValue: "",
    };
  },
  inject: ["ajaxData"],
  watch: {
    currentValue(nv, ov) {
      if (nv === ov) {
        return;
      }
      this.currentValue = nv;
      this.changeHeight();
    },
  },

  mounted() {
    let that = this;
    this.child = this.item;
    // console.log(this.item);
    this.currentValue = this.item.value;
    if (this.child.field === "mobile") {
      this.maxlength = 11;
      if (this.staffType === "details") {
        this.readonlyFlag = true;
      }
      this.formatPhoneOnkeyUp(this.child.value);
    }

    if (this.child.field === "bankCardNum") {
      // this.item.value = "6228 4808 8080 2012 216";
      this.bankCardNum = this.item.value
        .replace(/\D+/g, "")
        .replace(/(\d{4})/g, "$1 ")
        .replace(/ $/, "");
      this.bankDescBlur();
      if (!this.ajaxData.bankName) {
        this.bankBlur();
      }
    }
    if (this.child.field === "bankName") {
      bus.$on("bankName", function (name) {
        that.currentValue = name;
        that.child.value = name;
      });
    }
  },
  methods: {
    changeHeight() {
      let _this = this;
      this.$nextTick(() => {
        var textArea = _this.$refs[this.item.field];
        // console.log(_this.$refs);
        if (textArea) {
          var scrollHeight = textArea.scrollHeight; // 控件所有的高度，包含滚动的那部分(不可见也会有高度)
          var height = textArea.offsetHeight; // 屏幕上显示的高度
          if (height <= scrollHeight) {
            textArea.style.height = "auto !important"; // 恢复默认值，这个作用就是根据内容自适应textarea高度
            // console.log(textArea.scrollHeight);
            textArea.style.height = textArea.scrollHeight + "px"; // 拿到最新的高度改变textarea的高度
          }
        }
      });
    },
    formatPhoneOnkeyUp() {
      return;
      var value = this.child.value.replace(/\D/g, "").substring(0, 11);
      var valueLen = value.length;
      if (valueLen > 3 && valueLen < 8) {
        value = value.replace(/^(...)/g, "$1 ");
      } else if (valueLen >= 8) {
        value = value.replace(/^(...)(....)/g, "$1 $2 ");
      }
      this.child.value = value;
    },
    focus() {
      bus.$emit("hidePopup");
    },
    blur() {
      if (this.child.field === "mobile") {
        let val = this.child.value.replace(/\s+/g, "");
        const regMobile = /^1(3|4|5|6|7|8|9)\d{9}$/;
        if (!regMobile.test(val)) {
          bus.$emit(this.item.field, true);
          this.$store.commit("MyMessage", "手机号不符合规则");
          return;
        } else {
          bus.$emit(this.item.field, false);
        }
      }
      if (
        this.child.field === "idNumber" &&
        this.ajaxData.idType + "" === "1"
      ) {
        let testId = this.$config.testid(this.child.value);
        if (testId.status === 0) {
          this.$store.commit("MyMessage", "身份证格式有误，请重新填写");
          bus.$emit(this.item.field, true);
        } else {
          bus.$emit(this.item.field, false);
        }
      }
      let value = this.child.value;
      if (this.item.type === "textarea") {
        value = this.currentValue;
      }
      if (this.child.field === "bankCardNum") {
        value = this.bankCardNum.replace(/\s+/g, "");
      }
      this.ajaxData[this.child.field] = value.replace(/\s+/g, "");
      let param = {
        field: this.child.field,
        value: value,
        type: this.child.type,
      };
      this.$emit("staffParent", param);
    },
    bankBlur() {
      let bankItem = this.$bank.bankCardAttribution(
        this.bankCardNum.replace(/\s+/g, "")
      );
      if (bankItem !== "error") {
        this.bankDesc = bankItem.cardTypeName;
        this.ajaxData.bankName = bankItem.bankName;
        this.ajaxData.bankCardNum = this.bankCardNum.replace(/\s+/g, "");
      } else {
        this.ajaxData.bankName = "";
        this.bankDesc = "";
      }
      bus.$emit("bankName", bankItem.bankName);

      let value = this.child.value;
      value = this.bankCardNum.replace(/\s+/g, "");
      this.ajaxData[this.child.field] = value;
      let param = {
        field: this.child.field,
        value: value,
        type: this.child.type,
      };
      this.bankCardNum = this.bankCardNum
        .replace(/\D+/g, "")
        .replace(/(\d{4})/g, "$1 ")
        .replace(/ $/, "");
      this.$emit("staffParent", param);
    },
    bankDescBlur() {
      let bankItem = this.$bank.bankCardAttribution(
        this.bankCardNum.replace(/\s+/g, "")
      );
      if (bankItem !== "error") {
        this.bankDesc = bankItem.cardTypeName;
      }
    },
    testid(id) {
      // 1 "验证通过!", 0 //校验不通过 // id为身份证号码
      var format =
        /^(([1][1-5])|([2][1-3])|([3][1-7])|([4][1-6])|([5][0-4])|([6][1-5])|([7][1])|([8][1-2]))\d{4}(([1][9]\d{2})|([2]\d{3}))(([0][1-9])|([1][0-2]))(([0][1-9])|([1-2][0-9])|([3][0-1]))\d{3}[0-9xX]$/;
      //号码规则校验
      if (!format.test(id)) {
        return {
          status: 0,
          msg: "身份证号码不合规",
        };
      }
      //区位码校验
      //出生年月日校验  前正则限制起始年份为1900;
      var year = id.substr(6, 4),
        //身份证年
        month = id.substr(10, 2),
        //身份证月
        date = id.substr(12, 2),
        //身份证日
        time = Date.parse(month + "-" + date + "-" + year),
        //身份证日期时间戳date
        now_time = Date.parse(new Date()),
        //当前时间戳
        dates = new Date(year, month, 0).getDate(); //身份证当月天数
      if (time > now_time || date > dates) {
        return {
          status: 0,
          msg: "出生日期不合规",
        };
      }
      //校验码判断
      var c = new Array(7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2); //系数
      var b = new Array("1", "0", "X", "9", "8", "7", "6", "5", "4", "3", "2"); //校验码对照表
      var id_array = id.split("");
      var sum = 0;
      for (var k = 0; k < 17; k++) {
        sum += parseInt(id_array[k]) * parseInt(c[k]);
      }
      if (id_array[17].toUpperCase() != b[sum % 11].toUpperCase()) {
        return {
          status: 0,
          msg: "身份证校验码不合规",
        };
      }
      return {
        status: 1,
        msg: "校验通过",
      };
    },
  },
  props: {
    item: {
      type: Object,
      default() {
        return null;
      },
    },
    staffType: {
      type: String,
      default() {
        return null;
      },
    },
  },
};
</script>
<style lang="less" scoped>
.addStaff-tips,
input {
  text-align: right;
  width: calc(100%) !important;
  padding-right: 0 !important;
  background: none;
  font: 600 14px Techna;
  line-height: 34px;

  &::placeholder {
    color: #bbbbbb;
    font: 400 14px Techna;
    line-height: 34px;
  }
}
.addStaff-box-right {
  height: auto;
  position: relative;
  .b-span {
    display: inline-block;
    position: absolute;
    right: 0;
    bottom: -16px;
    min-width: 10px;
    color: #999;
    font-size: 12px;
  }
}
.textStyle {
  resize: none;
  text-align: right;
  float: right;
  background: none;
  min-height: 20px;
  max-height: 300px;
  width: 100%;
  height: 30px;
  position: relative;
  top: 5px;
  font: 600 14px Techna;
  // padding-top: 5px;
  &::placeholder {
    color: #bbbbbb;
    font: 400 14px Techna;
  }
}
</style>
<style lang="less">
.addStaff-box-right {
  .el-textarea {
    top: -8px;
    .el-textarea__inner {
      padding: 0;
      font-size: 16px;
      text-align: right;
      padding-right: 0;
      resize: none;
      border: none;
      font-weight: 600;
      background: none;
      font: 600 14px Techna;
      color: #262626;
      &::placeholder {
        font: 400 14px Techna;
        color: #bbbbbb;
      }
    }
  }
}
</style>