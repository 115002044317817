<template>
  <div class="department">
    <div class="department-mian">
      <div class="department-header department-width">
        <div class="department-link" @click="back">
          <i class="el-icon-arrow-left"></i>返回
        </div>
        工资记录
      </div>
      <div class="department-list department-width">
        <div
          class="payrollRecord-main"
          v-if="list.length > 0 || listOld.length > 0"
        >
          <div
            class="payrollRecord-main-box"
            v-for="(item, index) in list"
            :key="index"
            @click="gotoFun(item)"
          >
            <div class="payrollRecord-box-herder">
              <div class="payrollRecord-box-l">{{ item.monthText }}工资表</div>
              <div class="payrollRecord-box-r">
                <slot v-if="item.salaryStatus === -2">
                  <span style="color: #999">算税失败</span>
                </slot>
                <slot v-else-if="item.taxStatus === 1">
                  <span style="color: #bbb">算税中</span>
                </slot>
                <slot
                  v-else-if="item.taxStatus === -1 || item.taxStatus === -2"
                >
                  <span style="color: #999">算税失败</span>
                </slot>

                <slot
                  v-else-if="item.shenpiStatus === 1 || item.shenpiStatus === 4"
                >
                  <span style="color: #4c8afc">未提交审批</span>
                </slot>

                <slot v-else-if="item.shenpiStatus === 2">
                  <span style="color: #4c8afc">审批中</span>
                </slot>
                <slot v-else>
                  <slot v-if="item.baoshuiList[0].status === 30">
                    <span style="color: #4c8afc">未申报</span>
                  </slot>
                  <slot v-else-if="item.baoshuiList[0].status === 40">
                    <span style="color: #4c8afc">待申报</span>
                  </slot>
                  <slot
                    v-else-if="
                      item.baoshuiList[0].status === 50 ||
                      item.baoshuiList[0].status === 41
                    "
                  >
                    <span style="color: #bbbbbb">申报表报送中...</span>
                  </slot>
                  <slot v-if="item.baoshuiList[0].status === 100">
                    <span style="color: #e75850">申报失败</span>
                  </slot>
                  <slot v-else-if="item.baoshuiList[0].status === 60">
                    <!-- <span style="color: #999">已经报税</span> -->
                    <slot v-if="item.baoshuiList[0].payStatus === 300">
                      <slot v-if="item.baoshuiList[0].zuofeiStatus === 501">
                        <span style="color: #bbbbbb">作废申报中 </span>
                      </slot>
                      <span style="color: #70c050" v-else
                        >申报成功
                        <span style="color: #e75850">待缴款</span></span
                      >
                    </slot>
                    <slot v-else-if="item.baoshuiList[0].payStatus === 301">
                      <span style="color: #70c050"
                        >申报成功 <span style="color: #e75850">缴款中</span>
                      </span>
                    </slot>
                    <slot v-else-if="item.baoshuiList[0].payStatus === 302">
                      <span style="color: #70c050">申报成功 缴款成功</span>
                    </slot>
                    <slot v-else-if="item.baoshuiList[0].payStatus === 305">
                      <span style="color: #70c050">申报成功 无需缴款</span>
                    </slot>
                    <slot v-else>
                      <span style="color: #70c050"
                        >申报成功 <span style="color: #e75850">缴款失败</span>
                      </span>
                    </slot>
                  </slot>
                </slot>
              </div>
            </div>
            <div class="payrollRecord-box-content">
              <p style="display: inline-block; width: 100%; height: 16px">
                <!-- 合计人力成本 -->
              </p>
              <div class="payrollRecord-box-l">{{ item.personCount }}人</div>
              <div
                class="payrollRecord-box-r"
                style="
                  color: rgb(245, 142, 33);
                  font-size: 14px;
                  font-weight: 400;
                "
                v-if="item.huanfa === 1"
              >
                缓发
              </div>
            </div>
          </div>
          <div
            class="payrollRecord-main-box"
            v-for="(item, index) in listOld"
            :key="index + '-old'"
            @click="gotoFunOld(item)"
            style="position: relative; top: -2px"
          >
            <div class="payrollRecord-box-herder">
              <div class="payrollRecord-box-l">
                {{
                  item.belongMonth.split("-")[0] +
                  "年" +
                  item.belongMonth.split("-")[1] +
                  "月"
                }}工资表
              </div>
              <div class="payrollRecord-box-r">
                <slot v-if="item.status === 2">
                  <span style="color: #f58e21">待审批</span>
                </slot>
                <slot v-else-if="item.status === 4">
                  <span style="color: #e74b47">驳回</span>
                </slot>
                <slot v-else-if="item.status === 5">
                  <span style="color: #999">审批通过</span>
                </slot>
                <slot v-else-if="item.status === 6">
                  <span style="color: rgb(112, 192, 80)"
                    >申报成功 缴款成功</span
                  >
                </slot>
                <slot v-else><span style="color: #999">--</span></slot>
              </div>
            </div>
            <div class="payrollRecord-box-content">
              <p style="display: inline-block; width: 100%; height: 16px">
                合计人力成本
              </p>
              <div class="payrollRecord-box-l">¥{{ item.manpower }}</div>
              <div class="payrollRecord-box-r">{{ item.totalCount }}人</div>
            </div>
          </div>
        </div>
        <NoRecord v-if="list.length <= 0 && listOld.length <= 0"></NoRecord>
      </div>
      <div class="clear"></div>
      <TheFooter></TheFooter>
    </div>
    <transitionPage1
      v-if="tPage1"
      :month="tPageDate"
      :batch="batch"
      @hideLayer="hideLayer"
    ></transitionPage1>
  </div>
</template>

<script>
import { salaryRecord, queryMonthSalaryListV2 } from "@/http/api";
import NoRecord from "@/components/NoRecord.vue";
import TheFooter from "@/components/TheFooter.vue";
import transitionPage1 from "./components/transitionPage1";
export default {
  data() {
    return {
      userId: sessionStorage.getItem("userId"),
      enterpriseId: sessionStorage.getItem("enterpriseId"),
      list: [],
      listOld: [],
      tPage1: false,
      batch: "",
      tPageDate: "",
    };
  },
  mounted() {
    this.queryMonthSalaryListV2();
    this.salaryRecord();
  },

  methods: {
    hideLayer() {
      this.tPage1 = false;
      this.queryMonthSalaryListV2();
      this.$forceUpdate();
    },
    // 工资记录
    queryMonthSalaryListV2() {
      let param = {
        enterpriseId: this.enterpriseId,
        userId: this.userId,
      };
      queryMonthSalaryListV2(param).then((res) => {
        if (res.code === 0) {
          console.log(res.data);
          this.list = res.data;
          this.list.forEach((e) => {
            e.monthText =
              (e.month + "").substring(0, 4) +
              "年" +
              (e.month + "").substring(4, 6) +
              "月";
          });
        } else {
          this.$store.commit("MyMessage", res.msg);
        }
      });
    },
    //返回
    back() {
      this.$router.push("/payrollModule");
    },
    gotoFun(item) {
      item.month = item.month + "";
      let pathText = "";
      // pathText = "/generatePayroll";
      // this.$router.push({
      //   path: pathText,
      //   query: {
      //     batch: item.batch,
      //     month: item.month.substr(0, 4) + "-" + item.month.substr(4),
      //   },
      // });
      // return
      if (item.taxStatus === -2) {
        //算税失败
        //    batch: "",
        // tPageDate: "",

        //pathText = "/generatePayroll";

        this.batch = item.batch;
        this.tPageDate = item.month.substr(0, 4) + "-" + item.month.substr(4);
        // pathText = "/transitionPage1";
        this.tPage1 = true;
        return false;
      } else if (item.taxStatus === 2) {
        if (
          item.baoshuiList[0].status === 30 ||
          item.baoshuiList[0].status === 40 ||
          item.baoshuiList[0].status === 60
        ) {
          if (item.baoshuiList[0].payStatus === 301) {
            pathText = "/generatePayroll";
          } else {
            pathText = "/generatePayroll";
          }
        } else if (item.baoshuiList[0].status === 100) {
          pathText = "/generatePayroll";
        } else {
          pathText = "/generatePayroll";
        }
      } else {
        this.batch = item.batch;
        this.tPageDate = item.month.substr(0, 4) + "-" + item.month.substr(4);
        // pathText = "/transitionPage1";
        this.tPage1 = true;
        return false;
      }
      this.$router.push({
        path: pathText,
        query: {
          batch: item.batch,
          month: item.month.substr(0, 4) + "-" + item.month.substr(4),
        },
      });
    },
    gotoFunOld(item) {
      this.$router.push({
        path: "/generatePayrollOld",
        query: {
          batch: item.batch,
          mouth: item.belongMonth,
          sText: item.status === 6 ? "60" : 1,
        },
      });
    },
    salaryRecord() {
      let param = {
        enterpriseId: this.enterpriseId,
        pageNum: "1",
        pageSize: "999",
      };
      salaryRecord(param).then((res) => {
        console.log(res);
        // this.listOld = res.data;
        let array = [];
        if (res.code === 0) {
          if (res.data.length > 0) {
            res.data.forEach((element) => {
              if (element.belongMonth.split("-")[0] * 1 <= 2021) {
                if (element.belongMonth.split("-")[0] * 1 === 2021) {
                  if (element.belongMonth.split("-")[1] * 1 < 12) {
                    array.push(element);
                  }
                } else {
                  array.push(element);
                }
              }
              // element.manpower = element.manpower.toFixed(2);
            });
            this.listOld = array;
            this.$forceUpdate();
          }
        } else {
          this.$store.commit("MyMessage", res.msg);
        }
      });
    },
  },
  components: {
    TheFooter,
    NoRecord,
    transitionPage1,
  },
};
</script>

<style lang="less" scoped>
.department .department-list {
  padding-top: 18px;
  min-height: calc(100% - 158px);
}
.department-width {
  width: 1328px;
  margin: 0 auto;
}
.payrollRecord-main {
  width: 100%;
  margin: 25px 0;
  text-align: left;
  .payrollRecord-main-box {
    width: 307px;
    height: 120px;
    background: #ffffff;
    border: 1px solid #dbdbdb;
    opacity: 1;
    border-radius: 6px;
    display: inline-block;
    margin-left: 30px;
    margin-bottom: 18px;
    cursor: pointer;
    &:first-child {
      margin-left: 0;
    }
    &:nth-child(4n + 1) {
      margin-left: 0;
    }
    .payrollRecord-box-herder {
      width: calc(100% - 42px);
      height: 47px;
      line-height: 47px;
      background: #f4f4f4;
      opacity: 1;
      border-radius: 4px 4px 0px 0px;
      font-size: 17px;
      font-weight: bold;
      color: #262626;
      padding: 0 18px 0 24px;
    }
    .payrollRecord-box-content {
      width: calc(100% - 42px);
      font-size: 20px;
      font-weight: bold;
      color: #262626;
      padding: 0 18px 0 24px;
      p {
        padding: 8px 0 4px;
        font-size: 12px;
        font-weight: 400;
        color: #262626;
      }
      .payrollRecord-box-r {
        color: #262626;
        font-size: 20px;
        font-weight: bold;
      }
    }
    .payrollRecord-box-l {
      text-align: left;
      display: inline-block;
      width: 56%;
    }
    .payrollRecord-box-r {
      text-align: right;
      // display: inline-block;
      width: 44%;
      font-size: 12px;
      font-weight: 400;
      color: #bbbbbb;
      opacity: 1;
      float: right;
    }
  }
}
</style>
