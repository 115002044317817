<template>
  <div class="declare-popup">
    <div class="declare-main">
      <div class="department-header department-width">
        <div class="department-link" @click="childClose">
          <i class="el-icon-arrow-left"></i>返回
        </div>
        继续教育
        <div class="department-btn">
          <i class="el-icon-info" @click="showPopup3 = true"></i>
          <!-- <div class="addStaff-btn" @click="submitFun">保 存</div> -->
        </div>
      </div>
      <div class="declare-main-content">
        <div class="declare-list">
          <div class="declare-box">
            <div class="declare-box-l">手机号码</div>
            <div class="declare-box-r">
              <input type="text" v-model="item.mobile" placeholder="请输入" />
            </div>
          </div>
          <div class="declare-box">
            <div class="declare-box-l">电子邮箱</div>
            <div class="declare-box-r">
              <input type="text" v-model="item.email" placeholder="请输入" />
            </div>
          </div>
          <div class="declare-box">
            <div class="declare-box-l">联系地址</div>
            <div class="declare-box-r">
              <el-input
                type="textarea"
                :autosize="{ minRows: 1, maxRows: 5 }"
                v-model="item.address"
                class="textStyle"
                placeholder="请输入"
              >
              </el-input>
            </div>
          </div>
        </div>
        <div class="declare-list">
          <div class="declare-box">
            <div class="declare-box-l">选择扣除年度</div>
            <div class="declare-box-r">
              <el-select v-model="item.deductYear" placeholder="请选择">
                <el-option
                  v-for="item in options1"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="declare-box">
            <div class="declare-box-l">继续教育类型</div>
            <div class="declare-box-r">
              <el-select v-model="item.jixu" placeholder="请选择">
                <el-option
                  v-for="item in options3"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
          </div>
          <slot v-if="item.jixu === '1'">
            <div class="declare-box">
              <div class="declare-box-l">继续教育阶段</div>
              <div class="declare-box-r">
                <el-select v-model="item.jixuStage" placeholder="请选择">
                  <el-option
                    v-for="item in options4"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>
            <div class="declare-box">
              <div class="declare-box-l">开始时间</div>
              <div class="declare-box-r">
                <date-picker
                  v-model="item.start"
                  value-type="format"
                  format="YYYY-MM-DD"
                  class="data-picker-style"
                  :clearable="false"
                  :editable="false"
                  placeholder="受教育开始时间"
                ></date-picker>
                <i class="el-icon-arrow-down"></i>
              </div>
            </div>
            <div class="declare-box">
              <div class="declare-box-l">结束时间</div>
              <div class="declare-box-r">
                <date-picker
                  v-model="item.end"
                  value-type="format"
                  format="YYYY-MM-DD"
                  class="data-picker-style"
                  :clearable="false"
                  :editable="false"
                  placeholder="选填"
                ></date-picker>
                <i class="el-icon-arrow-down"></i>
              </div>
            </div>
          </slot>
          <slot v-if="item.jixu === '2'">
            <div class="declare-box">
              <div class="declare-box-l">职业资格教育类型</div>
              <div class="declare-box-r">
                <el-select v-model="item.jixuType" placeholder="请选择">
                  <el-option
                    v-for="item in options5"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>
            <div class="declare-box">
              <div class="declare-box-l">发证(批准)日期</div>
              <div class="declare-box-r">
                <date-picker
                  v-model="item.certification"
                  value-type="format"
                  format="YYYY-MM-DD"
                  class="data-picker-style"
                  :clearable="false"
                  :editable="false"
                  placeholder="受教育开始时间"
                ></date-picker>
                <i class="el-icon-arrow-down"></i>
              </div>
            </div>
            <div class="declare-box">
              <div class="declare-box-l">证书编号</div>
              <div class="declare-box-r">
                <input
                  type="text"
                  v-model="item.certificationNo"
                  placeholder="请输入"
                />
              </div>
            </div>
            <div class="declare-box">
              <div class="declare-box-l">证书名称</div>
              <div class="declare-box-r">
                <el-select
                  v-model="item.certificationName"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in options6"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>

            <div class="declare-box">
              <div class="declare-box-l">发证机关</div>
              <div class="declare-box-r">
                <input
                  type="text"
                  v-model="item.certificationOffice"
                  placeholder="请输入"
                />
              </div>
            </div>
          </slot>
        </div>
        <div class="declare-list check-list">
          <div class="declare-title">您的扣缴义务人</div>
          <div class="declare-box" @click="check = 1">
            <p class="p1">唐山市航达商贸有限公司1</p>
            <p class="p2">主管税务机关：国家税务总局唐山市路北区税务局</p>
            <p class="p2">地址：唐山丰润区曹雪芹西大街（三丰小区）102</p>
            <i class="el-icon-check" v-if="check === 1"></i>
          </div>
          <div class="declare-box" @click="check = 2">
            <p class="p1">唐山市航达商贸有限公司2</p>
            <p class="p2">主管税务机关：国家税务总局唐山市路北区税务局</p>
            <p class="p2">地址：唐山丰润区曹雪芹西大街（三丰小区）102</p>
            <i class="el-icon-check" v-if="check === 2"></i>
          </div>
        </div>
        <div class="declare-btns">
          <el-button type="primary" @click="saveFun">保 存</el-button>
        </div>
      </div>
    </div>
    <div class="popup-layer" v-if="showPopup3">
      <div class="popup-layer-bg" @click="showPopup3 = false"></div>
      <div class="popup-zinv tishi-popup">
        <div class="popup-layer-close" @click="showPopup3 = false">
          <svg-icon icon-class="popup-close"></svg-icon>
        </div>
        <div class="popup-zinv-tishi">
          <div class="tishi-title">继续教育填报提示</div>
          <div class="tishi-p1">
            纳税人在中国境内接受学历（学位）继续教育的支出,在学历（学位）教育期间按照每月400元定额扣除。同一学历（学位）继续教育的扣除期限不能超过48个月。
          </div>
          <div class="tishi-p1">
            纳税人接受技能人员职业资格继续教育、专业技术人员职业资格继续教育的支出,在取得相关证书的当年,按照3600元定额扣除。
          </div>
          <div class="tishi-p2">温馨提示：</div>
          <div class="tishi-p1">
            不在《国家职业资格目录》内的不属于可扣除的职业资格继续教育
          </div>
        </div>
        <div class="popup-tishi-footer">
          <div class="zinv-btn" @click="showPopup3 = false">我知道了</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/zh-cn";
export default {
  data() {
    return {
      showPopup1: false,
      showPopup2: false,
      showPopup3: false,
      item: {
        mobile: "",
        email: "",
        address: "",
        deductYear: "",
        jixu: "",
        //  1
        jixuStage: "",
        start: "",
        end: "",
        //  2
        jixuType: "",
        certification: "",
        certificationNo: "",
        certificationName: "",
        certificationOffice: "",
      },
      type: "",
      value: "13812345678",
      check: "",

      options: [],
      options1: [
        {
          value: "2020",
          label: "2020",
        },
        {
          value: "2021",
          label: "2021",
        },
      ],
      options2: [
        {
          value: "50",
          label: "50%平均扣除",
        },
        {
          value: "100",
          label: "100%全额扣除",
        },
      ],
      options3: [
        {
          value: "1",
          label: "学历(学位)继续教育",
        },
        {
          value: "2",
          label: "职业资格继续教育",
        },
      ],
      options4: [
        {
          value: "1",
          label: "博士研究生",
        },
        {
          value: "2",
          label: "硕士研究生",
        },
        {
          value: "3",
          label: "大学本科",
        },
        {
          value: "4",
          label: "大学专科",
        },
        {
          value: "5",
          label: "其他",
        },
      ],
      options5: [
        {
          value: "1",
          label: "技能人员职业资格",
        },
        {
          value: "2",
          label: "专业技术人员职业资格",
        },
      ],
      options6: [],
    };
  },
  components: {
    DatePicker,
  },
  props: {
    dateList: {
      type: Array,
      default() {
        return null;
      },
    },
  },
  mounted() {
    this.options1 = this.dateList;
  },
  methods: {
    saveFun() {
      this.childClose();
    },
    childClose() {
      this.$emit("closeFun");
    },
  },
};
</script>

<style lang="less" scoped>
.department-width {
  width: 480px;
  margin: 0 auto;
}

.popup-layer {
  z-index: 999;
}
</style>
