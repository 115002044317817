<template>
  <div
    class="staff-popup fadeIn"
    :style="{
      left: popupLeft + 'px',
      top: popupTop + 'px',
      bottom: popupBottom + 'px',
    }"
    @mouseleave="cascadeClose"
  >
    <Triangle :direction="direction" left="290" color="#cccccc"></Triangle>
    <div class="staff-popup-header">
      请给<span>{{ SalaryStaff.nickName }}</span
      >设置补助补贴
    </div>
    <div class="staff-popup-content" id="staffContent">
      <div
        class="staff-popup-box"
        v-for="(item, i) in arrayList"
        :key="i"
        @click.stop="listClick(item)"
      >
        <div class="staff-popup-box-l">
          <svg-icon
            v-if="item.select"
            icon-class="duigou-yes"
            class="duigou"
          ></svg-icon>
          <svg-icon
            v-if="!item.select"
            icon-class="duigou-no"
            class="duigou"
          ></svg-icon>
        </div>
        <div class="staff-popup-box-r">
          <input
            type="text"
            class="ipt1"
            @click.stop=""
            v-model="item.subsidyName"
            placeholder="补贴名称"
          />
          <input
            type="text"
            class="ipt2"
            @click.stop=""
            v-model="item.subsidyMoney"
            placeholder="金额"
          />
        </div>
      </div>
      <div class="staff-popup-box" @click.stop="addSubsidy()">
        <div class="staff-popup-box-l">
          <svg-icon icon-class="duigou-no" class="duigou"></svg-icon>
        </div>
        <div class="staff-popup-box-r">
          <input
            type="text"
            class="ipt1"
            @click.stop=""
            v-model="subsidyName"
            placeholder="补贴名称"
          />
          <input
            type="text"
            class="ipt2"
            @click.stop=""
            v-model="subsidyMoney"
            placeholder="金额"
          />
        </div>
      </div>
    </div>
    <div class="staff-popup-footer">
      <div class="footer-btn1" @click.stop="submit">确定</div>
      <div class="footer-btn2" @click.stop="cascadeClose">取消</div>
    </div>
  </div>
</template>

<script>
import { queryBuzhuButie, addSubsidy, cancelSubsidy } from "@/http/api";
import bus from "@/util/bus";
export default {
  data() {
    return {
      userId: sessionStorage.getItem("userId"),
      enterpriseId: sessionStorage.getItem("enterpriseId"),
      child: {},
      value1: "",
      showIcon: true,
      popupShow: false,
      openDates: false,
      showText: null,
      dimissionTime: "",
      name: "员工",
      arrayList: [],
      subsidyName: "",
      subsidyMoney: "",
      subsidyList: [],
    };
  },
  components: {},
  mounted() {
    // this.child = this.item;
    // console.log(this.popupLeft);
    // console.log(this.popupTop);
    // console.log(this.popupBottom);
    this.queryBuzhuButie();

    // this.arrayList = JSON.parse(sessionStorage.getItem("buzhuButie"));
    // this.buzhuButie();
  },
  methods: {
    cascadeClose() {
      this.$emit("cascadeClose");
    },
    submit() {
      this.showText = 0;
      this.arrayList.forEach((element) => {
        if (element.select) {
          // let _id = this.userId;
          // if (this.staffType === "details") {
          //   _id = this.SalaryStaff.userId;
          // }
          this.showText += element.subsidyMoney * 1;
          let param = {
            subsidyName: element.subsidyName,
            subsidyAmt: element.subsidyMoney * 1,
            enterpriseId: this.enterpriseId,
            userId: this.SalaryStaff.userId,
          };
          if (element.type === "this") {
            param.subsidyId = element.id;
          }

          this.subsidyFun(param);
        }
      });
      this.$emit("subsidyFun", this.showText);
    },
    subsidyFun(param) {
      addSubsidy(param).then((res) => {
        console.log(res);
        if (res.code === 0) {
          this.queryBuzhuButie();
        } else {
          this.$store.commit("MyMessage", res.msg);
        }
      });
    },
    addSubsidy() {
      if (!this.subsidyName) {
        this.$store.commit("MyMessage", "补贴名称不能为空");
        return;
      }
      if (!this.subsidyMoney) {
        this.$store.commit("MyMessage", "补贴金额不能为空");
        return;
      }
      this.arrayList.push({
        subsidyName: this.subsidyName,
        subsidyMoney: this.subsidyMoney,
        select: true,
        type: "add",
      });
      this.subsidyName = "";
      this.subsidyMoney = "";
      this.$nextTick(() => {
        let staffContent = document.getElementById("staffContent");
        staffContent.scrollTop = staffContent.scrollHeight;
      });
    },
    cancelSubsidyFun(item) {
      let that = this;
      let param = {
        userId: this.SalaryStaff.userId,
        enterpriseId: this.SalaryStaff.enterpriseId,
        subsidyIdEnterprise: item.id,
      };
      cancelSubsidy(param).then((res) => {
        if (res.code == 0) {
          this.queryBuzhuButie("show");
        }
      });
    },
    buzhuButie(type, res) {
      this.arrayList.forEach((element) => {
        element.select = false;
        element.type = "this";
        element.subsidyMoney = "";
      });
      this.showText = 0;
      this.subsidyList = res.data.subsidy;

      if (this.subsidyList.length > 0) {
        this.subsidyList.forEach((e1) => {
          this.showText += e1.subsidyMoney;
          this.arrayList.forEach((e2) => {
            if (e1.subsidyIdEnterprise === e2.id) {
              e2.subsidyMoney = e1.subsidyMoney;
              e2.select = true;
            }
          });
        });
      }
      if (this.showText === 0) {
        this.showText = null;
      }
      if (type === "show") {
        this.popupShow = true;
      }
    },
    queryBuzhuButie(type) {
      let that = this;
      let param = {
        userId: this.SalaryStaff.userId,
        enterpriseId: this.SalaryStaff.enterpriseId,
      };
      queryBuzhuButie(param, true).then((res) => {
        // console.log("res", res);
        if (res.code === 0) {
          this.arrayList = res.data.subsidyCompany;
          sessionStorage.setItem("buzhuButie", JSON.stringify(this.arrayList));
          this.buzhuButie(type, res);
        }
      });
    },
    listClick(item) {
      if (!item.select) {
        if (!item.subsidyName) {
          this.$store.commit("MyMessage", "补贴名称不能为空");
          return;
        }
        if (!item.subsidyMoney) {
          this.$store.commit("MyMessage", "补贴金额不能为空");
          return;
        }
      }
      item.select = !item.select;

      if (!item.select) {
        this.cancelSubsidyFun(item);
      }
      this.$forceUpdate();
    },
  },
  props: {
    SalaryStaff: {
      type: Object,
      default() {
        return null;
      },
    },
    popupTop: {
      type: Number,
      default() {
        return null;
      },
    },
    popupLeft: {
      type: Number,
      default() {
        return null;
      },
    },
    popupBottom: {
      type: Number,
      default() {
        return null;
      },
    },
    direction: {
      type: String,
      default() {
        return "top";
      },
    },
  },
};
</script>
<style lang="less" scoped>
.staff-popup-box-r {
  .ipt1 {
    width: 46% !important;
    float: left;
    text-align: left;
  }
  .ipt2 {
    width: 46% !important;
    float: right;
    text-align: right;
  }
}
.staff-popup-content {
  max-height: 290px;
}
</style>