<template>
  <div class="department">
    <div
      class="department-mian"
      id="views-mian-scroll"
      :class="{ overflowStyle: overflowStyle }"
    >
      <div class="department-header department-width">
        <div class="department-link" @click="gotoFun('/roster')">
          <i class="el-icon-arrow-left"></i>返回
        </div>
        {{ staffTitle }}
        <div class="department-right">
          <div class="addStaff-text">
            <span class="redColor" style="padding-right: 0"
              >离职(停薪)日期: {{ enterpriseEmployee.dimissionTime }}</span
            >
          </div>
        </div>
      </div>
      <div class="department-list department-width" v-if="addStaffShow">
        <div class="addStaff-main quitStaff">
          <div class="">
            <div class="addStaff-list">
              <p class="list-title-style">主要信息</p>
              <slot v-for="(e, i1) in employee.zhuyao">
                <StaffBox
                  :key="'zhuyao-' + i1"
                  :item="e"
                  index1="0"
                  :index2="i1"
                  :ajaxData="enterpriseEmployee"
                  :staffType="staffType"
                ></StaffBox>
              </slot>
              <slot v-if="enterpriseEmployee.idType > 1">
                <p class="list-title-style marTop">涉外信息</p>
                <slot v-for="(e, i1) in employee.shewai">
                  <StaffBox
                    :key="'shewai-' + i1"
                    :item="e"
                    index1="0"
                    :index2="i1"
                    :ajaxData="enterpriseEmployee"
                    :staffType="staffType"
                  ></StaffBox>
                </slot>
              </slot>
            </div>
            <div class="addStaff-list">
              <p class="list-title-style">基本信息</p>
              <slot v-for="(e, i1) in jiben">
                <StaffBox
                  :key="'jiben-' + i1"
                  :item="e"
                  index1="0"
                  :index2="i1"
                  :ajaxData="enterpriseEmployee"
                  :staffType="staffType"
                ></StaffBox>
              </slot>
            </div>
            <div class="addStaff-list">
              <p class="list-title-style">岗位信息</p>
              <slot v-for="(e, i1) in employee.gangwei">
                <StaffBox
                  :key="'gangwei-' + i1"
                  :item="e"
                  index1="0"
                  :index2="i1"
                  :ajaxData="enterpriseEmployee"
                  :staffType="staffType"
                ></StaffBox>
              </slot>
              <p class="list-title-style marTop">工资卡信息</p>
              <slot v-for="(e, i1) in employee.gongzika">
                <slot
                  v-if="
                    e.field === 'status' && enterpriseEmployee.isAdmin === 1
                  "
                ></slot>
                <StaffBox
                  v-else
                  :key="'gongzika-' + i1"
                  :item="e"
                  index1="0"
                  :index2="i1"
                  :ajaxData="enterpriseEmployee"
                  :staffType="staffType"
                ></StaffBox>
              </slot>
            </div>
          </div>
          <div class="clear"></div>
          <div class="addStaff-line-box">
            <div class="addStaff-line-btn" @click="isShow = !isShow">
              更多信息
              <i class="el-icon-arrow-down" :class="{ isRotate: !isShow }"></i>
            </div>
          </div>
          <div style="overflow: hidden; margin-bottom: 30px" v-if="isShow">
            <div class="addStaff-list addStaff-list-bottom">
              <slot v-for="(e, i1) in qita">
                <StaffBox
                  v-if="i1 < 3"
                  :key="'info1-' + i1"
                  :item="e"
                  index1="0"
                  :index2="i1"
                  :ajaxData="enterpriseEmployee"
                  :staffType="staffType"
                ></StaffBox>
              </slot>
            </div>
            <div class="addStaff-list addStaff-list-bottom">
              <!-- 残疾  烈属 -->
              <slot v-for="(e, i1) in qita">
                <slot v-if="i1 >= 3 && i1 < qita.length - 3">
                  <slot v-if="e.field === 'disabilityNum'">
                    <StaffBox
                      v-if="qita[i1 - 1].value === '1'"
                      :key="'info2-' + i1"
                      :item="e"
                      index1="0"
                      :index2="i1"
                      :ajaxData="enterpriseEmployee"
                      :staffType="staffType"
                    ></StaffBox>
                  </slot>
                  <slot v-else-if="e.field === 'martyrFamilyNum'">
                    <StaffBox
                      v-if="qita[i1 - 1].value === '1'"
                      :key="'info2-' + i1"
                      :item="e"
                      index1="0"
                      :index2="i1"
                      :ajaxData="enterpriseEmployee"
                      :staffType="staffType"
                    ></StaffBox>
                  </slot>
                  <slot v-else>
                    <StaffBox
                      :key="'info2-' + i1"
                      :item="e"
                      index1="0"
                      :index2="i1"
                      :ajaxData="enterpriseEmployee"
                      :staffType="staffType"
                    ></StaffBox>
                  </slot>
                </slot>
              </slot>
            </div>
            <div class="addStaff-list addStaff-list-bottom">
              <slot v-for="(e, i1) in qita">
                <StaffBox
                  v-if="i1 >= qita.length - 3"
                  :key="'info3-' + i1"
                  :item="e"
                  index1="0"
                  :index2="i1"
                  :ajaxData="enterpriseEmployee"
                  :staffType="staffType"
                ></StaffBox>
              </slot>
            </div>
          </div>
          <div class="clear"></div>
        </div>
        <div class="clear"></div>
        <div class="clear"></div>
        <div class="department-btn" v-if="0">
          <div class="addStaff-btn huifuStyle" @click="huifuFun">恢复在职</div>
          <!-- <div class="addStaff-btn" @click="addStaffSave">添 加</div> -->
        </div>
      </div>
      <div class="clear"></div>
      <TheFooter></TheFooter>
    </div>
    <div class="popup-layer" v-if="huifuFlag">
      <div class="popup-layer-bg" @click="rosterClose"></div>
      <div class="huifuPopup">
        <div class="popup-layer-close" @click="rosterClose">
          <svg-icon icon-class="popup-close"></svg-icon>
        </div>
        <div class="huifuPopup-content">
          <p class="huifuPopup-p1">是否确定恢复员工在职状态</p>
          <p class="huifuPopup-p2">离职信息与入职信息会保留继续延续</p>
          <p class="huifuPopup-p2">有信息变动请手动修改</p>
          <div class="huifuPopup-btns">
            <div class="huifuPopup-btn-l" @click="rosterClose">取 消</div>
            <div class="huifuPopup-btn-r" @click="rosterClose">确 定</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import bus from "@/util/bus";
import {
  getEmployeeInfo,
  prefectEnterpriseEmployee,
  modifyEnterpriseEmployee,
  checkApplyJoinEnterprise,
} from "@/http/api";
import TheFooter from "@/components/TheFooter.vue";
import StaffBox from "./components/StaffBox";
export default {
  data() {
    return {
      isShow: false,
      addStaffShow: false,
      overflowStyle: false,
      huifuFlag: false,
      userId: sessionStorage.getItem("userId"),
      enterpriseId: sessionStorage.getItem("enterpriseId"),
      employeeDate: this.$config.cloneObj(
        this.$dictionaries.employeeDateOld.child1
      ),

      employee: this.$config.cloneObj(this.$dictionaries.employeeDateOld),
      jiben: this.$config.cloneObj(this.$dictionaries.employeeDateOld.jiben),
      qita: this.$config.cloneObj(this.$dictionaries.employeeDateOld.qita1),
      enterpriseEmployee: {},
      staffType: "quitStaff",
      staffTitle: "",
      status: "",
    };
  },
  mounted() {
    let _route = this.$route.query;
    if (_route.uid) {
      this.getEmployeeInfo(_route.uid, _route.eid);
    }
    setInterval(() => {
      this.overflowStyle = this.$config.hasScrolled(
        "views-mian-scroll",
        "horizontal"
      );
    }, 1000);
  },
  methods: {
    huifuFun() {
      this.huifuFlag = true;
    },
    rosterClose() {
      this.huifuFlag = false;
    },
    // 获取员工信息
    getEmployeeInfo(uid, eid) {
      let param = {
        enterpriseId: this.enterpriseId,
        userId: uid,
        employeeId: eid,
      };
      getEmployeeInfo(param).then((res) => {
        console.log(res);
        if (res.code === 0) {
          this.enterpriseEmployee = res.data;
          if (this.enterpriseEmployee.dimissionTime) {
            this.enterpriseEmployee.dimissionTime =
              this.enterpriseEmployee.dimissionTime.split(" ")[0];
          }
          this.enterpriseEmployee.mobile =
            this.enterpriseEmployee.mobile.replace(/\s+/g, "");
          this.enterpriseEmployee.beizhu = "";
          if (!this.enterpriseEmployee.idType) {
            this.enterpriseEmployee.idType = 1;
          }

          this.status = res.data.status;
          if (this.enterpriseEmployee.nationality + "" === "") {
            this.enterpriseEmployee.nationality = "1";
          }
          if (this.enterpriseEmployee.sssy + "" === "0") {
            this.enterpriseEmployee.sssy = "";
          }
          if (this.enterpriseEmployee.education + "" === "0") {
            this.enterpriseEmployee.education = "";
          }
          if (!this.enterpriseEmployee.jobTime) {
            // this.staffType = "add";
            this.enterpriseEmployee.jobTime = "";
            // this.enterpriseEmployee.onTrialTermSalaryScale = "";
          }

          this.staffTitle = this.enterpriseEmployee.name + " 个人信息";
          if (!this.enterpriseEmployee.identity) {
            this.enterpriseEmployee.identity = 1;
          }
          if (!this.enterpriseEmployee.contractType) {
            this.enterpriseEmployee.contractType = 3;
          }

          // 获取数据后进行赋值
          this.assignmentFun();
          this.$nextTick(() => {
            this.addStaffShow = true;
          });
        }
      });
    },
    // 数据赋值
    assignmentFun() {
      let that = this;

      if (this.enterpriseEmployee.idType + "" === "1") {
        that.jiben = that.$config.cloneObj(
          that.$dictionaries.employeeDateOld.jiben
        );
      } else {
        that.jiben = that.$config.cloneObj(
          that.$dictionaries.employeeDateOld.jiben2
        );
        that.qita = that.$config.cloneObj(
          that.$dictionaries.employeeDateOld.qita2
        );
        that.jiben[0].value = this.enterpriseEmployee.idType;
        if (this.enterpriseEmployee.idType + "" === "2") {
          that.jiben[2].value = "1";
          that.jiben[2].arrayList = this.$dictionaries.countryData;
        } else if (this.enterpriseEmployee.idType + "" === "3") {
          that.jiben[2].arrayList = this.$dictionaries.gangao;
          that.jiben[2].value = "4";
        } else if (this.enterpriseEmployee.idType + "" === "4") {
          that.jiben[2].arrayList = this.$dictionaries.taiwan;
          that.jiben[2].value = "3";
        } else {
          that.jiben[2].arrayList = this.$dictionaries.countryData;
          that.jiben[2].value = "";
        }
      }

      this.employee.zhuyao.forEach((e, v) => {
        if (e.field === "contactProvince") {
          e.desc1 = this.enterpriseEmployee.contactProvince;
          e.desc2 = this.enterpriseEmployee.contactCity;
          e.desc3 = this.enterpriseEmployee.contactArea;
          e.value1 = this.enterpriseEmployee.contactProvinceId;
          e.value2 = this.enterpriseEmployee.contactCityId;
          e.value3 = this.enterpriseEmployee.contactAreaId;
        } else {
          e.value = this.enterpriseEmployee[e.field] + "";
        }
      });
      this.employee.gangwei.forEach((e, v) => {
        if (e.field === "onTrialTermBegin") {
          e.onTrialTermBegin = this.enterpriseEmployee.onTrialTermBegin;
          e.onTrialTermEnd = this.enterpriseEmployee.onTrialTermEnd;
        } else {
          e.value = this.enterpriseEmployee[e.field] + "";
        }
      });
      this.jiben.forEach((e, v) => {
        if (e.field === "province") {
          e.desc1 = this.enterpriseEmployee.province;
          e.desc2 = this.enterpriseEmployee.city;
          e.desc3 = this.enterpriseEmployee.area;
          e.value1 = this.enterpriseEmployee.provinceId;
          e.value2 = this.enterpriseEmployee.cityId;
          e.value3 = this.enterpriseEmployee.areaId;
        } else {
          e.value = this.enterpriseEmployee[e.field] + "";
        }
      });
      this.employee.gongzika.forEach((e, v) => {
        e.value = this.enterpriseEmployee[e.field] + "";
      });
      this.employee.shewai.forEach((e, v) => {
        e.value = this.enterpriseEmployee[e.field] + "";
      });
      this.qita.forEach((e, v) => {
        if (e.field === "idcardImgFront") {
          e.positive = this.enterpriseEmployee.idcardImgFront;
          e.back = this.enterpriseEmployee.idcardImgReverse;
        } else if (e.field === "degreeImg") {
          e.positive = this.enterpriseEmployee.degreeImg;
          e.back = this.enterpriseEmployee.diplomaImg;
        } else {
          e.value = this.enterpriseEmployee[e.field] + "";
        }
      });

      this.$forceUpdate();
    },

    gotoFun(path) {
      this.$router.push(path);
    },
  },
  components: {
    TheFooter,
    StaffBox,
  },
};
</script>

<style lang="less" scoped>
.huifuStyle {
  width: 680px;
  height: 54px;
  line-height: 54px;
  background: #e7eaec;
  opacity: 1;
  border-radius: 3px;
  font-size: 18px;
  font-weight: bold;
  color: #1a73e8;
  border: 1px solid #e7eaec;
  margin: 0 auto 50px !important;

  &:hover {
    color: #1a73e8;
    background: #e7eaec;
    border: 1px solid #e7eaec;
  }
}
.popup-layer {
  z-index: 9999999;
}

.department-btn {
  position: relative;
  top: 0;
  right: 0;
  text-align: right;
  text-align: center;
  .addStaff-btn {
    margin: 0;
    padding: 0;
  }
}
.addStaff-text {
  cursor: default;
}
.department .department-list {
  padding-top: 18px;
  min-height: calc(100% - 158px);
}
.department-width {
  width: 1328px;
  margin: 0 auto;
}
.addStaffBtn {
  width: 200px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  display: inline-block;
  margin: 0 20px;
  background: #4c8afc;
  color: #fff;
  cursor: pointer;
}
.addStaff-submit {
  width: 120px;
}
.addStaff-del {
  background: #fff;
  color: #999;
  width: 120px;
  border: none;
  font-weight: 400;
  &:hover {
    background: #fff;
    color: #999;
  }
}
.addStaff-list {
  width: calc((100% - 160px) / 3);
  float: left;
  margin-left: 80px;
  &:first-child {
    margin-left: 0;
  }
}
.addStaff-main {
  margin-bottom: 20px;
  padding-bottom: 20px;
}

.addStaff-line-box {
  margin: 30px 0;
}
.list-title-style {
  color: #999;
  background: #f5f5f5;
  &.marTop {
    margin-top: 36px;
  }
}
</style>
<style lang="less">
.quitStaff {
  .addStaff-box-left {
    .desc-p1,
    .desc-p2 {
      color: #bbb !important;
    }
  }
  .addStaff-box-right {
    .addStaff-text,
    .addStaff-tips,
    .el-textarea__inner,
    input {
      color: #bbb !important;
    }

    .redColor {
      color: #ea0200 !important;
    }
  }
}
</style>