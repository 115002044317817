<template>
  <div class="popup-layer">
    <div class="popup-layer-bg"></div>
    <div class="transition-page">
      <div class="popup-layer-close" @click="rosterClose">
        <svg-icon icon-class="popup-close"></svg-icon>
      </div>
      <!-- <div class="transition-title">申报个税</div> -->
      <div class="transition-title">{{ monthText }}月 工资表申报</div>
      <div class="transition-content">
        <div class="transition-content-top">
          <slot v-if="status === 100">
            <svg-icon icon-class="shenbaoshibai" class="xlsx"></svg-icon>
            <p class="transition-p1" style="color: #e74b47">
              {{ monthText }}月 工资表申报失败
            </p>
            <!-- <p class="transition-p2" style="margin-bottom: 30px">
              请检查申报表信息 重新申报
            </p> -->
            <div class="sbyy-list" v-if="sbyyList.length > 0">
              <table class="accountTable">
                <tbody>
                  <tr v-for="(e, i) in sbyyList" :key="i">
                    <td width="60px">
                      <div class="sbyy-td1">{{ e.xm }}</div>
                    </td>
                    <td class="sbyy-td">
                      <div class="sbyy-td2" style="text-align: left">
                        {{ e.cwxx }}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </slot>
          <slot v-else>
            <svg-icon icon-class="xlsx" class="xlsx"></svg-icon>
            <!-- <p class="transition-p1" style="font-size: 20px; font-weight: bold">
              {{ monthText }}月 工资表
            </p> -->
            <p
              class="transition-p2"
              style="
                color: #f58e21;
                font-size: 20px;
                padding: 14px 0 10px;
                font-weight: 500;
              "
            >
              队列处理中<slot>...</slot>
            </p>
            <p class="transition-p2">申报表报送中 请等待...</p>
            <!-- <svg-icon
              icon-class="loading-icon"
              class="loading-icon el-icon-loading"
            ></svg-icon> -->

            <loadingCss style="margin-top: 30px"></loadingCss>
          </slot>
          <!-- <div class="guocheng" v-if="incomeTaxFlag"> -->
          <div class="guocheng" v-if="0">
            <p>申报人数： {{ totalCount }}人</p>
            <p>本期收入总额： {{ deSalary }}元</p>
            <p>应补退税额： {{ incomeTax }}元</p>
          </div>
          <slot v-if="status === 100">
            <div
              style="margin-top: 30px; margin-bottom: 0px"
              class="btn-link btn-footer"
              @click="revokeSalaryV2"
            >
              返回修改
            </div>
            <div class="btn-link btn-footer btn-repeat" @click="baoshuiV2">
              <!-- :style="{ marginTop: sbyyList.length > 0 ? '0px' : '100px' }" -->
              重新申报
            </div>
            <br />
            <!-- <div class="btn-link btn-footer">返回修改</div> -->
          </slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  queryMonthSalaryListV2,
  queryShenbaoStatusV2,
  findEmployeeSalaryStatusV2,
  getAgreementBankInfo,
  setSalaryTaxPayed,
  revokeSalaryV2,
  baoshuiV2,
  querySalaryTaxShenbaoRecord,
} from "@/http/api";
export default {
  data() {
    return {
      // userId: this.$getCookie("userId"),
      // enterpriseId: this.$getCookie("enterpriseId"),

      enterpriseId: sessionStorage.getItem("enterpriseId"),
      userId: sessionStorage.getItem("userId"),
      flag: 1,
      flags: {
        flag1: false,
        flag2: false,
        flag3: false,
        flag4: false,
        flag5: false,
      },
      status: "",
      month: "",
      monthText: "",
      batch: "",
      deSalary: "",
      incomeTax: "",
      totalCount: "",
      incomeTaxFlag: false,
      leaveFlag: true,
      sbyyList: [],
    };
  },
  mounted() {
    this.month = this.$route.query.month;
    this.monthText = this.month.split("-")[1];
    this.batch = this.$route.query.batch;
    this.leaveFlag = true;

    this.queryMonthSalaryListV2();
    // this.findEmployeeSalaryStatusV2();
  },
  destroyed() {
    this.leaveFlag = false;
    this.$forceUpdate();
  },
  methods: {
    //员工工资-撤销工资v2
    revokeSalaryV2() {
      let param = {
        enterpriseId: this.enterpriseId,
        month: this.month,
        userId: this.userId,
      };
      revokeSalaryV2(param).then((res) => {
        if (res.code === 0) {
          // this.gotoFun("/payrollModule");
          this.$emit("hideLayer");
        } else {
          if (res.msg.substring(6) === "请先撤销审批") {
            this.$store.commit("MyMessage", "请先到APP端撤销审批");
          } else {
            this.$store.commit("MyMessage", res.msg);
          }
        }
      });
    },
    rosterClose() {
      this.$router.push("/payrollRecord");
    },
    baoshuiV2() {
      this.$store.commit("showLoading", "");
      let param = {
        enterpriseId: this.enterpriseId,
        month: this.month,
        userId: this.userId,
      };
      baoshuiV2(param).then((res) => {
        console.log(res);
        this.$store.commit("hideLoading", "");
        if (res.code === 0) {
          this.queryMonthSalaryListV2();
        }
      });
    },
    querySalaryTaxShenbaoRecord() {
      let param = {
        enterpriseId: this.enterpriseId,
        userId: this.userId,
        taxMonth: this.month,
      };
      querySalaryTaxShenbaoRecord(param).then((res) => {
        console.log(res);
        if (res.code === 0) {
          this.sbyyList = [];

          // let res = JSON.parse(res.data.submmitList);
          let array1 = [];
          // xm;//姓名String(64)
          // zzlx;//证件类型名称String(64)
          // zzhm;//证件号码String(64)
          // sbzt;//代报状态String(64)1待报送2代报中3代报失败4代报成功
          // rzzt;//人员认证状态 String(64)
          // sbyy;//失败原因String(64)
          // failReason;//失败原因Liist    String(64)
          // clzt;//专项代报状态String(64)
          // cljgms;//专项代报结果原因String(64)
          console.log(res.data);
          if (res.data.employeeList.length > 0) {
            res.data.employeeList.forEach((e) => {
              array1.push({
                xm: e.xm,
                cwxx: e.cwxx,
              });
              if (e.failReason) {
                if (e.failReason.msg) {
                  array1.push({
                    xm: "失败原因",
                    cwxx: e.failReason.msg,
                  });
                }
              }
            });
          } else {
            if (res.data.msgList.length > 0) {
              res.data.msgList.forEach((e) => {
                array1.push({
                  xm: "失败原因",
                  cwxx: e,
                });
              });
            }
          }
          this.sbyyList = array1;
        }
      });
    },
    getAgreementBankInfo() {
      let param = {
        enterpriseId: this.enterpriseId,
      };
      getAgreementBankInfo(param).then((res) => {
        console.log(res);
        if (res.code === 0) {
          if (res.data.status !== 1) {
            setTimeout(() => {
              this.getAgreementBankInfo();
            }, 5000);
          } else {
            this.$emit("tFun");
            return;
            this.$router.push({
              path: "/generatePayroll",
              query: {
                month: this.month,
                batch: this.batch,
              },
            });
          }
        }
      });
    },
    // 暂时用不到
    findEmployeeSalaryStatusV2() {
      let param = {
        enterpriseId: this.enterpriseId,
        month: this.month,
        batch: this.batch,
        pageNum: 1,
        pageSize: 999,
      };
      findEmployeeSalaryStatusV2(param).then((res) => {
        console.log(res);
        if (res.code === 0) {
          // this.deSalary = res.data.comprehensive.deSalary;
          // this.incomeTax = res.data.comprehensive.incomeTax;
          // this.totalCount = res.data.comprehensive.totalCount;
          // this.incomeTaxFlag = true;
        }
        // deSalary
        // incomeTax
      });
    },
    // 设置报税完成---个税为0时，不需要交税使用这个接口
    setSalaryTaxPayed() {
      let param = {
        enterpriseId: this.enterpriseId,
        taxMonth: this.month,
        userId: this.userId,
      };
      setSalaryTaxPayed(param).then((res) => {
        console.log(res);
        if (res.code === 0) {
          // this.$emit("tFun");
          // !
          return;
          this.$router.push({
            path: "/generatePayroll",
            query: {
              month: this.month,
              batch: this.batch,
            },
          });
        }
      });
    },
    queryMonthSalaryListV2() {
      let param = {
        enterpriseId: this.enterpriseId,
        userId: this.userId,
      };
      queryMonthSalaryListV2(param).then((res) => {
        if (res.code === 0) {
          // console.log(res.data);

          let date = this.month.split("-")[0] + this.month.split("-")[1];
          res.data.forEach((e) => {
            if (e.month + "" === date) {
              e.baoshuiList.forEach((e1) => {
                if (e1.month + "" === date) {
                  // this.status = 100;
                  this.status = e1.status;
                  let payStatus = e1.payStatus;
                  if (this.status === 100) {
                    this.querySalaryTaxShenbaoRecord();
                    // this.queryShenbaoStatusV2();
                    return;
                  }
                  if (
                    this.status === 40 ||
                    this.status === 41 ||
                    this.status === 50
                  ) {
                    setTimeout(() => {
                      if (this.leaveFlag) this.queryMonthSalaryListV2();
                    }, 5000);
                  } else {
                    if (payStatus === 305 || payStatus === 302) {
                      this.$emit("tFun");
                    } else {
                      this.queryShenbaoStatusV2();
                    }
                  }
                }
              });
            }
          });
        }
      });
    },
    queryShenbaoStatusV2() {
      let param = {
        enterpriseId: this.enterpriseId,
        month: this.month,
        userId: this.userId,
      };
      queryShenbaoStatusV2(param).then((res) => {
        console.log(res);
        // this.taxTotal = res.data.zhsd[0].srze;
        if (res.code === 0) {
          let sum = 0;
          res.data.forEach((e) => {
            e.statusList.forEach((e2) => {
              sum += e2.ybtse * 1;
            });
          });
          console.log(sum);
          if (sum === 0) {
            this.setSalaryTaxPayed();
          } else {
            this.$emit("tFun");
            // // !
            // return;
            // this.$router.push({
            //   path: "/generatePayroll",
            //   query: {
            //     month: this.month,
            //     batch: this.batch,
            //   },
            // });
          }
          this.deSalary = res.data[0].srze;
          this.incomeTax = res.data[0].ybtse;
          this.totalCount = res.data[0].nsrc;

          this.incomeTaxFlag = true;
        } else {
          this.$store.commit("MyMessage", res.msg);
        }
      });
    },
    goFun(str) {
      this.$router.push(str);
    },
  },
};
</script>

<style lang="less" scoped>
.sbyy-list {
  width: 90%;
  margin: 0 auto;
  max-height: 170px;
  overflow-y: auto;
  // height: 170px;
  td {
    padding: 0 5px;
  }
}
.loading-icon {
  width: 24px;
  height: 24px;
  margin-top: 30px;
}

.xlsx {
  font-size: 36px;
  padding: 80px 0 10px;
}
.btn-link {
  padding: 0 20px;
  display: inline-block;
  height: 34px;
  line-height: 34px;
  cursor: pointer;
  background: #f4f4f4;
  border: 1px solid #f4f4f4;
  font-size: 16px;
  font-weight: 400;
  color: #262626;
  margin-right: 10px;
  border-radius: 3px;
}
.btn-footer {
  width: 360px;
  margin: 20px auto 0;
  height: 54px;
  line-height: 54px;
  border-radius: 6px;
  font-size: 20px;
  padding: 0;
  &.btn-repeat {
    background: #ffffff;
    border: 1px solid #4c8afc;
    color: #4c8afc;
    // font-weight: bold;
  }
}
.popup-layer {
  z-index: 999;
}
.transition-page {
  margin: 0 auto;
  text-align: center;
  width: 460px;
  min-height: 450px;
  background: #ffffff;
  opacity: 1;
  border-radius: 6px;
  z-index: 999;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -230px;
  margin-top: -305px;
  padding-bottom: 20px;
  .transition-title {
    font-size: 20px;
    padding: 28px 0 0px;
    text-align: center;
    color: #262626;
    font-weight: bold;
  }
  .transition-content {
    .transition-p1 {
      color: #262626;
      font-size: 20px;
      padding-bottom: 20px;
      font-weight: bold;
      padding-top: 10px;
    }
    .transition-p2 {
      color: #bbb;
      font-size: 12px;
      letter-spacing: 1px;
      padding-top: 2px;
    }
    .guocheng {
      width: 360px;
      margin: 60px auto;
      text-align: left;
      p {
        position: relative;
        font-size: 16px;
        margin-bottom: 10px;
        color: #999;
        i {
          position: absolute;
          left: -20px;
          top: 1px;
          font-size: 16px;
        }
        .span1 {
          position: absolute;
          right: 0;
          top: 0px;
          font-size: 16px;
          color: #33c481;
        }
        .span2 {
          position: absolute;
          right: 0;
          top: 0px;
          font-size: 16px;
          // color: #e75850;
          color: #bbb;
        }
      }
    }
  }
}
</style>