var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"views-width"},[_c('div',{staticClass:"views-main"},[_c('div',{staticClass:"views-mian-header"},[_c('div',{staticClass:"views-mian-header-left"},[_c('el-button',{staticClass:"btn-primary btn-link",attrs:{"type":"primary"},on:{"click":function($event){return _vm.gotoFun('/payrollRecord')}}},[_vm._v("工资记录")]),_c('span',[_vm._v(" 总计 "+_vm._s(_vm.tableData.length)+" 人")])],1),_c('div',{staticClass:"views-mian-header-title"},[_vm._v(_vm._s(_vm.mouthText)+"工资表")]),_c('div',{staticClass:"views-mian-header-right"},[_c('dowmPopup',{attrs:{"status":_vm.status,"mouth":_vm.mouth,"tableData":_vm.tableData}}),(
            _vm.shenbaoStatus !== 60 &&
            _vm.shenbaoStatus !== 80 &&
            _vm.tableData.length > 0
          )?_c('div',{staticClass:"btn-link",on:{"click":_vm.revokeSalaryV2}},[_vm._v(" 重新制作 ")]):_vm._e(),(_vm.status === '1' && _vm.tableData.length > 0)?_c('el-button',{staticClass:"btn-primary",attrs:{"type":"primary"},on:{"click":_vm.approvalClick}},[_vm._v("提交审批")]):_vm._e(),_c('el-button',{staticClass:"btn-primary",class:{
            shenbaoStatus60: _vm.shenbaoStatus === 60 || _vm.tableData.length === 0,
            shenbaoStatus80: _vm.shenbaoStatus === 80,
          },attrs:{"type":"primary"},on:{"click":_vm.click}},[(_vm.shenbaoStatus === 50)?_vm._t("default",function(){return [_vm._v("报税中")]}):(_vm.shenbaoStatus === 60)?_vm._t("default",function(){return [_vm._v("队列处理中")]}):(_vm.shenbaoStatus === 80)?_vm._t("default",function(){return [_vm._v("申报完成 已缴款")]}):_vm._t("default",function(){return [_vm._v("申报个税")]})],2),(_vm.shenbaoStatus === 60 && _vm.visit === 'visit')?_c('button',{staticClass:"overall-btn active",class:{
            shenbaoStatus60: _vm.tableData.length === 0,
          },staticStyle:{"background":"#ea0200","float":"right"},on:{"click":function($event){_vm.invitationShow1 = true}}},[_vm._v(" 申报完成 ")]):_vm._e()],1)]),_c('div',{staticClass:"views-mian-scroll"},[_c('div',{staticClass:"views-mian-content"},[(_vm.tableData.length > 0)?_c('generateTable',{attrs:{"tableData":_vm.tableData,"tdShowList":_vm.tdShowList}}):_c('NoRecord')],1),_c('TheFooter')],1)]),(_vm.approvalShow)?_c('invitationPopup',{attrs:{"mouth":_vm.mouth,"batch":_vm.batch},on:{"approvalClose":_vm.approvalClose}}):_vm._e(),(_vm.declareShow)?_c('declarePopup',{attrs:{"mouth":_vm.mouth},on:{"approvalClose":_vm.approvalClose}}):_vm._e(),(_vm.invitationShow1)?_c('declareVisitPopup',{attrs:{"mouth":_vm.mouth,"tableData":_vm.tableData},on:{"approvalClose":_vm.approvalClose}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }