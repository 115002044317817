<template>
  <div class="elTable">
    <el-table
      :data="tableData"
      :header-cell-style="{
        background: '#fff',
      }"
      :height="tableHeight"
      :row-class-name="tableRowClassName"
      style="width: 100%"
      v-loadmore="loadMore"
    >
      <!-- //这里设置表头居中 > -->
      <!-- <el-table-column type="selection" width="55" align="center" fixed>
      </el-table-column> -->
      <el-table-column
        prop="nickName"
        label="姓名"
        align="center"
        fixed
        :autoFit="true"
      >
        <template slot-scope="scope">
          <span
            style="font-size: 14px"
            :style="{
              color:
                scope.row.status === 6 && scope.row.salaryStatus === 2
                  ? '#f58e21 !important'
                  : '',
            }"
            >{{ scope.row.nickName }}</span
          >
          <slot v-if="scope.row.salaryStatus === 2">
            <p class="salaryStatus" v-if="scope.row.status === 1">（缓发）</p>
            <p class="salaryStatus" v-if="scope.row.status === 2">
              （等待审批）
            </p>
            <p class="salaryStatus" v-if="scope.row.status === 3">
              （等待发放）
            </p>
            <p class="salaryStatus" v-if="scope.row.status === 4">（缓发）</p>
            <p class="salaryStatus" v-if="scope.row.status === 5">
              （等待发放）
            </p>
            <p
              class="salaryStatus"
              style="color: #f58e21"
              v-if="scope.row.status === 6"
            >
              （已缓发）
            </p>
          </slot>
        </template>
      </el-table-column>
      <!-- //这里设置表头居中 > -->
      <!-- <ex-table-column
        prop="nickName"
        label="姓名"
        align="center"
        fixed
        :autoFit="true"
      >
        <template slot-scope="scope">
          <span style="font-size: 14px">{{ scope.row.nickName }}</span>
        </template>
      </ex-table-column> -->
      <ex-table-column
        prop="idNum"
        label="证件号码"
        align="center"
        :autoFit="true"
      >
        <template slot-scope="scope">
          <span style="min-width: 155px">{{ scope.row.idNum }}</span>
        </template>
      </ex-table-column>
      <ex-table-column
        prop="postName"
        label="岗位"
        align="center"
        :autoFit="true"
      >
        <template slot-scope="scope">
          <span style="font-size: 14px; min-width: 40px">{{
            scope.row.postName
          }}</span>
        </template>
      </ex-table-column>

      <ex-table-column
        prop="departmentName"
        label="部门"
        align="center"
        :autoFit="true"
      >
        <template slot-scope="scope">
          <span style="font-size: 14px">{{ scope.row.departmentName }}</span>
        </template>
      </ex-table-column>
      <ex-table-column
        prop="deSalary"
        label="岗位工资"
        align="center"
        :autoFit="true"
      >
        <template slot-scope="scope">
          <span
            v-if="scope.row.deSalary === 0"
            style="color: #bbb; min-width: 60px"
            >0</span
          >
          <span style="min-width: 60px" v-else>{{ scope.row.deSalary }}</span>
        </template>
      </ex-table-column>
      <ex-table-column
        v-if="tableData[0].acheBouns > 0"
        prop="acheBouns"
        label="绩效/奖金"
        align="center"
        :autoFit="true"
      >
        <template slot-scope="scope">
          <span
            v-if="scope.row.acheBouns === 0"
            style="color: #bbb; min-width: 60px"
            >--</span
          >
          <span style="min-width: 60px" v-else>{{ scope.row.acheBouns }}</span>
        </template>
      </ex-table-column>
      <ex-table-column
        v-if="tableData[0].subsidy > 0"
        prop="subsidy"
        label="补助补贴"
        align="center"
        :autoFit="true"
      >
        <template slot-scope="scope">
          <span
            v-if="scope.row.subsidy === 0"
            style="color: #bbb; min-width: 60px"
            >--</span
          >
          <span style="min-width: 60px" v-else>{{ scope.row.subsidy }}</span>
        </template>
      </ex-table-column>
      <ex-table-column
        v-if="tableData[0].endBonus > 0"
        prop="endBonus"
        label="年终奖"
        align="center"
        :autoFit="true"
      >
        <template slot-scope="scope">
          <span
            v-if="scope.row.endBonus === 0"
            style="color: #bbb; min-width: 60px"
            >--</span
          >
          <span style="min-width: 60px" v-else>{{ scope.row.endBonus }}</span>
        </template>
      </ex-table-column>
      <ex-table-column
        v-if="tableData[0].compensation > 0"
        prop="compensation"
        label="解除合同补偿金"
        align="center"
        :autoFit="true"
      >
        <template slot-scope="scope">
          <span
            v-if="scope.row.compensation === 0"
            style="color: #bbb; min-width: 100px"
            >--</span
          >
          <span style="min-width: 100px" v-else>{{
            scope.row.compensation
          }}</span>
        </template>
      </ex-table-column>
      <ex-table-column
        v-if="tableData[0].kSalary > 0"
        prop="kSalary"
        label="考勤扣款"
        align="center"
        :autoFit="true"
      >
        <template slot-scope="scope">
          <span
            v-if="scope.row.kSalary === 0"
            style="color: #bbb; min-width: 60px"
            >--</span
          >
          <span style="min-width: 60px" v-else>{{ scope.row.kSalary }}</span>
        </template>
      </ex-table-column>
      <ex-table-column
        v-if="tableData[0].fine > 0"
        prop="fine"
        label="其他扣款"
        align="center"
        :autoFit="true"
      >
        <template slot-scope="scope">
          <span v-if="scope.row.fine === 0" style="color: #bbb; min-width: 60px"
            >--</span
          >
          <span style="min-width: 60px" v-else>{{ scope.row.fine }}</span>
        </template>
      </ex-table-column>
      <ex-table-column
        prop="wagePayable"
        label="应发工资"
        align="center"
        :autoFit="true"
      >
        <template slot-scope="scope">
          <span
            v-if="scope.row.wagePayable === 0"
            style="color: #bbb; min-width: 60px"
            >0</span
          >
          <span style="min-width: 60px" v-else>{{
            scope.row.wagePayable
          }}</span>
        </template>
      </ex-table-column>
      <ex-table-column label="社保公积金（单位）" align="center">
        <ex-table-column
          prop="oldCompany"
          label="养老"
          align="center"
          :autoFit="true"
        >
          <template slot-scope="scope">
            <span
              v-if="scope.row.oldCompany === 0"
              style="color: #bbb; min-width: 30px"
              >0</span
            >
            <span style="min-width: 30px" v-else>{{
              scope.row.oldCompany
            }}</span>
          </template>
        </ex-table-column>

        <ex-table-column
          prop="hosCompany"
          label="医疗"
          align="center"
          :autoFit="true"
        >
          <template slot-scope="scope">
            <span
              v-if="scope.row.hosCompany === 0"
              style="color: #bbb; min-width: 30px"
              >0</span
            >
            <span style="min-width: 30px" v-else>{{
              scope.row.hosCompany
            }}</span>
          </template>
        </ex-table-column>
        <ex-table-column
          prop="jobCompany"
          label="失业"
          align="center"
          :autoFit="true"
        >
          <template slot-scope="scope">
            <span
              v-if="scope.row.jobCompany === 0"
              style="color: #bbb; min-width: 30px"
              >0</span
            >
            <span style="min-width: 30px" v-else>{{
              scope.row.jobCompany
            }}</span>
          </template>
        </ex-table-column>
        <ex-table-column
          prop="industrialCompany"
          label="工伤"
          align="center"
          :autoFit="true"
        >
          <template slot-scope="scope">
            <span
              v-if="scope.row.industrialCompany === 0"
              style="color: #bbb; min-width: 30px"
              >0</span
            >
            <span style="min-width: 30px" v-else>{{
              scope.row.industrialCompany
            }}</span>
          </template>
        </ex-table-column>

        <ex-table-column
          v-for="(e, i) in salaryPropertyList"
          :key="i"
          :label="e.propertyName"
          align="center"
          :autoFit="true"
        >
          <template slot-scope="scope">
            <span :style="{ minWidth: e.propertyName.length * 14 + 'px' }">
              <slot v-if="scope.row.salaryPropertyList.length >= i + 1">
                <!-- {{ scope.row.salaryPropertyList[i].propertyCompanyValue }} -->

                <slot
                  v-if="
                    scope.row.salaryPropertyList[i].propertyCompanyValue > 0
                  "
                >
                  {{ scope.row.salaryPropertyList[i].propertyCompanyValue }}
                </slot>
                <span v-else style="color: #bbb">0</span>
              </slot>
              <slot v-else><span style="color: #bbb">0</span></slot>
            </span>
          </template>
        </ex-table-column>
        <ex-table-column
          prop="providentCompanyPay"
          label="公积金"
          align="center"
          :autoFit="true"
        >
          <template slot-scope="scope">
            <span
              v-if="scope.row.providentCompanyPay === 0"
              style="color: #bbb; min-width: 50px"
              >0</span
            >
            <span style="min-width: 50px" v-else>{{
              scope.row.providentCompanyPay
            }}</span>
          </template>
        </ex-table-column>
      </ex-table-column>
      <ex-table-column align="center" label="社保公积金（个人）">
        <ex-table-column
          prop="oldPerson"
          label="养老"
          align="center"
          :autoFit="true"
        >
          <template slot-scope="scope">
            <span
              v-if="scope.row.oldPerson === 0"
              style="color: #bbb; min-width: 30px"
              >0</span
            >
            <span style="min-width: 30px" v-else>{{
              scope.row.oldPerson
            }}</span>
          </template>
        </ex-table-column>
        <ex-table-column
          prop="hosPerson"
          label="医疗"
          align="center"
          :autoFit="true"
        >
          <template slot-scope="scope">
            <span
              v-if="scope.row.hosPerson === 0"
              style="color: #bbb; min-width: 30px"
              >0</span
            >
            <span style="min-width: 30px" v-else>{{
              scope.row.hosPerson
            }}</span>
          </template>
        </ex-table-column>
        <ex-table-column
          prop="jobPerson"
          label="失业"
          align="center"
          :autoFit="true"
        >
          <template slot-scope="scope">
            <span
              v-if="scope.row.jobPerson === 0"
              style="color: #bbb; min-width: 30px"
              >0</span
            >
            <span style="min-width: 30px" v-else>{{
              scope.row.jobPerson
            }}</span>
          </template>
        </ex-table-column>
        <ex-table-column
          prop="industrialPerson"
          label="工伤"
          align="center"
          :autoFit="true"
        >
          <template slot-scope="scope">
            <span
              v-if="scope.row.industrialPerson === 0"
              style="color: #bbb; min-width: 30px"
              >0</span
            >
            <span style="min-width: 30px" v-else>{{
              scope.row.industrialPerson
            }}</span>
          </template>
        </ex-table-column>

        <ex-table-column
          v-for="(e, i) in salaryPropertyList"
          :key="i"
          :label="e.propertyName"
          align="center"
          :autoFit="true"
        >
          <template slot-scope="scope">
            <slot v-if="scope.row.salaryPropertyList.length >= i + 1">
              <span :style="{ minWidth: e.propertyName.length * 14 + 'px' }">
                <slot
                  v-if="scope.row.salaryPropertyList[i].propertyPersonValue > 0"
                >
                  {{ scope.row.salaryPropertyList[i].propertyPersonValue }}
                </slot>
                <span v-else style="color: #bbb">0</span>
              </span>
            </slot>
            <span v-else style="color: #bbb; min-width: 30px">0</span>
          </template>
        </ex-table-column>
        <ex-table-column
          prop="providentPersonPay"
          label="公积金"
          align="center"
          :autoFit="true"
        >
          <template slot-scope="scope">
            <span
              v-if="scope.row.providentPersonPay === 0"
              style="color: #bbb; min-width: 50px"
              >0</span
            >
            <span style="min-width: 50px" v-else>{{
              scope.row.providentPersonPay
            }}</span>
          </template>
        </ex-table-column>
      </ex-table-column>
      <ex-table-column align="center" label="社保公积金扣款（合计）">
        <ex-table-column
          prop="insuranceCompanySum"
          label="单位应扣"
          align="center"
          :autoFit="true"
        >
          <template slot-scope="scope">
            <span
              v-if="scope.row.socialCompayPay === 0"
              style="color: #bbb; min-width: 70px"
              >0</span
            >
            <span style="min-width: 70px" v-else>{{
              scope.row.socialCompayPay
            }}</span>
          </template>
        </ex-table-column>
        <ex-table-column
          prop="insurancePersonSum"
          label="个人应扣"
          align="center"
          :autoFit="true"
        >
          <template slot-scope="scope">
            <span
              v-if="scope.row.socialPersonPay === 0"
              style="color: #bbb; min-width: 70px"
              >0</span
            >
            <span style="min-width: 70px" v-else>{{
              scope.row.socialPersonPay
            }}</span>
          </template>
        </ex-table-column>
      </ex-table-column>
      <ex-table-column align="center" label="个税专项扣除">
        <ex-table-column
          prop="deductionChildAmt"
          label="子女教育"
          align="center"
          :autoFit="true"
        >
          <template slot-scope="scope">
            <span
              v-if="scope.row.deductionChildAmt === 0"
              style="color: #bbb; min-width: 60px"
              >0</span
            >
            <span style="min-width: 60px" v-else>{{
              scope.row.deductionChildAmt
            }}</span>
          </template>
        </ex-table-column>
        <ex-table-column
          prop="deductionTeachAmt"
          label="继续教育"
          align="center"
          :autoFit="true"
        >
          <template slot-scope="scope">
            <span
              v-if="scope.row.deductionTeachAmt === 0"
              style="color: #bbb; min-width: 60px"
              >0</span
            >
            <span style="min-width: 60px" v-else>{{
              scope.row.deductionTeachAmt
            }}</span>
          </template>
        </ex-table-column>
        <ex-table-column
          prop="housingLoanAmt"
          label="住房贷款"
          align="center"
          :autoFit="true"
        >
          <template slot-scope="scope">
            <span
              v-if="scope.row.housingLoanAmt === 0"
              style="color: #bbb; min-width: 60px"
              >0</span
            >
            <span style="min-width: 60px" v-else>{{
              scope.row.housingLoanAmt
            }}</span>
          </template>
        </ex-table-column>
        <ex-table-column
          prop="houseRentAmt"
          label="住房租金"
          align="center"
          :autoFit="true"
        >
          <template slot-scope="scope">
            <span
              v-if="scope.row.houseRentAmt === 0"
              style="color: #bbb; min-width: 60px"
              >0</span
            >
            <span style="min-width: 60px" v-else>{{
              scope.row.houseRentAmt
            }}</span>
          </template>
        </ex-table-column>
        <ex-table-column
          prop="provideAgedAmt"
          label="赡养老人"
          align="center"
          :autoFit="true"
        >
          <template slot-scope="scope">
            <span
              v-if="scope.row.provideAgedAmt === 0"
              style="color: #bbb; min-width: 60px"
              >0</span
            >
            <span style="min-width: 60px" v-else>{{
              scope.row.provideAgedAmt
            }}</span>
          </template>
        </ex-table-column>
        <ex-table-column
          prop="yyezhzc"
          label="婴幼儿照护"
          align="center"
          :autoFit="true"
        >
          <template slot-scope="scope">
            <span
              v-if="scope.row.yyezhzc === 0 || scope.row.yyezhzc === undefined"
              style="color: #bbb; min-width: 70px"
              >0</span
            >
            <span style="min-width: 70px" v-else>{{ scope.row.yyezhzc }}</span>
          </template>
        </ex-table-column>
        <!-- <ex-table-column
          prop="healthAmt"
          label="大病医疗"
          align="center"
          :autoFit="true"
        >
          <template slot-scope="scope">
            <span
              v-if="scope.row.healthAmt === 0"
              style="color: #bbb; min-width: 60px"
              >0</span
            >
            <span style="min-width: 60px" v-else>{{
              scope.row.healthAmt
            }}</span>
          </template>
        </ex-table-column> -->
        <ex-table-column
          prop="deductionSum"
          label="扣除合计"
          align="center"
          :autoFit="true"
        >
          <template slot-scope="scope">
            <span
              v-if="scope.row.deductionSum === 0"
              style="color: #bbb; min-width: 60px"
              >0</span
            >
            <span style="min-width: 60px" v-else>{{
              scope.row.deductionSum
            }}</span>
          </template>
        </ex-table-column>
      </ex-table-column>
      <ex-table-column align="center" label="个人所得税">
        <ex-table-column
          prop="incomeTax"
          label="正常工资个税"
          align="center"
          :autoFit="true"
        >
          <template slot-scope="scope">
            <span
              v-if="scope.row.incomeTax === 0 || scope.row.incomeTax === '0.00'"
              style="color: #bbb; min-width: 85px"
              >0</span
            >
            <span style="min-width: 85px" v-else>{{
              scope.row.incomeTax
            }}</span>
          </template>
        </ex-table-column>

        <!-- v-if="tableData[0].laowuIncomeTax > 0" -->
        <ex-table-column
          prop="incomeTax1"
          label="劳务报酬个税"
          align="center"
          v-if="tableData[0].laowuIncomeTax > 0"
          :autoFit="true"
        >
          <template slot-scope="scope">
            <span
              v-if="scope.row.laowuIncomeTax === 0"
              style="color: #bbb; min-width: 85px"
              >0</span
            >
            <span style="min-width: 85px" v-else>{{
              scope.row.laowuIncomeTax
            }}</span>
          </template>
        </ex-table-column>
        <ex-table-column
          v-if="tableData[0].endBounsTax > 0"
          prop="endBounsTax"
          label="年终奖个税"
          align="center"
          :autoFit="true"
        >
          <template slot-scope="scope">
            <span
              v-if="scope.row.endBounsTax === 0"
              style="color: #bbb; min-width: 70px"
              >0</span
            >
            <span style="min-width: 70px" v-else>{{
              scope.row.endBounsTax
            }}</span>
          </template>
        </ex-table-column>
        <ex-table-column
          v-if="tableData[0].compensationTax > 0"
          prop="compensationTax"
          label="解除合同补偿金个税"
          align="center"
          :autoFit="true"
        >
          <template slot-scope="scope">
            <span
              v-if="scope.row.compensationTax === 0"
              style="color: #bbb; min-width: 140px"
              >0</span
            >
            <span style="min-width: 140px" v-else>{{
              scope.row.compensationTax
            }}</span>
          </template>
        </ex-table-column>
      </ex-table-column>
      <ex-table-column
        prop="bankName"
        label="开户行"
        align="center"
        :autoFit="true"
      >
        <template slot-scope="scope">
          <span style="font-size: 14px; min-width: 50px">{{
            scope.row.bankName
          }}</span>
        </template>
      </ex-table-column>
      <ex-table-column
        prop="bankCardNum"
        label="银行卡号"
        align="center"
        :autoFit="true"
      >
        <template slot-scope="scope">
          <span style="min-width: 120px">{{ scope.row.bankCardNum }}</span>
        </template>
      </ex-table-column>
      <ex-table-column
        prop="netSalary"
        label="实发工资"
        align="center"
        :autoFit="true"
        fixed="right"
      >
        <template slot-scope="scope">
          <span
            v-if="scope.row.netSalary === 0"
            style="color: #bbb; min-width: 60px"
            >0</span
          >
          <span style="min-width: 60px" v-else>{{ scope.row.netSalary }}</span>
        </template>
      </ex-table-column>
      <ex-table-column
        prop="manpower"
        label="人力成本"
        align="center"
        :autoFit="true"
        fixed="right"
      >
        <template slot-scope="scope">
          <span
            v-if="scope.row.manpower === 0"
            style="color: #bbb; min-width: 60px"
            >0</span
          >
          <span style="min-width: 60px" v-else>{{ scope.row.manpower }}</span>
        </template>
      </ex-table-column>
    </el-table>
    <div></div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      showList: {
        showTable1: false,
        showTable2: false,
        showTable3: false,
        showTable4: false,
        showTable5: false,
      },
      tableHeight: null,
      salaryPropertyList: [],
    };
  },
  props: {
    tableData: {
      type: Array,
      default() {
        return null;
      },
    },
    tdShowList: {
      type: Object,
      default() {
        return null;
      },
    },
    totalPage: {
      type: Number,
      default() {
        return null;
      },
    },
  },
  mounted() {
    // this.salaryPropertyList = this.tableData[1].salaryPropertyList;
    this.salaryPropertyList = [];
    this.tableHeight = document.body.clientHeight - 280;
    let data1 = [];
    if (this.tableData[1].salaryPropertyList.length > 0) {
      this.tableData[1].salaryPropertyList.forEach((e) => {
        this.tableData[0].salaryPropertyList.forEach((e1) => {
          if (e.propertyName === e1.propertyName) data1.push(e1);
        });
      });
      this.tableData[0].salaryPropertyList = data1;
    }
    let num = 0;
    let index = 0;
    this.tableData.forEach((e, i) => {
      if (i !== 0) {
        if (e.salaryPropertyList.length > num) {
          num = e.salaryPropertyList.length;
          index = i;
        }
      }
    });
    if (num !== 0) {
      this.salaryPropertyList = this.tableData[index].salaryPropertyList;
    }
    console.log(this.salaryPropertyList);
    let data = {
      deSalary: 0,
      acheBouns: 0,
      subsidy: 0,
      endBonus: 0,
      compensation: 0,
      kSalary: 0,
      fine: 0,
      wagePayable: 0,
      oldCompany: 0,
      hosCompany: 0,
      jobCompany: 0,
      industrialCompany: 0,
      babyCompany: 0,
      providentCompanyPay: 0,
      oldPerson: 0,
      hosPerson: 0,
      jobPerson: 0,
      industrialPerson: 0,
      babyPerson: 0,
      providentPersonPay: 0,
      insuranceCompanySum: 0,
      insurancePersonSum: 0,
      deductionChildAmt: 0,
      deductionTeachAmt: 0,
      housingLoanAmt: 0,
      houseRentAmt: 0,
      provideAgedAmt: 0,
      healthAmt: 0,
      deductionSum: 0,
      incomeTax: 0,
      incomeTax1: 0,
      endBounsTax: 0,
      compensationTax: 0,
      netSalary: 0,
      manpower: 0,
    };

    this.tableData.forEach((e) => {
      for (let key in data) {
        data[key] = data[key] + e[key];
        // data[key] = (data[key] * 1).toFixed(2);
      }
    });

    for (let key in data) {
      if (isNaN(data[key])) {
        data[key] = 0;
        return false;
      }
      if (data[key]) data[key] = data[key].toFixed(2);
      if ((data[key] + "").split(".")[1] === "00")
        data[key] = (data[key] + "").split(".")[0];
      // data[key] = data[key].toString().match(/^\d+(?:\.\d{0,2})?/)[0];
      // data[key] = (data[key] * 1).toFixed(2);
    }
    // data.nickName = "合计";
    // this.tableData.unshift(data);
  },
  methods: {
    computeIndex(index) {
      console.log(index);
      return;
    },
    tableRowClassName({ row, rowIndex }) {
      if (row.salaryStatus === 2 && row.status !== 6) {
        return "salaryStatus";
      }
      return "";
    },
    open(showTable) {
      this.showList[showTable] = !this.showList[showTable];
    },

    loadMore(el) {
      this.$emit("pageFun");
    },

    // :summary-method="getSummaries"
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      console.log(columns);
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "合计";
          return;
        }
        if (
          index === 1 ||
          column.label === "银行卡号" ||
          column.label === "岗位" ||
          column.label === "部门" ||
          column.label === "开户行"
        ) {
          sums[index] = "---";
          return;
        }
        const values = data.map((item) => Number(item[column.property]));
        if (!values.every((value) => isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
        } else {
          sums[index] = "---";
        }
        if (index !== 0) {
          if (sums[index] && column.label !== "岗位工资") {
            let num1 = sums[index].toFixed(2) + "";
            let num2 = num1.split(".")[1];
            let num3 = null;
            if (num2 * 1 > 0) {
              num3 = num1 * 1;
            } else {
              num3 = num1.split(".")[0] * 1;
            }
            sums[index] = num3;
          }
        }
      });
      return sums;
    },
  },
};
</script>
<style lang="less">
.el-table .cell {
  white-space: nowrap;
  width: fit-content;
}
.salaryStatus {
  .cell {
    font-size: 14px;
    color: #f58e21 !important;
    span {
      color: #f58e21 !important;
    }
  }
}
.elTable {
  .el-table__fixed-body-wrapper,
  .el-table__body-wrapper {
    tbody {
      tr {
        &:first-child {
          td {
            background: #fafafa;
          }
        }
      }
    }
  }
}
.views-mian-content {
  .el-table td,
  .el-table th {
    padding: 5px 0;
  }
  // .el-table__body tr.hover-row.current-row > td,
  // .el-table__body tr.hover-row.el-table__row--striped.current-row > td,
  // .el-table__body tr.hover-row.el-table__row--striped > td,
  // .el-table__body tr.hover-row > td {
  //   background-color: #bbb;
  // }
  .el-table__body-wrapper {
    // padding-bottom: 40px;

    td {
      padding: 5px 0;
    }
    &::-webkit-scrollbar {
      width: 20px;
      height: 20px !important;
    }

    &::-webkit-scrollbar-track,
    &::-webkit-scrollbar-thumb {
      border-radius: 0;
      border: 5px solid transparent;
    }
    &::-webkit-scrollbar-track {
      box-shadow: 3px 1px 13px rgba(219, 219, 219, 0.6) inset;
    }
    &::-webkit-scrollbar-thumb {
      min-height: 20px;
      background-clip: content-box;
      box-shadow: 0 0 0 0px rgba(153, 153, 153, 0.5);
    }
    &::-webkit-scrollbar-corner {
      background: transparent;
    }
  }
  .el-table {
    .el-table__header {
      tr {
        th {
          padding: 8px 0;
          background: #fff;
          .cell {
            color: #262626 !important;

            font-weight: 400;
            font-size: 14px;
          }
        }
        &:last-child {
          th {
            font-weight: 400;
          }
        }
      }
    }
    &::-webkit-scrollbar {
      height: 20px !important;
    }
  }
  .el-table__body-wrapper {
    // max-height: 530px !important;
  }
}
.el-table--scrollable-x .el-table__body-wrapper::-webkit-scrollbar-track {
  &:hover {
    .el-table--scrollable-x .el-table__body-wrapper::-webkit-scrollbar-thumb {
      box-shadow: 0 0 0 8px rgba(153, 153, 153, 0.5) inset;
    }
  }
}
.el-table--scrollable-x .el-table__body-wrapper::-webkit-scrollbar-thumb {
  box-shadow: 0 0 0 8px rgba(219, 219, 219, 0.3) inset;
  &:hover {
    box-shadow: 0 0 0 8px rgba(219, 219, 219, 0.6) inset;
  }
}
.salaryStatus {
  font-size: 12px;
  height: 12px;
  line-height: 12px;
}
.el-table .cell {
  white-space: nowrap;
  width: fit-content;
  margin: 0 auto;
  span {
    display: inline-block;
  }
}
</style>