var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"popup-layer"},[_c('div',{staticClass:"popup-layer-bg",on:{"click":function($event){$event.stopPropagation();return _vm.close.apply(null, arguments)}}}),_c('div',{staticClass:"images-popup"},[_c('div',{staticClass:"popup-layer-close",on:{"click":function($event){$event.stopPropagation();return _vm.close.apply(null, arguments)}}},[_c('svg-icon',{attrs:{"icon-class":"popup-close"}})],1),_c('div',{staticClass:"images-popup-header"},[_vm._v("学位证照片")]),_c('div',{staticClass:"images-popup-content"},[_c('div',{staticClass:"images-popup-box degreeImg",class:{ noBack: _vm.img1 }},[(_vm.imgload1)?_c('div',{staticClass:"load-style degreeImg"},[_vm._m(0)]):_vm._e(),_c('img',{class:{
            imgStyle1: _vm.imgStyle1,
            imgStyle2: !_vm.imgStyle1,
            opcity0: _vm.imgload1,
          },attrs:{"src":_vm.img1,"data-src":_vm.img1,"alt":"","id":"img1"}}),_c('input',{staticClass:"avatar",attrs:{"type":"file","name":"file","id":"avatar3"},on:{"change":function($event){return _vm.imgChange($event, 'avatar3', '1')}}})]),_c('p',[_vm._v("学位证")]),_c('div',{staticClass:"images-popup-box degreeImg",class:{ noBack: _vm.img2 }},[(_vm.imgload2)?_c('div',{staticClass:"load-style degreeImg"},[_vm._m(1)]):_vm._e(),_c('img',{class:{
            imgStyle1: _vm.imgStyle2,
            imgStyle2: !_vm.imgStyle2,
            opcity0: _vm.imgload1,
          },attrs:{"src":_vm.img2,"data-src":_vm.img2,"alt":"","id":"img2"}}),_c('input',{staticClass:"avatar",attrs:{"type":"file","name":"file","id":"avatar4"},on:{"change":function($event){return _vm.imgChange($event, 'avatar4', '2')}}})]),_c('p',[_vm._v("毕业证书")])]),_c('div',{staticClass:"images-popup-btns"},[_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){$event.stopPropagation();return _vm.close.apply(null, arguments)}}},[_vm._v("确定")])],1)]),_c('iframe',{staticStyle:{"display":"none"},attrs:{"id":"myIframe","name":"hideIframe"}})])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"load-style-div"},[_c('i',{staticClass:"el-icon-loading"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"load-style-div"},[_c('i',{staticClass:"el-icon-loading"})])}]

export { render, staticRenderFns }