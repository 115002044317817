<template>
  <div class="popup-layer">
    <div class="popup-layer-bg"></div>
    <div class="deduct-popup-main">
      <div class="deduct-popup-mian-herder">
        {{ deductItem.title
        }}<span @click="deductClose">
          <i class="el-icon-close" style="font-size: 18px"></i>
        </span>
      </div>
      <div class="deduct-popup-mian-content">
        <div class="standard">个税扣除定额标准</div>
        <div class="deduct-list">
          <el-radio-group v-model="radio">
            <div
              class="deduct-list-box"
              v-for="(item, index) in dataList.list"
              :key="index"
            >
              <slot v-if="deductItem.list === 'dabing'">
                <el-radio
                  :label="item.id"
                  @click.native.prevent="changeFun(item)"
                  >按<input
                    type="text"
                    class="dabingNum"
                    v-model="dabingNum"
                    @click.stop=""
                    placeholder="请输入"
                  />
                  元扣除</el-radio
                ></slot
              >
              <slot v-else-if="deductItem.list === 'shanyang' && index === 1">
                <el-radio
                  :label="item.id"
                  @click.native.prevent="changeFun(item, index)"
                  >按 分摊金额定额扣除
                  <input
                    type="text"
                    class="dabingNum"
                    @click.stop=""
                    v-model="shanyangNum"
                    placeholder="请输入"
                  />元
                </el-radio></slot
              >
              <slot v-else>
                <el-radio
                  :label="item.id"
                  @click.native.prevent="changeFun(item)"
                  >{{ item.text }}</el-radio
                ></slot
              >
            </div>
          </el-radio-group>
        </div>
        <div class="deduct-text-list">
          <div
            class="deduct-text-list-box"
            v-for="(item, index) in dataList.context"
            :key="index"
          >
            {{ item.text }}
          </div>
          <div class="deduct-text-list-box deductRed">
            提示：请务必保证在国家(个人所得税)APP提交完个税专项扣除信息后，再填写本项内容，否则会导致无法申报个税。
          </div>
          <div class="deductBtn-mian">
            <el-button type="primary" @click="deductSubmit" class="deductBtn"
              >保 存</el-button
            >
            <el-button type class="deductBtn deductClose" @click="deductClose"
              >取 消</el-button
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      listArray: {
        zinv: this.$dictionaries.employeeModel.zinv,
        jixu: this.$dictionaries.employeeModel.jixu,
        daikuan: this.$dictionaries.employeeModel.daikuan,
        zhufang: this.$dictionaries.employeeModel.zhufang,
        shanyang: this.$dictionaries.employeeModel.shanyang,
        dabing: this.$dictionaries.employeeModel.dabing,
      },
      deductTitle: "",
      radio: "",
      deductType: "",
      dataList: [],
      dabingNum: "",
      shanyangNum: "",
      data: {},
      popupSelectData: {},
    };
  },
  mounted() {
    this.dataList = this.listArray[this.deductItem.list];
    this.radio = this.deductItem.value;
    this.popupSelectData.id = this.deductItem.value;
    if (this.deductItem.list === "dabing") {
      this.dabingNum = this.deductItem.health;
      this.popupSelectData.dabingNum = this.deductItem.health;
      if (this.dabingNum === 0) {
        this.dabingNum = "";
        this.popupSelectData.dabingNum = "";
      }
    }
    if (this.deductItem.list === "shanyang") {
      if (this.deductItem.oldAve) {
        this.shanyangNum = this.deductItem.oldAve;
        this.popupSelectData.shanyangNum = this.deductItem.oldAve;
        if (this.shanyangNum === 0) {
          this.shanyangNum = "";
          this.popupSelectData.shanyangNum = "";
        }
      }
    }
    this.$forceUpdate();
  },
  methods: {
    deductClose() {
      this.$emit("cascadeClose");
    },
    changeFun(item) {
      this.popupSelectData = item;
      if (this.deductItem.list === "dabing" && !this.dabingNum * 1) {
        this.$store.commit("MyMessage", "请输入金额");
        return;
      }
      if (this.deductItem.list === "shanyang") {
        if (item.id === 2 && !this.shanyangNum * 1) {
          this.$store.commit("MyMessage", "请输入金额");
          return;
        }
      }
      item.id === this.radio ? (this.radio = "") : (this.radio = item.id);
      this.$nextTick(() => {
        if (this.radio === "") {
          this.popupSelectData = {};
        } else {
          if (this.deductItem.list === "shanyang") {
            if (item.id === 2) {
              this.popupSelectData.shanyangNum = this.shanyangNum;
            } else {
              this.popupSelectData.shanyangNum = null;
            }
          } else if (this.deductItem.list === "dabing") {
            this.popupSelectData.dabingNum = this.dabingNum;
          }
        }
        this.$forceUpdate();
      });
    },
    deductSubmit() {
      if (this.deductItem.list === "shanyang") {
        if (this.radio === 2) {
          this.popupSelectData.shanyangNum = this.shanyangNum;
        } else {
          this.popupSelectData.shanyangNum = null;
        }
      } else if (this.deductItem.list === "dabing") {
        this.popupSelectData.dabingNum = this.dabingNum;
      }
      this.$emit(
        "deductSave",
        this.popupSelectData,
        this.deductItem,
        this.dataList.list
      );
    },
  },
  props: {
    deductItem: {
      type: Object,
      default() {
        return null;
      },
    },
  },
};
</script>

<style lang="less" scoped>
.deduct-popup-main {
  width: 700px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -350px;
  margin-top: -300px;
  background: #fff;
  border-radius: 5px;
  // padding-bottom: 10px;
  .deduct-popup-mian-herder {
    height: 48px;
    line-height: 48px;
    width: 100%;
    text-align: center;
    background: #eaeaea;
    font-size: 16px;
    color: #262626;
    border-bottom: 1px solid rgba(219, 219, 219, 0.5);
    position: relative;
    span {
      position: absolute;
      right: 0;
      height: 48px;
      line-height: 48px;
      padding: 0 10px;
      color: #999;
      font-size: 14px;
      cursor: pointer;
    }
  }
  .deduct-popup-mian-content {
    width: 100%;
    height: calc(100% - 49px);
    .standard {
      width: calc(100% - 60px);
      padding: 0 30px;
      text-align: left;
      color: #999;
      height: 30px;
      line-height: 30px;
    }
    .deduct-list {
      width: calc(100% - 60px);
      padding: 0 30px;
      text-align: left;
      .el-radio-group {
        width: 100%;
      }
      .deduct-list-box {
        width: 100%;
        height: 40px;
        line-height: 50px;
        border-bottom: 1px solid rgba(219, 219, 219, 0.5);
        .dabingNum {
          margin: 0 10px;
          width: 100px;
          &::placeholder {
            color: #dbdbdb;
          }
        }
      }
    }
    .deduct-text-list {
      width: calc(100% - 60px);
      padding: 0 30px;
      margin-top: 20px;
      .deduct-text-list-box {
        width: 100%;
        line-height: 22px;
        color: #999999;
        font-size: 12px;
        margin-bottom: 10px;
        text-align: left;
        &.deductRed {
          color: #ed4956;
        }
      }
    }
  }
}
.deductBtn-mian {
  width: 100%;
  text-align: center;
  margin: 20px 0;
}
.deductBtn {
  width: 200px;
  display: inline-block;
}
</style>
<style lang="less">
.deduct-list-box {
  .el-radio__label {
    font-size: 18px;
  }
}
</style>