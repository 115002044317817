<template>
  <div class="declare-popup">
    <div class="declare-main">
      <div class="department-header department-width">
        <div class="department-link" @click="closeFun">
          <i class="el-icon-arrow-left"></i>返回
        </div>
        {{ popupData.title }}
        <div class="department-btn">
          <div class="del-btn" @click="submitFun">删除</div>
        </div>
      </div>
      <div class="declare-main-content">
        <div class="declare-list">
          <div class="declare-box">
            <div class="declare-box-l">他(她)是我的</div>
            <div class="declare-box-r">
              <el-select v-model="item.zinv" placeholder="请选择">
                <el-option
                  v-for="item in options1"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="declare-box">
            <div class="declare-box-l">证件类型</div>
            <div class="declare-box-r">
              <el-select
                v-model="item.idType"
                @change="idTypeFun"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in idTypeData"
                  :key="item.id"
                  :label="item.text"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="declare-box">
            <div class="declare-box-l">国籍(地区)</div>
            <div class="declare-box-r">
              <div
                class="declare-desc"
                v-if="
                  item.idType === '1' ||
                  item.idType === '4' ||
                  item.idType === '7' ||
                  item.idType === '8' ||
                  item.idType === '2'
                "
              >
                {{ nationalityText }}
              </div>
              <el-select v-else v-model="item.nationality" placeholder="请选择">
                <el-option
                  v-for="item in options3"
                  :key="item.id"
                  :label="item.text"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="declare-box" v-if="item.idType !== '1'">
            <div class="declare-box-l">出生时间</div>
            <div class="declare-box-r">
              <date-picker
                v-model="item.birth"
                value-type="format"
                format="YYYY-MM-DD"
                class="data-picker-style"
                :clearable="false"
                :editable="false"
                placeholder="受教育开始时间"
              ></date-picker>
              <i class="el-icon-arrow-down"></i>
            </div>
          </div>
          <div class="declare-box">
            <div class="declare-box-l">证件号码</div>
            <div class="declare-box-r">
              <input type="text" v-model="item.idNumber" placeholder="请输入" />
            </div>
          </div>
          <div class="declare-box">
            <div class="declare-box-l">姓名</div>
            <div class="declare-box-r">
              <input type="text" v-model="item.name" placeholder="请输入" />
            </div>
          </div>
          <div class="declare-btns">
            <el-button type="primary" @click="saveFun">保 存</el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/zh-cn";
export default {
  data() {
    return {
      idType: "1",
      nationalityText: "中华人民共和国",
      nationality: "1",
      value: "",
      check: "",
      select1: "",
      select2: "",
      select3: "",
      select4: "",
      options: [],

      list1: [
        {
          value: "1",
          label: "子",
        },
        {
          value: "2",
          label: "女",
        },
      ],
      list2: [
        {
          value: "1",
          label: "父母",
        },
        {
          value: "2",
          label: "其他",
        },
      ],
      options1: this.list1,
      options2: this.$dictionaries.employeeModel.idTypeData.list,
      options3: [],
      idTypeData: [
        {
          id: "1",
          text: "居民身份证",
        },
        {
          id: "2",
          text: "中国护照",
        },
        {
          id: "3",
          text: "港澳居民来往内地通行证",
        },
        {
          id: "4",
          text: "台湾居民来往内地通行证",
        },
        {
          id: "5",
          text: "外国护照",
        },
        {
          id: "6",
          text: "外国人永久居留身份证(外国人永久居留证) ",
        },
        {
          id: "7",
          text: "中华人民共和国港澳居民居住证",
        },
        {
          id: "8",
          text: "中华人民共和国台湾居民居住证",
        },
        {
          id: "9",
          text: "中华人民共和国外国人工作许可证(A类)",
        },
        {
          id: "10",
          text: "中华人民共和国外国人工作许可证(B类)",
        },
        {
          id: "11",
          text: "中华人民共和国外国人工作许可证(C类)",
        },
        {
          id: "12",
          text: "其他个人证件",
        },
      ],
      item: {
        zinv: "",
        idType: "1",
        nationality: "1",
        birth: "1",
        idNumber: "",
        name: "",
        stage: "",
        start: "",
        end: "",
        termination: "",
        school: "",
      },
    };
  },
  mounted() {
    if (this.type === "shanyang") {
      this.options1 = this.list2;
    }
    this.item = this.popupData;
  },
  props: {
    popupData: {
      type: Object,
      default() {
        return null;
      },
    },
    type: {
      type: String,
      default() {
        return null;
      },
    },
  },
  components: {
    DatePicker,
  },
  methods: {
    submitFun() {
      this.$emit("detailsDel");
    },
    idTypeFun() {
      console.log(this.item.idType);
      if (this.item.idType === "1") {
        this.nationalityText = "中华人民共和国";
        this.item.nationality = "1";
        this.options3 = this.$dictionaries.countryData;
      } else if (this.item.idType === "4" || this.item.idType === "8") {
        this.nationalityText = "中国台湾";
        this.item.nationality = "3";
        this.options3 = this.$dictionaries.countryData;
      } else if (this.item.idType === "3" || this.item.idType === "7") {
        this.options3 = this.$dictionaries.gangao;
        this.item.nationality = "4";
      } else if (this.item.idType === "2") {
        this.options3 = this.$dictionaries.countryData;
        this.item.nationality = "1";
      } else {
        this.item.nationality = "";
        this.options3 = this.$dictionaries.countryData;
      }
    },
    closeFun() {
      // this.$route
      this.$emit("closeFun");
    },
    saveFun() {
      console.log(111);
      this.$emit("detailsSave", this.item);
    },
  },
};
</script>

<style lang="less" scoped>
.department-width {
  width: 480px;
  margin: 0 auto;
}
.declare-box-l {
  width: 120px;
}
.declare-box-r {
  width: calc(100% - 120px);
}
.del-btn {
  font-size: 15px;
  font-weight: 400;
  color: #e74b47;
  cursor: pointer;
}
</style>
