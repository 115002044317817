<template>
  <div class="warper-over">
    <div class="warper-subject">
      <div class="payroll-main-header">
        <div class="payroll-header-l">
          <span @click="toogle">
            <i class="el-icon-arrow-left"></i>返回人员列表
          </span>
        </div>
        <div class="payroll-header-r"></div>
      </div>
      <div class="personDetails-main">
        <div class="personDetails-header">
          <div class="header-l">
            <div class="header-l-logo">
              <img
                :src="SalaryStaff.head"
                v-if="SalaryStaff.head"
                class="modular-img"
                alt=""
              />
              <span v-else>{{ SalaryStaff.nickNameText }}</span>
            </div>
            <div class="header-l-name">{{ SalaryStaff.nickNameText }}</div>
            <div class="header-l-desc">{{ SalaryStaff.postName }}</div>
          </div>
          <div class="header-r">
            <div class="header-r-save" @click="saveClick">保 存</div>
          </div>
        </div>

        <div class="personDetails-nav">
          <div class="personDetails-nav-box" @click="navClick(1)">
            <div class="personDetails-nav-box-l">
              <svg-icon
                icon-class="gongzixinxi0"
                v-if="navIndex === 1"
                class="nav-icon"
              ></svg-icon>
              <svg-icon
                icon-class="gongzixinxi1"
                v-else
                class="nav-icon"
              ></svg-icon>
            </div>
            <div class="personDetails-nav-box-r">
              <p class="nav-p1">工资信息</p>
              <p
                class="nav-p2"
                style="color: #ea0200"
                v-if="SalaryStaff.isFinish === 0"
              >
                未完善岗位工资
              </p>
              <p class="nav-p3" v-else>¥ {{ SalaryStaff.deSalary }}</p>
            </div>
            <span class="nav-line" v-if="navIndex === 1"></span>
          </div>
          <div class="personDetails-nav-box" @click="navClick(2)">
            <div class="personDetails-nav-box-l">
              <svg-icon
                icon-class="shebaogongjijin0"
                v-if="navIndex === 2"
                class="nav-icon"
              ></svg-icon>
              <svg-icon
                icon-class="shebaogongjijin1"
                v-else
                class="nav-icon"
              ></svg-icon>
            </div>
            <div class="personDetails-nav-box-r">
              <p class="nav-p1">社保公积金</p>
              <p class="nav-p4" v-if="SalaryStaff.isPaymentSS !== 2">未增员</p>
              <p class="nav-p5" v-else>已增员</p>
            </div>
            <span class="nav-line" v-if="navIndex === 2"></span>
          </div>
          <div class="personDetails-nav-box" @click="navClick(3)">
            <div class="personDetails-nav-box-l">
              <svg-icon
                icon-class="jibenxinxi0"
                v-if="navIndex === 3"
                class="nav-icon"
              ></svg-icon>
              <svg-icon
                icon-class="jibenxinxi1"
                v-else
                class="nav-icon"
              ></svg-icon>
            </div>
            <div class="personDetails-nav-box-r">
              <p class="nav-p1">基本信息</p>
              <!-- <p class="nav-p4">未完善</p> -->
              <p class="nav-p5" v-if="1">已完善</p>
            </div>
            <span class="nav-line" v-if="navIndex === 3"></span>
          </div>
        </div>
        <div class="personDetails-content">
          <slot v-if="personShow">
            <gzxx
              :details="SalaryStaff"
              :navIndex="navIndex"
              v-show="navIndex === 1"
            ></gzxx>
            <sbgjj
              :details="SalaryStaff"
              :eData="employeeNoun"
              :navIndex="navIndex"
              v-show="navIndex === 2"
            ></sbgjj>
            <jbxx
              :SalaryStaff="SalaryStaff"
              :navIndex="navIndex"
              v-show="navIndex === 3"
            ></jbxx>
          </slot>
        </div>
        <div class="personDetails-footer" v-if="navIndex !== 3">
          <div class="personDetails-nav">
            <div class="personDetails-nav-box">
              <div class="personDetails-nav-box-l">
                <svg-icon
                  icon-class="jibenxinxi0"
                  v-if="navIndex === 3"
                  class="nav-icon"
                ></svg-icon>
                <svg-icon
                  icon-class="jibenxinxi1"
                  v-else
                  class="nav-icon"
                ></svg-icon>
              </div>
              <div class="personDetails-nav-box-r">
                <p class="nav-p1">设置工资发放</p>
                <p class="nav-p5">缓发工资</p>
              </div>
            </div>
          </div>
          <div class="footer-main">
            <div
              class="footer-box footer-box-w1"
              :class="{ active: SalaryStaff.salaryStatus === 1 }"
              @click="SalaryStaff.salaryStatus = 1"
            >
              <div class="footer-box-l">
                <svg-icon
                  v-if="SalaryStaff.salaryStatus === 1"
                  icon-class="duigou-icon"
                  class="duigou-icon"
                ></svg-icon>
                <span v-else></span>
              </div>
              <div class="footer-box-r">
                <p class="footer-box-p1">正常发放</p>
                <p class="footer-box-p2">正常发放工资、社保、公积金</p>
              </div>
            </div>
            <div
              class="footer-box footer-box-w2"
              :class="{ active: SalaryStaff.salaryStatus === 2 }"
              @click="SalaryStaff.salaryStatus = 2"
            >
              <div class="footer-box-l">
                <svg-icon
                  v-if="SalaryStaff.salaryStatus === 2"
                  icon-class="duigou-icon"
                  class="duigou-icon"
                ></svg-icon>
                <span v-else></span>
              </div>
              <div class="footer-box-r">
                <p class="footer-box-p1">缓发工资</p>
                <p class="footer-box-p2">
                  社保、公积金、个税正常缴纳，不发工资
                </p>
              </div>
            </div>
            <div
              class="footer-box footer-box-w3"
              :class="{ active: SalaryStaff.salaryStatus === 3 }"
              @click="SalaryStaff.salaryStatus = 3"
            >
              <div class="footer-box-l">
                <svg-icon
                  v-if="SalaryStaff.salaryStatus === 3"
                  icon-class="duigou-icon"
                  class="duigou-icon"
                ></svg-icon>
                <span v-else></span>
              </div>
              <div class="footer-box-r">
                <p class="footer-box-p1">离职停薪</p>
                <p class="footer-box-p2">停薪后，该员工将从工资表删除</p>
              </div>
            </div>
            <div
              class="footer-box footer-box-w4"
              :class="{ active: SalaryStaff.salaryStatus === 4 }"
              @click="SalaryStaff.salaryStatus = 4"
            >
              <div class="footer-box-l">
                <svg-icon
                  v-if="SalaryStaff.salaryStatus === 4"
                  icon-class="duigou-icon"
                  class="duigou-icon"
                ></svg-icon>
                <span v-else></span>
              </div>
              <div class="footer-box-r">
                <p class="footer-box-p1">无工资</p>
                <p class="footer-box-p2">有社保、公积金，无工资</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <TheFooter></TheFooter>
  </div>
</template>

<script>
import bus from "@/util/bus";
import gzxx from "./components/gzxx";
import sbgjj from "./components/sbgjj";
import jbxx from "./components/jbxx";
import TheFooter from "@/components/TheFooter.vue";
import {
  querySalaryStaff,
  getEmployeeInfo,
  queryBuzhuButie,
  queryEmployeeNoun,
} from "@/http/api";
export default {
  data() {
    return {
      userId: sessionStorage.getItem("userId"),
      enterpriseId: sessionStorage.getItem("enterpriseId"),
      cityList: this.$dictionaries.employeeModel.cityMap,
      liList: this.$dictionaries.employeeModel.salaryStatus.list,
      date: this.$config.getDate(),
      SalaryStaff: {},
      employeeNoun: {},
      salaryStaffId: "",
      enterpriseEmployee: {},
      navIndex: 0,
      ffIndex: 1,
      personShow: false,
      saveNum: 0,
    };
  },
  components: {
    gzxx,
    sbgjj,
    jbxx,
    TheFooter,
  },
  mounted() {
    let query = this.$route.query;
    this.salaryStaffId = query.id;
    this.userId = query.user;
    this.querySalaryStaff();
    let that = this;
    bus.$on("saveSubmit", function (next) {
      if (next !== that.navIndex) that.navClick(next);
    });
  },
  methods: {
    saveClick() {
      this.saveNum = 0;

      bus.$emit("gzxx");
    },
    queryBuzhuButie() {
      let that = this;
      let param = {
        userId: this.userId,
        enterpriseId: this.enterpriseId,
      };
      queryBuzhuButie(param).then((res) => {
        // console.log("queryBuzhuButie", res);
        sessionStorage.setItem(
          "buzhuButie",
          JSON.stringify(res.data.subsidyCompany)
        );
        if (res.code == 0) {
          if (res.data.subsidy) {
            this.SalaryStaff.sum = 0;
            res.data.subsidy.forEach((element) => {
              this.SalaryStaff.sum += element.subsidyMoney;
            });
          }
          this.$forceUpdate();
        }
        this.navIndex = 1;
      });
    },
    navClick(index) {
      // console.log(bus);
      this.navIndex = index;
      this.$forceUpdate();
    },
    // 获取员工信息
    getEmployeeInfo(uid, eid) {
      let param = {
        enterpriseId: this.enterpriseId,
        userId: uid,
        employeeId: eid,
      };
      getEmployeeInfo(param).then((res) => {
        if (res.code === 0) {
          this.enterpriseEmployee = res.data;
        }
      });
    },
    querySalaryStaff(flag) {
      let param = {
        salaryStaffId: this.salaryStaffId,
        enterpriseId: this.enterpriseId,
      };
      querySalaryStaff(param).then((res) => {
        if (res.code === 0) {
          this.SalaryStaff = res.data;
          this.queryEmployeeNoun(this.SalaryStaff.salaryStaffId);
          this.bankCardNum = res.data.bankCardNum
            .replace(/\D+/g, "")
            .replace(/(\d{4})/g, "$1 ")
            .replace(/ $/, "");
          this.getEmployeeInfo(res.data.userId, res.data.employeeId);
          this.zxkc = false;
          if (
            this.SalaryStaff.deductionChild > 0 ||
            this.SalaryStaff.deductionTeach > 0 ||
            this.SalaryStaff.health > 0 ||
            this.SalaryStaff.housingLoan > 0 ||
            this.SalaryStaff.houseRent > 0 ||
            this.SalaryStaff.provideAged > 0
          ) {
            this.zxkc = true;
          }
          if (this.SalaryStaff.onTrialTermSalaryScale !== "") {
            if (
              this.SalaryStaff.onTrialTermSalaryScale.split(".")[1] === "00"
            ) {
              this.SalaryStaff.onTrialTermSalaryScale =
                this.SalaryStaff.onTrialTermSalaryScale.split(".")[0];
            }
          }
          if (this.SalaryStaff.bankProvinceId) {
            this.cityList.forEach((e) => {
              if (e.id === this.SalaryStaff.bankProvinceId) {
                this.SalaryStaff.bankProvince = e.text;
              }
            });
          }
          // this.SalaryStaff.bankCardNum = this.SalaryStaff.bankCardNum
          //   .replace(/\D+/g, "")
          //   .replace(/(\d{4})/g, "$1 ")
          //   .replace(/ $/, "");
          this.liList.forEach((element) => {
            if (this.SalaryStaff.salaryStatus === element.id * 1) {
              this.SalaryStaffColor = element.color;
              this.SalaryStaff.salaryStatusText = element.text;
            }
          });
          let time1 = this.SalaryStaff.jobTime.split(" ")[0];
          let time2 = time1.split("-")[0] + "-" + time1.split("-")[1];
          let time3 = this.date.thisMounth;
          if (flag === 2) {
            this.zxkc = true;
          }
          if (flag === 3) {
            this.SalaryStaff.isFinish = 1;
          }
          const regMobile = /^1(3|4|5|6|7|8|9)\d{9}$/;
          if (regMobile.test(this.SalaryStaff.nickName)) {
            this.SalaryStaff.nickNameText =
              "*" +
              this.SalaryStaff.nickName.substr(
                this.SalaryStaff.nickName.length - 2,
                2
              );
            this.nickNameText =
              "*" +
              this.SalaryStaff.nickName.substr(
                this.SalaryStaff.nickName.length - 2,
                2
              );
          } else {
            this.SalaryStaff.nickNameText = this.SalaryStaff.nickName.substr(
              this.SalaryStaff.nickName.length - 2,
              2
            );
            this.nickNameText = this.SalaryStaff.nickName;
          }
          let date = new Date();
          let date1 = new Date(this.SalaryStaff.onTrialTermEnd);
          if (this.SalaryStaff.onTrialTermEnd) {
            if (date > date1) {
              this.dateFlag = false;
            } else {
              if (
                this.SalaryStaff.onTrialTermEnd ===
                this.SalaryStaff.onTrialTermBegin
              ) {
                this.dateFlag = false;
              } else {
                this.dateFlag = true;
              }
            }
          }
          this.personShow = true;

          // this.wagePayable();
          console.log(this.SalaryStaff);

          this.queryBuzhuButie();
          if (this.SalaryStaff.isPaymentSS === 2) {
          }
          return;
        }
      });
    },
    queryEmployeeNoun(salaryStaffId) {
      let that = this;
      let param = {
        enterpriseId: this.enterpriseId,
        salaryStaffId: salaryStaffId,
      };
      queryEmployeeNoun(param).then((res) => {
        if (res.code === 0) {
          this.employeeNoun = res.data;
          this.employeeNoun.name = this.SalaryStaff.nickName;
          if (this.employeeNoun.provinceCode !== "") {
            if (
              this.employeeNoun.provinceCode === "110000" ||
              this.employeeNoun.provinceCode === "310000" ||
              this.employeeNoun.provinceCode === "500000" ||
              this.employeeNoun.provinceCode === "120000"
            ) {
              this.cascadeText =
                this.employeeNoun.provinceName +
                " - " +
                this.employeeNoun.areaName;
            } else {
              this.cascadeText =
                this.employeeNoun.provinceName +
                " - " +
                this.employeeNoun.cityName;
            }
            if (this.cascadeText === "/") {
              this.cascadeText = "";
            }
          }
          this.selectedOptions = [
            this.employeeNoun.provinceCode,
            this.employeeNoun.cityCode,
            this.employeeNoun.areaCode,
          ];
          this.$forceUpdate();
        }
      });
    },
    toogle() {
      this.$router.push("/payrollModule");
    },
  },
};
</script>


<style lang="less" scoped>
@import "../../assets/less/warper.less";
.warper-over {
  width: 100%;
  height: 100%;
  overflow-y: auto;
}
.warper-main-content {
  overflow: hidden;
}
.personDetails-header {
  width: 100%;
  height: 40px;
  margin-top: 20px;
  text-align: left;
}
.header-l {
  width: 70%;
  float: left;
}
.header-r {
  width: 30%;
  float: right;
  text-align: right;
  .header-r-save {
    width: 98px;
    height: 38px;
    line-height: 38px;
    background: #518eff;
    border: 1px solid #3976e8;
    border-radius: 4px;
    font-size: 14px;
    font-weight: bold;
    color: #ffffff;
    display: inline-block;
    text-align: center;
    cursor: pointer;
  }
}
.header-l-logo {
  width: 40px;
  height: 40px;
  border-radius: 4px;
  float: left;
  span {
    width: 100%;
    height: 100%;
    background: #4c8afc;
    line-height: 40px;
    text-align: center;
    color: #fff;
    display: inline-block;
    border-radius: 4px;
  }
  img {
    border-radius: 4px;
    width: 100%;
    height: 100%;
    display: inline-block;
  }
}
.header-l-name {
  font-size: 24px;
  font-weight: 800;
  color: #333333;
  line-height: 40px;
  text-align: left;
  float: left;
  margin-left: 16px;
}
.header-l-desc {
  font-size: 12px;
  font-weight: 400;
  color: #999999;
  line-height: 40px;
  text-align: left;
  float: left;
  margin-left: 10px;
}
.personDetails-nav {
  margin-top: 44px;
  height: 40px;
  padding-bottom: 20px;
  border-bottom: 1px solid #dbdbdb;
  width: 100%;
  text-align: left;
}
.personDetails-nav-box {
  display: inline-block;
  width: 160px;
  position: relative;
  height: 100%;
  border-right: 1px solid #eee;
  padding-left: 20px;
  cursor: pointer;
  &:first-child {
    padding-left: 0;
  }
  &:last-child {
    border-right: none;
  }
  .nav-line {
    position: absolute;
    width: 100%;
    height: 2px;
    background: #333333;
    left: 0;
    bottom: -21px;
  }
}
.nav-icon {
  width: 40px;
  height: 40px;
}
.personDetails-nav-box-l {
  float: left;
}
.personDetails-nav-box-r {
  margin-left: 12px;
  float: left;
  .nav-p1 {
    font-size: 14px;
    font-weight: 800;
    color: #333333;
    margin-bottom: 4px;
  }
  .nav-p2 {
    font-size: 12px;
    font-weight: 400;
    color: #ea0200;
  }
  .nav-p3 {
    font-size: 12px;
    font-weight: 400;
    color: #333333;
  }
  .nav-p4 {
    font-size: 12px;
    font-weight: 400;
    color: #f58e21;
  }
  .nav-p5 {
    font-size: 12px;
    font-weight: 400;
    color: #999999;
  }
}
.personDetails-content {
  overflow: hidden;
  margin-bottom: 40px;
  min-height: 332px;
}
.footer-main {
  width: 100%;
  overflow: hidden;
  padding-bottom: 70px;
  .footer-box {
    height: 68px;
    background: #ffffff;
    border: 1px solid #e3e5e6;
    border-radius: 6px;
    margin-top: 28px;
    margin-right: 20px;
    cursor: pointer;
    float: left;
    &.active {
      background: #e8f0fe;
      border: 1px solid #3976e8;
      .footer-box-r {
        .footer-box-p1 {
          color: #3976e8;
        }
        .footer-box-p2 {
          color: #3976e8;
        }
      }
    }
    &.footer-box-w1 {
      width: 226px;
    }
    &.footer-box-w2 {
      width: 283px;
    }
    &.footer-box-w3 {
      width: 231px;
    }
    &.footer-box-w4 {
      width: 193px;
    }
    .footer-box-l {
      width: 48px;
      float: left;
      height: 100%;
      position: relative;
      text-align: center;
      span {
        width: 20px;
        height: 20px;
        background: #ffffff;
        border: 1px solid #dbdbdb;
        border-radius: 50%;
        display: inline-block;
        position: relative;
        top: 25px;
      }
      .duigou-icon {
        width: 20px;
        height: 20px;
        position: relative;
        top: 25px;
      }
    }
    .footer-box-r {
      width: calc(100% - 48px);
      height: 68px;
      float: left;
      text-align: left;

      .footer-box-p1 {
        font-size: 14px;
        font-weight: bold;
        color: #333333;
        padding: 11px 0 6px;
      }
      .footer-box-p2 {
        font-size: 12px;
        font-weight: 400;
        color: #999999;
      }
    }
  }
}
</style>

