<template>
  <div
    class="addStaff-box"
    :class="{
      redLine: redLine,
      noClickMain: staffType === 'quitStaff',
      marginTop: item.field === 'status',
      jinjiLianxidianhua: item.field === 'jinjiLianxidianhua',
    }"
  >
    <div class="noClick" v-if="staffType === 'quitStaff'"></div>
    <div
      class="addStaff-box-left"
      :class="{
        addStaffWidth1:
          item.field === 'bankProvinceId' ||
          item.field === 'isDeductionCost' ||
          item.field === 'ruzhiqingxing',
        addStaffWidth2: item.field === 'identity',
        addStaffWidth3: item.field === 'onTrialTermBegin',
        descStyle: item.desc,
        descStyle1: item.field === 'contactDetail',
      }"
    >
      <p class="desc-p1">{{ item.text }}</p>
      <p class="desc-p2" v-if="item.desc">{{ item.desc }}</p>
    </div>
    <slot v-if="item.type === 'input' || item.type === 'textarea'">
      <inputBox
        :item="item"
        @staffParent="staffParent"
        :staffType="staffType"
      ></inputBox>
    </slot>
    <slot v-else-if="item.type === 'select'">
      <selectBox
        :item="item"
        @staffParent="staffParent"
        :staffType="staffType"
      ></selectBox>
    </slot>
    <slot v-else-if="item.type === 'selects'">
      <selectsBox :item="item" @staffParent="staffParent"></selectsBox>
    </slot>
    <slot v-else-if="item.type === 'city'">
      <cityBox :item="item" @staffParent="staffParent"></cityBox>
    </slot>
    <slot v-else-if="item.type === 'date'">
      <dateBox
        :item="item"
        @staffParent="staffParent"
        :staffType="staffType"
      ></dateBox>
    </slot>
    <slot v-else-if="item.type === 'dates'">
      <datesBox :item="item" @staffParent="staffParent"></datesBox>
    </slot>
    <slot v-else-if="item.type === 'image'">
      <imgBox :item="item" @staffParent="staffParent"></imgBox>
    </slot>
    <slot v-else-if="item.type === 'images'">
      <imgsBox :item="item" @staffParent="staffParent"></imgsBox>
    </slot>
    <slot v-else-if="item.type === 'status'">
      <statusBox :item="item" @staffParent="staffParent"></statusBox>
    </slot>
    <slot v-else-if="item.type === 'subsidy'">
      <subsidyBox
        :item="item"
        @staffParent="staffParent"
        :staffType="staffType"
      ></subsidyBox>
    </slot>
  </div>
</template>

<script>
import bus from "@/util/bus";
import inputBox from "./inputBox";
import selectBox from "./selectBox";
import selectsBox from "./selectsBox";
import dateBox from "./dateBox";
import datesBox from "./datesBox";
import cityBox from "./cityBox";
import imgBox from "./imgBox";
import imgsBox from "./imgsBox";
import statusBox from "./statusBox";
import subsidyBox from "./subsidyBox";
export default {
  data() {
    return {
      redLine: false,
    };
  },
  mounted() {
    let that = this;
    // console.log(this.staffType);
    if (this.staffType === "quitStaff") {
      this.item.tips = "";
    }
    if (that.item.require) {
      bus.$on(that.item.field, function (flag) {
        if (flag) {
          that.redLine = true;
        } else {
          that.redLine = false;
        }
      });
      bus.$on("hideLines", function () {
        that.redLine = false;
      });
    }
  },
  props: {
    item: {
      type: Object,
      default() {
        return null;
      },
    },
    ajaxData: {
      type: Object,
      default() {
        return null;
      },
    },
    index1: {
      type: String,
      default() {
        return null;
      },
    },
    index2: {
      type: Number,
      default() {
        return null;
      },
    },
    staffType: {
      type: String,
      default() {
        return null;
      },
    },
  },
  provide() {
    return {
      ajaxData: this.ajaxData,
    };
  },
  watch: {
    ajaxData: {
      handler(newValue, oldValue) {
        if (newValue.name) {
          this.name = newValue.name;
        } else {
          this.name = "员工";
        }
      },
      deep: true,
    },
  },
  methods: {
    staffParent(data) {
      // console.log(data);
      this.$emit("staffParent", data, this.index1, this.index2);
    },
  },
  components: {
    inputBox,
    selectBox,
    selectsBox,
    dateBox,
    datesBox,
    cityBox,
    imgBox,
    imgsBox,
    statusBox,
    subsidyBox,
  },
};
</script>

<style lang="less" scoped>
.addStaff-list-bottom {
  .addStaff-box-left .desc-p1 {
    color: #999;
  }
}
.addStaffWidth1 {
  width: 144px;
}
.addStaffWidth2 {
  width: 98px;
}
.addStaffWidth3 {
  width: 70px;
}
.noClick {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 999;
  opacity: 0;
}
.descStyle {
  .desc-p1 {
    position: relative;
    top: -8px;
  }
  .desc-p2 {
    position: relative;
    top: -20px;
    font-size: 12px;
    color: #999;
  }
}
.descStyle1 {
  .desc-p1 {
    position: relative;
    width: 105%;
  }
}
.marginTop {
  margin-top: 40px;
}
.jinjiLianxidianhua {
  .addStaff-box-left {
    width: 120px;
  }
  .addStaff-box-right {
    width: calc(100% - 120px);
  }
}
</style>
<style lang="less">
.addStaff-list {
  .noClickMain {
    position: relative;
    .addStaff-box-right {
      .xiala {
        display: none !important;
      }
      .addStaff-text,
      .addStaff-tips,
      .el-textarea .el-textarea__inner,
      input {
        width: 100% !important;
        padding-right: 0 !important;
      }
      .textStyle {
        color: #bbb;
      }
      .addStaff-tips {
        // display: none;
      }
    }
  }
}
</style>