<template>
  <div class="staffbox-div sHide">
    <div class="staffbox-tab" @click.stop="popupShowClick($event)">
      <div class="main-div-c">
        <div class="addStaff-tips sHide" v-if="!child.value">
          {{ child.tips }}
        </div>
        <div class="addStaff-text sHide" v-else>
          <span
            class="sHide"
            style="line-height: 20px; display: inline-block"
            >{{ showText }}</span
          >
        </div>
        <div
          class="staff-popup fadeIn sHide"
          :style="{
            left: popupLeft + 'px',
            bottom: popupTop + 'px',
          }"
          v-if="popupShow"
        >
          <Triangle direction="bottom" left="290" color="#cccccc"></Triangle>
          <div class="staff-popup-header sHide">
            请给<span class="sHide">{{ name }}</span
            >选择{{ child.text }}
          </div>
          <div class="staff-popup-content sHide">
            <div
              class="staff-popup-box sHide"
              v-for="(item, i) in child.arrayList"
              :key="i"
              @click.stop="listClick(item)"
            >
              <div class="staff-popup-box-l sHide">
                <svg-icon
                  v-if="item.select"
                  icon-class="duigou-yes"
                  class="duigou sHide"
                ></svg-icon>
                <svg-icon
                  v-if="!item.select"
                  icon-class="duigou-no"
                  class="duigou sHide"
                ></svg-icon>
              </div>
              <div class="staff-popup-box-r sHide">
                {{ item.text }}
              </div>
            </div>
          </div>
          <div class="staff-popup-footer sHide">
            <div class="footer-btn1 sHide" @click.stop="submit">确定</div>
            <div class="footer-btn2 sHide" @click.stop="popupShow = false">
              取消
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="main-div-r" v-if="showIcon && child.value"> -->
      <div class="main-div-r">
        <svg-icon icon-class="jiantou_shen" class="jiantou-icon"></svg-icon>
      </div>
    </div>
  </div>
</template>

<script>
import bus from "@/util/bus";
export default {
  data() {
    return {
      userId: sessionStorage.getItem("userId"),
      enterpriseId: sessionStorage.getItem("enterpriseId"),
      child: {},
      showText: "",
      popupShow: false,
      popupTop: "",
      popupLeft: "",
      array: [],
      name: "员工",
    };
  },
  inject: ["ajaxData"],
  mounted() {
    let that = this;
    this.child = this.item;
    if (this.item.value) {
      this.array = that.child.value.split(",");
      this.showText = "";
      this.array.forEach((e1, i1) => {
        this.item.arrayList.forEach((element) => {
          if (element.id === e1) {
            element.select = true;
            if (i1 === this.array.length - 1) {
              this.showText += element.text;
            } else {
              this.showText += element.text + ",";
            }
          }
        });
      });
    }
    // console.log(this.child);
    if (this.ajaxData.name) {
      this.name = this.ajaxData.name;
    }
    bus.$on("hidePopup", function (data) {
      that.popupShow = false;
    });
    document.addEventListener("click", function (e) {
      if (typeof e.target.className === "string") {
        if (e.target.className.indexOf("sHide") === -1) {
          that.popupShow = false;
          that.$forceUpdate();
        }
      }
    });
  },
  methods: {
    popupShowClick(e) {
      bus.$emit("hidePopup");
      this.popupShow = true;
      let H1 =
        document.body.clientHeight -
        e.currentTarget.getBoundingClientRect().bottom;
      this.popupLeft = e.currentTarget.getBoundingClientRect().left - 80;
      if (
        this.$route.path === "/detailsStaff" ||
        this.$route.path === "/addStaff"
      ) {
        this.popupLeft = this.popupLeft + 40;
      }
      this.popupTop = H1 + 40;
    },
    listClick(item) {
      item.select = !item.select;
    },
    submit() {
      let value = "";
      this.showText = "";
      this.item.arrayList.forEach((e, i) => {
        if (e.select) {
          value += e.id + ",";
          this.showText += e.text + ",";
        }
      });
      if (value) {
        value = value.substring(0, value.length - 1);
        this.showText = this.showText.substring(0, this.showText.length - 1);
      }
      this.child.value = value;
      if (!value) {
        this.$store.commit("MyMessage", "已取消选择");
      } else {
        this.$store.commit("MyMessage", "已选择：" + this.showText);
      }
      let param = {
        field: this.child.field,
        value: this.child.value,
        type: this.child.type,
      };
      this.$emit("staffParent", param);
      this.popupShow = false;
      this.$forceUpdate();
    },
  },
  props: {
    item: {
      type: Object,
      default() {
        return null;
      },
    },
  },
};
</script>
<style lang="less" scoped>
@import "../payroll.less";
@import "../../../../assets/less/popup.less";

.main-div-c {
  position: relative;
}
</style>