<template>
  <div class="popup-layer">
    <div class="popup-layer-bg"></div>
    <div class="transition-page">
      <div class="popup-layer-close" @click="rosterClose">
        <svg-icon icon-class="popup-close"></svg-icon>
      </div>
      <div class="transition-title">作废工资</div>
      <div class="transition-content">
        <div class="transition-content-top">
          <!-- <svg-icon icon-class="shenbaoshibai" class="xlsx"></svg-icon> -->
          <!-- <svg-icon icon-class="zuofei_gongzi"></svg-icon> -->
          <svg-icon icon-class="zuofei_gongzi" class="xlsx"></svg-icon>
          <p class="transition-p1">{{ monthText }}月工资表 作废中</p>
          <p class="transition-p2">作废工资 请重新申报</p>

          <loadingCss style="margin-top: 30px"></loadingCss>
          <div class="guocheng" v-if="0">
            <p>申报人数： {{ totalCount }}人</p>
            <p>本期收入总额： {{ deSalary }}元</p>
            <p>应补退税额： {{ incomeTax }}元</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  queryMonthSalaryListV2,
  findEmployeeSalaryStatusV2,
  queryShenbaoStatusV2,
} from "@/http/api";
export default {
  data() {
    return {
      // userId: this.$getCookie("userId"),
      // enterpriseId: this.$getCookie("enterpriseId"),

      enterpriseId: sessionStorage.getItem("enterpriseId"),
      userId: sessionStorage.getItem("userId"),
      flag: 1,
      flags: {
        flag1: false,
        flag2: false,
        flag3: false,
        flag4: false,
        flag5: false,
      },
      status: "",
      month: "",
      monthText: "",
      batch: "",
      deSalary: "",
      incomeTax: "",
      totalCount: "",
      leaveFlag: true,
    };
  },
  mounted() {
    this.month = this.$route.query.month;
    this.monthText = this.month.split("-")[1];
    this.batch = this.$route.query.batch;
    this.leaveFlag = true;
    this.queryMonthSalaryListV2();
    // this.findEmployeeSalaryStatusV2();
    // this.queryShenbaoStatusV2();
  },
  destroyed() {
    this.leaveFlag = false;
    this.$forceUpdate();
  },
  methods: {
    rosterClose() {
      this.$router.push("/payrollRecord");
    },
    queryShenbaoStatusV2() {
      let param = {
        enterpriseId: this.enterpriseId,
        month: this.month,
        userId: this.userId,
      };
      queryShenbaoStatusV2(param).then((res) => {
        console.log(res);
      });
    },
    findEmployeeSalaryStatusV2() {
      let param = {
        enterpriseId: this.enterpriseId,
        month: this.month,
        batch: this.batch,
        pageNum: 1,
        pageSize: 999,
      };
      findEmployeeSalaryStatusV2(param).then((res) => {
        console.log(res);
        if (res.code === 0) {
          this.deSalary = res.data.comprehensive.deSalary;
          this.incomeTax = res.data.comprehensive.incomeTax;
          this.totalCount = res.data.comprehensive.totalCount;
        }
      });
    },

    queryMonthSalaryListV2() {
      let param = {
        enterpriseId: this.enterpriseId,
        userId: this.userId,
      };
      queryMonthSalaryListV2(param).then((res) => {
        if (res.code === 0) {
          let date = this.month.split("-")[0] + this.month.split("-")[1];
          res.data.forEach((e) => {
            if (e.month + "" === date) {
              e.baoshuiList.forEach((e1) => {
                if (e1.month + "" === date) {
                  this.status = e1.status;
                  if (this.status === 60) {
                    if (e1.zuofeiStatus === 0) {
                      this.$emit("tFun");
                    }
                    if (e1.zuofeiStatus === 505) {
                      this.$emit("tFun");
                    }
                    setTimeout(() => {
                      if (this.leaveFlag) this.queryMonthSalaryListV2();
                    }, 5000);
                  } else {
                    this.$emit("tFun");
                  }
                }
              });
            }
          });
        }
      });
    },
    goFun(str) {
      this.$emit("tFun");
      return;
      if (str === "/payrollRecord") {
        this.$router.push(str);
      } else {
        this.$router.push({
          path: str,
          query: {
            month: this.month,
            batch: this.batch,
          },
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.loading-icon {
  width: 24px;
  height: 24px;
  margin-top: 30px;
}

.xlsx {
  font-size: 36px;
  padding: 130px 0 10px;
}
.btn-link {
  padding: 0 20px;
  display: inline-block;
  height: 34px;
  line-height: 34px;
  cursor: pointer;
  background: #f4f4f4;
  border: 1px solid #f4f4f4;
  font-size: 16px;
  font-weight: 400;
  color: #262626;
  margin-right: 10px;
  border-radius: 3px;
}
.btn-footer {
  width: 360px;
  margin: 20px auto 0;
  height: 54px;
  line-height: 54px;
  border-radius: 6px;
  font-size: 20px;
  padding: 0;
  &.btn-repeat {
    background: #ffffff;
    border: 1px solid #4c8afc;
    color: #4c8afc;
    // font-weight: bold;
  }
}
.transition-page {
  // width: 1280px;
  // height: 100%;
  width: 460px;
  height: 450px;
  margin: 0 auto;
  text-align: center;
  background: #ffffff;
  opacity: 1;
  border-radius: 6px;
  z-index: 999;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -230px;
  margin-top: -305px;
  .transition-title {
    font-size: 20px;
    padding: 28px 0 0px;
    text-align: center;
    color: #262626;
    font-weight: bold;
  }
  .transition-content {
    .transition-p1 {
      color: #ff931e;
      font-size: 20px;
      padding-bottom: 5px;
    }
    .transition-p2 {
      color: #bbb;
      font-size: 12px;
    }
    .guocheng {
      width: 360px;
      margin: 60px auto;
      text-align: left;
      p {
        position: relative;
        font-size: 16px;
        margin-bottom: 10px;
        color: #999;
        i {
          position: absolute;
          left: -20px;
          top: 1px;
          font-size: 16px;
        }
        .span1 {
          position: absolute;
          right: 0;
          top: 0px;
          font-size: 16px;
          color: #33c481;
        }
        .span2 {
          position: absolute;
          right: 0;
          top: 0px;
          font-size: 16px;
          // color: #e75850;
          color: #bbb;
        }
      }
    }
  }
}
</style>