<template>
  <div class="department user-select">
    <div class="department-mian">
      <div class="department-header department-width">
        <div class="department-link" @click="gotoFun('/payrollModule')">
          <i class="el-icon-arrow-left"></i>返回
        </div>
        {{ nickNameText }} - 工资信息
      </div>
      <div class="department-list department-width">
        <div class="tips-box" v-if="sbyyList.length > 0">
          <span v-for="(e, i) in sbyyList" :key="i">
            <slot v-if="sbyyList.length > 1">{{ i + 1 }}、</slot>{{ e.title }}
          </span>
        </div>
        <div class="personInfo-border">
          <div class="personInfo-main" v-if="personShow">
            <div class="personInfo-main-left">
              <div class="personInfo-left-box">
                <div
                  class="base-box"
                  @click="rosterFun"
                  style="border-bottom: none"
                >
                  <div class="base-user-left" style="width: 55px">
                    <span
                      class="modular-img"
                      :class="{ modularImg: !SalaryStaff.head }"
                    >
                      <img
                        :src="SalaryStaff.head"
                        v-if="SalaryStaff.head"
                        class="modular-img"
                        alt=""
                      />
                      <span v-else>{{ SalaryStaff.nickNameText }}</span>
                    </span>
                  </div>
                  <div class="base-user-right" style="width: calc(100% - 55px)">
                    <div class="base-div1">
                      <span class="base-span1">{{ nickNameText }}</span>
                      <span class="base-span2" :title="SalaryStaff.postName">{{
                        SalaryStaff.postName
                      }}</span>
                    </div>
                    <div class="base-div2">
                      <div
                        class="base-div3"
                        @click.stop="salaryStatusClick($event)"
                        :style="{
                          color: SalaryStaffColor,
                          borderColor: SalaryStaffColor,
                        }"
                      >
                        {{ SalaryStaff.salaryStatusText }}
                        <i
                          class="el-icon-arrow-down"
                          :style="{ color: SalaryStaffColor }"
                        ></i>
                      </div>
                    </div>
                    <i class="el-icon-arrow-right"></i>
                  </div>
                </div>
                <div class="clear"></div>
                <div class="base-box yingfa" v-if="0">
                  <div class="yingfaBg"></div>
                  <div class="base-box-left">应发工资</div>
                  <div class="base-box-right">
                    {{ SalaryStaff.wagePayable.toFixed(2) }}
                  </div>
                  <div class="base-box-icon">
                    <span class="span-left"></span>
                    <span class="span-right">元</span>
                  </div>
                </div>
                <div class="clear"></div>
                <div class="base-box yingfa" style="height: 54px">
                  <div class="base-box-left">岗位工资</div>
                  <div class="base-box-right">
                    <input
                      type="text"
                      v-model="SalaryStaff.deSalary"
                      @input="wagePayable(1)"
                      placeholder="请输入"
                    />
                  </div>
                  <div class="base-box-icon">
                    <span class="span-left">*</span>
                    <span class="span-right">元</span>
                  </div>
                </div>
                <div class="clear"></div>
                <div class="base-box" style="height: 54px">
                  <div class="base-box-left">试用期工资</div>
                  <div class="base-box-right">
                    <input
                      type="text"
                      v-model="SalaryStaff.onTrialTermSalaryScale"
                      placeholder="请输入"
                      @input="saveFun1()"
                      @blur="onTrialFun"
                    />
                  </div>
                  <div class="base-box-icon">
                    <span class="span-left"></span>
                    <span class="span-right">元</span>
                  </div>
                </div>
                <div class="clear"></div>
                <slot v-for="(e, i) in list1">
                  <slot v-if="e.field === 'endBouns'">
                    <slot v-if="SalaryStaff.contractType === 3">
                      <div
                        class="base-box"
                        :key="i + '-123'"
                        @click="selectClick($event, 2, e)"
                      >
                        <div class="base-box-left">
                          {{ e.title }}
                        </div>
                        <div class="base-box-right inputBox">
                          <input
                            type="text"
                            v-model="SalaryStaff[e.field]"
                            @input="wagePayable()"
                            :placeholder="e.tips"
                          />
                        </div>
                        <div
                          class="base-box-icon"
                          :style="{ lineHeight: e.inputBox ? '54px' : '' }"
                        >
                          <span class="span-right">元</span>
                        </div>
                      </div>
                      <div class="clear" :key="i + '-3224'"></div>
                    </slot>
                  </slot>
                  <div
                    v-else
                    class="base-box"
                    :key="i + '-43'"
                    @click="selectClick($event, 2, e)"
                    :class="{
                      payrollColor1: tips[e.field],
                      bankStyle:
                        e.field === 'bankName' || e.field === 'bankCardNum',
                    }"
                  >
                    <div
                      class="base-box-left"
                      :style="{
                        width:
                          e.field === 'bankName' || e.field === 'bankCardNum'
                            ? '30%'
                            : '',
                      }"
                    >
                      {{ e.title }}
                    </div>
                    <div
                      class="base-box-right"
                      :style="{
                        width:
                          e.field === 'bankName' || e.field === 'bankCardNum'
                            ? 'calc(70%) !important'
                            : '',
                      }"
                      :class="{
                        inputBox: e.inputBox,
                        bankNameSyle:
                          e.field === 'bankName' || e.field === 'bankCardNum',
                      }"
                    >
                      <p class="sumText" v-if="e.field === 'sum'">
                        {{ sum }}
                      </p>
                      <p class="sumText" v-else-if="e.field === 'bankProvince'">
                        <slot v-if="SalaryStaff[e.field]">{{
                          SalaryStaff[e.field]
                        }}</slot>
                        <slot v-else
                          ><span style="color: #bbbbbb">{{
                            e.tips
                          }}</span></slot
                        >
                      </p>
                      <p
                        class="sumText"
                        v-else-if="
                          e.field === 'bankName' || e.field === 'bankCardNum'
                        "
                      >
                        <el-input
                          type="textarea"
                          :autosize="{ minRows: 1, maxRows: 5 }"
                          :placeholder="e.tips"
                          v-model="SalaryStaff[e.field]"
                          class="textStyle"
                        >
                        </el-input>
                      </p>
                      <input
                        v-else
                        type="text"
                        v-model="SalaryStaff[e.field]"
                        @input="wagePayable()"
                        :placeholder="e.tips"
                      />
                    </div>
                    <div
                      class="base-box-icon"
                      :style="{ lineHeight: e.inputBox ? '54px' : '' }"
                    >
                      <span class="span-left" v-if="e.requireAuth">*</span>
                      <slot v-if="e.text === '元'">
                        <span class="span-right">元</span>
                      </slot>
                      <slot v-if="e.text === 'icon'">
                        <i class="el-icon-arrow-right arrow-right"></i>
                      </slot>
                    </div>
                  </div>
                  <div class="clear" :key="i + '-324'"></div>
                </slot>
                <div
                  class="base-box"
                  style="height: 54px"
                  v-if="
                    enterpriseEmployee.isAdmin !== 1 &&
                    loginUser !== enterpriseEmployee.userId
                  "
                >
                  <div class="base-box-left" style="width: 30%">员工状态</div>
                  <div
                    class="base-box-right"
                    style="width: 59%"
                    @click="dateClick()"
                  >
                    <slot v-if="!dimissionTime">正常</slot>
                    <slot v-else>
                      <span style="color: #ea0200"
                        >停薪(离职) {{ dimissionTime }}</span
                      >
                    </slot>
                    <date-picker
                      v-model="dimissionTime"
                      value-type="format"
                      format="YYYY-MM-DD"
                      @change="dateChange"
                      class="data-picker-style sHide"
                      :placeholder="item.tips"
                      :open.sync="openDates"
                      :editable="false"
                      :clearable="false"
                    ></date-picker>
                  </div>
                  <div class="base-box-icon">
                    <span class="span-left"></span>
                    <span class="span-right">
                      <i class="el-icon-arrow-right arrow-right"></i
                    ></span>
                  </div>
                </div>
              </div>
              <div class="personInfo-right-box">
                <div
                  class="base-box"
                  style="border-bottom: none"
                  v-if="SalaryStaff.contractType === 3"
                >
                  <div class="switch-header"></div>
                  <div
                    class="base-box-left"
                    style="font-size: 16px; width: 55% !important"
                  >
                    是否缴纳社保
                  </div>
                  <div class="base-box-right" style="width: 45% !important">
                    <el-switch
                      v-model="switchFlag"
                      active-color="#13ce66"
                      inactive-color="#f4f4f4"
                      @change="switchFun()"
                    >
                    </el-switch>
                  </div>
                </div>
                <div
                  class="base-box"
                  @click="showFlagClick(1)"
                  style="margin-top: 10px"
                  v-if="!noShowRight"
                >
                  <div class="base-box-left" style="cursor: pointer">
                    个税专项扣除
                  </div>
                  <div
                    class="base-box-right"
                    style="width: 52% !important; color: #bbb"
                  >
                    自动获取<i class="el-icon-arrow-right arrow-right"></i>
                  </div>
                </div>

                <div
                  class="base-box"
                  v-if="!noShowLeft"
                  style="cursor: pointer"
                  @click="showFlagClick(2)"
                >
                  <div class="base-box-left">累计薪酬扣税项目</div>
                  <div
                    class="base-box-right"
                    style="width: 52% !important; color: #bbb"
                  >
                    自动获取<i class="el-icon-arrow-right arrow-right"></i>
                  </div>
                </div>

                <div class="base-box bankStyle">
                  <div class="base-box-left" style="width: 28%">银行卡号</div>
                  <div
                    class="base-box-right bankNameSyle"
                    style="width: calc(72%) !important"
                  >
                    <p class="sumText">
                      <span class="span-desc">{{ bankDesc }}</span>
                      <el-input
                        type="textarea"
                        :autosize="{ minRows: 1, maxRows: 5 }"
                        placeholder="银行卡号"
                        v-model="bankCardNum"
                        class="textStyle"
                        @blur="bankBlur"
                        @input="bankBlur"
                      >
                      </el-input>
                    </p>
                  </div>
                  <div class="base-box-icon">
                    <span class="span-left">*</span>
                  </div>
                </div>

                <div class="base-box bankStyle">
                  <div class="base-box-left" style="width: 30%">开户行全称</div>
                  <div
                    class="base-box-right bankNameSyle"
                    style="width: calc(70%) !important"
                  >
                    <p class="sumText">
                      <el-input
                        type="textarea"
                        :autosize="{ minRows: 1, maxRows: 5 }"
                        placeholder="开户行全称"
                        v-model="SalaryStaff.bankName"
                        class="textStyle"
                        @input="saveFun1()"
                      >
                      </el-input>
                    </p>
                  </div>
                  <div class="base-box-icon">
                    <span class="span-left">*</span>
                  </div>
                </div>

                <div
                  class="base-box bankStyle"
                  @click="selectClick($event, 3, {})"
                >
                  <div class="base-box-left" style="width: 47%">
                    开户行所在地
                  </div>
                  <div
                    class="base-box-right bankNameSyle"
                    style="width: calc(53%) !important"
                  >
                    <!-- 工资卡开户行所在地 -->
                    <p class="sumText" style="padding-right: 16px">
                      <slot v-if="SalaryStaff.bankProvince">
                        {{ SalaryStaff.bankProvince }}
                      </slot>
                      <slot v-else
                        ><span style="color: #bbbbbb">开户行所在地</span></slot
                      >
                    </p>
                  </div>
                  <div class="base-box-icon">
                    <span class="span-left">*</span>
                    <i class="el-icon-arrow-right arrow-right"></i>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="personInfo-main-right"
              v-if="SalaryStaff.contractType === 3"
            >
              <div class="cover-div" v-if="!switchFlag"></div>
              <div class="personInfo-right-header">
                <div class="personInfo-header-title">缴纳城市</div>
                <div class="personInfo-cascade">
                  <div
                    class="personInfo-cascade-tips"
                    v-if="!cascadeText"
                    @click="popupShowClick($event, 1)"
                  >
                    缴纳社保所在城市
                  </div>
                  <div
                    class="personInfo-cascade-text"
                    @click="popupShowClick($event, 1)"
                  >
                    {{ cascadeText }}
                  </div>
                  <i class="el-icon-arrow-down"></i>
                </div>
              </div>
              <div class="personInfo-right-content">
                <!-- <el-tabs v-model="activeName" @tab-click="handleClick">
                  <el-tab-pane label="社保设置" name="first"
                    >社保设置</el-tab-pane
                  >
                  <el-tab-pane label="公积金设置" name="second"
                    >公积金设置</el-tab-pane
                  >
                </el-tabs> -->

                <div class="personInfo-right-l">
                  <p class="personInfo-tips">
                    <span>提示：可根据实际比例情况修正</span>
                  </p>
                  <div class="base-box geshui">
                    <div class="base-box-left">缴纳时间</div>
                    <div class="base-box-right">
                      <el-date-picker
                        v-model="employeeNoun.socialPayTimes"
                        :editable="false"
                        value-format="yyyy-MM"
                        type="month"
                        placeholder="选择月"
                        @input="saveFun2()"
                      >
                      </el-date-picker>
                    </div>
                    <div class="base-box-icon">
                      <i class="el-icon-arrow-down"></i>
                    </div>
                  </div>
                  <div class="base-box" v-if="0">
                    <div class="base-box-left">社保基数</div>
                    <div class="base-box-right">
                      <input
                        type="text"
                        v-model="employeeNoun.socialBasics"
                        placeholder="请输入"
                        @input="saveFun2()"
                      />
                    </div>
                  </div>

                  <div class="personInfo-ul">
                    <ul>
                      <li class="li-header">
                        <div class="li-left">险种</div>
                        <div class="li-left">缴费基数</div>
                        <div class="li-left">公司%</div>
                        <div class="li-left">个人%</div>
                      </li>
                      <li
                        class="li-body"
                        v-for="(e, i) in list4"
                        :key="i + '-876'"
                      >
                        <div class="li-left">
                          {{ e.title }} <span v-if="e.desc">{{ e.desc }}</span>
                        </div>
                        <div class="li-left">
                          <input
                            type="text"
                            v-model="employeeNoun[e.field3]"
                            @input="saveFun2()"
                          />
                        </div>
                        <div class="li-left">
                          <input
                            type="text"
                            v-model="employeeNoun[e.field2]"
                            @input="saveFun2()"
                          />
                        </div>
                        <div class="li-left">
                          <input
                            type="text"
                            v-model="employeeNoun[e.field1]"
                            @input="saveFun2()"
                          />
                        </div>
                      </li>
                      <li
                        class="li-body"
                        v-for="(e, i) in employeeNoun.socialNounPropertyList"
                        :key="i"
                      >
                        <div
                          class="li-left"
                          :style="{
                            lineHeight:
                              e.socialName.length > 6 ? 'inherit' : '',
                          }"
                        >
                          {{ e.socialName }}<slot v-if="e.unit === 1">(%)</slot
                          ><slot v-if="e.unit === 2">(元)</slot>
                        </div>
                        <div class="li-left">
                          <input
                            type="text"
                            v-model="e.socialBasic"
                            @input="saveFun2()"
                          />
                        </div>
                        <div class="li-left">
                          <input
                            type="text"
                            v-model="e.socialCompanyValue"
                            @input="saveFun2()"
                          />
                        </div>
                        <div class="li-left">
                          <input
                            type="text"
                            v-model="e.socialPersonValue"
                            @input="saveFun2()"
                          />
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="personInfo-right-l">
                  <p class="personInfo-tips">
                    <span>提示：请根据企业实际情况输入公积金基数及比例</span>
                  </p>
                  <div class="base-box geshui">
                    <div class="base-box-left">缴纳时间</div>
                    <div class="base-box-right">
                      <el-date-picker
                        v-model="employeeNoun.providentPayTimes"
                        :editable="false"
                        value-format="yyyy-MM"
                        type="month"
                        placeholder="选择月"
                        @input="saveFun2()"
                      >
                      </el-date-picker>
                    </div>
                    <div class="base-box-icon">
                      <i class="el-icon-arrow-down"></i>
                    </div>
                  </div>
                  <div class="base-box" v-if="0">
                    <div class="base-box-left">公积金基数</div>
                    <div class="base-box-right">
                      <input
                        type="text"
                        v-model="employeeNoun.socialBasicsProvident"
                        placeholder="请输入"
                      />
                    </div>
                  </div>

                  <div class="personInfo-ul">
                    <ul>
                      <li class="li-header">
                        <div class="li-left">险种</div>
                        <div class="li-left">缴费基数</div>
                        <div class="li-left">公司%</div>
                        <div class="li-left">个人%</div>
                      </li>
                      <li class="li-body">
                        <div class="li-left">公积金比例</div>

                        <div class="li-left">
                          <input
                            type="text"
                            v-model="employeeNoun.socialBasicsProvident"
                            @input="saveFun2()"
                          />
                        </div>
                        <div class="li-left">
                          <input
                            type="text"
                            v-model="employeeNoun.providentCompany"
                            @input="saveFun2()"
                          />
                        </div>
                        <div class="li-left">
                          <input
                            type="text"
                            v-model="employeeNoun.providentPerson"
                            @input="saveFun2()"
                          />
                        </div>
                      </li>
                      <li class="li-body" v-if="0">
                        <div class="li-left" style="width: 62%">
                          公积金计算取整
                        </div>
                        <div class="li-left">
                          <el-select
                            v-model="employeeNoun.providentValueType"
                            class="selectStyle"
                            placeholder="请选择"
                          >
                            <el-option
                              v-for="item in providentList"
                              :key="item.type"
                              :label="item.label"
                              :value="item.type"
                            >
                            </el-option>
                          </el-select>
                        </div>
                        <!-- <div class="li-left"></div> -->
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="clear"></div>
            <!-- <div class="personInfo-footer">
              <div class="personInfo-footer-btn" @click="submitFun">保 存</div>
            </div> -->
          </div>
        </div>
        <div class="clear"></div>
        <div class="department-btn">
          <div
            v-if="
              visit === 'visit' &&
              baosongStatus + '' !== '1' &&
              baosongStatus + '' !== '2'
            "
            class="addStaff-btn"
            style="
              background: none;
              color: #262626;
              border: 1px solid #bbb;
              margin-right: 15px;
            "
            @click="baosong"
          >
            员工股报送税局
          </div>
          <div
            v-if="visit === 'visit'"
            class="addStaff-btn"
            style="
              background: none;
              color: #262626;
              border: 1px solid #bbb;
              margin-right: 15px;
            "
            @click="removeSalaryStaff"
          >
            移除
          </div>
          <div class="addStaff-btn" @click="submitFun">保 存</div>
        </div>
      </div>
      <div class="clear"></div>
      <TheFooter></TheFooter>
    </div>
    <div
      class="modular-manage-popup"
      v-if="isShow"
      @mouseleave="isShow = false"
    >
      <Triangle :direction="direction" left="290" color="#cccccc"></Triangle>
      <div class="modular-manage-popup-list">
        <ul>
          <li
            v-for="(item, index) in liList"
            :key="index"
            @click="LiClick(item)"
          >
            <p class="li-p1" :class="{ noDesc: !item.desc }">
              {{ item.text }}
            </p>
            <p class="li-p2">{{ item.desc }}</p>
          </li>
        </ul>
      </div>
    </div>
    <cascadePopup
      v-if="popupShow"
      :offsetTop="popupTop"
      :offsetWidth="popupLeft"
      :popupBottom="popupBottom"
      :item="item"
      :ajaxData="employeeNoun"
      @cascadeClose="cascadeClose"
      @swiperSave="swiperSave"
    ></cascadePopup>
    <deductPopup
      :deductItem="deductItem"
      v-if="deductShow"
      @cascadeClose="cascadeClose"
      @deductSave="deductSave"
    ></deductPopup>
    <div
      class="staff-popup fadeIn"
      :style="{
        left: popupLeft + 'px',
        top: popupTop + 'px',
        bottom: popupBottom + 'px',
        height: '417px',
      }"
      v-if="selectShow1"
      @mouseleave="selectShow1 = false"
    >
      <Triangle :direction="direction" left="290" color="#cccccc"></Triangle>
      <div class="staff-popup-header">
        请给<span>{{ SalaryStaff.nickName }}</span
        >选择工资卡开户行所在地
      </div>
      <div class="staff-popup-content" id="staffPopup">
        <div
          class="staff-popup-box"
          v-for="(item, i) in cityList"
          :key="i"
          @click.stop="listClick(item)"
          :id="item.id"
        >
          <div class="staff-popup-box-l">
            <svg-icon
              v-if="item.id === SalaryStaff.bankProvinceId + ''"
              icon-class="duigou-yes"
              class="duigou"
            ></svg-icon>
            <svg-icon
              v-if="item.id !== SalaryStaff.bankProvinceId + ''"
              icon-class="duigou-no"
              class="duigou"
            ></svg-icon>
          </div>
          <div class="staff-popup-box-r">
            {{ item.text }}
          </div>
        </div>
      </div>
    </div>
    <div
      :style="{
        left: popupLeft + 'px',
        top: popupTop + 'px',
        bottom: popupBottom + 'px',
      }"
      class="modular-manage-popup sHide"
      v-if="selectShow3"
      @mouseleave="selectShow3 = false"
    >
      <Triangle :direction="direction" left="100" color="#4c8afc"></Triangle>
      <div class="modular-manage-popup-list sHide">
        <ul>
          <li
            class="sHide"
            v-for="(item, index) in liList"
            :key="index"
            @click="LiClick(item)"
          >
            <p
              class="li-p1 sHide"
              :style="{
                color: item.color,
              }"
              :class="{ noDesc: !item.desc }"
            >
              {{ item.text }}
            </p>
            <p class="li-p2">{{ item.desc }}</p>
          </li>
          <li @click="dateClick(item)" v-if="0">
            <p class="li-p1 noDesc sHide" style="color: #ea0200">
              离职(停薪)

              <date-picker
                v-model="dimissionTime"
                value-type="format"
                format="YYYY-MM-DD"
                @change="dateChange"
                class="data-picker-style sHide"
                :placeholder="item.tips"
                :open.sync="openDates"
                :editable="false"
                :clearable="false"
              ></date-picker>
            </p>
            <p class="li-p2 sHide">{{ item.desc }}</p>
          </li>
          <!-- <li>
            <p class="li-p1" style="color: #bbb">无工资</p>
            <p class="li-p2" style="color: #bbb">
              企业负担个人社保公积金等，但是没有工资！
            </p>
          </li> -->
          <!-- <li>
            <p class="li-p1" style="color: #bbb">移除</p>
            <p class="li-p2" style="color: #bbb">从工资人员列表删除此人</p>
          </li> -->
        </ul>
      </div>
    </div>
    <subsidyBox
      v-if="selectShow2"
      :popupLeft="popupLeft"
      :popupTop="popupTop"
      :popupBottom="popupBottom"
      :direction="direction"
      :SalaryStaff="SalaryStaff"
      @cascadeClose="cascadeClose"
      @subsidyFun="subsidyFun"
    ></subsidyBox>
    <zinvPopup
      v-if="showType === '1'"
      :dateList="dateList"
      @closeFun="closeFun"
    ></zinvPopup>
    <jixuPopup
      v-if="showType === '2'"
      :dateList="dateList"
      @closeFun="closeFun"
    ></jixuPopup>
    <zufangPopup
      v-if="showType === '3'"
      :dateList="dateList"
      @closeFun="closeFun"
    ></zufangPopup>
    <daikuanPopup
      v-if="showType === '4'"
      :dateList="dateList"
      @closeFun="closeFun"
    ></daikuanPopup>
    <shanyangPopup
      v-if="showType === '5'"
      :dateList="dateList"
      @closeFun="closeFun"
    ></shanyangPopup>
    <dabingPopup
      v-if="showType === '6'"
      :dateList="dateList"
      @closeFun="closeFun"
    ></dabingPopup>
    <personPopup
      v-if="rosterShow"
      :uId="userId"
      :eId="employeeId"
      @rosterClose="rosterClose"
      @rosterSave="rosterSave"
    ></personPopup>
    <earmarkedPopup
      :SalaryStaff="SalaryStaff"
      v-if="earmarkedShow"
      :employeeId="employeeId"
      :jobTime="jobTime"
      @rosterClose="rosterClose"
      @earmarkedSave="earmarkedSave"
      :earmarkedShow.sync="earmarkedShow"
    ></earmarkedPopup>
    <cumulativePopup
      :SalaryStaff="SalaryStaff"
      v-if="cumulativeShow"
      :employeeId="employeeId"
      :jobTime="jobTime"
      @rosterClose="rosterClose"
      @earmarkedSave="earmarkedSave"
      :cumulativeShow.sync="cumulativeShow"
    ></cumulativePopup>

    <div class="popup-layer" v-if="huifuFlag">
      <!-- <div class="popup-layer-bg" @click="rosterClose"></div> -->
      <div class="popup-layer-bg"></div>
      <div
        class="huifuPopup"
        style="
          width: 360px;
          height: 240px;
          margin-left: -180px;
          margin-top: -120px;
        "
      >
        <div class="huifuPopup-content">
          <!-- <p class="huifuPopup-p1">小提示</p> -->
          <p
            class="huifuPopup-p2"
            style="color: #ff931e; font-size: 16px; margin: 20px 0"
          >
            劳动合同 需完善社保信息
          </p>
          <!-- <p class="huifuPopup-p2">如有疑问，请联系公司管理员</p> -->
          <!-- <p class="huifuPopup-p2">有信息变动请手动修改</p> -->
          <div class="huifuPopup-btns">
            <!-- <div class="huifuPopup-btn-l" @click="rosterClose">取 消</div> -->
            <div class="huifuPopup-btn-r" @click="rosterClose">我知道了</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  querySalaryStaff,
  queryBuzhuButie,
  queryEmployeeNoun,
  querySocialSecurity,
  updateEmployeeNoun,
  updateFine,
  updateEmployeeStaffInfo,
  updateIsPaymentSS,
  prefectEnterpriseEmployee,
  modifyEnterpriseEmployee,
  getEmployeeInfo,
  removeSalaryStaff,
  baosong,
  dimission,
} from "@/http/api";

import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/zh-cn";
import TheFooter from "@/components/TheFooter.vue";
import cascadePopup from "@/components/cascadeCityPopup";
import deductPopup from "./components/deductPopup";
import subsidyBox from "./components/subsidyBox";
import zinvPopup from "./components/zinvPopup";
import jixuPopup from "./components/jixuPopup";
import dabingPopup from "./components/dabingPopup";
import daikuanPopup from "./components/daikuanPopup";
import zufangPopup from "./components/zufangPopup";
import shanyangPopup from "./components/shanyangPopup";
import personPopup from "./components/personPopup1";
import earmarkedPopup from "./components/earmarkedPopup";
import cumulativePopup from "./components/cumulativePopup";
export default {
  data() {
    return {
      save1: false,
      save2: false,
      save3: false,
      activeName: "first",
      showType: "",
      visit: sessionStorage.getItem("visit"),
      cityList: this.$dictionaries.employeeModel.cityMap,
      selectShow1: false,
      earmarkedShow: false,
      cumulativeShow: false,
      huifuFlag: false,
      selectShow2: 0,
      selectShow3: false,
      deductShow: false,
      popupTop: null,
      popupBottom: null,
      popupLeft: null,
      userId: "",
      employeeId: "",
      sbyyList: [],
      liList: this.$dictionaries.employeeModel.salaryStatus.list,
      enterpriseId: sessionStorage.getItem("enterpriseId"),
      date: this.$config.getDate(),
      zinv: this.$dictionaries.employeeModel.zinv,
      jixu: this.$dictionaries.employeeModel.jixu,
      daikuan: this.$dictionaries.employeeModel.daikuan,
      zhufang: this.$dictionaries.employeeModel.zhufang,
      shanyang: this.$dictionaries.employeeModel.shanyang,
      dabing: this.$dictionaries.employeeModel.dabing,
      loginUser: sessionStorage.getItem("userId"),
      personShow: false,
      rosterShow: false,
      dateFlag: false,
      dateFlag1: false,
      isShow: false,
      popupShow: false,
      switchFlag: false,
      showFlag1: false,
      showFlag2: false,
      mouth: "",
      nickNameText: "",
      salaryStaffId: "",
      salaryStatusText: "",
      cascadeText: "",
      SalaryStaffColor: "",
      bankNameFlag: false,
      SalaryStaff: {
        head: "",
      },
      direction: "",
      employeeNoun: {},
      list1: [
        {
          title: "绩效/奖金",
          value: "",
          field: "acheBouns",
          text: "元",
          requireAuth: false,
          tips: "0",
          inputBox: true,
        },
        {
          title: "补贴补助",
          value: "",
          field: "sum",
          text: "icon",
          requireAuth: false,
          tips: "0",
        },
        {
          title: "考勤扣款",
          value: "",
          field: "kSalary",
          text: "元",
          requireAuth: false,
          tips: "0",
          inputBox: true,
        },
        {
          title: "其他扣款",
          value: "",
          field: "otherDeductions1",
          text: "元",
          requireAuth: false,
          tips: "0",
          inputBox: true,
        },
        {
          title: "年终奖",
          value: "",
          field: "endBouns",
          text: "元",
          requireAuth: false,
          tips: "0",
          inputBox: true,
        },
        {
          title: "解除合同补偿金",
          value: "",
          field: "compensation",
          text: "元",
          requireAuth: false,
          tips: "0",
          inputBox: true,
        },
      ],
      list2: [
        {
          title: "子女教育",
          field: "deductionChild",
          showField: "zinvText",
          list: "zinv",
        },
        {
          title: "继续教育",
          field: "deductionTeach",
          showField: "jixuText",
          list: "jixu",
        },
        {
          title: "住房租金",
          field: "houseRent",
          showField: "zhufangText",
          list: "zhufang",
        },
        {
          title: "住房贷款",
          field: "housingLoan",
          showField: "daikuanText",
          list: "daikuan",
        },
        {
          title: "赡养老人",
          field: "provideAged",
          showField: "shanyangText",
          list: "shanyang",
        },
        {
          title: "大病医疗",
          field: "health",
          showField: "dabingText",
          list: "dabing",
        },
      ],
      list3: [
        {
          title: "累计应发工资",
          desc: "(累计收入额)",
          field: "cumulativeSalary",
          tips: "placeholder1",
        },
        {
          title: "累计减除费用",
          field: "cumulativeThreshold",
          tips: "placeholder2",
        },
        {
          title: "累计社保公积金",
          field: "cumulativeProvident",
          tips: "placeholder3",
          desc: "(累计专项扣除)",
        },
        {
          title: "累计专项扣除-子女教育",
          field: "cumulativeChild",
          tips: "placeholder4",
        },
        {
          title: "累计专项扣除-继续教育",
          field: "cumulativeNextTeach",
          tips: "placeholder5",
        },
        {
          title: "累计专项扣除-住房租金",
          field: "cumulativeRent",
          tips: "placeholder6",
        },
        {
          title: "累计专项扣除-住房贷款",
          field: "cumulativeHousingLoan",
          tips: "placeholder7",
        },
        {
          title: "累计专项扣除-赡养老人",
          field: "cumulativeSupport",
          tips: "placeholder8",
        },
      ],
      list4: [
        {
          title: "养老",
          field1: "oldPerson",
          field2: "oldCompany",
          field3: "socialBasicsYanglao",
        },
        {
          title: "医疗",
          desc: "(+生育)",
          field1: "medicalPerson",
          field2: "medicalCompany",
          field3: "socialBasicsYiliao",
        },
        {
          title: "失业",
          field1: "jobPerson",
          field2: "jobCompany",
          field3: "socialBasicsUnemployedt",
        },
        {
          title: "工伤",
          field1: "industrialPerson",
          field2: "industrialCompany",
          field3: "socialBasicsIndustrial",
        },
        // {
        //   title: "其他",
        //   field1: "otherOnePerson",
        //   field2: "otherOneCompany",
        // },
      ],
      providentList: [
        {
          type: 1,
          label: "是",
        },
        {
          type: 0,
          label: "否",
        },
      ],
      placeholders: {
        placeholder1: "请输入",
        placeholder2: "请输入",
        placeholder3: "请输入",
        placeholder4: "请输入",
        placeholder5: "请输入",
        placeholder6: "请输入",
        placeholder7: "请输入",
        placeholder8: "请输入",
      },

      sum: 0,
      subsidyList: [],
      textList: {
        zinvText: "无",
        jixuText: "无",
        zhufangText: "无",
        daikuanText: "无",
        shanyangText: "无",
        dabingText: "无",
      },
      item: {
        value1: "",
        value2: "",
        value3: "",
        desc1: "",
        desc2: "",
        desc3: "",
      },
      deductItem: {},
      noShowLeft: false,
      noShowRight: false,
      json1: {
        acheBouns: 0,
        area: "",
        areaId: "",
        bankCardNum: "",
        bankCode: "",
        bankName: "",
        bankProvince: "",
        bankProvinceId: "",
        bouns: 0,
        city: "",
        cityId: "",
        compensation: 0,
        contractType: 0,
        createTime: "",
        createTimes: 0,
        cumulativeChild: 0,
        cumulativeHousingLoan: 0,
        cumulativeNextTeach: 0,
        cumulativeProvident: 0,
        cumulativeRent: 0,
        cumulativeSalary: 0,
        cumulativeSupport: 0,
        cumulativeTax: 0,
        cumulativeTaxAmt: 0,
        cumulativeThreshold: 0,
        deSalary: 0,
        deductionChild: 0,
        deductionTeach: 0,
        endBouns: 0,
        enterpriseId: "",
        head: "",
        health: 0,
        healthAmt: 0,
        houseRent: 0,
        housingLoan: 0,
        idNum: "",
        identity: 0,
        isCompen: 0,
        isDel: 0,
        isFinish: 0,
        isSend: 0,
        isTrue: 0,
        jSalary: 0,
        jobTime: "",
        jobTimes: "",
        kSalary: 0,
        nickName: "",
        oldAve: 0,
        otherSubsidyBouns: 0,
        otherDeductions1: 0,
        otherDeductions2: 0,
        otherDeductions3: 0,
        otherDeductions4: 0,
        position: "",
        postName: "",
        provideAged: 0,
        province: "",
        provinceId: "",
        registered: 0,
        salaryStaffId: "",
        salaryStatus: 0,
        salaryTrue: 0,
        stakeIn: 0,
        subsidyId: 0,
        onTrialTermSalaryScale: 0,
        trueSalary: 0,
        userId: "",
      },
      tips: {
        bankProvince: false,
        bankCardNum: false,
        bankName: false,
        cumulativeSalary: false,
        cumulativeThreshold: false,
        cumulativeProvident: false,
        cumulativeChild: false,
        cumulativeNextTeach: false,
        cumulativeRent: false,
        cumulativeHousingLoan: false,
        cumulativeSupport: false,
      },
      dateList: [],
      numType: "",
      uId: "",
      enterpriseEmployee: {},
      bankCardNum: "",
      bankDesc: "",
      zxkc: false,
      deSalary: "",
      baosongStatus: "1",
      openDates: false,
      dimissionTime: "",
      showText: "",
      dimissionFlag: false,
    };
  },
  components: {
    TheFooter,
    cascadePopup,
    deductPopup,
    subsidyBox,
    zinvPopup,
    jixuPopup,
    dabingPopup,
    daikuanPopup,
    zufangPopup,
    shanyangPopup,
    personPopup,
    earmarkedPopup,
    cumulativePopup,
    DatePicker,
  },
  mounted() {
    let that = this;
    let query = this.$route.query;
    this.salaryStaffId = query.id;
    this.userId = query.user;
    this.init();
    this.dateList = [];
    for (let index = 2019; index <= this.date.year * 1 + 1; index++) {
      this.dateList.push({
        value: index + "",
        label: index + "",
      });
    }
    document.addEventListener("click", function (e) {
      if (typeof e.target.className === "string") {
        if (e.target.className.indexOf("sHide") === -1) {
          // that.selectShow3 = false;
          that.$forceUpdate();
        }
      }
    });
  },

  methods: {
    dateClick() {
      this.openDates = true;
      this.$nextTick(() => {
        let className1 = document.querySelector(".className1");
        if (className1 === null) {
          let a = document.getElementsByClassName("mx-calendar-panel-date")[0];
          var newNodeTop = document.createElement("div");
          var vtop = "选择离职日期(停薪日期)";
          newNodeTop.innerHTML = vtop;
          newNodeTop.style.textAlign = "center";
          newNodeTop.style.padding = "10px 0px 0";
          newNodeTop.style.color = "#262626";
          newNodeTop.className = "className1";
          a.parentNode.insertBefore(newNodeTop, a);
        }
      });
    },
    dateChange() {
      let year = this.dimissionTime.split("-")[0];
      let month = this.dimissionTime.split("-")[1];
      let day = this.dimissionTime.split("-")[2];
      if (this.date.year === year) {
        if (this.date.lastMounth === month) {
          this.$store.commit("MyMessage", "成功");
        } else if (this.date.thisMounth === month) {
          if (day * 1 > this.date.day * 1) {
            this.dimissionTime = "";
            this.$store.commit("MyMessage", "不能跨月或者选择后面的日期");
            return false;
          }
        } else {
          this.dimissionTime = "";
          this.$store.commit("MyMessage", "不能跨月或者选择后面的日期");
          return false;
        }
      } else if (year * 1 > this.date.year * 1) {
        this.dimissionTime = "";
        this.$store.commit("MyMessage", "不能跨月或者选择后面的日期");
        return false;
      } else if (year * 1 < this.date.year * 1) {
        if (year * 1 === this.date.year * 1 - 1 && month === "12") {
          this.$store.commit("MyMessage", "成功");
        } else {
          this.dimissionTime = "";
          this.$store.commit("MyMessage", "不能跨月或者选择后面的日期");
          return false;
        }
      } else {
        this.dimissionTime = "";
        this.$store.commit("MyMessage", "不能跨月或者选择后面的日期");
        return false;
      }
      let oDate1 = new Date(this.dimissionTime);
      let oDate2 = new Date(this.ajaxData.jobTime);
      let oDate3 = new Date();
      if (oDate1 < oDate2) {
        this.dimissionTime = "";
        this.$store.commit("MyMessage", "离职日期必须大于入职日期");
        return false;
      }
      if (oDate1 > oDate3) {
        this.dimissionTime = "";
        this.$store.commit("MyMessage", "离职日期不能大于当前日期");
        return false;
      }
      console.log(this.dimissionTime);
      this.enterpriseEmployee.status = "2";
      // this.enterpriseEmployee.dimissionTime = this.dimissionTime;
      console.log(this.enterpriseEmployee);
      // this.$confirm("确定要离职(停薪)该员工？", "提示", {
      //   confirmButtonText: "确定",
      //   cancelButtonText: "取消",
      //   type: "",
      //   center: true,
      // })
      //   .then(() => {
      //     this.dimission();
      //   })
      //   .catch(() => {});
      // this.ajaxData.status = "2";
      // this.ajaxData.dimissionTime = this.dimissionTime;
      // this.showText = "离职(停薪)日期: " + this.dimissionTime;
    },
    dimission() {
      let date = {
        userId: this.userId,
        enterpriseId: this.enterpriseId,
        employeeId: this.employeeId,
        dimissionTime: this.dimissionTime,
      };
      dimission(date).then((res) => {
        // console.log(res);
        if (res.code === 0) {
          // window.close();
          this.$store.commit("MyMessage", "操作成功");
          setTimeout(() => {
            // this.tishiFlag = true;
            this.gotoFun("/payrollModule");
          }, 500);
        } else {
          this.$store.commit("MyMessage", res.msg);
        }
      });
    },
    handleClick(tab, event) {
      console.log(tab, event);
    },
    // 员工报送税局
    baosong() {
      let param = {
        enterpriseId: this.enterpriseId,
        employeeId: this.employeeId,
        userId: sessionStorage.getItem("userId"),
      };
      baosong(param).then((res) => {
        // console.log(res);
        if (res.code === 0) {
          this.$store.commit("MyMessage", "操作成功，请稍等");
          setTimeout(() => {
            location.reload();
          }, 1500);
        } else {
          this.$store.commit("MyMessage", res.msg);
        }
      });
    },
    removeSalaryStaff() {
      let param = {
        enterpriseId: this.enterpriseId,
        employeeId: this.employeeId,
        userId: sessionStorage.getItem("userId"),
      };
      removeSalaryStaff(param).then((res) => {
        // console.log(res);
        if (res.code === 0) {
          this.$store.commit("MyMessage", "移除成功");
          this.gotoFun("/payrollModule");
        } else {
          this.$store.commit("MyMessage", res.msg);
        }
      });
    },
    bankBlur(flag) {
      let bankItem = this.$bank.bankCardAttribution(
        this.bankCardNum.replace(/\s+/g, "")
      );
      // console.log(bankItem);
      if (bankItem !== "error") {
        if (this.bankNameFlag) {
          this.SalaryStaff.bankName = bankItem.bankName;
        }
        this.bankDesc = bankItem.cardTypeName;
      } else {
        if (this.bankNameFlag) {
          this.SalaryStaff.bankName = "";
        }
        this.bankDesc = "";
      }
      this.bankNameFlag = true;
      this.bankCardNum = this.bankCardNum
        .replace(/\D+/g, "")
        .replace(/(\d{4})/g, "$1 ")
        .replace(/ $/, "");

      console.log(flag);
      this.saveFun1();
      if (!flag) {
        console.log(flag);
        this.bankBlurFun();
      }
    },
    init(flag) {
      this.querySalaryStaff(flag);
      this.queryBuzhuButie();
    },
    rosterFun() {
      this.rosterShow = true;
    },
    rosterClose() {
      this.rosterShow = false;
      this.earmarkedShow = false;
      this.cumulativeShow = false;
      this.huifuFlag = false;
    },
    earmarkedSave(flag) {
      this.init(flag);
      this.rosterClose(flag);
    },
    rosterSave() {
      this.init();
      this.rosterClose();
    },
    saveFun1() {
      this.save1 = true;
      console.log(this.save1);
    },
    switchFun() {
      this.save2 = true;
    },
    saveFun2() {
      this.save2 = true;
    },
    wagePayable(type) {
      if (type === 1) {
        this.deSalary = this.SalaryStaff.deSalary;
      }
      if (type !== 2) {
        this.save1 = true;
      }
      let num = this.SalaryStaff.deSalary;
      // console.log(this.dateFlag);
      if (this.dateFlag) {
        num = this.SalaryStaff.onTrialTermSalaryScale;
      }
      this.num("acheBouns");
      this.num("endBouns");
      this.num("compensation");
      this.num("sum");
      this.num("kSalary");
      this.num("fine");
      this.SalaryStaff.wagePayable =
        num * 1 +
        this.SalaryStaff.acheBouns * 1 +
        this.SalaryStaff.endBouns * 1 +
        this.SalaryStaff.compensation * 1 +
        this.sum * 1 -
        this.SalaryStaff.kSalary * 1 -
        this.SalaryStaff.fine * 1;
      this.$forceUpdate();
    },
    num(obj) {
      this.SalaryStaff[obj] = this.SalaryStaff[obj] + "";
      this.SalaryStaff[obj] = this.SalaryStaff[obj].replace(/[^\d.]/g, ""); //清除"数字"和"."以外的字符
      this.SalaryStaff[obj] = this.SalaryStaff[obj].replace(/^\./g, ""); //验证第一个字符是数字
      this.SalaryStaff[obj] = this.SalaryStaff[obj].replace(/\.{2,}/g, "."); //只保留第一个, 清除多余的
      this.SalaryStaff[obj] = this.SalaryStaff[obj]
        .replace(".", "$#$")
        .replace(/\./g, "")
        .replace("$#$", ".");
      // this.SalaryStaff[obj] = this.SalaryStaff[obj].replace(
      //   /^(\-)*(\d+)\.(\d\d).*$/,
      //   "$1$2.$3"
      // ); //只能输入两个小数
    },

    closeFun() {
      this.showType = "";
    },
    // 获取员工信息
    getEmployeeInfo(uid, eid) {
      let param = {
        enterpriseId: this.enterpriseId,
        userId: uid,
        employeeId: eid,
      };
      getEmployeeInfo(param).then((res) => {
        if (res.code === 0) {
          this.enterpriseEmployee = res.data;
          if (res.data.dimissionTime) this.dimissionFlag = true;
          if (res.data.ptsControInfo) {
            this.sbyyList = [];
            res.data.ptsControInfo.forEach((e, i) => {
              if (i === 0) {
                this.baosongStatus = e.baosongStatus;
              }
              if (e.baosongStatus !== 2) {
                if (e.ptsSbyy) {
                  this.sbyyList.push({
                    title: e.ptsSbyy,
                  });
                }
              }
            });
          }
        }
      });
    },
    prefectEnterpriseEmployee(enterpriseEmployee) {
      prefectEnterpriseEmployee(enterpriseEmployee).then((res) => {
        // console.log(res);
        if (res.code === 0) {
          setTimeout(() => {
            // this.gotoFun("/roster");
            // window.close();
            // this.$store.commit("MyMessage", "修改成功");
          }, 500);
        } else {
          this.$store.commit("MyMessage", res.msg);
        }
      });
    },
    modifyEnterpriseEmployee(enterpriseEmployee) {
      let date = {
        enterpriseEmployeeVo3: enterpriseEmployee,
        userId: sessionStorage.getItem("userId"),
        enterpriseId: this.enterpriseId,
        employeeId: enterpriseEmployee.employeeId,
        userId: sessionStorage.getItem("userId"),
      };
      modifyEnterpriseEmployee(date).then((res) => {
        // console.log(res);
        if (res.code === 0) {
          this.$store.commit("MyMessage", "修改成功");
          setTimeout(() => {
            // this.gotoFun("/roster");
            // window.close();
            // this.$router.go(-1); //返回上一层
            // this.$store.commit("MyMessage", "修改成功");
          }, 500);
        } else {
          this.$store.commit("MyMessage", res.msg);
        }
      });
    },
    bankBlurFun() {
      // if (this.SalaryStaff.bankCardNum === "") {
      //   this.tips.bankCardNum = true;
      //   this.$store.commit("MyMessage", "请输入银行卡号");
      //   return false;
      // }
      // if (this.SalaryStaff.bankName === "") {
      //   this.tips.bankName = true;
      //   this.$store.commit("MyMessage", "请输入开户行全称");
      //   return false;
      // }
      // if (this.SalaryStaff.bankProvince === "") {
      //   this.$store.commit("MyMessage", "请选择工资卡开户行所在地");
      //   this.tips.bankProvince = true;
      //   return false;
      // }
      if (this.enterpriseEmployee.jobTime) {
        this.enterpriseEmployee.jobTime =
          this.enterpriseEmployee.jobTime.split(" ")[0];
      }
      this.enterpriseEmployee.onTrialTermSalaryScale =
        this.SalaryStaff.onTrialTermSalaryScale;
      this.enterpriseEmployee.postSalary = this.SalaryStaff.deSalary;
      if (
        this.enterpriseEmployee.dimissionTime !== "" &&
        this.enterpriseEmployee.dimissionTime
      ) {
        console.log(this.enterpriseEmployee.dimissionTime);
        this.enterpriseEmployee.dimissionTime =
          this.enterpriseEmployee.dimissionTime.split(" ")[0];
      }
      return;
      if (this.enterpriseEmployee.status + "" !== "2") {
        if (
          this.enterpriseEmployee.userId === sessionStorage.getItem("userId")
        ) {
          // * 完善员工信息[自己给自己]
          this.prefectEnterpriseEmployee(this.enterpriseEmployee);
        } else {
          this.modifyEnterpriseEmployee(this.enterpriseEmployee);
        }
      }
    },
    onTrialFun() {
      if (this.dateFlag) {
        if (this.SalaryStaff.onTrialTermSalaryScale > 0) {
          if (this.SalaryStaff.onTrialTermSalaryScale === "") {
            return false;
          }
          // this.bankBlurFun();
        }
      }
    },
    //保存
    submitFun() {
      if (!this.dimissionFlag && this.dimissionTime) {
        this.$confirm("确定要离职(停薪)该员工？", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "",
          center: true,
        })
          .then(() => {
            this.dimission();
          })
          .catch(() => {});
        return false;
      }
      
      for (let key in this.tips) {
        this.tips[key] = false;
      }
      // 修改罚款扣款

      this.SalaryStaff.bankCardNum = this.bankCardNum.replace(/\s+/g, "");
      this.enterpriseEmployee.bankProvince = this.SalaryStaff.bankProvince;
      this.enterpriseEmployee.bankCardNum = this.SalaryStaff.bankCardNum;
      this.enterpriseEmployee.bankName = this.SalaryStaff.bankName;
      this.enterpriseEmployee.bankProvinceId = this.SalaryStaff.bankProvinceId;
      this.enterpriseEmployee.onTrialTermSalaryScale =
        this.SalaryStaff.onTrialTermSalaryScale;
      this.enterpriseEmployee.jobTime =
        this.enterpriseEmployee.jobTime.split(" ")[0];

      this.enterpriseEmployee.postSalary = this.SalaryStaff.deSalary;
      // if (!this.switchFlag && this.SalaryStaff.contractType === 3) {
      //   this.huifuFlag = true;
      //   return;
      // }
      // return;
      if (this.dateFlag) {
        if (this.SalaryStaff.onTrialTermSalaryScale === "") {
          this.$store.commit("MyMessage", "请输入试用期工资");
          return false;
        }
      }
      if (this.SalaryStaff.bankCardNum === "") {
        this.tips.bankCardNum = true;
        this.$store.commit("MyMessage", "请输入银行卡号");
        return false;
      }
      if (this.SalaryStaff.bankName === "") {
        this.tips.bankName = true;
        this.$store.commit("MyMessage", "请输入开户行全称");
        return false;
      }
      let isPaymentSS = 1;
      if (this.switchFlag && this.SalaryStaff.contractType === 3) {
        if (
          this.employeeNoun.provinceCode === "" ||
          this.employeeNoun.provinceCode === "1" ||
          !this.employeeNoun.provinceCode
        ) {
          this.$store.commit("MyMessage", "请选择缴纳城市");
          return;
        }

        if (
          this.employeeNoun.socialPayTimes === "" ||
          !this.employeeNoun.socialPayTimes
        ) {
          this.$store.commit("MyMessage", "请选择社保缴纳时间");
          return;
        }
        if (this.employeeNoun.socialBasicsProvident === "") {
          this.$store.commit("MyMessage", "请输入公积金基数金额");
          return;
        }

        if (
          this.employeeNoun.providentPayTimes === "" ||
          !this.employeeNoun.providentPayTimes
        ) {
          this.$store.commit("MyMessage", "请选择公积金缴纳时间");
          return;
        }
        if (
          this.employeeNoun.industrialPerson === "" ||
          this.employeeNoun.industrialCompany === ""
        ) {
          this.$store.commit("MyMessage", "请输入工伤缴纳比例");
          return;
        }
        if (
          this.employeeNoun.oldPerson === "" ||
          this.employeeNoun.oldCompany === ""
        ) {
          this.$store.commit("MyMessage", "请输入养老缴纳比例");
          return;
        }
        if (
          this.employeeNoun.medicalPerson === "" ||
          this.employeeNoun.medicalCompany === ""
        ) {
          this.$store.commit("MyMessage", "请输入医疗缴纳比例");
          return;
        }
        if (
          this.employeeNoun.babyPerson === "" ||
          this.employeeNoun.babyCompany === ""
        ) {
          this.$store.commit("MyMessage", "请输入其他缴纳比例");
          return;
        }
        if (
          this.employeeNoun.jobPerson === "" ||
          this.employeeNoun.jobCompany === ""
        ) {
          this.$store.commit("MyMessage", "请输入失业缴纳比例");
          return;
        }
        if (
          this.employeeNoun.providentPerson === "" ||
          this.employeeNoun.providentCompany === ""
        ) {
          this.$store.commit("MyMessage", "请输入公积金缴纳比例");
          return;
        }
        // console.log(this.employeeNoun);
        this.employeeNoun.salaryStaffId = this.SalaryStaff.salaryStaffId;
        this.employeeNoun.enterpriseId = this.enterpriseId;
        this.employeeNoun.registered =
          this.SalaryStaff.registered <= 0 ? 2 : this.SalaryStaff.registered;
      }

      delete this.SalaryStaff.wagePayable;
      delete this.SalaryStaff.belongtoMonth;
      // delete this.SalaryStaff.nickNameText;
      // delete this.SalaryStaff.salaryStatusText;
      let params = {
        enterpriseId: this.enterpriseId,
        salaryStaffId: this.SalaryStaff.salaryStaffId,
        fine: this.SalaryStaff.fine ? this.SalaryStaff.fine : 0,
      };
      for (let key in this.json1) {
        if (this.SalaryStaff[key]) {
          this.json1[key] = this.SalaryStaff[key];
        }
      }
      if (this.json1.registered === 0) {
        this.json1.registered = 2;
      }
      this.$store.commit("showLoading", "");
      this.json1.isFinish = 1;
      this.json1.userId = sessionStorage.getItem("userId");
      
      if (this.save1) {
        updateEmployeeStaffInfo(this.json1).then((res) => {
          this.$store.commit("hideLoading", "");
          if (res.code === 0) {
            // this.updateFine(params);
            this.$store.commit("MyMessage", "保存成功");
          } else {
            this.$store.commit("MyMessage", res.msg);
          }
        });
        // this.modifyEnterpriseEmployee(this.enterpriseEmployee);
        // return;
        if (this.enterpriseEmployee.status + "" !== "2") {
          if (
            this.enterpriseEmployee.userId === sessionStorage.getItem("userId")
          ) {
            // * 完善员工信息[自己给自己]
            this.prefectEnterpriseEmployee(this.enterpriseEmployee);
          } else {
            this.modifyEnterpriseEmployee(this.enterpriseEmployee);
          }
        } else {
          this.$store.commit("MyMessage", "保存成功");
        }
      }
      if (this.save2) {
        this.updateIsPaymentSS();
        if (this.SalaryStaff.contractType === 3) {
          if (this.switchFlag) {
            this.employeeNoun.socialBasicsYanglao =
              this.employeeNoun.socialBasicsYanglao * 1;
            this.employeeNoun.socialBasics =
              this.employeeNoun.socialBasicsYanglao * 1;
            this.employeeNoun.socialBasicsProvident =
              this.employeeNoun.socialBasicsProvident * 1;
            this.employeeNoun.providentBasics =
              this.employeeNoun.socialBasicsProvident;
            updateEmployeeNoun(this.employeeNoun).then((res) => {
              if (res.code === 0) {
                this.$store.commit("MyMessage", "保存成功");
              } else {
                this.$store.commit("MyMessage", res.msg);
              }
            });
          }
        } else {
          this.$router.go(-1); //返回上一层
          this.$store.commit("MyMessage", "保存成功");
        }
      }
      if (!this.save1 && !this.save2) {
        this.$store.commit("hideLoading", "");
        this.$store.commit("MyMessage", "保存成功");
      }
      setTimeout(() => {
        // location.reload();
      }, 1000);
    },
    updateIsPaymentSS() {
      //是否缴纳社保基数
      let that = this;
      let flag = false;
      let isPaymentSS = 1;
      if (this.switchFlag) {
        isPaymentSS = 2;
      }
      let param = {
        salaryStaffId: this.SalaryStaff.salaryStaffId,
        isPaymentSS: isPaymentSS,
        enterpriseId: this.enterpriseId,
        userId: sessionStorage.getItem("userId"),
      };
      updateIsPaymentSS(param).then((res) => {
        console.log("updateIsPaymentSS", res);
        if (res.code === 0) {
          this.$router.go(-1); //返回上一层
          this.$store.commit("MyMessage", "保存成功");
        }
      });
    },
    // 修改罚款扣款
    updateFine(param) {
      updateFine(param).then((res) => {
        if (res.state == 200) {
        }
      });
    },
    deductSave(item, data, list) {
      this.SalaryStaff[data.field] = item.id;
      if (item.id) {
        if (data.list === "shanyang") {
          this.SalaryStaff.oldAve = item.shanyangNum;
        }
        if (data.list === "dabing") {
          this.SalaryStaff.healthAmt = item.dabingNum;
        }
        list.forEach((element) => {
          if (item.id === element.id) {
            if (data.list === "shanyang" && item.id === 2) {
              this.$store.commit(
                "MyMessage",
                `已选择：按${item.shanyangNum}元/月分摊金额定额扣除 `
              );
            } else if (data.list === "dabing") {
              this.$store.commit(
                "MyMessage",
                `已选择：按${item.dabingNum}元扣除`
              );
            } else {
              this.$store.commit("MyMessage", element.text);
            }
            let array = this.$dictionaries.employeeModel[data.list].list;
            this.textFun(array, data.field, data.showField);
          }
        });
      } else {
        this.textList[data.showField] = "无";
        this.SalaryStaff[data.field] = -1;
        if (data.list === "shanyang") {
          this.SalaryStaff.oldAve = 0;
        }
        if (data.list === "dabing") {
          this.SalaryStaff.healthAmt = 0;
        }
      }
      this.deductShow = false;
      this.$forceUpdate();
    },
    deductClick(item, i) {
      // this.showType = i + "";
      // return;
      this.deductItem = item;
      this.deductItem.value = this.SalaryStaff[item.field];
      if (item.list === "dabing") {
        this.deductItem.health = this.SalaryStaff.healthAmt;
      }
      if (item.list === "shanyang") {
        if (this.SalaryStaff.provideAged === 2) {
          this.deductItem.oldAve = this.SalaryStaff.oldAve;
        }
      }
      this.deductShow = true;
    },
    cascadeClose() {
      this.popupShow = false;
      this.deductShow = false;
      this.selectShow2 = false;
      this.$forceUpdate();
    },
    LiClick(item) {
      this.SalaryStaffColor = item.color;
      this.SalaryStaff.salaryStatus = item.id;
      this.SalaryStaff.salaryStatusText = item.text;
      // this.liTitle = item.text;
      this.selectShow3 = false;
      this.saveFun1();
      this.$forceUpdate();
    },
    subsidyFun(sum) {
      this.sum = sum;
      this.cascadeClose();
    },
    swiperSave(param) {
      this.saveFun2();
      this.item = param;
      if (
        this.item.value1 === "110000" ||
        this.item.value1 === "310000" ||
        this.item.value1 === "500000" ||
        this.item.value1 === "120000"
      ) {
        this.cascadeText = this.item.desc1 + " - " + this.item.desc3;
      } else {
        this.cascadeText = this.item.desc1 + " - " + this.item.desc2;
      }

      this.employeeNoun.provinceCode = this.item.value1;
      this.employeeNoun.cityCode = this.item.value2;
      this.employeeNoun.areaCode = this.item.value3;
      this.employeeNoun.provinceName = this.item.desc1;
      this.employeeNoun.cityName = this.item.desc2;
      this.employeeNoun.areaName = this.item.desc3;
      let cityCode = this.item.value2;
      let areaCode = this.item.value3;
      if (
        this.item.value1 === "110000" ||
        this.item.value1 === "310000" ||
        this.item.value1 === "500000" ||
        this.item.value1 === "120000"
      ) {
      } else {
        areaCode = 1;
      }
      let params = {
        userId: sessionStorage.getItem("userId"),
        provinceCode: this.item.value1,
        cityCode: cityCode,
        areaCode: areaCode,
        registered:
          this.SalaryStaff.registered <= 0 ? 2 : this.SalaryStaff.registered,
      };
      this.querySocialSecurity(params);
      this.$forceUpdate();
    },

    querySocialSecurity(param) {
      querySocialSecurity(param).then((res) => {
        // console.log("querySocialSecurity", res);
        if (res.code == 0) {
          let database = res.data;
          if (database) {
            this.employeeNoun.socialBasics = database.socialBasicsYanglao;
            // 默认社保基数
            if (database.socialBasicsYiliao < this.employeeNoun.socialBasics) {
              this.employeeNoun.socialBasics = database.socialBasicsYiliao;
            }

            if (
              database.socialBasicsIndustrial < this.employeeNoun.socialBasics
            ) {
              this.employeeNoun.socialBasics = database.socialBasicsIndustrial;
            }
            if (
              database.socialBasicsUnemployedt < this.employeeNoun.socialBasics
            ) {
              this.employeeNoun.socialBasics = database.socialBasicsUnemployedt;
            }

            if (database.propertyList.length > 0) {
              database.propertyList.forEach((e) => {
                if (e.socialBasic < this.employeeNoun.socialBasics) {
                  this.employeeNoun.socialBasics = e.socialBasic;
                }
              });
            }

            this.employeeNoun.socialBasicsProvident =
              database.socialBasicsProvident;
            //工伤
            this.employeeNoun.industrialPerson = database.industrialPerson;
            this.employeeNoun.industrialCompany = database.industrialCompany;
            //养老
            this.employeeNoun.oldPerson = database.oldPerson;
            this.employeeNoun.oldCompany = database.oldCompany;

            // 医疗
            this.employeeNoun.medicalPerson = database.medicalPerson;
            this.employeeNoun.medicalCompany = database.medicalCompany;
            // 生育
            this.employeeNoun.babyPerson = database.babyPerson;
            this.employeeNoun.babyCompany = database.babyCompany;
            // 失业
            this.employeeNoun.jobPerson = database.jobPerson;
            this.employeeNoun.jobCompany = database.jobCompany;
            // 公积金比例
            this.employeeNoun.providentPerson = database.providentPerson;
            this.employeeNoun.providentCompany = database.providentCompany;
            // 其他比例
            this.employeeNoun.otherOnePerson = database.otherOnePerson;
            this.employeeNoun.otherOneCompany = database.otherOneCompany;

            this.employeeNoun.providentValueType = database.providentValueType;
            this.employeeNoun.socialBasicsYanglao =
              database.socialBasicsYanglao;
            this.employeeNoun.socialBasicsYiliao = database.socialBasicsYiliao;
            this.employeeNoun.socialBasicsUnemployedt =
              database.socialBasicsUnemployedt;
            this.employeeNoun.socialBasicsIndustrial =
              database.socialBasicsIndustrial;
          } else {
            this.employeeNoun.socialBasics = "";
            this.employeeNoun.socialBasicsProvident = "";
            //工伤
            this.employeeNoun.industrialPerson = 0;
            this.employeeNoun.industrialCompany = 0;
            //养老
            this.employeeNoun.oldPerson = 0;
            this.employeeNoun.oldCompany = 0;

            // 医疗
            this.employeeNoun.medicalPerson = 0;
            this.employeeNoun.medicalCompany = 0;
            // 生育
            this.employeeNoun.babyPerson = 0;
            this.employeeNoun.babyCompany = 0;
            // 失业
            this.employeeNoun.jobPerson = 0;
            this.employeeNoun.jobCompany = 0;
            // 公积金比例
            this.employeeNoun.providentPerson = 0;
            this.employeeNoun.providentCompany = 0;
            this.employeeNoun.socialNounPropertyList = database.propertyList;
          }

          this.employeeNoun.socialNounPropertyList = res.data.propertyList;
          this.popupShow = false;
        }
        this.$forceUpdate();
      });
    },
    querySocialSecurityShow(param) {
      querySocialSecurity(param).then((res) => {
        // console.log("querySocialSecurity", res);
        if (res.code == 0) {
          // this.employeeNoun.socialNounPropertyList.push(
          //   res.data.propertyList[2]
          // );
          // this.employeeNoun.socialNounPropertyList = res.data.propertyList;
        }
        this.$forceUpdate();
      });
    },
    popupShowClick(e, type, item) {
      this.popupShow = true;
      let H1 =
        document.body.clientHeight -
        e.currentTarget.getBoundingClientRect().bottom;
      if (H1 < 440) {
        this.popupBottom = H1 + 10;
        this.direction = "bottom";
      } else {
        this.popupTop = e.currentTarget.getBoundingClientRect().top + 30;
        this.direction = "top";
      }
      this.popupLeft = e.currentTarget.getBoundingClientRect().left - 350;
    },
    selectClick(e, type, item) {
      // console.log(item);
      if (type !== 3) {
        if (item.field === "sum") {
          this.selectShow2 = true;
        } else if (item.field !== "bankProvince") {
          return;
        } else {
          this.selectShow1 = true;
        }
      } else {
        this.selectShow1 = true;
      }
      let H1 =
        document.body.clientHeight -
        e.currentTarget.getBoundingClientRect().bottom;

      if (H1 < 450) {
        this.popupBottom = H1 + 60;
        this.direction = "bottom";
        this.popupTop = null;
      } else {
        this.popupTop = e.currentTarget.getBoundingClientRect().top + 50;
        this.direction = "top";
        this.popupBottom = null;
      }
      if (type !== 3) {
        if (item.field === "sum") {
          // this.popupTop = this.popupTop;
          // this.popupBottom = null;
        }
      }
      console.log(this.popupBottom);
      this.popupLeft = e.currentTarget.getBoundingClientRect().left - 20;
      if (item.field === "sum") {
        this.popupLeft = this.popupLeft - 28;
        // this.popupTop = this.popupTop;
        // this.popupBottom = null;
      }
      this.$nextTick(() => {
        // if (item.field === "bankProvince") {
        //   let total = document.getElementById(this.SalaryStaff.bankProvinceId)
        //     .offsetTop;
        //   document.getElementById("staffPopup").scrollTop = total - 90;
        // }
        if (type === 3) {
          let total = document.getElementById(
            this.SalaryStaff.bankProvinceId
          ).offsetTop;
          document.getElementById("staffPopup").scrollTop = total - 90;
        }
      });
    },
    salaryStatusClick(e) {
      this.selectShow3 = true;
      let H1 =
        document.body.clientHeight -
        e.currentTarget.getBoundingClientRect().bottom;
      if (H1 < 440) {
        this.popupBottom = H1 + 100;
        this.direction = "bottom";
      } else {
        this.popupTop = e.currentTarget.getBoundingClientRect().top + 40;
        this.direction = "top";
      }

      this.popupLeft = e.currentTarget.getBoundingClientRect().left - 55;
    },
    queryBuzhuButie() {
      let that = this;
      let param = {
        userId: that.userId,
        enterpriseId: this.enterpriseId,
      };
      queryBuzhuButie(param).then((res) => {
        // console.log("queryBuzhuButie", res);39251227648
        sessionStorage.setItem("buzhuButie", JSON.stringify(this.arrayList));
        if (res.code == 0) {
          if (res.data.subsidy) {
            that.subsidyList = res.data.subsidy;
            this.sum = 0;
            res.data.subsidy.forEach((element) => {
              this.sum += element.subsidyMoney;
            });
          }
          return;
        }
      });
    },
    textFun(list, value, showValue) {
      if (this.SalaryStaff[value] !== 0 && this.SalaryStaff[value] !== -1) {
        list.forEach((e) => {
          if (e.id === this.SalaryStaff[value]) {
            if (value === "health") {
              this.textList[
                showValue
              ] = `按${this.SalaryStaff.healthAmt}元扣除`;
            } else if (value === "provideAged") {
              if (this.SalaryStaff.provideAged === 2) {
                this.textList[
                  showValue
                ] = `按${this.SalaryStaff.oldAve}元/月分摊金额定额扣除`;
              } else {
                this.textList[showValue] = e.text;
              }
            } else {
              this.textList[showValue] = e.text;
            }
          }
        });
      }
      this.$forceUpdate();
    },
    querySalaryStaff(flag) {
      let param = {
        salaryStaffId: this.salaryStaffId,
        enterpriseId: this.enterpriseId,
        userId: sessionStorage.getItem("userId"),
      };
      querySalaryStaff(param).then((res) => {
        // console.log(res.data);

        if (res.code === 0) {
          this.SalaryStaff = res.data;
          if (this.SalaryStaff.head.indexOf("http://jz.jzdcs.com") !== -1) {
            this.SalaryStaff.head =
              "https://www.jzcfo.com" +
              this.SalaryStaff.head.split("http://jz.jzdcs.com")[1];
          }
          this.bankCardNum = res.data.bankCardNum
            .replace(/\D+/g, "")
            .replace(/(\d{4})/g, "$1 ")
            .replace(/ $/, "");
          this.bankBlur(true);
          this.employeeId = res.data.employeeId;
          this.jobTime = res.data.jobTime;
          this.getEmployeeInfo(res.data.userId, res.data.employeeId);
          this.zxkc = false;
          if (
            this.SalaryStaff.deductionChild > 0 ||
            this.SalaryStaff.deductionTeach > 0 ||
            this.SalaryStaff.health > 0 ||
            this.SalaryStaff.housingLoan > 0 ||
            this.SalaryStaff.houseRent > 0 ||
            this.SalaryStaff.provideAged > 0
          ) {
            this.zxkc = true;
          }
          if (this.SalaryStaff.onTrialTermSalaryScale !== "") {
            if (
              this.SalaryStaff.onTrialTermSalaryScale.split(".")[1] === "00"
            ) {
              this.SalaryStaff.onTrialTermSalaryScale =
                this.SalaryStaff.onTrialTermSalaryScale.split(".")[0];
            }
          }
          if (this.SalaryStaff.bankProvinceId) {
            this.cityList.forEach((e) => {
              if (e.id === this.SalaryStaff.bankProvinceId) {
                this.SalaryStaff.bankProvince = e.text;
              }
            });
          }
          // this.SalaryStaff.bankCardNum = this.SalaryStaff.bankCardNum
          //   .replace(/\D+/g, "")
          //   .replace(/(\d{4})/g, "$1 ")
          //   .replace(/ $/, "");
          this.liList.forEach((element) => {
            if (this.SalaryStaff.salaryStatus === element.id * 1) {
              this.SalaryStaffColor = element.color;
              this.SalaryStaff.salaryStatusText = element.text;
            }
          });
          let time1 = this.SalaryStaff.jobTime.split(" ")[0];
          let time2 = time1.split("-")[0] + "-" + time1.split("-")[1];
          let time3 = this.date.thisMounth;
          if (this.SalaryStaff.contractType === 2) {
            this.noShowLeft = true;
            this.noShowRight = true;
          } else if (this.SalaryStaff.contractType === 3) {
            if (time2 === time3) {
              this.noShowLeft = true;
            } else {
              this.noShowLeft = false;
            }
            this.noShowRight = false;
          } else if (this.SalaryStaff.contractType === 1) {
            this.noShowLeft = true;
            this.noShowRight = true;
          }
          if (flag === 2) {
            console.log(flag);
            this.zxkc = true;
          }
          if (flag === 3) {
            console.log(flag);
            this.SalaryStaff.isFinish = 1;
          }
          this.textFun(this.zinv.list, "deductionChild", "zinvText");
          this.textFun(this.jixu.list, "deductionTeach", "jixuText");
          this.textFun(this.zhufang.list, "houseRent", "zhufangText");
          this.textFun(this.daikuan.list, "housingLoan", "daikuanText");
          this.textFun(this.shanyang.list, "provideAged", "shanyangText");
          this.textFun(this.dabing.list, "health", "dabingText");
          const regMobile = /^1(3|4|5|6|7|8|9)\d{9}$/;
          if (regMobile.test(this.SalaryStaff.nickName)) {
            this.SalaryStaff.nickNameText =
              "*" +
              this.SalaryStaff.nickName.substr(
                this.SalaryStaff.nickName.length - 2,
                2
              );
            this.nickNameText =
              "*" +
              this.SalaryStaff.nickName.substr(
                this.SalaryStaff.nickName.length - 2,
                2
              );
          } else {
            this.SalaryStaff.nickNameText = this.SalaryStaff.nickName.substr(
              this.SalaryStaff.nickName.length - 2,
              2
            );
            this.nickNameText = this.SalaryStaff.nickName;
          }
          let date = new Date();
          let date4 = new Date(this.SalaryStaff.onTrialTermEnd);
          let date1 =
            this.SalaryStaff.onTrialTermEnd.split("-")[0] +
            this.SalaryStaff.onTrialTermEnd.split("-")[1];
          let year = this.date.year;
          if (this.date.lastMounth === "12") {
            year = this.date.year - 1;
          }
          let date2 = this.date.year + this.date.thisMounth;
          let date3 = year + this.date.lastMounth;
          if (this.SalaryStaff.onTrialTermEnd) {
            if (
              this.SalaryStaff.onTrialTermEnd ===
              this.SalaryStaff.onTrialTermBegin
            ) {
              this.dateFlag = false;
            } else {
              if (date1 === date3 || date1 === date2) {
                this.dateFlag = true;
              } else {
                this.dateFlag = false;
              }
            }

            if (date > date4) {
              this.dateFlag1 = false;
            } else {
              if (
                this.SalaryStaff.onTrialTermEnd ===
                this.SalaryStaff.onTrialTermBegin
              ) {
                this.dateFlag1 = false;
              } else {
                this.dateFlag1 = true;
              }
            }
          }
          this.personShow = true;

          this.wagePayable(2);
          this.queryEmployeeNoun(this.SalaryStaff.salaryStaffId);
          if (this.SalaryStaff.isPaymentSS === 2) {
            this.switchFlag = true;
          }
        }
      });
    },
    queryEmployeeNoun(salaryStaffId) {
      let that = this;
      let param = {
        enterpriseId: this.enterpriseId,
        salaryStaffId: salaryStaffId,
        userId: sessionStorage.getItem("userId"),
      };
      queryEmployeeNoun(param).then((res) => {
        if (res.code === 0) {
          this.employeeNoun = res.data;
          this.employeeNoun.name = this.SalaryStaff.nickName;
          this.item = {
            value1: res.data.provinceCode,
            value2: res.data.cityCode,
            value3: res.data.areaCode,
            desc1: res.data.provinceName,
            desc2: res.data.cityName,
            desc3: res.data.areaName,
          };
          // this.employeeNoun.socialNounPropertyList =
          //   this.employeeNoun.socialNounPropertyList;
          if (
            this.employeeNoun.provinceCode !== "" &&
            this.employeeNoun.provinceCode !== "1"
          ) {
            if (
              this.employeeNoun.provinceCode === "110000" ||
              this.employeeNoun.provinceCode === "310000" ||
              this.employeeNoun.provinceCode === "500000" ||
              this.employeeNoun.provinceCode === "120000"
            ) {
              this.cascadeText =
                this.employeeNoun.provinceName +
                " - " +
                this.employeeNoun.areaName;
            } else {
              this.cascadeText =
                this.employeeNoun.provinceName +
                " - " +
                this.employeeNoun.cityName;
            }
            if (this.cascadeText === "/") {
              this.cascadeText = "";
            }
          } else {
          }
          this.selectedOptions = [
            this.employeeNoun.provinceCode,
            this.employeeNoun.cityCode,
            this.employeeNoun.areaCode,
          ];
          // console.log(111);
          if (this.employeeNoun.socialNounPropertyList.length === 0) {
            let params = {
              userId: sessionStorage.getItem("userId"),
              provinceCode: this.employeeNoun.provinceCode,
              cityCode: this.employeeNoun.cityCode,
              areaCode: this.employeeNoun.areaCode,
              registered:
                this.SalaryStaff.registered <= 0
                  ? 2
                  : this.SalaryStaff.registered,
            };
            this.querySocialSecurityShow(params);
          }

          // this.employeeNoun.socialNounPropertyList.splice(2, 1);
        }
      });
    },
    // 提交按钮
    addStaffSave() {},
    gotoFun(path) {
      this.$router.push(path);
    },
    showFlagClick(type) {
      if (type === 1) {
        this.earmarkedShow = true;
      } else {
        this.cumulativeShow = true;
      }
    },
    listClick(item) {
      this.SalaryStaff.bankProvinceId = item.id;
      this.SalaryStaff.bankProvince = item.text;
      this.selectShow1 = false;
      this.saveFun1();
      this.bankBlurFun();
    },
  },
};
</script>

<style lang="less" scoped>
.tips-box {
  position: absolute;
  top: 0;
  left: 0px;
  width: calc(100% - 0px);
  height: 20px;
  background: rgba(234, 2, 0, 0.7);
  color: #fff;
  text-align: center;
  font-size: 12px;
}
.department {
  position: relative;
}
.department .department-mian {
  &::-webkit-scrollbar {
    width: 0;
  }
}
.department .department-list {
  padding-top: 18px;
  min-height: calc(100% - 158px);
  position: relative;
}
.department-width {
  width: 1328px;
  margin: 0 auto;
}
.personInfo-border {
  background: #ffffff;
  border: 1px solid #dbdbdb;
  padding: 35px 0 30px;
}
.personInfo-main {
  width: 1280px;
  margin: 0 auto;
  text-align: center;
  overflow: hidden;
  .personInfo-main-left {
    width: calc(100% - 680px);
    float: left;
    position: relative;
    z-index: 2;
    .personInfo-left-box {
      width: 48%;
      float: left;
    }
    .personInfo-right-box {
      width: calc(52% - 22px);
      float: left;
      padding-right: 22px;
      position: relative;
      .switch-header {
        width: calc(100% + 57px);
        height: 100%;
        position: absolute;
        left: -15px;
        right: 0;
        border: 1px solid #dbdbdb;
        border-right: none;
        background: #fff;
      }
      .base-box-right {
        width: 40% !important;
        padding-right: 0 !important;
        cursor: pointer;
      }
    }

    .dateFlag {
      .base-box-left,
      .base-box-right {
        margin-top: 10px;
        cursor: pointer;
      }
      .base-box-icon {
        line-height: 76px;
      }
    }
    .geshui {
      .base-box-left {
        width: 30%;
      }
      .base-box-right {
        width: 70% !important;
        cursor: pointer;
        padding: 0;
        height: 54px;
        line-height: 54px;
      }
    }

    .leiji {
      .base-box-left {
        width: 60%;
      }
      .base-box-right {
        width: 40% !important;
        cursor: pointer;
      }
    }
    .modular-img {
      width: 48px;
      height: 48px;
      line-height: 48px;
      float: left;

      &.modularImg {
        text-align: center;

        background: #4c8afc;
        color: #fff;
        border-radius: 5px;
        font-size: 16px;
      }
      img {
        width: 48px;
        height: 48px;
      }
      float: left;
      margin-right: 15px;
    }
  }
  .personInfo-main-right {
    width: calc(678px);
    float: left;
    min-height: 300px;
    background: #ffffff;
    border: 1px solid #dbdbdb;
    padding-bottom: 10px;
    position: relative;
    z-index: 1;
    .personInfo-right-header {
      // height: 54px;
      line-height: 54px;
      width: 630px;
      margin: 0 auto;
      position: relative;
      z-index: 1;

      .personInfo-header-title {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        height: 56px;
        text-align: left;
        font-size: 16px;
        font-weight: 400;
        color: #262626;
        // border-bottom: 1px solid #dbdbdb;
        z-index: -1;
      }
      .personInfo-cascade {
        text-align: left;
        padding-left: 90px;
      }
      .personInfo-cascade-tips {
        color: #bbbbbb;
        font-size: 14px;
        font-weight: 400;
        display: inline-block;
        margin-right: 5px;
        cursor: pointer;
        z-index: 1;
      }
      .personInfo-cascade-text {
        font-size: 14px;
        font-weight: 400;
        color: #4c8afc;
        display: inline-block;
        margin-right: 5px;
        cursor: pointer;
        // min-width: 100px;
      }
      .el-icon-arrow-down {
        font-size: 18px;
        font-weight: bold;
        color: #4c8afc;
        position: relative;
        top: 1px;
      }
    }
    .personInfo-right-content {
      width: 630px;

      margin: 1px auto 0;
      overflow: hidden;
    }
    .personInfo-right-l {
      width: calc((100% - 26px) / 2);
      margin-right: 26px;
      float: left;

      &:last-child {
        margin-right: 0;
      }
    }
    .base-box {
      margin: 0 auto;
    }
    .geshui {
      .base-box-right {
        width: calc(70% - 25px) !important;
        cursor: pointer;
      }
    }
  }
  .base-box {
    position: relative;
    margin: 0 20px;
    font-size: 14px;
    font-weight: 400;
    color: #262626;
    border-bottom: 1px solid rgba(219, 219, 219, 0.5);
    // overflow: hidden;
    min-height: 54px;
    .el-icon-arrow-down {
      color: #dbdbdb;
      font-weight: bold;
      font-size: 18px;
      // top: 3px;
      position: relative;
      left: 2px;
    }
    .base-user-left {
      float: left;
      width: 60%;
    }
    .base-user-right {
      float: right;
      width: 40%;
      text-align: left;
      position: relative;
      cursor: pointer;
      .el-icon-arrow-right {
        position: absolute;
        right: 0;
        top: 18px;
        font-size: 20px;
        font-weight: bold;
        color: #dbdbdb;
      }
      .base-div1 {
        width: 100%;
        overflow: hidden;
        margin-top: 2px;
      }
      .base-div2 {
        width: 100%;
        overflow: hidden;
      }
      .base-div3 {
        width: 80px;
        height: 22px;
        line-height: 20px;
        border: 1px solid;
        margin-top: 4px;
        padding: 0 5px;
        border-radius: 3px;
        position: relative;
        cursor: pointer;
        .el-icon-arrow-down {
          color: rgb(112, 192, 80);
          font-size: 12px;
          position: absolute;
          right: 5px;
          top: 5px;
          left: auto;
        }
      }
      .base-span1 {
        font-size: 17px;
        font-weight: bold;
        color: #262626;
        float: left;
        text-align: left;
        max-width: 112px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      .base-span2 {
        font-size: 14px;
        font-weight: 400;
        color: #bbbbbb;
        float: left;
        margin-left: 10px;
        position: relative;
        top: 2px;
        max-width: 80px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        font-size: 12px;
        text-align: left;
      }
      .status-style {
        line-height: 54px;
        font-size: 14px;
        font-weight: 400;
        cursor: pointer;
        .el-icon-arrow-down {
          top: 3px;
        }
      }
    }
    .base-box-left {
      width: 48%;
      float: left;
      text-align: left;
      line-height: 54px;
      position: relative;
      background: none;

      &.descStyle {
        .p1 {
          position: relative;
          top: -10px;
        }
        .p2 {
          position: absolute;
          left: 0;
          bottom: -8px;
          color: #999;
        }
      }
    }
    .base-box-right {
      width: calc(52% - 25px);
      float: left;
      text-align: right;
      line-height: 20px;
      padding: 17px 0;
      padding-right: 25px;
      background: none;
      position: relative;
      z-index: 1;
      &.inputBox {
        padding: 0;
        height: 54px;
        input {
          height: 100%;
        }
      }
      &.bankNameSyle {
        width: 60% !important;
        padding-right: 0;
      }
      input {
        text-align: right;
        width: 100%;
        font-size: 16px;
        font: 400 14px Techna;
        &::placeholder {
          color: #bbbbbb;
          font-size: 16px;
          font: 400 14px Techna;
        }
      }
      .sumText {
        cursor: pointer;
        font-size: 16px;
        font: 400 14px Techna;
        // padding-right: 16px;
        position: relative;
        .span-desc {
          position: absolute;
          right: 0;
          bottom: -14px;
          z-index: 1;
          color: #bbb;
          display: inline-block;
          font-size: 12px;
        }
      }
    }
    .span-left {
      color: #ed4956;
      position: absolute;
      left: -11px;
      top: 0px;
      padding-right: 5px;
      cursor: pointer;
    }
    .span-right {
      float: right;
      cursor: pointer;
    }
    .syq-style {
      width: 100%;
      float: left;
      text-align: left;
      position: absolute;
      bottom: 8px;
      font-size: 12px;
      font-weight: 400;
      line-height: 14px;
      color: #999999;
      .syq-styles-pan1 {
        float: right;
        color: #262626;
        transform: scale(0.83, 0.83);
        position: relative;
        right: -1%;
        span {
          color: #999999;
        }
      }
      .syq-styles-pan1-l {
        float: left;
        font-size: 12px;
        transform: scale(0.83, 0.83);
        position: relative;
        left: -5%;
      }
    }
    .base-box-icon {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      text-align: right;
      z-index: 0;
      line-height: 56px;
      font-size: 14px;
      font-weight: bold;
      color: #262626;
    }
  }
  .geshui {
    .base-box-left {
      width: 30%;
    }
    .base-box-right {
      width: 70% !important;
      cursor: pointer;
      padding: 0;
    }
  }
}
.staff-popup-box-title {
  text-align: left;
  margin-left: 20px;
  margin-right: 20px;
  padding-top: 16px;
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(219, 219, 219, 0.5);
}
.personInfo-footer {
  width: 100%;
  overflow: hidden;
  text-align: center;
  margin: 40px 0 20px;
  .personInfo-footer-btn {
    width: 680px;
    height: 54px;
    line-height: 54px;
    background: #4c8afc;
    border: 1px solid #4c8afc;
    opacity: 1;
    border-radius: 3px;
    display: inline-block;
    color: #fff;
    font-size: 20px;
    font-weight: bold;
    cursor: pointer;
  }
}
.personInfo-tips {
  width: 100%;
  // margin: 10px auto;
  text-align: center;
  height: 42px;
  line-height: 42px;
  background: #f0f6fc;
  span {
    color: #ed4956;
    font-weight: 400;
    font-size: 12px;
    // transform: scale(0.83, 0.83);
    display: inline-block;
    // position: relative;
    // left: -8%;
    width: 100%;
  }
}
.personInfo-ul {
  width: 100%;
}
.li-header {
  width: calc(100%);
  height: 33px;
  line-height: 33px;
  background: #f4f4f4;
  .li-left {
    color: #999999;
    &:last-child {
      width: calc((100% / 4) - 10px);
      padding-right: 10px;
    }
  }
}
.li-body {
  padding: 8px 0;
  width: calc(100%);
  border-bottom: 1px solid rgba(219, 219, 219, 0.5);
  overflow: hidden;
  .li-left {
    line-height: 38px;
    position: relative;
  }
  &:last-child {
    border-bottom: none;
  }
}
.li-left {
  float: left;
  width: calc(100% / 4);
  text-align: center;
  position: relative;
  &:first-child {
    text-align: left;

    width: calc((100% / 4) - 10px);
    padding-left: 10px;
  }
  &:last-child {
    text-align: right;
  }
  .add3 {
    position: absolute;
    right: -15px;
    top: 0;
    font-size: 12px;
    color: #262626;
  }
  span {
    font-size: 10px;
    transform: scale(0.83, 0.83);
    display: inline-block;
    position: absolute;
    top: 1px;
    right: 2px;
  }
  input {
    width: 60px;
    height: 38px;
    background: #f4f4f4;
    text-align: center;
    font-size: 16px;
    font-weight: bold;
  }
}
.cover-div {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 99;
  background: rgba(255, 255, 255, 0.8);
}
.modular-manage-popup {
  width: 290px;
  background: #ffffff;
  border: 1px solid #4c8afc;
  position: fixed;
  z-index: 9;
  .modular-manage-popup-list {
    padding: 10px 15px 30px;
    ul li {
      padding: 8px 0;
      border-bottom: 1px solid rgba(219, 219, 219, 0.5);
      cursor: pointer;
      position: relative;
      p {
        margin: 0;
        padding: 0;
        line-height: 18px;
        &.li-p1 {
          font-size: 14px;
          font-weight: bold;
          line-height: 20px;
          color: #262626;
          &.noDesc {
            line-height: 38px;
          }
        }
        &.li-p2 {
          font-size: 12px;
          font-weight: 400;
          line-height: 17px;
          color: #bbbbbb;
        }
      }
    }
  }
}
.payrollColor1 {
  border-bottom: 1px solid #ea0200 !important;
  .base-box-left {
    color: #ea0200 !important;
  }
}
.bankStyle {
  display: inline-block;
  width: calc(100% - 40px);
}
.yingfa {
  height: 54px;
  margin-top: 10px !important;

  // border-bottom: none !important;
  position: relative;
  .yingfaBg {
    background: #f8f9fa;
    position: absolute;
    left: -15px;
    top: 0;
    width: calc(100% + 30px);
    height: 100%;
  }
  .base-box-left,
  .base-box-right {
    // color: #bbbbbb;
  }
  .span-right {
    // color: #bbbbbb;
  }
}
.department-btn {
  position: relative;
  text-align: right;
  top: 0;
  .addStaff-btn {
    margin: 15px 0;
  }
}
.arrow-right {
  font-size: 18px;
  color: #dbdbdb;
  font-weight: bold;
  position: relative;
  top: 2px;
  right: -6px;
}
</style>
<style lang="less">
.li-left {
  .selectStyle {
    width: 60px;
    .el-input__icon {
      height: 30px;
      line-height: 30px;
      color: #999999 !important;
    }
    &.el-select {
      height: 30px;
      line-height: 30px;
    }
    .el-input__inner {
      border: 1px solid #dbdbdb !important;
      text-align: center !important;
    }
    .el-select__caret {
      display: inline-block !important;
    }
  }
}
.base-box-right {
  .el-textarea {
    .el-textarea__inner {
      min-height: 21px;
      height: 42px;
      border: none;
      resize: none;
      position: relative;
      top: -2px;
      text-align: right;
      padding: 0px;
      margin: 0px;
      color: #262626;
      font-weight: 400;
      font: 400 14px Techna;
    }
  }
  .el-switch__core {
    width: 50px !important;
    height: 28px;
    /*color:#409EFF;*/
    border-radius: 30px;
    padding-right: 10px;
  }
  /*设置圆*/
  .el-switch__core::after {
    width: 26px;
    height: 26px;
    margin-top: -1px;
    margin-bottom: 2px;
  }
  .el-switch.is-checked .el-switch__core::after {
    margin-left: -26px;
  }
  .el-date-editor {
    width: 100%;
    .el-input__inner {
      height: 54px;
      padding: 0;
      margin: 0;
      text-align: right;
      border: none;
      background: none;
      font-size: 16px;
      font-weight: 400;
      color: #262626;
      &::placeholder {
        color: #bbbbbb;
      }
    }
    .el-input__prefix,
    .el-input__suffix {
      display: none;
    }
  }
}
</style>
