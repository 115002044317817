<template>
  <div class="popup-layer">
    <div class="popup-layer-bg" @click="rosterClose"></div>
    <div class="personPopup" v-if="personShow">
      <div class="popup-layer-close" @click="rosterClose">
        <svg-icon icon-class="popup-close"></svg-icon>
      </div>
      <div class="addStaffPopup-header">
        <span v-if="enterpriseEmployee.name">
          {{ enterpriseEmployee.name }} -
        </span>
        员工信息
      </div>
      <div class="addStaffPopup-content">
        <div class="">
          <div class="addStaff-main" v-if="addStaffShow">
            <div
              class="hideStyle"
              v-if="enterpriseEmployee.status + '' === '2'"
            ></div>
            <div class="">
              <div
                class="addStaff-list"
                v-for="(item, i) in employeeDate.employeeList"
                :key="i"
              >
                <p style="color: #bbbbbb" v-if="i === 0">基本信息</p>
                <p style="color: #bbbbbb" v-if="i === 1">岗位信息</p>
                <slot v-for="(child, i1) in item.list">
                  <slot
                    v-if="
                      (child.field === 'status' &&
                        enterpriseEmployee.isAdmin === 1) ||
                      (enterpriseEmployee.status === 10 &&
                        child.field === 'status')
                    "
                  ></slot>
                  <slot v-else-if="child.field === 'onTrialTermSalaryScale'">
                    <slot
                      v-if="enterpriseEmployee.isOnterm + '' === '0'"
                    ></slot>
                    <slot v-else>
                      <StaffBox
                        :key="'info-' + i1"
                        :item="child"
                        :index1="i + ''"
                        :index2="i1"
                        :ajaxData="enterpriseEmployee"
                        @staffParent="staffParent"
                        staffType="add"
                      ></StaffBox>
                    </slot>
                  </slot>
                  <StaffBox
                    v-else
                    :key="'info-' + i1"
                    :item="child"
                    :index1="i + ''"
                    :index2="i1"
                    :ajaxData="enterpriseEmployee"
                    @staffParent="staffParent"
                    :staffType="staffType"
                  ></StaffBox>
                </slot>
              </div>
            </div>
            <div class="clear"></div>
            <div
              class="addStaff-lines"
              v-if="!isShow"
              @click="isShow = !isShow"
            >
              <svg-icon icon-class="big-jiantou" class="big-jiantou"></svg-icon>
            </div>
            <div style="overflow: hidden; margin-top: 20px" v-if="isShow">
              <div
                class="addStaff-list addStaff-list-bottom"
                v-for="(item, i) in employeeDate.employeeMoreList"
                :key="'info-' + i"
              >
                <p style="color: #bbbbbb" v-if="i === 0">选填</p>
                <p style="height: 19px" v-if="i === 1">　</p>
                <slot v-for="(child, i1) in item.list">
                  <slot v-if="child.field === 'disabilityNum'">
                    <StaffBox
                      v-if="
                        item.list[i1 - 1].value+'' !== '0' &&
                        item.list[i1 - 1].value+'' !== '-1' &&
                        item.list[i1 - 1].value+'' !== ''
                      "
                      :key="'info-' + i1"
                      :item="child"
                      :index1="i + ''"
                      :index2="i1"
                      :ajaxData="enterpriseEmployee"
                      @staffParent="staffParent"
                      :staffType="staffType"
                    ></StaffBox>
                  </slot>
                  <slot v-else-if="child.field === 'martyrFamilyNum'">
                    <StaffBox
                      v-if="
                        item.list[i1 - 1].value+'' !== '0' &&
                        item.list[i1 - 1].value+'' !== '-1' &&
                        item.list[i1 - 1].value+'' !== ''
                      "
                      :key="'info-' + i1"
                      :item="child"
                      :index1="i + ''"
                      :index2="i1"
                      :ajaxData="enterpriseEmployee"
                      @staffParent="staffParent"
                      :staffType="staffType"
                    ></StaffBox>
                  </slot>
                  <slot v-else>
                    <StaffBox
                      :key="'info-' + i1"
                      :item="child"
                      :index1="i + ''"
                      :index2="i1"
                      :ajaxData="enterpriseEmployee"
                      @staffParent="staffParent"
                      :staffType="staffType"
                    ></StaffBox>
                  </slot>
                </slot>
              </div>
            </div>
            <div class="clear"></div>
            <div class="addStaff-lines" v-if="isShow" @click="isShow = !isShow">
              <svg-icon
                icon-class="big-jiantou"
                class="big-jiantou isRotate"
              ></svg-icon>
            </div>
      
          </div>
        </div>
      </div>
      <div class="addStaff-footer-btns" v-if="baosongStatus + '' !== '1'">
            <div
              class="addStaff-footer-btn"
              v-if="enterpriseEmployee.status + '' !== '2'"
              @click="addStaffSave"
            >
              保 存
            </div>
      </div>
      <div class="addStaff-footer-btns" v-else>
        <div
          class="addStaff-footer-btn"
          style="opacity: 0.5; border-color: rgb(76 138 252 / 0.5)"
        >
          报送中
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StaffBox from "@/views/roster/components/StaffBox";
import bus from "@/util/bus";
import {
  getEmployeeInfo,
  getEmployeeInfoUserId,
  prefectEnterpriseEmployee,
  modifyEnterpriseEmployee,
} from "@/http/api";
export default {
  data() {
    return {
      value: "",
      userId: sessionStorage.getItem("userId"),
      enterpriseId: sessionStorage.getItem("enterpriseId"),
      employeeDate: this.$dictionaries.employeeDate.child1,
      enterpriseEmployee: {},
      staffType: "details",
      staffTitle: "完善员工信息",
      status: "",
      baosongStatus: "2",
      addStaffShow: false,
      isShow: false,
      personShow: false,
    };
  },
  props: {
    uId: {
      type: String,
      default() {
        return null;
      },
    },
    eId: {
      type: String,
      default() {
        return null;
      },
    },
  },
  components: {
    StaffBox,
  },
  mounted() {
    console.log(this.uId);
    this.getEmployeeInfo(this.uId, this.eId);
  },
  methods: {
    // 提交按钮
    addStaffSave() {
      bus.$emit("hideLines");
      let list = this.employeeDate.employeeList;
      if (this.enterpriseEmployee.isOnterm === 0) {
        this.enterpriseEmployee.onTrialTermSalaryScale =
          this.enterpriseEmployee.postSalary;
        list[1].list[4].value = this.enterpriseEmployee.postSalary;
      }
      for (let i1 = 0; i1 < list.length; i1++) {
        for (let i2 = 0; i2 < list[i1].list.length; i2++) {
          let item = list[i1].list[i2];
          if (item.require) {
            if (item.field === "onTrialTermBegin") {
              if (this.enterpriseEmployee.isOnterm) {
                if (!item.onTrialTermBegin || !item.onTrialTermEnd) {
                  bus.$emit(item.field, true);
                  this.$store.commit("MyMessage", item.text + "不能为空");
                  return false;
                }
                bus.$emit(item.field, false);
              }
            } else if (item.field === "province") {
              if (!item.value1) {
                bus.$emit(item.field, true);
                this.$store.commit("MyMessage", item.text + "不能为空");
                return false;
              }
              bus.$emit(item.field, false);
            } else if (item.field === "mobile") {
              if (!item.value) {
                bus.$emit(item.field, true);
                this.$store.commit("MyMessage", item.text + "不能为空");
                return false;
              } else {
                const regMobile = /^1(3|4|5|6|7|8|9)\d{9}$/;
                console.log();
                if (!regMobile.test(item.value)) {
                  bus.$emit(item.field, true);
                  this.$store.commit("MyMessage", "手机号不符合规则");
                  return;
                }
              }
              bus.$emit(item.field, false);
            } else if (item.field === "idNumber") {
              if (!item.value) {
                bus.$emit(item.field, true);
                this.$store.commit("MyMessage", item.text + "不能为空");
                return false;
              } else {
                if (this.enterpriseEmployee.idType === 1) {
                  let testId = this.$config.testid(item.value);
                  if (testId.status === 0) {
                    this.$store.commit(
                      "MyMessage",
                      "身份证格式有误，请重新填写"
                    );
                    bus.$emit(item.field, true);
                    return false;
                  }
                }
              }
              bus.$emit(item.field, false);
            } else {
              if (!item.value) {
                bus.$emit(item.field, true);
                console.log(item);
                this.$store.commit("MyMessage", item.text + "不能为空");
                return;
              }
              bus.$emit(item.field, false);
            }
          }
        }
      }
      if (this.enterpriseEmployee.idType + "" === "3") {
        if (
          this.enterpriseEmployee.idNumber.length === 9 ||
          this.enterpriseEmployee.idNumber.length === 11
        ) {
        } else {
          this.$store.commit("MyMessage", "证件号码未完善");
          bus.$emit("idNumber", true);
          return false;
        }
      }
      if (this.enterpriseEmployee.idType + "" === "4") {
        if (this.enterpriseEmployee.idNumber.length === 8) {
        } else {
          this.$store.commit("MyMessage", "证件号码未完善");
          bus.$emit("idNumber", true);
          return false;
        }
      }

      // console.log(this.enterpriseEmployee);
      if (this.enterpriseEmployee.jobTime) {
        this.enterpriseEmployee.jobTime =
          this.enterpriseEmployee.jobTime.split(" ")[0];
      }
      // this.enterpriseEmployee.mobile = "17872142236";
      this.enterpriseEmployee.idNumber =
        this.enterpriseEmployee.idNumber.toUpperCase();
      if (this.enterpriseEmployee.userId === this.userId) {
        // * 完善员工信息[自己给自己]
        this.prefectEnterpriseEmployee(this.enterpriseEmployee);
      } else {
        this.modifyEnterpriseEmployee(this.enterpriseEmployee);
      }
    },
    rosterClose() {
      this.$emit("rosterClose");
    },

    prefectEnterpriseEmployee(enterpriseEmployee) {
      prefectEnterpriseEmployee(enterpriseEmployee).then((res) => {
        // console.log(res);
        if (res.code === 0) {
          setTimeout(() => {
            // this.gotoFun("/roster");
            this.$emit("rosterSave");
            // window.close();
            // this.$store.commit("MyMessage", "修改成功");
          }, 500);
        } else {
          this.$store.commit("MyMessage", res.msg);
        }
      });
    },
    modifyEnterpriseEmployee(enterpriseEmployee) {
      let date = {
        enterpriseEmployeeVo3: enterpriseEmployee,
        userId: this.userId,
        enterpriseId: this.enterpriseId,
        employeeId: enterpriseEmployee.employeeId,
      };
      modifyEnterpriseEmployee(date).then((res) => {
        // console.log(res);
        if (res.code === 0) {
          setTimeout(() => {
            this.$emit("rosterSave");
            // window.close();
            // this.$store.commit("MyMessage", "修改成功");
          }, 500);
        } else {
          this.$store.commit("MyMessage", res.msg);
        }
      });
    },
    getEmployeeInfoUserId(user) {
      let param = {
        enterpriseId: this.enterpriseId,
        userId: user,
      };
      getEmployeeInfoUserId(param).then((res) => {
        if (res.code === 0) {
          console.log(res);
          this.getEmployeeInfo(res.data.userId, res.data.employeeId);
        }
      });
    },
    // 证件类型--切换判断
    idTypeFun(item, index1, index2) {
      this.addStaffShow = false;
      if (item.value === "1") {
        this.employeeDate = this.$config.cloneObj(
          this.$dictionaries.employeeDate.child1
        );
      } else if (item.value === "5") {
        this.employeeDate = this.$config.cloneObj(
          this.$dictionaries.employeeDate.child3
        );
      } else {
        this.employeeDate = this.$config.cloneObj(
          this.$dictionaries.employeeDate.child2
        );
      }
      this.employeeDate.employeeList.forEach((e1, i1) => {
        e1.list.forEach((e2, i2) => {
          e2.value = "";
        });
      });
      if (item.value === "1") {
        this.enterpriseEmployee.nationality = "1";
        this.employeeDate.employeeList[0].list[2].arrayList =
          this.$dictionaries.employeeModel.registeredData.list;
        this.employeeDate.employeeList[0].list[2].value = "";
      } else if (item.value === "2") {
        this.enterpriseEmployee.nationality = "1";
        this.employeeDate.employeeList[0].list[2].value = "1";
        this.employeeDate.employeeList[0].list[2].arrayList =
          this.$dictionaries.countryData;
      } else if (item.value === "3") {
        this.enterpriseEmployee.nationality = "4";
        this.employeeDate.employeeList[0].list[2].value = "4";
        this.employeeDate.employeeList[0].list[2].arrayList =
          this.$dictionaries.gangao;
      } else if (item.value === "4") {
        this.enterpriseEmployee.nationality = "3";
        this.employeeDate.employeeList[0].list[2].value = "3";
        this.employeeDate.employeeList[0].list[2].arrayList =
          this.$dictionaries.taiwan;
      } else {
        this.enterpriseEmployee.nationality = "";
        this.employeeDate.employeeList[0].list[2].value = "";
        this.employeeDate.employeeList[0].list[2].arrayList =
          this.$dictionaries.countryData;
      }
      this.employeeDate.employeeList[index1].list[index2].value = item.value;
      this.employeeDate.employeeList[1].list[0].value = "3";
      this.employeeDate.employeeList[1].list[1].value = "1";
      this.employeeDate.employeeList[1].list[4].value = "";
      this.$nextTick(() => {
        this.addStaffShow = true;
      });
    },
    staffParent(item, index1, index2) {
      console.log(item);
      this.enterpriseEmployee[item.field] = item.value;
      if (item.field === "idType") {
        this.idTypeFun(item, index1, index2);
      } else if (item.field === "identity") {
        if (item.value + "" === "2") {
          this.enterpriseEmployee.investMoney = item.value1;
          this.enterpriseEmployee.investproportion = item.value2;
        }
      } else if (item.field === "contractType") {
        if (item.value + "" === "2") {
          this.enterpriseEmployee.identity = "6";
          this.employeeDate.employeeList[index1].list[index2 + 1].value = "6";
        }
        if (item.value + "" === "1") {
          this.enterpriseEmployee.identity = "3";
          this.employeeDate.employeeList[index1].list[index2 + 1].value = "3";
        }
      } else if (item.type === "city") {
        this.enterpriseEmployee[item.field + "Id"] = item.value1;
        this.enterpriseEmployee[item.city + "Id"] = item.value2;
        this.enterpriseEmployee[item.area + "Id"] = item.value3;
        this.enterpriseEmployee[item.field] = item.desc1;
        this.enterpriseEmployee[item.city] = item.desc2;
        this.enterpriseEmployee[item.area] = item.desc3;
      } else if (item.field === "jobTime") {
        this.enterpriseEmployee.onTrialTermBegin = item.value;
        this.enterpriseEmployee.onTrialTermEnd = item.value;
        // this.enterpriseEmployee.onTrialTermSalaryScale = "";
        this.employeeDate.employeeList[index1].list[
          index2 + 1
        ].onTrialTermBegin = item.value;
        this.employeeDate.employeeList[index1].list[index2 + 1].onTrialTermEnd =
          item.value;
        // this.employeeDate.employeeList[index1].list[index2 + 3].value = "";
        this.isOntermFun();
        // console.log(this.employeeDate.employeeList[index1].list[index2 + 3]);
      } else if (item.field === "onTrialTermBegin") {
        this.enterpriseEmployee.onTrialTermBegin = item.onTrialTermBegin;
        this.enterpriseEmployee.onTrialTermEnd = item.onTrialTermEnd;
        this.employeeDate.employeeList[index1].list[index2].onTrialTermBegin =
          item.onTrialTermBegin;
        this.employeeDate.employeeList[index1].list[index2].onTrialTermEnd =
          item.onTrialTermEnd;
        if (item.onTrialTermBegin === item.onTrialTermEnd) {
          // this.employeeDate.employeeList[index1].list[index2 + 1].value = "";
          // this.enterpriseEmployee.onTrialTermSalaryScale = "";
        }
        this.isOntermFun();
      } else if (item.field === "idcardImgFront") {
        this.enterpriseEmployee.idcardImgFront = item.back;
        this.enterpriseEmployee.idcardImgReverse = item.positive;
        this.employeeDate.employeeList[index1].list[index2].positive =
          item.positive;
        this.employeeDate.employeeList[index1].list[index2].back = item.back;
      } else if (item.field === "degreeImg") {
        this.enterpriseEmployee.degreeImg = item.back;
        this.enterpriseEmployee.diplomaImg = item.positive;
        this.employeeDate.employeeList[index1].list[index2].degreeImg =
          item.back;
        this.employeeDate.employeeList[index1].list[index2].diplomaImg =
          item.positive;
      } else if (item.field === "status") {
        this.enterpriseEmployee.dimissionTime = item.dimissionTime;
      } else if (item.field === "bankProvinceId") {
        this.enterpriseEmployee.bankProvince = item.bankProvince;
      } else {
        this.employeeDate.employeeList[index1].list[index2].value = item.value;
      }
      this.$forceUpdate();
    },
    // 获取员工信息
    getEmployeeInfo(uid, eid) {
      let param = {
        enterpriseId: this.enterpriseId,
        userId: uid,
        employeeId: eid,
      };
      getEmployeeInfo(param).then((res) => {
        // console.log(res);
        if (res.code === 0) {
          this.enterpriseEmployee = res.data;
          this.status = res.data.status;
          this.isOntermFun();
          if (this.enterpriseEmployee.nationality + "" === "") {
            this.enterpriseEmployee.nationality = "1";
          }
          if (res.data.ptsControInfo.length > 0) {
            this.baosongStatus = res.data.ptsControInfo[0].baosongStatus;
          }
          const regMobile = /^1(3|4|5|6|7|8|9)\d{9}$/;
          if (regMobile.test(this.enterpriseEmployee.name)) {
            this.enterpriseEmployee.name =
              "*" +
              this.enterpriseEmployee.name.substr(
                this.enterpriseEmployee.name.length - 2,
                2
              );
          }
          if (!this.enterpriseEmployee.jobTime) {
            this.staffType = "add";
            this.enterpriseEmployee.jobTime = "";
            // this.enterpriseEmployee.onTrialTermSalaryScale = "";
          }
          if (this.enterpriseEmployee.isAdmin === 1) {
            this.staffTitle = "完善管理员信息";
          }
          if (!this.enterpriseEmployee.identity) {
            this.enterpriseEmployee.identity = 1;
          }
          if (!this.enterpriseEmployee.contractType) {
            this.enterpriseEmployee.contractType = 3;
          }
          if (
            this.enterpriseEmployee.idType === 1 ||
            !this.enterpriseEmployee.idType
          ) {
            this.enterpriseEmployee.idType = 1;
            this.employeeDate = this.$config.cloneObj(
              this.$dictionaries.employeeDate.child1
            );
          } else {
            this.employeeDate = this.$config.cloneObj(
              this.$dictionaries.employeeDate.child2
            );
          }
          // 获取数据后进行赋值
          this.assignmentFun();
          this.$nextTick(() => {
            this.addStaffShow = true;
            this.personShow = true;
          });
        }
      });
    },
    // 数据赋值
    assignmentFun() {
      for (let key in this.enterpriseEmployee) {
        this.employeeDate.employeeList.forEach((element) => {
          element.list.forEach((list) => {
            if (list.field === key) {
              list.value = this.enterpriseEmployee[key] + "";
              if (list.field === "nationality") {
                if (
                  this.enterpriseEmployee.idType + "" === "1" ||
                  this.enterpriseEmployee.idType + "" === "2" ||
                  this.enterpriseEmployee.idType + "" === "4"
                ) {
                  list.flag = false;
                } else {
                  list.flag = true;
                }
                if (this.enterpriseEmployee.idType + "" === "3") {
                  list.arrayList = this.$dictionaries.gangao;
                }
              }

              if (list.field === "onTrialTermBegin") {
                list.onTrialTermBegin =
                  this.enterpriseEmployee.onTrialTermBegin;
                list.onTrialTermEnd = this.enterpriseEmployee.onTrialTermEnd;
              }
              if (list.field === "province") {
                list.value1 = this.enterpriseEmployee.provinceId;
                list.value2 = this.enterpriseEmployee.cityId;
                list.value3 = this.enterpriseEmployee.areaId;
                list.desc1 = this.enterpriseEmployee.province;
                list.desc2 = this.enterpriseEmployee.city;
                list.desc3 = this.enterpriseEmployee.area;
              }
            }
            if (list.field === "birthDate" && key === "birth") {
              console.log(this.enterpriseEmployee);
            }
          });
        });
        this.employeeDate.employeeMoreList.forEach((element) => {
          element.list.forEach((list) => {
            if (list.field === key) {
              list.value = this.enterpriseEmployee[key] + "";
              if (list.type === "city") {
                list.value1 = this.enterpriseEmployee[list.city];
                list.value2 = this.enterpriseEmployee[list.area];
              }
              if (list.field === "idcardImgFront") {
                list.positive = this.enterpriseEmployee.idcardImgFront;
                list.back = this.enterpriseEmployee.idcardImgReverse;
              }
              if (list.field === "degreeImg") {
                list.positive = this.enterpriseEmployee.degreeImg;
                list.back = this.enterpriseEmployee.diplomaImg;
              }
              if (list.field === "oftenProvince") {
                list.value1 = this.enterpriseEmployee.oftenProvinceId;
                list.value2 = this.enterpriseEmployee.oftenCityId;
                list.value3 = this.enterpriseEmployee.oftenAreaId;
                list.desc1 = this.enterpriseEmployee.oftenProvince;
                list.desc2 = this.enterpriseEmployee.oftenCity;
                list.desc3 = this.enterpriseEmployee.oftenArea;
              }
              if (list.field === "contactProvince") {
                list.value1 = this.enterpriseEmployee.contactProvinceId;
                list.value2 = this.enterpriseEmployee.contactCityId;
                list.value3 = this.enterpriseEmployee.contactAreaId;
                list.desc1 = this.enterpriseEmployee.contactProvince;
                list.desc2 = this.enterpriseEmployee.contactCity;
                list.desc3 = this.enterpriseEmployee.contactArea;
              }
            }
          });
        });
      }
      console.log(this.employeeDate.employeeList);
      this.$forceUpdate();
    },
    // 判断试用期
    isOntermFun() {
      if (
        this.enterpriseEmployee.onTrialTermBegin &&
        this.enterpriseEmployee.onTrialTermEnd
      ) {
        if (
          this.enterpriseEmployee.onTrialTermBegin ===
          this.enterpriseEmployee.onTrialTermEnd
        ) {
          this.enterpriseEmployee.isOnterm = 0;
        } else {
          this.enterpriseEmployee.isOnterm = 1;
        }
      } else {
        this.enterpriseEmployee.isOnterm = 0;
      }
      this.$forceUpdate();
      // console.log(this.enterpriseEmployee.isOnterm);
    },
  },
};
</script>

<style lang="less" scoped>
.hideStyle {
  position: absolute;
  width: 100%;
  height: 100%;
}
.personPopup {
  width: 860px;
  height: 560px;
  background: #ffffff;
  opacity: 1;
  border-radius: 10px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -430px;
  margin-top: -280px;
}
.addStaffPopup-header {
  border-radius: 10px;
  background: #fff;
}
.addStaffPopup-content {
  width: 100%;
  height: calc(100% - 46px);
  overflow-y: auto;
  position: relative;
}
.addStaff-main {
  width: 740px;
  // padding: 0px 60px 0;
  padding: 0;
  float: left;
  margin-left: 60px;
  border: none;
  margin-bottom: 50px;
  position: relative;
  .hideStyle {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 999;
    background: rgba(255, 255, 255, 0.5);
  }
}
.addStaff-footer-btns {
  text-align: center;
}
.addStaff-lines {
  width: 100%;
  text-align: center;
  padding: 10px 0;
  background: rgba(248, 249, 250, 0.5);
  margin: 10px 0;
  cursor: pointer;
  .big-jiantou {
    width: 30px;
    &.isRotate {
      transform: rotate(180deg);
    }
  }
}
.addStaff-footer-btns {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background: #fff;
  padding-bottom: 15px;
  padding-top: 10px;
}
.addStaff-footer-btn {
  width: 170px;
  height: 34px;
  background: #4c8afc;
  border: 1px solid #1a73e8;
  opacity: 1;
  border-radius: 3px;
  display: inline-block;
  font-size: 16px;
  font-weight: bold;
  color: #ffffff;
  line-height: 34px;
  text-align: center;
  cursor: pointer;
}
</style>