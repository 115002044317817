<template>
  <div class="staffbox-div sHide">
    <div class="staffbox-tab" @click="dateClick">
      <div class="main-div-c">
        <div
          class="addStaff-tips"
          :class="{ noIcon: !showIcon }"
          v-if="!child.value"
        >
          {{ item.tips }}
        </div>
        <div class="addStaff-text" v-else>{{ child.value }}</div>
        <date-picker
          v-model="child.value"
          value-type="format"
          format="YYYY-MM-DD"
          @change="dateChange"
          class="data-picker-style"
          :placeholder="item.tips"
          :open.sync="openDates"
          :editable="false"
          :clearable="false"
          @click.stop="dataClick"
        ></date-picker>
        <!-- <div class="addStaff-box-right"></div> -->
      </div>
      <!-- <div class="main-div-r" v-if="showIcon && child.value"> -->
      <div class="main-div-r">
        <svg-icon icon-class="jiantou_shen" class="jiantou-icon"></svg-icon>
      </div>
    </div>
  </div>
</template>

<script>
import bus from "@/util/bus";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/zh-cn";
export default {
  data() {
    return {
      child: {},
      showIcon: true,
      openDates: false,
      date: this.$config.getDate(),
      visit: sessionStorage.getItem("visit"),
    };
  },
  inject: ["ajaxData"],
  components: {
    DatePicker,
  },
  mounted() {
    this.child = this.item;
    if (
      (this.staffType === "details" || this.staffType === "quitStaff") &&
      this.child.field === "jobTime"
    ) {
      if (this.child.value) {
        this.child.value = this.child.value.split(" ")[0];
      }
      if (this.visit !== "visit") {
        this.showIcon = false;
      }
    }
  },
  methods: {
    dateClick() {
      bus.$emit("hidePopup");
      console.log(11);
      if (this.staffType === "details" && this.child.field === "jobTime") {
        this.openDates = true;
        if (this.visit !== "visit") {
          return;
        }
      }
      this.openDates = true;
    },
    dateChange() {
      console.log(this.child.value);
      let param = {
        field: this.child.field,
        type: this.child.type,
        value: this.child.value,
      };
      if (this.child.field === "jobTime") {
        bus.$emit("dateChange", this.child.value);
      }
      this.$emit("staffParent", param);
    },
    blur() {
      console.log("child", this.child);
      // this.$emit("")
    },
  },
  props: {
    item: {
      type: Object,
      default() {
        return null;
      },
    },
    staffType: {
      type: String,
      default() {
        return null;
      },
    },
  },
};
</script>
<style lang="less" scoped>
@import "../payroll.less";
@import "../../../../assets/less/popup.less";

.main-div-c {
  position: relative;
}
</style>
<style lang="less">
.addStaff-box-right {
  .mx-datepicker {
    width: 100%;
    .mx-input {
      width: 100%;
      margin: 0;
      padding: 0;
      height: 22px;
      border: none;
      box-shadow: none;
      padding-right: 36px;
      font: 400 16px Techna;
      cursor: pointer;
    }
  }
  .mx-icon-calendar {
    display: none;
  }
}

.data-picker-style {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: -1;
}
</style>