import { render, staticRenderFns } from "./generatePayrollOld.vue?vue&type=template&id=293259a2&scoped=true&"
import script from "./generatePayrollOld.vue?vue&type=script&lang=js&"
export * from "./generatePayrollOld.vue?vue&type=script&lang=js&"
import style0 from "./generatePayrollOld.vue?vue&type=style&index=0&id=293259a2&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "293259a2",
  null
  
)

export default component.exports