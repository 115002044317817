<template>
  <div class="views-width">
    <div class="views-main">
      <div class="views-mian-header">
        <div class="views-mian-header-left">
          <!-- 日期
          <el-date-picker
            v-model="mouth"
            :editable="false"
            value-format="yyyy-MM"
            type="month"
            placeholder="选择月"
            @change="dateChange"
            :picker-options="pickerOptions"
          >
          </el-date-picker> -->
          <!-- <el-button type="primary" class="btn-primary btn-link"
            >返回</el-button
          > -->
          <div class="department-link1">
            <span @click="gotoFun('/payrollRecord')"
              ><i class="el-icon-arrow-left"></i>返回</span
            >
            <span style="color: #262626; cursor: default">
              合计 {{ tableData.length }} 人</span
            >
            <span style="color: #262626; font-size: 16px; cursor: default">
              {{ mouthText }}工资表</span
            >
            <el-select
              v-if="0"
              v-model="departmentId"
              @change="changeFun"
              placeholder="请选择"
            >
              <!-- {{ departmentList }} -->
              <el-option
                v-for="item in departmentList"
                :key="item.departmentId"
                :label="item.departmentName"
                :value="item.departmentId"
              >
              </el-option>
            </el-select>
          </div>

          <!-- <span>个税申报密码：</span> -->
        </div>
        <!-- <div class="views-mian-header-title"></div> -->
        <div class="views-mian-header-right">
          <dowmPopup
            :status="status"
            :shenbaoStatus="shenbaoStatus"
            :mouth="mouth"
            :tableData="tableData"
          ></dowmPopup>

          <el-button
            style="margin-left: 10px"
            type="primary"
            class="btn-primary"
            @click="huanfaClick"
            v-if="(payStatus === 302 || payStatus === 305) && salaryNum > 0"
            >缓发工资</el-button
          >
          <div
            class="btn-link"
            v-if="
              shenbaoStatus !== 60 && tableData.length > 0 && shenpiStatus < 5
            "
            @click="revokeSalaryV2"
          >
            重新制作工资表
            <span style="color: #bbbbbb; font-size: 12px">（重新算税）</span>
          </div>
          <div
            class="btn-link"
            v-if="
              (shenpiStatus === 5 ||
                shenpiStatus === 6 ||
                shenpiStatus === 2) &&
              shenbaoStatus > 40 &&
              shenbaoStatus < 60 &&
              visit === 'visit' &&
              0
            "
            @click="queryShenbaoStatusV2"
          >
            查询申报状态
          </div>
          <el-button
            style="margin-left: 10px"
            type="primary"
            class="btn-primary"
            @click="approvalClick"
            v-if="shenpiStatus === 1 || shenpiStatus === 4"
            >提交审批</el-button
          >

          <el-button
            type="primary"
            class="btn-primary x"
            style="margin-left: 10px; padding: 0 15px"
            v-else-if="shenpiStatus === 2"
          >
            <!-- 审批中 -->
            <slot>审批中</slot>
          </el-button>

          <el-button
            type="primary"
            class="btn-primary"
            style="margin-left: 10px; padding: 0 15px"
            v-else-if="
              (shenpiStatus === 5 ||
                shenpiStatus === 6 ||
                shenpiStatus === 2) &&
              shenbaoStatus !== 60
            "
            :class="{
              shenbaoStatus60: shenbaoStatus === 60 || tableData.length === 0,
              shenbaoStatus80: shenbaoStatus === 80,
              shenbaoStatus100: shenbaoStatus === 100,
            }"
            @click="click"
          >
            <!-- 申报表报送中 -->
            <slot v-if="shenbaoStatus === 50">申报表报送中...</slot>
            <!-- 未申报 -->
            <slot v-else-if="shenbaoStatus === 30">
              <svg-icon icon-class="gssb"></svg-icon>
              <span style="margin-left: 5px">申报个税</span>
            </slot>
            <!-- 待报税 -->
            <slot v-else-if="shenbaoStatus === 40">
              <svg-icon icon-class="gssb"></svg-icon>
              <span style="margin-left: 5px">申报个税</span>
            </slot>
            <!-- 申报成功 -->
            <slot v-else-if="shenbaoStatus === 60">申报成功</slot>
            <!-- 报税失败 -->
            <slot v-else-if="shenbaoStatus === 100">申报失败</slot>
            <!-- 其他情况 -->
            <slot v-else>
              <svg-icon icon-class="gssb"></svg-icon>
              <span style="margin-left: 5px">申报个税</span></slot
            >
          </el-button>
          <slot v-else-if="shenbaoStatus === 60">
            <div
              class="btn-link"
              style="background: #f4f4f4; border: 1px solid #dbdbdb"
              v-if="payStatus !== 302 && payStatus !== 305"
              @click="baoshuiV2Zuofei"
            >
              <!-- v-if="payStatus !== 302" -->
              <svg-icon icon-class="zuofei"></svg-icon> 作废工资
              <span style="color: #bbbbbb; font-size: 12px">（重新申报）</span>
            </div>
            <el-button
              style="margin-left: 10px"
              type="primary"
              class="btn-primary"
              @click="sbsj = true"
              v-if="shenbaoStatus === 60 && 0"
              >申报数据</el-button
            >
            <button
              class="overall-btn active"
              @click="shenbaoFun"
              style="padding: 0 15px"
              :style="{
                background:
                  payStatus === 0
                    ? '#FF931E'
                    : payStatus === 305 || payStatus === 302
                    ? '#E6F3DB'
                    : '#FF931E',
                color: payStatus === 305 || payStatus === 302 ? '#70C050' : '',
              }"
              :class="{
                shenbaoStatus60: tableData.length === 0,
              }"
              v-if="incomeTaxFlag"
            >
              <slot v-if="payStatus === 300">
                <slot v-if="zuofeiStatus === 501">作废申报中</slot>

                <slot v-else>
                  待缴款
                  <slot v-if="taxTotal && taxTotal !== ''"
                    >¥{{ taxTotal }}</slot
                  >
                  <i v-else class="el-icon-loading"></i
                ></slot>
              </slot>
              <slot v-else-if="payStatus === 301">个税缴纳中...</slot>
              <slot v-else-if="payStatus === 302">申报成功 缴款成功</slot>
              <slot v-else-if="payStatus === 0"
                >待缴款
                <slot v-if="taxTotal && taxTotal !== ''">¥{{ taxTotal }}</slot>
                <i v-else class="el-icon-loading"></i
              ></slot>
              <slot v-else-if="payStatus === 305">申报成功 无需缴款</slot>
              <slot v-else>申报成功 缴款失败</slot>
            </button>
            <button
              class="overall-btn active"
              @click="baoshuiV2Correct()"
              :class="{
                shenbaoStatus60: tableData.length === 0,
              }"
              style="background: FF931E"
              v-if="0"
            >
              更正申报
            </button>
          </slot>
        </div>
      </div>
      <div class="views-mian-scroll">
        <div class="views-mian-content">
          <generateTable
            v-if="tableData.length > 0"
            :tableData="tableData1"
            :tdShowList="tdShowList"
            :totalPage="totalPage"
            @pageFun="pageFun"
          ></generateTable>
          <NoRecord v-else></NoRecord>
          <!-- <el-pagination
            v-if="totalPage > 1"
            style="margin-top: 20px; text-align: right"
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="pages.pageNum"
            :page-sizes="[30, 60, 100]"
            :page-size="pages.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="pages.total"
            >>
          </el-pagination> -->
        </div>
        <TheFooter></TheFooter>
      </div>
    </div>
    <invitationPopup
      :mouth="mouth"
      v-if="approvalShow"
      :batch="batch"
      @approvalClose="approvalClose"
    ></invitationPopup>
    <declarePopup
      :mouth="mouth"
      v-if="declareShow"
      :taxTotal="taxTotal"
      @approvalClose="approvalClose"
    ></declarePopup>
    <declareVisitPopup
      :mouth="mouth"
      v-if="invitationShow1"
      :tableData="tableData"
      :taxTotal="taxTotal"
      :srze="srze"
      :batch="batch"
      @approvalClose="approvalClose"
      @showTPage4="showTPage4"
    ></declareVisitPopup>
    <delayPopup
      :mouth="mouth"
      v-if="delayPopupFlag"
      :tableData="delayData"
      :batch="batch"
      @delayClose="delayClose"
    ></delayPopup>
    <transitionPage2 v-if="tPage2" @tFun="tFun"></transitionPage2>
    <transitionPage3 v-if="tPage3" @tFun="tFun"></transitionPage3>
    <transitionPage4 v-if="tPage4" @tFun="tFun"></transitionPage4>
    <transitionPage5 v-if="tPage5" @tFun="tFun"></transitionPage5>
    <shenbaoQuery v-if="sbsj"></shenbaoQuery>
    <!-- 未到申报时间 -->
    <div class="popup-layer" v-if="payrollTips">
      <div class="popup-layer-bg" @click="payrollTips = false"></div>
      <div
        class="huifuPopup zhizuoPopup"
        style="
          width: 270px;
          height: 180px;
          margin-left: -135px;
          margin-top: -90px;
        "
      >
        <div class="popup-layer-close" @click="rosterClose">
          <svg-icon icon-class="popup-close"></svg-icon>
        </div>
        <div class="huifuPopup-content">
          <p class="huifuPopup-p2">审批通过后才可以申报个税哦！</p>
          <div class="huifuPopup-btns" style="padding-top: 24px">
            <!-- <div class="huifuPopup-btn-l" @click="rosterClose">取 消</div> -->
            <div class="huifuPopup-btn-r" @click="payrollTips = false">
              我知道了
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 查询申报状态 -->
    <div class="popup-layer" v-if="shenbaoPopup">
      <div class="popup-layer-bg" @click="shenbaoPopup = false"></div>
      <div
        class="huifuPopup zhizuoPopup"
        style="
          width: 270px;
          height: 180px;
          margin-left: -135px;
          margin-top: -90px;
        "
      >
        <div class="popup-layer-close" @click="shenbaoPopup = false">
          <svg-icon icon-class="popup-close"></svg-icon>
        </div>
        <div class="huifuPopup-content">
          <p class="huifuPopup-p2">审批通过后才可以申报个税哦！</p>
          <div class="huifuPopup-btns" style="padding-top: 24px">
            <!-- <div class="huifuPopup-btn-l" @click="rosterClose">取 消</div> -->
            <div class="huifuPopup-btn-r" @click="shenbaoPopup = false">
              我知道了
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  SalaryservicefindEmployeeSalary,
  queryBaoshuiamount,
  reMakeSalary,
  salaryRecord,
  revokeSalaryV2,
  findEmployeeSalaryStatusV2,
  findSalaryComprehensiveV2,
  queryMonthSalaryListV2,
  baoshuiV2,
  baoshuiV2Zuofei,
  queryShenbaoStatusV2,
  baoshuiV2Correct,
  arrearsQuery,
  shenbaoDataQuery,
} from "@/http/api";
import invitationPopup from "./components/invitationPopup";
import declarePopup from "./components/declarePopup";
import delayPopup from "./components/delayPopup";
import declareVisitPopup from "./components/declareVisitPopup";
import generateTable from "./components/generateTable";
import dowmPopup from "./components/dowmPopup";
import transitionPage2 from "./components/transitionPage2";
import transitionPage3 from "./components/transitionPage3";
import transitionPage4 from "./components/transitionPage4";
import transitionPage5 from "./components/transitionPage5";
import shenbaoQuery from "./components/shenbaoQuery";
import NoRecord from "@/components/NoRecord.vue";
import TheFooter from "@/components/TheFooter.vue";
export default {
  data() {
    return {
      userId: sessionStorage.getItem("userId"),
      enterpriseId: sessionStorage.getItem("enterpriseId"),
      mouth: "",
      date: this.$config.getDate(),
      pickerOptions: {
        disabledDate(time) {
          let _now = Date.now(),
            seven = 7 * 24 * 60 * 60 * 1000,
            sevenDays = _now - seven;
          return time.getTime() > _now; //大于当前的禁止，小于7天前的禁止
        },
      },
      tableData: [],
      tableData1: [],
      totalRow: null,
      taxTotal: null,
      totalPage: 0,
      srze: null,
      visit: sessionStorage.getItem("visit"),
      batch: "",
      status: "",
      payStatus: "",
      zuofeiStatus: "",
      correctStatus: "",
      shenbaoStatus: null,
      leaveFlag: true,
      tdShowList: {
        tdShow1: true,
        tdShow2: true,
        tdShow3: true,
        tdShow4: true,
        tdShow5: true,
        tdShow6: true,
        tdShow7: true,
        tdShow8: true,
        tdShow9: true,
        laowu: false,
        laodong: false,
      },
      shenbaoPopup: false,
      payrollTips: false,
      approvalShow: false,
      invitationShow1: false,
      declareShow: false,
      delayPopupFlag: false,
      incomeTaxFlag: 1,
      mouthText: "",
      list: [],
      shenpiStatus: null,
      payFlag: false,
      tPage2: false,
      tPage3: false,
      tPage4: false,
      tPage5: false,
      sbsj: 0,
      sbzt: 0,
      delayData: [],
      comprehensive: {},
      pages: {
        pageNum: 1,
        pageSize: 50,
        total: 0,
      },
      salaryNum: 0,
      departmentList: JSON.parse(sessionStorage.getItem("departmentList")),
      departmentId: "",
    };
  },
  components: {
    invitationPopup,
    declarePopup,
    declareVisitPopup,
    generateTable,
    dowmPopup,
    NoRecord,
    TheFooter,
    transitionPage2,
    transitionPage3,
    transitionPage4,
    transitionPage5,
    delayPopup,
    shenbaoQuery,
  },
  mounted() {
    this.batch = this.$route.query.batch;
    this.mouth = this.$route.query.month;
    if (this.departmentList) {
      this.departmentList.unshift({
        departmentName: "请选择",
        departmentId: "",
      });
    }
    // console.log(this.departmentList);
    // this.mouth = this.date.year + "-" + this.date.thisMounth;
    // * 暂时不调用吧
    // this.SalaryservicefindEmployeeSalary(this.batch);
    // this.findEmployeeSalaryStatusV2();
    this.findSalaryComprehensiveV2();
    this.queryMonthSalaryListV2();
    // this.queryBaoshuiamount();
    // this.queryShenbaoStatusV2();
    // * 暂时不调用吧

    // !  默然出现审批人弹框，暂时隐藏不出现 开始
    // let approvalShow = this.$getCookie("approvalShow");
    // if (approvalShow) {
    //   this.approvalShow = true;
    // }
    // !  默然出现审批人弹框，暂时隐藏不出现 结束

    // this.invitationShow1 = true;
  },
  watch: {
    mouth(newValue, oldValue) {
      this.mouthText = "";
      let date = this.mouth.split("-");
      if (date[0] !== this.date.year) {
        this.mouthText += date[0] + "年";
      }
      this.mouthText += date[1] + "月";
    },
  },
  methods: {
    changeFun() {
      this.pages.pageNum = 1;
      this.tableData1 = [];
      // this.findSalaryComprehensiveV2();
      this.findEmployeeSalaryStatusV2();
    },
    huanfaClick() {
      this.delayPopupFlag = true;
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pages.pageSize = val;
      this.findEmployeeSalaryStatusV2();
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.pages.pageNum = val;
      this.findEmployeeSalaryStatusV2();
    },
    pageFun() {
      if (this.totalPage > 1 && this.pages.pageNum < this.totalPage) {
        this.pages.pageNum++;
        this.findEmployeeSalaryStatusV2();
      }
    },
    delayClose() {
      this.delayPopupFlag = false;
      this.tFun();
    },
    showTPage4() {
      this.invitationShow1 = false;
      this.tPage4 = true;
    },
    tFun() {
      this.tPage2 = false;
      this.tPage3 = false;
      this.tPage4 = false;
      this.tPage5 = false;
      this.tableData1 = [];
      this.findSalaryComprehensiveV2();
      this.queryMonthSalaryListV2();
      // this.queryShenbaoStatusV2();
    },
    arrearsQuery() {
      let param = {
        enterpriseId: this.enterpriseId,
        taxMonth: this.mouth,
        userId: this.userId,
      };
      arrearsQuery(param).then((res) => {
        if (res.code === 0) {
          if (res.data.length === 0) {
            setTimeout(() => {
              if (this.shenbaoStatus == 60) {
                if (this.payStatus === 300 || this.payStatus === 301) {
                  if (leaveFlag) this.arrearsQuery();
                }
              }
            }, 5000);
            return;
          }
          if (res.data[0].status === "0" || res.data[0].status === "-1") {
            setTimeout(() => {
              if (leaveFlag) this.arrearsQuery();
            }, 5000);
          } else {
            this.taxTotal = 0;
            res.data.forEach((e1) => {
              e1.salaryTaxArrears.forEach((e2) => {
                e2.detailList.forEach((e3) => {
                  if (e3.ybtse !== "")
                    this.taxTotal += e3.ybtse * 1 + e3.znje * 1;
                });
              });
            });
            this.taxTotal = this.taxTotal.toFixed(2);
            this.incomeTaxFlag = true;
          }
        }
      });
    },
    baoshuiV2Correct() {
      let param = {
        enterpriseId: this.enterpriseId,
        month: this.mouth,
        userId: this.userId,
      };
      baoshuiV2Correct(param).then((res) => {
        // console.log(res);
        if (res.code === 0) {
          this.tPage5 = true;
          return;
          this.$router.push({
            path: "/transitionPage5",
            query: {
              month: this.mouth,
              batch: this.batch,
            },
          });
        }
      });
    },
    shenbaoDataQuery() {
      let param = {
        enterpriseId: this.enterpriseId,
        month: this.mouth,
        userId: this.userId,
        pageNo: 1,
        pageSize: 100,
        reportType: "1",
      };
      shenbaoDataQuery(param).then((res) => {
        console.log(res);
        if (res.code === 0) {
        }
      });
    },
    queryShenbaoStatusV2() {
      let param = {
        enterpriseId: this.enterpriseId,
        month: this.mouth,
        userId: this.userId,
      };
      queryShenbaoStatusV2(param).then((res) => {
        // console.log(res);
        if (res.code === 0) {
        }
      });
    },
    // queryShenbaoStatusV2() {
    //   let param = {
    //     enterpriseId: this.enterpriseId,
    //     month: this.mouth,
    //   };
    //   queryShenbaoStatusV2(param).then((res) => {
    //     // console.log(res);
    //     if (res.code === 0) {
    //       this.srze = 0;
    //       res.data.forEach((e1) => {
    //         e1.statusList.forEach((e3) => {
    //           if (e3.srze !== "") {
    //             this.srze += e3.srze * 1;
    //           }
    //         });
    //       });
    //       this.incomeTaxFlag = true;
    //     }
    //   });
    // },
    queryBaoshuiamount() {
      // 查询报税金额
      let param = {
        enterpriseId: this.enterpriseId,
        month: this.mouth,
        userId: this.userId,
      };
      queryBaoshuiamount(param).then((res) => {
        // this.taxTotal = res.data.taxTotal;
      });
    },
    shenbaoFun() {
      if (this.payStatus === 301) {
        // this.$store.commit("MyMessage", "申报成功 缴款中");
        // return false;
      } else if (this.payStatus === 302 || this.payStatus === 305) {
        // this.$store.commit("MyMessage", "申报成功 缴款成功");
        return false;
      } else if (this.payStatus === 300 || this.payStatus === 0) {
        if (!this.taxTotal) {
          return;
        }
      } else {
        // this.$store.commit("MyMessage", "申报成功 缴款失败");
        // return false;
      }
      console.log(this.taxTotal);
      this.invitationShow1 = true;
    },
    queryMonthSalaryListV2() {
      let param = {
        enterpriseId: this.enterpriseId,
        userId: this.userId,
      };
      queryMonthSalaryListV2(param).then((res) => {
        if (res.code === 0) {
          let date = this.mouth.split("-")[0] + this.mouth.split("-")[1];
          res.data.forEach((e) => {
            if (e.month + "" === date) {
              e.baoshuiList.forEach((e1) => {
                if (e1.month + "" === date) {
                  this.shenpiStatus = e.shenpiStatus;
                  this.shenbaoStatus = e1.status;
                  this.payStatus = e1.payStatus;
                  this.zuofeiStatus = e1.zuofeiStatus;
                  this.correctStatus = e1.correctStatus;
                  if (this.shenpiStatus > 2 && this.shenpiStatus !== 4) {
                    if (
                      this.shenbaoStatus === 50 ||
                      this.shenbaoStatus === 41 ||
                      this.shenbaoStatus === 100
                    ) {
                      this.tPage2 = true;
                      return;
                    }
                    if (this.payStatus === 301) {
                      this.tPage4 = true;
                      return;
                    }
                    if (this.zuofeiStatus === 501) {
                      this.tPage3 = true;
                      return;
                    }
                    if (this.correctStatus === 201) {
                      this.tPage5 = true;
                      return;
                    }
                    if (this.shenbaoStatus == 60) {
                      if (this.payStatus === 300 || this.payStatus === 301) {
                        this.arrearsQuery();
                        // this.queryShenbaoStatusV2();
                        this.shenbaoDataQuery();
                      }
                    }
                  }
                }
              });
            }
          });
        }
      });
    },
    findEmployeeSalaryStatusV2() {
      let param = {
        enterpriseId: this.enterpriseId,
        batch: this.batch,
        month: this.mouth,
        pageNum: this.pages.pageNum,
        pageSize: this.pages.pageSize,
        departmentId: this.departmentId,
        userId: this.userId,
      };
      findEmployeeSalaryStatusV2(param).then((res) => {
        console.log(res);
        if (res.code === 0) {
          if (res.data) {
            if (res.data.salaryEndList) {
              if (res.data.salaryEndList.list.length > 0) {
                this.tableData = res.data.salaryEndList.list;
                this.totalRow = res.data.salaryEndList.list.length;
                this.totalPage = res.data.salaryEndList.totalRow;
                this.pages.total = res.data.salaryEndList.totalRow;
                let oldDate = this.$config.cloneObj(this.tableData);
                this.delayData = this.$config.cloneObj(
                  res.data.salaryEndList.list
                );
                this.salaryNum = 0;
                this.tableData.forEach((e, i) => {
                  if (
                    e.salaryStatus === 2 &&
                    (e.status === 1 || e.status === 4)
                  ) {
                    this.salaryNum++;
                  }
                  const regMobile = /^1(3|4|5|6|7|8|9)\d{9}$/;
                  e.insuranceCompanySum += e.providentCompanyPay;
                  e.insurancePersonSum += e.providentPersonPay;
                  if (regMobile.test(e.nickName)) {
                    e.nickName =
                      "*" + e.nickName.substr(e.nickName.length - 2, 2);
                  }
                  e.laowuIncomeTax = oldDate[i].incomeTax;
                  if (e.contractType === 3) {
                    e.laowuIncomeTax = 0;
                  } else {
                    e.incomeTax = 0;
                  }
                });
                this.tableData1 = [...this.tableData1, ...this.tableData];
                this.$forceUpdate();
              }
            }
          }
        } else {
          this.tableData = [];
        }
      });
    },
    findSalaryComprehensiveV2() {
      let param = {
        enterpriseId: this.enterpriseId,
        batch: this.batch,
        month: this.mouth,
        pageNum: this.pages.pageNum,
        pageSize: this.pages.pageSize,
        departmentId: this.departmentId,
        userId: this.userId,
      };
      findSalaryComprehensiveV2(param).then((res) => {
        console.log("res", res);

        if (res.code === 0) {
          this.comprehensive = res.data.comprehensive;
          this.comprehensive.yyezhzc = this.comprehensive.yyezhzcAmt;

          this.comprehensive.nickName = "合计";
          this.tableData1.push(this.comprehensive);
          this.findEmployeeSalaryStatusV2();
          return;
          if (res.data) {
            if (res.data.salaryEndList) {
              this.tableData = res.data.salaryEndList.list;
              this.totalRow = res.data.salaryEndList.list.length;
              let numList = {
                num1: 0,
                num2: 0,
                num3: 0,
                num4: 0,
                num5: 0,
                num6: 0,
                num7: 0,
                num8: 0,
                num9: 0,
              };
              let num10 = 0,
                num11 = 0;

              let oldDate = this.$config.cloneObj(this.tableData);
              this.delayData = this.$config.cloneObj(
                res.data.salaryEndList.list
              );
              this.tableData.forEach((e, i) => {
                const regMobile = /^1(3|4|5|6|7|8|9)\d{9}$/;
                e.insuranceCompanySum += e.providentCompanyPay;
                e.insurancePersonSum += e.providentPersonPay;
                if (regMobile.test(e.nickName)) {
                  e.nickName =
                    "*" + e.nickName.substr(e.nickName.length - 2, 2);
                }
                e.incomeTax1 = oldDate[i].incomeTax;
                if (e.contractType === 3) {
                  num10++;
                  e.incomeTax1 = 0;
                } else {
                  num11++;
                  e.incomeTax = 0;
                }
                if (e.acheBouns === 0) numList.num1++;
                if (e.subsidy === 0) numList.num2++;
                if (e.bouns === 0 && e.compensation === 0) numList.num3++;
                if (e.kSalary === 0) numList.num4++;
                if (e.fine === 0) numList.num5++;
                if (e.compensationTax === 0) numList.num6++;
                if (e.endBounsTax === 0) numList.num7++;
                if (e.endBonus === 0) numList.num8++;
                if (e.compensation === 0) numList.num9++;
              });
              for (let index = 1; index <= 9; index++) {
                if (numList["num" + index] === this.tableData.length)
                  this.tdShowList["tdShow" + index] = false;
              }
              this.tdShowList.laodong = true;
              if (num11 === this.tableData.length)
                this.tdShowList.laodong = false;
              this.tdShowList.laowu = true;
              if (num10 === this.tableData.length)
                this.tdShowList.laowu = false;
              this.$forceUpdate();
              this.delayPopupFlag = true;
            }
            if (this.tableData.length > 0) {
              // this.$nextTick(() => {
              //   let footer = document.querySelector(
              //     ".el-table__footer-wrapper"
              //   );
              //   let wrapper = document.querySelector(".el-table__body-wrapper");
              //   footer.after(wrapper);
              // });
            }
          }
        } else {
          this.tableData = [];
        }
      });
    },
    baoshuiV2() {
      this.$store.commit("showLoading", "");
      let param = {
        enterpriseId: this.enterpriseId,
        month: this.mouth,
        userId: this.userId,
      };
      baoshuiV2(param).then((res) => {
        console.log(res);
        this.$store.commit("hideLoading", "");

        if (res.code === 0) {
          this.shenbaoStatus = 50;
          this.tPage2 = true;
          // this.$router.push({
          //   path: "/transitionPage2",
          //   query: {
          //     month: this.mouth,
          //     batch: this.batch,
          //   },
          // });
        } else {
          this.$store.commit("MyMessage", res.msg);
        }
      });
    },
    baoshuiV2Zuofei() {
      this.$store.commit("showLoading", "");
      let param = {
        enterpriseId: this.enterpriseId,
        month: this.mouth,
        userId: this.userId,
      };
      baoshuiV2Zuofei(param).then((res) => {
        console.log(res);
        this.$store.commit("hideLoading", "");
        if (res.code === 0) {
          // location.reload();
          // return;
          this.tPage3 = true;
          return;
          this.$router.push({
            path: "/transitionPage3",
            query: {
              month: this.mouth,
              batch: this.batch,
            },
          });
        } else {
          this.$store.commit("MyMessage", res.msg);
        }
      });
    },
    //员工工资-撤销工资v2
    revokeSalaryV2() {
      this.$store.commit("showLoading", "");
      let param = {
        enterpriseId: this.enterpriseId,
        month: this.mouth,
        userId: this.userId,
      };
      revokeSalaryV2(param).then((res) => {
        this.$store.commit("hideLoading", "");
        if (res.code === 0) {
          this.gotoFun("/payrollModule");
        } else {
          if (res.msg.substring(6) === "请先撤销审批") {
            this.$store.commit("MyMessage", "请先到APP端撤销审批");
          } else {
            this.$store.commit("MyMessage", res.msg);
          }
        }
        // this.gotoFun("/payrollModule");
      });
    },
    salaryRecord() {
      let param = {
        enterpriseId: this.enterpriseId,
        pageNum: "1",
        pageSize: "999",
        userId: this.userId,
      };
      salaryRecord(param).then((res) => {
        // console.log(res);
        this.list = res.data;
      });
    },
    //页面跳转
    gotoFun(link) {
      if (link === "/payrollRecord") {
        if (this.tableData.length === 0) {
          // this.$store.commit("MyMessage", "暂无记录");
          this.$router.push("/payrollModule");
          return;
        }
      }
      this.$router.push(link);
    },
    click() {
      if (this.tableData.length === 0) {
        return;
      }
      if (this.shenbaoStatus === 30) {
        // this.$store.commit("MyMessage", "未申报");
        // return false;
      } else if (this.shenbaoStatus === 40) {
        // this.$store.commit("MyMessage", "待报税");
        if (this.shenpiStatus === 1 || this.shenpiStatus === 4) {
          this.$store.commit("MyMessage", "请先将工资表提交审批");
          return false;
        } else if (this.shenpiStatus === 2) {
          this.payrollTips = true;
          // this.$store.commit(
          //   "MyMessage",
          //   "审批同意才能申报个税,请登录APP,进行审批"
          // );
          this.$forceUpdate();
          return false;
        }
      } else if (this.shenbaoStatus === 50) {
        this.$store.commit("MyMessage", "申报表报送中...");
        return false;
      } else if (this.shenbaoStatus === 100) {
        // this.$store.commit("MyMessage", "报税失败");
        this.baoshuiV2();
        return false;
      }
      if (this.shenbaoStatus === 50) {
        this.$store.commit("MyMessage", "申报表报送中...");
        return false;
      } else if (this.shenbaoStatus === 60) {
        this.$store.commit("MyMessage", "申报成功");
        return false;
      } else if (this.shenbaoStatus === 100) {
        this.$store.commit("MyMessage", "报税失败");
        return false;
      }
      // if (this.visit !== "visit") {
      //   if (this.employee + "" !== "1") {
      //     this.$store.commit("MyMessage", "没有权限");
      //     return false;
      //   }
      // }
      this.baoshuiV2();
      return;
      this.declareShow = true;
    },
    approvalClick() {
      if (this.tableData.length === 0) {
        return;
      }
      this.approvalShow = true;
    },
    approvalClose() {
      this.approvalShow = false;
      this.declareShow = false;
      this.invitationShow1 = false;
    },
    SalaryservicefindEmployeeSalary(batch) {
      let param = {
        enterpriseId: this.enterpriseId,
        month: this.mouth,
        batch: batch,
        pageNum: "1",
        pageSize: "999",
        userId: this.userId,
      };
      SalaryservicefindEmployeeSalary(param).then((res) => {
        console.log(res);
        if (res.code === 0) {
          if (res.data) {
            if (res.data.salaryEndList) {
              this.tableData = res.data.salaryEndList.list;
              this.totalRow = res.data.salaryEndList.list.length;
              let numList = {
                num1: 0,
                num2: 0,
                num3: 0,
                num4: 0,
                num5: 0,
                num6: 0,
                num7: 0,
                num8: 0,
                num9: 0,
              };
              let num10 = 0,
                num11 = 0;

              let oldDate = this.$config.cloneObj(this.tableData);
              this.tableData.forEach((e, i) => {
                const regMobile = /^1(3|4|5|6|7|8|9)\d{9}$/;
                e.insuranceCompanySum += e.providentCompanyPay;
                e.insurancePersonSum += e.providentPersonPay;
                if (regMobile.test(e.nickName)) {
                  e.nickName =
                    "*" + e.nickName.substr(e.nickName.length - 2, 2);
                }
                e.incomeTax1 = oldDate[i].incomeTax;
                if (e.contractType === 3) {
                  num10++;
                  e.incomeTax1 = 0;
                } else {
                  num11++;
                  e.incomeTax = 0;
                }
                if (e.acheBouns === 0) numList.num1++;
                if (e.subsidy === 0) numList.num2++;
                if (e.bouns === 0 && e.compensation === 0) numList.num3++;
                if (e.kSalary === 0) numList.num4++;
                if (e.fine === 0) numList.num5++;
                if (e.compensationTax === 0) numList.num6++;
                if (e.endBounsTax === 0) numList.num7++;
                if (e.endBonus === 0) numList.num8++;
                if (e.compensation === 0) numList.num9++;
              });
              for (let index = 1; index <= 9; index++) {
                if (numList["num" + index] === this.tableData.length)
                  this.tdShowList["tdShow" + index] = false;
              }
              this.tdShowList.laodong = true;
              if (num11 === this.tableData.length)
                this.tdShowList.laodong = false;
              this.tdShowList.laowu = true;
              if (num10 === this.tableData.length)
                this.tdShowList.laowu = false;
              this.$forceUpdate();
            }
            if (this.tableData.length > 0) {
              // this.$nextTick(() => {
              //   let footer = document.querySelector(
              //     ".el-table__footer-wrapper"
              //   );
              //   let wrapper = document.querySelector(".el-table__body-wrapper");
              //   footer.after(wrapper);
              // });
            }
          }
        } else {
          this.tableData = [];
        }
      });
    },

    reMakeSalary() {
      if (this.tableData.length === 0) {
        return;
      }
      if (this.status + "" !== "1" && this.status + "" !== "4") {
        this.$store.commit("MyMessage", "请先到APP端进行撤销");
        return false;
      }
      let param = {
        enterpriseId: this.enterpriseId,
        userId: this.userId,
        month: this.mouth,
      };
      reMakeSalary(param).then((res) => {
        // console.log("initialListpc", res);
        if (res.code === 0) {
          this.status = res.data.status;
          location.reload();
        } else {
          this.$store.commit("MyMessage", res.msg);
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.loading-icon {
  width: 16px;
  height: 16px;
  margin-top: 30px;
}
.views-mian-header,
.views-mian-content {
  // width: calc(100% - 60px);
  min-width: 1224px;
}
.views-mian-content {
  min-height: calc(100% - 160px);
}
.views-mian-header-left {
  .btn-link {
    margin-left: 0;
  }
}
.btn-link {
  padding: 0 20px;
  display: inline-block;
  height: 34px;
  line-height: 34px;
  cursor: pointer;
  background: #f4f4f4;
  border: 1px solid #f4f4f4;
  font-size: 14px;
  font-weight: 400;
  color: #262626;
  margin-left: 10px;
  border-radius: 3px;
}

.shenbaoStatus60 {
  opacity: 0.5;
}
.shenbaoStatus80 {
  background: #ea0200 !important;
  color: #fff;
}
.shenbaoStatus100 {
  background: #ff931e !important;
  color: #fff;
  border-color: #ff931e;
}
.overall-btn {
  // width: 102px;
  min-width: 102px;
  padding: 0 15px;
  height: 34px;
  line-height: 34px;
  text-align: center;
  background: #f4f4f4;
  color: #4c8afc;
  border: none;
  border-radius: 3px;
  margin-left: 10px;
  outline: none;
  cursor: pointer;
  float: right;
  font-size: 14px;
  &.btn-color-26 {
    color: #262626;
  }
  &.btn-border {
    border: 1px solid #dbdbdb;
    background: #fff;
    width: 110px;
  }
  &.active {
    background: #4c8afc;
    color: #fff;
    // font-weight: bold;
  }
}
</style>
