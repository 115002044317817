<template>
  <div class="popup-layer">
    <div class="popup-layer-bg" @click.stop="close"></div>
    <div class="images-popup">
      <div class="popup-layer-close" @click.stop="close">
        <svg-icon icon-class="popup-close"></svg-icon>
      </div>
      <div class="images-popup-header">身份证照片</div>
      <div class="images-popup-content">
        <div class="images-popup-box IdcardImg1" :class="{ noBack: img1 }">
          <div class="load-style degreeImg" v-if="imgload1">
            <div class="load-style-div">
              <i class="el-icon-loading"></i>
            </div>
          </div>
          <img
            :src="img1"
            :class="{
              imgStyle1: imgStyle1,
              imgStyle2: !imgStyle1,
              opcity0: imgload1,
            }"
            alt=""
            id="img1"
          />
          <input
            type="file"
            class="avatar"
            name="file"
            id="avatar1"
            @change="imgChange($event, 'avatar1', '1')"
          />
        </div>
        <p>身份证正面照片</p>
        <div class="images-popup-box IdcardImg2" :class="{ noBack: img2 }">
          <div class="load-style degreeImg" v-if="imgload2">
            <div class="load-style-div">
              <i class="el-icon-loading"></i>
            </div>
          </div>
          <img
            :src="img2"
            :class="{
              imgStyle1: imgStyle2,
              imgStyle2: !imgStyle2,
              opcity0: imgload2,
            }"
            alt=""
            id="img2"
          />
          <input
            type="file"
            class="avatar"
            name="file"
            id="avatar2"
            @change="imgChange($event, 'avatar2', '2')"
          />
        </div>
        <p>身份证反面照片</p>
      </div>
      <div class="images-popup-btns">
        <el-button type="primary" @click.stop="close">确定</el-button>
      </div>
    </div>
    <iframe id="myIframe" name="hideIframe" style="display: none"></iframe>
  </div>
</template>

<script>
export default {
  data() {
    return {
      userId: sessionStorage.getItem("userId"),
      enterpriseId: sessionStorage.getItem("enterpriseId"),
      token: sessionStorage.getItem("pc-tkn"),
      imgload1: false,
      imgload2: false,
      imgStyle1: false,
      imgStyle2: false,
      img1: "",
      img2: "",
    };
  },
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
    childData: {
      type: Object,
      default: () => ({}),
    },
  },
  mounted() {
    let that = this;
    // that.img1 = that.item.positive;
    // that.img2 = that.item.back;
    if (that.item.positive) {
      if (that.item.positive.indexOf("http://jz.jzdcs.com") !== -1) {
        that.img1 =
          "https://www.jzcfo.com" +
          that.item.positive.split("http://jz.jzdcs.com")[1];
      }else{
          that.img1 =that.item.positive
      }
    }
    if (that.item.back) {
      if (that.item.back.indexOf("http://jz.jzdcs.com") !== -1) {
        that.img2 =
          "https://www.jzcfo.com" +
          that.item.back.split("http://jz.jzdcs.com")[1];
      }else{
          that.img2 =that.item.back
      }
    }
    let img1 = document.getElementById("img1");
    let img2 = document.getElementById("img2");
    img1.onload = function () {
      if (img1.height > 240) {
        that.imgStyle1 = true;
      } else {
        that.imgStyle1 = false;
      }
    };
    img2.onload = function () {
      if (img2.height > 240) {
        that.imgStyle2 = true;
      } else {
        that.imgStyle2 = false;
      }
    };
  },
  methods: {
    imgChange(e, Id, flag) {
      let that = this;
      const avatar = document.getElementById(Id);
      avatar.onchange = (e) => {
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(file);
      };
      let num = Math.ceil(avatar.files[0].size / 1000000) * 1000;
      if (!avatar.files[0]) {
        alert("选择图片!");
        return false;
      }
      const formData = new FormData();
      formData.append("file", avatar.files[0]);
      formData.append("userId", that.userId);
      formData.append("flag", flag);
      formData.append("enterpriseId", that.enterpriseId);
      const xhr = new XMLHttpRequest();
      xhr.onreadystatechange = (data) => {
        if (xhr.readyState === 4 && xhr.status === 200) {
          let res = JSON.parse(xhr.responseText);
          console.log(res);
          if (res.code === 0) {
            if (flag === "1") {
              that.img1 = res.data.idcardImgFront;
              that.item.positive = res.data.idcardImgFront;
              that.imgload1 = true;
              setTimeout(() => {
                that.imgload1 = false;
              }, num);
            } else {
              that.img2 = res.data.idcardImgFeverse;
              that.item.back = res.data.idcardImgFeverse;
              that.imgload2 = true;
              setTimeout(() => {
                that.imgload2 = false;
              }, num);
            }
            this.$store.commit("MyMessage", "上传成功");
            that.$emit("ImgFrontFun", that.item);
          } else {
            if (flag === "1") {
              that.img1 = "";
            } else {
              that.img2 = "";
            }
            // Toast(res.msg);
          }
          console.log(that.img1);
        }
      };

      xhr.open(
        "POST",
        "https://api.jzcfo.com/usermanager/employee/v2/uploadIDCard"
      );
      xhr.setRequestHeader("token", this.token);
      xhr.send(formData);
    },
    popupClose() {},
    onclick() {},
    close() {
      let param = {
        field: this.item.field,
        type: this.item.type,
        back: this.img1,
        positive: this.img2,
        value: "",
      };
      this.$emit("staffParent", param);
    },
  },
};
</script>
<style lang="less" scoped>
.images-popup {
  height: 700px;
  width: 600px;
  background: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -350px;
  margin-left: -300px;
  cursor: default;
}
.images-popup-header {
  width: 100%;
  min-width: 100%;
  border-top: none;
  border-right: none;
  border-bottom: 1px solid rgb(219, 219, 219);
  border-left: none;
  border-image: initial;
  height: 54px;
  line-height: 54px;
  text-align: center;
}
.images-popup-content {
  text-align: center;
  margin: 0 50px;
  padding-top: 20px;
  p {
    padding: 10px 0 30px;
  }
}
.images-popup-box {
  height: 200px;
  width: 307px;
  margin: 0 auto;
  position: relative;
}
.imgStyle1 {
  height: 100%;
  width: auto;
  text-align: center;
  display: inline-block;
}
.imgStyle2 {
  height: auto;
  width: 100%;
  text-align: center;
  display: inline-block;
}
.noBack {
  background: none !important;
}

.images-popup-btns {
  width: 100%;
  text-align: center;
  .el-button {
    width: 307px;
    margin: 0 auto;
  }
}
</style>



