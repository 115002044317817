<template>
  <div class="popup-layer">
    <div class="popup-layer-bg"></div>
    <div class="elTable" v-if="dataShow">
      <el-table
        :data="tableData"
        :header-cell-style="{
          background: '#fff',
        }"
        :height="tableHeight"
        :row-class-name="tableRowClassName"
        style="width: 100%"
      >
        <ex-table-column type="index" label="序号" align="center">
        </ex-table-column>
        <ex-table-column prop="xm" label="姓名" align="center">
        </ex-table-column>
        <ex-table-column
          prop="zjlx"
          width="100"
          label="证件类型"
          align="center"
        >
        </ex-table-column>
        <ex-table-column
          prop="zjhm"
          width="160"
          label="证件号码"
          align="center"
        >
        </ex-table-column>
        <ex-table-column
          prop="nsrsbh"
          width="160"
          label="纳税人识别号"
          align="center"
        >
        </ex-table-column>
        <ex-table-column
          prop="sffjm"
          width="140"
          label="是否为非居民个人"
          align="center"
        >
        </ex-table-column>
        <ex-table-column
          prop="manpower"
          width="160"
          label="所得项目"
          align="center"
        >
        </ex-table-column>
        <ex-table-column label="本月（次）情况" align="center">
          <ex-table-column label="收入额计算" width="160" align="center">
            <ex-table-column prop="sre" label="收入" align="center">
            </ex-table-column>
            <ex-table-column prop="fy" label="费用" align="center">
            </ex-table-column>
            <ex-table-column prop="mssr" label="免税收入" align="center">
            </ex-table-column>
          </ex-table-column>
          <ex-table-column prop="jcfy" label="减除费用" align="center">
          </ex-table-column>
          <ex-table-column label="专项扣除" align="center">
            <ex-table-column
              prop="jbylaobxf"
              label="基本养老保险费"
              align="center"
              width="160"
            >
            </ex-table-column>
            <ex-table-column
              prop="jbylbxf"
              label="基本医疗保险费"
              align="center"
              width="160"
            >
            </ex-table-column>
            <ex-table-column
              prop="sybxf"
              width="160"
              label="失业保险费"
              align="center"
            >
            </ex-table-column>
            <ex-table-column
              prop="zfgjj"
              width="160"
              label="住房公积金"
              align="center"
            >
            </ex-table-column>
          </ex-table-column>
          <ex-table-column label="收入额计算" align="center">
            <ex-table-column prop="nj" label="年金" align="center">
            </ex-table-column>
            <ex-table-column
              prop="syjkbx"
              label="商业健康保险"
              width="160"
              align="center"
            >
            </ex-table-column>
            <ex-table-column
              prop="syylbx"
              label="税延养老保险"
              width="160"
              align="center"
            >
            </ex-table-column>
            <ex-table-column prop="ccyz" label="财产原值" align="center">
            </ex-table-column>
            <ex-table-column
              prop="yxkcsf"
              label="允许扣除的税费"
              align="center"
              width="160"
            >
            </ex-table-column>
            <ex-table-column prop="qt" label="其他" align="center">
            </ex-table-column>
          </ex-table-column>
        </ex-table-column>
        <ex-table-column label="累计情况" align="center">
          <ex-table-column
            prop="ljsre"
            label="累计收入额"
            width="160"
            align="center"
          >
          </ex-table-column>
          <ex-table-column
            prop="ljjcfy"
            label="累计减除费用"
            width="160"
            align="center"
          >
          </ex-table-column>
          <ex-table-column
            prop="ljzxkc"
            label="累计专项扣除"
            width="160"
            align="center"
          >
          </ex-table-column>
          <ex-table-column label="累计专项附加扣除" width="160" align="center">
            <ex-table-column prop="ljznjy" label="子女教育" align="center">
            </ex-table-column>
            <ex-table-column prop="ljsylr" label="赡养老人" align="center">
            </ex-table-column>
            <ex-table-column
              prop="ljzfdklx"
              label="住房贷款利息"
              align="center"
              width="160"
            >
            </ex-table-column>
            <ex-table-column prop="ljzfzj" label="住房租金" align="center">
            </ex-table-column>
            <ex-table-column prop="ljjxjy" label="继续教育" align="center">
            </ex-table-column>
          </ex-table-column>
        </ex-table-column>
        <ex-table-column
          prop="manpower"
          label="减按计税比例"
          width="160"
          align="center"
        >
        </ex-table-column>
        <ex-table-column
          prop="zykcjze"
          label="准予扣除的捐赠额"
          align="center"
          width="160"
        >
        </ex-table-column>
        <ex-table-column label="税款计算" align="center">
          <ex-table-column
            prop="ynssde"
            label="应纳税所得额"
            width="110"
            align="center"
          >
          </ex-table-column>
          <ex-table-column
            prop="sl"
            label="税率/预扣率"
            width="100"
            align="center"
          >
          </ex-table-column>
          <ex-table-column
            prop="sskcs"
            label="速算扣除数"
            width="100"
            align="center"
          >
          </ex-table-column>
          <ex-table-column
            prop="ynse"
            label="应纳税额"
            width="100"
            align="center"
          >
          </ex-table-column>
          <ex-table-column
            prop="jmse"
            label="减免税额"
            width="100"
            align="center"
          >
          </ex-table-column>
          <ex-table-column
            prop="ykjse"
            label="已缴税额"
            width="100"
            align="center"
          >
          </ex-table-column>
          <ex-table-column
            prop="ybtse"
            label="应补/退税额"
            width="100"
            align="center"
          >
          </ex-table-column>
        </ex-table-column>
        <ex-table-column prop="bz" label="备注" align="center">
        </ex-table-column>
      </el-table>
      <div></div>
    </div>
  </div>
</template>
<script>
import { shenbaoDataQuery } from "@/http/api";
export default {
  data() {
    return {
      userId: sessionStorage.getItem("userId"),
      enterpriseId: sessionStorage.getItem("enterpriseId"),
      showList: {
        showTable1: false,
        showTable2: false,
        showTable3: false,
        showTable4: false,
        showTable5: false,
      },
      tableHeight: null,
      dataShow: false,
      tableData: [],
      month: "",
    };
  },
  mounted() {
    this.month = this.$route.query.month;
    this.tableHeight = document.body.clientHeight - 270;
    setTimeout(() => {
      this.shenbaoDataQuery();
    }, 100);
  },
  methods: {
    shenbaoDataQuery() {
      let param = {
        enterpriseId: this.enterpriseId,
        month: this.month,
        userId: this.userId,
        pageNo: 1,
        pageSize: 100,
        reportType: "1",
      };
      shenbaoDataQuery(param).then((res) => {
        console.log(res);
        if (res.code === 0) {
          res.data.sfhj[0].xm = "合计";
          res.data.sfhj[0].sre = res.data.sfhj[0].srehj;
          res.data.sfhj[0].mssr = res.data.sfhj[0].mssrhj;
          res.data.sfhj[0].ccyz = res.data.sfhj[0].ccyzhj;
          res.data.sfhj[0].tzdk = res.data.sfhj[0].tzdkhj;
          res.data.sfhj[0].yxkcsf = res.data.sfhj[0].yxkcsfhj;
          res.data.sfhj[0].jbylaobxf = res.data.sfhj[0].jbylaobxfhj;
          res.data.sfhj[0].jbylbxf = res.data.sfhj[0].jbylbxfhj;
          res.data.sfhj[0].sybxf = res.data.sfhj[0].sybxfhj;
          res.data.sfhj[0].zfgjj = res.data.sfhj[0].zfgjjhj;
          res.data.sfhj[0].nj = res.data.sfhj[0].njhj;
          res.data.sfhj[0].syjkbx = res.data.sfhj[0].syjkbxhj;
          res.data.sfhj[0].syylbx = res.data.sfhj[0].syylbxhj;
          res.data.sfhj[0].qt = res.data.sfhj[0].qthj;
          res.data.sfhj[0].zykcjze = res.data.sfhj[0].zykcjzehj;
          res.data.sfhj[0].ljsre = res.data.sfhj[0].ljsrehj;
          res.data.sfhj[0].ljzxkc = res.data.sfhj[0].ljzxkchj;
          res.data.sfhj[0].ljznjy = res.data.sfhj[0].ljznjyhj;
          res.data.sfhj[0].ljsylr = res.data.sfhj[0].ljsylrhj;
          res.data.sfhj[0].ljzfdklx = res.data.sfhj[0].ljzfdklxhj;
          res.data.sfhj[0].ljzfzj = res.data.sfhj[0].ljzfzjhj;
          res.data.sfhj[0].ljjxjy = res.data.sfhj[0].ljjxjyhj;
          res.data.sfhj[0].ljqtkc = res.data.sfhj[0].ljqtkchj;
          res.data.sfhj[0].ljzykcjze = res.data.sfhj[0].ljzykcjzehj;
          res.data.sfhj[0].ynssde = res.data.sfhj[0].ynssdehj;
          res.data.sfhj[0].ynse = res.data.sfhj[0].ynsehj;
          res.data.sfhj[0].jmse = res.data.sfhj[0].jmsehj;
          res.data.sfhj[0].ykjse = res.data.sfhj[0].ykjsehj;
          res.data.sfhj[0].ybtse = res.data.sfhj[0].ybtsehj;
          res.data.sfhj[0].jcfy = 0;
          res.data.sfhj[0].ljjcfy = 0;
          res.data.sfmx.forEach((e) => {
            if (e.jcfy) {
              res.data.sfhj[0].jcfy += e.jcfy * 1;
              res.data.sfhj[0].ljjcfy += e.ljjcfy * 1;
            }
          });
          res.data.sfhj[0].jcfy = res.data.sfhj[0].jcfy.toFixed(2);
          res.data.sfhj[0].ljjcfy = res.data.sfhj[0].ljjcfy.toFixed(2);
          this.tableData = [...res.data.sfhj, ...res.data.sfmx];
          console.log(this.tableData);
          this.dataShow = true;
        }
      });
    },
    tableRowClassName({ row, rowIndex }) {
      if (row.salaryStatus === 2 && row.status !== 6) {
        return "salaryStatus";
      }
      return "";
    },
  },
};
</script>
<style lang="less" scoped>
.elTable {
  width: calc(90% - 30px);
  height: calc(80% - 30px);
  left: 5%;
  position: absolute;
  top: 10%;
  background: #fff;
  padding: 15px;
}
</style>
<style lang="less">
.el-table .cell {
  white-space: nowrap;
  width: fit-content;
}
.salaryStatus {
  .cell {
    font-size: 14px;
    color: #f58e21 !important;
    span {
      color: #f58e21 !important;
    }
  }
}
.elTable {
  .el-table__fixed-body-wrapper,
  .el-table__body-wrapper {
    tbody {
      tr {
        &:first-child {
          td {
            background: #fafafa;
          }
        }
      }
    }
  }
}
.views-mian-content {
  .el-table td,
  .el-table th {
    padding: 5px 0;
  }
  // .el-table__body tr.hover-row.current-row > td,
  // .el-table__body tr.hover-row.el-table__row--striped.current-row > td,
  // .el-table__body tr.hover-row.el-table__row--striped > td,
  // .el-table__body tr.hover-row > td {
  //   background-color: #bbb;
  // }
  .el-table__body-wrapper {
    // padding-bottom: 40px;

    td {
      padding: 5px 0;
    }
    &::-webkit-scrollbar {
      width: 20px;
      height: 20px;
    }

    &::-webkit-scrollbar-track,
    &::-webkit-scrollbar-thumb {
      border-radius: 0;
      border: 5px solid transparent;
    }
    &::-webkit-scrollbar-track {
      box-shadow: 3px 1px 13px rgba(219, 219, 219, 0.6) inset;
    }
    &::-webkit-scrollbar-thumb {
      min-height: 20px;
      background-clip: content-box;
      box-shadow: 0 0 0 0px rgba(153, 153, 153, 0.5);
    }
    &::-webkit-scrollbar-corner {
      background: transparent;
    }
  }
  .el-table {
    .el-table__header {
      tr {
        th {
          padding: 8px 0;
          background: #fff;
          .cell {
            color: #262626 !important;

            font-weight: 400;
            font-size: 14px;
          }
        }
        &:last-child {
          th {
            font-weight: 400;
          }
        }
      }
    }
  }
  .el-table__body-wrapper {
    // max-height: 530px !important;
  }
}
.el-table--scrollable-x .el-table__body-wrapper::-webkit-scrollbar-track {
  &:hover {
    .el-table--scrollable-x .el-table__body-wrapper::-webkit-scrollbar-thumb {
      box-shadow: 0 0 0 8px rgba(153, 153, 153, 0.5) inset;
    }
  }
}
.el-table--scrollable-x .el-table__body-wrapper::-webkit-scrollbar-thumb {
  box-shadow: 0 0 0 8px rgba(219, 219, 219, 0.3) inset;
  &:hover {
    box-shadow: 0 0 0 8px rgba(219, 219, 219, 0.6) inset;
  }
}
.salaryStatus {
  font-size: 12px;
  height: 12px;
  line-height: 12px;
}
.el-table .cell {
  white-space: nowrap;
  width: fit-content;
  margin: 0 auto;
  span {
    display: inline-block;
  }
}
</style>