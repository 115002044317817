<template>
  <div class="views-width">
    <div class="views-main">
      <div class="views-mian-header">
        <div class="views-mian-header-left">
          <!-- 日期
          <el-date-picker
            v-model="mouth"
            :editable="false"
            value-format="yyyy-MM"
            type="month"
            placeholder="选择月"
            @change="dateChange"
            :picker-options="pickerOptions"
          >
          </el-date-picker> -->
          <el-button
            type="primary"
            @click="gotoFun('/payrollRecord')"
            class="btn-primary btn-link"
            >工资记录</el-button
          >
          <span> 总计 {{ tableData.length }} 人</span>

          <!-- <span>个税申报密码：</span> -->
        </div>
        <div class="views-mian-header-title">{{ mouthText }}工资表</div>
        <div class="views-mian-header-right">
          <dowmPopup
            :status="status"
            :mouth="mouth"
            :tableData="tableData"
          ></dowmPopup>
          <div
            class="btn-link"
            v-if="
              shenbaoStatus !== 60 &&
              shenbaoStatus !== 80 &&
              tableData.length > 0
            "
            @click="revokeSalaryV2"
          >
            重新制作
          </div>
          <el-button
            type="primary"
            class="btn-primary"
            @click="approvalClick"
            v-if="status === '1' && tableData.length > 0"
            >提交审批</el-button
          >
          <el-button
            type="primary"
            class="btn-primary"
            :class="{
              shenbaoStatus60: shenbaoStatus === 60 || tableData.length === 0,
              shenbaoStatus80: shenbaoStatus === 80,
            }"
            @click="click"
          >
            <slot v-if="shenbaoStatus === 50">报税中</slot>
            <slot v-else-if="shenbaoStatus === 60">队列处理中</slot>
            <slot v-else-if="shenbaoStatus === 80">申报完成 已缴款</slot>
            <slot v-else>申报个税</slot>
          </el-button>
          <button
            class="overall-btn active"
            @click="invitationShow1 = true"
            style="background: #ea0200; float: right"
            :class="{
              shenbaoStatus60: tableData.length === 0,
            }"
            v-if="shenbaoStatus === 60 && visit === 'visit'"
          >
            申报完成
          </button>
        </div>
      </div>
      <div class="views-mian-scroll">
        <div class="views-mian-content">
          <generateTable
            v-if="tableData.length > 0"
            :tableData="tableData"
            :tdShowList="tdShowList"
          ></generateTable>
          <NoRecord v-else></NoRecord>
        </div>
        <TheFooter></TheFooter>
      </div>
    </div>
    <invitationPopup
      :mouth="mouth"
      v-if="approvalShow"
      :batch="batch"
      @approvalClose="approvalClose"
    ></invitationPopup>
    <declarePopup
      :mouth="mouth"
      v-if="declareShow"
      @approvalClose="approvalClose"
    ></declarePopup>
    <declareVisitPopup
      :mouth="mouth"
      v-if="invitationShow1"
      :tableData="tableData"
      @approvalClose="approvalClose"
    ></declareVisitPopup>
  </div>
</template>

<script>
import {
  findEmployeeSalaryNosubmmit,
  SalaryservicefindEmployeeSalary,
  queryBaoshuiamount,
  reMakeSalary,
  salaryRecord,
  revokeSalaryV2,
} from "@/http/api";
import invitationPopup from "./components/invitationPopup";
import declarePopup from "./components/declarePopup";
import declareVisitPopup from "./components/declareVisitPopup";
import generateTable from "./components/generateTable";
import dowmPopup from "./components/dowmPopup";
import NoRecord from "@/components/NoRecord.vue";
import TheFooter from "@/components/TheFooter.vue";
export default {
  data() {
    return {
      userId: sessionStorage.getItem("userId"),
      enterpriseId: sessionStorage.getItem("enterpriseId"),
      mouth: "",
      date: this.$config.getDate(),
      pickerOptions: {
        disabledDate(time) {
          let _now = Date.now(),
            seven = 7 * 24 * 60 * 60 * 1000,
            sevenDays = _now - seven;
          return time.getTime() > _now; //大于当前的禁止，小于7天前的禁止
        },
      },
      tableData: [],
      totalRow: null,
      taxTotal: null,
      visit: sessionStorage.getItem("visit"),
      batch: "",
      status: "",
      payStatus: "",
      shenbaoStatus: "",
      tdShowList: {
        tdShow1: true,
        tdShow2: true,
        tdShow3: true,
        tdShow4: true,
        tdShow5: true,
        tdShow6: true,
        tdShow7: true,
        tdShow8: true,
        tdShow9: true,
        laowu: false,
        laodong: false,
      },
      approvalShow: false,
      invitationShow1: false,
      declareShow: false,
      mouthText: "",
      list: [],
    };
  },
  components: {
    invitationPopup,
    declarePopup,
    declareVisitPopup,
    generateTable,
    dowmPopup,
    NoRecord,
    TheFooter,
  },
  mounted() {
    this.mouth = this.$route.query.mouth;
    if (this.$route.query.batch) {
      this.SalaryservicefindEmployeeSalary(this.$route.query.batch);
    }
    this.findEmployeeSalaryNosubmmit();
    this.mouthText = "";
    let date1 = this.mouth.split("-");
    if (date1[0] !== this.date.year) {
      this.mouthText += date1[0] + "年";
    }
    this.mouthText += date1[1] + "月";
    // this.salaryRecord();
    // let approvalShow = this.$getCookie("approvalShow");
    // if (approvalShow) {
    //   this.approvalShow = true;
    // }
    // this.invitationShow1 = true;
  },
  watch: {
    mouth(newValue, oldValue) {
      this.mouthText = "";
      let date = this.mouth.split("-");
      if (date[0] !== this.date.year) {
        this.mouthText += date[0] + "年";
      }
      this.mouthText += date[1] + "月";
    },
  },
  methods: {
    //员工工资-撤销工资v2
    revokeSalaryV2() {
      let param = {
        enterpriseId: this.enterpriseId,
        month: this.mouth,
        userId: this.userId,
      };
      revokeSalaryV2(param).then((res) => {
        // console.log(res);
        // this.list = res.data;
        if (res.code === 0) {
          this.gotoFun("/payrollModule");
        }
      });
    },
    salaryRecord() {
      let param = {
        enterpriseId: this.enterpriseId,
        pageNum: "1",
        pageSize: "999",
        userId: this.userId,
      };
      salaryRecord(param).then((res) => {
        // console.log(res);
        this.list = res.data;
      });
    },
    //页面跳转
    gotoFun(link) {
      this.$router.go(-1); //返回上一层
      // if (link === "/payrollRecord") {
      //   if (this.list.length === 0) {
      //     this.$store.commit("MyMessage", "暂无记录");

      //     return;
      //   }
      // }
      // this.$router.push(link);
    },
    click() {
      if (this.tableData.length === 0) {
        return;
      }
      if (this.status) {
        if (this.status + "" === "1") {
          this.$store.commit("MyMessage", "请先将工资表提交审批");
          return false;
        } else if (this.status + "" === "2") {
          this.$store.commit(
            "MyMessage",
            "审批同意才能申报个税,请登录APP,进行审批"
          );
          return false;
        } else if (this.status + "" === "4") {
          this.$store.commit(
            "MyMessage",
            "审批同意才能申报个税,请登录APP,进行审批"
          );
          return false;
        }
      }
      if (this.shenbaoStatus === 50) {
        this.$store.commit("MyMessage", "报税中");
        return false;
      } else if (this.shenbaoStatus === 60) {
        // this.$store.commit("MyMessage", "队列处理中");
        return false;
      } else if (this.shenbaoStatus === 80) {
        this.$store.commit("MyMessage", "申报完成 已缴款");
      }
      if (this.visit !== "visit") {
        if (this.employee + "" !== "1") {
          this.$store.commit("MyMessage", "没有权限");
          return false;
        }
      }
      this.declareShow = true;
    },
    approvalClick() {
      if (this.tableData.length === 0) {
        return;
      }
      this.approvalShow = true;
    },
    approvalClose() {
      this.approvalShow = false;
      this.declareShow = false;
      this.invitationShow1 = false;
    },
    findEmployeeSalaryNosubmmit() {
      let param = {
        enterpriseId: this.enterpriseId,
        month: this.mouth,
        batch: "",
        pageNum: "1",
        pageSize: "20",
        userId: this.userId,
      };
      findEmployeeSalaryNosubmmit(param).then((res) => {
        if (res.code === 0) {
          if (JSON.stringify(res.data) !== "{}") {
            // if (!res.data.comprehensive) {
            // let year =
            //   this.date.lastMounth === "12"
            //     ? this.date.year - 1
            //     : this.date.year;
            //   this.mouth = year + "-" + this.date.lastMounth;
            //   this.findEmployeeSalaryNosubmmit();
            //   return;
            // }
            // if (res.data.batch) {
            //   this.mouth = res.data.month;
            //   this.batch = res.data.batch;

            //   this.SalaryservicefindEmployeeSalary(res.data.batch);
            // }
            // if (!this.$route.query.batch) {
            //   // if (res.data.salaryEndList.list.length === 0) {
            //   //   this.$router.push("/payrollModule");
            //   // }
            // }
            this.status = res.data.comprehensive.status + "";
            this.payStatus = res.data.comprehensive.payStatus + "";
            this.shenbaoStatus = res.data.comprehensive.shenbaoStatus;
          } else {
            // this.$router.push("/payroll");
            // this.$router.push("/payrollModule");
          }
        } else {
          this.tableData = [];
        }
      });
    },
    findEmployeeSalaryNosubmmit1(data) {
      let param = {
        enterpriseId: this.enterpriseId,
        month: this.mouth,
        batch: "",
        pageNum: "1",
        pageSize: "20",
        userId: this.userId,
      };
      findEmployeeSalaryNosubmmit(param).then((res) => {
        // console.log("findEmployeeSalaryNosubmmit", res);
        if (res.code === 0) {
          if (JSON.stringify(res.data) !== "{}") {
            if (res.data.batch) {
              this.SalaryservicefindEmployeeSalary(res.data.batch);
            }
            if (res.data.salaryEndList.list.length > 0) {
              this.SalaryservicefindEmployeeSalary(res.data.batch);
            }
          } else {
            this.$router.push("/payrollModule");
          }
        } else {
          this.tableData = [];
        }
      });
    },
    SalaryservicefindEmployeeSalary(batch) {
      let param = {
        enterpriseId: this.enterpriseId,
        month: this.mouth,
        batch: batch,
        pageNum: "1",
        pageSize: "999",
        userId: this.userId,
      };
      SalaryservicefindEmployeeSalary(param).then((res) => {
        console.log(res);
        if (res.code === 0) {
          if (res.data) {
            if (res.data.salaryEndList) {
              this.tableData = res.data.salaryEndList.list;
              this.totalRow = res.data.salaryEndList.list.length;
              let numList = {
                num1: 0,
                num2: 0,
                num3: 0,
                num4: 0,
                num5: 0,
                num6: 0,
                num7: 0,
                num8: 0,
                num9: 0,
              };
              let num10 = 0,
                num11 = 0;

              let oldDate = this.$config.cloneObj(this.tableData);
              this.tableData.forEach((e, i) => {
                const regMobile = /^1(3|4|5|6|7|8|9)\d{9}$/;
                e.insuranceCompanySum += e.providentCompanyPay;
                e.insurancePersonSum += e.providentPersonPay;
                if (regMobile.test(e.nickName)) {
                  e.nickName =
                    "*" + e.nickName.substr(e.nickName.length - 2, 2);
                }
                e.incomeTax1 = oldDate[i].incomeTax;
                if (e.contractType === 3) {
                  num10++;
                  e.incomeTax1 = 0;
                } else {
                  num11++;
                  e.incomeTax = 0;
                }
                if (e.acheBouns === 0) numList.num1++;
                if (e.subsidy === 0) numList.num2++;
                if (e.bouns === 0 && e.compensation === 0) numList.num3++;
                if (e.kSalary === 0) numList.num4++;
                if (e.fine === 0) numList.num5++;
                if (e.compensationTax === 0) numList.num6++;
                if (e.endBounsTax === 0) numList.num7++;
                if (e.endBonus === 0) numList.num8++;
                if (e.compensation === 0) numList.num9++;
              });
              for (let index = 1; index <= 9; index++) {
                if (numList["num" + index] === this.tableData.length)
                  this.tdShowList["tdShow" + index] = false;
              }
              this.tdShowList.laodong = true;
              if (num11 === this.tableData.length)
                this.tdShowList.laodong = false;
              this.tdShowList.laowu = true;
              if (num10 === this.tableData.length)
                this.tdShowList.laowu = false;
              this.$forceUpdate();
            }
            if (this.tableData.length > 0) {
              // this.$nextTick(() => {
              //   let footer = document.querySelector(
              //     ".el-table__footer-wrapper"
              //   );
              //   let wrapper = document.querySelector(".el-table__body-wrapper");
              //   footer.after(wrapper);
              // });
            }
          }
        } else {
          this.tableData = [];
        }
      });
    },

    dateChange() {
      this.tdShowList = {
        tdShow1: true,
        tdShow2: true,
        tdShow3: true,
        tdShow4: true,
        tdShow5: true,
        tdShow6: true,
        tdShow7: true,
        tdShow8: true,
        tdShow9: true,
        laowu: false,
        laodong: false,
      };

      this.findEmployeeSalaryNosubmmit1();
    },
    reMakeSalary() {
      if (this.tableData.length === 0) {
        return;
      }
      if (this.status + "" !== "1" && this.status + "" !== "4") {
        this.$store.commit("MyMessage", "请先到APP端进行撤销");
        return false;
      }
      let param = {
        enterpriseId: this.enterpriseId,
        userId: this.userId,
        month: this.mouth,
      };
      reMakeSalary(param).then((res) => {
        // console.log("initialListpc", res);
        if (res.code === 0) {
          this.status = res.data.status;
          location.reload();
        } else {
          this.$store.commit("MyMessage", res.msg);
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.views-mian-header,
.views-mian-content {
  // width: calc(100% - 60px);
  min-width: 1224px;
}
.btn-link {
  padding: 0 20px;
  display: inline-block;
  height: 34px;
  line-height: 34px;
  cursor: pointer;
  background: #f4f4f4;
  border: 1px solid #f4f4f4;
  font-size: 16px;
  font-weight: 400;
  color: #262626;
  margin-right: 10px;
  border-radius: 3px;
}

.shenbaoStatus60 {
  opacity: 0.5;
}
.shenbaoStatus80 {
  background: #dbdbdb;
  border: 1px solid #dbdbdb;
  color: #ffffff;
}
.overall-btn {
  width: 102px;
  height: 34px;
  line-height: 34px;
  text-align: center;
  background: #f4f4f4;
  color: #4c8afc;
  border: none;
  border-radius: 3px;
  margin-left: 10px;
  outline: none;
  cursor: pointer;
  font-size: 14px;
  &.btn-color-26 {
    color: #262626;
  }
  &.btn-border {
    border: 1px solid #dbdbdb;
    background: #fff;
    width: 110px;
  }
  &.active {
    background: #4c8afc;
    color: #fff;
    // font-weight: bold;
  }
}
</style>