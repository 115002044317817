<template>
  <div class="addStaff-box-right" @click="dateClick">
    <div
      class="addStaff-tips"
      :class="{ noIcon: !showIcon }"
      v-if="!child.value"
    >
      {{ item.tips }}
    </div>
    <div class="addStaff-text" v-else>{{ child.value }}</div>
    <date-picker
      v-model="child.value"
      value-type="format"
      format="YYYY-MM-DD"
      @change="dateChange"
      class="data-picker-style"
      :placeholder="item.tips"
      :open.sync="openDates"
      :editable="false"
      :clearable="false"
    ></date-picker>
    <svg-icon icon-class="xiala-icon" class="xiala" v-if="showIcon"></svg-icon>
  </div>
</template>

<script>
import bus from "@/util/bus";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/zh-cn";
export default {
  data() {
    return {
      child: {},
      showIcon: true,
      openDates: false,
      date: this.$config.getDate(),
      visit: sessionStorage.getItem("visit"),
    };
  },
  inject: ["ajaxData"],
  components: {
    DatePicker,
  },
  mounted() {
    this.child = this.item;
    if (
      (this.staffType === "details" || this.staffType === "quitStaff") &&
      this.child.field === "jobTime"
    ) {
      if (this.child.value) {
        this.child.value = this.child.value.split(" ")[0];
      }
      // console.log(this.visit);
      if (this.visit !== "visit") {
        // this.showIcon = false;
      }
    }
  },
  methods: {
    dateClick() {
      bus.$emit("hidePopup");

      this.openDates = true;
    },
    dateChange() {
      let param = {
        field: this.child.field,
        type: this.child.type,
        value: this.child.value,
      };
      if (this.child.field === "jobTime") {
        bus.$emit("dateChange", this.child.value);
      }
      this.$emit("staffParent", param);
    },
    blur() {
      console.log("child", this.child);
      // this.$emit("")
    },
  },
  props: {
    item: {
      type: Object,
      default() {
        return null;
      },
    },
    staffType: {
      type: String,
      default() {
        return null;
      },
    },
  },
};
</script>
<style lang="less">
.addStaff-box-right {
  .mx-datepicker {
    width: 100%;
    .mx-input {
      width: 100%;
      margin: 0;
      padding: 0;
      height: 22px;
      border: none;
      box-shadow: none;
      padding-right: 36px;
      font: 400 16px Techna;
      cursor: pointer;
    }
  }
  .mx-icon-calendar {
    display: none;
  }
}
</style>