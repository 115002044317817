  <template>
  <div class="views-width">
    <div class="views-main">
      <div class="views-mian-header">
        <div class="views-mian-header-left">
          日期
          <el-date-picker
            v-model="mouth"
            :editable="false"
            value-format="yyyy-MM"
            type="month"
            placeholder="选择月"
            @change="dateChange"
            :picker-options="pickerOptions"
          >
          </el-date-picker>
          <span>总计 {{ rosterList.length }} 人</span>
        </div>
        <div class="views-mian-header-title" v-if="jiluFlag">花名册-记录</div>
        <div class="views-mian-header-right">
          <el-button
            type="primary"
            @click="exportExcel"
            class="btn-primary btn-info"
            >花名册导出</el-button
          >
          <el-button
            type="primary"
            @click="gotoFun('/department')"
            class="btn-primary btn-info"
            >部门设置</el-button
          >
          <el-button
            type="primary"
            @click="qrcodeFun"
            class="btn-primary btn-link"
            >邀请员工</el-button
          >
          <el-button
            type="primary"
            class="btn-primary"
            @click="gotoFun('/addStaff')"
            >添加员工</el-button
          >
        </div>
      </div>
      <div
        class="views-mian-scroll"
        id="views-mian-scroll"
        :class="{ overflowStyle: overflowStyle }"
      >
        <div class="views-mian-content">
          <slot v-if="rosterList.length > 0">
            <table class="accountTable" v-if="rosterList.length > 0">
              <thead>
                <tr>
                  <th><div class="table-width1">头像</div></th>
                  <th><div class="table-width2">姓名</div></th>
                  <th><div class="table-width11">性别</div></th>
                  <th><div class="table-width3">手机号</div></th>
                  <!-- <th><div class="table-width10">入职日期</div></th> -->
                  <th><div class="table-width4">员工身份</div></th>
                  <th><div class="table-width5">合同类型</div></th>
                  <th><div class="table-width6">所属部门</div></th>
                  <th><div class="table-width7">岗位名称</div></th>
                  <!-- <th><div class="table-width4">岗位工资</div></th> -->
                  <th><div class="table-width9">员工状态</div></th>
                  <th v-if="jurisdiction === '1'">
                    <div class="table-width8">权限</div>
                  </th>
                </tr>
              </thead>
              <tbody v-if="routeFlag">
                <tr
                  v-for="(item, index) in rosterList"
                  :key="index"
                  :class="{
                    status10: item.status === 10 || item.jobTimeShow,
                    status2: item.status === 2,
                  }"
                >
                  <td @click="showDetails(item)">
                    <div class="table-width1">
                      <div class="userHead" v-if="item.userHead">
                        <img
                          class="userHead-logo"
                          :class="{ gray: item.status === 2 }"
                          :src="item.userHead"
                          alt=""
                        />
                      </div>
                      <div class="userHead" v-else-if="item.name">
                        <div class="userHead-logo" v-if="item.name.length > 2">
                          <slot v-if="item.name.substr(0, 1) === '*'">*</slot
                          >{{ item.name.substr(item.name.length - 2, 2) }}
                        </div>
                        <div class="userHead-logo" v-else>
                          {{ item.name }}
                        </div>
                      </div>
                    </div>
                  </td>
                  <td @click="showDetails(item)">
                    <div class="table-width2">
                      {{ item.name }}
                    </div>
                  </td>
                  <td @click="showDetails(item)">
                    <div class="table-width11">
                      {{ item.genderText }}
                    </div>
                  </td>
                  <td @click="showDetails(item)">
                    <div class="table-width3">{{ item.mobile }}</div>
                  </td>
                  <!-- <td @click="showDetails(item)">
                    <div class="table-width10">
                      <slot v-if="item.jobTime">
                        {{ item.jobTime.split(" ")[0] }}
                      </slot>
                    </div>
                  </td> -->
                  <td @click="showDetails(item)">
                    <div class="table-width4">
                      <slot v-if="item.identity === 3">
                        <p>应届实习生<br />(全日制学历教育)</p>
                      </slot>
                      <slot v-else> {{ item.identityText }} </slot>
                    </div>
                  </td>
                  <td @click="showDetails(item)">
                    <div class="table-width5">
                      {{ item.contractTypeText }}
                    </div>
                  </td>
                  <td @click="showDetails(item)">
                    <div class="table-width6">{{ item.departmentName }}</div>
                  </td>
                  <td @click="showDetails(item)">
                    <div class="table-width7">{{ item.postName }}</div>
                  </td>
                  <!-- <td @click="showDetails(item)">
                    <div class="table-width4">{{ item.postSalary }}</div>
                  </td> -->
                  <td @click="showDetails(item)">
                    <div
                      class="table-width9"
                      :style="{ color: item.status === 2 ? '#999' : '' }"
                    >
                      <slot v-if="item.status === 1">正常</slot>
                      <slot v-if="item.status === 2">离职</slot>
                      <slot v-if="item.status === 10">待入职</slot>
                      <slot v-if="item.status === 99">审核不通过</slot>
                    </div>
                  </td>
                  <td v-if="jurisdiction === '1'">
                    <div class="table-width8">
                      <!-- 操作员工权限及更换管理员操作 -->
                      <statusLayer
                        @child-event="parentEvent"
                        :item="item"
                        :list="rosterList"
                        :isAdmin="isAdmin"
                        @statusLayer="statusLayer"
                      ></statusLayer>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <el-pagination
              v-if="totalPage > 1"
              style="margin-top: 20px; text-align: right"
              background
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="pages.pageNum"
              :page-sizes="[30, 50, 100]"
              :page-size="pages.pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="pages.total"
              >>
            </el-pagination>
          </slot>
          <NoRecord v-else></NoRecord>
        </div>
        <TheFooter></TheFooter>
      </div>
    </div>
    <div class="popup-layer" v-show="qrcodeShow">
      <div class="popup-layer-bg" @click="qrcodeShow = false"></div>
      <div class="qrcode-main">
        <div class="popup-layer-close">
          <svg-icon
            icon-class="popup-close"
            @click="qrcodeShow = false"
          ></svg-icon>
        </div>
        <div class="qrcode-main-header">邀请员工</div>
        <div class="qrcode-box">
          <div id="qrcode"></div>
        </div>
        <div class="qrcode-button">扫描二维码邀请员工</div>
      </div>
    </div>
    <perfectPopup
      :wanshanShow1.sync="wanshanShow1"
      :userItem="userItem"
      v-if="wanshanShow1"
    ></perfectPopup>
    <div class="popup-layer" v-if="messageShow">
      <div class="popup-layer-bg" @click="hideLogin"></div>
      <div class="roster-message">
        <div class="popup-layer-close" @click="hideLogin">
          <svg-icon icon-class="popup-close"></svg-icon>
        </div>
        <div class="roster-message-title">提示</div>
        <div class="roster-message-text">
          <p v-html="text1"></p>
          <p v-html="text2"></p>
        </div>
        <div class="roster-message-footer">
          <el-button type="primary" class="btn-primary" @click="hideLogin">
            我知道了
          </el-button>
        </div>
      </div>
    </div>
    <addStaffPopup
      v-if="addStaffShow"
      @hidePopup="hideLogin"
      @savePopup="savePopup"
    ></addStaffPopup>
    <detailsStaffPopup
      v-if="detailsStaffShow"
      :details="details"
      @hidePopup="hideLogin"
      @savePopup="savePopup"
    ></detailsStaffPopup>
    <quitStaffPopup
      v-if="quitStaffShow"
      :details="details"
      @hidePopup="hideLogin"
    ></quitStaffPopup>
  </div>
</template>

  <script>
import {
  getEnterpriseEmployee,
  flashTokenPc,
  flashTokenPcPlatform,
  getEmployeeInfoUserId,
  getEnterpriseEmployeeHistory,
} from "@/http/api";
import TheFooter from "@/components/TheFooter.vue";
import statusLayer from "./components/statusLayer";
import perfectPopup from "@/components/perfectPopup.vue";
import addStaffPopup from "./components/addStaffPopup.vue";
import detailsStaffPopup from "./components/detailsStaffPopup.vue";
import quitStaffPopup from "./components/quitStaffPopup.vue";
import NoRecord from "@/components/NoRecord.vue";
import QRCode from "qrcodejs2"; // 引入qrcode
export default {
  data() {
    return {
      details: {},
      addStaffShow: false,
      routeFlag: false,
      detailsStaffShow: false,
      quitStaffShow: false,
      qrcodeShow: false,
      wanshanShow1: false,
      noPerfect: false,
      totalPage: 0,
      pages: {
        pageNum: 1,
        pageSize: 50,
        total: 0,
      },
      userId: sessionStorage.getItem("userId"),
      enterpriseId: sessionStorage.getItem("enterpriseId"),
      visit: sessionStorage.getItem("visit"),
      jurisdiction: sessionStorage.getItem("jurisdiction"),
      mouth: "",
      date: this.$config.getDate(),
      pickerOptions: {
        disabledDate(time) {
          let _now = Date.now(),
            seven = 7 * 24 * 60 * 60 * 1000,
            sevenDays = _now - seven;
          return time.getTime() > _now; //大于当前的禁止，小于7天前的禁止
        },
      },
      oldMouth: "",
      rosterList: [],
      userItem: {
        employeeId: "",
        userId: "",
        isAdmin: "",
      },
      isAdmin: null,
      text1: "xxx员工未到入职日期",
      text2: "请在 正式入职日期 进行编辑",
      messageShow: false,
      overflowStyle: false,
      ExcelData: [],
      jiluFlag: false,
    };
  },
  components: {
    statusLayer,
    perfectPopup,
    TheFooter,
    addStaffPopup,
    detailsStaffPopup,
    quitStaffPopup,
    NoRecord,
  },
  mounted() {
    let that = this;
    // that.flashTokenPc();
    that.mouth = that.date.year + "-" + that.date.thisMounth;
    // let _route = that.$route;
    // if (_route.query.mouth) {
    //   that.mouth = _route.query.mouth;
    //   that.oldMouth = _route.query.oldMouth;
    // } else {
    //   that.mouth = that.date.year + "-" + that.date.thisMounth;
    //   that.oldMouth = that.mouth;
    // }
    that.init();
    that.$nextTick(() => {
      let qrcode = new QRCode("qrcode", {
        width: 132,
        height: 132,
        correctLevel: 3,
        text:
          "https://share.jzcfo.com/share?userId=" +
          that.userId +
          "&enterpriseId=" +
          that.enterpriseId +
          "&token=" +
          sessionStorage.getItem("pc-tkn") +
          "&company=" +
          sessionStorage.getItem("company"), // 二维码地址
      });
    });
  },
  methods: {
    sText(value, list, type) {
      let text = "";
      list.forEach((e1) => {
        if (e1.id + "" === value + "") {
          text = e1.text;
        }
      });
      return text;
    },
    exportExcel() {
      require.ensure([], () => {
        const { export_json_to_excel } = require("@/util/Export2Excel.js");
        let eData = this.$dictionaries.employeeModel;
        this.ExcelData.forEach((e, i) => {
          e.educationText = this.sText(e.education, eData.educationData.list);
          e.statusText = this.sText(e.status, eData.statusList2.list);
          e.nText = this.sText(e.nationality, this.$dictionaries.countryData);
          e.identityText = this.sText(e.identity, eData.identityData.list);
          e.TermText = this.sText(e.contractTerm, eData.contractTermData.list);
          e.cTypeText = this.sText(e.contractType, eData.contractTypeData.list);
          if (e.gender && e.gender + "" !== "0") {
            e.genderText = this.sText(e.gender, eData.sex.list);
          } else {
            if (e.idType + "" === "0" || e.idType + "" === "1") {
              if (e.idNumber) {
                e.genderText = this.discriCard(e.idNumber);
              }
            }
          }

          if (e.jobTime) {
            e.jobTime = e.jobTime.split(" ")[0];
          }
          if (e.province) {
            e.pText = e.province + "-" + e.city + "-" + e.area;
          }
          if (e.contactProvince) {
            e.oText =
              e.contactProvince + "-" + e.contactCity + "-" + e.contactArea;
          }
        });
        const tHeader = [
          "姓名",
          "手机号",
          "证件号码",
          "岗位名称",
          "岗位工资",
          "试用期工资",
          "试用期开始日期",
          "试用期结束时期",
          "合同期限",
          "所属部门",
          "员工身份",
          "合同类型",
          "入职日期",
          "国籍(地区)",
          "性别",
          "户籍所在地",
          "现居住地",
          // "现居住地",
          // "详细地址",
          "学校",
          "学历",
          "员工状态",
          "银行卡号",
          "所属银行",
          "开户行所在地",
        ]; // 上面设置Excel的表格第一行的标题
        const filterVal = [
          "name",
          "mobile",
          "idNumber",
          "postName",
          "postSalary",
          "onTrialTermSalaryScale",
          "onTrialTermBegin",
          "onTrialTermEnd",
          "TermText",
          "departmentName",
          "identityText",
          "cTypeText",
          "jobTime",
          "nText",
          "genderText",
          "pText",
          "oText",
          "school",
          "educationText",
          "statusText",
          "bankCardNum",
          "bankName",
          "bankProvince",
        ]; // 上面的index、nickName、name是tableData里对象的属性
        const list = this.tableData; //把data里的tableData存到list
        const data = this.formatJson(filterVal, this.ExcelData);
        export_json_to_excel(tHeader, data, this.mouth + " 花名册", true);
        // export_json_to_excel({
        //   tHeader,
        //   data,
        //   fileName: this.mouth + " 花名册",
        //   autoWidth: true,
        // });
      });
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map((v) => filterVal.map((j) => v[j]));
    },
    hideLogin() {
      this.messageShow = false;
      this.addStaffShow = false;
      this.detailsStaffShow = false;
      this.quitStaffShow = false;
    },
    savePopup() {
      this.hideLogin();
      this.init();
    },
    init() {
      this.getEnterpriseEmployee();
      this.getEmployeeInfoUserId();
    },
    statusLayer() {
      this.routeFlag = false;
      this.getEnterpriseEmployee();
    },
    getEmployeeInfoUserId(item) {
      let res = JSON.parse(sessionStorage.getItem("infoUser"));
      // console.log(res);
      if (res.code === 0) {
        this.userItem.employeeId = res.data.employeeId;
        this.userItem.userId = res.data.userId;
        this.userItem.isAdmin = res.data.isAdmin;
        this.isAdmin = res.data.isAdmin;
        if (!res.data.contactDetail) {
          this.noPerfect = true;
          return;
        } else {
          this.noPerfect = false;
        }
        // console.log(this.noPerfect);
      }
    },
    qrcodeFun() {
      if (this.noPerfect) {
        this.wanshanShow1 = true;
        return;
      }
      this.qrcodeShow = true;
    },
    parentEvent() {
      this.init();
    },
    dateChange() {
      // this.$router.push({
      //   path: "/roster",
      //   query: {
      //     mouth: this.mouth,
      //     oldMouth: this.oldMouth,
      //   },
      // });
      this.routeFlag = false;
      this.pages = {
        pageNum: 1,
        pageSize: 50,
        total: 0,
      };
      this.getEnterpriseEmployeeHistory();
      // this.getEnterpriseEmployee();
      this.getEmployeeInfoUserId();
      this.jiluFlag = true;
    },
    discriCard(UUserCard) {
      let text = "";
      UUserCard.substring(6, 10) +
        "-" +
        UUserCard.substring(10, 12) +
        "-" +
        UUserCard.substring(12, 14); //获取性别
      if (parseInt(UUserCard.substr(16, 1)) % 2 == 1) {
        text = "男";
      } else {
        text = "女";
      }
      return text;
    },
    handleSizeChange(val) {
      this.pages.pageSize = val;
      if (this.jiluFlag) {
        this.getEnterpriseEmployeeHistory();
        return false;
      }
      this.getEnterpriseEmployee();
    },
    handleCurrentChange(val) {
      this.pages.pageNum = val;
      if (this.jiluFlag) {
        this.getEnterpriseEmployeeHistory();
        return false;
      }
      this.getEnterpriseEmployee();
    },
    getEnterpriseEmployee() {
      let that = this;
      let param = {
        pageNum: this.pages.pageNum,
        pageSize: this.pages.pageSize,
        month: this.mouth,
        enterpriseId: that.enterpriseId,
      };
      // console.log(this.mouth, this.oldMouth);
      getEnterpriseEmployee(param).then((res) => {
        // console.log(res);
        if (res.code === 0) {
          if (res.data.list.length === 0) {
            // if (this.oldMouth !== this.mouth) {
            //   this.mouth = this.oldMouth;
            //   this.$router.push({
            //     path: "/roster",
            //     query: {
            //       mouth: that.oldMouth,
            //       oldMouth: this.oldMouth,
            //     },
            //   });
            // }
            this.$store.commit("MyMessage", "暂无记录");
            // this.$forceUpdate();
            // return;
          }
          this.ExcelData = this.$config.cloneObj(res.data.list);
          this.oldMouth = this.mouth;
          that.rosterList = res.data.list;

          this.totalPage = res.data.totalPage;
          this.pages.total = res.data.totalRow;
          let jurisdiction = this.$dictionaries.employeeModel.jurisdiction.list;
          let identityData = this.$dictionaries.employeeModel.identityData.list;
          let contractType =
            this.$dictionaries.employeeModel.contractTypeData.list;
          let fristList = null;
          let fristIndex = null;
          // genderText
          let eData = this.$dictionaries.employeeModel;
          that.rosterList.forEach((element, index) => {
            if (element.userHead.indexOf("http://jz.jzdcs.com") !== -1) {
              element.userHead =
                "https://www.jzcfo.com" +
                element.userHead.split("http://jz.jzdcs.com")[1];
            }
            if (element.gender && element.gender + "" !== "0") {
              element.genderText = this.sText(element.gender, eData.sex.list);
            } else {
              if (element.idType + "" === "0" || element.idType + "" === "1") {
                if (element.idNumber) {
                  element.genderText = this.discriCard(element.idNumber);
                }
              }
            }
            const regMobile = /^1(3|4|5|6|7|8|9)\d{9}$/;
            if (regMobile.test(element.name)) {
              element.name =
                "*" + element.name.substr(element.name.length - 2, 2);
            }
            let newDate =
              this.date.year + "-" + this.date.thisMounth + "-" + this.date.day;
            if (element.jobTime) {
              if (element.jobTime.split(" ")[0] > newDate) {
                // 确认关闭
                element.jobTimeShow = true;
              }
            }
            // element.nationality = "36";
            if (element.isAdmin === 1) {
              fristList = element;
              fristIndex = index;
            }
            contractType.forEach((e1) => {
              if (e1.id === element.contractType + "") {
                element.contractTypeText = e1.text;
              }
            });
            identityData.forEach((e1) => {
              if (e1.id === element.identity + "") {
                element.identityText = e1.text;
              }
            });
            if (element.userAuthorizationList.length > 0) {
              let listData = [];
              let user = -1;
              let delIndex = -1;
              element.userAuthorizationList.forEach((e1, i) => {
                jurisdiction.forEach((e2) => {
                  if (e1.userAuthority === e2.key) {
                    e1.textName = e2.value;
                  }
                  if (e1.userAuthority === "100101") {
                    element.userAuthorityType = true;
                  }
                  if (e1.userAuthority === "100107") {
                    user = i;
                  }
                  if (e1.userAuthority === "1001") {
                    delIndex = i;
                  }
                });
                this.dataFunCopy(listData, e1);
              });
              element.userAuthorizationList = listData;
              if (user !== -1) {
                element.userAuthorizationList.splice(user, 1);
              }
              if (delIndex !== -1) {
                element.userAuthorizationList.splice(delIndex, 1);
              }
            }
            element.idType = element.idType ? element.idType : "1";
          });
          if (that.rosterList.length > 1) {
            if (fristIndex) {
              that.rosterList.splice(fristIndex, 1);
              that.rosterList.unshift(fristList);
            }
            let array = this.$config.cloneObj(that.rosterList);
            let rosterData1 = [];
            let rosterData2 = [];
            let rosterData3 = [];
            let rosterData4 = [];
            let rosterData5 = [];
            that.rosterList.forEach((element, i) => {
              if (element.isAdmin === 1) {
                rosterData1.push(element);
              } else if (element.status === 1) {
                rosterData2.push(element);
              } else if (element.status === 2) {
                rosterData3.push(element);
              } else if (element.status === 10) {
                rosterData4.push(element);
              }
            });
            rosterData2.sort(function (a, b) {
              return (
                b.userAuthorizationList.length - a.userAuthorizationList.length
              );
            });
            rosterData5.push(
              ...rosterData1,
              ...rosterData4,
              ...rosterData2,
              ...rosterData3
            );
            this.rosterList = rosterData5;
          }
          this.$nextTick(() => {
            this.overflowStyle = this.$config.hasScrolled(
              "views-mian-scroll",
              "horizontal"
            );
          });
        } else {
          this.$store.commit("MyMessage", res.msg);
        }
        this.routeFlag = true;
      });
    },
    getEnterpriseEmployeeHistory() {
      let that = this;
      let param = {
        pageNum: this.pages.pageNum,
        pageSize: this.pages.pageSize,
        month: this.mouth,
        enterpriseId: that.enterpriseId,
        userId: this.userId,
      };
      // console.log(this.mouth, this.oldMouth);
      getEnterpriseEmployeeHistory(param).then((res) => {
        // console.log(res);
        if (res.code === 0) {
          if (res.data.list.length === 0) {
            // if (this.oldMouth !== this.mouth) {
            //   this.mouth = this.oldMouth;
            //   this.$router.push({
            //     path: "/roster",
            //     query: {
            //       mouth: that.oldMouth,
            //       oldMouth: this.oldMouth,
            //     },
            //   });
            // }
            this.$store.commit("MyMessage", "暂无记录");
            // this.$forceUpdate();
            // return;
          }
          this.ExcelData = this.$config.cloneObj(res.data.list);
          this.oldMouth = this.mouth;
          that.rosterList = res.data.list;

          this.totalPage = res.data.totalPage;
          this.pages.total = res.data.totalRow;
          let jurisdiction = this.$dictionaries.employeeModel.jurisdiction.list;
          let identityData = this.$dictionaries.employeeModel.identityData.list;
          let contractType =
            this.$dictionaries.employeeModel.contractTypeData.list;
          let fristList = null;
          let fristIndex = null;

          let eData = this.$dictionaries.employeeModel;
          that.rosterList.forEach((element, index) => {
            if (element.userHead.indexOf("http://jz.jzdcs.com") !== -1) {
              element.userHead =
                "https://www.jzcfo.com" +
                element.userHead.split("http://jz.jzdcs.com")[1];
            }
            if (element.gender && element.gender + "" !== "0") {
              element.genderText = this.sText(element.gender, eData.sex.list);
            } else {
              if (element.idType + "" === "0" || element.idType + "" === "1") {
                if (element.idNumber) {
                  element.genderText = this.discriCard(element.idNumber);
                }
              }
            }
            const regMobile = /^1(3|4|5|6|7|8|9)\d{9}$/;
            if (regMobile.test(element.name)) {
              element.name =
                "*" + element.name.substr(element.name.length - 2, 2);
            }
            let newDate =
              this.date.year + "-" + this.date.thisMounth + "-" + this.date.day;
            if (element.jobTime) {
              if (element.jobTime.split(" ")[0] > newDate) {
                // 确认关闭
                element.jobTimeShow = true;
              }
            }
            // element.nationality = "36";
            if (element.isAdmin === 1) {
              fristList = element;
              fristIndex = index;
            }
            contractType.forEach((e1) => {
              if (e1.id === element.contractType + "") {
                element.contractTypeText = e1.text;
              }
            });
            identityData.forEach((e1) => {
              if (e1.id === element.identity + "") {
                element.identityText = e1.text;
              }
            });
            if (element.userAuthorizationList.length > 0) {
              let listData = [];
              let user = -1;
              let delIndex = -1;
              element.userAuthorizationList.forEach((e1, i) => {
                jurisdiction.forEach((e2) => {
                  if (e1.userAuthority === e2.key) {
                    e1.textName = e2.value;
                  }
                  if (e1.userAuthority === "100101") {
                    element.userAuthorityType = true;
                  }
                  if (e1.userAuthority === "100107") {
                    user = i;
                  }
                  if (e1.userAuthority === "1001") {
                    delIndex = i;
                  }
                });
                this.dataFunCopy(listData, e1);
              });
              element.userAuthorizationList = listData;
              if (user !== -1) {
                element.userAuthorizationList.splice(user, 1);
              }
              if (delIndex !== -1) {
                element.userAuthorizationList.splice(delIndex, 1);
              }
            }
            element.idType = element.idType ? element.idType : "1";
          });
          if (that.rosterList.length > 1) {
            if (fristIndex) {
              that.rosterList.splice(fristIndex, 1);
              that.rosterList.unshift(fristList);
            }
            let array = this.$config.cloneObj(that.rosterList);
            let rosterData1 = [];
            let rosterData2 = [];
            let rosterData3 = [];
            let rosterData4 = [];
            let rosterData5 = [];
            that.rosterList.forEach((element, i) => {
              if (element.isAdmin === 1) {
                rosterData1.push(element);
              } else if (element.status === 1) {
                rosterData2.push(element);
              } else if (element.status === 2) {
                rosterData3.push(element);
              } else if (element.status === 10) {
                rosterData4.push(element);
              }
            });
            rosterData2.sort(function (a, b) {
              return (
                b.userAuthorizationList.length - a.userAuthorizationList.length
              );
            });
            rosterData5.push(
              ...rosterData1,
              ...rosterData4,
              ...rosterData2,
              ...rosterData3
            );
            this.rosterList = rosterData5;
          }
          this.$nextTick(() => {
            this.overflowStyle = this.$config.hasScrolled(
              "views-mian-scroll",
              "horizontal"
            );
          });
        } else {
          this.$store.commit("MyMessage", res.msg);
        }
        this.routeFlag = true;
      });
    },
    dataFunCopy(data, item) {
      let a = false;
      data.forEach((element) => {
        if (element.userAuthority === item.userAuthority) {
          a = true;
        }
      });
      if (!a) {
        data.push(item);
      }
    },
    flashTokenPc() {
      if (this.visit === "visit") {
        this.flashTokenPcPlatform();
        return false;
      }
      let that = this;
      let param = {
        userId: this.userId,
        enterpriseId: this.enterpriseId,
      };
      flashTokenPc(param).then((res) => {
        if (res.code == 0) {
          sessionStorage.setItem("pc-tkn", res.data.token);
          this.$forceUpdate();
        }
      });
    },
    flashTokenPcPlatform() {
      // if (this.visit === "visit") return;
      let that = this;
      let param = {
        userId: this.userId,
        enterpriseId: this.enterpriseId,
      };
      flashTokenPcPlatform(param).then((res) => {
        if (res.code == 0) {
          sessionStorage.setItem("pc-tkn", res.data.token);
          this.$forceUpdate();
        }
      });
    },
    gotoFun(path) {
      if (this.noPerfect) {
        this.wanshanShow1 = true;
        return;
      }
      // if (path === "/addStaff") {
      //   this.addStaffShow = true;
      //   return;
      // }
      this.$router.push(path);
    },
    showDetails(item) {
      let newDate =
        this.date.year + "-" + this.date.thisMounth + "-" + this.date.day;
      if (item.jobTime && item.isAdmin !== 1) {
        if (item.jobTime.split(" ")[0] > newDate) {
          // 确认关闭
          this.text1 = `<span style="font-weight: bold;">${item.name}</span> 员工未到入职日期`;
          this.text2 = `请到<span style="font-weight: bold;"> ${
            item.jobTime.split(" ")[0]
          }</span> 再进行编辑`;
          this.messageShow = true;
          return;
        }
      }
      this.details = item;

      if (item.status === 2) {
        // this.quitStaffShow = true;
        // return;
        this.$router.push({
          path: "/quitStaff",
          query: {
            uid: item.userId,
            eid: item.employeeId,
          },
        });
        return;
      }
      // this.detailsStaffShow = true;
      // return;

      window.open(
        // "http://localhost:8185/navigation?pcTkn=" +
        window.location.origin +
          "/detailsStaff?uid=" +
          item.userId +
          "&eid=" +
          item.employeeId,
        "_blank"
      );
      // this.$router.push({
      //   path: "/detailsStaff",
      //   query: {
      //     uid: item.userId,
      //     eid: item.employeeId,
      //   },
      // });
    },
  },
};
</script>

<style lang="less" scoped>
.gray {
  -webkit-filter: grayscale(85%);
  -moz-filter: grayscale(85%);
  -ms-filter: grayscale(85%);
  -o-filter: grayscale(85%);
  filter: grayscale(85%);
  filter: rgb(128, 128, 128);
  opacity: 0.6;
}
.views-mian-content {
  min-height: calc(100% - 114px);
}
.accountTable tbody td {
  height: 70px;
}
.accountTable tbody tr {
  cursor: pointer;
}
.views-width {
  width: 100%;
  margin: 0 auto;
  height: 100%;
}

.table-width1 {
  width: 74px;
}
.table-width11 {
  width: 50px;
}
.table-width2 {
  width: 156px;
}
.table-width10 {
  width: 100px;
}
.table-width3 {
  width: 158px;
}
.table-width4 {
  width: 176px;
}
.table-width5 {
  // width: 130px;
  width: 105px;
}
.table-width6 {
  width: 156px;
}
.table-width7 {
  // width: 170px;
  width: 178px;
}
.table-width8 {
  width: 175px;
}
.table-width9 {
  width: 90px;
}
.qrcode-main {
  position: fixed;
  left: 50%;
  top: 50%;
  width: 400px;
  height: 400px;
  margin-left: -200px;
  margin-top: -200px;
  background: #fff;
  border-radius: 5px;
  .qrcode-main-header {
    height: 50px;
    line-height: 50px;
    text-align: center;
    font-size: 18px;
    font-weight: 400;
    color: #262626;
    position: relative;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
  .qrcode-box {
    width: 200px;
    height: 200px;
    margin: 40px auto 0;
    #qrcode {
      width: 200px;
      height: 200px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .qrcode-button {
    margin-top: 40px;
    font-size: 18px;
    color: #262626;
    text-align: center;
  }
}

.status2 {
  td {
    div {
      color: #bbb !important;
    }
    .userHead-logo {
      color: #fff !important;
      background: #999;
    }
  }
}
.status10 {
  td {
    div {
      color: #bbb !important;
    }
    .userHead-logo {
      color: #fff !important;
    }
  }
  .table-width2 {
    color: #f58e21 !important;
  }
}
.roster-message {
  width: 320px;
  height: 250px;
  border-radius: 5px;
  background: #fff;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -160px;
  margin-top: -125px;
  .roster-message-title {
    width: 100%;
    text-align: center;
    height: 30px;
    line-height: 30px;
    font-size: 14px;
    padding-top: 15px;
    color: #999;
  }
  .roster-message-text {
    height: 100px;
    width: 100%;
    text-align: center;
    padding-top: 30px;

    p {
      font-size: 16px;
      padding-top: 10px;
      color: #262626;
    }
  }
  .roster-message-footer {
    width: 100%;
    text-align: center;
  }
}
</style>
