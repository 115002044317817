<template>
  <div class="staffbox-div sHide">
    <div class="staffbox-tab" @click="dateClick">
      <div class="main-div-c">
        <div class="addStaff-text" v-if="!showText">无</div>
        <div class="addStaff-text" v-else>{{ showText }}</div>
        <date-picker
          v-model="value1"
          type="date"
          range
          format="YYYY-MM-DD"
          value-type="YYYY-MM-DD"
          @change="dateChange"
          placeholder=""
          class="data-picker-style"
          ref="onTrialTermBegin"
          :open.sync="openDates"
          @click.stop="dataClick"
        ></date-picker>
      </div>
      <!-- <div class="main-div-r" v-if="showIcon && child.value"> -->
      <div class="main-div-r">
        <svg-icon icon-class="jiantou_shen" class="jiantou-icon"></svg-icon>
      </div>
    </div>
  </div>
</template>

<script>
import bus from "@/util/bus";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/zh-cn";
export default {
  data() {
    return {
      child: {},
      showText: "",
      openDates: false,
      value1: [],
    };
  },
  inject: ["ajaxData"],
  watch: {
    ajaxData: {
      handler(newValue, oldValue) {
        // console.log(this.item);
      },
      deep: true,
    },
    item: {
      deep: true,
      handler(newValue, oldValue) {
        // console.log(newValue);
        this.value1 = [newValue.onTrialTermBegin, newValue.onTrialTermEnd];
        if (newValue.onTrialTermBegin === newValue.onTrialTermEnd) {
          this.showText = "无";
        } else {
          this.showText = "起" + this.value1[0] + " 至 " + this.value1[1];
        }
        this.$forceUpdate();
      },
    },
  },
  mounted() {
    let that = this;
    this.child = this.item;
    this.value1 = [this.child.onTrialTermBegin, this.child.onTrialTermEnd];
    if (this.child.onTrialTermBegin === this.child.onTrialTermEnd) {
      this.showText = "无";
    } else if (this.child.onTrialTermBegin) {
      this.showText = "起 " + this.value1[0] + " 至 " + this.value1[1];
    } else {
      this.showText = "无";
    }
    bus.$on("dateChange", function (value) {
      that.child.onTrialTermBegin = value;
      that.child.onTrialTermEnd = value;
      that.showText = "无";
    });
  },
  components: {
    DatePicker,
  },
  methods: {
    dateClick() {
      bus.$emit("hidePopup");
      if (!this.ajaxData.jobTime) {
        this.$store.commit("MyMessage", "请选择入职日期");
        return;
      }
      this.openDates = true;
    },
    dateChange() {
      if (this.value1[0] === this.value1[1]) {
        this.showText = "无";
      } else {
        this.showText = "起 " + this.value1[0] + " 至 " + this.value1[1];
      }
      let param = {
        field: this.child.field,
        type: this.child.type,
        value: this.child.value,
        onTrialTermBegin: this.value1[0],
        onTrialTermEnd: this.value1[1],
      };

      this.$emit("staffParent", param);
    },
  },
  props: {
    item: {
      type: Object,
      default() {
        return null;
      },
    },
  },
};
</script>
<style lang="less">
.addStaff-box-right {
  .data-picker-style {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    z-index: -1;
  }
}
</style>
<style lang="less" scoped>
@import "../payroll.less";
@import "../../../../assets/less/popup.less";

.main-div-c {
  position: relative;
}
.noIcon {
  width: calc(100%) !important;
  padding-right: 0 !important;
}
.addStaff-box-right {
  width: calc(100% - 70px);
}
</style>