<template>
  <div class="declare-popup">
    <div class="declare-main">
      <div class="department-header department-width">
        <div class="department-link" @click="childClose">
          <i class="el-icon-arrow-left"></i>返回
        </div>
        住房贷款利息
        <div class="department-btn">
          <!-- <div class="addStaff-btn" @click="submitFun">保 存</div> -->
        </div>
      </div>
      <div class="declare-main-content">
        <div class="declare-list">
          <div class="declare-box">
            <div class="declare-box-l">手机号码</div>
            <div class="declare-box-r">
              <input type="text" v-model="item.mobile" placeholder="请输入" />
            </div>
          </div>
          <div class="declare-box">
            <div class="declare-box-l">电子邮箱</div>
            <div class="declare-box-r">
              <input type="text" v-model="item.email" placeholder="请输入" />
            </div>
          </div>
          <div class="declare-box">
            <div class="declare-box-l">联系地址</div>
            <div class="declare-box-r">
              <el-input
                type="textarea"
                :autosize="{ minRows: 1, maxRows: 5 }"
                v-model="item.address"
                class="textStyle"
                placeholder="请输入"
              >
              </el-input>
            </div>
          </div>
        </div>
        <div class="declare-list">
          <div class="declare-box">
            <div class="declare-box-l">选择扣除年度</div>
            <div class="declare-box-r">
              <el-select v-model="item.deductYear" placeholder="请选择">
                <el-option
                  v-for="item in options1"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="declare-box">
            <div class="declare-box-l">产权证明</div>
            <div class="declare-box-r">
              <el-select v-model="item.jixu" placeholder="请选择">
                <el-option
                  v-for="item in options2"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="declare-box">
            <div class="declare-box-l">证书号码</div>
            <div class="declare-box-r">
              <input type="text" v-model="item.mobile" placeholder="请输入" />
            </div>
          </div>
          <div class="declare-box">
            <div class="declare-box-l">房屋坐落地址</div>
            <div class="declare-box-r" @click="popupShowClick($event)">
              <div
                class="declare-text"
                @click="showPopup1 = true"
                v-if="cascadeText"
              >
                {{ cascadeText }}<i class="el-icon-arrow-down"></i>
              </div>
              <div class="declare-desc" @click="showPopup1 = true" v-else>
                请选择<i class="el-icon-arrow-down"></i>
              </div>
            </div>
          </div>
          <div class="declare-box">
            <div class="declare-box-l">详细地址</div>
            <div class="declare-box-r">
              <el-input
                type="textarea"
                :autosize="{ minRows: 1, maxRows: 5 }"
                v-model="item.address"
                class="textStyle"
                placeholder="请输入"
              >
              </el-input>
            </div>
          </div>
        </div>
        <div class="declare-list">
          <div class="declare-box">
            <div class="declare-box-l">贷款方式</div>
            <div class="declare-box-r">
              <el-select v-model="item.daikuan" placeholder="请选择">
                <el-option
                  v-for="item in options3"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
          </div>
        </div>
        <p
          class="declare-p"
          v-if="item.daikuan === '1' || item.daikuan === '2'"
        >
          公积金贷款
        </p>
        <div
          class="declare-list"
          v-if="item.daikuan === '1' || item.daikuan === '2'"
        >
          <div class="declare-box">
            <div class="declare-box-l">贷款银行</div>
            <div class="declare-box-r">
              <input
                type="text"
                v-model="item.mobile"
                placeholder="请填写贷款银行全称"
              />
            </div>
          </div>
          <div class="declare-box">
            <div class="declare-box-l">贷款合同编号</div>
            <div class="declare-box-r">
              <input
                type="text"
                v-model="item.mobile"
                placeholder="请按照贷款合同内容据实填写"
              />
            </div>
          </div>
          <div class="declare-box">
            <div class="declare-box-l">首次还款日期</div>
            <div class="declare-box-r">
              <date-picker
                v-model="item.birth"
                value-type="format"
                format="YYYY-MM-DD"
                class="data-picker-style"
                :clearable="false"
                :editable="false"
                placeholder="首次还款日期"
              ></date-picker>
              <i class="el-icon-arrow-down"></i>
            </div>
          </div>
          <div class="declare-box">
            <div class="declare-box-l">贷款期限</div>
            <div class="declare-box-r">
              <input
                type="text"
                v-model="item.mobile"
                placeholder="输入贷款月数"
              />
            </div>
          </div>
        </div>
        <p
          class="declare-p"
          v-if="item.daikuan === '1' || item.daikuan === '3'"
        >
          商业贷款
        </p>
        <div
          class="declare-list"
          v-if="item.daikuan === '1' || item.daikuan === '3'"
        >
          <div class="declare-box">
            <div class="declare-box-l">贷款银行</div>
            <div class="declare-box-r">
              <input
                type="text"
                v-model="item.mobile"
                placeholder="请填写贷款银行全称"
              />
            </div>
          </div>
          <div class="declare-box">
            <div class="declare-box-l">贷款合同编号</div>
            <div class="declare-box-r">
              <input
                type="text"
                v-model="item.mobile"
                placeholder="请按照贷款合同内容据实填写"
              />
            </div>
          </div>
          <div class="declare-box">
            <div class="declare-box-l">首次还款日期</div>
            <div class="declare-box-r">
              <date-picker
                v-model="item.birth"
                value-type="format"
                format="YYYY-MM-DD"
                class="data-picker-style"
                :clearable="false"
                :editable="false"
                placeholder="首次还款日期"
              ></date-picker>
              <i class="el-icon-arrow-down"></i>
            </div>
          </div>
          <div class="declare-box">
            <div class="declare-box-l">贷款期限</div>
            <div class="declare-box-r">
              <input
                type="text"
                v-model="item.mobile"
                placeholder="输入贷款月数"
              />
            </div>
          </div>
        </div>
        <div class="declare-list dabing">
          <div class="declare-box">
            <div class="declare-box-l">本人是否借款人</div>
            <div class="declare-box-r">
              <el-select
                v-model="item.value1"
                placeholder="请选择"
                @change="change1()"
              >
                <el-option
                  v-for="item in boolData"
                  :key="item.id"
                  :label="item.text"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </div>
          </div>
          <div
            class="declare-box"
            style="padding-bottom: 30px"
            v-if="item.value1 === '1'"
          >
            <div class="declare-box-l">
              是否婚前各自首套贷款，且婚后分别扣除50%
              <i class="el-icon-info" @click="showPopup3 = true"></i>
            </div>
            <div class="declare-box-r">
              <el-select
                v-model="item.value2"
                @change="change2()"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in boolData"
                  :key="item.id"
                  :label="item.text"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </div>
          </div>
        </div>
        <div class="declare-list check-list">
          <div class="declare-title">您的扣缴义务人</div>
          <div class="declare-box" @click="check = 1">
            <p class="p1">唐山市航达商贸有限公司1</p>
            <p class="p2">主管税务机关：国家税务总局唐山市路北区税务局</p>
            <p class="p2">地址：唐山丰润区曹雪芹西大街（三丰小区）102</p>
            <i class="el-icon-check" v-if="check === 1"></i>
          </div>
          <div class="declare-box" @click="check = 2">
            <p class="p1">唐山市航达商贸有限公司2</p>
            <p class="p2">主管税务机关：国家税务总局唐山市路北区税务局</p>
            <p class="p2">地址：唐山丰润区曹雪芹西大街（三丰小区）102</p>
            <i class="el-icon-check" v-if="check === 2"></i>
          </div>
        </div>
        <div class="declare-btns">
          <el-button type="primary" @click="saveFun">保 存</el-button>
        </div>
      </div>
    </div>
    <div class="popup-layer" v-if="showPopup3">
      <div class="popup-layer-bg" @click="showPopup3 = false"></div>
      <div class="popup-zinv tishi-popup">
        <div class="popup-layer-close" @click="showPopup3 = false">
          <svg-icon icon-class="popup-close"></svg-icon>
        </div>
        <div class="popup-zinv-tishi">
          <div class="tishi-title">说明</div>
          <div class="tishi-p1">
            是否婚前各自首套贷款且婚后分别扣除：夫妻双方婚前分别购买住房发生的首套住房贷款，其贷款利息支出，婚后可以选择其中一套购买的住房，由购买方按扣除标准的100%扣除，也可以由夫妻双方对各自购买的住房分别按扣除标准的50%扣除，具体扣除方式在一个纳税年度内不能变更。
          </div>
        </div>
        <div class="popup-tishi-footer">
          <div class="zinv-btn" @click="showPopup3 = false">我知道了</div>
        </div>
      </div>
    </div>
    <cascadePopup
      v-if="popupShow"
      :offsetTop="popupTop"
      :offsetWidth="popupLeft"
      :popupBottom="popupBottom"
      :ajaxData="item"
      :item="child"
      @swiperSave="swiperSave"
      @cascadeClose="cascadeClose"
    ></cascadePopup>
  </div>
</template>

<script>
import cascadePopup from "@/components/cascadePopup";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/zh-cn";
export default {
  data() {
    return {
      showPopup1: false,
      showPopup2: false,
      showPopup3: false,
      popupShow: false,
      popupTop: null,
      popupBottom: null,
      popupLeft: null,
      child: {
        value1: "",
        value2: "",
        value3: "",
        desc1: "",
        desc2: "",
        desc3: "",
      },
      item: {
        mobile: "",
        email: "",
        address: "",
        deductYear: "",
        daikuan: "",
        //  1
        jixuStage: "",
        start: "",
        end: "",
        //  2
        jixuType: "",
        certification: "",
        certificationNo: "",
        certificationName: "",
        certificationOffice: "",
        value1: "",
        value2: "",
      },
      type: "",
      value: "13812345678",
      check: "",

      options: [],
      options1: [
        {
          value: "2020",
          label: "2020",
        },
        {
          value: "2021",
          label: "2021",
        },
      ],
      options2: [
        {
          value: "1",
          label: "房屋所有权证",
        },
        {
          value: "2",
          label: "不动产权证",
        },
        {
          value: "3",
          label: "房屋买卖合同",
        },
        {
          value: "4",
          label: "房屋预售合同",
        },
      ],
      options3: [
        {
          value: "1",
          label: "组合贷",
        },
        {
          value: "2",
          label: "公积金贷款",
        },
        {
          value: "3",
          label: "商业贷款",
        },
      ],

      boolData: this.$dictionaries.employeeModel.boolData.list,
      cascadeText: "",
    };
  },
  components: {
    DatePicker,
    cascadePopup,
  },
  props: {
    dateList: {
      type: Array,
      default() {
        return null;
      },
    },
  },
  mounted() {
    this.options1 = this.dateList;
  },
  methods: {
    saveFun() {
      this.childClose();
    },
    childClose() {
      this.$emit("closeFun");
    },
    change1() {
      if (this.item.value1 === "0") {
        this.item.value2 = "0";
      }
    },
    change2() {
      if (this.item.value2 === "1") {
        this.item.value1 = "1";
      }
    },
    popupShowClick(e) {
      this.popupShow = true;
      let H1 =
        document.body.clientHeight -
        e.currentTarget.getBoundingClientRect().bottom;
      if (H1 < 440) {
        this.popupBottom = H1 + 10;
        this.direction = "bottom";
      } else {
        this.popupTop = e.currentTarget.getBoundingClientRect().top + 30;
        this.direction = "top";
      }
      this.popupLeft = e.currentTarget.getBoundingClientRect().left - 350;
    },
    swiperSave(param) {
      console.log(param);
      this.cascadeText = param.desc1 + "-" + param.desc2 + "-" + param.desc3;
      this.cascadeClose();
      this.$forceUpdate();
    },
    cascadeClose() {
      this.popupShow = false;
    },
  },
};
</script>

<style lang="less" scoped>
.department-width {
  width: 480px;
  margin: 0 auto;
}

.popup-layer {
  z-index: 999;
}
.tishi-popup {
  min-height: 256px;
  .popup-zinv-tishi {
    height: 256px;
  }
}
.dabing {
  .declare-box-l {
    width: 190px;
    position: relative;
    .el-icon-info {
      position: absolute;
      right: 0;
      top: 1px;
    }
  }
  .declare-box-r {
    width: calc(100% - 190px);
  }
}
</style>
