var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"addStaff-box-right sHide",class:{
    addStaffWidth1:
      _vm.item.field === 'bankProvinceId' ||
      _vm.item.field === 'isDeductionCost' ||
      _vm.item.field === 'ruzhiqingxing',
    addStaffWidth2: _vm.item.field === 'identity',
    placement:
      _vm.item.field === 'nationality' &&
      (_vm.ajaxData.idType + '' === '2' || _vm.ajaxData.idType + '' === '4') &&
      _vm.ajaxData.status + '' !== '2',
  },on:{"click":function($event){return _vm.popupShowClick($event)}}},[(!_vm.showIcon)?_vm._t("default",function(){return [_c('div',{staticClass:"addStaff-tips noIcon sHide"},[_vm._v(" "+_vm._s(_vm.showText)+" ")])]}):_vm._t("default",function(){return [(!_vm.child.value)?_c('div',{staticClass:"addStaff-tips sHide"},[_vm._v(" "+_vm._s(_vm.child.tips)+" ")]):_c('div',{staticClass:"addStaff-text sHide"},[_vm._v(_vm._s(_vm.showText))]),_c('svg-icon',{staticClass:"xiala sHide",attrs:{"icon-class":"xiala-icon"}})]}),(_vm.popupShow)?_c('div',{staticClass:"staff-popup fadeIn sHide",style:({
      left: _vm.popupLeft + 'px',
      top: _vm.popupTop,
      bottom: _vm.popupBottom,
      height: _vm.item.field === 'bankProvinceId' ? '417px' : '',
    })},[_c('Triangle',{attrs:{"direction":_vm.direction,"left":"290","color":"#cccccc"}}),_c('div',{staticClass:"staff-popup-header sHide"},[_vm._v(" 请给"),_c('span',[_vm._v(_vm._s(_vm.name))]),_vm._v("选择"+_vm._s(_vm.child.text)+" ")]),_c('div',{staticClass:"staff-popup-content sHide"},_vm._l((_vm.child.arrayList),function(item,i){return _c('div',{key:i,staticClass:"staff-popup-box sHide",on:{"click":function($event){$event.stopPropagation();return _vm.listClick(item)}}},[_c('div',{staticClass:"staff-popup-box-l sHide"},[(item.id === _vm.child.value)?_c('svg-icon',{staticClass:"duigou sHide",attrs:{"icon-class":"duigou-yes"}}):_vm._e(),(item.id !== _vm.child.value)?_c('svg-icon',{staticClass:"duigou sHide",attrs:{"icon-class":"duigou-no"}}):_vm._e()],1),_c('div',{staticClass:"staff-popup-box-r sHide",class:{ gudongr: item.text === '股东' }},[_vm._v(" "+_vm._s(item.text)+" "),(item.text === '股东')?_vm._t("default",function(){return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.value1),expression:"value1"}],staticClass:"sHide",attrs:{"type":"text","placeholder":"投资金额"},domProps:{"value":(_vm.value1)},on:{"click":function($event){$event.stopPropagation();},"input":function($event){if($event.target.composing){ return; }_vm.value1=$event.target.value}}}),_vm._v("元 ")]}):_vm._e()],2),(item.text === '股东')?_vm._t("default",function(){return [_c('div',{staticClass:"staff-popup-box-l sHide"}),_c('div',{staticClass:"staff-popup-box-r gudongr sHide"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.value2),expression:"value2"}],staticClass:"sHide",attrs:{"type":"text","placeholder":"百分比"},domProps:{"value":(_vm.value2)},on:{"click":function($event){$event.stopPropagation();},"input":function($event){if($event.target.composing){ return; }_vm.value2=$event.target.value}}}),_vm._v("% ")])]}):_vm._e()],2)}),0)],1):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }