<template>
  <div class="addStaff-box-right" @click="popupShowClick($event)">
    <div class="addStaff-tips" v-if="!showText">{{ child.tips }}</div>
    <div class="addStaff-text" v-else>{{ showText }}</div>
    <svg-icon icon-class="move-icon" class="xiala"></svg-icon>
    <slot v-if="child.field === 'idcardImgFront'">
      <uploadIDCard
        :childData="ajaxData"
        :item="item"
        @staffParent="staffParent"
        v-if="popupShow"
      ></uploadIDCard
    ></slot>
    <slot v-if="child.field === 'degreeImg'">
      <updateDiplomaImge
        :childData="ajaxData"
        :item="item"
        @staffParent="staffParent"
        v-if="popupShow"
      ></updateDiplomaImge
    ></slot>
  </div>
</template>

<script>
import uploadIDCard from "./uploadIDCard";
import updateDiplomaImge from "./updateDiplomaImge";
export default {
  data() {
    return {
      popupShow: false,
      value: "",
      showText: "",
      child: {},
    };
  },
  inject: ["ajaxData"],
  props: {
    item: {
      type: Object,
      default() {
        return null;
      },
    },
  },
  components: {
    uploadIDCard,
    updateDiplomaImge,
  },
  mounted() {
    this.child = this.item;
    if (this.child.back && this.child.positive) {
      this.showText = "已上传";
    } else {
      this.showText = "";
    }
  },
  methods: {
    popupShowClick(e) {
      this.popupShow = true;
    },
    staffParent(param) {
      if (param.back && param.positive) {
        this.showText = "已上传";
      } else {
        this.showText = "";
      }
      this.popupShow = false;
      this.$emit("staffParent", param);
    },
  },
};
</script>

<style lang="less" scoped>
.xiala {
  font-size: 22px;
  right: 6px;
  top: 7px;
}
.addStaff-text,
.addStaff-tips {
  padding-right: 30px !important;
  width: calc(100% - 30px) !important;
}
</style>