<template>
  <div class="export-select">
    <div
      class="staff-popup fadeIn sHide"
      :style="{
        left: popupLeft + 'px',
        top: popupTop + 'px',
        bottom: popupBottom + 'px',
        height: item.field === 'bankProvinceId' ? '417px' : '',
      }"
    >
      <!-- <Triangle :direction="direction" left="290" color="#cccccc"></Triangle> -->
      <div class="staff-popup-header sHide">
        请给<span>{{ name }}{{ item.value }}</span
        >选择{{ child.text }}
      </div>
      <div class="staff-popup-content sHide">
        <div
          class="staff-popup-box sHide"
          v-for="(item, i) in child.arrayList"
          :key="i"
          @click.stop="listClick(item)"
        >
          <div class="staff-popup-box-l sHide">
            <svg-icon
              v-if="item.id + '' === row[child.field] + ''"
              icon-class="duigou-yes"
              class="duigou sHide"
            ></svg-icon>
            <svg-icon
              v-if="item.id + '' !== row[child.field] + ''"
              icon-class="duigou-no"
              class="duigou sHide"
            ></svg-icon>
          </div>
          <div class="staff-popup-box-r sHide">
            {{ item.text }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      popupShow: false,
      // popupTop: "",
      // popupBottom: "",
      // popupLeft: "",
      child: {},
      value1: "",
      value2: "",
      name: "员工",
      direction: "top",
    };
  },
  mounted() {
    this.child = this.item;
    console.log(this.child.value);

    console.log(this.row[this.child.field]);
  },
  methods: {
    listClick(item) {
      console.log(item);
      this.child.value = item.id;
      this.$emit("exportParent", this.child);
    },
  },
  props: {
    item: {
      type: Object,
      default() {
        return null;
      },
    },
    row: {
      type: Object,
      default() {
        return null;
      },
    },
    popupLeft: {
      type: Number,
      default() {
        return null;
      },
    },
    popupTop: {
      type: Number,
      default() {
        return null;
      },
    },
    popupBottom: {
      type: Number,
      default() {
        return null;
      },
    },
  },
};
</script>

<style lang="less" scoped>
.export-select {
  width: 100%;
  height: 100%;
  cursor: pointer;
}
</style>