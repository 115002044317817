<template>
  <div class="department">
    <div
      class="department-mian"
      id="views-mian-scroll"
      :class="{ overflowStyle: overflowStyle }"
    >
      <div class="department-header department-width">
        <div class="department-link" @click="gotoFun('/roster')">
          <i class="el-icon-arrow-left"></i>返回
        </div>
        添加员工
      </div>
      <div class="department-list department-width" v-if="addStaffShow">
        <div class="addStaff-main">
          <div class="">
            <div class="addStaff-list">
              <p class="list-title-style">主要信息</p>
              <slot v-for="(e, i1) in employee.zhuyao">
                <StaffBox
                  :key="'zhuyao-' + i1"
                  :item="e"
                  index1="0"
                  :index2="i1"
                  :ajaxData="enterpriseEmployee"
                  @staffParent="staffParent"
                  staffType="add"
                ></StaffBox>
              </slot>
              <slot v-if="enterpriseEmployee.idType > 1">
                <p class="list-title-style marTop">涉外信息</p>
                <slot v-for="(e, i1) in employee.shewai">
                  <StaffBox
                    :key="'shewai-' + i1"
                    :item="e"
                    index1="0"
                    :index2="i1"
                    :ajaxData="enterpriseEmployee"
                    @staffParent="staffParent"
                    staffType="add"
                  ></StaffBox>
                </slot>
              </slot>
            </div>
            <div class="addStaff-list">
              <p class="list-title-style">基本信息</p>
              <slot v-for="(e, i1) in jiben">
                <StaffBox
                  :key="'jiben-' + i1"
                  :item="e"
                  index1="0"
                  :index2="i1"
                  :ajaxData="enterpriseEmployee"
                  @staffParent="staffParent"
                  staffType="add"
                ></StaffBox>
              </slot>
            </div>
            <div class="addStaff-list">
              <p class="list-title-style">岗位信息</p>
              <slot v-for="(e, i1) in employee.gangwei">
                <StaffBox
                  :key="'gangwei-' + i1"
                  :item="e"
                  index1="0"
                  :index2="i1"
                  :ajaxData="enterpriseEmployee"
                  @staffParent="staffParent"
                  staffType="add"
                ></StaffBox>
              </slot>
              <p class="list-title-style marTop">工资卡信息</p>
              <slot v-for="(e, i1) in employee.gongzika">
                <StaffBox
                  :key="'gongzika-' + i1"
                  :item="e"
                  index1="0"
                  :index2="i1"
                  :ajaxData="enterpriseEmployee"
                  @staffParent="staffParent"
                  staffType="add"
                ></StaffBox>
              </slot>
            </div>
          </div>
          <div class="clear"></div>
          <div class="addStaff-line-box">
            <div class="addStaff-line-btn" @click="isShow = !isShow">
              更多信息
              <i class="el-icon-arrow-down" :class="{ isRotate: !isShow }"></i>
            </div>
          </div>
          <div style="overflow: hidden" v-if="isShow">
            <div class="addStaff-list addStaff-list-bottom">
              <slot v-for="(e, i1) in qita">
                <StaffBox
                  v-if="i1 < 3"
                  :key="'info1-' + i1"
                  :item="e"
                  index1="0"
                  :index2="i1"
                  :ajaxData="enterpriseEmployee"
                  @staffParent="staffParent"
                  staffType="add"
                ></StaffBox>
              </slot>
            </div>
            <div class="addStaff-list addStaff-list-bottom">
              <!-- 残疾  烈属 -->
              <slot v-for="(e, i1) in qita">
                <slot v-if="i1 >= 3 && i1 < qita.length - 3">
                  <slot v-if="e.field === 'disabilityNum'">
                    <StaffBox
                      v-if="qita[i1 - 1].value === '1'"
                      :key="'info2-' + i1"
                      :item="e"
                      index1="0"
                      :index2="i1"
                      :ajaxData="enterpriseEmployee"
                      @staffParent="staffParent"
                      staffType="add"
                    ></StaffBox>
                  </slot>
                  <slot v-else-if="e.field === 'martyrFamilyNum'">
                    <StaffBox
                      v-if="qita[i1 - 1].value === '1'"
                      :key="'info2-' + i1"
                      :item="e"
                      index1="0"
                      :index2="i1"
                      :ajaxData="enterpriseEmployee"
                      @staffParent="staffParent"
                      staffType="add"
                    ></StaffBox>
                  </slot>
                  <slot v-else>
                    <StaffBox
                      :key="'info2-' + i1"
                      :item="e"
                      index1="0"
                      :index2="i1"
                      :ajaxData="enterpriseEmployee"
                      @staffParent="staffParent"
                      staffType="add"
                    ></StaffBox>
                  </slot>
                </slot>
              </slot>
            </div>
            <div class="addStaff-list addStaff-list-bottom">
              <slot v-for="(e, i1) in qita">
                <StaffBox
                  v-if="i1 >= qita.length - 3"
                  :key="'info3-' + i1"
                  :item="e"
                  index1="0"
                  :index2="i1"
                  :ajaxData="enterpriseEmployee"
                  @staffParent="staffParent"
                  staffType="add"
                ></StaffBox>
              </slot>
            </div>
          </div>
          <!-- <div class="addStaff-btns">
            <div class="addStaff-btn" @click="addStaffSave">保 存</div>
          </div> -->
        </div>
        <div class="clear"></div>
        <div class="department-btn">
          <div class="addStaff-btn" @click="addStaffSave">添 加</div>
        </div>
      </div>
      <div class="clear"></div>
      <TheFooter></TheFooter>
    </div>
  </div>
</template>

<script>
import bus from "@/util/bus";
import { addEmployee, queryBuzhuButie } from "@/http/api";
import TheFooter from "@/components/TheFooter.vue";
import StaffBox from "./components/StaffBox";
export default {
  data() {
    return {
      isShow: false,
      addStaffShow: false,
      overflowStyle: false,
      userId: sessionStorage.getItem("userId"),
      enterpriseId: sessionStorage.getItem("enterpriseId"),
      employeeDate: this.$dictionaries.employeeDateOld.child1,
      employee: this.$config.cloneObj(this.$dictionaries.employeeDateOld),
      jiben: this.$config.cloneObj(this.$dictionaries.employeeDateOld.jiben),
      qita: this.$config.cloneObj(this.$dictionaries.employeeDateOld.qita1),
      enterpriseEmployee: {
        area: "",
        areaId: "",
        bankCardNum: "",
        bankCode: "",
        bankName: "",
        bankProvince: "",
        bankProvinceId: "",
        birth: "",
        bornNation: "",
        chinaName: "",
        city: "",
        cityId: "",
        contactArea: "",
        contactAreaId: "",
        contactCity: "",
        contactCityId: "",
        contactDetail: "",
        contactProvince: "",
        contactProvinceId: "",
        contractTerm: 0,
        contractType: 3,
        degreeImg: "",
        departmentId: "",
        departmentName: "",
        dimissionTime: "",
        diplomaImg: "",
        disabilityNum: "",
        dutiesType: 0,
        education: 0,
        employeeId: "",
        enterpriseDepartment: {
          creatTime: "",
          departmentFuncitonType: 0,
          departmentId: "",
          departmentName: "",
          enterpriseId: "",
          id: "",
          rdDirection: 0,
          updateTime: "",
        },
        enterpriseId: "",
        estimateLeaveTime: "",
        firstEntryTime: "",
        gender: "",
        headOne: "",
        hujiDetail: "",
        idNumber: "",
        idType: 1,
        idcardImgFront: "",
        idcardImgReverse: "",
        identity: 1,
        investMoney: "",
        investproportion: "",
        isAbroad: 0,
        isAdmin: 0,
        isDeductionCost: 0,
        isDisability: 0,
        isLonelyOld: 0,
        isMartyrFamily: 0,
        isOnterm: 0,
        isleave: 0,
        jobTime: "",
        jinjiLianxidianhua: "",
        jinjiLianxiren: "",
        martyrFamilyNum: "",
        mobile: "",
        name: "",
        nationality: "1",
        oftenArea: "",
        oftenAreaId: "",
        oftenCity: "",
        oftenCityId: "",
        oftenDetail: "",
        oftenProvince: "",
        oftenProvinceId: "",
        onTrialTermBegin: "",
        onTrialTermEnd: "",
        onTrialTermSalaryScale: "",
        otherCardNum: "",
        otherCardType: "",
        postName: "",
        postSalary: 0,
        province: "",
        provinceId: "",
        registered: 0,
        role: "",
        ruzhiqingxing: 0,
        school: "",
        sssy: 0,
        status: 0,
        subsidyList: [],
        userAuthorizationList: [
          {
            employeeId: "",
            enterpriseId: "",
            id: "",
            userApi: "",
            userAuthority: "",
            userId: "",
          },
        ],
        userHead: "",
        userId: "",
        oldIdtype: "",
      },
    };
  },
  mounted() {
    this.queryBuzhuButie();
    setInterval(() => {
      this.overflowStyle = this.$config.hasScrolled(
        "views-mian-scroll",
        "horizontal"
      );
    }, 100);
  },

  methods: {
    queryBuzhuButie(tye) {
      let that = this;
      let param = {
        userId: this.userId,
        enterpriseId: this.enterpriseId,
      };
      queryBuzhuButie(param).then((res) => {
        // console.log("res", res);
        this.arrayList = res.data.subsidyCompany;
        sessionStorage.setItem("buzhuButie", JSON.stringify(this.arrayList));
        this.addStaffShow = true;
      });
    },
    // 提交按钮
    addStaffSave() {
      this.$store.commit("showLoading", "");
      bus.$emit("hideLines");
      this.enterpriseEmployee.mobile = this.enterpriseEmployee.mobile.replace(
        /\s+/g,
        ""
      );
      let list = this.employeeDate.employeeList;
      if (this.enterpriseEmployee.isOnterm === 0) {
        this.enterpriseEmployee.onTrialTermSalaryScale =
          this.enterpriseEmployee.postSalary;
      }

      var jmz = {};
      jmz.GetLength = function (str) {
        return str.replace(/[\u0391-\uFFE5]/g, "aa").length; //先把中文替换成两个字节的英文，在计算长度
      };
      let length1 = jmz.GetLength(this.enterpriseEmployee.name);
      if (!this.enterpriseEmployee.name) {
        bus.$emit("name", true);
        this.$store.commit("MyMessage", "请输入姓名");
        return;
      } else if (length1 < 4) {
        bus.$emit("name", true);
        this.$store.commit("MyMessage", "姓名长度不符合规则");
        return;
      }
      if (!this.enterpriseEmployee.mobile) {
        bus.$emit("mobile", true);
        this.$store.commit("MyMessage", "请输入常用手机号");
        return;
      }
      const regMobile = /^1(3|4|5|6|7|8|9)\d{9}$/;
      if (!regMobile.test(this.enterpriseEmployee.mobile)) {
        bus.$emit("mobile", true);
        this.$store.commit("MyMessage", "手机号不符合规则");
        return;
      }
      if (!this.enterpriseEmployee.postName) {
        bus.$emit("postName", true);
        this.$store.commit("MyMessage", "请输入岗位名称");
        return;
      }
      if (!this.enterpriseEmployee.departmentId) {
        bus.$emit("departmentId", true);
        this.$store.commit("MyMessage", "请选择所属部门");
        return;
      }
      if (!this.enterpriseEmployee.contactProvinceId) {
        bus.$emit("contactProvince", true);
        this.$store.commit("MyMessage", "请选择现居住地");
        return false;
      }
      if (!this.enterpriseEmployee.contactDetail) {
        bus.$emit("contactDetail", true);
        this.$store.commit("MyMessage", "请输入现居住详细地址");
        return false;
      }
      if (this.enterpriseEmployee.idType + "" === "0") {
        this.enterpriseEmployee.idType = "1";
      }
      if (
        this.enterpriseEmployee.idType + "" === "1" ||
        this.enterpriseEmployee.idType + "" === "0"
      ) {
        if (this.enterpriseEmployee.idNumber !== "") {
          if (!this.enterpriseEmployee.idNumber) {
            this.$store.commit("MyMessage", "证件号码未完善");
            bus.$emit("idNumber", true);
            return false;
          }
          let testId = this.testid(this.enterpriseEmployee.idNumber);
          if (testId.status === 0) {
            bus.$emit("idNumber", true);
            this.$store.commit("MyMessage", "身份证格式有误，请重新填写");
            return false;
          }
        }
      } else if (this.enterpriseEmployee.idType + "" != "1") {
        if (!this.enterpriseEmployee.idNumber) {
          this.$store.commit("MyMessage", "证件号码未完善");
          bus.$emit("idNumber", true);
          return false;
        }
        if (this.enterpriseEmployee.idType + "" === "3") {
          if (
            this.enterpriseEmployee.idNumber.length === 9 ||
            this.enterpriseEmployee.idNumber.length === 11
          ) {
          } else {
            this.$store.commit("MyMessage", "证件号码未完善");
            bus.$emit("idNumber", true);
            return false;
          }
        } else if (this.enterpriseEmployee.idType + "" === "4") {
          if (this.enterpriseEmployee.idNumber.length === 8) {
          } else {
            this.$store.commit("MyMessage", "证件号码未完善");
            bus.$emit("idNumber", true);
            return false;
          }
        }
        if (!this.enterpriseEmployee.nationality) {
          this.$store.commit("MyMessage", "国籍未完善");
          return;
        }
      }

      // for
      if (0) {
        if (
          this.enterpriseEmployee.idType + "" !== "1" &&
          this.enterpriseEmployee.idType + "" !== "0"
        ) {
          let sw = this.employee.shewai;
          for (let i = 0; i < sw.length; i++) {
            if (sw[i].require) {
              if (!sw[i].value) {
                this.$store.commit("MyMessage", sw[i].tips + sw[i].text);
                bus.$emit(sw[i].field, true);
                return false;
              }
            }
          }
        }
        let jb = this.jiben;
        for (let i = 0; i < jb.length; i++) {
          if (jb[i].require) {
            if (
              !this.enterpriseEmployee[jb[i].field] ||
              this.enterpriseEmployee[jb[i].field] === -1
            ) {
              this.$store.commit("MyMessage", jb[i].tips + jb[i].text);
              bus.$emit(jb[i].field, true);
              return false;
            }
          }
        }
        let gw = this.employee.gangwei;
        for (let i = 0; i < gw.length; i++) {
          if (gw[i].require) {
            if (gw[i].field === "onTrialTermBegin") {
              if (!this.enterpriseEmployee.onTrialTermBegin) {
                console.log(this.enterpriseEmployee.onTrialTermBegin);
                this.$store.commit("MyMessage", gw[i].tips + gw[i].text);
                bus.$emit(gw[i].field, true);
                return false;
              }
            } else if (!gw[i].value) {
              this.$store.commit("MyMessage", gw[i].tips + gw[i].text);
              bus.$emit(gw[i].field, true);
              return false;
            }
          }
        }
      }
      if (this.enterpriseEmployee.idNumber === "") {
        this.enterpriseEmployee.idType = 0;
      }
      this.enterpriseEmployee.idNumber =
        this.enterpriseEmployee.idNumber.toUpperCase();
      let param = {
        enterpriseId: this.enterpriseId,
        userId: this.userId,
        // joinChannelCode: "20001", //20001：金翅膀
        enterpriseEmployee: this.enterpriseEmployee,
      };
      addEmployee(param).then((res) => {
        this.$store.commit("hideLoading", "");
        console.log("res", res);
        if (res.code === 0) {
          this.$store.commit("MyMessage", "添加成功");
          this.$router.push("/roster");
        } else {
          this.$store.commit("MyMessage", res.msg);
        }
      });
    },
    gotoFun(path) {
      this.$router.push(path);
    },
    // 证件类型--切换判断
    idTypeFun(item, index1, index2) {
      this.addStaffShow = false;
      let oldData = this.$config.cloneObj();
      this.oldIdtype = item.value;
      if (item.value === "1") {
        this.jiben = this.$config.cloneObj(
          this.$dictionaries.employeeDateOld.jiben
        );
        this.qita = this.$config.cloneObj(
          this.$dictionaries.employeeDateOld.qita1
        );
      } else if (item.value === "5") {
        this.jiben = this.$config.cloneObj(
          this.$dictionaries.employeeDateOld.jiben2
        );
        this.qita = this.$config.cloneObj(
          this.$dictionaries.employeeDateOld.qita3
        );
      } else {
        this.jiben = this.$config.cloneObj(
          this.$dictionaries.employeeDateOld.jiben2
        );
        this.qita = this.$config.cloneObj(
          this.$dictionaries.employeeDateOld.qita2
        );
      }

      if (item.value === "1") {
        this.enterpriseEmployee.nationality = "1";
        this.jiben[0].value = "1";
      } else if (item.value === "2") {
        this.enterpriseEmployee.nationality = "1";
        this.jiben[2].value = "1";
        this.jiben[2].arrayList = this.$dictionaries.countryData;
      } else if (item.value === "3") {
        this.enterpriseEmployee.nationality = "4";
        this.jiben[2].value = "4";
        this.jiben[2].arrayList = this.$dictionaries.gangao;
      } else if (item.value === "4") {
        this.enterpriseEmployee.nationality = "3";
        this.jiben[2].value = "3";
        this.jiben[2].arrayList = this.$dictionaries.taiwan;
      } else {
        this.enterpriseEmployee.nationality = "";
        this.jiben[2].value = "";
        this.jiben[2].arrayList = this.$dictionaries.countryData;
      }
      this.jiben[0].value = item.value;
      this.$nextTick(() => {
        this.addStaffShow = true;
      });
    },
    // 判断试用期
    isOntermFun() {
      if (
        this.enterpriseEmployee.onTrialTermBegin &&
        this.enterpriseEmployee.onTrialTermEnd
      ) {
        if (
          this.enterpriseEmployee.onTrialTermBegin ===
          this.enterpriseEmployee.onTrialTermEnd
        ) {
          this.enterpriseEmployee.isOnterm = 0;
        } else {
          this.enterpriseEmployee.isOnterm = 1;
        }
      } else {
        this.enterpriseEmployee.isOnterm = 0;
      }
    },
    staffParent(item, index1, index2) {
      // console.log(item);
      this.enterpriseEmployee[item.field] = item.value;
      if (item.field === "idType") {
        this.idTypeFun(item, index1, index2);
      } else if (item.field === "identity") {
        if (item.value + "" === "2") {
          this.enterpriseEmployee.investMoney = item.value1;
          this.enterpriseEmployee.investproportion = item.value2;
        }
      } else if (item.type === "city") {
        this.enterpriseEmployee[item.field + "Id"] = item.value1;
        this.enterpriseEmployee[item.city + "Id"] = item.value2;
        this.enterpriseEmployee[item.area + "Id"] = item.value3;
        this.enterpriseEmployee[item.field] = item.desc1;
        this.enterpriseEmployee[item.city] = item.desc2;
        this.enterpriseEmployee[item.area] = item.desc3;
      } else if (item.field === "jobTime") {
        this.enterpriseEmployee.onTrialTermBegin = item.value;
        this.enterpriseEmployee.onTrialTermEnd = item.value;
        // this.enterpriseEmployee.onTrialTermSalaryScale = "";
        this.isOntermFun();
      } else if (item.field === "contractType") {
        if (item.value + "" === "2") {
          this.enterpriseEmployee.identity = "6";
        } else if (item.value + "" === "1") {
          this.enterpriseEmployee.identity = "3";
        }
      } else if (item.field === "onTrialTermBegin") {
        this.enterpriseEmployee.onTrialTermBegin = item.onTrialTermBegin;
        this.enterpriseEmployee.onTrialTermEnd = item.onTrialTermEnd;

        this.isOntermFun();
      } else if (item.field === "idcardImgFront") {
        this.enterpriseEmployee.idcardImgFront = item.positive;
        this.enterpriseEmployee.idcardImgReverse = item.back;
      } else if (item.field === "degreeImg") {
        this.enterpriseEmployee.degreeImg = item.positive;
        this.enterpriseEmployee.diplomaImg = item.back;
      } else if (item.field === "butieSalary") {
        this.enterpriseEmployee.subsidyList = item.subsidyList;
      } else if (item.field === "bankProvinceId") {
        this.enterpriseEmployee.bankProvince = item.bankProvince;
      }
    },
    testid(id) {
      // 1 "验证通过!", 0 //校验不通过 // id为身份证号码
      var format =
        /^(([1][1-5])|([2][1-3])|([3][1-7])|([4][1-6])|([5][0-4])|([6][1-5])|([7][1])|([8][1-2]))\d{4}(([1][9]\d{2})|([2]\d{3}))(([0][1-9])|([1][0-2]))(([0][1-9])|([1-2][0-9])|([3][0-1]))\d{3}[0-9xX]$/;
      //号码规则校验
      if (!format.test(id)) {
        return {
          status: 0,
          msg: "身份证号码不合规",
        };
      }
      //区位码校验
      //出生年月日校验  前正则限制起始年份为1900;
      var year = id.substr(6, 4),
        //身份证年
        month = id.substr(10, 2),
        //身份证月
        date = id.substr(12, 2),
        //身份证日
        time = Date.parse(month + "-" + date + "-" + year),
        //身份证日期时间戳date
        now_time = Date.parse(new Date()),
        //当前时间戳
        dates = new Date(year, month, 0).getDate(); //身份证当月天数
      if (time > now_time || date > dates) {
        return {
          status: 0,
          msg: "出生日期不合规",
        };
      }
      //校验码判断
      var c = new Array(7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2); //系数
      var b = new Array("1", "0", "X", "9", "8", "7", "6", "5", "4", "3", "2"); //校验码对照表
      var id_array = id.split("");
      var sum = 0;
      for (var k = 0; k < 17; k++) {
        sum += parseInt(id_array[k]) * parseInt(c[k]);
      }
      if (id_array[17].toUpperCase() != b[sum % 11].toUpperCase()) {
        return {
          status: 0,
          msg: "身份证校验码不合规",
        };
      }
      return {
        status: 1,
        msg: "校验通过",
      };
    },
  },
  components: {
    TheFooter,
    StaffBox,
  },
};
</script>

<style lang="less" scoped>
.department .department-list {
  padding-top: 18px;
  min-height: calc(100% - 158px);
}
.department-width {
  width: 1328px;
  margin: 0 auto;
}
.addStaff-list {
  width: calc((100% - 160px) / 3);
  float: left;
  margin-left: 80px;
  &:first-child {
    margin-left: 0;
  }
}
.addStaff-main {
  margin-bottom: 20px;
  padding-bottom: 20px;
}

.addStaff-line-box {
  margin: 30px 0;
}
.department-btn {
  position: relative;
  top: 0;
  right: 0;
  text-align: right;
  .addStaff-btn {
    margin: 0;
    padding: 0;
  }
}
.list-title-style {
  color: #999;
  background: #f5f5f5;
  &.marTop {
    margin-top: 36px;
  }
}
</style>
