<template>
  <!-- <div class="department-right">员工状态：<span>正常</span></div> -->
  <div class="department-right" @click="dateClick">
    <div class="addStaff-text" v-if="!lizhi">
      员工状态：<span>{{ showText }}</span
      ><svg-icon icon-class="xiala-icon" class="xiala"></svg-icon>
    </div>
    <div class="addStaff-text" v-else>
      员工状态：<span class="redColor">{{ showText }}</span
      ><svg-icon icon-class="xiala-icon" class="xiala"></svg-icon>
    </div>
    <date-picker
      v-model="dimissionTime"
      value-type="format"
      format="YYYY-MM-DD"
      @change="dateChange"
      class="data-picker-style"
      :placeholder="item.tips"
      :open.sync="openDates"
      :editable="false"
      :clearable="false"
    ></date-picker>
  </div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/zh-cn";
export default {
  data() {
    return {
      child: {},
      showIcon: true,
      openDates: false,
      lizhi: false,
      showText: "",
      dimissionTime: "",
      date: this.$config.getDate(),
    };
  },
  props: {
    ajaxData: {
      type: Object,
      default() {
        return null;
      },
    },
    item: {
      type: Object,
      default() {
        return null;
      },
    },
    staffType: {
      type: String,
      default() {
        return null;
      },
    },
  },
  components: {
    DatePicker,
  },
  mounted() {
    this.child = this.item;
    // console.log(this.child);
    // console.log(this.ajaxData);
    if (this.ajaxData.status + "" === "1") {
      this.showText = "正常";
    } else if (this.ajaxData.status + "" === "2") {
      this.showText =
        "离职(停薪)日期: " + this.ajaxData.dimissionTime.split(" ")[0];
      this.lizhi = true;
    }
    if (this.staffType === "details" && this.child.field === "jobTime") {
      if (this.ajaxData.status) {
        this.ajaxData.status = this.ajaxData.status.split(" ")[0];
      }
    }
  },
  methods: {
    dateClick() {
      this.openDates = true;
      this.$nextTick(() => {
        let className1 = document.querySelector(".className1");
        if (className1 === null) {
          let a = document.getElementsByClassName("mx-calendar-panel-date")[0];
          var newNodeTop = document.createElement("div");
          var vtop = "选择离职日期(停薪日期)";
          newNodeTop.innerHTML = vtop;
          newNodeTop.style.textAlign = "center";
          newNodeTop.style.padding = "10px 0px 0";
          newNodeTop.style.color = "#262626";
          newNodeTop.className = "className1";
          a.parentNode.insertBefore(newNodeTop, a);
        }
      });
    },
    dateChange() {
      let year = this.dimissionTime.split("-")[0];
      let month = this.dimissionTime.split("-")[1];
      let day = this.dimissionTime.split("-")[2];
      // console.log(this.ajaxData.ptsControInfo);
      // if (this.ajaxData.ptsControInfo.length > 0) {
      // }
      if (this.date.year === year) {
        if (this.date.lastMounth === month) {
          // this.$store.commit("MyMessage", "成功");
        } else if (this.date.thisMounth + "" === month + "") {
          if (day * 1 > this.date.day * 1) {
            this.dimissionTime = "";
            this.$store.commit("MyMessage", "不能跨月或者选择后面的日期");
            return false;
          }
        } else {
          this.dimissionTime = "";
          this.$store.commit("MyMessage", "不能跨月或者选择后面的日期");
          return false;
        }
      } else if (year * 1 > this.date.year * 1) {
        this.dimissionTime = "";
        this.$store.commit("MyMessage", "不能跨月或者选择后面的日期");
        return false;
      } else if (year * 1 < this.date.year * 1) {
        if (year * 1 === this.date.year * 1 - 1 && month + "" === "12") {
          // this.$store.commit("MyMessage", "成功");
        } else {
          this.dimissionTime = "";
          this.$store.commit("MyMessage", "不能跨月或者选择后面的日期");
          return false;
        }
      } else {
        this.dimissionTime = "";
        this.$store.commit("MyMessage", "不能跨月或者选择后面的日期");
        return false;
      }
      let oDate1 = new Date(this.dimissionTime);
      let oDate2 = new Date(this.ajaxData.jobTime);
      let oDate3 = new Date();
      if (oDate1 < oDate2) {
        this.dimissionTime = "";
        this.$store.commit("MyMessage", "离职日期必须大于入职日期");
        return false;
      }
      if (oDate1 > oDate3) {
        this.dimissionTime = "";
        this.$store.commit("MyMessage", "离职日期不能大于当前日期");
        return false;
      }
      this.ajaxData.status = "2";
      this.ajaxData.dimissionTime = this.dimissionTime;
      this.showText = "离职(停薪)日期: " + this.dimissionTime;
      this.lizhi = true;
    },
    blur() {
      console.log("child", this.child);
      // this.$emit("")
    },
  },
};
</script>
<style lang="less">
.addStaff-box-right {
  .mx-datepicker {
    width: 100%;
    .mx-input {
      width: 100%;
      margin: 0;
      padding: 0;
      height: 22px;
      border: none;
      box-shadow: none;
      padding-right: 36px;
      font: 400 16px Techna;
      cursor: pointer;
    }
  }
  .mx-icon-calendar {
    display: none;
  }
}
.redColor {
  color: #ea0200;
}
</style>