<template>
  <div class="starusStyle" :class="{ noClick: !statusFlag }">
    <slot v-if="item.isAdmin === 1">
      <slot v-if="item.userId === userId">
        <p style="color: #999" v-if="list.length <= 1">管理员</p>
        <p
          style="color: #4c8afc"
          v-if="list.length > 1"
          @click.stop="statusClick(2, $event)"
        >
          管理员
        </p>
      </slot>
      <slot v-else>
        <p style="color: #999" @click.stop="statusClick(2, $event)">管理员</p>
      </slot>
    </slot>
    <slot v-else-if="item.status === 10">
      <p style="color: #f58e21" @click.stop="statusClick(1, $event)">
        [同意加入]
      </p>
    </slot>
    <slot v-else>
      <slot v-if="item.userAuthorityType"
        ><p style="color: #4c8afc" @click.stop="statusClick(3, $event, item)">
          全部权限
        </p>
      </slot>
      <slot v-else-if="item.userAuthorizationList.length === 0">
        <p style="color: #999" @click.stop="statusClick(3, $event, item)">
          <slot v-if="statusFlag">
            分配权限
            <svg-icon
              icon-class="jiantou-bottom"
              class="jiantou-bottom"
            ></svg-icon>
          </slot>
          <slot v-if="!statusFlag">--</slot>
        </p>
      </slot>
      <slot v-else>
        <div @click.stop="statusClick(3, $event, item)">
          <slot v-if="statusFlag">
            <span
              style="color: #4c8afc"
              class="table-popup-span"
              v-for="(child, i1) in item.userAuthorizationList"
              :key="i1"
            >
              {{ child.textName
              }}<slot
                v-if="item.userAuthorizationList.length - 1 !== i1 && i1 !== 2"
                ><span class="span-width"></span
              ></slot>
              <slot v-if="i1 === 2 || i1 == 5"><br /></slot>
            </span>
          </slot>
          <slot v-if="!statusFlag"><span>无</span></slot>
        </div>
      </slot>
    </slot>
    <div
      class="status-popup"
      v-if="item.status === 10 && statusShow1"
      @mouseleave="statusShow1 = false"
    >
      <Triangle direction="top" left="120" color="#cccccc"></Triangle>
      <div class="status-popup-mian">
        <div
          class="tatus-popup-mian-btn1"
          @click.stop="checkApplyJoinEnterprise(item, 1)"
        >
          同意加入
        </div>
        <div
          class="tatus-popup-mian-btn2"
          @click.stop="checkApplyJoinEnterprise(item, 2)"
        >
          删除信息
        </div>
      </div>
    </div>
    <div
      class="replace-popup replaceHide"
      v-if="isAdmin === 1 && userList.length > 0 && statusShow2"
      @mouseleave="statusShow2 = false"
    >
      <Triangle direction="top" left="226" color="#cccccc"></Triangle>
      <div class="replace-main replaceHide">
        <div class="replace-top replaceHide">更换管理员</div>
        <div class="replace-list replaceHide">
          <slot v-for="(e, i) in userList">
            <div
              class="replace-list-box replaceHide"
              :key="i + '-1'"
              @click.stop="exchangeEnterpriseAdmin(e)"
            >
              <div class="replace-list-l replaceHide">
                <div class="userHead" v-if="e.userHead">
                  <img
                    class="userHead-logo replaceHide"
                    :src="e.userHead"
                    style="margin-top: 0; position: relative; top: 8px"
                    alt=""
                  />
                </div>
                <div class="userHead replaceHide" v-else-if="e.name">
                  <div
                    class="userHead-logo replaceHide"
                    v-if="e.name.length > 2"
                  >
                    <slot v-if="e.name.substr(0, 1) === '*'">*</slot
                    >{{ e.name.substr(e.name.length - 2, 2) }}
                  </div>
                  <div class="userHead-logo replaceHide" v-else>
                    {{ e.name }}
                  </div>
                </div>
              </div>
              <div class="replace-list-r replaceHide">{{ e.name }}</div>
            </div>
            <div :key="i + '-2'" class="clear"></div>
          </slot>
        </div>
      </div>
    </div>
    <div
      class="roster-popup replaceHide"
      v-if="statusShow3"
      @mouseleave="statusShow3 = false"
      :style="{
        left: popupLeft + 'px',
        top: popupTop,
        bottom: popupBottom,
      }"
    >
      <Triangle :direction="direction" left="206" color="#cccccc"></Triangle>
      <div class="roster-popup-top replaceHide">
        请给
        <span class="popup-name replaceHide">{{ item.name }}</span>
        修改权限
        <p>提示：如没有以下权限，无需分配！</p>
      </div>
      <div class="clear replaceHide"></div>
      <div class="roster-popup-main replaceHide">
        <div class="roster-popup-list replaceHide">
          <div
            class="roster-popup-box replaceHide"
            v-for="(item, i) in selectArray"
            :key="i"
            :class="{ notOpen: item.conceal }"
            @click.stop="selectClick(item)"
          >
            <div class="notOpenBg replaceHide" v-if="item.conceal"></div>
            <svg-icon
              v-if="item.select"
              icon-class="duigou-yes"
              class="duigou replaceHide"
            ></svg-icon>
            <svg-icon
              v-if="!item.select"
              icon-class="duigou-no"
              class="duigou replaceHide"
            ></svg-icon>
            <div class="roster-popup-box-text replaceHide">
              {{ item.value }}
              <span>{{ item.desc }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="roster-popup-bottom replaceHide replaceHide">
        <el-button
          style="width: 100%"
          type="primary"
          class="primary-btn replaceHide"
          @click.stop="editClick"
          >确定</el-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import bus from "@/util/bus";
import {
  exchangeEnterpriseAdmin,
  flashTokenPc,
  enterpriseGrantAuthorization,
  checkApplyJoinEnterprise,
  flashTokenPcPlatform,
} from "@/http/api";
export default {
  data() {
    return {
      userId: sessionStorage.getItem("userId"),
      enterpriseId: sessionStorage.getItem("enterpriseId"),
      visit: sessionStorage.getItem("visit"),
      selectArray: [],
      userList: [],
      value: "",
      statusShow1: false, //审批-邀请员工
      statusShow2: false, //更换管理员
      statusShow3: false, //分配权限
      selects: [],
      statusFlag: true,
      popupTop: "",
      popupBottom: "",
      popupLeft: "",
      direction: "",
    };
  },
  mounted() {
    let that = this;
    this.selectArray = this.$config.cloneObj(
      this.$dictionaries.employeeModel.jurisdiction.list
    );
    if (this.item.userAuthorizationList.length > 0) {
      this.item.userAuthorizationList.forEach((element) => {
        this.selects.push(element.userAuthority);
      });

      this.selectArray.forEach((element) => {
        this.selects.forEach((e) => {
          if (e === element.key) {
            element.select = true;
          }
        });
      });
    }
    this.userList = [];
    this.list.forEach((element) => {
      if (element.isAdmin === 1) {
      } else if (element.status + "" === "2") {
      } else if (element.status + "" === "10") {
      } else {
        if (element.userHead.indexOf("http://jz.jzdcs.com") !== -1) {
          element.userHead =
            "https://www.jzcfo.com" +
            element.userHead.split("http://jz.jzdcs.com")[1];
        }
        this.userList.push(element);
      }
    });
    if (this.item.status + "" === "2") {
      this.statusFlag = false;
    }
    if (this.item.isAdmin === 1 && this.userList.length > 0) {
      this.statusFlag = true;
    }

    if (this.$route.path === "/roster") {
      document.addEventListener("click", (e) => {
        let text1 = "replaceHide";
        let flag1 = null;
        if (e.target.className == "") {
          flag1 = 1;
        } else {
          if (typeof e.target.className !== "string") {
            flag1 = 1;
          } else {
            flag1 = e.target.className.indexOf(text1);
          }
        }
        if (
          flag1 != -1 ||
          e.target.className == "edit-box-text" ||
          e.target.className == ""
        ) {
        } else {
          this.statusShow3 = false;
          this.statusShow2 = false;
          this.statusShow1 = false;
          this.$forceUpdate();
        }
      });
    }
    // replaceHide
    bus.$on("replaceHide", function (data) {
      that.statusShow3 = false;
      that.statusShow2 = false;
      that.statusShow1 = false;
    });
    // console.log("selectArray", this.selectArray);
  },
  methods: {
    popupShowClick(e) {
      let H1 =
        document.body.clientHeight -
        e.currentTarget.getBoundingClientRect().bottom;
      // console.log(H1);
      if (H1 < 373) {
        this.popupBottom = H1 + 36 + "px";
        this.direction = "bottom";
        this.popupTop = "auto";
      } else {
        this.popupTop = e.currentTarget.getBoundingClientRect().top + 56 + "px";
        this.direction = "top";
        this.popupBottom = "auto";
      }
      this.popupLeft = e.currentTarget.getBoundingClientRect().left - 125;
    },
    statusClick(flag, e, item) {
      bus.$emit("replaceHide");
      if (this.statusFlag) {
        if (flag === 1) {
          this.statusShow1 = true;
        } else if (flag === 2) {
          this.statusShow2 = true;
        } else if (flag === 3) {
          if (item.userId === this.userId && item.isAdmin !== 1) {
            this.$store.commit("MyMessage", "不能修改自己的权限");
            return false;
          }
          this.popupShowClick(e);
          this.statusShow3 = true;
        }
      }
    },
    checkApplyJoinEnterprise(item, type) {
      // console.log(item);
      let param = {
        adminId: this.userId,
        enterpriseId: this.enterpriseId,
        employeeId: item.employeeId,
        confirmResult: type, //通过1/不通过2
      };
      checkApplyJoinEnterprise(param).then((res) => {
        // console.log(res);
        if (res.code === 0) {
          location.reload();
        } else {
          this.$store.commit("MyMessage", res.msg);
        }
      });
    },
    enterpriseGrantAuthorization(list) {
      let that = this;
      let param = {
        adminId: this.userId,
        userId: this.item.userId,
        enterpriseId: this.enterpriseId,
        userAuthorityList: list,
      };
      enterpriseGrantAuthorization(param).then((res) => {
        if (res.code === 0) {
          this.emitToParent();
          this.statusShow3 = false;
          // location.reload();
          this.$emit("statusLayer");
          that.$forceUpdate();
        }
        this.$store.commit("MyMessage", "分配成功");
      });
    },
    editClick() {
      let child = [];
      this.selectArray.forEach((element) => {
        if (element.select) {
          child.push(element.key);
        }
      });
      this.enterpriseGrantAuthorization(child);
    },
    selectClick(item) {
      if (!item.conceal) {
        item.select = !item.select;
        if (item.key === "100101") {
          this.selectArray.forEach((element) => {
            if (!element.conceal) {
              element.select = item.select;
            }
          });
        } else {
          if (!item.select) {
            this.selectArray[0].select = false;
          } else {
            let selectNum = 0;
            this.selectArray.forEach((element, i) => {
              if (i !== 0) {
                if (!element.conceal && element.select) {
                  selectNum++;
                }
              }
            });
            // if (selectNum === 5) {
            //   this.selectArray[0].select = true;
            // }
          }
        }
      }
    },
    flashTokenPc() {
      if (this.visit === "visit") {
        this.flashTokenPcPlatform();
        return false;
      }

      let that = this;
      let param = {
        userId: this.userId,
        enterpriseId: this.enterpriseId,
      };
      flashTokenPc(param).then((res) => {
        if (res.code == 0) {
          this.closePopup();
          // sessionStorage.setItem("pc-tkn", res.data.token);
          // this.$nextTick(() => {
          //   location.reload();
          // });
        } else {
          if (!flag) this.$store.commit("MyMessage", res.msg);
        }
      });
    },
    flashTokenPcPlatform() {
      let that = this;
      let param = {
        userId: this.userId,
        enterpriseId: this.enterpriseId,
      };
      flashTokenPcPlatform(param).then((res) => {
        if (res.code == 0) {
          // sessionStorage.setItem("pc-tkn", res.data.token);
          // this.$nextTick(() => {

          // });
          this.closePopup();
        } else {
          if (!flag) this.$store.commit("MyMessage", res.msg);
        }
      });
    },
    emitToParent() {
      this.$emit("child-event");
    },
    exchangeEnterpriseAdmin(e) {
      this.$confirm(
        "切换管理员给 <span style='color: #ff931e;'>" + e.name + "</span>",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          dangerouslyUseHTMLString: true,
          type: "",
          center: true,
        }
      )
        .then(() => {
          let param = {
            userIdSource: this.item.userId,
            employeeIdSource: this.item.employeeId,
            userIdTarget: e.userId,
            employeeIdTarget: e.employeeId,
            enterpriseId: this.enterpriseId,
          };
          console.log("e", e);
          console.log("this.item", this.item);
          console.log("param", param);
          exchangeEnterpriseAdmin(param).then((res) => {
            if (res.code === 0) {
              if (this.visit !== "visit") {
                return;
              }
              // sessionStorage.setItem("userId", e.userId);
              // this.$setCookie("userId", e.userId);
              // this.userId = e.userId;

              this.closePopup();
              this.$store.commit("MyMessage", "请重新登录！");
              // this.flashTokenPc();
              // // location.reload();
              // this.emitToParent();
            } else {
              this.$store.commit("MyMessage", res.msg);
            }
          });
        })
        .catch(() => {});
    },
    closePopup() {
      sessionStorage.removeItem("userId");
      sessionStorage.removeItem("pc-tkn");
      sessionStorage.removeItem("jurisdiction");
      sessionStorage.removeItem("enterpriseId");
      sessionStorage.removeItem("accountingCriterion");
      sessionStorage.removeItem("visit");
      sessionStorage.removeItem("departmentList");
      sessionStorage.removeItem("company");
      sessionStorage.removeItem("creditCode");
      sessionStorage.removeItem("infoUser");
      // sessionStorage.removeItem("mobile");
      this.routeFun();
    },
    routeFun() {
      if (this.$route.path !== "/Navigation") {
        this.$router.push("/Navigation");
      } else {
        location.reload();
      }
    },
  },
  props: {
    item: {
      type: Object,
      default() {
        return {};
      },
    },
    isAdmin: {
      type: Number,
      default() {
        return null;
      },
    },
    list: {
      type: Array,
      default() {
        return [];
      },
    },
  },
};
</script>

<style lang="less" scoped>
.jiantou-bottom {
  width: 10px;
}
.starusStyle {
  width: 100%;
  height: 100%;
  cursor: pointer;
  position: relative;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Chrome/Safari/Opera */
  -khtml-user-select: none;
  /* Konqueror */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  &.noClick {
    p,
    span {
      color: #999 !important;
    }
  }
}
.status-popup {
  position: absolute;
  width: 246px;
  height: 137px;
  background: #ffffff;
  border: 1px solid #cccccc;
  z-index: 1;
  right: -54px;
  top: 40px;

  .status-popup-mian {
    width: 100%;

    .tatus-popup-mian-btn1 {
      width: 173px;
      height: 34px;
      line-height: 34px;
      background: #4c8afc;
      border: 1px solid #4c8afc;
      opacity: 1;
      border-radius: 3px;
      margin: 20px auto 0;
      font-size: 14px;
      font-weight: bold;
      color: #ffffff;
      cursor: pointer;
    }

    .tatus-popup-mian-btn2 {
      width: 173px;
      height: 34px;
      line-height: 34px;
      background: #f4f4f4;
      border: 1px solid #f4f4f4;
      opacity: 1;
      border-radius: 3px;
      margin: 16px auto 0;
      font-size: 14px;
      font-weight: 400;
      color: #999999;
      cursor: pointer;
    }
  }
}
.replace-popup {
  position: absolute;
  width: 302px;
  min-height: 137px;
  background: #ffffff;
  border: 1px solid #cccccc;
  z-index: 1;
  right: -10px;
  top: 40px;
}
.span-width {
  width: 5px;
  display: inline-block;
}
</style>