<template>
  <div class="popup-layer">
    <div class="popup-layer-bg" @click="closeFun"></div>
    <div class="payroll-popup-main mouth-mian">
      <div class="payroll-popup-main-title">{{ title }}</div>
      <div class="payroll-popup-main-text" style="height: 80px">
        <el-button plain class="payroll-click" @click="gotoFun('/detailsStaff')"
          >去完善</el-button
        >
      </div>
      <div class="payroll-popup-main-fotter">
        <el-button plain class="fotter-click closeColor" @click="closeFun"
          >取消</el-button
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      wanshanShow1: false,
      title: "请先完善管理员信息",
    };
  },
  props: {
    userItem: {
      type: Object,
      default: null,
    },
  },
  mounted() {
    if (this.userItem.isAdmin !== 1) {
      this.title = "请先完善员工信息";
    }
  },
  methods: {
    closeFun() {
      this.$emit("update:wanshanShow1", false);
    },
    gotoFun(path) {
      // this.$emit("wanshanFun");
      // console.log(this.userItem);
      // return;
      this.$router.push({
        path: path,
        query: {
          uid: this.userItem.userId,
          eid: this.userItem.employeeId,
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.payroll-popup-main {
  position: absolute;

  left: 50%;
  top: 50%;
  width: 340px;
  height: 340px;
  margin-left: -170px;
  margin-top: -170px;
  height: auto;
  background: #ffffff;
  border-radius: 0;
  text-align: center;
  padding-bottom: 30px;
  .payroll-popup-main-title {
    margin: 38px auto 35px;
  }
  .payroll-click {
    width: 232px;
    font-size: 16px;
    font-weight: 400;
    color: #4c8afc;
  }
  .closeColor {
    width: 232px;
    border: none;
  }
}
</style>