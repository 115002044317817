<template>
  <div class="popup-layer">
    <div class="popup-layer-bg" @click="hidePopup"></div>
    <div class="popup-layer-content">
      <div class="popup-layer-close" @click="hidePopup">
        <svg-icon icon-class="popup-close"></svg-icon>
      </div>
      <div class="addStaffPopup-header">{{ title }}</div>
      <div class="addStaffPopup-body">
        <div class="addStaffPopup-box">
          <div class="addStaffPopup-box-l">姓名</div>
          <div class="addStaffPopup-box-r">
            <input
              type="text"
              v-model="enterpriseEmployee.name"
              @input="
                enterpriseEmployee.name = enterpriseEmployee.name.replace(
                  /[^\u4E00-\u9FA5A-Za-z.]/g,
                  ''
                )
              "
              placeholder="真实姓名"
            />
          </div>
        </div>
        <div class="addStaffPopup-box">
          <div class="addStaffPopup-box-l">手机号</div>
          <div class="addStaffPopup-box-r">
            <input
              type="tel"
              maxlength="13"
              v-model="mobile"
              placeholder="常用手机号"
              readonly
              @blur="blur"
              style="color: #999"
            />
          </div>
        </div>

        <div class="addStaffPopup-box">
          <div class="addStaffPopup-box-l">岗位名称</div>
          <div class="addStaffPopup-box-r">
            <input
              type="text"
              v-model="enterpriseEmployee.postName"
              placeholder="请输入"
            />
          </div>
        </div>
        <div class="addStaffPopup-box">
          <div class="addStaffPopup-box-l">所属部门</div>
          <div
            class="addStaffPopup-box-r cityText"
            :class="{ cityDesc: selectText === '请选择' }"
            @click="popupShowClick($event, 2)"
          >
            {{ selectText
            }}<svg-icon icon-class="xiala-icon" class="xiala"></svg-icon>
          </div>
        </div>
        <div class="addStaffPopup-box">
          <div class="addStaffPopup-box-l">现居住地</div>
          <div
            class="addStaffPopup-box-r cityText"
            :class="{ cityDesc: showText === '所在地区' }"
            @click="popupShowClick($event, 1)"
          >
            {{ showText
            }}<svg-icon icon-class="xiala-icon" class="xiala"></svg-icon>
          </div>
        </div>
        <div class="addStaffPopup-box">
          <div class="addStaffPopup-box-l">现居住详细地址</div>
          <div class="addStaffPopup-box-r">
            <el-input
              type="textarea"
              :autosize="{ minRows: 1, maxRows: 6 }"
              v-model="enterpriseEmployee.contactDetail"
              placeholder="请输入"
              class="textStyle"
            >
            </el-input>
          </div>
        </div>
        <div
          class="addStaffPopup-box"
          v-if="
            enterpriseEmployee.status !== 10 && enterpriseEmployee.isAdmin !== 1
          "
          style="
            border-top: 5px solid rgba(244, 244, 244, 0.5);
            border-bottom: 5px solid rgba(244, 244, 244, 0.5);
          "
        >
          <div class="addStaffPopup-box-l">员工状态</div>
          <div
            class="addStaffPopup-box-r"
            :class="{ redColor: enterpriseEmployee.dimissionTime }"
            @click="dateClick"
          >
            {{ statusText }}
            <svg-icon icon-class="xiala-icon" class="xiala"></svg-icon>

            <date-picker
              v-model="enterpriseEmployee.dimissionTime"
              value-type="format"
              format="YYYY-MM-DD"
              @change="dateChange"
              class="data-picker-style"
              :placeholder="item.tips"
              :open.sync="openDates"
              :editable="false"
              :clearable="false"
            ></date-picker>
          </div>
        </div>
      </div>
      <div class="addStaffPopup-footer" v-if="enterpriseEmployee.status === 10">
        <div class="addStaff-btn" @click="checkApplyJoinEnterprise(1)">
          同意加入
        </div>
        <div
          class="addStaff-btn addStaffDel"
          @click="checkApplyJoinEnterprise(2)"
        >
          删除信息
        </div>
      </div>
      <div class="addStaffPopup-footer" v-else>
        <div class="addStaff-btn" @click="addStaffSave">保 存</div>
      </div>
    </div>
    <cascadePopup
      v-if="popupShow"
      :offsetTop="popupTop"
      :offsetWidth="popupLeft"
      :popupBottom="popupBottom"
      :ajaxData="enterpriseEmployee"
      :item="item"
      @swiperSave="swiperSave"
      @cascadeClose="cascadeClose"
    ></cascadePopup>

    <div
      class="staff-popup fadeIn"
      :style="{
        left: popupLeft + 'px',
        top: popupTop + 'px',
        bottom: popupBottom + 'px',
        height: item.field === 'bankProvinceId' ? '417px' : '',
      }"
      v-if="selectShow"
      @mouseleave="selectShow = false"
    >
      <Triangle direction="top" left="290" color="#cccccc"></Triangle>
      <div class="staff-popup-header" v-if="!enterpriseEmployee.name">
        请给<span>{{ name }}</span
        >选择所属部门
      </div>
      <div class="staff-popup-header" v-else>
        请给<span>{{ enterpriseEmployee.name }}</span
        >选择所属部门
      </div>
      <div class="staff-popup-content">
        <div
          class="staff-popup-box"
          v-for="(item, i) in arrayList"
          :key="i"
          @click.stop="listClick(item)"
        >
          <div class="staff-popup-box-l">
            <svg-icon
              v-if="item.id === enterpriseEmployee.departmentId"
              icon-class="duigou-yes"
              class="duigou"
            ></svg-icon>
            <svg-icon
              v-if="item.id !== enterpriseEmployee.departmentId"
              icon-class="duigou-no"
              class="duigou"
            ></svg-icon>
          </div>
          <div
            class="staff-popup-box-r"
            :class="{ gudongr: item.text === '股东' }"
          >
            {{ item.text }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/zh-cn";
import cascadePopup from "@/components/cascadePopup";
import {
  getEmployeeInfo,
  prefectEnterpriseEmployee,
  modifyEnterpriseEmployee,
  checkApplyJoinEnterprise,
} from "@/http/api";
export default {
  data() {
    return {
      userId: sessionStorage.getItem("userId"),
      enterpriseId: sessionStorage.getItem("enterpriseId"),
      item: {
        value1: "",
        value2: "",
        value3: "",
        desc1: "",
        desc2: "",
        desc3: "",
      },
      dimissionTime: "",
      popupShow: false,
      popupTop: null,
      popupLeft: 0,
      openDates: false,
      popupBottom: null,
      showText: "所在地区",
      requireList: [
        "name",
        "mobile",
        "postName",
        "contactProvinceId",
        "contactDetail",
      ],
      enterpriseEmployee: {},
      statusText: "",
      arrayList: {},
      selectText: "请选择",
      selectShow: false,
      name: "员工",
      title: "完善员工信息",
      mobile: "",
    };
  },
  watch: {
    //监听手机号位数input输入
    mobile(newValue, oldValue) {
      if (this.mobile) {
        this.mobile = this.mobile.substring(0, 13);
        this.mobile =
          newValue.length > oldValue.length
            ? newValue
                .replace(/\s/g, "")
                .replace(/(\d{3})(\d{0,4})(\d{0,4})/, "$1 $2 $3")
            : this.mobile.trim();
        // 用户名与密码联动效果
        // var reg = /^[1-9]\\d{" + 0 + "," + 13 + "}$/;
        // var re = new RegExp(reg);
        // console.log(re.text(thismobile));
        this.mobile = this.mobile.substring(0, 13);
      }
    },
  },
  props: {
    details: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  components: {
    cascadePopup,
    DatePicker,
  },
  mounted() {
    this.getEmployeeInfo();
    this.arrayList = JSON.parse(sessionStorage.getItem("departmentList"));
    this.arrayList.forEach((element) => {
      element.text = element.departmentName;
      element.id = element.departmentId;
    });
  },
  methods: {
    checkApplyJoinEnterprise(type) {
      // console.log(item);
      let msg = this.checkFun();
      if (msg !== "true") {
        this.$store.commit("MyMessage", msg);
        return;
      }
      let param = {
        adminId: this.userId,
        enterpriseId: this.enterpriseId,
        employeeId: this.enterpriseEmployee.employeeId,
        confirmResult: type, //通过1/不通过2
      };
      checkApplyJoinEnterprise(param).then((res) => {
        // console.log(res);
        if (res.code === 0) {
          this.enterpriseEmployee.status = 1;
          this.addStaffSave();
        } else {
          this.$store.commit("MyMessage", res.msg);
        }
      });
    },
    // 获取员工信息
    getEmployeeInfo() {
      let param = {
        enterpriseId: this.enterpriseId,
        userId: this.details.userId,
        employeeId: this.details.employeeId,
      };
      getEmployeeInfo(param).then((res) => {
        console.log(res);
        if (res.code === 0) {
          this.enterpriseEmployee = res.data;
          if (res.data.isAdmin === 1) {
            this.title = "完善管理员信息";
          }
          this.mobile = res.data.mobile;
          this.enterpriseEmployee.mobile =
            this.enterpriseEmployee.mobile.replace(/\s+/g, "");
          this.status = res.data.status;
          // this.isOntermFun();
          if (this.enterpriseEmployee.departmentName) {
            this.selectText = this.enterpriseEmployee.departmentName;
          }
          if (this.enterpriseEmployee.nationality + "" === "") {
            this.enterpriseEmployee.nationality = "1";
          }
          if (!this.enterpriseEmployee.jobTime) {
            this.staffType = "add";
            this.enterpriseEmployee.jobTime = "";
            // this.enterpriseEmployee.onTrialTermSalaryScale = "";
          }
          if (this.enterpriseEmployee.isAdmin === 1) {
            this.staffTitle = "完善管理员信息";
          }
          if (!this.enterpriseEmployee.identity) {
            this.enterpriseEmployee.identity = 1;
          }
          if (!this.enterpriseEmployee.contractType) {
            this.enterpriseEmployee.contractType = 3;
          }
          if (this.enterpriseEmployee.status === 1) {
            this.statusText = "正常";
          } else if (this.enterpriseEmployee.status === 2) {
            this.statusText =
              "离职日期: " +
              this.enterpriseEmployee.dimissionTime.split(" ")[0];
          } else if (this.enterpriseEmployee.status === 10) {
            this.statusText = "待入职";
          } else if (this.enterpriseEmployee.status === 99) {
            this.statusText = "审核不通过";
          }
          this.item.desc1 = this.enterpriseEmployee.contactProvince;
          this.item.desc2 = this.enterpriseEmployee.contactCity;
          this.item.desc3 = this.enterpriseEmployee.contactArea;
          this.item.value1 = this.enterpriseEmployee.contactProvinceId;
          this.item.value2 = this.enterpriseEmployee.contactCityId;
          this.item.value3 = this.enterpriseEmployee.contactAreaId;
          if (this.item.value1) {
            this.showText =
              this.item.desc1 +
              " - " +
              this.item.desc2 +
              " - " +
              this.item.desc3;
          }
        }
      });
    },
    dateChange() {
      this.enterpriseEmployee.status = 2;
      this.statusText = "离职日期: " + this.enterpriseEmployee.dimissionTime;
    },
    dateClick() {
      if (this.enterpriseEmployee.status === 10) {
        return;
      }
      this.openDates = true;
      this.$nextTick(() => {
        let className1 = document.querySelector(".className1");
        if (className1 === null) {
          let a = document.getElementsByClassName("mx-calendar-panel-date")[0];
          var newNodeTop = document.createElement("div");
          var vtop = "选择离职日期";
          newNodeTop.innerHTML = vtop;
          newNodeTop.style.textAlign = "center";
          newNodeTop.style.padding = "10px 0px 0";
          newNodeTop.style.color = "#262626";
          newNodeTop.className = "className1";
          a.parentNode.insertBefore(newNodeTop, a);
        }
      });
    },
    checkFun() {
      var jmz = {};
      jmz.GetLength = function (str) {
        return str.replace(/[\u0391-\uFFE5]/g, "aa").length; //先把中文替换成两个字节的英文，在计算长度
      };
      let length1 = jmz.GetLength(this.enterpriseEmployee.name);

      if (!this.enterpriseEmployee.name) {
        // this.$store.commit("MyMessage");
        return "请输入姓名";
      } else if (length1 < 4) {
        return "姓名长度不符合规则";
      }
      if (!this.mobile) {
        // this.$store.commit("MyMessage", "请输入常用手机号");
        return "请输入常用手机号";
      }
      const regMobile = /^1(3|4|5|6|7|8|9)\d{9}$/;
      if (!regMobile.test(this.mobile.replace(/\s+/g, ""))) {
        // this.$store.commit("MyMessage", "手机号不符合规则");
        console.log(123);
        return "手机号不符合规则";
      }
      if (!this.enterpriseEmployee.postName) {
        // this.$store.commit("MyMessage", "请输入岗位名称");
        return "请输入岗位名称";
      }
      if (!this.enterpriseEmployee.contactProvinceId) {
        // this.$store.commit("MyMessage", "请选择现居住地");
        return "请选择现居住地";
      }
      if (!this.enterpriseEmployee.contactDetail) {
        this.$store.commit("MyMessage", "请输入现居住详细地址");
        return "请输入现居住详细地址";
      }
      return "true";
    },
    hidePopup() {
      this.$emit("hidePopup");
    },
    blur() {
      const regMobile = /^1(3|4|5|6|7|8|9)\d{9}$/;
      console.log(this.enterpriseEmployee.mobile.replace(/\s+/g, ""));
      if (!regMobile.test(this.mobile.replace(/\s+/g, ""))) {
        this.$store.commit("MyMessage", "手机号不符合规则");
        return;
      }
    },
    addStaffSave() {
      let msg = this.checkFun();
      if (msg !== "true") {
        this.$store.commit("MyMessage", msg);
        return;
      }

      if (this.enterpriseEmployee.jobTime) {
        this.enterpriseEmployee.jobTime =
          this.enterpriseEmployee.jobTime.split(" ")[0];
      }
      let enterpriseEmployee = this.$config.cloneObj(this.enterpriseEmployee);
      enterpriseEmployee.mobile = this.mobile.replace(/\s+/g, "");
      if (this.enterpriseEmployee.userId === this.userId) {
        // * 完善员工信息[自己给自己]
        this.prefectEnterpriseEmployee(enterpriseEmployee);
      } else {
        this.modifyEnterpriseEmployee(enterpriseEmployee);
      }
    },
    prefectEnterpriseEmployee(enterpriseEmployee) {
      prefectEnterpriseEmployee(enterpriseEmployee).then((res) => {
        // console.log(res);
        if (res.code === 0) {
          setTimeout(() => {
            this.$emit("savePopup");
            // window.close();
            this.$store.commit("MyMessage", "修改成功");
          }, 500);
        } else {
          this.$store.commit("MyMessage", res.msg);
        }
      });
    },
    modifyEnterpriseEmployee(enterpriseEmployee) {
      let date = {
        enterpriseEmployeeVo3: enterpriseEmployee,
        userId: this.userId,
        enterpriseId: this.enterpriseId,
        employeeId: enterpriseEmployee.employeeId,
      };
      modifyEnterpriseEmployee(date).then((res) => {
        // console.log(res);
        if (res.code === 0) {
          setTimeout(() => {
            this.$emit("savePopup");
            // window.close();
            this.$store.commit("MyMessage", "修改成功");
          }, 500);
        } else {
          this.$store.commit("MyMessage", res.msg);
        }
      });
    },
    listClick(item) {
      this.enterpriseEmployee.departmentId = item.id;
      this.selectText = item.text;
      this.cascadeClose();
    },
    cascadeClose() {
      this.popupShow = false;
      this.selectShow = false;
      this.$forceUpdate();
    },

    swiperSave(item) {
      this.showText = item.desc1 + " - " + item.desc2 + " - " + item.desc3;
      this.popupShow = false;

      this.enterpriseEmployee.contactProvince = item.desc1;
      this.enterpriseEmployee.contactCity = item.desc2;
      this.enterpriseEmployee.contactArea = item.desc3;
      this.enterpriseEmployee.contactProvinceId = item.value1;
      this.enterpriseEmployee.contactCityId = item.value2;
      this.enterpriseEmployee.contactAreaId = item.value3;
    },
    popupShowClick(e, type) {
      if (type === 1) {
        this.popupShow = true;
      } else {
        this.selectShow = true;
      }
      let H1 =
        document.body.clientHeight -
        e.currentTarget.getBoundingClientRect().bottom;
      if (H1 < 440) {
        this.popupBottom = H1 + 10;
        this.direction = "bottom";
      } else {
        this.popupTop = e.currentTarget.getBoundingClientRect().top + 10;
        this.direction = "top";
      }
      this.popupLeft = e.currentTarget.getBoundingClientRect().left - 139;
      if (type === 2) {
        this.popupLeft += 50;
        this.popupTop += 28;
      }
    },
  },
};
</script>
<style lang="less" scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type="number"] {
  -moz-appearance: textfield;
}
</style>
<style lang="less" scoped>
.addStaffPopup-box-r {
  cursor: pointer;
}
.redColor {
  color: #ea0200;
}
.addStaff-btn {
  margin: 50px auto 0;
  &.addStaffDel {
    margin-top: 0;
    // margin-bottom: 10px;
    background: #fff;
    color: #999;
    border: none;
    font-weight: 400;
  }
}
</style>
<style lang="less">
.addStaffPopup-box-r {
  position: relative;
  .data-picker-style {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    z-index: -1;
  }
  .mx-datepicker {
    width: 100%;
    .mx-input {
      width: 100%;
      margin: 0;
      padding: 0;
      height: 22px;
      border: none;
      box-shadow: none;
      padding-right: 36px;
      font: 400 16px Techna;
      cursor: pointer;
    }
  }
  .mx-icon-calendar {
    display: none;
  }
}
.redColor {
  color: #ea0200;
}
</style>



