<template>
  <div
    class="staff-popup fadeIn sHide"
    :style="{
      left: popupLeft,
      top: popupTop,
      bottom: popupBottom,
      height: item.field === 'bankProvinceId' ? '417px' : '',
    }"
    @mouseleave="popupClose"
  >
    <Triangle :direction="direction" left="260" color="#cccccc"></Triangle>
    <div class="staff-popup-header sHide">
      请给<span>{{ obj.name }}</span
      >选择{{ child.text }}
    </div>
    <div class="staff-popup-content sHide">
      <div
        class="staff-popup-box sHide"
        v-for="(item, i) in child.arrayList"
        :key="i"
        @click.stop="listClick(item)"
      >
        <div class="staff-popup-box-l sHide">
          <svg-icon
            v-if="item.select"
            icon-class="duigou-yes"
            class="duigou sHide"
          ></svg-icon>
          <svg-icon
            v-if="!item.select"
            icon-class="duigou-no"
            class="duigou sHide"
          ></svg-icon>
        </div>
        <div class="staff-popup-box-r sHide">
          {{ item.text }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      userId: sessionStorage.getItem("userId"),
      enterpriseId: sessionStorage.getItem("enterpriseId"),
      visit: sessionStorage.getItem("visit"),
      name: "员工",
      scrollbarNumber: 0,
      child: {},
    };
  },
  props: {
    obj: {
      type: Object,
      default() {
        return {};
      },
    },
    item: {
      type: Object,
      default() {
        return {};
      },
    },
    popupTop: {
      type: String,
      default() {
        return {};
      },
    },
    popupBottom: {
      type: String,
      default() {
        return {};
      },
    },
    popupLeft: {
      type: String,
      default() {
        return {};
      },
    },
    direction: {
      type: String,
      default() {
        return {};
      },
    },
  },
  mounted() {
    let that = this;
    this.child = this.item;
    if (this.child.value + "" === "0" || this.child.value + "" === "-1") {
      this.child.value = "";
    }
  },
  methods: {
    popupClose() {
      this.$emit("closeFun");
    },
    listClick(item) {
      if (item.text === "股东") {
        if (!this.value1) {
          this.$store.commit("MyMessage", "请输入投资金额");
          return;
        }
        if (!this.value2) {
          this.$store.commit("MyMessage", "请输入百分比");
          return;
        }
      }
      if (item.text === "应届实习生(全日制学历教育)") {
        this.open(item);
        return;
      }
      if (this.child.field === "contractType") {
        if (this.item.id === "2") {
          this.ajaxData.identity = "6";
        }
        if (this.item.id === "1") {
          this.ajaxData.identity = "3";
        }
      }
      this.emitFun(item);
      this.$forceUpdate();
    },
    open(item, param) {
      const h = this.$createElement;
      this.$msgbox({
        title: "消息",
        message: h("p", null, [
          h(
            "p",
            { style: "color: #262626;font-size:14px" },
            "是否为本年度首次取得薪酬月份"
          ),
          h(
            "p",
            { style: "color: #FF931E;font-size:12px" },
            "涉及个税减除扣除，务必真实选择"
          ),
        ]),
        showCancelButton: true,
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        beforeClose: (action, instance, done) => {
          this.emitFun(item);
          done();
        },
      }).then((action) => {});
    },
    emitFun(item) {
      this.child.value = item.id;
      this.$store.commit("MyMessage", "已选择：" + item.text);
      let param = {
        field: this.child.field,
        value: this.child.value,
        type: this.child.type,
      };
      if (this.child.field === "bankProvinceId") {
        param.bankProvince = item.text;
      }
      this.$emit("parentEmit", param);
    },
  },
};
</script>

<style lang="less" scoped>
@import "../../assets/less/popup.less";
.duigou {
  font-size: 18px;
}
</style>