<template>
  <div class="addStaff-box-right" @click="popupShowClick($event)">
    <div class="addStaff-tips" v-if="!showText">{{ child.tips }}</div>
    <div class="addStaff-text" v-else>{{ showText }}</div>
    <svg-icon icon-class="move-icon" class="xiala"></svg-icon>
    <input
      type="file"
      class="avatar"
      name="file"
      id="avatar"
      @change="imgChange($event)"
    />
  </div>
</template>

<script>
export default {
  data() {
    return {
      userId: sessionStorage.getItem("userId"),
      enterpriseId: sessionStorage.getItem("enterpriseId"),
      token: sessionStorage.getItem("pc-tkn"),
      value: "",
      showText: "",
      child: {},
    };
  },
  props: {
    item: {
      type: Object,
      default() {
        return null;
      },
    },
  },
  mounted() {
    this.child = this.item;
    if (this.child.value) {
      this.showText = "已上传";
    }
  },
  methods: {
    popupShowClick(e) {},
    emitFun() {
      this.showText = "已上传";
      this.$store.commit("MyMessage", "已上传");
      let param = {
        field: this.child.field,
        value: this.child.image,
        type: this.child.type,
      };

      this.$emit("staffParent", param);
    },
    imgChange(e) {
      let that = this;
      console.log(e);
      const avatar = document.getElementById("avatar");
      avatar.onchange = (e) => {
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(file);
      };
      if (!avatar.files[0]) {
        alert("选择图片!");
        return false;
      }
      const formData = new FormData();
      formData.append("file", avatar.files[0]);
      formData.append("userId", that.userId);
      formData.append("enterpriseId", that.enterpriseId);
      const xhr = new XMLHttpRequest();
      xhr.onreadystatechange = (data) => {
        if (xhr.readyState === 4 && xhr.status === 200) {
          let res = JSON.parse(xhr.responseText);
          if (res.code === 0) {
            that.item.image = res.data.degreeeImg;
            that.child.image = res.data.headOne;
            this.emitFun();
            // console.log("success");
          } else {
            this.$store.commit("MyMessage", res.code);
          }
        }
      };

      xhr.open(
        "POST",
        "https://api.jzcfo.com/usermanager/employee/v1/uploadInchPhoto"
      );
      xhr.setRequestHeader("token", this.token);
      xhr.send(formData);
    },
  },
};
</script>

<style lang="less" scoped>
.xiala {
  font-size: 22px;
  right: 6px;
  top: 7px;
}
.addStaff-text,
.addStaff-tips {
  padding-right: 30px !important;
  width: calc(100% - 30px) !important;
}
</style>