<template>
  <div class="staffbox-div sHide">
    <div class="staffbox-tab" @click.stop="popupShowClick($event)">
      <div class="main-div-c">
        <slot v-if="!showIcon">
          {{ showText }}
        </slot>
        <slot v-else>
          <slot v-if="!child.value">
            <span style="color: #bbb">{{ child.tips }}</span>
          </slot>
          <slot v-else>{{ showText }}</slot>
        </slot>
        <div
          class="staff-popup fadeIn sHide"
          :style="{
            left: popupLeft + 'px',
            top: popupTop,
            bottom: popupBottom,
            height: item.field === 'bankProvinceId' ? '417px' : '',
          }"
          v-if="popupShow"
        >
          <Triangle
            :direction="direction"
            left="230"
            color="#cccccc"
          ></Triangle>
          <div class="staff-popup-header sHide">
            请给<span>{{ name }}</span
            >选择{{ child.text }}
          </div>
          <div class="staff-popup-content sHide">
            <div
              class="staff-popup-box sHide"
              v-for="(item, i) in child.arrayList"
              :key="i"
              @click.stop="listClick(item)"
            >
              <div class="staff-popup-box-l sHide">
                <svg-icon
                  v-if="item.id === child.value"
                  icon-class="duigou-yes"
                  class="duigou sHide"
                ></svg-icon>
                <svg-icon
                  v-if="item.id !== child.value"
                  icon-class="duigou-no"
                  class="duigou sHide"
                ></svg-icon>
              </div>
              <div
                class="staff-popup-box-r sHide"
                :class="{ gudongr: item.text === '股东' }"
              >
                {{ item.text }}
                <slot v-if="item.text === '股东'">
                  <input
                    type="text"
                    @click.stop=""
                    v-model="value1"
                    placeholder="投资金额"
                    class="sHide"
                  />元
                </slot>
              </div>
              <slot v-if="item.text === '股东'">
                <div class="staff-popup-box-l sHide"></div>
                <div class="staff-popup-box-r gudongr sHide">
                  <input
                    type="text"
                    @click.stop=""
                    v-model="value2"
                    placeholder="百分比"
                    class="sHide"
                  />%
                </div>
              </slot>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="main-div-r" v-if="showIcon && child.value"> -->
      <div class="main-div-r">
        <svg-icon
          icon-class="jiantou_shen"
          v-if="child.value"
          class="jiantou-icon"
        ></svg-icon>
        <svg-icon
          icon-class="jiantou_qian"
          v-else
          class="jiantou-icon"
        ></svg-icon>
      </div>
    </div>
  </div>
</template>

<script>
import bus from "@/util/bus";
export default {
  data() {
    return {
      userId: sessionStorage.getItem("userId"),
      enterpriseId: sessionStorage.getItem("enterpriseId"),
      visit: sessionStorage.getItem("visit"),
      child: {},
      showText: "",
      popupShow: false,
      popupTop: "",
      popupBottom: "",
      popupLeft: "",
      scrollbarNumber: 0,
      value1: "",
      value2: "",
      direction: "",
      name: "员工",
      showIcon: true,
    };
  },
  inject: ["ajaxData"],
  mounted() {
    let that = this;
    this.child = this.item;
    // console.log(this.child);
    if (this.child.value + "" === "0" || this.child.value + "" === "-1") {
      this.child.value = "";
    }
    if (this.staffType === "details" && this.child.field === "nationality") {
      if (
        this.ajaxData.idType + "" === "4" ||
        this.ajaxData.idType + "" === "2" ||
        this.ajaxData.idType + "" === "1"
      ) {
        this.showIcon = false;
      }
    }
    if (this.child.field === "identity") {
      if (this.ajaxData.contractType + "" !== "3") {
        this.showIcon = false;
      }
    }
    if (this.item.field === "departmentId") {
      this.item.arrayList = JSON.parse(
        sessionStorage.getItem("departmentList")
      );
      this.showText = this.ajaxData.departmentName;
      this.showText = this.ajaxData.departmentName;
      this.item.arrayList.forEach((element) => {
        element.text = element.departmentName;
        element.id = element.departmentId;
        // if()
      });
    }
    if (this.item.value) {
      this.item.arrayList.forEach((element) => {
        if (element.id === this.item.value) {
          this.showText = element.text;
        }
      });
    }
    if (this.ajaxData.name) {
      this.name = this.ajaxData.name;
    }
    bus.$on("hidePopup", function (data) {
      that.popupShow = false;
    });
    document.addEventListener("click", function (e) {
      if (typeof e.target.className === "string") {
        if (e.target.className.indexOf("sHide") === -1) {
          that.popupShow = false;
          that.$forceUpdate();
        }
      }
    });
  },
  watch: {
    ajaxData: {
      handler(newValue, oldValue) {
        if (newValue.name) {
          this.name = newValue.name;
        } else {
          this.name = "员工";
        }
        if (this.child.field === "onTrialTermSalaryScale") {
          // console.log(newValue);
          this.child.value = newValue.onTrialTermSalaryScale + "";
          this.item.arrayList.forEach((element) => {
            if (element.id === this.child.value) {
              this.showText = element.text;
            }
          });
          this.$forceUpdate();
        }
        if (this.child.field === "identity") {
          this.child.value = newValue.identity + "";
          if (
            this.child.value + "" === "6" &&
            this.ajaxData.contractType + "" === "2"
          ) {
            this.showIcon = false;
          } else if (
            this.child.value + "" === "3" &&
            this.ajaxData.contractType + "" === "1"
          ) {
            this.showIcon = false;
          } else {
            this.showIcon = true;
          }
          this.item.arrayList.forEach((element) => {
            if (element.id === this.child.value) {
              this.showText = element.text;
            }
          });
          this.$forceUpdate();
        }
      },
      deep: true,
    },
  },
  methods: {
    open(item, param) {
      const h = this.$createElement;
      this.$msgbox({
        title: "消息",
        message: h("p", null, [
          h(
            "p",
            { style: "color: #262626;font-size:14px" },
            "是否为本年度首次取得薪酬月份"
          ),
          h(
            "p",
            { style: "color: #FF931E;font-size:12px" },
            "涉及个税减除扣除，务必真实选择"
          ),
        ]),
        showCancelButton: true,
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        beforeClose: (action, instance, done) => {
          this.emitFun(item);
          done();
        },
      }).then((action) => {});
    },
    emitFun(item) {
      this.child.value = item.id;
      this.showText = item.text;
      this.popupShow = false;
      this.$store.commit("MyMessage", "已选择：" + item.text);
      let param = {
        field: this.child.field,
        value: this.child.value,
        type: this.child.type,
      };
      if (this.child.field === "identity") {
        if (item.text === "股东") {
          param.value1 = this.value1;
          param.value2 = this.value2;
        } else {
          param.value1 = 0;
          param.value2 = 0;
        }
      }
      if (this.child.field === "bankProvinceId") {
        param.bankProvince = item.text;
      }
      this.$emit("staffParent", param);
    },
    popupShowClick(e) {
      bus.$emit("hidePopup");
      bus.$emit("subsidy");

      if (!this.showIcon) {
        return;
      }
      if (this.child.field === "nationality") {
        if (
          this.ajaxData.idType + "" === "2" ||
          this.ajaxData.idType + "" === "4"
        ) {
          return;
        }
      }
      if (this.child.field === "onTrialTermSalaryScale") {
        if (
          this.ajaxData.onTrialTermBegin === "" &&
          this.ajaxData.onTrialTermEnd === ""
        ) {
          this.$store.commit("MyMessage", "请先选择试用期");
          return;
        } else {
          if (this.ajaxData.onTrialTermBegin === this.ajaxData.onTrialTermEnd) {
            return;
          }
        }
      }
      if (this.child.field === "departmentId") {
        if (this.ajaxData.postName === "") {
          this.$store.commit("MyMessage", "请先输入岗位名称");
          return;
        }
      }
      this.popupShow = true;
      let H1 =
        document.body.clientHeight -
        e.currentTarget.getBoundingClientRect().bottom;
      if (H1 < 440) {
        this.popupBottom = H1 + 36 + "px";
        this.direction = "bottom";
        this.popupTop = "auto";
      } else {
        this.popupTop = e.currentTarget.getBoundingClientRect().top + 36 + "px";
        this.direction = "top";
        this.popupBottom = "auto";
      }
      this.popupLeft = e.currentTarget.getBoundingClientRect().left - 80;
    },
    listClick(item) {
      if (item.text === "股东") {
        if (!this.value1) {
          this.$store.commit("MyMessage", "请输入投资金额");
          return;
        }
        if (!this.value2) {
          this.$store.commit("MyMessage", "请输入百分比");
          return;
        }
      }
      if (item.text === "应届实习生(全日制学历教育)") {
        this.open(item);
        return;
      }
      if (this.child.field === "contractType") {
        if (this.item.id === "2") {
          this.ajaxData.identity = "6";
        }
        if (this.item.id === "1") {
          this.ajaxData.identity = "3";
        }
      }
      this.emitFun(item);
      this.$forceUpdate();
    },
  },
  props: {
    ajaxData: {
      type: Object,
      default() {
        return null;
      },
    },
    item: {
      type: Object,
      default() {
        return null;
      },
    },
    staffType: {
      type: String,
      default() {
        return null;
      },
    },
  },
};
</script>
<style lang="less" scoped>
@import "../payroll.less";
@import "../../../../assets/less/popup.less";
.staffbox-div {
  .main-div-c {
    width: calc(100% - 40px);
  }
}
.addStaffWidth1 {
  width: calc(100% - 144px) !important;
}
.addStaffWidth2 {
  width: calc(100% - 98px) !important;
}
.placement {
  color: #999;
  .xiala {
    display: none;
  }
  .addStaff-text {
    width: calc(100%) !important;
    padding-right: 0 !important;
  }
}
.noIcon {
  width: calc(100%) !important;
  padding-right: 0 !important;
}
.staffbox-tab {
  display: table;
  width: 100%;
  .main-div-c {
    width: calc(100% - 40px);
  }
}
</style>