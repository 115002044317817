<template>
  <div class="declare-popup">
    <div class="declare-main">
      <div class="department-header department-width">
        <div class="department-link" @click="childClose">
          <i class="el-icon-arrow-left"></i>返回
        </div>
        子女教育
        <div class="department-btn">
          <i class="el-icon-info" @click="showPopup3 = true"></i>
          <!-- <div class="addStaff-btn" @click="submitFun">保 存</div> -->
        </div>
      </div>
      <div class="declare-main-content">
        <div class="declare-list">
          <div class="declare-box">
            <div class="declare-box-l">手机号码</div>
            <div class="declare-box-r">
              <input type="text" v-model="item.mobile" placeholder="请输入" />
            </div>
          </div>
          <div class="declare-box">
            <div class="declare-box-l">电子邮箱</div>
            <div class="declare-box-r">
              <input type="text" v-model="item.email" placeholder="请输入" />
            </div>
          </div>
          <div class="declare-box">
            <div class="declare-box-l">联系地址</div>
            <div class="declare-box-r">
              <el-input
                type="textarea"
                :autosize="{ minRows: 1, maxRows: 5 }"
                v-model="item.address"
                class="textStyle"
                placeholder="请输入"
              >
              </el-input>
            </div>
          </div>
        </div>
        <div class="declare-list">
          <div class="declare-box">
            <div class="declare-box-l">选择扣除年度</div>
            <div class="declare-box-r">
              <el-select v-model="item.deductYear" placeholder="请选择">
                <el-option
                  v-for="item in options1"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="declare-box">
            <div class="declare-box-l">选择子女</div>
            <div class="declare-box-r">
              <!-- <el-select
                v-model="item.zinv"
                placeholder="请选择"
                v-if="options.length > 0"
              >
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select> -->
              <div
                class="declare-text"
                @click="showPopup2 = true"
                v-if="list.length > 0"
              >
                {{ zinvText }}<i class="el-icon-arrow-down"></i>
              </div>
              <div class="declare-desc" @click="showPopup1 = true" v-else>
                请添加子女信息<i class="el-icon-arrow-down"></i>
              </div>
            </div>
          </div>
          <div class="declare-box">
            <div class="declare-box-l">本人扣除比例</div>
            <div class="declare-box-r">
              <el-select v-model="item.deductScale" placeholder="请选择">
                <el-option
                  v-for="item in options2"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
          </div>
        </div>
        <div class="declare-list check-list">
          <div class="declare-title">您的扣缴义务人</div>
          <div class="declare-box" @click="check = 1">
            <p class="p1">唐山市航达商贸有限公司1</p>
            <p class="p2">主管税务机关：国家税务总局唐山市路北区税务局</p>
            <p class="p2">地址：唐山丰润区曹雪芹西大街（三丰小区）102</p>
            <i class="el-icon-check" v-if="check === 1"></i>
          </div>
          <div class="declare-box" @click="check = 2">
            <p class="p1">唐山市航达商贸有限公司2</p>
            <p class="p2">主管税务机关：国家税务总局唐山市路北区税务局</p>
            <p class="p2">地址：唐山丰润区曹雪芹西大街（三丰小区）102</p>
            <i class="el-icon-check" v-if="check === 2"></i>
          </div>
        </div>
        <div class="declare-btns">
          <el-button type="primary" @click="saveFun">保 存</el-button>
        </div>
      </div>
    </div>

    <div class="popup-layer" v-if="showPopup2">
      <div class="popup-layer-bg"></div>
      <div class="popup-zinv">
        <div class="popup-layer-close">
          <svg-icon icon-class="popup-close"></svg-icon>
        </div>
        <div class="popup-zinv-main">
          <div class="popup-zinv-list" v-if="list.length > 0">
            <div
              class="declare-box"
              v-for="(e, i) in list"
              :key="i"
              @click="zinvSelect(e)"
            >
              <p class="p1">{{ e.name }}</p>
              <p class="p2">与我的关系：女儿</p>
              <p class="p2">居民身份证号：1*****************X</p>
              <!-- <i class="el-icon-check"></i> -->
            </div>
          </div>
          <NoRecord v-else></NoRecord>
        </div>
        <div class="popup-zinv-footer">
          <div
            class="zinv-btn"
            @click="
              showPopup1 = true;
              showPopup2 = false;
            "
          >
            添加子女
          </div>
        </div>
      </div>
    </div>
    <div class="popup-layer" v-if="showPopup3">
      <div class="popup-layer-bg" @click="showPopup3 = false"></div>
      <div class="popup-zinv tishi-popup">
        <div class="popup-layer-close" @click="showPopup3 = false">
          <svg-icon icon-class="popup-close"></svg-icon>
        </div>
        <div class="popup-zinv-tishi">
          <div class="tishi-title">子女教育填报提示</div>
          <div class="tishi-p1">
            在一个纳税年度内,纳税人发生的与基本医保相关的医药费用支出,扣除医保报销后个人负担（指医保目录范围内的自付部分）累计超过15000元的部分,由纳税人在办理年度汇算清缴时,在80000元限额内据实扣除。
          </div>
          <div class="tishi-p2">温馨提示：</div>
          <div class="tishi-p2">
            1.若纳税人本人、配偶、未成年子女中一人或多人一个纳税年度内发生大病医疗费用支出,需各自单独计算扣除额,再按政策规定办理扣除；
          </div>
          <div class="tishi-p2">
            2.您可以登录国家医保服务平台APP查询相关可扣除的大病医疗费用支出；
          </div>
          <div class="tishi-p2">
            3.当年发生的大病医疗费用,在次年办理年度汇算时才能享受,建议您在办理时一次性汇总报送。
          </div>
        </div>
        <div class="popup-tishi-footer">
          <div class="zinv-btn" @click="showPopup3 = false">我知道了</div>
        </div>
      </div>
    </div>
    <addzinvPopup
      v-if="showPopup1"
      @closeFun="closeFun"
      @save="save"
    ></addzinvPopup>
  </div>
</template>

<script>
import addzinvPopup from "./addzinvPopup";
import NoRecord from "@/components/NoRecord.vue";
export default {
  data() {
    return {
      showPopup1: false,
      showPopup2: false,
      showPopup3: false,
      date: this.$config.getDate(),
      item: {
        mobile: "",
        email: "",
        address: "",
        deductYear: "",
        zinv: "",
        deductScale: "",
      },
      value: "13812345678",
      check: "",

      options: [],
      options1: [
        {
          value: "2020",
          label: "2020",
        },
        {
          value: "2021",
          label: "2021",
        },
      ],
      options2: [
        {
          value: "50",
          label: "50%平均扣除",
        },
        {
          value: "100",
          label: "100%全额扣除",
        },
      ],
      list: [],
      zinvText: "",
    };
  },
  props: {
    dateList: {
      type: Array,
      default() {
        return null;
      },
    },
  },
  components: {
    addzinvPopup,
    NoRecord,
  },
  mounted() {
    this.options1 = this.dateList;
  },
  methods: {
    saveFun() {
      this.childClose();
    },
    closeFun() {
      // this.$route
      this.showPopup1 = false;
    },

    save(d) {
      this.list.push(d);
      this.zinvText = d.name;
      this.closeFun();
      this.showPopup2 = true;
    },
    zinvSelect(e) {
      this.zinvText = e.name;
      this.showPopup2 = false;
    },
    childClose() {
      this.$emit("closeFun");
    },
  },
};
</script>

<style lang="less" scoped>
.department-width {
  width: 480px;
  margin: 0 auto;
}

.popup-layer {
  z-index: 999;
}
</style>
