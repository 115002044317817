<template>
  <div class="addStaff-box-right" @click="popupShowClick($event)">
    <div class="addStaff-tips" v-if="!showText && showText !== 0">
      {{ child.tips }}
    </div>
    <div class="addStaff-text" v-else>{{ showText }}</div>
    <svg-icon icon-class="xiala-icon" class="xiala" v-if="showIcon"></svg-icon>
    <div
      class="staff-popup fadeIn"
      :style="{
        left: popupLeft + 'px',
        top: popupTop,
        bottom: popupBottom,
      }"
      v-if="popupShow"
    >
      <Triangle :direction="direction" left="290" color="#cccccc"></Triangle>
      <div class="staff-popup-header">
        请给<span>{{ name }}</span
        >设置{{ child.text }}
      </div>
      <div class="staff-popup-content" id="staffContent">
        <div
          class="staff-popup-box"
          v-for="(item, i) in arrayList"
          :key="i"
          @click.stop="listClick(item)"
        >
          <div class="staff-popup-box-l">
            <svg-icon
              v-if="item.select"
              icon-class="duigou-yes"
              class="duigou"
            ></svg-icon>
            <svg-icon
              v-if="!item.select"
              icon-class="duigou-no"
              class="duigou"
            ></svg-icon>
          </div>
          <div class="staff-popup-box-r">
            <input
              type="text"
              class="ipt1"
              @click.stop=""
              v-model="item.subsidyName"
              placeholder="补贴名称"
            />
            <input
              type="text"
              class="ipt2"
              @click.stop=""
              v-model="item.subsidyMoney"
              placeholder="金额"
            />
          </div>
        </div>
        <div class="staff-popup-box" @click.stop="addSubsidy()">
          <div class="staff-popup-box-l">
            <svg-icon icon-class="duigou-no" class="duigou"></svg-icon>
          </div>
          <div class="staff-popup-box-r">
            <input
              type="text"
              class="ipt1"
              @click.stop=""
              v-model="subsidyName"
              placeholder="补贴名称"
            />
            <input
              type="text"
              class="ipt2"
              @click.stop=""
              v-model="subsidyMoney"
              placeholder="金额"
            />
          </div>
        </div>
      </div>
      <div class="staff-popup-footer">
        <div class="footer-btn1" @click.stop="submit">确定</div>
        <div class="footer-btn2" @click.stop="popupShow = false">取消</div>
      </div>
    </div>
  </div>
</template>

<script>
import { queryBuzhuButie, addSubsidy, cancelSubsidy } from "@/http/api";
import bus from "@/util/bus";
export default {
  data() {
    return {
      userId: sessionStorage.getItem("userId"),
      enterpriseId: sessionStorage.getItem("enterpriseId"),
      child: {},
      value1: "",
      showIcon: true,
      popupShow: false,
      openDates: false,
      showText: null,
      dimissionTime: "",
      popupTop: "",
      popupBottom: "",
      popupLeft: "",
      name: "员工",
      arrayList: [],
      subsidyName: "",
      subsidyMoney: "",
      subsidyList: [],
    };
  },
  inject: ["ajaxData"],
  components: {},
  mounted() {
    let that = this;
    that.child = that.item;
    if (that.staffType === "details") {
      that.queryBuzhuButie();
    } else {
      that.arrayList = JSON.parse(sessionStorage.getItem("buzhuButie"));
      that.buzhuButie();
    }

    bus.$on("hidePopup", function (data) {
      that.popupShow = false;
    });
  },
  methods: {
    submit() {
      let arrayData = [];
      this.showText = 0;
      this.arrayList.forEach((element) => {
        if (element.select) {
          let _id = this.userId;
          if (this.staffType === "details") {
            _id = this.ajaxData.userId;
          }
          this.showText += element.subsidyMoney * 1;
          let param = {
            subsidyName: element.subsidyName,
            subsidyAmt: element.subsidyMoney,
            enterpriseId: this.enterpriseId,
            userId: _id,
          };
          if (element.type === "this") {
            param.subsidyId = element.id;
          }
          arrayData.push(param);
          if (this.staffType === "details") {
            this.subsidyFun(param);
          }
        }
      });

      if (this.staffType === "add") {
        let params = {
          field: this.child.field,
          value: "",
          type: this.child.type,
          subsidyList: arrayData,
        };
        this.$emit("staffParent", params);
      }
      this.popupShow = false;
    },
    subsidyFun(param) {
      addSubsidy(param).then((res) => {
        console.log(res);
        if (res.code === 0) {
          this.queryBuzhuButie();
        } else {
          this.$store.commit("MyMessage", res.msg);
        }
      });
    },
    addSubsidy() {
      if (!this.subsidyName) {
        this.$store.commit("MyMessage", "补贴名称不能为空");
        return;
      }
      if (!this.subsidyMoney) {
        this.$store.commit("MyMessage", "补贴金额不能为空");
        return;
      }
      this.arrayList.push({
        subsidyName: this.subsidyName,
        subsidyMoney: this.subsidyMoney,
        select: true,
        type: "add",
      });
      this.subsidyName = "";
      this.subsidyMoney = "";
      this.$nextTick(() => {
        let staffContent = document.getElementById("staffContent");
        staffContent.scrollTop = staffContent.scrollHeight;
      });
    },
    cancelSubsidyFun(item) {
      let that = this;
      let param = {
        userId: this.ajaxData.userId,
        enterpriseId: this.ajaxData.enterpriseId,
        subsidyIdEnterprise: item.id,
      };
      cancelSubsidy(param).then((res) => {
        if (res.code == 0) {
          this.queryBuzhuButie("show");
        }
      });
    },
    buzhuButie(type, res) {
      this.arrayList.forEach((element) => {
        element.select = false;
        element.type = "this";
        element.subsidyMoney = "";
      });
      this.showText = 0;
      this.subsidyList = this.child.subsidyList;
      if (this.staffType === "details") {
        this.subsidyList = res.data.subsidy;
      }
      if (this.subsidyList.length > 0) {
        this.subsidyList.forEach((e1) => {
          this.showText += e1.subsidyMoney;
          this.arrayList.forEach((e2) => {
            if (e1.subsidyIdEnterprise === e2.id) {
              e2.subsidyMoney = e1.subsidyMoney;
              e2.select = true;
            }
          });
        });
      }
      if (this.showText === 0) {
        this.showText = null;
      }
      if (type === "show") {
        this.popupShow = true;
      }
    },
    queryBuzhuButie(type) {
      let that = this;
      let param = {
        userId: this.ajaxData.userId,
        enterpriseId: this.enterpriseId,
      };
      queryBuzhuButie(param).then((res) => {
        // console.log("res", res);
        if (res.code === 0) {
          this.arrayList = res.data.subsidyCompany;
          sessionStorage.setItem("buzhuButie", JSON.stringify(this.arrayList));
          this.buzhuButie(type, res);
        }
      });
    },
    listClick(item) {
      if (!item.select) {
        if (!item.subsidyName) {
          this.$store.commit("MyMessage", "补贴名称不能为空");
          return;
        }
        if (!item.subsidyMoney) {
          this.$store.commit("MyMessage", "补贴金额不能为空");
          return;
        }
      }
      item.select = !item.select;
      if (this.staffType === "details") {
        if (!item.select) {
          this.cancelSubsidyFun(item);
        }
      }
      this.$forceUpdate();
    },
    popupShowClick(e) {
      bus.$emit("hidePopup");
      this.popupShow = true;
      let H1 =
        document.body.clientHeight -
        e.currentTarget.getBoundingClientRect().bottom;
      // console.log(H1);
      if (H1 < 440) {
        this.popupBottom = H1 + 36 + "px";
        this.direction = "bottom";
        this.popupTop = "auto";
      } else {
        this.popupTop = e.currentTarget.getBoundingClientRect().top + 36 + "px";
        this.direction = "top";
        this.popupBottom = "auto";
      }
      this.popupLeft = e.currentTarget.getBoundingClientRect().left - 80;
    },
  },
  props: {
    item: {
      type: Object,
      default() {
        return null;
      },
    },
    staffType: {
      type: String,
      default() {
        return null;
      },
    },
  },
  watch: {
    ajaxData: {
      handler(newValue, oldValue) {
        if (newValue.name) {
          this.name = newValue.name;
        } else {
          this.name = "员工";
        }
        if (this.child.field === "onTrialTermSalaryScale") {
          // console.log(newValue);
          this.child.value = newValue.onTrialTermSalaryScale + "";
          this.item.arrayList.forEach((element) => {
            if (element.id === this.child.value) {
              this.showText = element.text;
            }
          });
          this.$forceUpdate();
        }
      },
      deep: true,
    },
  },
};
</script>
<style lang="less" scoped>
.staff-popup-box-r {
  .ipt1 {
    width: 46% !important;
    float: left;
    text-align: left;
  }
  .ipt2 {
    width: 46% !important;
    float: right;
    text-align: right;
  }
}
.duigou {
  position: relative;
  top: -7px;
}
</style>